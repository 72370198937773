import ScrollView from './Scroll'

const CheckboxScrollView = ScrollView.extend({
    checkContentSize: function(){
        return this.$el.find(".slidee").height() > this.$el.height();
    },
    calculateScrollContentSize: function(){
        this.$frame.height(this.$frame.parent().height());
    }
})

export default CheckboxScrollView
