import $ from 'jquery'
import ConfirmVideoRequestFormView from '../camera/ConfirmVideoRequestForm'

const MultiCameraConfirmVideoRequestFormView = ConfirmVideoRequestFormView.extend({
  onSave: function (confirmVideoRequestModel, response) {
    var view = this
    var confirmVideoRequestPopupView = view.parentView
    var notificationPopupView = view.findSubView('notificationPopup')
    var updateVideoPlayer = function (activityLog) {
      var videoPLayerView = view.parentView.parentView.getVideoPlayer()

      videoPLayerView.setProps(activityLog)
    }

    confirmVideoRequestPopupView.model.set('visible', false)
    $.get('/api/activity-log/' + confirmVideoRequestModel.get('logId')).then(function (activityLog) {
      view.updateActivityLogTableRow(activityLog)
      updateVideoPlayer(activityLog)
    })

    view.cancel()
    view.$el.find('[data-button=save]').addClass('disabled')

    notificationPopupView.render({
      msgId: 'successfully_saved',
      title: 'Camera Status',
      message: response.message,
      button: ['Close']
    })
  },
  getActivityLogTable: function () {
    return this.parentView.parentView.parentView
  }
})

export default MultiCameraConfirmVideoRequestFormView
