import $ from 'jquery'
import ScrollView from '../../view/Scroll'

const ReportScrollView = ScrollView.extend({
  calculateScrollContentSize: function () {
    this.$frame.height($(window).height() - this.$frame.offset().top)
  },
  checkContentSize: function () {
    return this.$el.offset().top + this.$('.slidee').height() > $(window).height()
  }
})

export default ReportScrollView
