import TableGrid from '../TableGrid@'

const AlertTableGridModel = TableGrid.extend({
  defaults: {
    selectAllowed: false,
    tableHead: [
      {
        tableHeadName: 'ID',
        field: 'id',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            dataButton: 'sortAsc',
            iconClass: 'asc'
          },
          {
            optionName: 'Sort Descending',
            dataButton: 'sortDesc',
            iconClass: 'desc'
          }
        ]
      },
      {
        tableHeadName: 'Alert Name',
        field: 'name',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            dataButton: 'sortAsc',
            iconClass: 'asc'
          },
          {
            optionName: 'Sort Descending',
            dataButton: 'sortDesc',
            iconClass: 'desc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'name',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Created On',
        field: 'createdDate',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            dataButton: 'sortAsc',
            iconClass: 'asc'
          },
          {
            optionName: 'Sort Descending',
            dataButton: 'sortDesc',
            iconClass: 'desc'
          }
        ]
      },
      {
        tableHeadName: 'Created By',
        field: 'createdBy',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            dataButton: 'sortAsc',
            iconClass: 'asc'
          },
          {
            optionName: 'Sort Descending',
            dataButton: 'sortDesc',
            iconClass: 'desc'
            // },
            // {
            //     optionName: 'Filter',
            //     iconClass: 'filter',
            //     subDrop: {
            //         dataLeftButtonValue: 'filter',
            //         dataRightButtonValue: 'clearFilter',
            //         leftButtonText: 'Filter',
            //         rightButtonText: 'Clear',
            //         subDropSection: [
            //             {
            //                 title: 'Show items with value that:',
            //                 filterByField: 'createdBy',
            //                 select: {
            //                     name: 'filter',
            //                     options: [
            //                         {
            //                             option: 'Starts with',
            //                             value: 'startsWith'
            //                         },
            //                         {
            //                             option: 'Ends With',
            //                             value: 'endsWith'
            //                         }
            //                     ]
            //                 },
            //                 input: {
            //                     placeholder: 'Enter value',
            //                     name: 'value',
            //                     dataType: 'string'
            //                 }
            //             }
            //         ]
            //     }
          }
        ]
      },
      {
        tableHeadName: 'Status',
        field: 'active',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            dataButton: 'sortAsc',
            iconClass: 'asc'
          },
          {
            optionName: 'Sort Descending',
            dataButton: 'sortDesc',
            iconClass: 'desc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'active',
                  select: {
                    name: 'filterAndValue',
                    filterFn: 'equal',
                    dataType: 'boolean',
                    options: [
                      {
                        option: 'Active',
                        value: '1'
                      },
                      {
                        option: 'Inactive',
                        value: '0'
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Activate/Deactivate',
        field: 'postcode',
        noDrop: true
      },
      {
        tableHeadName: 'Delete',
        noDrop: true
      },
      {
        tableHeadName: 'Edit',
        noDrop: true
      }
    ]
  }
})

export default AlertTableGridModel
