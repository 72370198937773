import AlertPanelView from '../AlertPanel'
import TowAwayTabsetView from './TowAwayTabset'
import TowAwayAlertListPopupView from './TowAwayAlertListPopup'
import TowAwayAlertFormView from './TowAwayAlertForm'
import $ from 'jquery'

const TowAwayAlertView = AlertPanelView.extend({
  constructor: function () {
    this.towAway = true
    this.setSubView({})
    this.AlertListPopupView = TowAwayAlertListPopupView // use addSubView
    this.tabsetName = 'TowAwayAlert'
    this.$el = $('[data-tab=towAway]')
    this.el = this.$el[0]
    this.addSubView('alertForm', TowAwayAlertFormView)

    AlertPanelView.apply(this, arguments)
  },
  initialize: function () { // should remove this
    var view = this

    view.alertListPopupView.on('ready', function () {
      this.alertListTableGridView.on('itemSelect', function ($tr, id) {
        var activeTabName = view.tabsetView.model.get('activeTab')

        if (view.model.get('dataMode') === 'edit') {
          if (activeTabName === 'selectRecipient') {
            if (view.tabsetView.selectVehicle) {
              view.tabsetView.selectVehicle.innerView.availableTableGridCollection.set(this.selectedItem[id].get('selectedAlertVehicleList'))
            }
          } else {
            if (view.tabsetView.selectRecipient) {
              view.tabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid').model.get('tableRow').set(this.selectedItem[id].get('selectedAlertRecipientList'))
            }
          }
        } else {
          if (activeTabName === 'selectRecipient') {
            if (view.tabsetView.selectVehicle) {
              view.tabsetView.selectVehicle.innerView.availableTableGridCollection.set([])
            }
          } else {
            if (view.tabsetView.selectRecipient) {
              view.tabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid').model.get('tableRow').set([])
            }
          }
        }
      })
    })
  },
  initTabset: function () {
    var view = this

    TowAwayTabsetView.prototype.parentView = this

    this.tabsetView = new TowAwayTabsetView({
      el: this.$el.find('[data-tabset=' + this.tabsetName + ']'),
      beforeInitTabCallback: (tabsetView, tabsetModel) => {
        tabsetView.on('initTab', function (tabView, activeTabName) {
          if (!tabView.innerView.isReady) {
            tabView.innerView.on('ready', function () {
              view.changeActiveTabHandler(tabView, activeTabName)
            })
          } else {
            view.changeActiveTabHandler(tabView, activeTabName)
          }
        })
      },
      afterInitTabCallback: (tabsetView, tabsetModel) => {
        tabsetView.listenTo(tabsetModel, 'change:activeTab', function (tabsetModel, activeTabName) {
          if (view.findSubView('profileManagerPopup')) {
            view.findSubView('profileManagerPopup').hide()
            if (view.findSubView('profileManagerPopup').findSubView('profileManagerBuilderPopup')) {
              view.findSubView('profileManagerPopup').findSubView('profileManagerBuilderPopup').hide()
            }
          }

          var tabData = view.tabData[activeTabName]

          if (!tabData || tabData.alertId === view.model.get('alertId') && tabData.dataMode === view.model.get('dataMode')) {
            return
          }

          view.changeActiveTabHandler(this.getActiveTabView(), activeTabName)
        })
      }
    })
    return this
  },
  changeActiveTabHandler: function (activeTabView, activeTabName) {
    var view = this
    var alertListTableGridView = view.alertListPopupView.alertListTableGridView
    var id = view.model.get('alertId')
    var isSelectRecipient = !activeTabView.innerView.availableTableGridView
    var selectedList, recipientTableGridView

    if (view.model.get('dataMode') === 'edit') {
      id = alertListTableGridView.getActiveRowId()

      if (!isSelectRecipient) {
        selectedList = alertListTableGridView.model.get('tableRow').get(id).get(view.tabsetView.get$ActiveTab().data('selected-list'))
        activeTabView.innerView.availableTableGridCollection.id = id
      } else {
        selectedList = alertListTableGridView.model.get('tableRow').get(id).get('selectedAlertRecipientList')
      }
    } else {
      if (!isSelectRecipient) {
        activeTabView.innerView.availableTableGridCollection.id = null
      } else {
        selectedList = []
      }
    }

    view.tabData[activeTabName] = {
      alertId: view.model.get('alertId'),
      dataMode: view.model.get('dataMode')
    }

    if (!isSelectRecipient) {
      activeTabView.innerView.reloadContent(selectedList, true)
    } else {
      recipientTableGridView = activeTabView.innerView.findSubView('selectRecipientTableGrid')
      if (!recipientTableGridView.isReady) {
        recipientTableGridView.on('ready', function () {
          this.renderSelectedList(selectedList)
        })
      } else {
        recipientTableGridView.renderSelectedList(selectedList)
      }
    }
  },
  bindAlertListTableGridItemSelect: function () {
    var view = this

    view.alertListPopupView.on('ready', function () {
      this.alertListTableGridView.on('itemSelect', function ($tr, id) {
        var activeTabView = view.tabsetView.getActiveTabView()
        var selectedVehicleList, selectedRecipientList

        view.model.set('alertId', id)

        selectedVehicleList = this.model.get('tableRow').get(id).get('selectedAlertVehicleList')
        selectedRecipientList = this.model.get('tableRow').get(id).get('selectedAlertRecipientList')

        if ($tr.is('.active')) {
          view.model.set('dataMode', 'edit')
          view.$el.find('input[name=name]').val(this.model.get('tableRow').get(id).get('name'))

          if (activeTabView.innerView.availableTableGridCollection) {
            activeTabView.innerView.availableTableGridCollection.id = view.model.get('alertId')
          }

          view.tabsetView.selectVehicle.innerView.reloadContent(selectedVehicleList, true)

          if (!view.tabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid').isReady) {
            view.tabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid').on('ready', function () {
              this.renderSelectedList(selectedRecipientList)
            })
          } else {
            view.tabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid').renderSelectedList(selectedRecipientList)
          }
        } else {
          view.model.set('dataMode', 'create')
          view.$el.find('input[name=name]').val('')
          if (activeTabView.innerView.availableTableGridCollection) {
            activeTabView.innerView.availableTableGridCollection.id = null
            activeTabView.innerView.reloadContent()
          } else {
            activeTabView.innerView.findSubView('selectRecipientTableGrid').renderSelectedList()
          }
        }
      })
    })
    return view
  }
})

export default TowAwayAlertView
