import Model from '../../Model'

const PredefinedChecklistModel = Model.extend({
  defaults: {
    id: null,
    checkName: '',
    options: null
  }
})

export default PredefinedChecklistModel
