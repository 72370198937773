
import Model from "../../Model";
import Validator from "../../../util/Validator";

const UnpluggedDeviceAlertModel = Model.extend({
    urlRoot: "/api/unplugged-device/alert",
    defaults: {
        id: null,
        name: "",
        selectedTrackingDeviceList: [],
        selectedAlertRecipientList: [],
        sendToSelf: true
    },
    validate: function(attrs, options) {
        const message = {
            empty_alert_name: "Please enter Alert name",
            wrong_alert_name: "Please verify the Alert name format.",
            profile_not_selected: "Please select profile."
        };
        const json = {
            button: ["OK"]
        };
        if (attrs.name.trim() === "") {
            json.msgId = "empty_alert_name";
            json.title = "Alert name verification";
            json.message = message[json.msgId];
            return json;
        }
        if (!Validator.checkValidity("alertName", attrs.name)) {
            json.msgId = "wrong_alert_name";
            json.title = "Alert name verification";
            json.message = message[json.msgId];
            return json;
        }
    }
});

export default UnpluggedDeviceAlertModel;
