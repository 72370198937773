import Model from '../../Model'

const DateRangeDropdownModel = Model.extend({
  urlRoot: 'api/report/location/date-range/',
  defaults: {
    data: []
  }
})

export default DateRangeDropdownModel
