import $ from 'jquery'
import View from './View'

const TableDropView = View.extend({
  templateUrl: 'template/tableDrop.html',
  elSelector: '[data-drop=table]',
  sortData: null,
  filterData: null,
  appendToBody: false,
  events: {
    'submit form': 'submitHandler',
    'mouseenter >ul>li': 'showSubDrop',
    'click ul>li[data-button]': 'optionClickHandler',
    'click [data-button=sort]': 'sortButtonHandler',
    'click [data-button=filter]': 'filterButtonHandler',
    'click [data-button=clearSort],[data-button=clearFilter]': 'clearButtonHandler'
  },
  constructor: function (option) {
    var view = this
    var args = arguments

    view.sortData = view.sortData || {}
    view.filterData = view.filterData || {}
    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, args)
    })
  },
  render: function (json) {
    var view = this
    var $el
    var html = view.dotTemplateToHtml(json)

    if (view.isElementInDom()) {
      view.$el.html($(html).html())
    } else {
      if (this.appendToBody) {
        $el = $(html)
        $('body').append($el)
        view.setElement($el)
      } else {
        view.getParentView().$el.append(html)
        view.resetElement()
      }
    }
    view.$el.find('select').selectmenu({
      width: 'auto'
    })
    return view
  },
  submitHandler: function (e) {
    var $form = $(e.currentTarget)

    $form.find('[data-button=filter]').click()
    e.preventDefault()
  },
  saveSort: function (sortData) {
    throw new Error('saveSort is not implemented')
  },
  clearSortData: function () {
    throw new Error('clearSortData is not implemented')
  },
  clearAllSortData: function () {
    this.sortData = {}
  },
  clearButtonHandler: function (e) {
    var view = this
    var $clearButton = $(e.currentTarget)
    var parentView = view.getParentView()
    var $activeTh = parentView.findActiveTh()

    if ($clearButton.attr('data-button') === 'clearSort') {
      view.clearSortData($activeTh)
      parentView.hideSortIcon($activeTh)
    } else if ($clearButton.attr('data-button') === 'clearFilter') {
      view.clearFilterData($activeTh)
      parentView.hideFilterIcon($activeTh)
    }
    parentView.render()
    view.hide()
  },
  getSortData: function () {
    return this.sortData
  },
  saveFilter: function (filterData, $th) {
    throw new Error('saveFilter is not implemented')
  },
  clearFilterData: function (thIndex) {
    if (this.filterData.hasOwnProperty(thIndex)) {
      delete this.filterData[thIndex]
    }
  },
  clearAllFilterData: function (thIndex) {
    this.filterData = {}
  },
  getFilterData: function () {
    return this.filterData
  },
  optionClickHandler: function (e) {
    var view = this
    var $li = $(e.currentTarget)
    var dataButton = $li.attr('data-button')
    var parentView = view.getParentView()
    var $activeTh = parentView.findActiveTh()
    var activeThIndex = $activeTh.index()
    var field = parentView.findTableHeadOptions($activeTh).field

    view.saveSort({
      field: field,
      sortOrder: dataButton
    }, $activeTh)
    parentView.render()
    parentView.showSortIcon($activeTh, dataButton)
    view.hide()
  },
  sortButtonHandler: function (e) {
    var view = this
    var parentView = view.getParentView()
    var $sortButton = $(e.currentTarget)
    var $form = $sortButton.closest('form')
    var $activeTh = parentView.findActiveTh()
    var sortOrder = $form.find('select[name=sortOrder]').val()
    var field = $form.find('select[name=sortField]').val()

    view.saveSort({
      field: field,
      sortOrder: sortOrder
    }, $activeTh)
    parentView.render()
    parentView.showSortIcon($activeTh, sortOrder)
    view.hide()
  },
  filterButtonHandler: function (e) {
    var view = this
    var parentView = view.getParentView()
    var $filterButton = $(e.currentTarget)
    var $form = $filterButton.closest('form')
    var $activeTh = parentView.findActiveTh()
    var activeThIndex = $activeTh.index()
    var field = parentView.findTableHeadOptions($activeTh).field

    view.saveFilter(view.parseFilterForm($form, field), $activeTh)
    parentView.showFilterIcon($activeTh)
    parentView.render()
    view.hide()
  },
  parseFilterForm: function ($form, field) {
    var view = this
    var json = []

    $form.find('fieldset>ul>li').each(function () {
      var $li = $(this)
      var filterAndValue = $li.find('[name=filterAndValue]')
      var dataType = $li.find('[name=value]').attr('data-type')
      var value = dataType ? view.parseByType(dataType, $li.find('[name=value]').val()) : $li.find('[name=value]').val()
      var filterFnName = $li.find('[name=filter]').size() ? $li.find('[name=filter]').val() : 'startsWith'

      if (filterAndValue.size()) {
        dataType = filterAndValue.attr('data-type')
        value = dataType ? view.parseByType(dataType, filterAndValue.val()) : filterAndValue.val()
        filterFnName = filterAndValue.attr('data-filter-fn') ? filterAndValue.attr('data-filter-fn') : 'startsWith'
      }

      json.push({
        field: field || $li.attr('data-filter-by'),
        filterFnName: filterFnName,
        value: value
      })
    })

    return json
  },
  parseByType: function (dataType, value) {
    var parseFn
    if (dataType === 'float') {
      parseFn = parseFloat
    } else if (dataType === 'number') {
      parseFn = parseInt
    } else if (dataType === 'string') {
      parseFn = String
    } else if (dataType === 'boolean') {
      parseFn = this.parseToBoolean
    }
    return parseFn(value)
  },
  parseToBoolean: function (value) {
    return Boolean(parseInt(value))
  },
  setDropPosition: function (pageX, pageY, relativePageX, relativePageY) {
    this.$el.css({
      display: 'block',
      top: pageY,
      left: pageX
    })
  },
  show: function (pageX, pageY, relativePageX, relativePageY) {
    var view = this

    view.setDropPosition(pageX, pageY, relativePageX, relativePageY)
    view.$el.find('[data-sub-drop]').each(function () {
      var $subDrop = $(this)
      var $li = $subDrop.closest('li')

      $subDrop.attr('data-index', $li.index())
    })
    view.$el.prepend(view.$el.find('[data-sub-drop]').hide())
  },
  hide: function () {
    var view = this

    view.$el.hide()
  },
  setValueToSubDrop: function ($tableDrop, activeThIndex) {
    var view = this
    var filterAndValue = $tableDrop.find('[name=filterAndValue]')
    var filterValue = $tableDrop.find('input[name=value]')
    var filterSelect = $tableDrop.find('[name=filter]')

    if (Object.keys(view.filterData).length) {
      if (filterSelect.size() && view.filterData[activeThIndex]) {
        filterSelect.val(view.filterData[activeThIndex][0].filterFnName)
        view.$el.find('select').selectmenu('refresh')
      }
      if (filterAndValue.size() && view.filterData[activeThIndex]) {
        filterAndValue.val(view.filterData[activeThIndex][0].value)
        view.$el.find('select').selectmenu('refresh')
      } else if (filterValue.size() && view.filterData[activeThIndex]) {
        view.$el.find('input[name=value]').val(view.filterData[activeThIndex][0].value)
      }
    }
  },
  showSubDrop: function (e) {
    var view = this
    var parentView = view.getParentView()
    var $li = $(e.currentTarget)
    var $tableDrop = $li.closest('[data-drop]')
    var $subDrop = $tableDrop.find('[data-sub-drop][data-index=' + $li.index() + ']')
    var top = $li.position().top
    var left = view.$el.width()
    var $activeTh = parentView.findActiveTh()
    var activeThIndex = $activeTh.index()

    if ($subDrop.is(':visible')) return
    if (view.$el.offset().left + view.$el.width() + $subDrop.width() > $(window).width()) {
      left = -$subDrop.outerWidth()
    } else {
      left = view.$el.width()
    }
    $subDrop.css({
      display: 'block',
      top: top,
      left: left
    })

    view.hideSubDrop()
    $subDrop.addClass('active')

    view.setValueToSubDrop($tableDrop, activeThIndex)
  },
  hideSubDrop: function () {
    var view = this
    var $subDrop = view.$('[data-sub-drop].active')

    $subDrop.hide().removeClass('active')
  },
  destroy: function () {
    var view = this

    view.remove()
  }
})

export default TableDropView
