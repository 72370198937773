import $ from 'jquery'
import View from '../../View'
import TodaysJourneySnailTrailPopupView from './TodaysJourneySnailTrailPopup'
import CompactReportView from '../CompactReport'
import TodaysJourneyFormView from './TodaysJourneyForm'
import TodaysJourneyTableGridView from './TodaysJourneyTableGrid'
import TodaysJourneyModel from '../../../model/report/todaysJourney/TodaysJourney'

const TodaysJourneyReportView = CompactReportView.extend({
  templateUrl: 'template/report/todaysJourney/todaysJourney.html',
  model: TodaysJourneyModel,
  mergeEvents: true,
  events: {
    'click [data-button=close]': 'closeBtnHandler',
    'change [data-checkbox=selectAll]': 'selectAllCheckboxHandler',
    'click tbody tr[data-journey-id]': 'selectJourney'
  },
  constructor: function () {
    var view = this
    var args = arguments

    view.model = new view.model()

    view.loadDotTemplate(function () {
      view.compileTemplate()
      view.templateOptions = window.settings.data
      CompactReportView.apply(view, args)
    })
  },
  initialize: function (option) {
    var view = this

    view.addSubView('snailTrailPopup', TodaysJourneySnailTrailPopupView)
    view.addSubView('compactReportTableGrid', TodaysJourneyTableGridView, this.$('[data-table-grid=todays-journey]')).once('ready', function () {
      view.addSubView('compactReportForm', TodaysJourneyFormView)
    })
  },
  render: function () {
    var view = this

    view.getParentView().$el.append(view.$el)
    view.$el.find('.header-panel span.title span').text(window.wls.getLastClickedVehicle().data.registrationNumber)

    return view
  },
  selectAllCheckboxHandler: function (e) {
    var view = this
    var $checkbox = $(e.currentTarget)
    var $daySection = $checkbox.closest('.box-shadow-frame')
    var $tr = $daySection.find('[data-table-grid=todays-journeys] tr')

    if ($checkbox.prop('checked')) {
      view.selectAll($tr.filter(':not(.active)'))
    } else {
      view.selectAll($tr.filter('.active'))
    }
  },
  selectAll: function ($tr) {
    var view = this

    if ($tr.size() === 1) {
      $tr.toggleClass('active')
      view.$('input:checkbox:checked').prop('checked', false)
    } else {
      $tr.toggleClass('active')
    }
    if (view.$('tr.active').size()) {
      view.findSubView('snailTrailPopup').showSnailTrail()
    } else {
      view.findSubView('snailTrailPopup').hide()
    }
  },
  getSelectedJourneyList: function () {
    var view = this
    var journeyIdList = []
    var $tr = view.$('tr.active')

    return $tr.map(function () {
      return this.getAttribute('data-journey-id')
    }).get()
  },
  selectJourney: function (e) {
    var $tr = $(e.currentTarget)

    this.selectAll($tr)
  },
  resetSelectedJourneyAndCheckbox: function ($tr) {
    var view = this

    view.$el.find('tr.active').removeClass('active').end().find('[data-checkbox]:checked').prop('checked', false)
  },
  closeBtnHandler: function () {
    $('.categories>li.mapping').click()
  },
  destroy: function () {
    var view = this

    if (this.hasSubView('snailTrailPopup')) {
      this.findSubView('snailTrailPopup').destroy()
    }

    if (this.hasSubView('compactReportTableGrid')) {
      this.findSubView('compactReportTableGrid').destroy()
    }

    if (this.hasSubView('compactReportForm')) {
      this.findSubView('compactReportForm').destroy()
    }

    if (window.mapCanvas.isStreetViewVisible()) {
      window.mapCanvas.toggleStreetView(false)
    }
    view.remove()
  }
})

export default TodaysJourneyReportView
