import $ from 'jquery'
import PopupView from '../Popup'
import EditVehRegPopupModel from '../../model/editVehicle/EditVehRegPopup'
import EditVehRegFormView from './EditVehRegFormView'

const EditVehRegPopupView = PopupView.extend({
  model: EditVehRegPopupModel,
  url: 'js/html/app/src/template/editVehicle/editVehRegPopup.html',
  initialize: function () {
    var view = this
    var $editVehReg = $('#editVehReg')
    var formView = view.addSubView('form', EditVehRegFormView)
    this.vehId = null

    // formView.listenTo(formView.model,'change:currentVehReg',this.changeCurrentVehReg)
    $('#editVehReg').on('click', function () {
      var vehId = $editVehReg.attr('data-veh-id')
      var $vehicleData = $('.row[data-id=' + vehId + ']')
      var vehReg = window.wls.getVehicle(vehId).data.registrationNumber
      view.alignBy($vehicleData, 'topRight', { left: 12 })

      if (view.isVisible() && this.vehId !== vehId) {
        formView.reset()
        view.$('.validation-ico').removeClass('error').removeClass('ok')
        formView.$('.button.save').addClass('disabled')
      }
      this.vehId = vehId
      formView.model.set('id', vehId)
      formView.model.set('currentVehReg', vehReg)
      view.show()
    })

    $(document).on('click.editVehReg', function (e) {
      var target = $(e.target)

      if (!target.closest('.vehicle-holder').size() && !target.closest('td.active[data-view="fleet"]').size() && !target.closest('.control-panel').size() && !target.closest('.map-tools').size() && !target.closest('#wlsmap').size() && !target.closest('.options').size() && !target.closest('#editVehReg').size() && !target.closest('.edit-veh-reg').size() && view.isVisible()) {
        formView.cancel()
        view.hide()
      }
    })
  },
  alignBy: function (opener) {
    PopupView.prototype.alignBy.apply(this, [opener.closest('.row'), 'topRight', { left: 12 }])
  },
  closeBtnHandler: function () {
    this.findSubView('form').cancel()
  }
})

export default EditVehRegPopupView
