import Model from '../Model'

const UnitIdReportFormModel = Model.extend({
  defaults: {
    unitIds: ''
  },
  validate: function (attrs, options) {
    console.log('validate()')
  }
})

export default UnitIdReportFormModel
