import $ from 'jquery'
import View from '../View'
import ScrollView from '../Scroll'
import VehicleIconList from '../../collection/editVehicle/VehicleIconList'

const VehicleIconListView = View.extend({
  templateUrl: 'template/editVehicle/vehicleIconList.html',
  collection: VehicleIconList,
  constructor: function (option) {
    var view = this
    var args = arguments
    // this.model = new this.model()
    this.el = option.el
    this.$el = $(this.el)
    this.collection = new this.collection()

    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, args)
    })
  },
  initialize: function () {
    var view = this
    var scrollView = this.addSubView('scroll', ScrollView)

    scrollView.setCalculateScrollContentSize(this.calculateScrollContentSize)
  },
  calculateScrollContentSize: null,
  fetchList: function (options) {
    this.collection.fetch(options)
  },
  fetchListSuccess: function (done) {
    this.render(done)
  },
  render: function (done) {
    var scroll = this.findSubView('scroll')
    this.$el.find('.slidee').html($(this.dotTemplateToHtml(this.collection.toJSON())))
    scroll.render(done, true)
  }
})

export default VehicleIconListView
