import MileageReportPopupView from './MileageReportPopup'
import JourneyReportSelectionMenuView from '../journeyReport/JourneyReportSelectionMenu'
import ReportPageView from '../ReportPage'
import MileageReportView from './MileageReport'

const MileageReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="journey"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', MileageReportPopupView)
    view.addSubView('selectionMenu', JourneyReportSelectionMenuView)
    view.addSubView('report', MileageReportView)
    view.render()
  }
})

export default MileageReportPageView
