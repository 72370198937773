import Collection from '../../Collection'
import ProfileModel from '../../../model/alert/outOfHours/Profile'

const OutOfHoursAlertAvailableProfileList = Collection.extend({
    model: ProfileModel,
    url: function(){
        return "/services/out-of-hours/alert-available-profile/" + (this.id ? this.id : "");
    }
})

export default OutOfHoursAlertAvailableProfileList
