import Model from './Model'

const AoiPoiDropdownModel = Model.extend({
  urlRoot: 'api/geo-resource/aoi-poi-options/',
  defaults: {
    data: []
  }
})

export default AoiPoiDropdownModel
