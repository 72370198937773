import View from '../View'
import AvailableSelectedTableGridView from './AvailableSelectedTableGrid'

const SelectProfileView = View.extend({
    events:{
        "click [data-button=createProfile]": "showPermittedOperatingHoursProfiles"
    },
    initialize: function(){
        var view = this;

        view.addSubView("availableSelectedTableGrid",AvailableSelectedTableGridView,{el: view.$el.find(".info-wrap")});
    },
    showPermittedOperatingHoursProfiles: function (){
        console.log(this);
        this.profileManagerPopupView.show();
        this.profileManagerPopupView.alignBy(this.$el.find("[data-button=manage-profiles]"),"center",{top: 10,left: 5});
        // var view = this;
        //
        // $("div:has(iframe) [data-button=close]").one("click",function(e){
        //     view.closeProfilePopup();
        //     e.preventDefault();
        // });
        // $("#content iframe").attr("src","/flx/html/wrapper/modalWindowsWrapper.jsf?view=OutOfHoursProfile").parent().show();
    },
    closeProfilePopup: function(){
        var view = this;
        var availableTableGridView = this.findSubView("availableSelectedTableGrid").availableTableGridView;
        var selectedTableGridView = this.findSubView("availableSelectedTableGrid").selectedTableGridView;
        var collectionId = availableTableGridView.collection.id;

        availableTableGridView.collection.id = null;
        $("#content div:has(iframe)").hide().find("iframe").attr("src","");
        availableTableGridView.collection.fetch({silent: true,remove: true}).done(function(newCollection){
            var len = newCollection.length;
            if(selectedTableGridView.collection.length) {
                availableTableGridView.collection.remove(selectedTableGridView.collection.models);
                selectedTableGridView.collection.each(function(model){
                    if(!_.where(newCollection,{id: model.id}).length){
                        selectedTableGridView.collection.remove(model);
                    }
                });
            }
            availableTableGridView.resetSelectedItemAndRow();
            availableTableGridView.render();
            availableTableGridView.scroll.render();
            availableTableGridView.collection.id = collectionId;
            if(view.getParentView().getParentView().model.get("dataMode") == "edit"){
                view.getParentView().getParentView().alertListPopupView.alertListTableGridView.fetchAlertlist(null,true);
            }
        });
    }
});

export default SelectProfileView
