import CompactReportPageView from '../CompactReportPage'
import VehicleDefectReportView from './VehicleDefectReport'

const VehicleDefectReportPageView = CompactReportPageView.extend({
  el: '<div class="report-page" data-report-page="vehicleDefect"></div>',
  initialize: function () {
    var view = this

    view.addSubView('compactReport', VehicleDefectReportView)
    view.render()
  }
})

export default VehicleDefectReportPageView
