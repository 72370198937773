import ReportPopupView from '../ReportPopup'
import LWJourneyReportFormView from './LWJourneyReportForm'

const LWJourneyReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/LoneWorkerJourney.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', LWJourneyReportFormView)
  }
})

export default LWJourneyReportPopupView
