import $ from 'jquery'
import View from './View'
import ScrollModel from '../model/Scroll'

const ScrollView = View.extend({
  model: ScrollModel,
  elSelector: '.scroll-frame',
  constructor: function () {
    this.model = new this.model()
    View.apply(this, arguments)
  },
  initSly: function () {
    var view = this

    view.$frame.sly(this.model.attributes)
    return view
  },
  render: function (done, noStyleReset) {
    var view = this

    view.resetElement()
    view.$frame = view.$el.find('.scroll-content')
    view.$scrollbar = view.$el.find('.scrollbar')
    view.model.set('scrollBar', view.$scrollbar)

    if (!noStyleReset) {
      view.$frame.attr('style', '')
    }

    if (view.calculateScrollContentSize) {
      view.calculateScrollContentSize()
    }
    if (view.checkContentSize()) {
      if (!view.$frame.hasClass('hasScroll')) {
        view.initSly()
      }

      setTimeout(function () {
        view.$frame.sly('reload')
        done && done()
      }, 1000)

      view.$scrollbar.show()
    } else {
      view.destroySly(noStyleReset)
    }
    return view
  },
  checkContentSize: function () {
    return this.$el.find('.slidee').height() > this.$el.height()
  },
  calculateScrollContentSize: function () {
    var buttonSetHeight = 42
    this.$frame.height($(window).height() - (this.$el.offset().top + buttonSetHeight))
  },
  setCheckContentSize: function (fn) { // should use OOP override instead of redefining checkContentSize
    this.checkContentSize = fn
  },
  setCalculateScrollContentSize: function (fn) {
    this.calculateScrollContentSize = fn
  },
  destroySly: function (noStyleReset) {
    if (this.$frame) {
      this.$frame.sly('destroy')
      this.$scrollbar.hide()
      if (!noStyleReset) {
        this.$frame.attr('style', '')
      }
      this.$el.find('.slidee').attr('style', '')
      this.$frame.removeClass('hasScroll')
    }
    return this
  },
  destroy: function () {
    this.destroySly()
    this.remove()
  }
})

export default ScrollView
