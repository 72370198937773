import CommentModel from '../../Comment'

const VehicleDefectCommentModel = CommentModel.extend({
  defaults: {
    defectId: null,
    user: '',
    createdAt: ''
  },
  urlRoot: 'api/report/comment'
})

export default VehicleDefectCommentModel
