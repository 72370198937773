import PopupView from '../../Popup'

const LeagueTableKeyPopupView = PopupView.extend({
  url: 'js/html/app/src/template/report/leagueTable/leagueTableKey.html',
  destroy: function () {
    var view = this

    view.remove()
  }

})

export default LeagueTableKeyPopupView
