import _ from 'underscore'
import View from './View'
import NotificationPopupView from './NotificationPopup'

const FormView = View.extend({
  model: null,
  subView: {
    notificationPopup: NotificationPopupView // causes inheritance bug
  },
  events: {
    submit: 'submitHandler',
    'click [data-button=cancel]': 'cancel'
  },
  constructor: function () {
    this.model = new this.model()
    View.apply(this, arguments)
  },
  render: function () {
    var $el = this.$el
    var json = this.model.toJSON()

    _.mapObject(json, function (val, key) {
      var $formElement = $el.find('[name=' + key + ']')
      if ($formElement.is('input:checkbox')) {
        $formElement.prop('checked', val)
      } else if ($formElement.is('input:radio')) {
        $formElement.filter('[value=' + val + ']').prop('checked', true)
      } else {
        $formElement.val(val)
      }
    })
  },
  cancel: function () {
    this.reset()
    this.trigger('cancel')
  },
  submitHandler: function (e) {
    e && e.preventDefault()
    this.syncData()

    if (this.model.isValid()) {
      this.submit()
    }
  },
  submit: function (doneCallback) {
    var view = this

    if (!view.model.isClientSide()) {
      view.model.save().done(function (response) {
        view.trigger('save', view.model, response)
        doneCallback && doneCallback(view.model, response)
      })
    } else {
      view.trigger('save', view.model)
    }
  },
  getFormData: function () {
    var names = {}
    var value
    var view = this
    var model = view.model

    view.$el.find('input:text,input[type=color],input:password,input:radio:checked,input:checkbox,input[type=hidden],select,textarea').each(function () {
      if (model.attributes.hasOwnProperty(this.name)) {
        if (this.type === 'checkbox') {
          value = this.checked
        } else {
          if (this.dataset && this.dataset.type === 'int') {
            value = parseInt(this.value)
            if (isNaN(value)) {
              value = 0
            }
          } else if (this.dataset && this.dataset.type === 'float') {
            value = parseFloat(this.value)
            if (isNaN(value)) {
              value = 0
            }
          } else {
            value = this.value
          }
        }
        names[this.name] = value
      }
    })

    return names
  },
  syncData: function () {
    this.model.set(this.getFormData())
  },
  reset: function () {
    this.el.reset()
    this.model.clear()
    this.model.set(this.model.defaults)
    this.syncData()
  }
})

export default FormView
