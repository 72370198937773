import SelectItemDropdownView from '../../SelectItemDropdown'
import DateIntervalDropdownModel from '../../../model/report/location/DateIntervalDropdown'

const DateIntervalDropdownView = SelectItemDropdownView.extend({
  model: DateIntervalDropdownModel,
  elSelector: '.select-holder.date-interval',
  selectSelector: 'dateInterval'
})

export default DateIntervalDropdownView
