import CompactReportView from '../../report/CompactReport'
import GeoMonitorFormView from './GeoMonitorForm'
import GeoMonitorTableGridView from './GeoMonitorTableGrid'

const GeoMonitorView = CompactReportView.extend({
  templateUrl: 'template/alert/geoMonitor/geoMonitor.html',
  initialize: function (option) {
    var view = this

    view.addSubView('compactReportTableGrid', GeoMonitorTableGridView, this.$('[data-table-grid=geoMonitor]')).once('ready', function () {
      view.addSubView('compactReportForm', GeoMonitorFormView)
    })
  },
  render: function () {
    var view = this

    view.getParentView().$el.append(view.$el)
    return view
  },
  destroy: function () {
    var view = this

    view.findSubView('compactReportTableGrid').destroy()
    view.findSubView('compactReportForm').destroy()
    view.remove()
  }
})

export default GeoMonitorView
