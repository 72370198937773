import SelectView from '../../Select'

const FuelVarianceFilterSelectView = SelectView.extend({
  events: {
    selectmenuchange: 'selectChangeHandler'
  },
  selectChangeHandler: function (e, ui) {
    var view = this
    var tableDropView = view.findTableDropView()
    var tableGridView = tableDropView.getParentView()
    var index = tableGridView.$('th').size()

    this[ui.item.value](tableDropView, index, {
      field: 'fuelLevelVarianceLitres',
      value: 0
    })
    tableGridView.render()
  },
  noFilter: function (tableDropView, index) {
    tableDropView.clearFilterData(null, index)
  },
  fuelTheft: function (tableDropView, index, filterParam) {
    filterParam.filterFnName = 'lessThan'
    tableDropView.saveFilter([filterParam], null, index)
  },
  fuelFill: function (tableDropView, index, filterParam) {
    filterParam.filterFnName = 'greaterThan'
    tableDropView.saveFilter([filterParam], null, index)
  },
  findTableDropView: function () {
    return this.getParentView().getParentView().findSubView('compactReportTableGrid').findSubView('tableDrop')
  }
})

export default FuelVarianceFilterSelectView
