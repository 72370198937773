import Model from '../../Model'

const LocationReportFormModel = Model.extend({
  defaults: {
    lat: '',
    lon: '',
    from: '',
    fromTime: '',
    to: '',
    toTime: '',
    fromDate: null,
    toDate: null,
    radius: '',
    unitViewId: '',
    dateRange: '',
    dateInterval: '',
    aoiPoiId: ''
  },
  url: 'api/report/location',
  validate: function (attrs, options) {
    console.log('Nothing to validate')
  }
})

export default LocationReportFormModel
