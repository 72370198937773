/**
 * Created by George on 07.04.2015.
 */
/**
 * Created by George on 26.03.2015.
 */
import ProfileBuilderPopupView from '../profileManager/ProfileBuilderPopup';
import DriverBehaviorProfileBuilderFormView from './DriverBehaviorProfileBuilderForm';

const DriverBehaviorProfileBuilderPopup = ProfileBuilderPopupView.extend({
    ProfileBuilderFormView: DriverBehaviorProfileBuilderFormView
});

export default DriverBehaviorProfileBuilderPopup;
