/**
 * Created by George on 01.09.2015.
 */
import DraggableModel from '../model/Draggable'
import $ from 'jquery'

const DraggablePopupModel = DraggableModel.extend({

  defaults: {
    containment: [0, 0, $(window).width() - 20, $(window).height() - 10],
    cancel: '.close-btn',
    cursor: 'move',
    handle: '.header-panel'
  }
})

export default DraggablePopupModel
