import SelectView from '../../Select'
import LeagueTableGroupListView from './LeagueTableGroupList'

const LeagueTableGroupSelectView = SelectView.extend({
  mergeEvents: true,
  events: {
    selectmenuchange: 'selectChange'
  },
  initialize: function () {
    this.addSubView('groupList', LeagueTableGroupListView, { el: this.el })
    SelectView.prototype.initialize.apply(this, arguments)
  },
  selectChange: function (e, ui) {
    var view = this
    var leagueTableReport = view.getParentView()
    var compactReportForm = leagueTableReport.findSubView('compactReportForm')

    compactReportForm.model.set('unit_id', ui.item.value)

    leagueTableReport.hideActivePopups()
    compactReportForm.submit()
  },
  reset: function () {
    var view = this

    view.el.value = 'none'
    view.$el.selectmenu('refresh')
  }
})

export default LeagueTableGroupSelectView
