import _ from 'underscore'
import $ from 'jquery'
import View from './View'
import SelectionMenuModel from '../model/SelectionMenu'
import ReportGroupTabsetView from './groupTabset/ReportGroupTabset'

const SelectionMenuView = View.extend({
  model: SelectionMenuModel,
  events: {
    'click [data-opener=dropdown]': 'toggleDropdown',
    'click [data-button=expand]': 'toggleExpand',
    'click [data-sort]': 'sort',
    'click [data-button=groups]': 'toggleSelectGroup'
  },
  constructor: function () {
    var view = this
    var args = arguments

    view.model = new view.model()
    view.templateOptions = window.userPrefs
    view.model.fetch({
      success: function () {
        view.loadDotTemplate(function () {
          view.compileTemplate()
          View.apply(view, args)
        })
      }
    })
  },
  render: function (groupHandler) {
    var view = this
    var json = view.model.toJSON()
    var $selectionMenu = $(view.dotTemplateToHtml(json))
    var selectedGroup

    if (!view.$el.attr('data-selection-menu')) {
      $(document).on('click', view.documentClickHandler)
      if (!groupHandler) {

        $('.left-col .account-list .account-folder').on('dblclick.selectionMenu', function (e) {
          view.selectGroupHandler(e)
        })
      }
      view.setElement($selectionMenu)
      view.$el.hide()
      view.getParentView().$el.append(view.$el)

      $('.left-col .account-list input:hidden[value=' + $.cookie('lastViewedGroup') + ']').addClass('active')
      //                view.findSubView('vehicleList').once('ready',function(){
      //                   this.render()
      //                })
      //                 if(!hide){
      view.$el.fadeIn(400, function () {
        view.switchExpandTitle()
      })
      // }
      // if(userPrefs.htmlGroupTabsAllowed){
      //     view.addSubView('groupTabset',ReportGroupTabsetView)
      // }
    }
    setTimeout(function () {
      if (window.settings.data.selectionMenuGroupNameVisible) {
        $('.groups-btn.hover-tooltip-report').mousemove(function (e) {
          var hovertext = $(this).attr('data-tooltip')
          var groupsTooltip = $('.groups-tooltip')
          groupsTooltip.text(hovertext).show()
          if ($('.left-panel').hasClass('active')) {
            groupsTooltip.css('top', e.clientY - 18).css('left', e.clientX - 268)
          } else {
            groupsTooltip.css('top', e.clientY - 18).css('left', e.clientX + 14)
          }
        }).mouseout(function () {
          $('.groups-tooltip').hide()
        })
      }
    }, 800)
    return view
  },
  selectGroupHandler: function (e) {

  },
  toggleActiveSort: function ($sortOption) {
    var sortOrder
    if ($sortOption.hasClass('asc')) {
      $sortOption.addClass('desc').removeClass('asc')
      sortOrder = 'desc'
    } else {
      $sortOption.addClass('asc').removeClass('desc')
      sortOrder = 'asc'
    }
    $sortOption.parent().siblings().find('.asc,.desc').removeClass('asc').removeClass('desc')
    return sortOrder
  },
  sort: function (e) {
    var view = this
    var $sortOption = $(e.currentTarget)
    var selectionName = $sortOption.attr('data-sort')
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var sortedVehicles
    var sortOrder

    switch (selectionName) {
      case 'SM_REGISTRATION':
        sortedVehicles = view.sortRegistration($sortOption)
        break
      case 'SM_TIME':
        sortedVehicles = view.sortTime($sortOption)
        break
      default:
        break
    }
    selectionMenuVehicleListView.render(sortedVehicles)
    view.hideDropdown($sortOption.closest('[data-dropdown]'))
  },
  sortRegistration: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.sortByNat(selectionMenuVehicleListView.collection.toJSON(), 'clientDescr')
    var $sortOption = view.$('[data-sort=SM_REGISTRATION]')
    var sortOrder = view.toggleActiveSort($sortOption)

    if (sortOrder === 'desc') {
      json.reverse()
    }

    return json
  },
  sortTime: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.sortBy(selectionMenuVehicleListView.collection.toJSON(), 'renewalDate')
    var $sortOption = view.$('[data-sort=SM_TIME]')
    var sortOrder = view.toggleActiveSort($sortOption)

    if (sortOrder === 'desc') {
      json.reverse()
    }

    return json
  },
  documentClickHandler: function (e) {
    var $opener

    if (!$(e.target).closest('.control-panel').size()) {
      $opener = $('[data-selection-menu] [data-opener=dropdown]')
      $opener.next(':visible').hide(300)
      $opener.parent().not('.expand').removeClass('active')
    }
  },
  resetElement: function () { // override
    var view = this
    var parentView = view.getParentView()

    if (!document.body.contains(view.el)) { // if element not in DOM
      view.setElement($(view.elSelector))
    }
  },
  toggleDropdown: function (e) {
    var view = this
    var $dropdownOpener = $(e.currentTarget)
    var $dropdown = $dropdownOpener.next()
    var $siblings = $dropdownOpener.parent().siblings()

    if ($dropdownOpener.hasClass('active')) {
      view.hideDropdown($dropdown)
    } else {
      view.showDropdown($dropdown)
    }
  },
  showDropdown: function ($dropdown) {
    var view = this
    var $siblings = $dropdown.parent().siblings()

    view.hideDropdown($siblings.find('[data-opener]').next(':visible'))
    $dropdown.prev().addClass('active')
    $dropdown.show(300)
  },
  hideDropdown: function ($dropdown) {
    $dropdown.prev().removeClass('active')
    $dropdown.hide(300)
  },
  toggleExpand: function (e) {
    var $expandButton = $(e.currentTarget)

    if ($expandButton.hasClass('active')) {
      this.collapseAll(e)
    } else {
      this.expandAll(e)
    }
  },
  expandAll: function (e) {
    var view = this
    var $expandButton = $(e.currentTarget)
    var $info = view.$('.vehicle-holder .slidee .row:visible .info')
    $expandButton.addClass('active')
    $info.slideDown(600).promise().done(function () {
      view.findSubView('vehicleList').findSubView('scroll').render()
      // if(userPrefs.htmlGroupTabsAllowed){
      //     view.findSubView('groupTabset').renderScroll()
      // }
    })
  },
  collapseAll: function (e) {
    var view = this
    var $expandButton = $(e.currentTarget)
    var $info = view.$('.vehicle-holder .slidee .row:visible .info')
    var sum = 0

    $expandButton.removeClass('active')
    //            $info.each(function(){
    //                sum += $(this).closest('.row').outerHeight() - $(this).height()
    //            })
    //            view.$('.right-col .vehicle-holder .scroll-content').triggerHandler('resize' , [sum] )
    $info.slideUp(600).promise().done(function () {
      view.findSubView('vehicleList').findSubView('scroll').render()
      // if(userPrefs.htmlGroupTabsAllowed){
      //     view.findSubView('groupTabset').renderScroll()
      // }
    })
  },
  switchExpandTitle: function () {
    var view = this
    if (view.$('.info:visible').size() >= Math.round(view.$('.info').size() / 2)) {
      view.$('.expand').addClass('active')
    } else {
      view.$('.expand').removeClass('active')
    }
  },
  toggleSelectGroup: function (e) {
    var view = this
    var $groupsButton = $(e.currentTarget)
    if ($('.map-wrap').hasClass('active')) {
      view.hideSelectGroup()
    } else {
      view.showSelectGroup()
    }
  },
  showSelectGroup: function () {
    $('.map-wrap').animate({ 'margin-left': $('.left-col').width() }, 600).addClass('active')
    $('.top-bar').animate({ 'margin-left': $('.left-col').width() }, 600).addClass('active')
    $('.left-panel').addClass('active')
  },
  hideSelectGroup: function () {
    $('.map-wrap').animate({ 'margin-left': 0 }, 600).removeClass('active')
    $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
    $('.left-panel').removeClass('active')
  },
  hide: function (callback) {
    var view = this
    var $mapWrap = $('.map-wrap')

    if ($('.map-wrap').hasClass('active')) {
      $mapWrap.css('margin-left', 0)
      $('.left-panel,.map-wrap').removeClass('active')
    }
    view.$el.fadeOut(400, callback)
  },
  show: function (callback) {
    var view = this

    view.$el.fadeIn(400, callback)
  },
  destroy: function () {
    var view = this

    $(document).off('click', view.documentClickHandler)
    $('.left-col .account-list .account-folder').off('dblclick.selectionMenu')
    $('.left-col .account-list input:hidden').removeClass('active')
    // if(userPrefs.htmlGroupTabsAllowed){
    //     view.findSubView('groupTabset').destroy()
    // }
    view.findSubView('vehicleList').destroy()
    view.hide(function () {
      view.remove()
    })
  }
})

export default SelectionMenuView
