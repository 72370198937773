import ReportSelectionMenuView from '../../ReportSelectionMenu'
import LWJourneyReportSelectionMenuModel from '../../../model/report/loneworkerReport/LWJourneyReportSelectionMenu'
import LWJourneyReportSelectionMenuUserListView from './LWJourneyReportSelectionMenuUserList'

const LWJourneyReportSelectionMenuView = ReportSelectionMenuView.extend({
  model: LWJourneyReportSelectionMenuModel,
  templateUrl: 'template/report/journeyReport/journeyReportSelectionMenu.html',
  elSelector: '[data-selection-menu=journey]',
  initialize: function () {
    var view = this

    view.addSubView('vehicleList', LWJourneyReportSelectionMenuUserListView)
    view.render()
  }
})

export default LWJourneyReportSelectionMenuView
