import Model from '../../Model'

const ReportDriverModel = Model.extend({
  defaults: {
    driverId: null,
    name: null
  },
  urlRoot: 'api/report/drivers'
})

export default ReportDriverModel
