import TableGridModel from "../../TableGrid@";
import PredefinedChecklist from "../../../collection/report/vehicleDefectReport/PredefinedChecklist";

const ChecklistTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: true,
    tableRow: PredefinedChecklist
  }
})

export default ChecklistTableGridModel
