
const Validator = {
  email: '^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$',
  firstName: '^[a-z]+$',
  lastName: '^[a-z]+$',
  alertName: '^[a-z0-9_-]+(\\s*[a-z0-9_-]+)*$',
  profileName: '^[a-z0-9_-]+(\\s*[a-z0-9_-]+)*$',
  vehReg: '^[a-zA-Z0-9\\s!@#\$%\^\&*\)\(+=._-]+$',
  checkValidity: function (name, value, caseSensitive) {
    var flags = 'ig'
    if (caseSensitive) {
      flags = 'g'
    }
    var regex = new RegExp(this[name], flags)
    return regex.test(value)
  }
}

export default Validator
