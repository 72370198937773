import ReportPopupView from '../ReportPopup'
import OutOfHoursQueryReportFormView from './OutOfHoursQueryReportForm'

const OutOfHoursQueryReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/Out_Of_Hours_Query.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', OutOfHoursQueryReportFormView)
  }
})

export default OutOfHoursQueryReportPopupView
