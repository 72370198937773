import Collection from '../Collection'
import VehicleIconModel from '../../model/editVehicle/VehicleIcon'

const VehicleIconList = Collection.extend({
  model: VehicleIconModel,
  url: function () {
    return 'api/selection-menu/action/edit-vehicle/iconset'
  }
})

export default VehicleIconList
