import Validator from '../../util/Validator'
import Model from '../Model'
import $ from 'jquery'

const RecipientModel = Model.extend({
  url: '',
  defaults: {
    firstName: '',
    lastName: '',
    email: '',
    sendByEmail: false
  },
  validate: function (attrs, options) {
    var message = {
      wrong_first_name_format: 'Please verify the First Name format.',
      wrong_last_name_format: 'Please verify the Last Name format.',
      wrong_email_format: 'Please verify the Email address format.',
      user_email_already_exists: 'This Email address matches an Active User Email address. Do you wish to continue?',
      archived_user_email_already_exists: 'This Email address matches an Archived User’s Email address. An Archived User must be Re-activated in order to become a Recipient of this Alert. '
    }
    var json = {
      button: ['OK']
    }
    if (!Validator.checkValidity('firstName', attrs.firstName)) {
      json.msgId = 'wrong_first_name_format'
      json.title = 'First name verification'
      json.message = message[json.msgId]
      return json
    }
    if (!Validator.checkValidity('lastName', attrs.lastName)) {
      json.msgId = 'wrong_last_name_format'
      json.title = 'Last name verification'
      json.message = message[json.msgId]
      return json
    }
    if (!Validator.checkValidity('email', attrs.email)) {
      json.msgId = 'wrong_email_format'
      json.title = 'Email verification'
      json.message = message[json.msgId]
      return json
    } else {
      return (function (json) {
        if (json.msgId) {
          json.message = message[json.msgId]
          if (json.msgId === 'archived_user_email_already_exists') {
            json.button = ['Close']
          } else {
            json.button = ['Abandon', 'Continue']
          }
          return json
        }
      })($.parseJSON($.ajax({
        type: 'POST',
        url: '/services/out-of-hours/check-recipient-email/',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        async: false,
        data: JSON.stringify({ email: attrs.email })
      }).responseText))
    }
  }
})

export default RecipientModel
