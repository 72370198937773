import $ from 'jquery'
import SnailTrailPopupView from '../SnailTrailPopup'
import TimeOnSiteDetailsTableGridView from './TimeOnSiteDetailsTableGrid'
import SnailTrailStepPlayView from '../SnailTrailStepPlay'

const TimeOnSiteDetailsPopupView = SnailTrailPopupView.extend({
  url: '/getTimeOnSiteDetailsPopup.do',
  initialize: function () {
    this.addSubView('SnailTrailStepPlayer', SnailTrailStepPlayView, { el: this.$('[data-player=stepPlay]') })
    this.addSubView('snailTrailTableGrid', TimeOnSiteDetailsTableGridView)
    this.findSubView('snailTrailTableGrid').collection.url = '/api/report/time-on-site/detail'
  },
  show: function () { // override
    $('[data-popup="journeyDetails"]').addClass('time-on-site-report')
    $('#content').prepend($('[data-popup="journeyDetails"]'))

    SnailTrailPopupView.prototype.show.apply(this, arguments)
  },

  showSnailTrail: function (row) {
    console.log('showSnailTrail: overriden')
    var view = this
    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.resetWindowSize()
    }
    var tableGridView = view.findSubView('snailTrailTableGrid')
    if (row) {
      console.log(row)
      var data = row.children()
      var valuesArray = data.toArray()
      var lat = valuesArray[7].innerHTML.substr(5, valuesArray[7].innerHTML.lastIndexOf('lon:') - 7)
      var lon = valuesArray[7].innerHTML.substr(21, valuesArray[7].innerHTML.length)
      var aoiPoiDescr = valuesArray[3].innerHTML
      var mapData = {
        vehReg: valuesArray[8].innerHTML,
        lat: lat,
        lon: lon,
        vehicleIconUrl: valuesArray[9].innerHTML
      }
      view.model.set('lat', lat)
      view.model.set('lon', lon)
      if (!view.isVisible()) {
        view.prepare(function () {
          tableGridView.render(data)
          view.renderAoiPoi(aoiPoiDescr)
          view.prepareDone()
          view.show()
          view.removeSnailTrailFromMap()
          view.showSnailTrailOnMap(mapData)
        })
      } else {
        tableGridView.render(data)
        view.renderAoiPoi(aoiPoiDescr)
        view.prepareDone()
        view.show()
        view.removeSnailTrailFromMap()
        view.showSnailTrailOnMap(mapData)
      }
    }
  },
  renderAoiPoi: function (aoiPoiDescr) {
    var view = this
    var aoiPrefix = 'AOI:'
    if (aoiPoiDescr) {
      if (aoiPoiDescr.includes(aoiPrefix)) {
        var aoiIndex = aoiPoiDescr.indexOf(aoiPrefix)
        if (aoiIndex > 0) { // DM Home, AOI:a1
          view.showPoiOnMap(aoiPoiDescr.substr(0, aoiPoiDescr.indexOf(',')))
        } else {
          view.showAoiOnMap(aoiPoiDescr.substr(aoiPoiDescr.indexOf(aoiPrefix) + aoiPrefix.length, aoiPoiDescr.length))
        }
      } else {
        if (aoiPoiDescr.indexOf(',') !== -1) {
          view.showPoiOnMap(aoiPoiDescr.substr(0, aoiPoiDescr.indexOf(',')))
        } else {
          view.showPoiOnMap(aoiPoiDescr)
        }
      }
    }
  },
  showPoiOnMap: function (poiDescr) {
    $.post('/api/geo-resource/poi-descr/', {
      descr: poiDescr
    }).done(function (poi) {
      var img = new Image()
      var poiPin = {}
      img.src = 'img/markers/marker_' + poi.pinColour.toLowerCase() + '.png'
      poiPin.lat = poi.lat
      poiPin.lon = poi.lon
      poiPin.typeDescr = 'poi'
      poiPin.areaColour = poi.areaColour
      poiPin.radius = poi.radius
      poiPin = window.mapCanvas.addTextMarkerByObj(img, poiPin, false, false, true, true, true)
      poiPin.areaColour = window.getAreaColorsRGB(poi.areaColour, true, true)
      setTimeout(window.rezoomMapFlx, 1500)
    }).error(function (msg, errorText) {
      console.error(errorText)
    })
  },
  showAoiOnMap: function (aoiDescr) {
    $.post('/api/geo-resource/aoi-descr/', {
      descr: aoiDescr
    }).done(function (aoi) {
      var areaColour = window.getAreaColorsRGB(aoi.areaColour, true, true, undefined)
      window.mapCanvas.initDrawControl(window.mapCanvas, aoi.polygonWkt, null, aoi.pinColour.toLowerCase(), areaColour)

      window.mapCanvas.deletePolygon()
      window.mapCanvas.addPolygonFromWkt(aoi.polygonWkt)
      window.mapCanvas.setPolygonPinColor(aoi.pinColour.toLowerCase())
      window.mapCanvas.setPolygonAreaColor(areaColour)
      window.mapCanvas.disableDrawing()
    }).error(function (msg, errorText) {
      console.error(errorText)
    })
  },
  showSnailTrailOnMap: function (mapData) {
    var vehicleIconUrl

    vehicleIconUrl = '../' + mapData.vehicleIconUrl
    window.mapCanvas.addImgMarker(
      vehicleIconUrl,
      mapData.lon,
      mapData.lat,
      undefined,
      false,
      false)
    setTimeout(window.rezoomMapFlx, 1500)
  },
  removeSnailTrailFromMap: function () {
    console.log('TimeOnSiteDetailsPopup: removeSnailTrailFromMap()')
    window.mapCanvas.cleanUp()
  },
  addNewPoi: function () {
    var $HTML = $('[data-popup="journeyDetails"]')
    var $report = $('[data-report="timeOnSite"]')

    $HTML.hide()
    $report.hide()

    this.outFromPOI()

    window.AddPOI.goToPOIAOI('fromTimeOnSiteReportDetails')
  },
  outFromPOI: function (e) {
    var $el = $('[data-popup="journeyDetails"] #addPoiMenu')

    $el.hide()
  },
  getPOIcoordinates: function (arr, el) {
    var view = this
    if (view.model) {
      window.AddPOI.lat = view.model.get('lat')
      window.AddPOI.lon = view.model.get('lon')
    }
    if (window.AddPOI.lat === 0 || window.AddPOI.lon === 0) {
      window.AddPOI.lat = 51.507351
      window.AddPOI.lon = -0.127758
    }
  }
})

export default TimeOnSiteDetailsPopupView
