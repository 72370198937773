import $ from 'jquery'
import Backbone from 'backbone'
import IdlingReportPageView from '../view/report/idling/IdlingReportPage'
import ProximityPageView from '../view/proximity/ProximityPage'
import DetailedReportPageView from '../view/report/detailed/DetailedReportPage'
import MileageReportPageView from '../view/report/mileage/MileageReportPage'
import DigitalInputReportPageView from '../view/report/digitalInput/DigitalInputReportPage'
import OutOfHoursQueryReportPageView from '../view/report/outOfHoursQuery/OutOfHoursQueryReportPage'
import JourneyCostReportPageView from '../view/report/journeyCost/JourneyCostReportPage'
import BusinessPrivateReportPageView from '../view/report/businessPrivate/BusinessPrivateReportPage'
import GeoFenceAlertView from '../view/alert/geofence/GeoFenceAlert'
import DriverBehaviourPageView from '../view/report/driverBehaviour/DriverBehaviourPage'
import TimeOnSiteReportPageView from '../view/report/timeOnSite/TimeOnSitePage'
import IdlingReportFuelUsageView from '../view/report/idlingReportFuelUsage/IdlingReportFuelUsagePage'
import SpeedingReportView from '../view/report/speedingReport/SpeedingReportPage'
import JourneyReportPageView from '../view/report/journeyReport/JourneyReportPage'
import LeagueTableReportPageView from '../view/report/leagueTable/LeagueTablePage'
import LWJourneyReportPageView from '../view/report/loneworkerReport/LWJourneyReportPage'
import GroupTabsetView from '../view/groupTabset/GroupTabset'
import FuelTankLevelVarianceReportPageView from '../view/report/fuelTankLevelVarianceReport/FuelTankLevelVarianceReportPage'
import FuelUsageReportPageView from '../view/report/fuelUsageReport/FuelUsageReportPage'
import VehicleDefectReportPageView from '../view/report/vehicleDefectReport/VehicleDefectReportPage'
import VehicleChecksView from '../view/report/vehicleDefectReport/VehicleChecks'
import TodaysJourneyPageView from '../view/report/todaysJourney/TodaysJourneyPage'
import DriverWorkingHoursTodayPopupView from '../view/driverWorkingHours/DriverWorkingHoursTodayPopup'
import RefreshSettingsPopupView from '../view/modalPanel/RefreshSettingsPopup'
import HeartBeatPopupView from '../view/modalPanel/HeartBeatPopup'
import PhoneNumberPopupView from '../view/phoneNumber/PhoneNumberPopup'
import EmergencyPhoneNumberPopupView from '../view/phoneNumber/EmergencyPhoneNumberPopup'
import DrivingTimeReportPageView from '../view/report/drivingTimeReport/DrivingTimeReportPage'
import ActivityLogPopupView from '../view/modalPanel/ActivityLogPopup'
import LoneWorkerActivityLogPopup from '../view/loneWorker/LoneWorkerActivityLogPopup'
import AlertManagerView from '../view/alert/AlertManager'
import TowAwayAlertManagerView from '../view/alert/towAway/TowAwayAlertManager'
import PreferencesPopupView from '../view/modalPanel/PreferencesPopup'
import EditVehRegPopupView from '../view/editVehicle/EditVehRegPopup'
import EditVehIconPopupView from '../view/editVehicle/EditVehIconPopup'
import EditVehDriverPopupView from '../view/editVehicle/EditVehDriverPopup'
import SendCommandPopupView from "../view/editVehicle/SendCommandPopup.js";
import GeoMonitorAlertView from '../view/alert/geoMonitor/GeoMonitorAlert'
import PoiListAlertView from '../view/alert/geoZones/PoiListAlert'
import AoiListAlertView from '../view/alert/geoZones/AoiListAlert'
import LiveVideoPlayerView from '../view/camera/LiveVideoPlayerPopup'
import getAreaColorsRGB from '../util/getAreaColorsRGB'

function resolveColor (info) {
  var color = info.match(/[0-9]/i)
  var hexColor = '#828282'
  if (color) {
    switch (color[0]) {
      case '0': // Blue
        hexColor = '#0175d7'
        break
      case '1': // Grey
        hexColor = '#828282'
        break
      case '2': // Black
        hexColor = '#000'
        break
      case '3': // Indigo
        hexColor = '#4b0082'
        break
      case '4': // Red
        hexColor = '#ff0000'
        break
      case '5': // Orange
        hexColor = '#ff9c00'
        break
      case '6': // Violet
        hexColor = '#ee82ee'
        break
      case '7': // Green
        hexColor = '#0db900'
        break
    }
  }
  return hexColor
}

function initJqueryBackboneApp () {
  const jQuery = $
  const RMS = window.RMS
  const Microsoft = window.Microsoft
  const google = window.google
  const localStorage = window.localStorage
  var leftPanel = $('.left-panel')
  var mapKey = $('#key').val()
  var mapSource = $('#mapSource').val()
  var content = $('#content')
  var optDrop = $('.options-drop:first')
  var activeOptionBtn = $('')
  var request = null
  var selectedGroup = $.cookie('lastViewedGroup')
  var showBE = window.parent.location.toString().indexOf('geoZones') == -1 && window.parent.location.toString().indexOf('proximity') == -1
  var additionalPixelsForHtmlWidth = 30
  var additionalPixelsForHtmlHeight = 3
  var additionalPixelsForHtmlWidthActivity = 80
  var additionalPixelsForHtmlHeightActivity = 3
  var mapObject = null
  var mapCanvas = null
  var userPrefs = null
  var mapPaused = false
  var poiManager
  var aoiManager
  var vehicleChecks
  var geoFenceAlertView
  var geoMonitorAlertView
  var towAwayAlertManagerView = null
  var unpluggedDeviceAlertManagerView = null
  var proximityTabset = null
  var videoPlayer
  var groupTabsetView
  var searchVehicle
  var htmlSearchVehicleAllowed
  var htmlGroupTabsAllowed
  var driverWorkingHoursTodayPopupView = new DriverWorkingHoursTodayPopupView()
  var refreshSettingsPopupView = new RefreshSettingsPopupView()
  var prefererencesPopupView = new PreferencesPopupView()
  var editVehRegPopupView = new EditVehRegPopupView()
  var editVehIconPopupView = new EditVehIconPopupView()
  var editVehDriverPopupView = new EditVehDriverPopupView()
  var sendCommandPopupView = new SendCommandPopupView()
  var activityLogPopupView
  var phoneNumberPopup
  var heartBeatPopupView = new HeartBeatPopupView()
  var addInfo = $('.add-information-popup.edit-info')
  var addPoi
  var multipleSelect
  var addPoiPinByRightClickFromMap = false
  var addPoiPinByRightClickFromReport = false
  var addPoiPinByRightClickFromJourneyReport = false
  var addPoiPinByRightClickFromActivityLog = false
  var addPoiPinByRightClickFromTimeOnSiteReport = false
  var addPoiPinByRightClickFromTimeOnSiteReportDetails = false
  // var messageAlertPopup = $(".message-alert-popup");
  var createdPoiPin = window.createdPoiPin = false
  var userProfileInfo
  var isResizable = false
  var liveVideoPlayerPopup = null
  // var sendMessage = $('.send-sms-message');

  var activeMenuItem
  var startVehiclesUpdate = true
  var filtersLoaded = false
  var pastDate = false

  $.ajaxSetup({ // setup default properties for ajax request
    cache: true
  })

  function getMap (mapdiv) {
    if (!mapObject) {
      if (mapSource.startsWith('MSVE')) {
        if (settings) {
          mapObject = new window.RMS.RVirtualEarth8(mapdiv, 6, 54.559322, -4.174804, showBE, mapKey, settings.data.maxZoomLevel)
        } else {
          mapObject = new window.RMS.RVirtualEarth8(mapdiv, 6, 54.559322, -4.174804, showBE, mapKey, 19)
        }
      } else if (mapSource === 'GOOGLEMAPS') {
        mapObject = new window.RMS.RGoogleMaps(mapdiv, 6, 54.559322, -4.174804, showBE)
      }
    }

    $('#key').remove()
    return mapObject
  }
  mapCanvas = getMap('wlsmap')
  window.mapCanvas = mapCanvas

  var cameraEventsContainer = new MutationObserver(function (e) {
    if (e[0].removedNodes) {
      window.mapCanvas.cleanUp()
    }
  })

  cameraEventsContainer.observe($('.cameras-events')[0], { childList: true })

  function initLiveVideo () {
    liveVideoPlayerPopup = new LiveVideoPlayerView()
    var $tooltip = $('<div class="groups-tooltip" style="display:block;visibility:hidden;"></div>')

    $('#content').append($tooltip)
    $('body').on('click', '.vehicle-header .live-video.available', function (e) {
      var vehicleId = $(e.target).closest('.row').find('input').val()
      var data = wls.getVehicle(vehicleId).data

      liveVideoPlayerPopup.setVideoProps({
        regNumber: data.registrationNumber,
        channels: data.channels,
        unitId: data.id
      })
      liveVideoPlayerPopup.prepare()
      liveVideoPlayerPopup.align()
      liveVideoPlayerPopup.prepareDone()

      liveVideoPlayerPopup.show()


      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (activityLogPopupView && activityLogPopupView.isVisible()) {
        activityLogPopupView.destroy()
      }

      editVehIconPopupView.hide()
      editVehDriverPopupView.hide()
      sendCommandPopupView.hide()
      editVehRegPopupView.hide()

      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      $(e.target).addClass('active')
      $(e.target).closest('.row').siblings().find('.live-video').removeClass('active')
    })
    $('body').on('mouseover', '.vehicle-header .live-video', function (e) {
      var $target = $(e.target)
      var alignTooltip = function (text) {
        $tooltip.text(text)
        $tooltip.css({
          top: ($target.offset().top - $tooltip.outerHeight()) - 5,
          left: $target.offset().left - (($tooltip.outerWidth() - $target.width()) / 2),
          visibility: 'visible'
        })
      }

      if ($target.hasClass('bad-connection')) {
        alignTooltip('Bad connection')
        return
      }

      var vehicleId = $target.closest('.row').find('input').val()
      var lastSeen = Date.now() - wls.getVehicle(vehicleId).data.renewalDate
      var timeLabel = ''

      if (lastSeen / 1000 < 60) {
        lastSeen /= 1000
        timeLabel = 's'
      } else if (lastSeen / 60000 < 60) {
        lastSeen /= 60000
        timeLabel = 'm'
      } else if (lastSeen / 3.6e+6 < 24) {
        lastSeen /= 3.6e+6
        timeLabel = 'h'
      } else if (lastSeen / 8.64e+7) {
        lastSeen /= 8.64e+7
        timeLabel = 'd'
      }

      alignTooltip('Last seen ' + lastSeen.toFixed(0) + timeLabel + ' ago')
    })
    $('body').on('mouseout', '.vehicle-header .live-video', function () {
      $tooltip.css({
        top: -9999,
        left: -9999,
        visibility: 'hidden'
      })
    })
  }

  if (!localStorage.hasOwnProperty('selectedVehicles')) {
    localStorage.setItem('selectedVehicles', '[]')
  }
  if (!localStorage.hasOwnProperty('expandedVehicles')) {
    localStorage.setItem('expandedVehicles', '[]')
  }
  if (!localStorage.hasOwnProperty('orderType')) {
    localStorage.setItem('orderType', '')
  }
  if (!localStorage.hasOwnProperty('orderDesc')) {
    localStorage.setItem('orderDesc', '')
  }
  if (!localStorage.hasOwnProperty('filter')) {
    localStorage.setItem('filter', '')
  }
  // Custom jQuery events
  jQuery.Event('vehicleListChange')

  jQuery.fn.getCursorPosition = function () {
    if (this.length == 0) return -1
    return $(this).getSelectionStart()
  }

  jQuery.fn.setCursorPosition = function (position) {
    if (this.length == 0) return this
    return $(this).setSelection(position, position)
  }

  jQuery.fn.getSelectionStart = function () {
    if (this.length == 0) return -1
    var input = this[0]

    var pos = input.value.length

    if (input.createTextRange) {
      var r = document.selection.createRange().duplicate()
      r.moveEnd('character', input.value.length)
      if (r.text == '') { pos = input.value.length }
      pos = input.value.lastIndexOf(r.text)
    } else if (typeof (input.selectionStart) !== 'undefined') { pos = input.selectionStart }

    return pos
  }

  jQuery.fn.setSelection = function (selectionStart, selectionEnd) {
    if (this.length == 0) return this
    var input = this[0]

    if (input.createTextRange) {
      var range = input.createTextRange()
      range.collapse(true)
      range.moveEnd('character', selectionEnd)
      range.moveStart('character', selectionStart)
      range.select()
    } else if (input.setSelectionRange) {
      input.focus()
      input.setSelectionRange(selectionStart, selectionEnd)
    }

    return this
  }

  window.isValidDate = function (str) {
    var params = str.split(/[\.]/)
    var yyyy = parseInt(params[2], 10)
    var mm = parseInt(params[1], 10)
    var dd = parseInt(params[0], 10)
    var date = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0)
    return mm === (date.getMonth() + 1) && dd === date.getDate() && yyyy === date.getFullYear()
  }

  window.isValidTime = function (str) {
    var params = str.split(/[\:]/)
    var hours = parseInt(params[0], 10)
    var minutes = parseInt(params[1], 10)
    var date = new Date()
    date.setHours(hours)
    date.setMinutes(minutes)
    return hours === date.getHours() && minutes === date.getMinutes()
  }

  $(document).ready(function () {
    var $events = jQuery._data(jQuery('.hover-tooltip')[0], 'events')
    if (!$events.mousemove) {
      var hoverTooltip = $('.hover-tooltip')
      hoverTooltip.mousemove(function (e) {
        var hovertext = $(this).attr('data-tooltip')
        var groupsTooltip = $('.control-holder .groups-tooltip')
        if (settings.data.selectionMenuGroupNameVisible) {
          groupsTooltip.text(hovertext).show()
          if ($('.left-panel').hasClass('active')) {
            groupsTooltip.css('top', e.clientY - 18).css('left', e.clientX - 268)
          } else {
            groupsTooltip.css('top', e.clientY - 18).css('left', e.clientX + 14)
          }
        }
      }).mouseout(function () {
        $('.control-holder .groups-tooltip').hide()
      })
    }
  });

  (function datepickerDefaults () {
    $.datepicker._checkOffset = function (inst, offset, isFixed) {
      // CHANGED This way I can add class date-right to the input to make it right aligned
      var isRightAlign = inst.input.hasClass('date-top-right')
      var dpWidth = inst.dpDiv.outerWidth()
      var dpHeight = inst.dpDiv.outerHeight()
      var inputWidth = inst.input ? inst.input.outerWidth() : 0
      var inputHeight = inst.input ? inst.input.outerHeight() : 0
      var viewWidth = document.documentElement.clientWidth + (isFixed ? 0 : $(document).scrollLeft())
      var viewHeight = document.documentElement.clientHeight + (isFixed ? 0 : $(document).scrollTop())

      offset.left += (this._get(inst, 'isRTL') || isRightAlign ? (inputWidth) : 0)
      offset.left -= (isFixed && offset.left == inst.input.offset().left) ? $(document).scrollLeft() : 0
      offset.top -= inputHeight + 1
      offset.top -= (isFixed && offset.top == (inst.input.offset().top + inputHeight)) ? $(document).scrollTop() : 0

      // CHANGED Do not check if datepicker is outside of the viewport if it's right aligned
      if (!isRightAlign) {
        // now check if datepicker is showing outside window viewport - move to a better place if so.
        offset.left -= Math.min(offset.left, (offset.left + dpWidth > viewWidth && viewWidth > dpWidth)
          ? Math.abs(offset.left + dpWidth - viewWidth) : 0)
      }

      offset.top -= Math.min(offset.top, (offset.top + dpHeight > viewHeight && viewHeight > dpHeight)
        ? Math.abs(dpHeight + inputHeight) : 0)
      return offset
    }
    $.datepicker.setDefaults({
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      showOtherMonths: true,
      firstDay: 1,
      dateFormat: 'dd.mm.yy'
    })
  })()

  $.widget('custom.imgselectmenu', $.ui.selectmenu, {
    // refresh: function() {
    //     // this._refreshMenu();
    //     // this._setText( this.buttonText, this._getSelectedItem() );
    //     // this.buttonText.css("background",this.element.val());
    //     // if ( !this.options.width ) {
    //     //     this._resizeButton();
    //     // }
    // },
    _select: function (item, event) {
      var oldIndex = this.element[0].selectedIndex

      if (event.type != 'menufocus') {
        var select = $('<span>' + event.currentTarget.innerHTML + '</span>')

        this.buttonText.attr('class', 'ui-menu-item')
        // Change native select element
        this.element[0].selectedIndex = item.index
        this.buttonText.empty()
        select.appendTo(this.buttonText)
        this._setAria(item)
        this._trigger('select', event, { item: item })
      }

      if (item.index !== oldIndex) {
        this._trigger('change', event, { item: item })
      }

      this.close(event)
    },
    _drawButton: function () {
      var that = this
      var tabindex = this.element.attr('tabindex')

      // Associate existing label with the new button
      this.label = $("label[for='" + this.ids.element + "']").attr('for', this.ids.button)
      this._on(this.label, {
        click: function (event) {
          this.button.focus()
          event.preventDefault()
        }
      })

      // Hide original select element
      this.element.hide()

      // Create button
      this.button = $('<span>', {
        class: 'ui-selectmenu-button ui-widget ui-state-default ui-corner-all',
        tabindex: tabindex || this.options.disabled ? -1 : 0,
        id: this.ids.button,
        role: 'combobox',
        'aria-expanded': 'false',
        'aria-autocomplete': 'list',
        'aria-owns': this.ids.menu,
        'aria-haspopup': 'true'
      })
        .insertAfter(this.element)

      $('<span>', {
        class: 'ui-icon ' + this.options.icons.button
      })
        .prependTo(this.button)

      this.buttonText = $('<span>' + this.element.find('option:selected').attr('data-tag') + '</span>')
        .appendTo(this.button)
      // this.buttonText.addClass(this.element.find( "option:selected" ).attr("data-class"));
      // this._setText( this.buttonText, this.element.find( "option:selected" ).text() );
      // this._setText( this.buttonText, this.element.find( "option:selected" ).attr( "data-tag" ) );
      this._resizeButton()

      this._on(this.button, this._buttonEvents)
      this.button.one('focusin', function () {
        // Delay rendering the menu items until the button receives focus.
        // The menu may have already been rendered via a programmatic open.
        if (!that.menuItems) {
          that._refreshMenu()
        }
      })
      this._hoverable(this.button)
      this._focusable(this.button)
    },
    _renderItem: function (ul, item) {
      var li = $('<li>', { class: 'daily-checks' })
      var wrapper = $(item.element.attr('data-tag'))
      //
      wrapper.appendTo(li)

      if (item.disabled) {
        li.addClass('ui-state-disabled')
      }

      return li.appendTo(ul)
    }
  })

  $.widget('custom.colorpicker', $.ui.selectmenu, {
    refresh: function () {
      this._refreshMenu()
      this._setText(this.buttonText, this._getSelectedItem().text())
      this.buttonText.css('background', this.element.val())
      if (!this.options.width) {
        this._resizeButton()
      }
    },
    _select: function (item, event) {
      var oldIndex = this.element[0].selectedIndex

      // Change native select element
      this.element[0].selectedIndex = item.index
      this.buttonText.css('background', item.value)
      this._setText(this.buttonText, item.label)
      this._setAria(item)
      this._trigger('select', event, { item: item })

      if (item.index !== oldIndex) {
        this._trigger('change', event, { item: item })
      }

      this.close(event)
    },
    _drawButton: function () {
      var that = this
      var tabindex = this.element.attr('tabindex')

      // Associate existing label with the new button
      this.label = $("label[for='" + this.ids.element + "']").attr('for', this.ids.button)
      this._on(this.label, {
        click: function (event) {
          this.button.focus()
          event.preventDefault()
        }
      })

      // Hide original select element
      this.element.hide()

      // Create button
      this.button = $('<span>', {
        class: 'ui-selectmenu-button ui-widget ui-state-default ui-corner-all',
        tabindex: tabindex || this.options.disabled ? -1 : 0,
        id: this.ids.button,
        role: 'combobox',
        'aria-expanded': 'false',
        'aria-autocomplete': 'list',
        'aria-owns': this.ids.menu,
        'aria-haspopup': 'true'
      })
        .insertAfter(this.element)

      $('<span>', {
        class: 'ui-icon ' + this.options.icons.button
      })
        .prependTo(this.button)

      this.buttonText = $('<span>', {
        class: 'ui-selectmenu-text'
      })
        .appendTo(this.button)
      this.buttonText.css('background', this.element.val())
      this._setText(this.buttonText, this.element.find('option:selected').text())
      this._resizeButton()

      this._on(this.button, this._buttonEvents)
      this.button.one('focusin', function () {
        // Delay rendering the menu items until the button receives focus.
        // The menu may have already been rendered via a programmatic open.
        if (!that.menuItems) {
          that._refreshMenu()
        }
      })
      this._hoverable(this.button)
      this._focusable(this.button)
    },
    _renderItem: function (ul, item) {
      var li = $('<li>', { style: 'background:' + item.value + ';' })
      if (item.disabled) {
        li.addClass('ui-state-disabled')
      }
      $(ul).addClass('ui-colorpicker-menu')
      $('<span>', {
        style: item.element.attr('data-style'),
        class: 'ui-icon ' + item.element.attr('data-class')
      }).appendTo(li)
      return li.appendTo(ul)
    }
  })
  function formatDateToHM (milliseconds) {
    var date = new Date(milliseconds)
    var hours = date.getHours()
    var minutes = date.getMinutes()
    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    return hours + ':' + minutes
  }
  function formatDateToHMS (milliseconds) {
    var date = new Date(milliseconds)
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds()
    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return hours + ':' + minutes + ':' + seconds
  }
  function sendCurrentAccount (accountId, callback) {
    $.cookie('lastViewedAccount', accountId)
    $.post('/setCurrentAccount.do', { id: accountId }).done(function () {
      if (callback) callback()
    })
  }
  window.sendCurrentAccount = sendCurrentAccount
  function getLastViewedAccount () {
    var $el = $('.left-col .account-list input[value=' + $.cookie().lastViewedGroup + ']').parents('li')
    return $($el[$el.length - 2]).find('[data-accountid]').attr('data-accountid')
  }

  async function initSettings() {
    // Wait for settings.get() to resolve
    await settings.get();

    // Once settings.get() resolves, check the condition and execute the block
    if (settings.data.powerBiAllowed) {
      var iframe = $('.modal-popup').find('iframe');
      var title = $('.modal-popup').find('.header-panel .title');

      // Populate the iframe with the specified URL
      iframe.attr('src', '/powerbi.html');

      // Set the title of the modal panel
      title.text("Power BI");

      // Show the modal popup
      $('.modal-popup').fadeIn(400);
    }
  }

  function setActionMenuVisibility () {
    $('#todaysJourneysOption').show()
    $('#hearBeatOption').show()
    if (settings.data.activityLogAllowed) {
      $('#activityLogOption').show()
    } else {
      $('#activityLogOption').hide()
    }
    $('#addInformation').show()
    addInfo = $('.add-information-popup.edit-info')
    if (settings.data.immobilizationAllowed) {
      $('#immobilise').show()
    }
    $('#sendSmsMessage').hide()
    $('#sendCommand').hide()
  }
  function modalPopupInit () {
    var popup = $('.modal-popup')
    var menu = $('.user-menu')
    var tab = $('.tab', menu)
    var iframe = popup.find('iframe')
    var headerPanel = popup.find('.header-panel')
    var title = headerPanel.find('.title')
    var flex = false
    var jsf = false
    var iframeHeight = 0
    var iframeWidth = 0
    var topBar = $('.top-bar')
    iframe.load(function () {
      var iframeContent = iframe.contents()
      if (!iframeContent.find('body').children().size()) {
        return
      }
      popup.fadeIn(400)
      iframe.attr('style', '')
      iframe.css({ height: $(window).height() - 35, width: $(window).width() })
      if (!topBar.height() > 0) {
        popup.css({ left: ($(window).width() - popup.outerWidth()) / 2, top: ($(window).height() - popup.outerHeight()) / 2 })
      } else {
        popup.css({ left: ($(window).width() - popup.outerWidth()) / 2, top: 0 })
      }
      if (iframeContent.find('#wrapper > .btn-close').length) {
        iframeContent.find('#wrapper > .btn-close').hide()
      }
    })
    $('#admin li:has(>.account-folder>a.group:not([data-html-page])),.user-menu .preferences,.tab li:has(>.account-folder>a[data-flex]),#reports li:has(a[data-flex]),#reports li:has(a[data-jsf]), li.bi').click(function (e) {
      var a = this.querySelector('a')

      activeMenuItem = menu.find('.categories>li.active')
      menu.find('.categories>li').removeClass('active')
      menu.find('.categories>li').removeClass('pressed')

      if ($(e.currentTarget).find(a).text() === 'Driver Access Rights') {
        popup.css('z-index', 0).fadeIn(50)
      }

      if (a.className == 'group') {
        menu.find('.categories>li.admin').addClass('active')
      } else {
        menu.find('.categories>li.reports').addClass('active')
      }

      if (searchVehicle) {
        searchVehicle.resetTableResults()
        if (searchVehicle.isVisible()) {
          searchVehicle.hideSearchResult()
        }
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }
      }
      if (leftPanel.find('.actions-holder').is(':Visible')) {
        searchVehicle.resetActionHolder()
      }
      tab.filter(':visible').animate({ width: 'hide' }, 600)
      if (reportHandler.hasActiveReport()) {
        reportHandler.hideReport(reportHandler.getActiveReport(), function () {
          leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
          leftPanel.fadeIn(300, function () {
            if (view.getActiveState() !== 'dual') {
              view.switchStateTo('fleet')
            } else {
              view.switchStateTo('dual')
            }
          })
        })
      }
      if (Incidents().windowIsVisible()) {
        Incidents().hideIncidentsWindow(function () {
          leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
          leftPanel.fadeIn(300, function () {
            if (view.getActiveState() !== 'dual') {
              view.switchStateTo('fleet')
            } else {
              view.switchStateTo('dual')
            }
          })
        })
        Incidents().hideIncidentDetails()
        if (Incidents().isOnMap()) {
          Incidents().removeIncidentsFromMap()
        }
        if (Incidents().getSnailTrail().isOnMap()) {
          Incidents().getSnailTrail().removeSnailTrailFromMap()
        }
      }
      if (JourneyDetails().isVisible()) {
        JourneyDetails().hide()
        JourneyDetails().getSnailTrail().removeSnailTrailFromMap()
      }
      flex = a.hasAttribute('data-flex')
      jsf = a.hasAttribute('data-jsf')
      iframe.attr('src', a.href)
      title.text($(a).text())
      e.preventDefault()
    })
    $('#content').on('click', '.tabset-report-panel [data-flex]', function () {
      var el = $(this)
      flex = true
      jsf = false
      iframe.attr('src', el.attr('data-flex'))
      title.text(el.find('span').text())
    })
    popup.find('.close-btn').click(function () {
      popup.fadeOut()
      // $(".user-menu .categories li.proximity").removeClass("active");
      $('.user-menu .categories li.reports').removeClass('active')
      $('.user-menu .categories li.admin').removeClass('active')
      $('.user-menu .categories li.incidents').removeClass('active')
      // activeMenuItem.addClass("active");
      $('.user-menu .categories li.mapping').addClass('active')
    })
  }
  function SafeInput ($input, allowOptions) { // safe input Class
    var allow

    allow = {
      letters: function (key) {
        return key < 65 || key > 90
      },
      digits: function (key) {
        return (key < 48 || key > 57) && (key < 96 || key > 105)
      },
      navigation: function (key) {
        return (key < 35 || key > 40) && key != 9
      },
      edit: function (key) {
        return key != 8 && key != 13 && key != 46
      },
      period: function period (key) {
        return key != 190 && key != 191
      },
      subtract: function (key) {
        return key != 189
      },
      semiColon: function (key) {
        return key != 186
      },
      f5: function (key) {
        return key != 116
      }
    }
    $input.keydown(function (e) {
      var key = e.keyCode
      var block = true
      var len = allowOptions.length
      for (var i = 0; i < len; i++) {
        if (!allow[allowOptions[i]](key)) {
          block = false
        }
      }
      if (block) {
        e.preventDefault()
      }
    })
  }
  function SnailTrail () { // SnailTrail Class
    var snailTrailJson
    var snailTrailTrTpl
    var tableGrid
    var snailTrailUrl
    var snailTrailParam = {}
    var onMap = false
    this.loadSnailTrail = function (incidentId, journeyId, callback) {
      if (journeyId instanceof Array) {
        snailTrailParam = { journeyIds: journeyId, permDescr: 'Full Day Snail Trail' }
        snailTrailUrl = '/getJourneysSnailTrail.do'
      } else if (journeyId) {
        snailTrailParam = { journeyId: journeyId }
        snailTrailUrl = '/getJourneySnailTrail.do'
      } else {
        snailTrailParam = { incidentId: incidentId }
        snailTrailUrl = '/getIncidentSnailTrail.do'
      }
      $.post(snailTrailUrl, snailTrailParam).done(function (json) {
        snailTrailJson = json
        if (callback) callback()
      }).error(function () {
        alert('ERROR')
      })
    }
    this.setSnailTrailTrTpl = function (templateInst) {
      snailTrailTrTpl = templateInst
    }
    this.setTableGrid = function (tableGridInst) {
      tableGrid = tableGridInst
    }
    this.getTableGrid = function () {
      return tableGrid
    }
    this.showSnailTrailOnMap = function () {
      var data = snailTrailJson.data
      var dataLen = data.length
      var img
      var shape
      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        img = new Image()
        img.src = '../' + currData.iconUrl
        shape = window.mapCanvas.addImgMarker(img, currData.lon, currData.lat)
        shape.logIndex = i
        shape.mapIndex = i
      }
      rezoomMapFlx()
      onMap = true
    }
    this.removeSnailTrailFromMap = function () {
      console.log('removeSnailTrailFromMap()')
      window.mapCanvas.cleanUp()
      onMap = false
    }
    this.isOnMap = function () {
      return onMap
    }
    this.generateSnailTrail = function () {
      var distanceMetric = userPrefs.distanceMetric
      var data = snailTrailJson.data
      var dataLen = data.length
      var date = new Date()
      var snailTrailTr = ''

      if (distanceMetric != 'KM') {
        $('.incident-details').find('.info th:last-child').text('mph')
      }

      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        date.setTime(currData.recDate)
        currData.formatedTime = (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ':' + (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

        currData.speed = (String(currData.speed)).replace(/([0-9]+\.[0-9]{2})[0-9]+$/g, '$1')

        snailTrailTr += snailTrailTrTpl.compile(currData)
      }
      tableGrid.getTable().find('tbody').html(snailTrailTr)
      bindEvents()
    }

    function bindEvents () {
      tableGrid.getTable().find('tbody tr').off('click').click(function () {
        var tr = $(this)
        var index = tr.index()
        tr.toggleClass('active').siblings().removeClass('active')
        if (tr.hasClass('active')) {
          window.mapCanvas.swapShapeIconToGlowedByIndex(index)
          window.mapCanvas.zoomToSubShapeList(index)
        } else {
          window.mapCanvas.setAllShapesIconColorToDefault()
        }
      })
    }
  }
  function MultipleSelect () {
    var handler = this
    var isDragging = false
    this.mouseMoveHandler = null
    this.mouseClickHandler = null

    this.mouseDownLoc = null
    this.mouseUpLoc = null
    this.boundaryZone = null

    this.vehiclesInBoundary = null

    var $HTML = $('.select-to-sms-popup')
    var $sendSmsOption = $('#multiple-select-sendsms')
    var $cancelOption = $('#multiple-select-cancel')

    $sendSmsOption.click(function (e) {
      console.log(e)
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (activityLogPopupView && activityLogPopupView.isVisible()) {
        activityLogPopupView.destroy() // close currently visible instance
      }
      liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

      window.wls.hideAddInformation()
      if (handler.vehiclesInBoundary && handler.vehiclesInBoundary.length > 0) {
        sendMessage.loadSendMessage(handler.vehiclesInBoundary)
        $HTML.hide()
        if (mapSource && mapSource.startsWith('MSVE')) {
          window.mapCanvas.map.entities.pop()
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          window.mapCanvas.cleanUp()
        }
      }
    })

    $cancelOption.click(function () {
      console.log('cancelOption click()')
      $HTML.hide()
      if (mapSource && mapSource.startsWith('MSVE')) {
        window.mapCanvas.map.entities.pop()
      } else if (mapSource && mapSource === 'GOOGLEMAPS') {
        window.mapCanvas.cleanUp()
      }
      $('body').css('cursor', 'crosshair')
    })

    this.showPopup = function (_left, _top, vehiclesInBoundary) {
      $HTML.css({ left: _left - ($HTML.outerWidth() / 2), top: _top - $HTML.outerHeight() - 10, visibility: 'visible' })
      $HTML.show()
      var dropdown = $($HTML.children('.options-drop')[0])
      dropdown.css({ display: 'block' })
      console.log(this.vehiclesInBoundary)
    }

    function initBindMapEvent () {
      if (mapSource && mapSource.startsWith('MSVE')) {
        handler.mouseClickHandler = Microsoft.Maps.Events.addHandler(window.mapCanvas.map, 'mousedown', function (e) {
          if (window.wlsMapTools.multipleSelectToolIsActive) {
            // re-draw case
            if (handler.mouseDownLoc != null && handler.mouseUpLoc != null) {
              handler.mouseDownLoc = e.location
              handler.mouseUpLoc = null
              isDragging = true
              $('body').css('cursor', 'crosshair')
            } else if (handler.mouseDownLoc == null) { // 1st draw event
              handler.mouseDownLoc = e.location
              isDragging = true
              $('body').css('cursor', 'crosshair')
            } else if (handler.mouseUpLoc == null) {
              handler.mouseUpLoc = e.location
              isDragging = false
              $('body').css('cursor', 'auto')

              var left = e.pageX
              var top = e.pageY

              var curVehicle
              var curLocation
              handler.vehiclesInBoundary = []
              for (var i = 0; i < wls.carsArray.length; i++) {
                curVehicle = wls.carsArray[i]
                curLocation = new Microsoft.Maps.Location(curVehicle.lat, curVehicle.lon)
                if (handler.boundaryZone && handler.boundaryZone.contains(curLocation)) {
                  console.log('MAP ZONE contains ' + curVehicle.registrationNumber)
                  handler.vehiclesInBoundary.push(curVehicle)
                }
              }
              handler.showPopup(left, top)
            }
          }
        })

        handler.mouseMoveHandler = Microsoft.Maps.Events.addHandler(window.mapCanvas.map, 'mousemove', function (e) {
          if (isDragging && window.wlsMapTools.multipleSelectToolIsActive) {
            //                        console.log("dragging: " + isDragging);
            if (mapSource && mapSource.startsWith('MSVE')) {
              window.mapCanvas.map.entities.pop()
            } else if (mapSource && mapSource === 'GOOGLEMAPS') {
              window.mapCanvas.cleanUp()
            }
            handler.mouseUpLoc = e.location
            //                    if (handler.mouseDownLoc && handler.mouseUpLoc) {
            var temporaryRectangle = [
              new Microsoft.Maps.Location(handler.mouseDownLoc.latitude, handler.mouseDownLoc.longitude),
              new Microsoft.Maps.Location(handler.mouseDownLoc.latitude, handler.mouseUpLoc.longitude),
              new Microsoft.Maps.Location(handler.mouseUpLoc.latitude, handler.mouseUpLoc.longitude),
              new Microsoft.Maps.Location(handler.mouseUpLoc.latitude, handler.mouseDownLoc.longitude)
            ]
            // Create a polygon
            var polygon = new Microsoft.Maps.Polygon(temporaryRectangle, {
              fillColor: 'rgba(0, 0, 0, 0.1)',
              strokeColor: 'black',
              strokeThickness: 1,
              strokeDashArray: [10, 5]
            })
            // Add the polygon to map
            window.mapCanvas.map.entities.push(polygon)
            handler.boundaryZone = Microsoft.Maps.LocationRect.fromCorners(
                new Microsoft.Maps.Location(handler.mouseDownLoc.latitude, handler.mouseDownLoc.longitude),
                new Microsoft.Maps.Location(handler.mouseUpLoc.latitude, handler.mouseUpLoc.longitude))
            //                    }
            //                     handler.mouseDownLoc = null;
            handler.mouseUpLoc = null
          }
        })
      }
      // else if (mapSource && mapSource === 'GOOGLEMAPS') {
      //   google.maps.event.addListener(window.mapCanvas.map, 'click', function (e) {
      //   })
      // }

    }

    initBindMapEvent()
  }
  function AddPOI () {
    var $HTML = $('.add-poi-popup')
    var handler = this
    var mapClickhandler
    this.lat = null
    this.lon = null

    this.showPopup = function (_left, _top) {
      $HTML.css({ left: _left - ($HTML.outerWidth() / 2), top: _top - $HTML.outerHeight() - 10 })
      $HTML.show()
    }

    this.hidePopup = function () {
      $HTML.hide()
    }

    this.goToPOIAOI = function (place) {
      var li = $('.geo-zones')
      var time = 0

      addPoiPinByRightClickFromMap = false
      addPoiPinByRightClickFromReport = false
      addPoiPinByRightClickFromJourneyReport = false
      addPoiPinByRightClickFromActivityLog = false
      addPoiPinByRightClickFromTimeOnSiteReport = false
      addPoiPinByRightClickFromTimeOnSiteReportDetails = false

      switch (place) {
        case 'fromMap':
          addPoiPinByRightClickFromMap = true
          if (view.getActiveState() !== 'dual') {
            view.state = 'fleet'
          }
          break
        case 'fromActivityLog':
          addPoiPinByRightClickFromActivityLog = true
          break
        case 'fromJourney':
          addPoiPinByRightClickFromReport = true
          if (view.getActiveState() !== 'dual') {
            view.state = 'fleet'
          }
          break
        case 'fromJourneyReport':
          addPoiPinByRightClickFromJourneyReport = true
          if (view.getActiveState() !== 'dual') {
            view.state = 'fleet'
          }
          break
        case 'fromTimeOnSiteReport':
          addPoiPinByRightClickFromTimeOnSiteReport = true
          if (view.getActiveState() !== 'dual') {
            view.state = 'fleet'
          }
          break
        case 'fromTimeOnSiteReportDetails':
          addPoiPinByRightClickFromTimeOnSiteReportDetails = true
          if (view.getActiveState() !== 'dual') {
            view.state = 'fleet'
          }
          break
        default:
          addPoiPinByRightClickFromMap = false
          addPoiPinByRightClickFromReport = false
          addPoiPinByRightClickFromJourneyReport = false
          addPoiPinByRightClickFromActivityLog = false
          addPoiPinByRightClickFromTimeOnSiteReport = false
          addPoiPinByRightClickFromTimeOnSiteReportDetails = false
      }

      li.addClass('active')
      activeMenuItem = li
      li.siblings().removeClass('active')
      li.siblings().removeClass('pressed')
      selectedGroup = $.cookie('lastViewedGroup')
      // tab.filter(":visible").animate({"width":"hide"},600);

      if (JourneyDetails().isVisible()) {
        JourneyDetails().hide()
      }
      if (searchVehicle) {
        searchVehicle.resetTableResults()
        if (searchVehicle.isVisible()) {
          searchVehicle.hideSearchResult()
        }
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }
      }
      if (JourneyDetails().getSnailTrail().isOnMap()) {
        JourneyDetails().getSnailTrail().removeSnailTrailFromMap()
      }
      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      if (sendMessage.isVisible()) {
        sendMessage.destroyEl()
      }
      if ($('.select-to-sms-popup').css('visibility') === 'visible') {
        $('.select-to-sms-popup').css('visibility', 'hidden')
        if (mapSource && mapSource.startsWith('MSVE')) {
          window.mapCanvas.map.entities.pop()
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          window.mapCanvas.cleanUp()
        }
        window.wlsMapTools.multipleSelectToolIsActive = false
      }
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
      if (activityLogPopupView && activityLogPopupView.isVisible()) {
        activityLogPopupView.destroy()
      }
      liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
      startVehiclesUpdate = false
      if (GeoZones().isVisible()) {
        return
      }
      if (searchVehicle && leftPanel.find('.actions-holder').is(':Visible')) {
        startVehiclesUpdate = true
        searchVehicle.resetActionHolder()
      }
      var topBar = content.find('.top-bar')
      driverWorkingHoursTodayPopupView.hideActivePopup()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()
      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500)
        leftPanel.removeClass('active').parent().removeClass('active')
        time = 500
      }
      if (topBar.is(':visible')) {
        topBar.animate({
          'margin-left': 0
        }, 500, null)
      }
      if (htmlGroupTabsAllowed) {
        window.groupTabsetView.findSubView('colorPicker').hide()
      }
      leftPanel.delay(time).fadeOut(300, function () {
        leftPanel.find('.slide-button').removeClass('active')
        leftPanel.css('left', 0)
        leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
      })

      activityLog.hide()
      activityLog.removeLogEntryMarker()
      proximity.resetPin()
      wlsMapTools.saveState()
      wls.resetState()
      wlsMapTools.hideToolsFrame()
      wlsMapTools.hide()
      if (GeoZones().isLoaded()) {
        GeoZones().show()
        GeoZones().getTabset().getControls().first().click()

        if (poiManager) {
          // $("[data-tab=poiManager]").find("[data-button=back]").show();
          poiManager.switchDataMode('create')
          poiManager.bindMapClick()
        }
      } else {
        GeoZones().loadHTML(function () {
          GeoZones().setTabset(new Tabset($('[data-tabset="geoZones"]')))
          GeoZones().getHTML().find('.slide-button').off('click').click(function () {
            var slideBtn = $(this)
            slideBtn.toggleClass('active')
            if (slideBtn.hasClass('active')) {
              GeoZones().getHTML().animate({ marginLeft: -GeoZones().getHTML().outerWidth() }, 600)
            } else {
              GeoZones().getHTML().animate({ marginLeft: 0 }, 600)
            }
          })
          GeoZones().getTabset().getControls().filter('[data-target=poiManager]').one('click', function () {
            poiManager = new OOIManager(GeoZones().getTabset().getTab('poiManager'))
            if (aoiManager) {
              aoiManager.hide()
              aoiManager.unbindMapClick()
            }
            poiManager.bindMapClick()
            poiManager.loadAccountList(function () {
              $('[data-tab=poiManager]').find('[data-button=back]').show()
              poiManager.switchDataMode('create')
            })
            $(this).mousedown(function () {
              if ($(this).hasClass('active')) {
                return
              }
              if (poiManager) {
                poiManager.switchDataMode('create')
                poiManager.bindMapClick()
              }
              if (aoiManager) {
                aoiManager.hide()
                aoiManager.unbindMapClick()
              }
            })
          })
          GeoZones().getTabset().getControls().filter('[data-target=aoiManager]').one('click', function () {
            aoiManager = new AOIManager(GeoZones().getTabset().getTab('aoiManager'))
            if (poiManager) {
              poiManager.hide()
              poiManager.unbindMapClick()
            }
            aoiManager.bindMapClick()
            aoiManager.loadAccountList(function () {
              $('[data-tab=aoiManager]').find('[data-button=back]').show()
              aoiManager.switchDataMode('create')
            })
            $(this).mousedown(function () {
              if ($(this).hasClass('active')) {
                return
              }
              if (poiManager) {
                poiManager.hide()
                poiManager.unbindMapClick()
              }
              if (aoiManager) {
                aoiManager.switchDataMode('create')
                aoiManager.bindMapClick()
              }
            })
          })
          GeoZones().getTabset().getControls().filter('[data-target=geoFenceAlert]').one('click', function () {
            geoFenceAlertView = new GeoFenceAlertView({ el: GeoZones().getTabset().getTab('geoFenceAlert') })

            if (poiManager) {
              poiManager.hide()
              poiManager.unbindMapClick()
            }
            if (aoiManager) {
              aoiManager.hide()
              aoiManager.unbindMapClick()
            }
            $(this).mousedown(function () {
              if ($(this).hasClass('active')) {
                return
              }
              if (poiManager) {
                poiManager.hide()
                poiManager.unbindMapClick()
              }
              if (aoiManager) {
                aoiManager.hide()
                aoiManager.unbindMapClick()
              }
            })
          })
          GeoZones().getTabset().getControls().filter('[data-target=geoMonitor]').one('click', function () {
            geoMonitorAlertView = new GeoMonitorAlertView()

            if (poiManager) {
              poiManager.hide()
              poiManager.unbindMapClick()
            }
            if (aoiManager) {
              aoiManager.hide()
              aoiManager.unbindMapClick()
            }
            $(this).mousedown(function () {
              if ($(this).hasClass('active')) {
                return
              }
              if (poiManager) {
                poiManager.hide()
                poiManager.unbindMapClick()
              }
              if (aoiManager) {
                aoiManager.hide()
                aoiManager.unbindMapClick()
              }
            })
          })
          GeoZones().show(function () {
            GeoZones().getTabset().getControls().first().click()
          })
        })
      }
      if (reportHandler.hasActiveReport() && !addPoiPinByRightClickFromReport && !addPoiPinByRightClickFromJourneyReport && !addPoiPinByRightClickFromTimeOnSiteReport && !addPoiPinByRightClickFromTimeOnSiteReportDetails) {
        reportHandler.hideReport(reportHandler.getActiveReport(), null, !li.hasClass('mapping'))
      }
      handler.hidePopup()
    }

    function initBindMapEvent () {
      if (mapSource && mapSource.startsWith('MSVE')) {
        mapClickhandler = Microsoft.Maps.Events.addHandler(window.mapCanvas.map, 'rightclick', function (e) {
          handler.hidePopup()
          if ($('.user-menu .categories').find('.mapping').hasClass('active')) {
            addPoiPinByRightClickFromMap = true
            addPoiPinByRightClickFromReport = false
            addPoiPinByRightClickFromJourneyReport = false
            addPoiPinByRightClickFromActivityLog = false
            addPoiPinByRightClickFromTimeOnSiteReport = false
            addPoiPinByRightClickFromTimeOnSiteReportDetails = false

            if (e.targetType == 'pushpin') {
              return
            }
            var point = new Microsoft.Maps.Point(e.getX(), e.getY())
            var loc = window.mapCanvas.map.tryPixelToLocation(point)
            var location = new Microsoft.Maps.Location(loc.latitude, loc.longitude)
            var left = e.pageX
            var top = e.pageY

            handler.lat = location.latitude
            handler.lon = location.longitude

            handler.showPopup(left, top)
          }
        })
      } else if (mapSource && mapSource === 'GOOGLEMAPS') {
        google.maps.event.addListener(window.mapCanvas.map, 'rightclick', function (e) {
          handler.hidePopup()
          if ($('.user-menu .categories').find('.mapping').hasClass('active')) {
            addPoiPinByRightClickFromMap = true
            addPoiPinByRightClickFromReport = false
            addPoiPinByRightClickFromJourneyReport = false
            addPoiPinByRightClickFromActivityLog = false
            addPoiPinByRightClickFromTimeOnSiteReport = false
            addPoiPinByRightClickFromTimeOnSiteReportDetails = false

            if (e.targetType == 'pushpin') {
              return
            }

            var left = e.pixel.x
            var top = e.pixel.y

            handler.lat = e.latLng.lat()
            handler.lon = e.latLng.lng()

            handler.showPopup(left, top)
          }
        })
      }

      $('#wlsmap, .left-col, .map-tools, .left-panel, .user-menu').off('click').click(function (e) {
        if ($HTML.is(':visible')) {
          handler.hidePopup()
          handler.lat = null
          handler.lon = null
          addPoiPinByRightClickFromMap = false
          addPoiPinByRightClickFromReport = false
          addPoiPinByRightClickFromJourneyReport = false
          addPoiPinByRightClickFromActivityLog = false
          addPoiPinByRightClickFromTimeOnSiteReport = false
          addPoiPinByRightClickFromTimeOnSiteReportDetails = false
        }
      })
      $HTML.off('click').click(function () {
        handler.goToPOIAOI('fromMap')
      })
    }

    initBindMapEvent()
  }

  function VideoPlayer ($HTML, options) { // class Video player
    var video
    var $video
    var $pauseBtn
    var $playBtn
    var $bigPlayBtn
    var $time
    var $bufferedProgress
    var $timeProgress
    var $pauseBtn
    var player = this
    var $timeSlider
    var videoDuration
    var isPausedBeforeSeeking
    this.setVideoProp = function (name, value) {
      $video.prop(name, value)
    }
    this.setDownloadBtnSrc = function (url) {
      $HTML.find('.download-button').attr('href', url)
    }
    this.getVideoProp = function (name) {
      return $video.prop(name)
    }
    this.play = function () {
      video.play()
    }
    this.pause = function () {
      video.pause()
    }
    function fixSliderWidth () {
      $HTML.find('.slider').each(function () {
        var handle = $(this).find('.ui-slider-handle')
        $(this).width($(this).width() - handle.width() + 2)
      })
    }
    this.show = function () {
      $HTML.show()
    }
    this.hide = function () {
      $HTML.hide()
    }
    function formatSecondsToMS (duration) {
      var seconds = duration % 60
      var minutes = duration / 60
      seconds = Math.floor(seconds)
      minutes = Math.floor(minutes)
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      return minutes + ':' + seconds
    }
    function updateTime () {
      $time.text(formatSecondsToMS(video.currentTime))
      $timeSlider.slider('option', 'value', video.currentTime / videoDuration * $timeSlider.slider('option', 'max'))
    }
    function toggleFullScreen () {
      var HTML = $HTML[0]
      if (!document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) { // current working methods
        if (HTML.requestFullscreen) {
          HTML.requestFullscreen()
        } else if (HTML.msRequestFullscreen) {
          HTML.msRequestFullscreen()
        } else if (HTML.mozRequestFullScreen) {
          HTML.mozRequestFullScreen()
        } else if (HTML.webkitRequestFullscreen) {
          HTML.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        }
        $HTML.addClass('fullscreen-active')
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
        $HTML.removeClass('fullscreen-active')
      }
    }
    function init () {
      $video = $HTML.find('video')
      video = $video[0]
      $bigPlayBtn = $HTML.find('.play.big')
      $playBtn = $HTML.find('.play')
      $pauseBtn = $HTML.find('.pause')
      $time = $HTML.find('.time')
      $bufferedProgress = $HTML.find('.buffered-line')
      $timeSlider = $HTML.find('.progress-bar .slider')

      if (options) {
        $(video).prop(options)
      }
      $playBtn.click(function () {
        if (video.ended) {
          video.currentTime = 0
        }
        player.play()
      })
      $pauseBtn.click(function () {
        player.pause()
      })
      $video.on('play', function () {
        $playBtn.hide()
        $pauseBtn.css('display', 'block')
      })
      $video.on('pause', function () {
        if (isPausedBeforeSeeking === false) return
        $pauseBtn.hide()
        $playBtn.not('.big').css('display', 'block')
      })
      $video.on('loadedmetadata', function () {
        videoDuration = video.duration
        $time.text(formatSecondsToMS(videoDuration))
        $timeSlider.slider({
          range: 'min',
          min: 0,
          max: videoDuration * 1000,
          value: 0,
          start: function (e, ui) {
            isPausedBeforeSeeking = video.paused
            video.pause()
            $(document).one('mouseup', function () {
              if (!isPausedBeforeSeeking && !video.ended) {
                isPausedBeforeSeeking = null
                video.play()
              }
            })
          },
          slide: function (e, ui) {
            video.currentTime = ui.value / $timeSlider.slider('option', 'max') * videoDuration
            updateTime()
          }
        })
        fixSliderWidth()
        $video.on('progress', function () {
          var percentage = (video.buffered.end(video.buffered.length - 1) / videoDuration * 100)
          $bufferedProgress.css('width', percentage + '%')
          if (percentage == 100) {
            $video.off('progress')
          }
        })
      })
      $video.on('ended', function () {
        $pauseBtn.hide()
        $playBtn.not('.big').css('display', 'block')
      })
      $video.on('timeupdate', updateTime)
      $HTML.find('.fullscreen').click(toggleFullScreen)
    }
    init()
  }
  function Counter ($HTML) { // counter class
    var $input
    var $arrowUp
    var $arrowDown
    var count

    this.getInput = function () {
      return $input
    }
    this.getHTML = function () {
      return $HTML
    }
    function init () {
      $input = $HTML.find('input:text')
      $arrowUp = $HTML.find('.arrow-up')
      $arrowDown = $HTML.find('.arrow-down')
      count = 0

      $arrowUp.click(function () {
        var val = $input.val()
        $input.val(++val)
      })
      $arrowDown.click(function () {
        var val = $input.val()
        if (val - 1 < 0) {
          val = 0
        } else {
          --val
        }
        $input.val(val)
      })
      new SafeInput($input, ['digits', 'edit', 'navigation'])
    }
    init()
  }
  function GeoZones () { // Geo zones singleton
    if (GeoZones.prototype._singletonInstance) {
      return GeoZones.prototype._singletonInstance
    }
    GeoZones.prototype._singletonInstance = this

    var $HTML
    var loaded = false
    var visible = false
    var tabset
    this.show = function (callback) {
      visible = true
      $HTML.fadeIn(300, callback)
    }
    this.hide = function (callback) {
      visible = false
      window.mapCanvas.disableDrawControl()
      $HTML.fadeOut(300, callback)
    }
    this.isLoaded = function () {
      return loaded
    }
    this.isVisible = function () {
      return visible
    }
    this.getHTML = function () {
      return $HTML
    }
    this.setTabset = function (tabsetInst) {
      tabset = tabsetInst
    }
    this.getTabset = function () {
      return tabset
    }
    this.loadHTML = function (callback) {
      $.post('/getGeozonesWindowPopup.do').done(function (data) {
        $HTML = $(data)
        $('#content').append($HTML)
        $HTML = $('.tabset-report-panel')
        loaded = true
        if (callback) callback()
      })
    }
  }
  new GeoZones()
  function OOIManager ($HTML) { // OOI manager class
    var ooiManager = this
    var $selects
    var $colorpickers
    var counter
    var $form
    var $searchPostcode
    var $postcode
    var $poiListBtn
    var $poiCategoryBtn
    var $poiListPopup
    var $poiCategoryDrop
    var $poiCategoryPopup
    var poiListGrid
    var poiCategoryListGrid
    var poiListTrTpl
    var poiCategoryTrTpl
    var poiIdList
    var poiCategoryIdList
    var poiListJson
    var poiCategoryListJson
    var poiCategoryJson
    var poiAccountJson
    var $inputs
    var $titles
    var $cancelBtn
    var $backBtn
    var dataMode
    var poiPin
    var mapClickhandler

    function init () {
      $selects = $HTML.find('[data-select]')
      $colorpickers = $HTML.find('[data-colorpicker]')
      counter = new Counter($HTML.find('[data-counter]'))
      $form = $HTML.find('.ooi-data form')
      $searchPostcode = $HTML.find('form.search')
      $postcode = $searchPostcode.find('input:text')
      $poiListBtn = $HTML.find('[data-button=poiList]')
      $poiCategoryBtn = $HTML.find('[data-button=poiCategory]')
      $inputs = $HTML.find('[data-input]')
      $titles = $HTML.find('[data-title]')
      $cancelBtn = $HTML.find('[data-button=cancel]')
      $backBtn = $HTML.find('[data-button=back]')
      poiIdList = {}
      poiCategoryIdList = {}
      poiPin = null

      poiListTrTpl = new Template(
        '<tr data-id="${id}">' +
        '<td data-sortValue="${id}">${id}</td>' +
        '<td data-sortValue="${categoryDtoDescr}">${categoryDtoDescr}</td>' +
        '<td data-sortValue="${description}">${description}</td>' +
        '<td>${lat}</td>' +
        '<td>${lon}</td>' +
        '<td>${radius}</td>' +
        '<td data-sortValue="${accountDtoDescr}">${accountDtoDescr}</td>' +
        '<td>${status}</td>' +
        '<td class="with-button">' +
        '<div class="button" data-button="activateDeactivate">' +
        '<span>${statusAction}</span>' +
        '</div>' +
        '</td>' +
        '<td class="with-ico">' +
        '<span class="edit" data-button="edit"></span>' +
        '</td>' +
        '</tr>'
      )
      poiCategoryTrTpl = new Template(
        '<tr data-id="${id}">' +
        '<td>${id}</td>' +
        '<td>${descr}</td>' +
        '<td>${accountDtoDescr}</td>' +
        '<td class="with-button">' +
        '<span class="delete" data-button="delete"></span>' +
        '</td>' +
        '<td class="with-ico">' +
        '<span class="edit" data-button="edit"></span>' +
        '</td>' +
        '</tr>'
      )
      $colorpickers.colorpicker({
        width: 'auto',
        open: function (event, ui) {
          var $selectDropdown = $(this).colorpicker('menuWidget').parent()
          $selectDropdown.find('ul').width($(this).next().outerWidth() - 8)
        },
        create: function (e, ui) {
          var selectDropdown = $(this).colorpicker('menuWidget').parent()
          $(this).parent().append(selectDropdown)
        },
        select: function () {
          if (poiPin) {
            ooiManager.addPoiPin(parseFloat($inputs.filter('[data-input=lon]').val()), parseFloat($inputs.filter('[data-input=lat]').val()))
          }
        }
      })
      counter.getHTML().find('.arrow-up,.arrow-down').click(function () {
        if (poiPin) {
          ooiManager.addPoiPin(parseFloat($inputs.filter('[data-input=lon]').val()), parseFloat($inputs.filter('[data-input=lat]').val()))
        }
      })
      counter.getInput().keyup(function () {
        if (poiPin) {
          ooiManager.addPoiPin(parseFloat($inputs.filter('[data-input=lon]').val()), parseFloat($inputs.filter('[data-input=lat]').val()))
        }
      })
      $inputs.not('[data-input=description]').keyup(function () {
        if (poiPin) {
          ooiManager.addPoiPin(parseFloat($inputs.filter('[data-input=lon]').val()), parseFloat($inputs.filter('[data-input=lat]').val()))
        }
      })
      $form.submit(sendFormData)
      $cancelBtn.click(function () {
        ooiManager.switchDataMode('create')
        $poiListPopup.$el.find('tbody tr.active').removeClass('active')
      })
      $backBtn.off('click').click(function () {
        returnToMapping()
      })
      $selects.filter('[data-select=accountDto]').on('selectmenuselect', function (e, ui) {
        var lastViewedAccountInMapping = $.cookie('lastViewedAccount')
        if ($selects.filter('[data-select=categoryDto]').hasClass('hasSelect')) {
          $selects.filter('[data-select=categoryDto]').selectmenu('disable')
        }
        sendCurrentAccount(ui.item.element.val(), function () {
          ooiManager.loadCategoryList(function () {
            if (dataMode == 'edit' && !('bubbles' in e)) {
              // $selects.filter("[data-select=categoryDto]").val(poiIdList[$poiListPopup.find("tr.active").attr("data-id")].categoryDto.id).selectmenu("refresh");
              $selects.filter('[data-select=categoryDto]').val($poiListPopup.findSubView('poiList')
                .findSubView('compactReportTableGrid')
                .model.get('tableRow').get($poiListPopup.$el.find('tr.active').attr('data-id'))
                .attributes.categoryDto.id).selectmenu('refresh')
            }
            $selects.filter('[data-select=categoryDto]').selectmenu('enable')
            $.cookie('lastViewedAccount', lastViewedAccountInMapping)
          })
        })
      })
      new SafeInput($inputs.filter('[data-input=lat],[data-input=lon]'), ['digits', 'period', 'subtract', 'navigation', 'edit', 'f5'])
      $poiListBtn.click(function () {
        $poiListPopup = new PoiListAlertView()
      })

      // POI categories popup events
      $poiCategoryBtn.click(function () {
        if ($poiCategoryPopup) {
          ooiManager.loadPoiCategoryList(function () {
            generatePoiCategoryTable()
            ooiManager.showPoiCategoryPopup(function () {
              ooiManager.reloadScroll(poiCategoryListGrid)
              if ($poiListPopup) {
                $poiListPopup.closeBtnHandler()
              }
            })
          })
        } else {
          ooiManager.loadPoiCategoryPopup(function () {
            ooiManager.loadPoiCategoryList(function () {
              var edit = false
              var currCategory
              var $accountDtoSelect = $poiCategoryDrop.find('[data-select=accountDto]')
              var $dropTitles = $poiCategoryDrop.find('[data-title]')

              generatePoiCategoryTable()
              poiCategoryListGrid = new TableGrid($poiCategoryPopup.find('.info-holder'))
              poiCategoryIdList = generateIdList(poiCategoryListJson)
              ooiManager.showPoiCategoryPopup(function () {
                ooiManager.reloadScroll(poiCategoryListGrid)
                if ($poiListPopup) {
                  $poiListPopup.closeBtnHandler()
                }
              })
              $poiCategoryDrop.show()
              initSelect.apply(poiAccountJson, [$poiCategoryDrop.find('[data-select=accountDto]')])
              initSelectScroll($poiCategoryDrop.find('.select-holder:has([data-select=accountDto]) .scroll-content'))
              $poiCategoryDrop.hide()
              $poiCategoryPopup.find('[data-button=newCategory]').click(function () {
                edit = false
                $poiCategoryDrop.css({ 'z-index': '2', top: '', left: '', right: '' }).show()
                $dropTitles.hide().filter('[data-title=add]').show()
                $poiCategoryDrop.find('[data-input=categoryDto]').val('').focus()
                $accountDtoSelect.val($accountDtoSelect.find('option:first').val())
                $poiCategoryPopup.find('tbody tr.active').removeClass('active')
                $accountDtoSelect.selectmenu('refresh')
              })
              $poiCategoryPopup.find('[data-button=cancel],[data-button=done]').click(function () {
                ooiManager.hidePoiCategoryPopup()
                $poiCategoryDrop.hide()
                ooiManager.loadCategoryList()
                $poiCategoryPopup.find('tbody tr.active').removeClass('active')
              })
              $poiCategoryDrop.find('[data-button=cancel]').click(function () {
                $poiCategoryDrop.hide()
                $poiCategoryPopup.find('tbody tr.active').removeClass('active')
              })
              $poiCategoryPopup.on('click', '[data-button=edit]', function (e) {
                var $editBtn = $(this)
                var $tr = $(this).closest('tr')
                var categoryId = $tr.attr('data-id')

                currCategory = poiCategoryIdList[categoryId]

                $poiCategoryDrop.find('[data-input=categoryDto]').val(currCategory.descr).focus()
                $accountDtoSelect.val(currCategory.accountDto.id).selectmenu('refresh')
                $tr.addClass('active').siblings().removeClass('active')
                $poiCategoryDrop.css({ top: $editBtn.offset().top - 5, left: '516px', 'z-index': '2' }).show()
                $dropTitles.hide().filter('[data-title=edit]').show()
                edit = true
              })
              $poiCategoryPopup.on('click', '[data-button=delete]', function (e) {
                var $tr = $(this).closest('tr')
                // var confirmDialog = confirm("Are you sure you want to delete this category?");

                systemAlert.initDialog('Are you sure you want to delete this category?', function (value) {
                  if (value) {
                    if ($tr.hasClass('active')) {
                      $tr.removeClass('active')
                      $poiCategoryDrop.hide()
                    }
                    ooiManager.deletePoiCategory($tr.attr('data-id'), function () {
                      $tr.remove()
                      ooiManager.reloadScroll(poiCategoryListGrid)
                    })
                  }
                })
              })

              $poiCategoryDrop.find('form').submit(function (e) {
                var data = getFormData($poiCategoryDrop)
                e.preventDefault()
                if (checkFormValidity($(this))) {
                  return false
                }
                if (edit) {
                  data = {
                    id: currCategory.id,
                    descr: $poiCategoryDrop.find('[data-input=categoryDto]').val(),
                    accountDto: {
                      id: $accountDtoSelect.val(),
                      descr: $accountDtoSelect.find('option[value=' + $accountDtoSelect.val() + ']').text()
                    }
                  }
                  currCategory.accountDtoDescr = currCategory.accountDto.descr
                } else {
                  $dropTitles.hide().filter('[data-title=create]').show()
                  data = {
                    id: 0,
                    descr: data.categoryDto,
                    accountDto: {
                      id: data.accountDto,
                      descr: $poiCategoryDrop.find('[data-select=accountDto] option[value=' + data.accountDto + ']').text()
                    }
                  }
                  $poiCategoryDrop.hide()
                }
                ooiManager.createEditPoiCategory(data, function (json) {
                  json.accountDtoDescr = json.accountDto.descr
                  if (edit) {
                    $poiCategoryPopup.find('tbody tr[data-id=' + json.id + ']').html($(poiCategoryTrTpl.compile(json)).html()).removeClass('active')
                    $poiCategoryDrop.hide()
                  } else {
                    if ($poiCategoryPopup.find('tbody [data-id=' + json.id + ']').size()) {
                      return
                    }
                    $poiCategoryPopup.find('tbody').append(poiCategoryTrTpl.compile(json))
                  }
                  poiCategoryIdList[json.id] = json
                  ooiManager.reloadScroll(poiCategoryListGrid)
                  systemAlert.initMsg('Successfully saved')
                })
              })
            })
          })
        }
      })
      // POI categories popup events

      function geocodeCallback (result) {
        var redMarker = new Image()
        var len = result.length
        var options = ''

        redMarker.src = 'img/markers/marker_red.png'
        if (!result || len == 0) {
          alert('There were no locations found')
          return
        }
        for (var i = 0; i < len; i++) {
          options += "<li data-id='" + i + "'>" + result[i].description + '</li>'
        }
        options = $(options)
        $searchPostcode.find('ul').html(options)
        function clickHandler (e) {
          var markerData = e.data.markerData
          ooiManager.addPoiPin(markerData.lon, markerData.lat)
          $searchPostcode.removeClass('active')
          $inputs.filter('[data-input=lat]').val(markerData.lat)
          $inputs.filter('[data-input=lon]').val(markerData.lon)
          setTimeout(rezoomMapFlx, 1500)
        }
        for (var i = 0; i < len; i++) {
          options.eq(i).on('click', { markerData: result[options[i].getAttribute('data-id')] }, clickHandler)
        }
        options.removeAttr('data-id')
        $searchPostcode.addClass('active')
        initSelectScroll($('.scroll-content', $searchPostcode))
        setTimeout(rezoomMapFlx, 1500)
      }
      $searchPostcode.submit(function (e) {
        e.preventDefault()
        if ($.trim($postcode.val())) {
          window.mapCanvas.geocode($postcode.val(), geocodeCallback)
        }
      })
      var topBar = $('#content').find('.top-bar')
      if (!topBar.is(':visible') && !topBar.is(':hidden')) {
        topBar.show()
      }
    }
    function returnToMapping () {
      createdPoiPin = window.createdPoiPin = false
      addPoiPinByRightClickFromMap = false
      $backBtn.hide()

      addPoi.lat = null
      addPoi.lon = null

      if (JourneyDetails().isVisible()) {
        JourneyDetails().hide()
      }
      if (searchVehicle) {
        searchVehicle.resetTableResults()
        if (searchVehicle.isVisible()) {
          searchVehicle.hideSearchResult()
        }
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }
      }

      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      if (sendMessage.isVisible()) {
        sendMessage.destroyEl()
      }
      $('.multiple-select-btn').removeClass('active')
      if ($('.select-to-sms-popup').css('visibility') === 'visible') {
        $('.select-to-sms-popup').css('visibility', 'hidden')
        if (mapSource && mapSource.startsWith('MSVE')) {
          window.mapCanvas.map.entities.pop()
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          window.mapCanvas.cleanUp()
        }
        window.wlsMapTools.multipleSelectToolIsActive = false
      }
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }

      if (view.getActiveState() !== 'dual') {
        view.state = 'fleet'
      }

      GeoZones().hide()
      if (poiManager) {
        poiManager.hide()
      }
      if (aoiManager) {
        aoiManager.hide()
      }

      if (addPoiPinByRightClickFromReport || addPoiPinByRightClickFromJourneyReport || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
        var $dataPopup

        if (addPoiPinByRightClickFromTimeOnSiteReportDetails) {
          $dataPopup = $(".time-on-site-details-popup[data-popup='journeyDetails']")
        } else {
          $dataPopup = $(".journey-details-popup[data-popup='journeyDetails']")
        }

        var $dataReport = $('.report[data-report]')
        var li = $('.user-menu .categories li.reports')

        li.addClass('active')
        activeMenuItem = li
        li.siblings().removeClass('active')
        li.siblings().removeClass('pressed')

        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy()
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
        startVehiclesUpdate = true

        if (addPoiPinByRightClickFromReport || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
          $dataPopup.show()
          //                        $dataPopup.showSnailTrail();
        }

        if (addPoiPinByRightClickFromReport) {
          reportHandler.getActiveReport().findSubView('report').findSubView('snailTrailPopup').findSubView('snailTrailTableGrid').removeSnailTrailFromMap()
          reportHandler.getActiveReport().findSubView('report').findSubView('snailTrailPopup').findSubView('snailTrailTableGrid').showSnailTrailOnMap()
        }

        $dataReport.show()

        selectedGroup = $.cookie('lastViewedGroup')
        addPoiPinByRightClickFromReport = false
        addPoiPinByRightClickFromJourneyReport = false
        addPoiPinByRightClickFromTimeOnSiteReport = false
        addPoiPinByRightClickFromTimeOnSiteReportDetails = false
      } else {
        var li = $('.user-menu .categories li.mapping')
        var time = 0

        li.addClass('active')
        activeMenuItem = li
        li.siblings().removeClass('active')
        li.siblings().removeClass('pressed')
        selectedGroup = $.cookie('lastViewedGroup')

        if (JourneyDetails().getSnailTrail().isOnMap()) {
          JourneyDetails().getSnailTrail().removeSnailTrailFromMap()
        }

        if (leftPanel.find('.right-col').is(':visible')) {
          return
        }

        startVehiclesUpdate = true

        if (userPrefs.htmlGroupTabsAllowed) {
          var groupId = $.cookie('lastViewedGroup')

          $.cookie('lastViewedGroup', null) // reset cookie
          $.cookie('lastViewedGroup', groupId)
          groupTabsetView.render()
        } else {
          wls.requestCars($.cookie('lastViewedGroup'), function () {
            wls.removeCars()
            wls.restoreSelectionMenuState()
            $('.left-panel').fadeIn(400, function () {
              $('.left-panel .right-col .vehicle-holder .scroll-content').triggerHandler('resize', [$('.left-panel .vehicle-holder .slidee').height()])
            })
          })
        }

        if (leftPanel.hasClass('active')) {
          leftPanel.parent().animate({ 'margin-left': 0 }, 500)
          leftPanel.removeClass('active').parent().removeClass('active')
          time = 500
        }
        if (leftPanel.find('.actions-holder').is(':Visible')) {
          searchVehicle.resetActionHolder()
        }
        if (leftPanel.find('.control-panel,.control-panel+.row').is(':visible') && view.getActiveState() != 'fleet') {
          leftPanel.find('.control-panel,.control-panel+.row').hide()
        }
        if (leftPanel.hasClass('no-group-tab') && settings.data.htmlGroupTabsAllowed) {
          leftPanel.removeClass('no-group-tab').addClass('has-group-tab')
        }
        leftPanel.delay(time).fadeOut(300, function () {
          leftPanel.find('.slide-button').removeClass('active')
          leftPanel.css('left', 0)
          leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
          leftPanel.fadeIn(300, function () {
            if (view.getActiveState() !== 'dual') {
              view.switchStateTo('fleet')
            } else {
              view.switchStateTo('dual')
            }
          })
        })
      }

      addPoiPinByRightClickFromReport = false
      addPoiPinByRightClickFromJourneyReport = false
      addPoiPinByRightClickFromTimeOnSiteReport = false
      addPoiPinByRightClickFromTimeOnSiteReportDetails = false
    }
    this.switchDataMode = function (_mode) {
      dataMode = _mode
      if (_mode == 'create') {
        $cancelBtn.hide()

        if (addPoiPinByRightClickFromMap || addPoiPinByRightClickFromReport || addPoiPinByRightClickFromJourneyReport || addPoiPinByRightClickFromActivityLog || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
          createPoiPin(addPoi.lon, addPoi.lat)
        } else {
          setDefaultValues()
        }
        $('[data-tab=poiManager]').find('[data-button=back]').show()
        GeoZones().getHTML().find('.slide-button').removeClass('active').end().css('margin-left', '0')
      } else {
        if ($('[data-tab=poiManager]').find('[data-button=back]').is(':visible')) {
          $('[data-tab=poiManager]').find('[data-button=back]').hide()
        }
        $cancelBtn.show()
      }
      $titles.hide().filter('[data-title=' + _mode + ']').show()
    }
    function generateIdList (json) {
      var idList = {}
      var len = json.data.length
      for (var i = 0; i < len; i++) {
        idList[json.data[i].id] = json.data[i]
      }
      return idList
    }
    this.bindMapClick = function () {
      if (mapSource && mapSource.startsWith('MSVE')) {
        mapClickhandler = Microsoft.Maps.Events.addHandler(window.mapCanvas.map, 'click', function (e) {
          if (e.targetType == 'pushpin') {
            return
          }
          var point = new Microsoft.Maps.Point(e.getX(), e.getY())
          var loc = window.mapCanvas.map.tryPixelToLocation(point)
          var location = new Microsoft.Maps.Location(loc.latitude, loc.longitude)
          var lat = location.latitude
          var lon = location.longitude

          if (addPoiPinByRightClickFromMap || addPoiPinByRightClickFromReport || addPoiPinByRightClickFromJourneyReport || addPoiPinByRightClickFromActivityLog || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
            addPoi.lat = lat
            addPoi.lon = lon
          }

          $inputs.filter('[data-input=lon]').val(lon)
          $inputs.filter('[data-input=lat]').val(lat)
          ooiManager.addPoiPin(lon, lat)
        })
      } else if (mapSource && mapSource === 'GOOGLEMAPS') {
        window.mapCanvas.map.addListener('click', (e) => {
          console.log(e)
        })
        mapClickhandler = google.maps.event.addListener(window.mapCanvas.map, 'click', function (event) {
          console.log(event)

          var location = event.latLng.toJSON()
          var lat = location.lat
          var lon = location.lng

          if (addPoiPinByRightClickFromMap || addPoiPinByRightClickFromReport || addPoiPinByRightClickFromJourneyReport || addPoiPinByRightClickFromActivityLog || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
            addPoi.lat = lat
            addPoi.lon = lon
          }

          $inputs.filter('[data-input=lon]').val(lon)
          $inputs.filter('[data-input=lat]').val(lat)
          ooiManager.addPoiPin(lon, lat)
        })
      }
    }
    this.unbindMapClick = function () {
      if (mapClickhandler) {
        if (mapSource && mapSource.startsWith('MSVE')) {
          Microsoft.Maps.Events.removeHandler(mapClickhandler)
        } else {
          google.maps.event.clearListeners(window.mapCanvas.map, 'click')
        }
      }
    }
    this.reloadScroll = function (tableGrid) {
      var $tableHTML = tableGrid.getTable()
      var tableHeight = $tableHTML.find('.info.content').height()
      var $frame = $tableHTML.find('.scroll-content')
      var $wrap = $frame.parent()
      $frame.attr('style', '')

      if ($wrap.height() < tableHeight) {
        $frame.height($wrap.outerHeight(true))
        if (!$frame.hasClass('hasScroll')) {
          initGridScroll($frame)
        }
        $frame.sly('reload')
        $wrap.find('.scrollbar').show()
      } else {
        $frame.sly('destroy')
        $wrap.find('.scrollbar').hide()
        $frame.attr('style', '')
        $frame.removeClass('hasScroll')
      }
    }
    function initGridScroll ($frame) {
      $frame.sly({
        horizontal: 0,
        speed: 300,
        easing: 'linear',
        scrollBar: $frame.parent().find('.scrollbar'),
        scrollBy: 100,
        dragHandle: 1,
        dynamicHandle: 1,
        clickBar: 1
      })
      $frame.addClass('hasScroll')
    }
    function initSelect (currSelect) {
      var options = ''

      $.each(this.data, function (i, obj) {
        options += '<option value="' + obj.id + '">' + obj.descr + '</option>'
      })

      currSelect.html(options)
      currSelect.selectmenu({
        width: 'auto',
        open: function (event, ui) {
          var $selectDropdown = $(this).selectmenu('menuWidget').parent()
          $selectDropdown.find('ul').width($(this).next().outerWidth())
        },
        create: function (e, ui) {
          var $selectDropdown = $(this).selectmenu('menuWidget').parent()
          var $scrollFrame = $('<div class="scroll-frame">' +
            '<div class="scrollbar vertical">' +
            '<div class="handle">' +
            '<div class="mousearea"></div>' +
            '<div class="bg"></div>' +
            '</div>' +
            '</div>' +
            '<div class="scroll-content">' +
            '<div class="slidee"></div>' +
            '</div>' +
            '</div>')
          $(this).parent().append($selectDropdown)
          $selectDropdown.append($scrollFrame)
          $scrollFrame.find('.slidee').append($selectDropdown.find('ul'))
          $selectDropdown.find('ul').width($(this).next().outerWidth())
          $(this).addClass('hasSelect')
        }
      })
      currSelect.selectmenu('refresh')

      if (this.data.length < 2 && $(currSelect).attr('data-select') === 'accountDto') {
        currSelect.parents('.row').hide()
      }
    }
    function initSelectScroll ($frame) {
      var $wrap = $frame.parent()
      var ulHeight
      var drop = $frame.closest('.ui-selectmenu-menu')

      drop.show()
      ulHeight = $frame.find('ul').css('height', 'auto').height()
      if ($wrap.height() < ulHeight) {
        $frame.height($wrap.outerHeight(true))
        if (!$frame.hasClass('hasScroll')) {
          $frame.sly({
            speed: 300,
            easing: 'linear',
            scrollBar: $wrap.find('.scrollbar'),
            scrollBy: 100,
            dragHandle: 1,
            dynamicHandle: 1,
            clickBar: 1
          })
          $frame.addClass('hasScroll')
        }
        $frame.sly('reload')
        $wrap.find('.scrollbar').show()
      } else {
        $frame.sly('destroy')
        $wrap.find('.scrollbar').hide()
        $frame.attr('style', '')
        $frame.removeClass('hasScroll')
      }
      drop.attr('style', '')
    }
    this.loadAccountList = function (callback) {
      $.post('/getAccountListForCurrentUser.do').done(function (json) {
        poiAccountJson = json
        initSelect.apply(json, [$selects.filter('[data-select=accountDto]')])
        if (getLastViewedAccount()) {
          $selects.filter('[data-select=accountDto]').val(getLastViewedAccount()).selectmenu('refresh')
        }
        initSelectScroll($selects.filter('[data-select=accountDto]').parent().find('.scroll-content'))
        if (callback) callback()
      })
    }
    this.deletePoiCategory = function (categoryId, callback) {
      $.ajax({
        type: 'POST',
        url: '/deletePoiCategory.do',
        data: JSON.stringify({ id: categoryId, active: false }),
        contentType: 'application/json; charset=utf-8',
        success: function (json) {
          if (callback) callback(json)
        }
      })
    }
    this.loadCategoryList = function (callback) {
      $.post('/getPoiCategoryListForCurrentAccount.do').done(function (json) {
        poiCategoryJson = json
        initSelect.apply(json, [$selects.filter('[data-select=categoryDto]')])
        initSelectScroll($selects.filter('[data-select=categoryDto]').parent().find('.scroll-content'))
        if (callback) callback()
      })
    }
    this.loadOOIList = function () {

    }
    this.loadPoiList = function (callback) {
      $.post('/getPoiForCurrentUser.do', { active: true }).done(function (json) {
        poiListJson = json
        if (callback) callback()
      })
    }
    this.loadPoiCategoryList = function (callback) {
      $.post('/getPoiCategoryListForCurrentUser.do').done(function (json) {
        poiCategoryListJson = json
        if (callback) callback()
      })
    }
    function generatePoiListTable () {
      var data = poiListJson.data
      var dataLen = data.length
      var poiListTr = ''
      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        currData.accountDtoDescr = currData.accountDto.descr
        if (!currData.categoryDto || currData.categoryDto.descr == 'No Category') {
          currData.categoryDtoDescr = null
        } else {
          currData.categoryDtoDescr = currData.categoryDto.descr
        }
        if (currData.status == 'Active') {
          currData.statusAction = 'Deactivate'
        } else {
          currData.statusAction = 'Activate'
        }
        poiListTr += poiListTrTpl.compile(currData)
      }
      $poiListPopup.find('tbody').html(poiListTr)
    }
    function generatePoiCategoryTable () {
      var data = poiCategoryListJson.data
      var dataLen = data.length
      var poiCategoryTr = ''
      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        currData.accountDtoDescr = currData.accountDto.descr
        poiCategoryTr += poiCategoryTrTpl.compile(currData)
      }
      $poiCategoryPopup.find('tbody').html(poiCategoryTr)
    }
    this.loadPoiListPopupHTML = function (callback) {
      $.post('/getPoiListWindowPopup.do').done(function (HTML) {
        $poiListPopup = $(HTML)
        $('#content').append($poiListPopup)
        if (callback) callback()
      })
    }
    this.loadPoiCategoryPopup = function (callback) {
      $.post('/getPoiCategoryWindowPopup.do').done(function (HTML) {
        $poiCategoryPopup = $(HTML).filter('.ooi-categories-popup')
        $poiCategoryDrop = $(HTML).filter('.ooi-category-popup')
        $('#content').append($poiCategoryPopup)
        $('#content').append($poiCategoryDrop)
        if (callback) callback()
      })
    }
    this.createEditPoiCategory = function (data, callback) {
      $.ajax({
        type: 'POST',
        url: '/saveOrUpdatePoiCategory.do',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function (json) {
          if (callback) callback(json)
        }
      })
    }
    this.getPoiListPopup = function () {
      return $poiListPopup
    }
    this.showPoiListPopup = function (callback) {
      $poiListPopup.css({ left: $HTML.outerWidth() })
      $poiListPopup.fadeIn(300, callback)
    }
    this.hidePoiListPopup = function (callback) {
      $poiListPopup.fadeOut(300, callback)
    }
    this.showPoiCategoryPopup = function (callback) {
      $poiCategoryPopup.fadeIn(300, callback)
    }
    this.hidePoiCategoryPopup = function (callback) {
      $poiCategoryPopup.fadeOut(300, callback)
    }
    this.hide = function () {
      if ($poiCategoryPopup) {
        ooiManager.hidePoiCategoryPopup()
      }
      if ($poiListPopup) {
        $poiListPopup.closeBtnHandler()
      }
      if ($poiCategoryDrop) {
        $poiCategoryDrop.hide()
      }
      ooiManager.unbindMapClick()
      removePoiPin()
    }

    this.addPoiPin = function (lon, lat) {
      var img = new Image()
      var pinColour = $colorpickers.filter('[data-colorpicker=pinColour]').val()
      var areaColour = $colorpickers.filter('[data-colorpicker=areaColour]').val()

      if ((!lon || !lat) && (lat !== 0 || lon !== 0)) {
        lon = poiPin._location.longitude
        lat = poiPin._location.latitude
      }
      //                var opacity = undefined;
      poiPin = {}
      console.log('OOIManager: addPoiPin()')
      window.mapCanvas.cleanUp()
      img.src = 'img/markers/marker_' + pinColour + '.png'
      poiPin.lat = lat
      poiPin.lon = lon
      poiPin.typeDescr = 'poi'
      poiPin.areaColour = areaColour
      poiPin.radius = parseInt($inputs.filter('[data-input=radius]').val())
      poiPin.areaColour = getAreaColorsRGB(areaColour, true, true)
      window.mapCanvas.addTextMarkerByObj(img, poiPin, false, false, true, true, true, false,
        function (result) {
          poiPin = result
          if (!window.createdPoiPin) {
            // setTimeout(rezoomMapFlx, 1500);
            setTimeout(() => {
              rezoomMapFlx()
              window.mapCanvas.zoomToMaxExtentToPoint(lon, lat)
            }, 1500)

            createdPoiPin = window.createdPoiPin = true
          } else {
            setTimeout(() => {
              window.mapCanvas.zoomToMaxExtentToPoint(lon, lat)
            }, 1000)
          }
        })
    }
    function removePoiPin () {
      console.log('OOIManager: removePoiPin()')
      window.mapCanvas.cleanUp()
      poiPin = null
    }
    function removePostcodePin () {
      if (window.pin) {
        window.mapCanvas.removeMarker(window.pin)
        window.pin = null
        rezoomMapFlx()
      }
    }
    function sendFormData (e) {
      var data = getFormData($form)

      data.accountDto = { id: data.accountDto }
      data.categoryDto = { id: data.categoryDto }

      e.preventDefault()

      if (checkFormValidity($form)) {
        return false
      }

      // if(dataMode == "edit"){
      //     // data.id = $poiListPopup.find("tbody tr.active").attr("data-id");
      //     data.id = $poiListPopup.$el.find("tr.active").attr("data-id");
      // }

      $.ajax({
        type: 'POST',
        url: '/saveOrUpdatePoi.do',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
      }).done(function (json) {
        systemAlert.initMsg('Successfully saved')

        if (dataMode == 'edit') {
          $poiListPopup.findSubView('poiList').findSubView('compactReportTableGrid').updateRecord(json)
          $poiListPopup.findSubView('poiList').findSubView('compactReportTableGrid').render()
          ooiManager.switchDataMode('create')
        } else {
          if ($poiListPopup) {
            $poiListPopup.findSubView('poiList').loadList()
            // ooiManager.loadPoiList(function(){
            //     generatePoiListTable();
            //     ooiManager.reloadScroll(poiListGrid);
            //     poiIdList = generateIdList(poiListJson);
            // });
          }
          if (addPoiPinByRightClickFromMap || addPoiPinByRightClickFromReport || addPoiPinByRightClickFromJourneyReport || addPoiPinByRightClickFromActivityLog || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
            returnToMapping()
          } else {
            ooiManager.switchDataMode('create')
          }
        }
        wlsPOIHandler.setOOI([])
      })
    }
    function checkFormValidity ($form) {
      var error = false
      $form.find('input:text').each(function () {
        if (this.value.trim() == '' || this.value.trim() == '0' && this.getAttribute('data-input') == 'radius') {
          alert($(this).closest('.row').find('>span').text() + ' is required.')
          error = true
          return false
        }
      })
      return error
    }
    function updateRecord (json) {
      var $tr
      json.accountDtoDescr = json.accountDto.descr
      if (!json.categoryDto || json.categoryDto.descr == 'No Category') {
        json.categoryDtoDescr = null
      } else {
        json.categoryDtoDescr = json.categoryDto.descr
      }
      if (json.status == 'Active') {
        json.statusAction = 'Deactivate'
      } else {
        json.statusAction = 'Activate'
      }
      $tr = $(poiListTrTpl.compile(json))
      $poiListPopup.find('tbody tr[data-id=' + json.id + ']').html($tr.children()).removeClass('active')
      poiIdList[json.id] = json
    }
    function createPoiPin (_lon, _lat) {
      $inputs.filter('[data-input=lat]').val(_lat)
      $inputs.filter('[data-input=lon]').val(_lon)
      $inputs.filter('[data-input=description]').val('')
      $inputs.filter('[data-input=radius]').val('100')

      createdPoiPin = window.createdPoiPin = false

      if (getLastViewedAccount()) {
        $selects.filter('[data-select=accountDto]').val(getLastViewedAccount()).selectmenu('refresh')
      }
      ooiManager.loadCategoryList()
      $colorpickers.val($colorpickers.find('option').first().val()).colorpicker('refresh')

      ooiManager.addPoiPin(_lon, _lat)
    }
    function setDefaultValues () {
      var defLat = 51.507351
      var defLon = -0.127758

      createdPoiPin = window.createdPoiPin = false

      $inputs.filter('[data-input=lat]').val(defLat)
      $inputs.filter('[data-input=lon]').val(defLon)
      $inputs.filter('[data-input=description]').val('')
      $inputs.filter('[data-input=radius]').val('100')
      if (getLastViewedAccount()) {
        $selects.filter('[data-select=accountDto]').val(getLastViewedAccount()).selectmenu('refresh')
      }
      ooiManager.loadCategoryList()
      $colorpickers.val($colorpickers.find('option').first().val()).colorpicker('refresh')

      ooiManager.addPoiPin(defLon, defLat)
    }
    init()
  }
  function AOIManager ($HTML) { // OOI manager class
    var ooiManager = this
    var $selects
    var $colorpickers
    var $form
    var $searchPostcode
    var $postcode
    var $aoiListBtn
    var $aoiCategoryBtn
    var $aoiListPopup
    var $aoiCategoryDrop
    var $aoiCategoryPopup
    var aoiListGrid
    var aoiCategoryListGrid
    var aoiListTrTpl
    var aoiCategoryTrTpl
    var aoiIdList
    var aoiCategoryIdList
    var aoiListJson
    var aoiCategoryListJson
    var aoiCategoryJson
    var aoiAccountJson
    var $inputs
    var $titles
    var $cancelBtn
    var dataMode
    var aoiPin
    var $backBtn
    var mapClickhandler
    var deletePolygonBtn
    var lastActiveAoiId
    var drawControlInited

    function init () {
      $selects = $HTML.find('[data-select]')
      $colorpickers = $HTML.find('[data-colorpicker]')
      $form = $HTML.find('.ooi-data form')
      $searchPostcode = $HTML.find('form.search')
      $postcode = $searchPostcode.find('input:text')
      $aoiListBtn = $HTML.find('[data-button=aoiList]')
      $aoiCategoryBtn = $HTML.find('[data-button=aoiCategory]')
      $inputs = $HTML.find('[data-input]')
      $titles = $HTML.find('[data-title]')
      $cancelBtn = $HTML.find('[data-button=cancel]')
      $backBtn = $HTML.find('[data-button=back]')
      aoiIdList = {}
      aoiCategoryIdList = {}
      aoiPin = null
      lastActiveAoiId = null
      deletePolygonBtn = $HTML.find('[data-button=delete-polygon]')
      aoiListTrTpl = new Template(
        '<tr data-id="${id}">' +
        '<td>${id}</td>' +
        '<td>${categoryDtoDescr}</td>' +
        '<td>${description}</td>' +
        '<td>${accountDtoDescr}</td>' +
        '<td>${status}</td>' +
        '<td class="with-button">' +
        '<div class="button" data-button="activateDeactivate">' +
        '<span>${statusAction}</span>' +
        '</div>' +
        '</td>' +
        '<td class="with-ico">' +
        '<span class="edit" data-button="edit"></span>' +
        '</td>' +
        '</tr>'
      )
      aoiCategoryTrTpl = new Template(
        '<tr data-id="${id}">' +
        '<td>${id}</td>' +
        '<td>${descr}</td>' +
        '<td>${accountDtoDescr}</td>' +
        '<td class="with-button">' +
        '<span class="delete" data-button="delete"></span>' +
        '</td>' +
        '<td class="with-ico">' +
        '<span class="edit" data-button="edit"></span>' +
        '</td>' +
        '</tr>'
      )
      deletePolygonBtn.click(function () {
        window.mapCanvas.deletePolygon()
      })
      $colorpickers.colorpicker({
        width: 'auto',
        open: function (event, ui) {
          var $selectDropdown = $(this).colorpicker('menuWidget').parent()
          $selectDropdown.find('ul').width($(this).next().outerWidth() - 8)
        },
        create: function (e, ui) {
          var selectDropdown = $(this).colorpicker('menuWidget').parent()
          $(this).parent().append(selectDropdown)
        },
        select: function () {
          if (!window.mapCanvas.hasDrawControl()) {
            return
          }
          if (!window.mapCanvas.hasPolygonShape()) {
            if ($(this).attr('data-colorpicker') == 'pinColour') {
              window.mapCanvas.setPinColor(getAreaColorsRGB(this.value, true, true))
            } else {
              window.mapCanvas.setAreaColor(getAreaColorsRGB(this.value, true, true))
            }
          } else {
            if ($(this).attr('data-colorpicker') == 'pinColour') {
              window.mapCanvas.setPolygonPinColor(this.value)
            } else {
              var colourOpacity = $inputs.filter('[data-input=colourOpacity]').val()
              window.mapCanvas.setPolygonAreaColor(getAreaColorsRGB(this.value, true, true, colourOpacity))
            }
          }
        }
      })
      $form.submit(sendFormData)
      $cancelBtn.click(function () {
        ooiManager.switchDataMode('create')
        // $aoiListPopup.find("tbody tr.active").removeClass("active");
        $aoiListPopup.$el.find('tbody tr.active').removeClass('active')

        window.mapCanvas.deletePolygon()
        removeAoiPin()
        lastActiveAoiId = null
        window.mapCanvas.animateZoomChange()
      })
      $backBtn.off('click').click(function () {
        returnToMapping()
      })
      $selects.filter('[data-select=accountDto]').on('selectmenuselect', function (e, ui) {
        var lastViewedAccountInMapping = $.cookie('lastViewedAccount')
        if ($selects.filter('[data-select=categoryDto]').hasClass('hasSelect')) {
          $selects.filter('[data-select=categoryDto]').selectmenu('disable')
        }
        sendCurrentAccount(ui.item.element.val(), function () {
          ooiManager.loadCategoryList(function () {
            if (dataMode == 'edit' && !('bubbles' in e)) {
              $selects.filter('[data-select=categoryDto]').val($aoiListPopup.findSubView('aoiList')
                .findSubView('compactReportTableGrid')
                .model.get('tableRow').get($aoiListPopup.$el.find('tr.active').attr('data-id'))
                .attributes.categoryDto.id).selectmenu('refresh')
            }
            $selects.filter('[data-select=categoryDto]').selectmenu('enable')
            $.cookie('lastViewedAccount', lastViewedAccountInMapping)
          })
        })
      })
      $inputs.filter('[data-input=colourOpacity]').on('change', function (e, ui) {
        var colour = $colorpickers.filter('[data-colorpicker=areaColour]').val()
        if (!window.mapCanvas.hasDrawControl()) {
          return
        }
        var colourOpacity = $inputs.filter('[data-input=colourOpacity]').val()
        window.mapCanvas.setPolygonAreaColor(getAreaColorsRGB(colour, true, true, colourOpacity))
      })
      new SafeInput($inputs.filter('[data-input=lat],[data-input=lon]'), ['digits', 'period', 'subtract', 'navigation', 'edit', 'f5'])
      $aoiListBtn.click(function () {
        window.mapCanvas.disableDrawing()
        $aoiListPopup = new AoiListAlertView()
        // console.log("window.mapCanvas.toggleAdvancedDrawing();");
        // window.mapCanvas.toggleAdvancedDrawing();

        // if($aoiListPopup) {
        //     ooiManager.loadAoiList(function(){
        //         generateAoiListTable();
        //         if(dataMode == "edit" && lastActiveAoiId != null){
        //             $aoiListPopup.find("[data-id= " + lastActiveAoiId + "]").addClass("active");
        //         }
        //         ooiManager.showAoiListPopup(function(){
        //             ooiManager.reloadScroll(aoiListGrid);
        //             if($aoiCategoryPopup){
        //                 ooiManager.hideAoiCategoryPopup();
        //                 $aoiCategoryDrop.hide();
        //             }
        //         });
        //     });
        // } else {
        //     ooiManager.loadAoiListPopupHTML(function(){
        //         aoiListGrid = new TableGrid($aoiListPopup.find(".info-holder"),{"hideDropdown" : true});
        //         ooiManager.loadAoiList(function(){
        //             generateAoiListTable();
        //             aoiIdList = generateIdList(aoiListJson);
        //             ooiManager.showAoiListPopup(function(){
        //                 ooiManager.reloadScroll(aoiListGrid);
        //                 if($aoiCategoryPopup){
        //                     ooiManager.hideAoiCategoryPopup();
        //                     $aoiCategoryDrop.hide();
        //                 }
        //             });
        //             $aoiListPopup.on("click","[data-button=activateDeactivate]",function(){
        //                 var poiId = $(this).closest("tr").attr("data-id");
        //                 var currPoi = aoiIdList[poiId];
        //
        //                 $.ajax({
        //                     type: "POST",
        //                     url: "/activateDeactivateAoi.do",
        //                     data: JSON.stringify({"id":currPoi.id,"active": currPoi.status != "Active"}),
        //                     contentType: "application/json; charset=utf-8",
        //                     dataType:"json",
        //                     success : function(json) {
        //                         updateRecord(json);
        //                         wlsAOIHandler.setOOI([]);
        //                     }
        //                 });
        //             });
        //
        //             $aoiListPopup.on("click","[data-button=edit]",function(){
        //                 var tr = $(this).closest("tr");
        //                 var aoiId = tr.attr("data-id");
        //                 var currAoi = aoiIdList[aoiId];
        //                 lastActiveAoiId = aoiId;
        //                 tr.addClass("active").siblings().removeClass("active");
        //                 $inputs.filter(function(){
        //                     this.value = currAoi[this.getAttribute("data-input")];
        //                 });
        //                 if(currAoi.accountDto.id == $selects.filter("[data-select=accountDto]").val()){
        //                     $selects.filter("[data-select=categoryDto]").val(currAoi.categoryDto.id);
        //                 }
        //                 $selects.filter("[data-select=accountDto]").val(currAoi.accountDto.id);
        //                 $selects.selectmenu("refresh");
        //                 $colorpickers.filter(function(){
        //                     var currColorpicker = this;
        //                     var color = currAoi[currColorpicker.getAttribute("data-colorpicker")].toLowerCase();
        //                     $.each(currColorpicker.options,function(index){
        //                         if(this.value == color){
        //                             currColorpicker.selectedIndex = index;
        //                             return false;
        //                         }
        //                     });
        //                 });
        //
        //                 $colorpickers.colorpicker("refresh");
        //                 ooiManager.switchDataMode("edit");
        //                 addPolygon(currAoi.polygonWkt);
        //             });
        //         });
        //
        //         $aoiListPopup.find(".close-btn").click(function(){
        //             ooiManager.hideAoiListPopup();
        //         });
        //     });
        // }
      })
      // POI categories popup events
      $aoiCategoryBtn.click(function () {
        if ($aoiCategoryPopup) {
          ooiManager.loadAoiCategoryList(function () {
            generateAoiCategoryTable()
            ooiManager.showAoiCategoryPopup(function () {
              ooiManager.reloadScroll(aoiCategoryListGrid)
              if ($aoiListPopup) {
                $aoiListPopup.closeBtnHandler()
              }
            })
          })
        } else {
          ooiManager.loadAoiCategoryPopup(function () {
            ooiManager.loadAoiCategoryList(function () {
              var edit = false
              var currCategory
              var $accountDtoSelect = $aoiCategoryDrop.find('[data-select=accountDto]')
              var $dropTitles = $aoiCategoryDrop.find('[data-title]')

              generateAoiCategoryTable()
              aoiCategoryListGrid = new TableGrid($aoiCategoryPopup.find('.info-holder'), { hideDropdown: true })
              aoiCategoryIdList = generateIdList(aoiCategoryListJson)
              ooiManager.showAoiCategoryPopup(function () {
                ooiManager.reloadScroll(aoiCategoryListGrid)
                if ($aoiListPopup) {
                  $aoiListPopup.closeBtnHandler()
                }
              })
              $aoiCategoryDrop.show()
              initSelect.apply(aoiAccountJson, [$aoiCategoryDrop.find('[data-select=accountDto]')])
              initSelectScroll($aoiCategoryDrop.find('.select-holder:has([data-select=accountDto]) .scroll-content'))
              $aoiCategoryDrop.hide()
              $aoiCategoryPopup.find('[data-button=newCategory]').click(function () {
                edit = false
                $aoiCategoryDrop.css({ 'z-index': '2', top: '', left: '', right: '' }).show()
                $dropTitles.hide().filter('[data-title=add]').show()
                $aoiCategoryDrop.find('[data-input=categoryDto]').val('').focus()
                $accountDtoSelect.val($accountDtoSelect.find('option:first').val())
                $aoiCategoryPopup.find('tbody tr.active').removeClass('active')
                $accountDtoSelect.selectmenu('refresh')
              })
              $aoiCategoryPopup.find('[data-button=cancel],[data-button=done]').click(function () {
                ooiManager.hideAoiCategoryPopup()
                $aoiCategoryDrop.hide()
                ooiManager.loadCategoryList()
                $aoiCategoryPopup.find('tbody tr.active').removeClass('active')
              })
              $aoiCategoryDrop.find('[data-button=cancel]').click(function () {
                $aoiCategoryDrop.hide()
                $aoiCategoryPopup.find('tbody tr.active').removeClass('active')
              })
              $aoiCategoryPopup.on('click', '[data-button=edit]', function (e) {
                var $editBtn = $(this)
                var $tr = $(this).closest('tr')
                var categoryId = $tr.attr('data-id')

                currCategory = aoiCategoryIdList[categoryId]

                $aoiCategoryDrop.find('[data-input=categoryDto]').val(currCategory.descr).focus()
                $accountDtoSelect.val(currCategory.accountDto.id).selectmenu('refresh')
                $tr.addClass('active').siblings().removeClass('active')
                $aoiCategoryDrop.css({ top: $editBtn.offset().top - 5, left: '516px', 'z-index': '2' }).show()
                $dropTitles.hide().filter('[data-title=edit]').show()
                edit = true
              })
              $aoiCategoryPopup.on('click', '[data-button=delete]', function (e) {
                var $tr = $(this).closest('tr')
                var confirmDialog = window.confirm('Are you sure you want to delete this category?')

                if (confirmDialog) {
                  if ($tr.hasClass('active')) {
                    $tr.removeClass('active')
                    $aoiCategoryDrop.hide()
                  }
                  ooiManager.deleteAoiCategory($tr.attr('data-id'), function () {
                    $tr.remove()
                    ooiManager.reloadScroll(aoiCategoryListGrid)
                  })
                }
              })
              $aoiCategoryDrop.find('form').submit(function (e) {
                var data = getFormData($aoiCategoryDrop)
                e.preventDefault()
                if (checkFormValidity($(this))) {
                  return false
                }
                if (edit) {
                  data = {
                    id: currCategory.id,
                    descr: $aoiCategoryDrop.find('[data-input=categoryDto]').val(),
                    accountDto: {
                      id: $accountDtoSelect.val(),
                      descr: $accountDtoSelect.find('option[value=' + $accountDtoSelect.val() + ']').text()
                    }
                  }
                  currCategory.accountDtoDescr = currCategory.accountDto.descr
                } else {
                  $dropTitles.hide().filter('[data-title=create]').show()
                  data = {
                    id: 0,
                    descr: data.categoryDto,
                    accountDto: {
                      id: data.accountDto,
                      descr: $aoiCategoryDrop.find('[data-select=accountDto] option[value=' + data.accountDto + ']').text()
                    }
                  }
                  $aoiCategoryDrop.hide()
                }
                ooiManager.createEditAoiCategory(data, function (json) {
                  json.accountDtoDescr = json.accountDto.descr
                  if (edit) {
                    $aoiCategoryPopup.find('tbody tr[data-id=' + json.id + ']').html($(aoiCategoryTrTpl.compile(json)).html()).removeClass('active')
                    $aoiCategoryDrop.hide()
                  } else {
                    if ($aoiCategoryPopup.find('tbody [data-id=' + json.id + ']').size()) {
                      return
                    }
                    $aoiCategoryPopup.find('tbody').append(aoiCategoryTrTpl.compile(json))
                  }
                  aoiCategoryIdList[json.id] = json
                  ooiManager.reloadScroll(aoiCategoryListGrid)
                  systemAlert.initMsg('Successfully saved')
                })
              })
            })
          })
        }
      })
      // POI categories popup events

      function geocodeCallback (result) {
        var redMarker = new Image()
        var len = result.length
        var options = ''

        redMarker.src = 'img/markers/marker_red.png'
        if (!result || len == 0) {
          alert('There were no locations found')
          return
        }
        for (var i = 0; i < len; i++) {
          options += "<li data-id='" + i + "'>" + result[i].description + '</li>'
        }
        options = $(options)
        $searchPostcode.find('ul').html(options)
        function clickHandler (e) {
          var markerData = e.data.markerData
          addAoiPostcodePin(markerData)
          $searchPostcode.removeClass('active')
          $inputs.filter('[data-input=lat]').val(markerData.lat)
          $inputs.filter('[data-input=lon]').val(markerData.lon)
          setTimeout(rezoomMapFlx, 1500)
        }
        for (var i = 0; i < len; i++) {
          options.eq(i).on('click', { markerData: result[options[i].getAttribute('data-id')] }, clickHandler)
        }
        options.removeAttr('data-id')
        $searchPostcode.addClass('active')
        initSelectScroll($('.scroll-content', $searchPostcode))
        setTimeout(rezoomMapFlx, 1500)
      }
      $searchPostcode.submit(function (e) {
        e.preventDefault()
        if ($.trim($postcode.val())) {
          window.mapCanvas.geocode($postcode.val(), geocodeCallback)
        }
      })
    }
    this.addPolygon = function (polygonWkt) {
      var pinColour = $colorpickers.filter('[data-colorpicker=pinColour]').val()
      var areaColour = $colorpickers.filter('[data-colorpicker=areaColour]').val()
      var colourOpacity = $inputs.filter('[data-input=colourOpacity]').val()
      window.mapCanvas.deletePolygon()

      const polygonColor = window.mapCanvas.colorStringToHex(areaColour, true, true, colourOpacity)
      const polygonOptions = {
        fillColor: polygonColor,
        fillOpacity: 0.8,
        strokeColor: '#000',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        pinColour: pinColour
      }

      if (mapSource && mapSource.startsWith('MSVE')) {
        window.mapCanvas.addPolygonFromWkt(polygonWkt)
        window.mapCanvas.setPolygonPinColor(pinColour)
        window.mapCanvas.setPolygonAreaColor(getAreaColorsRGB(areaColour, true, true, colourOpacity))
        window.mapCanvas.disableDrawing()
        setTimeout(rezoomMapFlx, 1500)
      } else if (mapSource && mapSource === 'GOOGLEMAPS') {
        window.mapCanvas.addPolygonFromWkt(polygonWkt, function () {
          setTimeout(rezoomMapFlx, 1500)
        }, polygonOptions)
      }
    }
    function returnToMapping () {
      createdPoiPin = window.createdPoiPin = false
      addPoiPinByRightClickFromMap = false
      $backBtn.hide()

      addPoi.lat = null
      addPoi.lon = null

      if (JourneyDetails().isVisible()) {
        JourneyDetails().hide()
      }
      if (searchVehicle) {
        searchVehicle.resetTableResults()
        if (searchVehicle.isVisible()) {
          searchVehicle.hideSearchResult()
        }
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }
      }

      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      if (sendMessage.isVisible()) {
        sendMessage.destroyEl()
      }
      if ($('.select-to-sms-popup').css('visibility') === 'visible') {
        $('.select-to-sms-popup').css('visibility', 'hidden')
        if (mapSource && mapSource.startsWith('MSVE')) {
          window.mapCanvas.map.entities.pop()
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          window.mapCanvas.cleanUp()
        }
        window.wlsMapTools.multipleSelectToolIsActive = false
      }
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }

      if (view.getActiveState() !== 'dual') {
        view.state = 'fleet'
      }

      if (window.mapCanvas.isDrawingControlVisible()) {
        window.mapCanvas.switchDrawingControl()
      }
      GeoZones().hide()
      if (poiManager) {
        poiManager.hide()
      }
      if (aoiManager) {
        aoiManager.hide()
      }

      if (addPoiPinByRightClickFromReport || addPoiPinByRightClickFromJourneyReport || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
        var $dataPopup
        if (addPoiPinByRightClickFromTimeOnSiteReportDetails) {
          $dataPopup = $(".time-on-site-details-popup[data-popup='journeyDetails']")
        } else {
          $dataPopup = $(".journey-details-popup[data-popup='journeyDetails']")
        }
        var $dataReport = $('.report[data-report]')
        var li = $('.user-menu .categories li.reports')

        li.addClass('active')
        activeMenuItem = li
        li.siblings().removeClass('active')
        li.siblings().removeClass('pressed')

        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy()
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
        startVehiclesUpdate = true

        if (addPoiPinByRightClickFromReport || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
          $dataPopup.show()
          //                        $dataPopup.showSnailTrail();
        }
        $dataReport.show()

        selectedGroup = $.cookie('lastViewedGroup')
      } else {
        var li = $('.user-menu .categories li.mapping')
        var time = 0

        li.addClass('active')
        activeMenuItem = li
        li.siblings().removeClass('active')
        li.siblings().removeClass('pressed')
        selectedGroup = $.cookie('lastViewedGroup')

        if (JourneyDetails().getSnailTrail().isOnMap()) {
          JourneyDetails().getSnailTrail().removeSnailTrailFromMap()
        }

        if (leftPanel.find('.right-col').is(':visible')) {
          return
        }
        startVehiclesUpdate = true
        if (userPrefs.htmlGroupTabsAllowed) {
          var groupId = $.cookie('lastViewedGroup')

          $.cookie('lastViewedGroup', null) // reset cookie
          $.cookie('lastViewedGroup', groupId)
          groupTabsetView.render()
        } else {
          wls.requestCars($.cookie('lastViewedGroup'), function () {
            wls.removeCars()
            wls.restoreSelectionMenuState()
            $('.left-panel').fadeIn(400, function () {
              $('.left-panel .right-col .vehicle-holder .scroll-content').triggerHandler('resize', [$('.left-panel .vehicle-holder .slidee').height()])
            })
          })
        }

        if (leftPanel.hasClass('active')) {
          leftPanel.parent().animate({ 'margin-left': 0 }, 500)
          leftPanel.removeClass('active').parent().removeClass('active')
          time = 500
        }
        if (leftPanel.find('.actions-holder').is(':Visible')) {
          searchVehicle.resetActionHolder()
        }
        if (leftPanel.find('.control-panel,.control-panel+.row').is(':visible') && view.getActiveState() != 'fleet') {
          leftPanel.find('.control-panel,.control-panel+.row').hide()
        }
        if (leftPanel.hasClass('no-group-tab') && settings.data.htmlGroupTabsAllowed) {
          leftPanel.removeClass('no-group-tab').addClass('has-group-tab')
        }
        leftPanel.delay(time).fadeOut(300, function () {
          leftPanel.find('.slide-button').removeClass('active')
          leftPanel.css('left', 0)
          leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
          leftPanel.fadeIn(300, function () {
            if (view.getActiveState() !== 'dual') {
              view.switchStateTo('fleet')
            } else {
              view.switchStateTo('dual')
            }
          })
        })
      }

      addPoiPinByRightClickFromReport = false
      addPoiPinByRightClickFromJourneyReport = false

      if (proximityTabset) {
        proximityTabset.destroy()
        proximityTabset = null
      }
    }
    this.switchDataMode = function (_mode) {
      dataMode = _mode
      if (_mode == 'create') {
        $cancelBtn.hide()
        $('[data-tab=aoiManager]').find('[data-button=back]').show()
        setDefaultValues()
        if (!window.mapCanvas.hasDrawControl()) {
          const pinColour = $colorpickers.filter('[data-colorpicker=pinColour]').val()
          const areaColour = $colorpickers.filter('[data-colorpicker=areaColour]').val()
          const colourOpacity = $inputs.filter('[data-input=colourOpacity]').val()
          window.mapCanvas.initDrawControl(mapCanvas, '',
            function () {
              window.mapCanvas.setPolygonPinColor(pinColour)
            }, getAreaColorsRGB(pinColour, true, true, undefined), getAreaColorsRGB(areaColour, true, true, colourOpacity))
        }
        GeoZones().getHTML().find('.slide-button').removeClass('active').end().css('margin-left', '0')
        window.mapCanvas.deletePolygon()
      } else {
        if ($('[data-tab=aoiManager]').find('[data-button=back]').is(':visible')) {
          $('[data-tab=aoiManager]').find('[data-button=back]').hide()
        }
        $cancelBtn.show()
      }
      $titles.hide().filter('[data-title=' + _mode + ']').show()
    }
    function generateIdList (json) {
      var idList = {}
      var len = json.data.length
      for (var i = 0; i < len; i++) {
        idList[json.data[i].id] = json.data[i]
      }
      return idList
    }
    this.bindMapClick = function () {
      var pinColour = $colorpickers.filter('[data-colorpicker=pinColour]').val()
      var areaColour = $colorpickers.filter('[data-colorpicker=areaColour]').val()
      var colourOpacity = $inputs.filter('[data-input=colourOpacity]').val()
      function finishedCallback () {
        var pinColour = $colorpickers.filter('[data-colorpicker=pinColour]').val()
        window.mapCanvas.setPolygonPinColor(pinColour)
        if (mapSource && mapSource.startsWith('MSVE')) {
          setTimeout(rezoomMapFlx, 1500)
        }
      }
      if (!drawControlInited) {
        window.mapCanvas.initDrawControl(mapCanvas, '', finishedCallback, getAreaColorsRGB(pinColour, true, true, undefined), getAreaColorsRGB(areaColour, true, true, colourOpacity))
        drawControlInited = true
      }
      $('#wlsmap').on('contextmenu', function (e) {
        e.preventDefault()
      })
      $(document).keydown(function (e) {
        if (e.keyCode == 27) {
          window.mapCanvas.deletePolygon()
        }
      })
    }
    this.unbindMapClick = function () {
      window.mapCanvas.deletePolygon()
      window.mapCanvas.disableDrawing()
      $('#wlsmap').off('contextmenu')
      $(document).off('keydown')
    }
    this.reloadScroll = function (tableGrid) {
      var $tableHTML = tableGrid.getTable()
      var tableHeight = $tableHTML.find('.info.content').height()
      var $frame = $tableHTML.find('.scroll-content')
      var $wrap = $frame.parent()
      $frame.attr('style', '')

      if ($wrap.height() < tableHeight) {
        $frame.height($wrap.outerHeight(true))
        if (!$frame.hasClass('hasScroll')) {
          initGridScroll($frame)
        }
        $frame.sly('reload')
        $wrap.find('.scrollbar').show()
      } else {
        $frame.sly('destroy')
        $wrap.find('.scrollbar').hide()
        $frame.attr('style', '')
        $frame.removeClass('hasScroll')
      }
    }
    function initGridScroll ($frame) {
      $frame.sly({
        horizontal: 0,
        speed: 300,
        easing: 'linear',
        scrollBar: $frame.parent().find('.scrollbar'),
        scrollBy: 100,
        dragHandle: 1,
        dynamicHandle: 1,
        clickBar: 1
      })
      $frame.addClass('hasScroll')
    }
    function initSelect (currSelect) {
      var options = ''

      $.each(this.data, function (i, obj) {
        options += '<option value="' + obj.id + '">' + obj.descr + '</option>'
      })

      currSelect.html(options)
      currSelect.selectmenu({
        width: 'auto',
        open: function (event, ui) {
          var $selectDropdown = $(this).selectmenu('menuWidget').parent()
          $selectDropdown.find('ul').width($(this).next().outerWidth())
        },
        create: function (e, ui) {
          var $selectDropdown = $(this).selectmenu('menuWidget').parent()
          var $scrollFrame = $('<div class="scroll-frame">' +
            '<div class="scrollbar vertical">' +
            '<div class="handle">' +
            '<div class="mousearea"></div>' +
            '<div class="bg"></div>' +
            '</div>' +
            '</div>' +
            '<div class="scroll-content">' +
            '<div class="slidee"></div>' +
            '</div>' +
            '</div>')
          $(this).parent().append($selectDropdown)
          $selectDropdown.append($scrollFrame)
          $scrollFrame.find('.slidee').append($selectDropdown.find('ul'))
          $selectDropdown.find('ul').width($(this).next().outerWidth())
          $(this).addClass('hasSelect')
        }
      })
      currSelect.selectmenu('refresh')
      if (this.data.length < 2 && $(currSelect).attr('data-select') === 'accountDto') {
        currSelect.parents('.row').hide()
      }
    }
    function initSelectScroll ($frame) {
      var $wrap = $frame.parent()
      var ulHeight
      var drop = $frame.closest('.ui-selectmenu-menu')

      drop.show()
      ulHeight = $frame.find('ul').css('height', 'auto').height()
      if ($wrap.height() < ulHeight) {
        $frame.height($wrap.outerHeight(true))
        if (!$frame.hasClass('hasScroll')) {
          $frame.sly({
            speed: 300,
            easing: 'linear',
            scrollBar: $wrap.find('.scrollbar'),
            scrollBy: 100,
            dragHandle: 1,
            dynamicHandle: 1,
            clickBar: 1
          })
          $frame.addClass('hasScroll')
        }
        $frame.sly('reload')
        $wrap.find('.scrollbar').show()
      } else {
        $frame.sly('destroy')
        $wrap.find('.scrollbar').hide()
        $frame.attr('style', '')
        $frame.removeClass('hasScroll')
      }
      drop.attr('style', '')
    }
    this.loadAccountList = function (callback) {
      $.post('/getAccountListForCurrentUser.do').done(function (json) {
        aoiAccountJson = json
        initSelect.apply(json, [$selects.filter('[data-select=accountDto]')])
        if (getLastViewedAccount()) {
          $selects.filter('[data-select=accountDto]').val(getLastViewedAccount()).selectmenu('refresh')
        }
        initSelectScroll($selects.filter('[data-select=accountDto]').parent().find('.scroll-content'))
        if (callback) callback()
      })
    }
    this.deleteAoiCategory = function (categoryId, callback) {
      $.ajax({
        type: 'POST',
        url: '/deleteAoiCategory.do',
        data: JSON.stringify({ id: categoryId, active: false }),
        contentType: 'application/json; charset=utf-8',
        success: function (json) {
          if (callback) callback(json)
        }
      })
    }
    this.loadCategoryList = function (callback) {
      $.post('/getAoiCategoryListForCurrentAccount.do').done(function (json) {
        aoiCategoryJson = json
        initSelect.apply(json, [$selects.filter('[data-select=categoryDto]')])
        initSelectScroll($selects.filter('[data-select=categoryDto]').parent().find('.scroll-content'))
        if (callback) callback()
      })
    }
    this.loadOOIList = function () {

    }
    this.loadAoiList = function (callback) {
      $.post('/getAoiForCurrentUser.do', { active: true }).done(function (json) {
        aoiListJson = json
        if (callback) callback()
      })
    }
    this.loadAoiCategoryList = function (callback) {
      $.post('/getAoiCategoryListForCurrentUser.do').done(function (json) {
        aoiCategoryListJson = json
        if (callback) callback()
      })
    }
    function generateAoiListTable () {
      var data = aoiListJson.data
      var dataLen = data.length
      var poiListTr = ''
      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        currData.accountDtoDescr = currData.accountDto.descr
        if (!currData.categoryDto || currData.categoryDto.descr == 'No Category') {
          currData.categoryDtoDescr = null
        } else {
          currData.categoryDtoDescr = currData.categoryDto.descr
        }
        if (currData.status == 'Active') {
          currData.statusAction = 'Deactivate'
        } else {
          currData.statusAction = 'Activate'
        }
        poiListTr += aoiListTrTpl.compile(currData)
      }
      $aoiListPopup.find('tbody').html(poiListTr)
    }
    function generateAoiCategoryTable () {
      var data = aoiCategoryListJson.data
      var dataLen = data.length
      var aoiCategoryTr = ''
      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        currData.accountDtoDescr = currData.accountDto.descr
        aoiCategoryTr += aoiCategoryTrTpl.compile(currData)
      }
      $aoiCategoryPopup.find('tbody').html(aoiCategoryTr)
    }
    this.loadAoiListPopupHTML = function (callback) {
      $.post('/getAoiListWindowPopup.do').done(function (HTML) {
        $aoiListPopup = $(HTML)
        $('#content').append($aoiListPopup)
        if (callback) callback()
      })
    }
    this.loadAoiCategoryPopup = function (callback) {
      $.post('/getAoiCategoryWindowPopup.do').done(function (HTML) {
        $aoiCategoryPopup = $(HTML).filter('.ooi-categories-popup')
        $aoiCategoryDrop = $(HTML).filter('.ooi-category-popup')
        $('#content').append($aoiCategoryPopup)
        $('#content').append($aoiCategoryDrop)
        if (callback) callback()
      })
    }
    this.createEditAoiCategory = function (data, callback) {
      $.ajax({
        type: 'POST',
        url: '/saveOrUpdateAoiCategory.do',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function (json) {
          if (callback) callback(json)
        }
      })
    }
    this.getAoiListPopup = function () {
      return $aoiListPopup
    }
    this.showAoiListPopup = function (callback) {
      $aoiListPopup.css({ left: $HTML.outerWidth() })
      $aoiListPopup.fadeIn(300, callback)
    }
    this.hideAoiListPopup = function (callback) {
      $aoiListPopup.fadeOut(300, callback)
    }
    this.showAoiCategoryPopup = function (callback) {
      $aoiCategoryPopup.fadeIn(300, callback)
    }
    this.hideAoiCategoryPopup = function (callback) {
      $aoiCategoryPopup.fadeOut(300, callback)
    }
    this.hide = function () {
      if ($aoiCategoryPopup) {
        ooiManager.hideAoiCategoryPopup()
      }
      if ($aoiListPopup) {
        $aoiListPopup.closeBtnHandler()
      }
      if ($aoiCategoryDrop) {
        $aoiCategoryDrop.hide()
      }
      ooiManager.unbindMapClick()
      removeAoiPin()
    }
    function addAoiPostcodePin (place) {
      var img = new Image()
      removeAoiPin()
      img.src = 'img/markers/marker_red.png'
      aoiPin = { lat: place.lat, lon: place.lon }
      aoiPin.typeDescr = 'poi'
      aoiPin.radius = 0
      aoiPin.areaColour = 'red'
      aoiPin = window.mapCanvas.addTextMarkerByObj(img, aoiPin, false, false, false, false, 'red', false,
        function (result) {
          aoiPin = result
          if (!createdPoiPin) {
            setTimeout(rezoomMapFlx, 1500)
            createdPoiPin = window.createdPoiPin = true
          }
        })
    }
    function removeAoiPin () {
      if (aoiPin) {
        window.mapCanvas.removeMarker(aoiPin)
        aoiPin = null
      }
    }
    function sendFormData (e) {
      e.preventDefault()
      var data = getFormData($form)
      data.accountDto = { id: data.accountDto }
      data.categoryDto = { id: data.categoryDto }
      if (window.mapCanvas.hasPolygonShape()) {
        data.polygonWkt = window.mapCanvas.getPolygonWktFromShape()
      }
      if (!window.mapCanvas.hasPolygonShape()) {
        alert('Please draw a polygon.')
        return false
      }
      if (checkFormValidity($form)) {
        return false
      }
      // if(dataMode == "edit"){
      //     // data.id = $aoiListPopup.find("tbody tr.active").attr("data-id");
      //     data.id = $aoiListPopup.$el.find("tr.active").attr("data-id");
      // }
      $.ajax({
        type: 'POST',
        url: '/saveOrUpdateAoi.do',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
      }).done(function (json) {
        systemAlert.initMsg('Successfully saved')
        window.mapCanvas.cleanUp('poiPin')
        if (dataMode == 'edit') {
          // updateRecord(json);
          // ooiManager.switchDataMode("create");
          $aoiListPopup.findSubView('aoiList').findSubView('compactReportTableGrid').updateRecord(json)
          $aoiListPopup.findSubView('aoiList').findSubView('compactReportTableGrid').render()
          ooiManager.switchDataMode('create')
        } else {
          if ($aoiListPopup) {
            $aoiListPopup.findSubView('aoiList').loadList()
            // ooiManager.loadAoiList(function(){
            //     generateAoiListTable();
            //     ooiManager.reloadScroll(aoiListGrid);
            //     aoiIdList = generateIdList(aoiListJson);
            // });
          }
          if (addPoiPinByRightClickFromMap || addPoiPinByRightClickFromReport || addPoiPinByRightClickFromJourneyReport || addPoiPinByRightClickFromActivityLog || addPoiPinByRightClickFromTimeOnSiteReport || addPoiPinByRightClickFromTimeOnSiteReportDetails) {
            returnToMapping()
          } else {
            ooiManager.switchDataMode('create')
          }
        }
        removeAoiPin()
        wlsAOIHandler.setOOI([])
      })
    }
    function checkFormValidity ($form) {
      var error = false
      $form.find('input:text').each(function () {
        if (this.value.trim() == '' || this.value.trim() == '0' && this.getAttribute('data-input') == 'radius') {
          alert($(this).closest('.row').find('>span').text() + ' is required.')
          error = true
          return false
        }
      })
      return error
    }
    function updateRecord (json) {
      var $tr
      json.accountDtoDescr = json.accountDto.descr
      if (!json.categoryDto || json.categoryDto.descr == 'No Category') {
        json.categoryDtoDescr = null
      } else {
        json.categoryDtoDescr = json.categoryDto.descr
      }
      if (json.status == 'Active') {
        json.statusAction = 'Deactivate'
      } else {
        json.statusAction = 'Activate'
      }
      $tr = $(aoiListTrTpl.compile(json))
      $aoiListPopup.find('tbody tr[data-id=' + json.id + ']').html($tr.children()).removeClass('active')
      lastActiveAoiId = null
      aoiIdList[json.id] = json
    }
    function setDefaultValues () {
      var defLat = 51.507351
      var defLon = -0.127758
      $inputs.filter('[data-input=lat]').val(defLat)
      $inputs.filter('[data-input=lon]').val(defLon)
      $inputs.filter('[data-input=description]').val('')
      $inputs.filter('[data-input=radius]').val('100')
      if (getLastViewedAccount()) {
        $selects.filter('[data-select=accountDto]').val(getLastViewedAccount()).selectmenu('refresh')
      }
      ooiManager.loadCategoryList()
      $colorpickers.val($colorpickers.find('option').first().val()).colorpicker('refresh')
    }
    init()
  }

  function Tabset ($HTML) { // Tabset class
    var tabset = this
    var tabs = $HTML.find('[data-tab]')
    var controls = $HTML.find('[data-tabset-control]:first [data-target]')
    this.show = function (tab) {
      controls.filter('[data-target=' + tab + ']').addClass('active').siblings().removeClass('active')
      tabs.filter('[data-tab=' + tab + ']').addClass('active').siblings().removeClass('active')
      switch (tab) {
        case 'poiManager':
        case 'geoFenceAlert':
        case 'geoMonitor': {
          window.mapCanvas.disableDrawControl()
          break
        }
        case 'aoiManager': {
          break
        }
        default: {
          break
        }
      }
    }
    this.getTab = function (tab) {
      return tabs.filter('[data-tab=' + tab + ']')
    }
    this.getControls = function () {
      return controls
    }
    controls.click(function () {
      tabset.show($(this).attr('data-target'))
    })
    this.show(controls.first().attr('data-target'))
  }
  function Incidents () { // Incidents singleton
    if (Incidents.prototype._singletonInstance) {
      return Incidents.prototype._singletonInstance
    }
    Incidents.prototype._singletonInstance = this

    var $incidentsTable = null
    var incidentIdList = {}
    var incidentsInst = this
    var $frame = null
    var $wrap = null
    var $incidentDetailsFrame
    var $IncidentDetailsWrap
    var incidentsTableGridInst = null
    var $incidentsWindow = null
    var incidentsJson = null
    var $HTMLincidents = null
    var $incidentDetails = $('.incident-details')
    var $vehicleAndIncidentDetailsTab = $('.tab.vehicle-incident-details')
    var incidentsTrTpl = ''
    var incidentsThTpl = ''
    var vehicleAndIncidentDetailsTbodyTpl = ''
    var snailTrail
    var onMap = false
    var accountColumnIndex
    var subGroupColumnIndex
    var datepickerInited = false
    var inited = false
    var gforceXChart
    var gforceYChart
    var gforceZChart

    function hideLeftPanel (callback) {
      leftPanel.fadeOut(300, function () {
        leftPanel.find('.slide-button').removeClass('active')
        leftPanel.css('left', 0)
        leftPanel.find('.incidents').show().siblings().not('.slide-button').hide()
        leftPanel.fadeIn(300, callback)
        if (!leftPanel.find('.control-panel,.control-panel+.row').is(':visible')) {
          leftPanel.find('.control-panel,.control-panel+.row').show()
        }
      })
    }
    this.reloadIncidentDetailsScroll = function () {
      var $HTML = $incidentDetails
      $incidentDetailsFrame = $incidentDetails.find('.scroll-content')
      $IncidentDetailsWrap = $incidentDetailsFrame.parent()
      $incidentDetailsFrame.attr('style', '')
      if ($HTML.height() < $HTML.find('.info-holder').outerHeight() + $incidentDetails.find('.header-panel').outerHeight(true) + $incidentDetails.find('.tab-control').outerHeight()) {
        $incidentDetailsFrame.height($HTML.height() - ($HTML.find('.header-panel').outerHeight(true) + $incidentDetails.find('.tab-control').outerHeight() + $HTML.find('table.info:first').outerHeight()))
        if (!inited) {
          initIncidentDetailsSly()
        }
        $incidentDetailsFrame.sly('reload')
        $IncidentDetailsWrap.find('.scrollbar').show()
      } else {
        $incidentDetailsFrame.sly('destroy')
        $IncidentDetailsWrap.find('.scrollbar').hide()
        $incidentDetailsFrame.attr('style', '')
        inited = false
      }
    }
    function initIncidentDetailsSly () {
      $incidentDetailsFrame.sly({
        horizontal: 0,
        speed: 300,
        easing: 'linear',
        scrollBar: $IncidentDetailsWrap.find('.scrollbar'),
        scrollBy: 100,
        dragHandle: 1,
        dynamicHandle: 1,
        clickBar: 1
      })
      inited = true
    }
    this.showIncidentsWindow = function (callback) {
      if (leftPanel.is(':animated')) {
        return
      }
      if (leftPanel.hasClass('has-group-tab')) {
        leftPanel.removeClass('has-group-tab').addClass('no-group-tab')
      }
      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500, function () {
          hideLeftPanel(callback)
        })
        leftPanel.removeClass('active').parent().removeClass('active')
      } else {
        hideLeftPanel(callback)
      }
    }
    this.hideIncidentsWindow = function (callback) {
      leftPanel.fadeOut(300, function () {
        $incidentsWindow.hide()
        leftPanel.find('.slide-button').removeClass('active')
        leftPanel.css('left', 0)
        if (settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('no-group-tab')) {
          leftPanel.addClass('has-group-tab').removeClass('no-group-tab')
        }
        if (callback) callback()
      })
    }
    this.windowIsVisible = function () {
      return $incidentsWindow.is(':visible')
    }
    this.removeIncidentsFromMap = function () {
      window.mapCanvas.cleanUp()
      onMap = false
    }
    this.isOnMap = function () {
      return onMap
    }
    this.showIncidentsOnMap = function () {
      var data = incidentsJson.data
      var dataLen = data.length
      var img = new Image()
      img.src = '/img/markers/incident.png'
      for (var i = 0; i < dataLen; i++) {
        (function () {
          var currData = data[i]
          window.mapCanvas.addImgMarker(img,
            currData.lon,
            currData.lat,
            '' + currData.vehReg + '<br>' + currData.formatedDate + currData.formatedTime,
            false,
            false,
            function () {
              $incidentsTable.find('tr[data-incidentid=' + currData.id + ']').find('td[data-column=details]').find('.button').click()
            })
        })()
      }
      onMap = true
      rezoomMapFlx()
    }
    this.showIncidentOnMap = function (id) {
      window.mapCanvas.cleanUp()
      var $id = parseInt(id, 10)
      var data = incidentsJson.data
      var dataLen = data.length
      var img = new Image()
      img.src = '/img/markers/incident.png'
      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        if (currData.id == $id) {
          window.mapCanvas.addImgMarker(img, currData.lon, currData.lat, '' + currData.vehReg + '<br>' + currData.formatedDate + currData.formatedTime, false, false)
        }
      }
      onMap = true
      rezoomMapFlx()
    }
    this.insertVideo = function (url) {
      var $oldObject = $incidentDetails.find('object')

      $incidentDetails.find('.tab.video b').remove()
      if ($oldObject.size()) {
        $oldObject.remove()
      }
      if (url == '-' || url === null) {
        $incidentDetails.find('.tab.video').append('<b class="no-video">There is no video for this incident</b>')
      } else {
        window.QT_WriteOBJECT.apply($incidentDetails.find('.tab.video'), [url, '750', '435', '', 'autoplay', 'false', 'scale', 'tofit'])// 'wmode','transparent'
      }
    }
    this.setIncidentsTable = function ($table) {
      $incidentsTable = $table
    }
    this.setSnailTrail = function (snailTrailInst) {
      snailTrail = snailTrailInst
    }
    this.getSnailTrail = function () {
      return snailTrail
    }
    this.setAccountColumnIndex = function (index) {
      accountColumnIndex = index
    }
    this.setSubGroupColumnIndex = function (index) {
      subGroupColumnIndex = index
    }
    this.initCharts = function () {

    }
    this.renderCharts = function (incidentId) {
      $.post('/getAccelerationDataForIncident.do', { incidentId: incidentId }).done(function (data) {
        // FusionCharts.ready(function () {
        //   data.xAccelData.dataSet[1].anchorBorderColor = "ff0000";
        //   data.xAccelData.dataSet[1].anchorRadius = 4;
        //   data.yAccelData.dataSet[1].anchorBorderColor = "ff0000";
        //   data.yAccelData.dataSet[1].anchorRadius = 4;
        //   data.zAccelData.dataSet[1].anchorBorderColor = "ff0000";
        //   data.zAccelData.dataSet[1].anchorRadius = 4;
        //   gforceXChart = new FusionCharts({
        //     type: 'zoomline',
        //     renderAt: 'gforceX',
        //     width: '898',
        //     height: '240',
        //     dataFormat: 'json',
        //     dataSource: {
        //       "chart": {
        //         "caption": "",
        //         "theme": "gforces",
        //         lineColor: "#6fd69a",
        //         anchorBorderColor: "#6fd69a"
        //       },
        //       "categories": [
        //         {
        //           "category": data.xAccelData.category
        //         }
        //       ],
        //       "dataset": data.xAccelData.dataSet
        //     }
        //   });
        //   gforceYChart = new FusionCharts({
        //     type: 'zoomline',
        //     renderAt: 'gforceY',
        //     width: '898',
        //     height: '240',
        //     dataFormat: 'json',
        //     dataSource: {
        //       "chart": {
        //         "caption": "",
        //         "theme": "gforces",
        //         "lineColor": "#569fc8",
        //         "anchorBorderColor": "#569fc8"
        //       },
        //       "categories": [
        //         {
        //           "category": data.yAccelData.category
        //         }
        //       ],
        //       "dataset": data.yAccelData.dataSet
        //     }
        //   });
        //   gforceZChart = new FusionCharts({
        //     type: 'zoomline',
        //     renderAt: 'gforceZ',
        //     width: '898',
        //     height: '240',
        //     dataFormat: 'json',
        //     dataSource: {
        //       "chart": {
        //         "caption": "",
        //         "theme": "gforces",
        //         "linecolor": "#ff776b",
        //         "anchorBorderColor": "#ff776b"
        //       },
        //       "categories": [
        //         {
        //           "category": data.zAccelData.category
        //         }
        //       ],
        //       "dataset": data.zAccelData.dataSet
        //     }
        //   });
        //   gforceXChart.render();
        //   gforceYChart.render();
        //   gforceZChart.render();
        // });
      })
    }
    function initTemplates (json) {
      var distanceMetric = userPrefs.distanceMetric
      var metricString = 'km/h'
      if (distanceMetric != 'KM') {
        metricString = 'mph'
      }
      incidentsTrTpl = new Template('<tr data-incidentid="${id}">' +
        '<td data-column="vehReg"><span>${vehReg}</span></td>' +
        '<td data-column="account" title="${account}"><span>${account}</span></td>' +
        '<td data-column="group" title="${group}"><span>${group}</span></td>' +
        '<td data-column="subGroup" title="${subGroup}"><span>${subGroup}</span></td>' +
        '<td data-column="date" title="${formatedDate} ${formatedTime}"><span>${formatedDate} ${formatedTime}</span></td>' +
        //                    '<td data-column="trigger"><span>${trigger}</span></td>'+
        '<td data-column="address" title="${address}"><span>${address}</span></td>' +
        '<td data-column="postcode"><span>${postcode}</span></td>' +
        //                    '<td data-column="timezone"><span>${timezone}</span></td>'+
        '<td data-column="speed"><span>${speed}</span></td>' +
        //                    '<td data-column="xGForce"><span>${xGForce}</span></td>'+
        //                    '<td data-column="yGForce"><span>${yGForce}</span></td>'+
        //                    '<td data-column="zGForce"><span>${zGForce}</span></td>'+
        //                    '<td data-column="cabPhone"><span>${cabPhone}</span></td>'+
        '<td data-column="details"><div class="button"><span>Details</span></div></td>' +
        '</tr>')
      incidentsThTpl = new Template('<tr>' +
        '<th data-head="vehReg"><span>Veh. Reg.<span class="table-icon"><i></i></span></span><i></i></th>' +
        '<th data-head="account"><span>Account<span class="table-icon"><i></i></span></span><i></i></th>' +
        '<th data-head="group"><span>Group<span class="table-icon"><i></i></span></span><i></i></th>' +
        '<th data-head="subGroup"><span>Sub-group<span class="table-icon"><i></i></span></span><i></i></th>' +
        '<th><span>Date | Time<span class="table-icon"><i></i></span></span><i></i></th>' +
        //                    '<th><span>Trigger<span class="table-icon"><i></i></span></span><i></i></th>'+
        '<th><span>Address<span class="table-icon"><i></i></span></span><i></i></th>' +
        '<th><span>Postcode<span class="table-icon"><i></i></span></span><i></i></th>' +
        //                    '<th><span>Time Zone<span class="table-icon"><i></i></span></span><i></i></th>'+
        '<th><span>' + metricString + '<span class="table-icon"><i></i></span></span><i></i></th>' +
        //                    '<th><span>Max. x g-force<span class="table-icon"><i></i></span></span><i></i></th>'+
        //                    '<th><span>Max. y g-force<span class="table-icon"><i></i></span></span><i></i></th>'+
        //                    '<th><span>Max. z g-force<span class="table-icon"><i></i></span></span><i></i></th>'+
        //                    '<th><span>Cab Phone No.<span class="table-icon"><i></i></span></span></th>'+
        '<th><span>Details<span class="table-icon"><i></i></span></span></th>' +
        '</tr>')
      vehicleAndIncidentDetailsTbodyTpl = new Template('<tbody>' +
        '<tr>' +
        '<td>Account:</td>' +
        '<td>${account}<div class="line"></div></td>' +
        '</tr>' +
        '<tr>' +
        '<td>Vehicle Registration:</td>' +
        '<td>${vehReg}<div class="line"></div></td>' +
        '</tr>' +
        '<tr>' +
        '<td>Driver:</td>' +
        '<td>${driverName}<div class="line"></div></td>' +
        '</tr>' +
        '<tr>' +
        '<td>Date:</td>' +
        '<td>${formatedDate}<div class="line"></div></td>' +
        '</tr>' +
        '<tr>' +
        '<td>Time:</td>' +
        '<td>${formatedTime}<div class="line"></div></td>' +
        '</tr>' +
        // '<tr>'+
        // '<td>Trigger:</td>'+
        // '<td>${trigger}<div class="line"></div></td>'+
        // '</tr>'+
        '<tr>' +
        '<td>Speed:</td>' +
        '<td>${speed} ' + metricString + '<div class="line"></div></td>' +
        '</tr>' +
        // '<tr>'+
        // '<td>Time Zone:</td>'+
        // '<td>${timezone}<div class="line"></div></td>'+
        // '</tr>'+
        // '<tr>'+
        // '<td>Cab Phone No.:</td>'+
        // '<td>${cabPhone}<div class="line"></div></td>'+
        // '</tr>'+
        '</tbody>')

      snailTrail.setSnailTrailTrTpl(new Template(
        '<tr>' +
        '<td style="width:31px; height:18px"><img src="../${iconUrl}" alt=""></td>' +
        '<td title="${address}">${address}</td>' +
        '<td style="width:68px;">${postcode}</td>' +
        '<td style="width:54px;">${formatedTime}</td>' +
        '<td style="width:44px;">${speed}</td>' +
        '</tr>'
      )
      )
      if (json.singleAccount) {
        incidentsTrTpl.replace('<td data-column="account" title="${account}"><span>${account}</span></td>')
        incidentsThTpl.replace('<th data-head="account"><span>Account<span class="table-icon"><i></i></span></span><i></i></th>')
        vehicleAndIncidentDetailsTbodyTpl.replace('<tr><td>Account:</td><td>${account}</td></tr>')
      }
      if (!json.subGroupColumnVisible) {
        incidentsTrTpl.replace('<td data-column="subGroup" title="${subGroup}"><span>${subGroup}</span></td>')
        incidentsThTpl.replace('<th data-head="subGroup"><span>Sub-group<span class="table-icon"><i></i></span></span><i></i></th>')
      }
    }
    function generateIncidentIdList (json) {
      var jsonData = json.data
      var jsonLen = jsonData.length
      for (var i = 0; i < jsonLen; i++) {
        incidentIdList[jsonData[i].id] = jsonData[i]
      }
    }
    this.loadIncidents = function (groupId, from, to, callback) {
      $.post('/getIncidents.do', { unitViewId: groupId, modelAttribute: 'incidentsRequest', from: from.date, fromTime: from.time, to: to.date, toTime: to.time }).done(function (json) {
        incidentsJson = json
        generateIncidentIdList(json)
        initTemplates(json)
        if (callback) callback(json)
      }).error(function () {
        alert('error')
      })
    }
    this.generateIncidentsTable = function (json) {
      var data = json.data
      var dataLen = data.length
      var incidentsTr = ''
      var date = new Date()
      var distanceMetric = userPrefs.distanceMetric

      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        date.setTime(currData.date)
        currData.formatedDate = $.datepicker.formatDate('dd.mm.yy ', date)
        currData.formatedTime = (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ':' + (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

        if (distanceMetric != 'KM') {
          currData.speed = (currData.speed * 0.621371).toFixed(0)
        }

        incidentsTr += incidentsTrTpl.compile(currData)
      }
      $incidentsWindow.find('thead').html(incidentsThTpl.compile())
      $incidentsTable.find('tbody').html(incidentsTr)
    }
    this.getIncidentsTable = function () {
      return $incidentsTable
    }

    this.setTableGridInst = function (tableGridInst) {
      incidentsTableGridInst = tableGridInst
    }
    this.getTableGridInst = function () {
      return incidentsTableGridInst
    }
    this.getIncidentsWindow = function () {
      return $incidentsWindow
    }
    this.setIncidentsWindow = function ($incidentsWindowHTMl) {
      $incidentsWindow = $incidentsWindowHTMl
    }
    this.calcIncidentsTableTh = function () {
      var th = $incidentsTable.closest('.control-holder').find('th:visible')
      $incidentsTable.find('tr:first td').each(function (i) {
        th.eq(i).width(($(this).width() - th.eq(i).width()) + th.eq(i).width())
      })
    }
    this.generateVehicleAndIncidentDetailsTable = function (trIndex) {
      if (!incidentsJson.data[trIndex].hasVideoData) {
        $incidentDetails.find('.tab-control td.video').hide()
      }
      if (!incidentsJson.data[trIndex].hasGraphData) {
        $incidentDetails.find('.tab-control td.g-forces').hide()
      }
      $vehicleAndIncidentDetailsTab.find('tbody').html(vehicleAndIncidentDetailsTbodyTpl.compile(incidentsJson.data[trIndex]))
    }
    this.showIncidentDetails = function () {
      $incidentDetails.fadeIn(400)
    }
    this.hideIncidentDetails = function () {
      $incidentDetails.fadeOut(400)
    }
    this.initPopup = function () {
      $incidentDetails.removeAttr('style')
      if ($incidentDetails.find('.max-min_btn').hasClass('active')) {
        incidentsInst.maximiseIncidentDetails()
      }
    }
    this.minimiseIncidentDetails = function () {
      $incidentDetails.find('.max-min_btn').toggleClass('active')
      $incidentDetails.find('.details-info').slideUp(400)
    }
    this.maximiseIncidentDetails = function () {
      $incidentDetails.find('.max-min_btn').toggleClass('active')
      $incidentDetails.find('.details-info').slideDown(400)
    }
    this.initScroll = function () {
      $frame = $incidentsTable.closest('.scroll-content')
      $wrap = $frame.parent()
      $HTMLincidents = $incidentsTable.closest('.incidents')
      var inited = false
      var $HTMLincidentsHeight = $HTMLincidents.outerHeight() - $HTMLincidents.find('.menu').outerHeight() - $HTMLincidents.find('.control-panel').outerHeight() - $HTMLincidents.find('.info').outerHeight()

      function initSly () {
        $frame.sly({
          horizontal: 0,
          speed: 300,
          easing: 'linear',
          scrollBar: $wrap.find('.scrollbar'),
          scrollBy: 100,
          dragHandle: 1,
          dynamicHandle: 1,
          clickBar: 1
        })
        inited = true
      }
      initSly()
      function reloadScroll () {
        $frame.attr('style', '')

        if ($incidentsTable.outerHeight() > $HTMLincidentsHeight) {
          $frame.height($HTMLincidentsHeight)

          if (!inited) {
            initSly()
          }

          $frame.sly('reload')
          $wrap.find('.scrollbar').show()
        } else {
          $frame.sly('destroy')
          $wrap.find('.scrollbar').hide()
          inited = false
        }
      }
      reloadScroll()
      $(window).resize(reloadScroll)
    }
    $incidentDetails.draggable({
      containment: 'parent',
      cancel: '.close-btn',
      handle: $incidentDetails.find('.header-panel'),
      drag: function () {
        $(this).css('height', 'auto')
        $(this).css('width', '100%')
      },
      stop: function () {
        $(this).css('height', 'auto')
        $(this).css('width', '100%')
      }
    })

    this.bindEvents = function () {
      var $incidentDetailsTabs = $incidentDetails.find('.tab')
      var $currentActiveRow, $currentDetailIncident
      var detailsPositions = {}
      $incidentsTable.find('.button').click(function () {
        incidentsInst.initPopup()
        $currentActiveRow = $(this).closest('tr')
        $currentDetailIncident = $(this).closest('tr').attr('data-incidentid')
        $currentActiveRow.addClass('active').siblings().removeClass('active')
        incidentsInst.generateVehicleAndIncidentDetailsTable($(this).closest('tr').index())
        $incidentDetailsTabs.removeClass('active').eq(0).addClass('active')
        $incidentDetails.find('.tab-control td:first').addClass('active').siblings().removeClass('active')

        if (leftPanel.hasClass('active')) {
          $('#content .map-wrap').animate({
            'margin-left': 0
          }, 800, function () {
            leftPanel.removeClass('active')
            $('#content .map-wrap').removeClass('active')
          })
        }

        if (!$('.slide-button', leftPanel).hasClass('active')) {
          $('.slide-button', leftPanel).addClass('active')
          leftPanel.animate({ left: -leftPanel.find('.incidents')[0].clientWidth }, 800)
        }

        incidentsInst.showIncidentDetails()
        if (snailTrail.isOnMap()) {
          snailTrail.removeSnailTrailFromMap()
        }
        incidentsInst.showIncidentOnMap($currentDetailIncident)
        if (inited) {
          $incidentDetailsFrame.sly('toStart', true)
        }
      })
      $incidentDetails.find('.close-btn').off('click').click(function () {
        detailsPositions = {}
        if ($('.slide-button', leftPanel).hasClass('active')) {
          $('.slide-button', leftPanel).removeClass('active')
          leftPanel.animate({ left: 0 }, 800)
        }
        $incidentsTable.find('tr').removeClass('active')
        incidentsInst.initPopup()
        incidentsInst.hideIncidentDetails()
        if (snailTrail.isOnMap()) {
          snailTrail.removeSnailTrailFromMap()
        }
        incidentsInst.showIncidentsOnMap()
      })
      $incidentDetails.find('.max-min_btn').off('click').click(function () {
        if ($(this).hasClass('active')) {
          $incidentDetails.animate({ top: detailsPositions.top, left: detailsPositions.left }, 300)
          incidentsInst.maximiseIncidentDetails()
        } else {
          detailsPositions.top = $incidentDetails[0].offsetTop
          detailsPositions.left = $incidentDetails[0].offsetLeft
          incidentsInst.minimiseIncidentDetails()
          $incidentDetails.animate({ top: window.innerHeight * 0.96 + 'px', left: '260px' }, 300)
        }
      })
      $incidentDetails.find('.tab-control td').off('click').click(function () {
        var $control = $(this)
        var index = $control.index()
        var $currTab = $incidentDetailsTabs.eq(index)
        if ($control.hasClass('active')) return
        $control.addClass('active').siblings().removeClass('active')
        if ($currTab.hasClass('video')) {
          incidentsInst.insertVideo(incidentIdList[$currentActiveRow.attr('data-incidentid')].videoUrl)
          if (snailTrail.isOnMap()) {
            snailTrail.removeSnailTrailFromMap()
            incidentsInst.showIncidentsOnMap()
          }
        } else if ($currTab.hasClass('snail-trail-details')) {
          snailTrail.setTableGrid(new TableGrid($currTab.find('.info-holder'), { hideDropdown: true }))
          snailTrail.loadSnailTrail($currentActiveRow.attr('data-incidentid'), false, function () {
            snailTrail.generateSnailTrail()
            incidentsInst.reloadIncidentDetailsScroll()
            incidentsInst.removeIncidentsFromMap()
            snailTrail.showSnailTrailOnMap()
          })
        } else if ($currTab.hasClass('g-forces')) {
          incidentsInst.renderCharts($currentActiveRow.attr('data-incidentid'))
          if (snailTrail.isOnMap()) {
            snailTrail.removeSnailTrailFromMap()
            incidentsInst.showIncidentsOnMap()
          }
        } else {
          if (snailTrail.isOnMap()) {
            snailTrail.removeSnailTrailFromMap()
            // incidentsInst.showIncidentsOnMap();
            incidentsInst.showIncidentOnMap($currentDetailIncident)
          }
        }
        $incidentDetailsTabs.removeClass('active')
        $currTab.addClass('active')
      })
      $incidentsWindow.find('.control-panel').off('click').click(function () {
        if (snailTrail.isOnMap()) {
          snailTrail.removeSnailTrailFromMap()
          incidentsInst.showIncidentsOnMap()
        }
        if ($incidentDetails.is(':visible')) {
          incidentsInst.hideIncidentDetails()
        }
      })
      $(window).off('resize.incidents').on('resize.incidents', function () {
        $incidentsWindow.find('table [style]').attr('style', '')
        $incidentsWindow.width(Math.round($(window).width() * 0.85))
        TableGridHandler().calculateFlexibleGrid(incidentsTableGridInst)
        $incidentsWindow.width($incidentsTable.outerWidth())
        incidentsInst.calcIncidentsTableTh()
      })
    }
    function onSelectLoadIncidents () {
      var fromDatepicker = $('.from', $incidentsWindow)
      var toDatepicker = $('.to', $incidentsWindow)
      var dateParams
      if (checkDateValidity()) {
        dateParams = {
          from: {
            date: fromDatepicker.val().match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/ig)[0],
            time: fromDatepicker.val().match(/[0-9]{2}:[0-9]{2}/ig)[0]
          },
          to: {
            date: toDatepicker.val().match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/ig)[0],
            time: toDatepicker.val().match(/[0-9]{2}:[0-9]{2}/ig)[0]
          }
        }
      } else {
        return
      }
      if (incidentsInst.isOnMap()) {
        incidentsInst.removeIncidentsFromMap()
      }
      incidentsInst.loadIncidents(selectedGroup, dateParams.from, dateParams.to, function (json) {
        incidentsInst.generateIncidentsTable(json)
        incidentsInst.getIncidentsWindow().width(Math.round($(window).width() * 0.85))
        TableGridHandler().calculateFlexibleGrid(incidentsInst.getTableGridInst())
        incidentsInst.getIncidentsWindow().width(incidentsInst.getTableGridInst().getTable().outerWidth())
        incidentsInst.calcIncidentsTableTh()
        incidentsInst.initScroll()
        incidentsInst.bindEvents()
        incidentsInst.showIncidentsOnMap()
        incidentsInst.initDraggable()
      })
    }
    function getLastDayInMonth (month) {
      var date = new Date()
      month -= 1
      date.setMonth(month + 1, 0)
      return date.getDate()
    }
    function checkDateValidity () {
      var fromDatepicker = $('.from', $incidentsWindow)
      var toDatepicker = $('.to', $incidentsWindow)
      var datepickers = [fromDatepicker, toDatepicker]
      var fromDate = new Date()
      var toDate = new Date()
      var dates = [fromDate, toDate]
      for (var i = 0; i < 2; i++) {
        var currDateTime = datepickers[i].val()
        var date
        var time
        var maxDaysInMonth
        if ((/[0-9]{2}\.[0-9]{2}\.[0-9]{4}/ig).test(currDateTime) && (/[0-9]{2}:[0-9]{2}/ig).test(currDateTime)) {
          date = currDateTime.match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/ig)[0].split('.')
          time = currDateTime.match(/[0-9]{2}:[0-9]{2}/ig)[0].split(':')
          date[0] = parseInt(date[0])
          date[1] = parseInt(date[1])
          if (date[1] > 12 || date[1] == 0 || date[0] > getLastDayInMonth(date[1]) || date[0] == 0 || parseInt(time[0]) > 23 || parseInt(time[1]) > 59) {
            alert('Invalid date format.')
            return false
          }
          dates[i].setFullYear(date[2], parseInt(date[1]) - 1, date[0])
          dates[i].setHours(time[0])
          dates[i].setMinutes(time[1])
        } else {
          alert('Invalid date format.')
          return false
        }
      }
      if (fromDate.getTime() > toDate.getTime() || toDate.getTime() < fromDate.getTime()) {
        alert('Invalid date range.')
        return false
      }
      return true
    }
    function insertIncidentsDatepickerTrigger () {
      $('.to,.from', $incidentsWindow).each(function () {
        $(this).closest('.date').find('.calendar-btn').append($(this).next('button'))
      })
    }
    this.refreshControlPanel = function () {
      var today = new Date()
      var fromDatepicker = $('.from', $incidentsWindow)
      var toDatepicker = $('.to', $incidentsWindow)
      var select = $('select', $incidentsTable.closest('.incidents'))
      select.val('live_incidents')
      select.selectmenu('refresh')
      today.setHours(0)
      today.setMinutes(0)
      fromDatepicker.datetimepicker('setDate', today)
      today.setHours(23)
      today.setMinutes(59)
      toDatepicker.datetimepicker('setDate', today)
    }
    this.initDatepicker = function () {
      var today = new Date()
      var fromDatepicker = $('.from', $incidentsWindow)
      var toDatepicker = $('.to', $incidentsWindow)
      fromDatepicker.datetimepicker({
        showOn: 'button',
        buttonImage: '',
        selectOtherMonths: true,
        beforeShow: function (input, inst) {
          $(this).closest('.date').find('.calendar-btn').addClass('active')
          if (snailTrail.isOnMap()) {
            snailTrail.removeSnailTrailFromMap()
            incidentsInst.showIncidentsOnMap()
          }
          if ($incidentDetails.is(':visible')) {
            incidentsInst.hideIncidentDetails()
          }
        },
        onClose: function (dateText, inst) {
          insertIncidentsDatepickerTrigger()
          $(this).closest('.date').find('.calendar-btn').removeClass('active')
        },
        onSelect: function (selectedDate) {
          $(this).closest('.journey-report-wrap').find('.button-list .button.active').removeClass('active')
        }
      })
      toDatepicker.datetimepicker({
        showOn: 'button',
        buttonImage: '',
        selectOtherMonths: true,
        beforeShow: function (input, inst) {
          $(this).closest('.date').find('.calendar-btn').addClass('active')
          if (snailTrail.isOnMap()) {
            snailTrail.removeSnailTrailFromMap()
            incidentsInst.showIncidentsOnMap()
          }
          if ($incidentDetails.is(':visible')) {
            incidentsInst.hideIncidentDetails()
          }
        },
        onClose: function (selectedDate) {
          insertIncidentsDatepickerTrigger()
          $(this).closest('.date').find('.calendar-btn').removeClass('active')
        },
        onSelect: function (selectedDate) {
          $(this).closest('.journey-report-wrap').find('.button-list .button.active').removeClass('active')
        }
      })
      fromDatepicker.closest('li').find('.button.submit').click(function () {
        var currDatepicker = $(this)
        onSelectLoadIncidents()
      })
      insertIncidentsDatepickerTrigger()
      today.setHours(0)
      today.setMinutes(0)
      fromDatepicker.datetimepicker('setDate', today)
      today.setHours(23)
      today.setMinutes(59)
      toDatepicker.datetimepicker('setDate', today)
      datepickerInited = true
    }
    this.isDatepickerInited = function () {
      return datepickerInited
    }
    this.initSelect = function () {
      var fromDatepicker = $('.from', $incidentsWindow)
      var toDatepicker = $('.to', $incidentsWindow)
      var date = new Date()
      var select = $('select', $incidentsTable.closest('.incidents'))
      select.selectmenu({
        width: 138,
        create: function (e, ui) {
          var dropdown = $(this).selectmenu('menuWidget').parent()
          $(this).parent().append(dropdown)
          select.on('selectmenuselect', function () {
            var action = this.value
            toDatepicker.datepicker('option', 'minDate', null)
            fromDatepicker.datepicker('option', 'maxDate', null)
            switch (action) {
              case 'live_incidents':
                date.setHours(0)
                date.setMinutes(0)
                fromDatepicker.datetimepicker('setDate', date)
                date.setHours(23)
                date.setMinutes(59)
                toDatepicker.datetimepicker('setDate', date)
                break
              case 'last_week':
                date.setDate(date.getDate() - (date.getDay() + 6))
                date.setHours(0)
                date.setMinutes(0)
                fromDatepicker.datetimepicker('setDate', date)
                date.setDate(date.getDate() + 6)
                date.setHours(23)
                date.setMinutes(59)
                toDatepicker.datetimepicker('setDate', date)
                break
              case 'this_week':
                date.setDate(date.getDate() - (date.getDay() - 1))
                date.setHours(0)
                date.setMinutes(0)
                fromDatepicker.datetimepicker('setDate', date)
                date.setDate(date.getDate() + 6)
                date.setHours(23)
                date.setMinutes(59)
                toDatepicker.datetimepicker('setDate', date)
                break
              case 'last_month':
                date.setDate(0)
                date.setHours(0)
                date.setMinutes(0)
                toDatepicker.datetimepicker('setDate', date)
                date.setDate(1)
                date.setHours(23)
                date.setMinutes(59)
                fromDatepicker.datetimepicker('setDate', date)
                break
              default:
                alert(' action: ' + action + ' not described')
                break
            }
            date.setTime(Date.now())
            insertIncidentsDatepickerTrigger()
            onSelectLoadIncidents()
          })
        }
      })
    }
    this.initDraggable = function () {
      var minWidth = 15
      $incidentsWindow.find('table th>i').draggable({
        containment: $incidentsWindow,
        helper: 'clone',
        axis: 'x',
        start: function (e, ui) {
          ui.helper.css({ background: 'black', height: $incidentsTable.height() + 30, width: 1, 'margin-left': 5 })
        },
        stop: function (e, ui) {
          var thIndex = $(this).parent().index()
          var $th = $(this).parent()
          var $td = $incidentsTable.find('tr:first td:eq(' + thIndex + ')')
          var $nextTd = $td.next()
          var offset = $th.offset().left + $th.width() - ui.offset.left
          var tdWidth = $td.width() - offset
          var nextTdWidth = $nextTd.width() + offset

          if (nextTdWidth <= 0) {
            tdWidth = nextTdWidth + tdWidth - minWidth
            nextTdWidth = minWidth
          }
          if (tdWidth <= 0) {
            nextTdWidth = nextTdWidth + tdWidth - minWidth
            tdWidth = minWidth
          }
          $td.width(tdWidth)
          $nextTd.width(nextTdWidth)
          TableGridHandler().saveGridCurrentSizes(incidentsTableGridInst)
          incidentsInst.calcIncidentsTableTh()
        }
      })
    }
  }
  new Incidents()

  function UserProfile () {
    if (UserProfile.prototype._singletonInstance) {
      return UserProfile.prototype._singletonInstance
    }
    UserProfile.prototype._singletonInstance = this

    var userProfile = this
    var $HTML = $('.user-profile-panel')
    var $popup = $('.user-profile-popup__password')
    var $currPass = $('#current-password')
    var $addPhoto = $('.user-profile-popup__image')
    var $form = $('.upload-box')
    var $preview = $('.file-preview')
    var droppedFile = false
    var pass
    var currentPassword = ''
    var $uploadCropHtml = $('.crop-photo')
    var $uploadCrop = $('#crop-photo')
    var cropPhoto
    var $progressBar = $('#wls-progress')
    var isDeleted = false
    var modalTop = null

    // *******************
    // User profile popups

    this.isVisible = function () {
      return $HTML.is(':visible')
    }
    this.show = function () {
      var topBarHeight = $('.top-bar').outerHeight()

      modalTop = topBarHeight
      $HTML.css({ left: 0, top: topBarHeight }).show()
    }
    this.hide = function () {
      if ($popup.is(':visible')) {
        userProfile.popupHide()
      }
      if ($addPhoto.is(':visible')); {
        userProfile.addPhotoPopupHide()
      }
      $HTML.hide()
    }
    this.addPhotoPopupShow = function (left, top) {
      $addPhoto.css({ left: left, top: top }).show()
    }
    this.addPhotoPopupHide = function () {
      if (systemAlert.isOpen()) {
        systemAlert.hideAlert()
      }

      if (!userProfile.isUserProfilePhoto()) {
        $preview.hide()
      } else {
        var $input = $form.find('input[type=file]')

        $input.val('')

        $preview.find('img').attr('src', userProfileInfo.profileImage)
        $preview.find('.profile-file-preview > span').prop('title', '').text('')
        $preview.show()
      }
      if (cropPhoto) {
        cropPhoto.croppie('destroy')
        $form.show()
      }

      droppedFile = false
      $addPhoto.find('.action-row>.save').addClass('btn-disabled')
      $addPhoto.hide()
    }
    this.popupShow = function (left, top) {
      $popup.css({ left: left, top: top }).show(300)
    }
    this.popupHide = function () {
      var currPass = $popup.find('.curr-pass')
      var newPass = $popup.find('.new-pass')
      var confirmPass = $popup.find('.confirm-pass')

      currPass.find('input').val('')
      newPass.find('input').val('')
      newPass.find('i.new-password-icon').css('display', 'none')
      newPass.removeClass('approved')
      confirmPass.find('input').val('')
      confirmPass.find('i').css('display', 'none')
      confirmPass.removeClass('approved').removeClass('error')
      userProfile.popupDisabled()
      $popup.hide(300)
    }
    this.popupDisabled = function () {
      $popup.find('.new-pass').addClass('disabled')
      $popup.find('.confirm-pass').addClass('disabled')
      $popup.find('.new-pass > input').prop('disabled', true)
      $popup.find('.confirm-pass > input').prop('disabled', true)
      $popup.find('.action-row>.save').addClass('btn-disabled')
    }
    this.popupUnabled = function () {
      $popup.find('.new-pass').removeClass('disabled')
      $popup.find('.confirm-pass').removeClass('disabled')
      $popup.find('.new-pass > input').prop('disabled', false)
      $popup.find('.confirm-pass > input').prop('disabled', false)
    }

    // User profile popups
    // *******************

    // *******************
    // User-menu image

    this.showImageInMenu = function () {
      $('.user-menu .categories .user-profile i').css('display', 'none')
      $('.user-menu .categories .user-profile img').css('display', 'block').attr('src', userProfileInfo.profileImage)
    }
    this.removeImageFromMenu = function () {
      $('.user-menu .categories .user-profile i').css('display', 'block')
      $('.user-menu .categories .user-profile img').css('display', 'none').attr('src', userProfileInfo.profileImage)
    }

    // User-menu image
    // *******************

    this.isUserProfilePhoto = function () {
      return userProfileInfo.profileImage.length > 0
    }

    function isAlphaNumeric (str) {
      var re = /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/
      return re.test(str)
    }

    function addFilePreview (file) { // file preview in Add photo popup
      var reader = new FileReader()

      reader.onloadend = function () {
        $preview.find('.profile-file-preview > span').prop('title', file[0].name).text(file[0].name)
        $preview.find('img').attr('src', reader.result)
        $preview.show()
      }

      if (file[0]) {
        reader.readAsDataURL(file[0])
      } else {
        $preview.find('.profile-file-preview>img').src = ''
        $preview.hide()
      }
    }
    function uploadImage (img) { // upload image to user profile
      var formData = new FormData()

      formData.append('file', img)
      $.ajax({
        url: '/api/settings/profileImage',
        type: 'post',
        cache: false,
        contentType: false,
        processData: false,
        dataType: 'json',
        data: formData
      }).done(function (res) {
        userProfileInfo = res

        $HTML.find('.add-profile-image span').text('Change photo')

        $HTML.find('#default-profile-img').attr('src', userProfileInfo.profileImage)

        $preview.find('img').attr('src', userProfileInfo.profileImage)

        $addPhoto.css('left', 'calc(50% - 200px)')

        userProfile.showImageInMenu()
        userProfile.addPhotoPopupHide()

        $addPhoto.find('.action-row>.save').addClass('btn-disabled')

        $form.find('input[type=file]').val('')

        droppedFile = false
      }).error(function (error) {
        console.log(error)
        alert('Something wrong')
      })
    }
    function removeImage () { // remove image to user profile
      $.ajax({
        url: '/api/settings/profileImage',
        type: 'delete'
      })
        .done(function (res) {
          userProfileInfo = res

          $HTML.find('.add-profile-image span').text('Add photo')
          $HTML.find('#default-profile-img').attr('src', '/img/profile_user_icon.png')

          $preview.find('img').attr('src', userProfileInfo.profileImage)

          $addPhoto.find('.action-row>.save').addClass('btn-disabled')

          userProfile.removeImageFromMenu()
          userProfile.addPhotoPopupHide()
        }).error(function () {
          alert('Something wrong')
        })
    }
    this.getSettings = function () {
      $.get('api/settings/user').done(function (json) {
        userProfileInfo = json

        $HTML.find('.user-info__name').text(userProfileInfo.fullName)
        $HTML.find('.user-info__email').text(userProfileInfo.email)

        if (userProfileInfo.profileImage.length > 0) {
          $HTML.find('.add-profile-image span').text('Change photo')
          $HTML.find('#default-profile-img').attr('src', userProfileInfo.profileImage)

          userProfile.showImageInMenu()

          $preview.find('img').attr('src', userProfileInfo.profileImage)

          $preview.show()
        }
      })
    }

    function approveNewPassword () {
      if (currentPassword.length < 6) {
        $popup.find('.curr-pass').addClass('error')
      } else {
        $.post('/api/settings/changePassword', { newPassword: pass, currentPassword: currentPassword }).done(function (msg) {
          $popup.siblings('.row').children('label').children('input').val('')
          userProfile.popupHide()
        })
      }
    }

    function demoUpload (file) {
      function readFile (input) {
        if (input && input[0]) {
          var reader = new FileReader()

          reader.onload = function (e) {
            $('.upload-demo').addClass('ready')
            cropPhoto.croppie('bind', {
              url: reader.result
            }).then(function () {
              $addPhoto.find('.action-row>.save').removeClass('btn-disabled')
              $form.hide()
              $preview.hide()
            })
          }

          reader.readAsDataURL(input[0])
        } else {
          console.log("Sorry - you're browser doesn't support the FileReader API")
        }
      }

      cropPhoto = $uploadCrop.croppie({
        enableExif: true,
        viewport: { width: 300, height: 300 },
        boundary: { width: 450, height: 300 }
      })

      readFile(file)

      $addPhoto.find('.action-row>.save').off('click').on('click', function (ev) {
        if (!droppedFile) {
          removeImage()
        } else {
          cropPhoto.croppie('result', {
            type: 'canvas',
            size: 'viewport'
          }).then(function (resp) {
            uploadImage(resp)
          })
        }
        isDeleted = false
      })
    }

    // Initialize user profile actions
    function init () {
      var _html = null

      $HTML.draggable({
        containment: 'parent',
        cancel: '.close-btn',
        handle: $HTML.find('.header-panel')
      })

      $addPhoto.draggable({
        containment: 'parent',
        cancel: '.close-btn',
        handle: $addPhoto.find('.header-panel'),
        stop: function () {
          $(this).css({ width: 'auto', height: 'auto' })
        }
      })

      $popup.draggable({
        containment: 'parent',
        cancel: '.close-btn',
        handle: $popup.find('.header-panel')
      })

      $HTML.find('.change-pass').off('click').click(function (e) {
        e.preventDefault()
        var $offset = $(this).offset()
        userProfile.popupShow($offset.left - 92, $offset.top + $(this).outerHeight() + 20)
      })
      $HTML.find('.add-profile-image').off('click').click(function () {
        userProfile.addPhotoPopupShow('calc(50% - 200px)', modalTop)
      })
      $form.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
        e.preventDefault()
        e.stopPropagation()
      })
        .on('dragover dragenter', function () {
          $form.addClass('is-dragover')
        })
        .on('dragleave dragend drop', function () {
          $form.removeClass('is-dragover')
        })
        .on('drop', function (e) {
          e.preventDefault()
          droppedFile = false
          droppedFile = e.originalEvent.dataTransfer.files

          if (droppedFile.length > 1) {
            systemAlert.initError('Multiple file upload is not allowed', function (html) {
              _html = html
            })
          } else {
            if (droppedFile[0].type !== 'image/png' && droppedFile[0].type !== 'image/jpeg') {
              systemAlert.initError('Wrong file format. Only .png and .jpeg format are available.', function (html) {
                _html = html
              })
            } else {
              if (systemAlert.isOpen()) {
                _html.html()
                systemAlert.hideAlert()
              }
              // $addPhoto.find(".action-row>.save").removeClass("btn-disabled");
              demoUpload(droppedFile)
              _html = null
            }
          }
        })
      $form.find('.box_file').on('change', function (e) {
        e.preventDefault()

        droppedFile = this.files

        if (droppedFile[0].type !== 'image/png' && droppedFile[0].type !== 'image/jpeg') {
          systemAlert.initError('Wrong file format. Only .png and .jpeg format are available.', function (html) {
            _html = html
          })
        } else {
          if (systemAlert.isOpen()) {
            _html.html()
            systemAlert.hideAlert()
          }
          demoUpload(droppedFile)

          _html = null
        }
        e.stopPropagation()
      })
      $addPhoto.find('.action-row>.save').off('click').click(function () {
        if (!droppedFile) {
          removeImage()
        }
        isDeleted = false
      })
      $addPhoto.find('.action-row>.cancel').off('click').click(function () {
        var $input = $form.find('input[type=file]')
        droppedFile = false

        $input.val('')

        if (!$form.is(':visible')) {
          cropPhoto.croppie('destroy')
          $addPhoto.css('left', 'calc(50% - 200px)')

          $addPhoto.find('.action-row>.save').addClass('btn-disabled')
          $form.show()
          if (!userProfile.isUserProfilePhoto()) {
            $preview.hide()
          } else {
            $preview.find('img').attr('src', userProfileInfo.profileImage)
            $preview.show()
          }
        } else {
          if (isDeleted) {
            $preview.find('img').attr('src', userProfileInfo.profileImage)
            $preview.show()
            $addPhoto.find('.action-row>.save').addClass('btn-disabled')
            isDeleted = false
          } else {
            userProfile.addPhotoPopupHide()
          }
        }
      })
      $addPhoto.find('.close-btn').off('click').click(function () {
        var $input = $form.find('input[type=file]')
        droppedFile = false

        $input.val('')

        if (!$form.is(':visible')) {
          cropPhoto.croppie('destroy')

          $addPhoto.css('left', 'calc(50% - 200px)')
          $addPhoto.find('.action-row>.save').addClass('btn-disabled')
          $form.show()

          if (!userProfile.isUserProfilePhoto()) {
            $preview.hide()
          } else {
            $preview.find('img').attr('src', userProfileInfo.profileImage)
            $preview.show()
          }
        }
        userProfile.addPhotoPopupHide()
      })
      $popup.find('.close-btn,.action-row>.cancel').off('click').click(function (e) {
        e.preventDefault()
        userProfile.popupHide()
      })
      $popup.find('.action-row>.save').off('click').click(function (e) {
        e.preventDefault()
        if (pass.length >= 6) {
          approveNewPassword()
        }
      })
      $popup.find('.curr-pass > input').off('click').keyup(function (e) {
        var confirmPass = $popup.find('.confirm-pass')
        var confirmPassVal = confirmPass.find('input').val()
        var newPass = $popup.find('.new-pass')
        var newPassVal = newPass.find('input').val()

        $popup.find('.curr-pass').removeClass('error')
        currentPassword = $(this).val()

        if (currentPassword.length > 5) {
          userProfile.popupUnabled()
        } else {
          userProfile.popupDisabled()
        }

        if (currentPassword.length > 5 && (newPassVal.length > 5 && newPassVal === confirmPassVal)) {
          $popup.find('.action-row>.save').removeClass('btn-disabled')
        }
      })
      $popup.find('.new-pass > input').off('click').keyup(function (e) {
        var newPass = $popup.find('.new-pass')
        var pass = $(this).val()
        var passIsValid = isAlphaNumeric(pass)
        var confirmPass = $popup.find('.confirm-pass')
        var confirmPassVal = confirmPass.find('input').val()
        newPass.removeClass('approved')
        $popup.find('.action-row>.save').addClass('btn-disabled')

        if (pass.length > 0 && pass.length < 6) {
          newPass.find('i.new-password-icon').css('display', 'block')
          newPass.removeClass('confirm')
          $popup.find('.new-pass').prop('title', 'Your password must be at least 6 characters long')
        } else if (pass.length >= 6 && passIsValid) {
          if (pass !== confirmPassVal) {
            confirmPass.find('i').prop('title', 'Password doesn\'t match')
            confirmPass.find('i').css('display', 'block')
            confirmPass.removeClass('confirmed')
            confirmPass.removeClass('approved').addClass('error')
          }
          confirmPass.find('i').prop('title')
          newPass.addClass('approved')
          newPass.find('i.new-password-icon').css('display', 'block')
          newPass.addClass('confirm')
          $popup.find('.new-pass').prop('title', '')
        } else if (pass.length >= 6 && !passIsValid) {
          newPass.find('i.new-password-icon').css('display', 'block')
          newPass.removeClass('confirm')
          $popup.find('.new-pass').prop('title', 'Password must contain letters and digits')
        } else {
          newPass.find('i.new-password-icon').css('display', 'none')
          newPass.removeClass('confirm')
          $popup.find('.new-pass').prop('title', '')
        }
      })
      $popup.find('.confirm-pass > input').off('click').keyup(function (e) {
        var newPass = $popup.find('.new-pass')
        var newPassValue = newPass.find('input').val()
        var confirmPass = $popup.find('.confirm-pass')
        var confirm = e.target.value

        pass = ''
        confirmPass.find('i').prop('title', 'Password doesn\'t match')
        confirmPass.find('i').css('display', 'block')
        $popup.find('.action-row>.save').addClass('btn-disabled')

        confirmPass.removeClass('confirmed')
        confirmPass.removeClass('approved').addClass('error')
        if (confirm.length < 1) {
          confirmPass.find('i').css('display', 'none')
        } else if (newPassValue.length >= 6 && newPassValue === confirm && currentPassword) {
          confirmPass.addClass('approved').removeClass('error')
          confirmPass.addClass('confirmed')
          confirmPass.find('i').prop('title', '')
          $popup.find('.action-row>.save').removeClass('btn-disabled')
          pass = newPassValue
        }
      })
      $popup.find('.curr-pass i').off('click').click(function (e) {
        var icon = $popup.find('.curr-pass')
        if (icon.hasClass('hide-pass')) {
          icon.removeClass('hide-pass').addClass('show-pass')
          icon.find('i').prop('title', 'Hide password')
          $currPass.attr('type', $currPass.attr('type') === 'password' ? 'text' : 'password')
        } else {
          icon.addClass('hide-pass').removeClass('show-pass')
          icon.find('i').prop('title', 'Show password')
          $currPass.attr('type', $currPass.attr('type') === 'password' ? 'text' : 'password')
        }
      })

      $preview.find('.remove-btn').off('click').click(function () {
        if (userProfile.isUserProfilePhoto()) {
          isDeleted = true
          var $input = $form.find('input[type=file]')

          $input.val('')

          $preview.find('img').attr('src', userProfileInfo.profileImage)
          $preview.find('.profile-file-preview > span').prop('title', '').text('')
        }

        isDeleted = true
        $preview.hide()
        droppedFile = false
        $addPhoto.find('.action-row>.save').removeClass('btn-disabled')
      })
    };
    init()
  }
  new UserProfile()

  function userMenuHover () {
    var IncidentsSing = Incidents()
    var today = $.datepicker.formatDate('dd.mm.yy ', new Date())
    var journeyReportPopupName = reportHandler.getReportName('journeyReportPopup')
    var loneWorkerJourneyReportPopupName = reportHandler.getReportName('loneWorkerJourneyReport')
    var journeyReportName = reportHandler.getReportName('journeyReport')
    var menu = $('.user-menu')
    var tab = $('.tab', menu)
    var item = $('.categories>li', menu)
    var tooltip = $('.tooltip', item)
    var shadow = $('.inner-shadow', item)
    var reportTabHasScroll = false
    var refreshButton = $('.refresh_button')

    item.hover(function () {
      // We do it to prevent hover of refresh button because it should only work on mapping page.
      // Only mapping page has timer object.
      var currItem = $(this)
      if (!wls.isTimerPresent() && currItem.is('li.refresh')) {
        return
      }
      if (currItem.is('li.refresh')) {
        refreshButton.stop().animate({ 'z-index': 1 }, 900)
      }
      shadow.eq($(this).index()).hide()
      tooltip.eq($(this).index()).stop().animate({ left: -(tooltip.eq($(this).index()).outerWidth() - $(this).width() - 1) }, 800)
    }, function () {
      var itemIndex = $(this).index()
      tooltip.eq(itemIndex).stop().animate({ left: 0 }, 800, function () {
        shadow.eq(itemIndex).show()
      })

      refreshButton.stop().animate({ 'z-index': 0 }, 900)
    })
    item.filter('[rel]').click(function (e) {
      if (searchVehicle) {
        searchVehicle.resetTableResults()
        if (searchVehicle.isVisible()) {
          searchVehicle.hideSearchResult()
        }
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }
      }
      if (leftPanel.find('.actions-holder').is(':Visible')) {
        searchVehicle.resetActionHolder()
      }
      var currItem = $(this)
      var selectedTab = $('#' + currItem.attr('rel'))
      var activeItem = item.filter('.active')
      if (currItem.hasClass('reports pressed')) {
        tab.filter(':visible').animate({ width: 'hide' }, 600)
        currItem.toggleClass('pressed')
        currItem.siblings().removeClass('active')
        currItem.addClass('active')
      } else if (tab.filter(':visible').size() && currItem.hasClass('pressed')) {
        tab.filter(':visible').animate({ width: 'hide' }, 600)
        currItem.toggleClass('pressed')
      } else if (tab.filter(':visible').size() && currItem.get(0) != activeItem.get(0)) {
        tab.filter(':visible').hide()
        selectedTab.show()
        if (!reportTabHasScroll) {
          initScroll($('#reports .scroll-content'), 69)
          reportTabHasScroll = true
        }
        currItem.addClass('pressed')
      } else {
        tab.filter(':visible').hide()
        selectedTab.animate({ width: 'toggle' }, 600, function () {
          wlsMapTools.correctPosition()
          if (!reportTabHasScroll) {
            initScroll($('#reports .scroll-content'), 69)
            reportTabHasScroll = true
          }
        })
        currItem.toggleClass('pressed')
      }
      currItem.siblings().removeClass('pressed')
      if (!currItem.is('li.refresh')) {
        refreshSettingsPopupView.hideActivePopup()
      }

      if (currItem.is('li.refresh') && $(e.target).is('i')) {
        var refreshSettingsView = refreshSettingsPopupView.findSubView('refreshSettings')
        refreshSettingsView.render('{}')
        refreshSettingsView.updateRefreshSettings(userPrefs.refreshSmSeconds)
        refreshSettingsPopupView.show()
        refreshSettingsPopupView.alignBy($('.refresh_button'), 'bottomRight', { top: 10, left: -240 })
      }
    })

    item.filter('.refresh').click(function (e) {
      if ($(e.target).is('i') || !wls.isTimerPresent()) {
        return false
      }
      wls.updateCars()
    })
    item.filter('.mapping,.incidents,.geo-zones,.user-profile,.proximity,.alerts,.vehicle-checks,.camera-events,.sms').click(function () {
      var li = $(this)
      var time = 0

      wlsMapTools.dropActiveFeatures()

      if (window.mapCanvas.isDrawingControlVisible()) {
        window.mapCanvas.switchDrawingControl()
        window.mapCanvas.disableDrawing()
      }

      if (manageDrivers.isVisible()) {
        manageDrivers.destroyEl()
      }

      if (dallasKeyManager.isVisible()) {
        dallasKeyManager.destroyEl()
      }

      if (tachographKeyManager.isVisible()) {
        tachographKeyManager.destroyEl()
      }

      if (permissionsPlus.isVisible()) {
        permissionsPlus.destroyEl()
      }

      if (geoFenceAlertView && geoFenceAlertView.alertListPopupView.$el.is(':visible')) {
        geoFenceAlertView.alertListPopupView.closeBtnHandler()
      }

      if (proximityTabset) {
        proximityTabset.destroy(li)
        proximityTabset = null
        window.mapCanvas.removeAllProximityLines()
      }

      if (reportHandler.getReport('TimeSheet.do')) {
        reportHandler.removeReport('TimeSheet.do')
        window.reportPageIsActive = false

        wls.resetSavedStates()

        $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      }

      if (reportHandler.getReport('Scheduled.do')) {
        reportHandler.removeReport('Scheduled.do')
        window.reportPageIsActive = false

        wls.resetSavedStates()

        $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      }

      createdPoiPin = window.createdPoiPin = false
      li.addClass('active')
      activeMenuItem = li
      li.siblings().removeClass('active')
      li.siblings().removeClass('pressed')
      selectedGroup = $.cookie('lastViewedGroup')
      tab.filter(':visible').animate({ width: 'hide' }, 600)

      if (cameraEvents.isVisible()) {
        cameraEvents.destroyEl()
      }

      if (fleetManagement.isVisible()) {
        fleetManagement.destroyEl()
      }

      if (smsManager.isVisible()) {
        smsManager.destroyEl()
      }

      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
      if (JourneyDetails().isVisible()) {
        JourneyDetails().hide()
      }
      if (searchVehicle) {
        searchVehicle.resetTableResults()
        if (searchVehicle.isVisible()) {
          searchVehicle.hideSearchResult()
        }
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }
      }
      if (JourneyDetails().getSnailTrail().isOnMap()) {
        JourneyDetails().getSnailTrail().removeSnailTrailFromMap()
      }
      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      if (sendMessage.isVisible()) {
        sendMessage.destroyEl()
      }
      if ($('.select-to-sms-popup').css('visibility') === 'visible') {
        $('.select-to-sms-popup').css('visibility', 'hidden')
      }
      window.wlsMapTools.multipleSelectToolIsActive = false
      if (mapSource && mapSource.startsWith('MSVE')) {
        window.mapCanvas.map.entities.pop()
      } else if (mapSource && mapSource === 'GOOGLEMAPS') {
        window.mapCanvas.cleanUp()
      }
      $('body').css('cursor', 'auto')
      if (window.multipleSelect) {
        window.multipleSelect.isDragging = false
      }
      $('.multiple-select-btn').removeClass('active')
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
      if (activityLogPopupView && activityLogPopupView.isVisible()) {
        activityLogPopupView.destroy()
      }
      liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
      // MAP TOOLS
      wlsMapTools.activateDropdowns()
      // MAP TOOLS
      wlsMapTools.deactivateShowVehReg()

      if (li.hasClass('sms')) {
        wlsMapTools.saveState()
        wls.resetState()

        if (towAwayAlertManagerView) {
          towAwayAlertManagerView.destroy()
        }

        if (GeoZones().isVisible()) {
          GeoZones().hide()
          if (poiManager) {
            poiManager.hide()
          }
          if (aoiManager) {
            aoiManager.hide()
          }
        }

        if (UserProfile().isVisible()) {
          UserProfile().hide()
        }

        var topBar = content.find('.top-bar')

        if (leftPanel.hasClass('active')) {
          leftPanel.parent().animate({ 'margin-left': 0 }, 500)
          leftPanel.removeClass('active').parent().removeClass('active')
        }
        if (topBar.is(':visible')) {
          topBar.animate({
            'margin-left': 0
          }, 500, null)
        }

        $('.left-panel').fadeOut(400)
        smsManager.loadSmsManager()

        wlsMapTools.hideToolsFrame()
        wlsMapTools.hide()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
      } else if (li.hasClass('camera-events')) {
        wlsMapTools.saveState()
        wls.resetState()

        if (towAwayAlertManagerView) {
          towAwayAlertManagerView.destroy()
        }

        if (GeoZones().isVisible()) {
          GeoZones().hide()
          if (poiManager) {
            poiManager.hide()
          }
          if (aoiManager) {
            aoiManager.hide()
          }
        }

        if (UserProfile().isVisible()) {
          UserProfile().hide()
        }

        var topBar = content.find('.top-bar')

        if (leftPanel.hasClass('active')) {
          leftPanel.parent().animate({ 'margin-left': 0 }, 500)
          leftPanel.removeClass('active').parent().removeClass('active')
        }
        if (topBar.is(':visible')) {
          topBar.animate({
            'margin-left': 0
          }, 500, null)
        }

        $('.left-panel').fadeOut(400)
        cameraEvents.loadCameraEventReport()

        wlsMapTools.hideToolsFrame()
        wlsMapTools.hide()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
      } else if (li.hasClass('vehicle-checks')) {
        if (vehicleChecks && vehicleChecks.isVisible()) {
          return
        }

        wlsMapTools.saveState()
        wls.resetState()

        if (towAwayAlertManagerView) {
          towAwayAlertManagerView.destroy()
        }

        if (GeoZones().isVisible()) {
          GeoZones().hide()
          if (poiManager) {
            poiManager.hide()
          }
          if (aoiManager) {
            aoiManager.hide()
          }
        }

        if (UserProfile().isVisible()) {
          UserProfile().hide()
        }

        // vehicleChecks = new VehicleDefectReportPageView();
        vehicleChecks = new VehicleChecksView()

        wlsMapTools.hideToolsFrame()
        wlsMapTools.hide()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
      } else if (li.hasClass('alerts')) {
        if (towAwayAlertManagerView && towAwayAlertManagerView.isVisible()) {
          return
        }

        if (vehicleChecks) {
          vehicleChecks.destroy(true)
        }

        wlsMapTools.saveState()
        wls.resetState()

        if (GeoZones().isVisible()) {
          GeoZones().hide()
          if (poiManager) {
            poiManager.hide()
          }
          if (aoiManager) {
            aoiManager.hide()
          }
        }

        if (UserProfile().isVisible()) {
          UserProfile().hide()
        }

        towAwayAlertManagerView = new TowAwayAlertManagerView()

        wlsMapTools.hideToolsFrame()
        wlsMapTools.hide()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
      } else if (li.hasClass('proximity')) {
        window.mapCanvas.deletePolygon()

        if (towAwayAlertManagerView) {
          towAwayAlertManagerView.destroy()
        }

        if (vehicleChecks) {
          vehicleChecks.destroy(true)
        }

        wlsMapTools.saveState()
        wls.resetState()

        if (view.getActiveState() !== 'dual') {
          view.switchStateTo('fleet')
        } else {
          view.switchStateTo('dual')
        }

        if (GeoZones().isVisible()) {
          GeoZones().hide()
          if (poiManager) {
            poiManager.hide()
          }
          if (aoiManager) {
            aoiManager.hide()
          }
        }
        if (UserProfile().isVisible()) {
          UserProfile().hide()
        }
        proximityTabset = new ProximityPageView()
        wlsMapTools.hideToolsFrame()
        wlsMapTools.hide()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
      } else if (li.hasClass('mapping')) {
        addPoi.lon = null
        addPoi.lat = null

        addPoiPinByRightClickFromMap = false
        addPoiPinByRightClickFromReport = false
        addPoiPinByRightClickFromJourneyReport = false
        addPoiPinByRightClickFromActivityLog = false
        addPoiPinByRightClickFromTimeOnSiteReport = false
        addPoiPinByRightClickFromTimeOnSiteReportDetails = false

        if (leftPanel.find('.right-col').is(':visible')) {
          return
        }

        if (towAwayAlertManagerView) {
          towAwayAlertManagerView.destroy()
        }
        if (vehicleChecks) {
          vehicleChecks.destroy(true)
        }

        if (proximityTabset) {
          proximityTabset.destroy(li)
          proximityTabset = null
        }

        if (GeoZones().isVisible()) {
          startVehiclesUpdate = true
          if (userPrefs.htmlGroupTabsAllowed) {
            // window.groupTabsetView.collection.fetch({success: function () {
            //     startVehiclesUpdate = true;
            var groupId = $.cookie('lastViewedGroup')
            $.cookie('lastViewedGroup', null) // reset cookie
            // window.groupTabsetView.selectGroup(groupId);
            $.cookie('lastViewedGroup', groupId)
            groupTabsetView.render()
            // }});
          } else {
            // startVehiclesUpdate = true;
            wls.requestCars($.cookie('lastViewedGroup'), function () {
              wls.removeCars()
              wls.restoreSelectionMenuState()
              $('.left-panel').fadeIn(400, function () {
                $('.left-panel .right-col .vehicle-holder .scroll-content').triggerHandler('resize', [$('.left-panel .vehicle-holder .slidee').height()])
              })
            }, true)
          }
          GeoZones().hide()
          if (poiManager) {
            poiManager.hide()
          }
          if (aoiManager) {
            aoiManager.hide()
          }
        }
        if (UserProfile().isVisible()) {
          UserProfile().hide()
        }
        if (leftPanel.hasClass('active')) {
          leftPanel.parent().animate({ 'margin-left': 0 }, 500)
          leftPanel.removeClass('active').parent().removeClass('active')
          time = 500
        }
        if (leftPanel.find('.actions-holder').is(':Visible')) {
          searchVehicle.resetActionHolder()
        }
        if (leftPanel.find('.control-panel,.control-panel+.row').is(':visible') && view.getActiveState() !== 'fleet') {
          leftPanel.find('.control-panel,.control-panel+.row').hide()
        }
        if (leftPanel.hasClass('no-group-tab') && settings.data.htmlGroupTabsAllowed) {
          leftPanel.removeClass('no-group-tab').addClass('has-group-tab')
        }
        leftPanel.delay(time).fadeOut(300, function () {
          leftPanel.find('.slide-button').removeClass('active')
          leftPanel.css('left', 0)
          leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
          leftPanel.fadeIn(300, function () {
            if (view.getActiveState() !== 'dual') {
              view.switchStateTo('fleet')
            } else {
              view.switchStateTo('dual')
            }
          })
        })
        wlsMapTools.initInput()
        IncidentsSing.hideIncidentDetails()
        if (IncidentsSing.getSnailTrail().isOnMap()) {
          IncidentsSing.getSnailTrail().removeSnailTrailFromMap()
        } else {
          IncidentsSing.removeIncidentsFromMap()
        }
      } else if (li.hasClass('incidents')) {
        if (leftPanel.find('.incidents').is(':visible')) {
          return
        }

        if (towAwayAlertManagerView) {
          towAwayAlertManagerView.destroy()
        }

        if (vehicleChecks) {
          vehicleChecks.destroy(true)
        }

        if (settings.data.htmlGroupTabsAllowed) {
          window.groupTabsetView.findSubView('colorPicker').hide()
        }
        if (GeoZones().isVisible()) {
          GeoZones().hide()
          if (poiManager) {
            poiManager.hide()
          }
          if (aoiManager) {
            aoiManager.hide()
          }
        }
        if (UserProfile().isVisible()) {
          UserProfile().hide()
        }
        wlsMapTools.saveState()
        wls.resetState()
        IncidentsSing.loadIncidents(selectedGroup, { date: today, time: '00:00' }, { date: today, time: '23:59' }, function (json) {
          IncidentsSing.generateIncidentsTable(json)
          IncidentsSing.setTableGridInst(new TableGrid(IncidentsSing.getIncidentsWindow(), {
            flexible:
            {
              vehReg: 7,
              account: 7,
              subGroup: 7,
              group: 7,
              date: 3,
              //                                        "trigger" : 4,
              address: 12,
              postcode: 2,
              //                                        "timezone" : 5,
              speed: 1,
              //                                        "xGForce" : 6,
              //                                        "yGForce" : 6,
              //                                        "zGForce" : 6,
              //                                        "cabPhone" : 7,
              details: 2
            },
            hideDropdown: true
          }
          )
          )
          if (json.singleAccount) {
            TableGridHandler().calculateWithout.apply(IncidentsSing.getTableGridInst(), ['account'])
          }
          if (!json.subGroupColumnVisible) {
            TableGridHandler().calculateWithout.apply(IncidentsSing.getTableGridInst(), ['subGroup'])
          }
          activityLog.hide()
          activityLog.removeLogEntryMarker()
          proximity.resetPin()
          IncidentsSing.showIncidentsWindow(function () {
            IncidentsSing.getIncidentsWindow().width(Math.round($(window).width() * 0.85))
            TableGridHandler().calculateFlexibleGrid(IncidentsSing.getTableGridInst())
            IncidentsSing.getIncidentsWindow().width(IncidentsSing.getTableGridInst().getTable().outerWidth())
            IncidentsSing.calcIncidentsTableTh()
            IncidentsSing.initScroll()
            IncidentsSing.bindEvents()
          })
          IncidentsSing.showIncidentsOnMap()
          IncidentsSing.initSelect()
          IncidentsSing.refreshControlPanel()
          IncidentsSing.initDraggable()
          wlsMapTools.hideToolsFrame()
          wlsMapTools.hide()
          if (!IncidentsSing.isDatepickerInited()) {
            IncidentsSing.initDatepicker()
          }
        })
      } else if (li.hasClass('user-profile')) {
        addPoiPinByRightClickFromMap = false
        addPoiPinByRightClickFromReport = false
        addPoiPinByRightClickFromJourneyReport = false
        addPoiPinByRightClickFromActivityLog = false
        addPoiPinByRightClickFromTimeOnSiteReport = false
        addPoiPinByRightClickFromTimeOnSiteReportDetails = false

        if (UserProfile().isVisible()) {
          return
        };

        if (towAwayAlertManagerView) {
          towAwayAlertManagerView.destroy()
        }

        if (vehicleChecks) {
          vehicleChecks.destroy(true)
        }

        var topBar = content.find('.top-bar')
        if (settings.data.htmlGroupTabsAllowed) {
          window.groupTabsetView.findSubView('colorPicker').hide()
        }
        if (GeoZones().isVisible()) {
          GeoZones().hide()
          if (poiManager) {
            poiManager.hide()
          }
          if (aoiManager) {
            aoiManager.hide()
          }
        }
        wlsMapTools.saveState()
        wls.resetState()
        if (leftPanel.hasClass('active')) {
          leftPanel.parent().animate({ 'margin-left': 0 }, 500)
          leftPanel.removeClass('active').parent().removeClass('active')
          time = 500
        }
        if (topBar.is(':visible')) {
          topBar.animate({
            'margin-left': 0
          }, 500, null)
        }
        leftPanel.delay(time).fadeOut(300, function () {
          leftPanel.find('.slide-button').removeClass('active')
          leftPanel.css('left', 0)
          leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
        })
        IncidentsSing.hideIncidentDetails()
        if (IncidentsSing.getSnailTrail().isOnMap()) {
          IncidentsSing.getSnailTrail().removeSnailTrailFromMap()
        } else {
          IncidentsSing.removeIncidentsFromMap()
        }

        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
        activityLog.hide()
        activityLog.removeLogEntryMarker()
        proximity.resetPin()
        wlsMapTools.hideToolsFrame()
        wlsMapTools.hide()

        if (proximityTabset) {
          proximityTabset.destroy(li)
          proximityTabset = null
        }

        UserProfile().show()
      } else {
        addPoi.lon = null
        addPoi.lat = null

        addPoiPinByRightClickFromMap = false
        addPoiPinByRightClickFromReport = false
        addPoiPinByRightClickFromJourneyReport = false
        addPoiPinByRightClickFromActivityLog = false
        addPoiPinByRightClickFromTimeOnSiteReport = false
        addPoiPinByRightClickFromTimeOnSiteReportDetails = false

        startVehiclesUpdate = false
        if (GeoZones().isVisible()) {
          return
        }
        if (UserProfile().isVisible()) {
          UserProfile().hide()
        }

        if (towAwayAlertManagerView) {
          towAwayAlertManagerView.destroy()
        }

        if (vehicleChecks) {
          vehicleChecks.destroy(true)
        }

        if (searchVehicle && leftPanel.find('.actions-holder').is(':Visible')) {
          searchVehicle.resetActionHolder()
        }
        if (view.getActiveState() !== 'dual') {
          view.state = 'fleet'
        }
        if (geoMonitorAlertView) {
          geoMonitorAlertView.destroy()
          geoMonitorAlertView = null
        }
        var topBar = content.find('.top-bar')
        driverWorkingHoursTodayPopupView.hideActivePopup()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
        if (leftPanel.hasClass('active')) {
          leftPanel.parent().animate({ 'margin-left': 0 }, 500)
          leftPanel.removeClass('active').parent().removeClass('active')
          time = 500
        }
        if (topBar.is(':visible')) {
          topBar.animate({
            'margin-left': 0
          }, 500, null)
        }
        if (htmlGroupTabsAllowed) {
          window.groupTabsetView.findSubView('colorPicker').hide()
        }
        leftPanel.delay(time).fadeOut(300, function () {
          leftPanel.find('.slide-button').removeClass('active')
          leftPanel.css('left', 0)
          leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
        })
        IncidentsSing.hideIncidentDetails()
        if (IncidentsSing.getSnailTrail().isOnMap()) {
          IncidentsSing.getSnailTrail().removeSnailTrailFromMap()
        } else {
          IncidentsSing.removeIncidentsFromMap()
        }
        activityLog.hide()
        activityLog.removeLogEntryMarker()
        proximity.resetPin()
        wlsMapTools.saveState()
        wls.resetState()
        wlsMapTools.hideToolsFrame()
        wlsMapTools.hide()

        if (proximityTabset && proximityTabset.isVisible()) {
          proximityTabset.hide()
          proximityTabset = null
        }

        if (GeoZones().isLoaded()) {
          GeoZones().show()
          GeoZones().getTabset().getControls().first().click()
          if (poiManager) {
            poiManager.switchDataMode('create')
            poiManager.bindMapClick()
          }
        } else {
          GeoZones().loadHTML(function () {
            GeoZones().setTabset(new Tabset($('[data-tabset="geoZones"]')))
            GeoZones().getHTML().find('.slide-button').off('click').click(function () {
              var slideBtn = $(this)
              slideBtn.toggleClass('active')
              if (slideBtn.hasClass('active')) {
                GeoZones().getHTML().animate({ marginLeft: -GeoZones().getHTML().outerWidth() }, 600)
              } else {
                GeoZones().getHTML().animate({ marginLeft: 0 }, 600)
              }
            })
            GeoZones().getTabset().getControls().filter('[data-target=poiManager]').one('click', function () {
              poiManager = new OOIManager(GeoZones().getTabset().getTab('poiManager'))
              window.poiManager = poiManager

              if (aoiManager) {
                aoiManager.hide()
                aoiManager.unbindMapClick()
              }
              if (geoFenceAlertView && geoFenceAlertView.alertListPopupView.$el.is(':visible')) {
                geoFenceAlertView.alertListPopupView.closeBtnHandler()
              }
              poiManager.bindMapClick()
              poiManager.loadAccountList(function () {
                poiManager.switchDataMode('create')
              })
              $(this).mousedown(function () {
                if ($(this).hasClass('active')) {
                  return
                }
                if (geoFenceAlertView && geoFenceAlertView.alertListPopupView.$el.is(':visible')) {
                  geoFenceAlertView.alertListPopupView.closeBtnHandler()
                }
                if (poiManager) {
                  poiManager.switchDataMode('create')
                  poiManager.bindMapClick()
                }
                if (aoiManager) {
                  aoiManager.hide()
                  aoiManager.unbindMapClick()
                  if (window.mapCanvas.isDrawingControlVisible()) {
                    window.mapCanvas.switchDrawingControl()
                  }
                }
              })
            })
            GeoZones().getTabset().getControls().filter('[data-target=aoiManager]').one('click', function () {
              aoiManager = new AOIManager(GeoZones().getTabset().getTab('aoiManager'))
              window.aoiManager = aoiManager

              if (poiManager) {
                poiManager.hide()
                poiManager.unbindMapClick()
              }
              if (geoFenceAlertView && geoFenceAlertView.alertListPopupView.$el.is(':visible')) {
                geoFenceAlertView.alertListPopupView.closeBtnHandler()
              }
              aoiManager.bindMapClick()
              aoiManager.loadAccountList(function () {
                aoiManager.switchDataMode('create')
              })
              $(this).mousedown(function () {
                if ($(this).hasClass('active')) {
                  return
                }
                if (geoFenceAlertView && geoFenceAlertView.alertListPopupView.$el.is(':visible')) {
                  geoFenceAlertView.alertListPopupView.closeBtnHandler()
                }
                if (poiManager) {
                  poiManager.hide()
                  poiManager.unbindMapClick()
                }
                if (aoiManager) {
                  aoiManager.switchDataMode('create')
                  aoiManager.bindMapClick()
                  if (!window.mapCanvas.isDrawingControlVisible()) {
                    window.mapCanvas.switchDrawingControl()
                  }
                }
              })
            })
            GeoZones().getTabset().getControls().filter('[data-target=geoFenceAlert]').one('click', function () {
              geoFenceAlertView = new GeoFenceAlertView({ el: GeoZones().getTabset().getTab('geoFenceAlert') })
              if (poiManager) {
                poiManager.hide()
                poiManager.unbindMapClick()
              }
              if (aoiManager) {
                aoiManager.hide()
                aoiManager.unbindMapClick()
              }
              $(this).mousedown(function () {
                if ($(this).hasClass('active')) {
                  return
                }
                if (poiManager) {
                  poiManager.hide()
                  poiManager.unbindMapClick()
                }
                if (aoiManager) {
                  aoiManager.hide()
                  aoiManager.unbindMapClick()
                }
              })
            })
            GeoZones().getTabset().getControls().filter('[data-target=geoMonitor]').on('click', function () {
              if (!geoMonitorAlertView) {
                geoMonitorAlertView = new GeoMonitorAlertView({ el: GeoZones().getTabset().getTab('geoMonitor') })
              }

              if (geoFenceAlertView && geoFenceAlertView.alertListPopupView.$el.is(':visible')) {
                geoFenceAlertView.alertListPopupView.closeBtnHandler()
              }

              if (poiManager) {
                poiManager.hide()
                poiManager.unbindMapClick()
              }

              if (aoiManager) {
                aoiManager.hide()
                aoiManager.unbindMapClick()
              }

              $(this).mousedown(function () {
                if ($(this).hasClass('active')) {
                  return
                }

                if (geoFenceAlertView && geoFenceAlertView.alertListPopupView.$el.is(':visible')) {
                  geoFenceAlertView.alertListPopupView.closeBtnHandler()
                }

                if (poiManager) {
                  poiManager.hide()
                  poiManager.unbindMapClick()
                }

                if (aoiManager) {
                  aoiManager.hide()
                  aoiManager.unbindMapClick()
                }
              })
            })
            GeoZones().show(function () {
              GeoZones().getTabset().getControls().first().click()
            })
          })
        }
      }
      if (reportHandler.hasActiveReport()) {
        reportHandler.hideReport(reportHandler.getActiveReport(), null, !li.hasClass('mapping'))
      }
    })
    $('.account-list .account-folder', menu).click(function () {
      $(this).next().slideToggle(400, function () {
        $(this).closest('li').toggleClass('active')
      })
    })
  }
  function initReportClickHandler () {
    var journeyReportPopupName = reportHandler.getReportName('journeyReportPopup')
    var loneWorkerJourneyReportPopupName = reportHandler.getReportName('loneWorkerJourneyReport')
    var speedingReportPopupName = reportHandler.getReportName('speedingReportPopup')
    var journeyReportName = reportHandler.getReportName('journeyReport')

    $('#reports li:has(:not(a[data-flex], a[data-jsf])), #admin li:has(>.account-folder>a[data-html-page])').click(function (e) {
      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      if (sendMessage.isVisible()) {
        sendMessage.destroyEl()
      }
      if ($('.select-to-sms-popup').css('visibility') === 'visible') {
        $('.select-to-sms-popup').css('visibility', 'hidden'); if (mapSource && mapSource.startsWith('MSVE')) {
          window.mapCanvas.map.entities.pop()
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          window.mapCanvas.cleanUp()
        }
        window.wlsMapTools.multipleSelectToolIsActive = false
      }
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
      if (towAwayAlertManagerView) {
        towAwayAlertManagerView.destroy()
      }

      if (vehicleChecks) {
        vehicleChecks.destroy(true)
      }

      if (proximityTabset) {
        proximityTabset.destroy($(this))
        proximityTabset = null
      }
      if (cameraEvents.isVisible()) {
        cameraEvents.destroyEl()
      }
      if (fleetManagement.isVisible()) {
        fleetManagement.destroyEl()
      }
      if (smsManager.isVisible()) {
        smsManager.destroyEl()
      }
      if (reportHandler.getReport('TimeSheet.do')) {
        reportHandler.removeReport('TimeSheet.do')
        window.reportPageIsActive = false

        wls.resetSavedStates()

        $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      }

      if (reportHandler.getReport('Scheduled.do')) {
        reportHandler.removeReport('Scheduled.do')
        window.reportPageIsActive = false

        wls.resetSavedStates()

        $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      }

      startVehiclesUpdate = true
      var url = this.querySelector('a').href
      var reportName = url.match(/[A-Za-z_]+\.[A-Za-z]+$/i)[0]
      var report
      var incidents = Incidents()
      var snailTrail = null

      e.preventDefault()
      driverWorkingHoursTodayPopupView.hideActivePopup()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()
      if (JourneyDetails().isVisible()) {
        JourneyDetails().hide()
      }

      if (UserProfile().isVisible()) {
        UserProfile().hide()
      }

      if (manageDrivers.isVisible()) {
        manageDrivers.destroyEl()
      }

      if (dallasKeyManager.isVisible()) {
        dallasKeyManager.destroyEl()
      }

      if (tachographKeyManager.isVisible()) {
        tachographKeyManager.destroyEl()
      }

      if (permissionsPlus.isVisible()) {
        permissionsPlus.destroyEl()
      }

      if (activityLogPopupView && activityLogPopupView.isVisible()) {
        activityLogPopupView.destroy()
      }
      liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
      if (reportHandler.hasActiveReport() && reportHandler.getActiveReport() != reportHandler.getReport(reportName)) {
        reportHandler.hideReport(reportHandler.getActiveReport(), null, true)
      }
      if (view.getActiveState() !== 'dual') {
        view.switchStateTo('fleet', true)
      }
      if (searchVehicle) {
        searchVehicle.resetTableResults()
        if (searchVehicle.isVisible()) {
          searchVehicle.hideSearchResult()
        }
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }
      }

      if ($(e.currentTarget).find('a.group').text() === 'Fleet Management+') {
        fleetManagement.loadFleetManagementReport()
      } else if ($(e.currentTarget).find('a.group').text() === 'Manage Drivers') {
        manageDrivers.loadManageDriversPopup()
      } else if ($(e.currentTarget).find('a.group').text() === 'Dallas Keys') {
        dallasKeyManager.loadDallasKeyManagerPopup()
      } else if ($(e.currentTarget).find('a.group').text() === 'Tachograph Card') {
        tachographKeyManager.loadTachographKeyManagerPopup()
      } else if ($(e.currentTarget).find('a.group').text() === 'Manage Users') {
        permissionsPlus.loadPermissionsPlus()
      } else if (!reportHandler.hasReport(reportName)) {
        if (reportName != 'TimeSheet.do' &&
          reportName != 'Scheduled.do' &&
          reportName != 'TimeOnSite.do' &&
          reportName != 'IdlingReportFuelUsage.do' &&
          reportName != 'DriverBehaviour.do' &&
          reportName != 'LoneWorkerJourney.do' &&
          reportName != 'Speeding.do' &&
          reportName != 'Journey.do' &&
          reportName != 'FuelTankLevelVariance.do' &&
          reportName != 'FuelUsageReport.do' &&
          reportName != 'VehicleDefectReport.do' &&
          reportName !== 'Alerts.do' &&
          reportName != 'Out_Of_Hours_Query.do' &&
          reportName != 'DigitalInput.do' &&
          reportName != 'Mileage.do' &&
          reportName != 'DetailedReport.do' &&
          reportName != 'Idling.do' &&
          reportName != 'LeagueTable.do') {
          reportLoader.loadReport(url, function reportOnLoad () {
            reportHandler.addReport(this, reportName)
            reportHandler.initReport(reportName, function () {
              report = reportHandler.getReport(reportName)
              report.getHTML().css({ opacity: 0, display: 'block' })
              reportHandler.generateVehicleList(reportName)
              reportHandler.reloadReportScroll(reportName)
              report.getHTML().attr('style', '')
              reportHandler.showReport(reportName)
            })
          })
        } else {
          if (reportName === 'TimeSheet.do' || reportName === 'Scheduled.do') {
            reportLoader.loadReport(url, function reportOnLoad () {
              reportHandler.addReport(this, reportName)
              reportHandler.initReport(reportName, function () {
                report = reportHandler.getReport(reportName)
                report.getHTML().find('.report').css('display', 'block')
                reportHandler.generateVehicleList(reportName)
                window.reportPageIsActive = true
                $('#content').append(report.getHTML())
                wls.resetState()
                leftPanel.fadeOut(400)
              })
            })
          } else {
            reportHandler.initReport(reportName)
          }
        }
      } else {
        report = reportHandler.getReport(reportName)
        if (!report.isVisible()) {
          report.getHTML().css({ opacity: 0, display: 'block' })
          reportHandler.generateVehicleList(reportName)
          reportHandler.reloadReportScroll(reportName)
          report.getHTML().attr('style', '')
          reportHandler.showReport(reportName)
        }
      }
      if (GeoZones().isVisible()) {
        GeoZones().hide()
        if (poiManager) {
          poiManager.hide()
        }
        if (aoiManager) {
          aoiManager.hide()
        }

        if (geoFenceAlertView && geoFenceAlertView.alertListPopupView.$el.is(':visible')) {
          geoFenceAlertView.alertListPopupView.closeBtnHandler()
        }
        leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
      }
      if (incidents.windowIsVisible() && (reportName === journeyReportPopupName || reportName === loneWorkerJourneyReportPopupName || reportName === speedingReportPopupName)) {
        snailTrail = incidents.getSnailTrail()
        incidents.hideIncidentsWindow(function () {
          leftPanel.find('.right-col').show().siblings().not('.slide-button').hide()
          leftPanel.fadeOut(300, function () {
            if (view.getActiveState() !== 'dual') {
              view.switchStateTo('fleet')
            } else {
              view.switchStateTo('dual')
            }
          })
        })
        incidents.hideIncidentDetails()
        incidents.getSnailTrail()
        if (snailTrail.isOnMap()) {
          snailTrail.removeSnailTrailFromMap()
        } else {
          incidents.removeIncidentsFromMap()
        }
      }
      wlsMapTools.saveState()
      wlsMapTools.hideToolsFrame()
      wlsMapTools.hide()
      // MAP TOOLS
      wlsMapTools.activateDropdowns()
      // MAP TOOLS
      wlsMapTools.deactivateShowVehReg()
      $('li.' + $(this).closest('.tab').attr('id')).click()
    })
  }

  function getUserMenu (callback) {
    request = $.post('/getRightMenuPanel.do').done(function (msg) {
      var html = $(msg)
      request = false
      if (html.size()) {
        html.hide()
        $('#content').append(html)
        html.fadeIn(600)
      }
      if (callback) callback()
    }).error(function (msg, errorTxt) {
      alert('error')
      request = false
    })
  }
  function insertDatepickerTrigger () {
    $('#to,#from').each(function () {
      $(this).closest('.date').find('.calendar-btn').append($(this).next('button'))
    })
  }
  function initDatepicker () {
    $('#from').datepicker({
      showOn: 'button',
      buttonImage: '',
      beforeShow: function (input, inst) {
        $(this).closest('.date').find('.calendar-btn').addClass('active')
      },
      onClose: function (selectedDate) {
        $('#to').datepicker('option', 'minDate', selectedDate)
        insertDatepickerTrigger()
        $(this).closest('.date').find('.calendar-btn').removeClass('active')
      },
      onSelect: function () {
        $(this).closest('.journey-report-wrap').find('.button-list .button.active').removeClass('active')
      }
    })
    $('#to').datepicker({
      showOn: 'button',
      buttonImage: '',
      beforeShow: function (input, inst) {
        $(this).closest('.date').find('.calendar-btn').addClass('active')
      },
      onClose: function (selectedDate) {
        $('#from').datepicker('option', 'maxDate', selectedDate)
        insertDatepickerTrigger()
        $(this).closest('.date').find('.calendar-btn').removeClass('active')
      },
      onSelect: function () {
        $(this).closest('.journey-report-wrap').find('.button-list .button.active').removeClass('active')
      }
    })

    insertDatepickerTrigger()
  }
  function rezoomMapFlx () {
    if (!mapPaused) { window.mapCanvas.correctMap() }
  }
  window.rezoomMapFlx = rezoomMapFlx
  function userSettings () { // user settings Class
    var us = this
    var storage = localStorage
    var data = this.data = {}
    var fnQueue = []

    this.isOutdated = function () {
      return $.cookie('timestamp', Number) > data.lastUpdated
    }

    this.get = function (silent) {
      return request = $.post('/getSettings.do').done(function (msg) {
        var json = msg
        request = false
        if (typeof json === 'string') return

        if (json.data) {
          us.parse(json.data)
          storage.loaded = true
          us.data = json.data
          if (!silent) {
            callOnLoad()
          }
        }
      }).error(function (msg, errorTxt) {
        alert('error')
        request = false
      })
    }

    this.parse = function (obj) {
      storage.setItem('json', JSON.stringify(obj))
    }

    this.getCache = function () {
      return JSON.parse(storage.getItem('json'))
    }

    this.setTimeStamp = function () {
      data.lastUpdated = $.cookie('timestamp', Number)
      storage.setItem('lastUpdated', data.lastUpdated)
    }

    this.checkTimeStamp = function () {
      if (!('lastUpdated' in storage)) {
        us.setTimeStamp()
      } else {
        data.lastUpdated = parseInt(storage.getItem('lastUpdated'))
      }
      if (us.isOutdated() || !storage.loaded) {
        us.get()
        us.setTimeStamp()
      } else {
        us.data = us.getCache()
        callOnLoad()
      }
    }

    this.onLoad = function (eventHandler) {
      fnQueue.push(eventHandler)
    }
    function callOnLoad () {
      var len = fnQueue.length
      for (var i = 0; i < len; i++) {
        fnQueue[i]()
      }
    }
  }
  function Template (string) { // Template Class
    var compiledString
    this.compile = function (object) {
      var key
      var placeholder = '-'
      compiledString = string
      for (key in object) {
        if (object[key] == null) object[key] = placeholder
        compiledString = compiledString.replace(new RegExp('\\$\\{' + key + '\\}+', 'g'), object[key])
      }
      return compiledString
    }
    this.replace = function (query) {
      string = string.replace(query, '')
    }
  }

  function Report ($reportHTMl) { // Report Class
    var inited = false
    var $frame = null
    var scrolls = []
    var scrollType

    this.getHTML = function () {
      return $reportHTMl
    }
    this.setInitStatus = function (booleanVal) {
      inited = booleanVal
    }
    this.setScrollFrame = function ($scrollContent) {
      $frame = $scrollContent
    }
    this.getScrollFrame = function () {
      return $frame
    }
    this.hasScroll = function () {
      return $frame != null
    }
    this.isVisible = function () {
      return $reportHTMl.is(':visible')
    }
    this.setScrollType = function (_$frame, _scrollType) {
      scrolls[$frame.index(_$frame)] = _scrollType
    }
    this.getScrollType = function (_$frame) {
      return scrolls[$frame.index(_$frame)]
    }
  }

  function ReportLoader () { // ReportLoader Class
    var reportLinks = []

    this.loadReport = function (url, callback) {
      return request = $.post(url).done(function (msg) {
        var $html = $(msg)
        reportLinks.push(url)
        if (callback) callback.apply(new Report($html))
        request = false
      }).error(function (msg, errorTxt) {
        alert('error')
        request = false
      })
    }

    this.getReportLinks = function () {
      return reportLinks
    }
  }

  function ReportHandler () { // ReportHandler Class
    var reports = {}
    var reportInitObj = {}
    var reportHandlerInst = this
    var vehicleListHTML = ''
    var reportNames = {
      journeyReportPopup: { name: 'Journey.do' },
      journeyReport: { name: 'getJourneyReport.do' },
      loneWorkerJourneyReport: { name: 'LoneWorkerJourney.do' },
      driverBehaviourReport: { name: 'DriverBehaviour.do' },
      todaysJourneysReport: { name: 'todaysJourneys' },
      idlingReportFuelUsagePopup: { name: 'IdlingReportFuelUsage.do' },
      speedingReportPopup: { name: 'Speeding.do' },
      fuelTankLevelVarianceReportPage: { name: 'FuelTankLevelVariance.do' },
      fuelUsageReportPage: { name: 'FuelUsageReport.do' },
      vehicleDefectReportPage: { name: 'VehicleDefectReport.do' },
      drivingTimeReportPage: { name: 'DrivingTime.do' },
      alertManagerPage: { name: 'Alerts.do' },
      timeOnSiteReport: { name: 'TimeOnSite.do' },
      timeSheetReport: { name: 'TimeSheet.do' },
      scheduledReport: { name: 'Scheduled.do' },
      journeyCostReport: { name: 'JourneyCost.do' },
      outOfHoursQueryReport: { name: 'Out_Of_Hours_Query.do' },
      digitalInputReport: { name: 'DigitalInput.do' },
      mileageReport: { name: 'Mileage.do' },
      detailedReport: { name: 'DetailedReport.do' },
      idlingReport: { name: 'Idling.do' },
      leagueTable: { name: 'LeagueTable.do' },
      businessPrivate: { name: 'BusinessPrivate.do' }
    }

    this.addReport = function (reportInst, reportName) {
      reports[reportName] = reportInst
      $('#content').append(reportInst.getHTML())
    }
    this.getReportName = function (property) {
      return reportNames[property].name
    }
    this.getReport = function (reportName) {
      return reports[reportName]
    }
    this.removeReport = function (reportNameOrInstance) {
      var $reportHTML

      if (typeof reportNameOrInstance === 'object') {
        for (var key in reports) {
          if (reports[key] === reportNameOrInstance) {
            delete reports[key]
            break
          }
        }
      } else {
        $reportHTML = reports[reportNameOrInstance].getHTML()
        $reportHTML.fadeOut(600, function () {
          $reportHTML.remove()
        })
        delete reports[reportNameOrInstance]
      }
    }
    this.hasReport = function (reportName) {
      return reports.hasOwnProperty(reportName)
    }
    this.getActiveReport = function () {
      for (var report in reports) {
        if (reports[report].isVisible()) {
          return reports[report]
        }
      }
      return false
    }
    this.hasActiveReport = function () {
      for (var report in reports) {
        if (reports[report].isVisible()) {
          return true
        }
      }
      return false
    }
    this.showReport = function (reportInstOrName, callback) {
      var $reportHTML
      if (reportInstOrName instanceof Report) {
        $reportHTML = reportInstOrName.getHTML()
      } else {
        $reportHTML = reports[reportInstOrName].getHTML()
      }
      $reportHTML.fadeIn(600, callback)
    }
    this.hideReport = function (reportInstOrName, callback, dontShowLeftPanel) {
      var $reportHTML
      if (typeof reportInstOrName === 'object' && 'destroy' in reportInstOrName) {
        reportInstOrName.destroy(dontShowLeftPanel)
        if (callback) callback()
      } else {
        if (reportInstOrName instanceof Report) {
          $reportHTML = reportInstOrName.getHTML()
        } else {
          $reportHTML = reports[reportInstOrName].getHTML()
        }
        $reportHTML.fadeOut(600, callback)
      }
    }
    this.getVehicleList = function () {
      return vehicleListHTML
    }
    this.generateVehicleList = function (reportName) {
      var reportPopup = reportHandlerInst.getReport(reportName).getHTML()
      var vehicleList = wls.getSelectedVehicles()
      var vehicleListWidth = 0
      vehicleListHTML = ''
      for (var id in vehicleList) {
        var currVehicle = vehicleList[id]
        vehicleListHTML += '<li>' +
          '<img src="' + currVehicle.getImgURL() + '" alt="">' +
          '<span>' + currVehicle.data.registrationNumber + '</span>' +
          '</li>'
      }
      vehicleListHTML = $(vehicleListHTML)
      reportPopup.find('.slidee').css('width', 'auto')
      reportPopup.find('.vehicle-list').html(vehicleListHTML)
      vehicleListHTML.each(function () {
        vehicleListWidth += $(this).outerWidth()
      })
      reportPopup.find('.slidee').width(vehicleListWidth + 1)
    }
    function attachReportScroll (reportName, scrollType) {
      var report = reportHandlerInst.getReport(reportName)
      var reportPopup = report.getHTML()
      var $frame = $('.scroll-content', reportPopup)

      report.setScrollFrame($frame)
      $frame.each(function (i) {
        var currFrame = $(this)
        var $wrap = currFrame.parent()
        report.setScrollType(currFrame, scrollType[i] ? 'horizontal' : 'vertical')
        currFrame.sly({
          horizontal: scrollType[i],
          speed: 300,
          easing: 'linear',
          scrollBar: $wrap.find('.scrollbar'),
          scrollBy: 100,
          dragHandle: 1,
          dynamicHandle: 1,
          clickBar: 1
        })
        if (scrollType[i] == 1) {
          if (currFrame.find('.slidee').width() > currFrame.width()) {
            $wrap.find('.scrollbar').show()
          }
        } else {
          if (currFrame.find('.slidee').height() > currFrame.height()) {
            $wrap.find('.scrollbar').show()
          }
        }
      })
    }
    this.reloadReportScroll = function (reportName) {
      var report = reportHandlerInst.getReport(reportName)
      var reportPopup = report.getHTML()
      var $frame = report.getScrollFrame()
      $frame.each(function () {
        var currFrame = $(this)
        var $wrap = currFrame.parent()
        var scrollType = report.getScrollType(currFrame)
        var contentSize = scrollType == 'horizontal' ? currFrame.find('.slidee').width() : currFrame.find('.slidee').height()
        var frameSize = scrollType == 'horizontal' ? currFrame.width() : currFrame.height()
        if (contentSize > frameSize) {
          $wrap.find('.scrollbar').show()
        } else {
          $wrap.find('.scrollbar').hide()
        }
        currFrame.sly('reload')
      })
    }
    this.destroyReportScroll = function (reportName) {
      var report = reportHandlerInst.getReport(reportName)
      var $frame = report.getScrollFrame()
      $frame.parent().find('.scrollbar').hide()
      $frame.find('.slidee').attr('style', '')
      $frame.attr('style', '')
      $frame.sly('destroy')
      report.setScrollFrame(null)
    }
    reportInitObj[reportNames.journeyReportPopup.name] = function () { // Journey report popup
      var currReport = this.report
      var reportName = this.reportName
      var $HTML = currReport.getHTML()
      var fromDatepicker = $('#from', $HTML)
      var toDatepicker = $('#to', $HTML)
      var fromTime = $('#fromTime', $HTML)
      var toTime = $('#toTime', $HTML)
      var date = new Date()

      $HTML.draggable({
        containment: 'parent',
        cancel: '.close-btn',
        handle: $HTML.find('.header-panel')
      })
      function checkTimeValidity () {
        var timeInputs = [fromTime, toTime]
        var timeInputsLen = timeInputs.length
        var currVal
        var timeArray
        var hours
        var minutes
        for (var i = 0; i < timeInputsLen; i++) {
          currVal = timeInputs[i].val()

          if ((/^[0-9]{2}:[0-9]{2}$/).test(currVal)) {
            timeArray = currVal.split(':')
            hours = parseInt(timeArray[0])
            minutes = parseInt(timeArray[1])

            if (hours > 23) {
              hours = 23
            }

            if (minutes > 59) {
              minutes = 59
            }
            if (hours < 10) {
              hours = '0' + hours
            }
            if (minutes < 10) {
              minutes = '0' + minutes
            }
            timeInputs[i].val(hours + ':' + minutes)
          } else {
            timeInputs[i].val('00:00')
          }
        }
      }
      function convertToMilliseconds (string) {
        var milliseconds = 0
        var second = 1000
        var minute = second * 60
        var hour = minute * 60
        var day = hour * 24
        var timeArr = [day, hour, minute, second]
        var parsedTime = string.split(/days|:/)
        var parsedTimeLen = parsedTime.length
        var timeArrLen = timeArr.length
        for (var i = parsedTimeLen; i != 0; i--) {
          milliseconds += timeArr[timeArrLen - i] * parseInt(parsedTime[(timeArrLen - i) - (timeArrLen - parsedTimeLen)])
        }
        return milliseconds
      }

      function generateUnitIdList () {
        var vehicleListInput = $HTML.find('#unitIdList')
        var vehicleList = wls.getSelectedVehicles()
        var vehiclesId = []
        for (var id in vehicleList) {
          vehiclesId.push(vehicleList[id].data.id)
        }
        vehicleListInput.val(vehiclesId.join(','))
      }
      function generateReportURL (data) {
        var url = '/load-report?' + 'report-name=' + 'Journey' + '&report-type=Journey&report-format=' + data.repFormat + '&alternate=false'
        var parameters = {}
        parameters.excludeWeekends = parseInt(data.excludeWeekends) == 1
        parameters.driverFilter = parseInt(data.groupJourneysByDrivers) == 1
        parameters.startPeriod = (data.from.replace(/\.+/g, '/')) + ' ' + data.fromTime
        parameters.endPeriod = (data.to.replace(/\.+/g, '/')) + ' ' + data.toTime
        parameters.vehicleIds = data.unitIdList.replace(/,+/g, '/')
        for (var key in parameters) {
          url += ('&' + key + '=' + parameters[key])
        }
        return url
      }
      $(document).on('vehicleListChange', function (e) {
        if (currReport.isVisible() && view.state == 'fleet') {
          reportHandlerInst.generateVehicleList(reportName)
          reportHandlerInst.reloadReportScroll(reportName)
        }
        generateUnitIdList()
      })
      generateUnitIdList()
      initDatepicker()
      attachReportScroll(reportName, [1])
      fromDatepicker.datepicker('setDate', '+0d')
      toDatepicker.datepicker('setDate', '+0d')
      fromTime.add(toTime).keydown(function (e) {
        var key = e.keyCode
        if ((key < 48 || key > 57) && (key < 97 || key > 105) && (key != 35 && key != 36 && key != 39 && key != 37 && key != 8 && key != 46 && key != 186 && key != 116)) {
          e.preventDefault()
        }
      }).blur(function () {
        checkTimeValidity()
      })
      $HTML.find('.close-btn,.button.cancel').click(function () {
        reportHandlerInst.hideReport(reportName)
        wlsMapTools.show()
        wlsMapTools.loadState()
      })

      $HTML.find('form').submit(function (e) {
        e.preventDefault()
        var formAction = this.action
        var dateRangeNotSelected = false
        var data = getFormData($(this))
        if (!$HTML.find('.vehicle-list li').size()) {
          alert('Select at least one vehicle.')
          return
        }
        $HTML.find('.range input:text').each(function () {
          if ($.trim(this.value) == '') {
            dateRangeNotSelected = true
          }
        })
        if (dateRangeNotSelected) {
          alert('select date range!')
          return
        }
        if (!$HTML.find('.format input:radio:checked').size()) {
          alert('select format!')
          return
        }
        if (data.repFormat == 'PDF' || data.repFormat == 'XLS') {
          window.open(generateReportURL(data))
        } else {
          // $.post(formAction, data).done(function (msg) {
          //   var json = msg;
          //   var $html = generateJourneyReport(json, data);
          //   if (leftPanel.hasClass("active")) {
          //     leftPanel.parent().animate({ "margin-left": 0 }, 500);
          //     leftPanel.removeClass("active").parent().removeClass("active");
          //   }
          //   if (!json.journeySections.length) {
          //     alert("No data against this Unit for range selected");
          //     return;
          //   }
          //   formAction = formAction.match(/[a-z]+\.[a-z]+$/i)[0];
          //   reportHandlerInst.hideReport(reportName);
          //   if (reportHandlerInst.hasReport(formAction)) {
          //     reportHandlerInst.showReport(formAction);
          //     wlsMapTools.hideToolsFrame();
          //     wlsMapTools.hide();
          //     leftPanel.fadeOut(600);
          //   } else {
          //     reportHandlerInst.addReport(new Report($html), formAction);
          //     reportHandlerInst.initReport(formAction);
          //   }
          // }).error(function (msg, errorTxt) {
          //   alert("error");
          // });
        }
      })
      $HTML.find('.button-list .button').click(function () {
        var action = $(this).data('action')

        if ($(this).hasClass('active')) {
          $(this).removeClass('active')
          return
        }

        $(this).addClass('active').parent().siblings().find('.button').removeClass('active')
        toDatepicker.datepicker('option', 'maxDate', null)
        toDatepicker.datepicker('option', 'minDate', null)
        fromDatepicker.datepicker('option', 'minDate', null)
        fromDatepicker.datepicker('option', 'maxDate', null)

        switch (action) {
          case 'today':
            fromDatepicker.datepicker('setDate', '+0d')
            toDatepicker.datepicker('setDate', '+0d')
            break

          case 'yesterday':
            fromDatepicker.datepicker('setDate', '-1d')
            toDatepicker.datepicker('setDate', '-1d')
            break

          case 'last_week':
            date.setDate(date.getDate() - (date.getDay() + 6))
            fromDatepicker.datepicker('setDate', date)
            date.setDate(date.getDate() + 6)
            toDatepicker.datepicker('setDate', date)
            break

          case 'previous_month':
            date.setDate(0)
            toDatepicker.datepicker('setDate', date)
            date.setDate(1)
            fromDatepicker.datepicker('setDate', date)
            break

          default:
            alert(' action: ' + action + ' not described')
            break
        }
        fromTime.val('00:00')
        toTime.val('23:59')
        date.setTime(Date.now())
        insertDatepickerTrigger()
      })
      currReport.setInitStatus(true)
    }
    reportInitObj[reportNames.journeyReportPopup.name] = function () { // Journey report
      reports[reportNames.journeyReportPopup.name] = new JourneyReportPageView()
    }
    reportInitObj[reportNames.loneWorkerJourneyReport.name] = function () { // Lone Worker Journey report
      reports[reportNames.loneWorkerJourneyReport.name] = new LWJourneyReportPageView()
    }
    reportInitObj[reportNames.idlingReportFuelUsagePopup.name] = function () {
      reports[reportNames.idlingReportFuelUsagePopup.name] = new IdlingReportFuelUsageView()
    }
    reportInitObj[reportNames.speedingReportPopup.name] = function () {
      reports[reportNames.speedingReportPopup.name] = new SpeedingReportView()
    }
    reportInitObj[reportNames.fuelTankLevelVarianceReportPage.name] = function () {
      reports[reportNames.fuelTankLevelVarianceReportPage.name] = new FuelTankLevelVarianceReportPageView()
    }
    reportInitObj[reportNames.fuelUsageReportPage.name] = function () {
      reports[reportNames.fuelUsageReportPage.name] = new FuelUsageReportPageView()
    }
    reportInitObj[reportNames.vehicleDefectReportPage.name] = function () {
      reports[reportNames.vehicleDefectReportPage.name] = new VehicleDefectReportPageView()
    }
    reportInitObj[reportNames.drivingTimeReportPage.name] = function () {
      reports[reportNames.drivingTimeReportPage.name] = new DrivingTimeReportPageView()
    }
    reportInitObj[reportNames.alertManagerPage.name] = function () {
      reports[reportNames.alertManagerPage.name] = new AlertManagerView()
    }
    reportInitObj[reportNames.driverBehaviourReport.name] = function () {
      reports[reportNames.driverBehaviourReport.name] = new DriverBehaviourPageView()
    }
    reportInitObj[reportNames.timeOnSiteReport.name] = function () {
      reports[reportNames.timeOnSiteReport.name] = new TimeOnSiteReportPageView()
    }
    reportInitObj[reportNames.timeSheetReport.name] = function () {
      var topBar = content.find('.top-bar')

      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500)
        leftPanel.removeClass('active').parent().removeClass('active')
      }
      if (topBar.is(':visible')) {
        topBar.animate({
          'margin-left': 0
        }, 500, null)
      }
    }
    reportInitObj[reportNames.journeyCostReport.name] = function () {
      reports[reportNames.journeyCostReport.name] = new JourneyCostReportPageView()
    }
    reportInitObj[reportNames.businessPrivate.name] = function () {
      reports[reportNames.businessPrivate.name] = new BusinessPrivateReportPageView()
    }
    reportInitObj[reportNames.outOfHoursQueryReport.name] = function () {
      reports[reportNames.outOfHoursQueryReport.name] = new OutOfHoursQueryReportPageView()
    }
    reportInitObj[reportNames.digitalInputReport.name] = function () {
      reports[reportNames.digitalInputReport.name] = new DigitalInputReportPageView()
    }
    reportInitObj[reportNames.mileageReport.name] = function () {
      reports[reportNames.mileageReport.name] = new MileageReportPageView()
    }
    reportInitObj[reportNames.detailedReport.name] = function () {
      reports[reportNames.detailedReport.name] = new DetailedReportPageView()
    }
    reportInitObj[reportNames.idlingReport.name] = function () {
      reports[reportNames.idlingReport.name] = new IdlingReportPageView()
    }
    reportInitObj[reportNames.leagueTable.name] = function () {
      reports[reportNames.leagueTable.name] = new LeagueTableReportPageView()
    }
    reportInitObj[reportNames.scheduledReport.name] = function () {
      var topBar = content.find('.top-bar')

      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500)
        leftPanel.removeClass('active').parent().removeClass('active')
      }
      if (topBar.is(':visible')) {
        topBar.animate({
          'margin-left': 0
        }, 500, null)
      }
    }
    reportInitObj[reportNames.todaysJourneysReport.name] = function () { // Today's Journeys report
      reports[reportNames.todaysJourneysReport.name] = new TodaysJourneyPageView()
    }
    this.initReport = function (reportName, callback) {
      var currReport = reportHandlerInst.getReport(reportName)

      reportInitObj[reportName].apply({ report: currReport, reportName: reportName })
      if (callback) callback()
    }
  }

  function CameraEvents () {
    var $reportHTMl = $('.cameras-events')

    this.loadCameraEventReport = function () {
      $.get('/api/getIncidentsWindow.do').done(function (json) {
        $reportHTMl.append(json)
        $reportHTMl.show()
      }).error(function (err) {
        console.log(err)
      })
    }
    this.isVisible = function () {
      return $reportHTMl.is(':visible')
    }

    this.destroyEl = function () {
      wls.resetSavedStates()

      $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      $reportHTMl.empty()
      wls.loadState()
      $reportHTMl.hide()
    }
  }

  function FleetManagementPlus () {
    var $reportHTMl = $('.fleet-management-plus')
    var fleetManagement = this

    this.loadFleetManagementReport = function () {
      $.get('/getManagerWindow/fleetManagement.do').done(function (json) {
        $reportHTMl.append(json)
        $reportHTMl.show()
        fleetManagement.initFleetManagement()
      }).error(function (err) {
        console.log(err)
      })
    }
    this.isVisible = function () {
      return $reportHTMl.is(':visible')
    }

    this.initFleetManagement = function () {
      addPoiPinByRightClickFromMap = false
      wlsMapTools.saveState()
      wls.resetState()
      $('.user-menu .categories li.mapping').removeClass('active')

      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500)
        leftPanel.removeClass('active').parent().removeClass('active')
      }
      // if (topBar.is(":visible")) {
      //     topBar.animate({
      //         "margin-left": 0
      //     }, 500, null);
      // }

      $('.left-panel').fadeOut(400)

      wlsMapTools.hideToolsFrame()
      wlsMapTools.hide()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()
    }

    this.destroyEl = function () {
      wls.resetSavedStates()
      $('.user-menu .categories li.mapping').addClass('active')

      $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      $reportHTMl.empty()
      wls.loadState()
      $reportHTMl.hide()
    }
  }

  function SmsManager () {
    var $reportHTMl = $('.sms-manager')

    this.loadSmsManager = function () {
      $.get('/api/getManagerWindow/smsManager.do').done(function (json) {
        $reportHTMl.append(json)
        $reportHTMl.show()
      }).error(function (err) {
        console.log(err)
      })
    }
    this.isVisible = function () {
      return $reportHTMl.is(':visible')
    }

    this.destroyEl = function () {
      wls.resetSavedStates()
      $('.user-menu .categories li.mapping').addClass('active')

      $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      $reportHTMl.empty()
      wls.loadState()
      $reportHTMl.hide()
    }
  }

  function SendMessage () {
    var $html = $('.send-sms-message')

    this.loadSendMessage = function (ids) {
      var arrayUrl = ''
      var i
      if (ids.length > 0 && typeof ids[0] === 'object') {
        for (i = 0; i < ids.length; i++) {
          arrayUrl += ids[i].id + ','
        }
      } else {
        for (i = 0; i < ids.length; i++) {
          arrayUrl += ids[i] + ','
        }
      }

      arrayUrl = arrayUrl.substring(0, arrayUrl.length - 1)
      $.get('/api/selection-menu/action/sendMessage/popup/' + arrayUrl
      ).done(function (json) {
        $html.append(json)
        $html.show()
        initAction()
      }).error(function (err) {
        console.log(err)
      })
    }
    this.isVisible = function () {
      return $html.is(':visible')
    }

    this.destroyEl = function () {
      $html.empty()
      $html.hide()
    }

    function initAction () {
      // setTimeout(function() {$(".sms-new-message").find('div#' + id).find(' span.checkbox').click();}, 2000);
      $html.find('#send-message .popup-header>.close-btn, #send-message .actions-row>.button:first-child').off('click').click(function () {
        sendMessage.destroyEl()
      })
    }
  }

  function ManageDrivers () {
    var $reportHTMl = $('.drivers-manages')
    var driversManager = this

    this.loadManageDriversPopup = function () {
      $.get('/getManagerWindow/driverManager.do').done(function (json) {
        $reportHTMl.append(json)
        $reportHTMl.show()
        driversManager.initDriversManager()
      }).error(function (err) {
        console.log(err)
      })
    }
    this.isVisible = function () {
      return $reportHTMl.is(':visible')
    }

    this.initDriversManager = function () {
      addPoiPinByRightClickFromMap = false
      wlsMapTools.saveState()
      wls.resetState()
      $('.user-menu .categories li.mapping').removeClass('active')

      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500)
        leftPanel.removeClass('active').parent().removeClass('active')
      }
      // if (topBar.is(":visible")) {
      //     topBar.animate({
      //         "margin-left": 0
      //     }, 500, null);
      // }

      $('.left-panel').fadeOut(400)

      wlsMapTools.hideToolsFrame()
      wlsMapTools.hide()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()

      $reportHTMl.find('.manage-drivers>.popup-header>.close-btn').click(function () {
        driversManager.destroyEl()
        $('.mapping').click()
      })
    }

    this.destroyEl = function () {
      wls.resetSavedStates()
      $('.user-menu .categories li.mapping').addClass('active')

      $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      $reportHTMl.empty()
      wls.loadState()
      $reportHTMl.hide()
    }
  }

  function DallasKeyManager () {
    var $reportHTMl = $('.dallas-key-manager')
    var dallasKeyManager = this

    this.loadDallasKeyManagerPopup = function () {
      $.get('/getManagerWindow/dallasKeyManager.do').done(function (json) {
        $reportHTMl.append(json)
        $reportHTMl.show()
        dallasKeyManager.initDallasKeyManager()
      }).error(function (err) {
        console.log(err)
      })
    }
    this.isVisible = function () {
      return $reportHTMl.is(':visible')
    }

    this.initDallasKeyManager = function () {
      addPoiPinByRightClickFromMap = false
      wlsMapTools.saveState()
      wls.resetState()
      $('.user-menu .categories li.mapping').removeClass('active')

      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500)
        leftPanel.removeClass('active').parent().removeClass('active')
      }

      $('.left-panel').fadeOut(400)

      wlsMapTools.hideToolsFrame()
      wlsMapTools.hide()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()

      $reportHTMl.find('.dallas-key-manager>.popup-header>.close-btn').click(function () {
        dallasKeyManager.destroyEl()
        $('.mapping').click()
      })
    }

    this.destroyEl = function () {
      wls.resetSavedStates()
      $('.user-menu .categories li.mapping').addClass('active')

      $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      $reportHTMl.empty()
      wls.loadState()
      $reportHTMl.hide()
    }
  }

  function TachographKeyManager () {
    var $reportHTMl = $('.tachograph-key-manager')
    var tachographKeyManager = this

    this.loadTachographKeyManagerPopup = function () {
      $.get('/getManagerWindow/tachographKeyManager.do').done(function (json) {
        $reportHTMl.append(json)
        $reportHTMl.show()
        tachographKeyManager.initTachographKeyManager()
      }).error(function (err) {
        console.log(err)
      })
    }
    this.isVisible = function () {
      return $reportHTMl.is(':visible')
    }

    this.initTachographKeyManager = function () {
      addPoiPinByRightClickFromMap = false
      wlsMapTools.saveState()
      wls.resetState()
      $('.user-menu .categories li.mapping').removeClass('active')

      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500)
        leftPanel.removeClass('active').parent().removeClass('active')
      }
      // if (topBar.is(":visible")) {
      //     topBar.animate({
      //         "margin-left": 0
      //     }, 500, null);
      // }

      $('.left-panel').fadeOut(400)

      wlsMapTools.hideToolsFrame()
      wlsMapTools.hide()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()

      $reportHTMl.find('.tachograph-key-manager>.popup-header>.close-btn').click(function () {
        tachographKeyManager.destroyEl()
        $('.mapping').click()
      })
    }

    this.destroyEl = function () {
      wls.resetSavedStates()
      $('.user-menu .categories li.mapping').addClass('active')

      $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      $reportHTMl.empty()
      wls.loadState()
      $reportHTMl.hide()
    }
  }

  function PermissionsPlus () {
    var $reportHTMl = $('.manage-users')
    var fleetManagement = this

    this.loadPermissionsPlus = function () {
      $.get('/getManagerWindow/manageUsers.do').done(function (json) {
        $reportHTMl.append(json)
        $reportHTMl.show()
        fleetManagement.initPermissionsPlus()
      }).error(function (err) {
        console.log(err)
      })
    }
    this.isVisible = function () {
      return $reportHTMl.is(':visible')
    }

    this.initPermissionsPlus = function () {
      addPoiPinByRightClickFromMap = false
      wlsMapTools.saveState()
      wls.resetState()
      $('.user-menu .categories li.mapping').removeClass('active')

      if (leftPanel.hasClass('active')) {
        leftPanel.parent().animate({ 'margin-left': 0 }, 500)
        leftPanel.removeClass('active').parent().removeClass('active')
      }
      // if (topBar.is(":visible")) {
      //     topBar.animate({
      //         "margin-left": 0
      //     }, 500, null);
      // }

      $('.left-panel').fadeOut(400)

      wlsMapTools.hideToolsFrame()
      wlsMapTools.hide()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()
    }

    this.destroyEl = function () {
      wls.resetSavedStates()
      $('.user-menu .categories li.mapping').addClass('active')

      $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
      $reportHTMl.empty()
      wls.loadState()
      $reportHTMl.hide()
    }
  }

  function TableGridHandler () { // TableGridHandler singleton
    if (TableGridHandler.prototype._singletonInstance) {
      return TableGridHandler.prototype._singletonInstance
    }
    TableGridHandler.prototype._singletonInstance = this

    var activeTableGrid = null
    var $activeTh = null

    this.setActiveTableGrid = function (tableGrid) {
      activeTableGrid = tableGrid
    }

    this.getActiveTableGrid = function () {
      return activeTableGrid
    }

    this.setActiveTh = function ($th) {
      $activeTh = $th
    }

    this.getActiveTh = function () {
      return $activeTh
    }
    this.calculateFlexibleGrid = function (tableGridInst) {
      var gridColumnSize = null
      var $table = null
      var tableWidth = null
      var $columns = null
      var borderRight = 1
      if (tableGridInst.isFlexible()) {
        gridColumnSize = tableGridInst.getOption('flexible')
        $table = tableGridInst.getTable()
        $columns = $table.find('table.info.content tbody tr:first [data-column]')
        $columns.attr('style', '')
        tableWidth = $table.width()
        for (var columnName in gridColumnSize) {
          if (gridColumnSize[columnName] < 5) {
            $columns.filter('[data-column=' + columnName + ']').width((gridColumnSize[columnName] * 35) + 'px')
          } else {
            $columns.filter('[data-column=' + columnName + ']').width('auto')
          }
        }
      }
    }
    this.saveGridCurrentSizes = function (tableGridInst) {
      var $columns = null
      var gridColumnSize = null
      var $table = null
      var tableWidth = null
      var $columns = null
      if (tableGridInst.isFlexible()) {
        gridColumnSize = tableGridInst.getOption('flexible')
        $table = tableGridInst.getTable()
        $columns = $table.find('table.info.content tbody tr:first [data-column]')
        tableWidth = $table.width()
        for (var columnName in gridColumnSize) {
          if (gridColumnSize[columnName] < 5) {
            $columns.filter('[data-column=' + columnName + ']').width((gridColumnSize[columnName] * 35) + 'px')
          } else {
            gridColumnSize[columnName] = $columns.filter('[data-column=' + columnName + ']').width() / tableWidth * 100
          }
        }
      }
    }
    this.calculateWithout = function (columnName) {
      var tableGridInst = this
      var table = tableGridInst.getTable()
      var size = table.find('th').size()
      var gridColumnSize = null
      var offset
      if (tableGridInst.isFlexible()) {
        gridColumnSize = tableGridInst.getOption('flexible')
        offset = gridColumnSize[columnName] / size
        delete gridColumnSize[columnName]
        for (var columnNameKey in gridColumnSize) {
          gridColumnSize[columnNameKey] += offset
        }
      }
    }
  }
  new TableGridHandler()

  function TableGrid ($tableHTML, options) { // TableGrid Class
    var tableDropdownSing = TableDropdown()
    var tableGridHandlerSing = TableGridHandler()
    var tableGridInst = this
    var sortFunc = {}
    var filterFunc = {}
    var thIndex = 0
    var defaultOrder = $tableHTML.find('tbody tr').clone()

    if (!options) options = {}

    if (!options.hideDropdown) {
      $tableHTML.find('th').mousedown(function (e) {
        var th = $(this)
        var offset = th.offset()
        var activeTh = tableGridHandlerSing.getActiveTh()

        if ($(e.target).hasClass('ui-draggable')) return

        thIndex = th.index()

        if (!activeTh || activeTh[0] !== th[0]) {
          tableDropdownSing.showDrop()
          tableGridHandlerSing.setActiveTh(th)
          tableGridHandlerSing.setActiveTableGrid(tableGridInst)
          tableDropdownSing.moveDropdownTo(offset.top + th.outerHeight(), e.pageX)
        } else {
          tableDropdownSing.hideDrop()
          tableGridHandlerSing.setActiveTableGrid(null)
          tableGridHandlerSing.setActiveTh(null)
        }
      })
    }

    function parseSortValues (a, b) {
      var valA = a.children[thIndex].getAttribute('data-sortValue')
      var valB = b.children[thIndex].getAttribute('data-sortValue')
      valA = isNaN(valA) ? valA : parseInt(valA)
      valB = isNaN(valB) ? valB : parseInt(valB)

      return [valA, valB]
    }

    sortFunc.asc = function (a, b) {
      var values = parseSortValues(a, b)

      return values[0] > values[1] ? 1 : -1
    }

    sortFunc.desc = function (a, b) {
      var values = parseSortValues(a, b)

      return values[0] < values[1] ? 1 : -1
    }
    this.isFlexible = function () {
      if (options && ('flexible' in options)) {
        return true
      }
      return false
    }
    this.getOption = function (optionName) {
      return options[optionName]
    }
    this.getTable = function () {
      return $tableHTML
    }
    this.sort = function (sortOrder) {
      var tr = $tableHTML.find('tbody tr')
      var $activeTh = tableGridHandlerSing.getActiveTh()

      tr.sort(sortFunc[sortOrder])
      $tableHTML.find('tbody').append(tr)

      if (sortOrder == 'asc') {
        $activeTh.find('.table-icon').addClass('asc').removeClass('desc').removeClass('filter')
      } else {
        $activeTh.find('.table-icon').addClass('desc').removeClass('asc').removeClass('filter')
      }
      $activeTh.siblings().find('.table-icon').removeClass('desc').removeClass('asc')
    }

    filterFunc.clear = function () {
      tableGridHandlerSing.getActiveTh().find('.table-icon').removeClass('asc').removeClass('desc').removeClass('filter')
      $tableHTML.find('tbody tr').remove()
      $tableHTML.find('tbody').append(defaultOrder.clone())
    }
    this.filter = function (command) {
      filterFunc[command]()
    }
  }

  function TableDropdown () { // TableDropdown singleton
    if (TableDropdown.prototype._singletonInstance) {
      return TableDropdown.prototype._singletonInstance
    }
    TableDropdown.prototype._singletonInstance = this

    var tableDropdownInst = this
    var tableGridHandlerSing = TableGridHandler()
    var $tableDropdownHTML = $('<div class="table-drop">' +
      '<ul>' +
      '<li data-sortOrder="asc">' +
      '<span class="table-icon asc">' +
      '<i></i>' +
      '<div class="top-border"></div>' +
      '</span>' +
      '<span class="title">Sort Ascending</span>' +
      '</li>' +
      '<li data-sortOrder="desc">' +
      '<span class="table-icon desc">' +
      '<i></i>' +
      '<div class="top-border"></div>' +
      '</span>' +
      '<span class="title">Sort Descending</span>' +
      '</li>' +
      '<li>' +
      '<span class="table-icon filter">' +
      '<i></i>' +
      '<div class="top-border"></div>' +
      '</span>' +
      '<div class="title">' +
      '<span>Filter</span>' +
      '<div class="table-sub-drop">' +
      '<ul>' +
      '<li>Show items with value that:</li>' +
      '<li>' +
      '<select>' +
      '<option value="">Starts with:</option>' +
      '<option value="">Account 1</option>' +
      '<option value="">awd</option>' +
      '</select>' +
      '<li>' +
      '<select>' +
      '<option value="">--Select value--</option>' +
      '<option value="">awd</option>' +
      '<option value="">awd</option>' +
      '</select>' +
      '</ul>' +
      '<div class="btn-holder">' +
      '<div class="button filter">' +
      '<span>Filter</span>' +
      '</div>' +
      '<div class="button clear" data-filterCommand="clear">' +
      '<span>Clear</span>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</li>' +
      '</ul>' +
      '</div>')

    $('#content').append($tableDropdownHTML)
    // custom select
    $tableDropdownHTML.find('select').selectmenu({
      width: 'auto',
      create: function (e, ui) {
        var selectDropdown = $(this).selectmenu('menuWidget').parent()
        $(this).parent().append(selectDropdown)
      }
    })

    $tableDropdownHTML.find('[data-sortOrder=asc],[data-sortOrder=desc]').click(function () {
      var activeTableGrid = tableGridHandlerSing.getActiveTableGrid()

      activeTableGrid.sort($(this).attr('data-sortOrder'))
      tableGridHandlerSing.setActiveTh(null)
      tableDropdownInst.hideDrop()
    })
    $tableDropdownHTML.find('[data-filterCommand=clear]').click(function () {
      var activeTableGrid = tableGridHandlerSing.getActiveTableGrid()

      activeTableGrid.filter($(this).attr('data-filterCommand'))
      tableGridHandlerSing.setActiveTh(null)
      tableDropdownInst.hideDrop()
    })

    $(document).click(function (e) {
      var target = $(e.target)
      if (!target.closest('th').size() && !target.closest('.table-drop').size()) {
        tableGridHandlerSing.setActiveTh(null)
        tableDropdownInst.hideDrop()
      }
    })

    this.showDrop = function () {
      $tableDropdownHTML.show()
    }

    this.hideDrop = function () {
      $tableDropdownHTML.hide()
    }

    this.moveDropdownTo = function (top, left) {
      $tableDropdownHTML.css({ top: top, left: left })
    }
  }
  new TableDropdown()

  function JourneyDetails () { // Journey details singleton
    if (JourneyDetails.prototype._singletonInstance) {
      return JourneyDetails.prototype._singletonInstance
    }
    JourneyDetails.prototype._singletonInstance = this

    var $HTML = $('.journey-details-popup')
    var journeyDetails = this
    var activeReport
    var $activeReportHTML
    var snailTrail = new SnailTrail()
    var $frame = $HTML.find('.scroll-content')
    var $wrap = $frame.parent()
    var inited = false

    this.show = function (callback) {
      $HTML.fadeIn(500, callback)
    }
    this.hide = function (callback) {
      $HTML.fadeOut(500, callback)
    }
    this.calulateScrollBeforeShow = function () {
      $HTML.css({ display: 'block', visibility: 'hidden' })
      journeyDetails.reloadScroll()
      $HTML.css({ visibility: 'visible', display: 'none' })
    }
    this.isVisible = function () {
      return $HTML.is(':visible')
    }
    this.getSnailTrail = function () {
      return snailTrail
    }
    this.getHTML = function () {
      return $HTML
    }
    this.reloadScroll = function () {
      $frame.attr('style', '')
      if ($HTML.height() < $HTML.find('.info-holder').outerHeight() + $HTML.find('.header-panel').outerHeight()) {
        $frame.height($HTML.height() - ($HTML.find('.header-panel').outerHeight() + $HTML.find('table.info:first').outerHeight()))
        if (!inited) {
          initSly()
          $wrap.find('.scrollbar').show()
        } else {
          $frame.sly('reload')
        }
      } else {
        $frame.sly('destroy')
        $wrap.find('.scrollbar').hide()
        $frame.attr('style', '')
        inited = false
      }
    }
    function initSly () {
      $frame.sly({
        horizontal: 0,
        speed: 300,
        easing: 'linear',
        scrollBar: $wrap.find('.scrollbar'),
        scrollBy: 100,
        dragHandle: 1,
        dynamicHandle: 1,
        clickBar: 1
      })
      inited = true
    }
    snailTrail.setSnailTrailTrTpl(new Template(
      '<tr>' +
      '<td style="width:49px;">${formatedTime}</td>' +
      '<td style="width:31px;"><img src="../${iconUrl}" alt=""></td>' +
      '<td style="width: 378px;" title="${address}">${address}</td>' +
      '<td style="width:64px;">${postcode}</td>' +
      '<td style="width:41px;">${speed}</td>' +
      '</tr>'
    )
    )
    snailTrail.setTableGrid(new TableGrid($('.info-holder', $HTML), { hideDropdown: true }))
    $HTML.draggable({
      containment: 'parent',
      cancel: '.close-btn',
      handle: $HTML.find('.header-panel')
    })
    this.insertMetrics = function () {
      var speedMetric = 'km/h'
      if (userPrefs.distanceMetric != 'KM') {
        speedMetric = 'MPH'
      }
      $HTML.find('th:last').text(speedMetric.toLowerCase())
    }
    $HTML.find('.close-btn').click(function () {
      activeReport = reportHandler.getActiveReport()
      journeyDetails.hide()
      $activeReportHTML = activeReport.getHTML()
      $activeReportHTML.find('table.info tr.active').removeClass('active')
      $activeReportHTML.find('input:checkbox').prop('checked', false)
      if (snailTrail.isOnMap()) {
        snailTrail.removeSnailTrailFromMap()
      }
    })
    $(window).on('resize.JourneyDetails', journeyDetails.reloadScroll)
  }
  new JourneyDetails()

  function controlPanel () { // controlPanel Class
    var cp = this
    var $HTML = ''
    var items = ''
    var drop = ''
    var opener = ''
    var cars = ''
    var options = ''
    this.fleetSort = ''
    this.fleetFilteredCars = ''

    function setUserSMFilterList (callback) {
      var filterDropdownTpl = new Template('<div class="options-drop">' +
        '<ul>' +
        '${filterItems}' +
        '</ul>' +
        '</div>')
      var filterDropdown = ''
      var filterDropdownItemTpl = new Template('<li><span data-action="${action}">${filterTitle}</span></li>')
      var filterDropdownItems = ''
      $.get('/getSelectionMenuFilterList.do').done(function (json) {
        for (var key in json) {
          filterDropdownItems += filterDropdownItemTpl.compile({ action: key, filterTitle: json[key] })
        }
        filterDropdown = filterDropdownTpl.compile({ filterItems: filterDropdownItems })
        $HTML.find('[data-type=filter]').append(filterDropdown)
        if (callback) callback()
      })
    }

    function select (name) {
      var len = cars.length
      var method = {
        all: function () {
          var vehicleListChanged = false

          for (var i = 0; i < len; i++) {
            (function () {
              var currCar = cars[i]
              if (!currCar.mapView.markerIsOnMap && currCar.HTML.is(':visible')) {
                if (view.state == 'fleet' || view.state == 'dual') {
                  wls.lastSelected[currCar.data.id] = currCar
                  wls.saveSelectedVehicleToStorage()
                  vehicleListChanged = true
                }
                if (currCar.cached) {
                  if (view.state == 'proximity') {
                    currCar.drawLine(proximity.pin.data || proximity.pin)
                    return
                  }
                  currCar.add(true)
                } else {
                  currCar.cache()
                  $(currCar.mapView.vehImg).one('load', function h () {
                    if (view.state == 'proximity') {
                      currCar.drawLine(proximity.pin.data || proximity.pin)
                      return
                    }
                    currCar.add(true)
                  })
                  if (currCar.mapView.vehImg.complete) $(currCar.mapView.vehImg).load()
                }
              }
            })()
          }
          if (vehicleListChanged) {
            $(document).trigger('vehicleListChange')
          }
        },
        none: function () {
          for (var i = 0; i < len; i++) {
            if (cars[i].mapView.markerIsOnMap && cars[i].HTML.is(':visible')) {
              cars[i].remove()
              cars[i].HTML.find('.zoom.active').removeClass('active')
            }
          }
          wls.resetSavedStates()
          $(document).trigger('vehicleListChange')
        },
        invert: function () {
          for (var i = 0; i < len; i++) {
            if (cars[i].mapView.markerIsOnMap && cars[i].HTML.is(':visible')) {
              cars[i].remove()
              delete wls.lastSelected[cars[i].data.id]
            } else {
              (function () {
                var currCar = cars[i]
                if (!currCar.mapView.markerIsOnMap && currCar.HTML.is(':visible')) {
                  if (view.state == 'fleet' || view.state == 'dual') {
                    wls.lastSelected[currCar.data.id] = currCar
                    wls.saveSelectedVehicleToStorage()
                  }
                  if (currCar.cached) {
                    if (view.state == 'proximity') {
                      currCar.drawLine(proximity.pin.data || proximity.pin)
                      return
                    }
                    currCar.add(true)
                  } else {
                    currCar.cache()
                    $(currCar.mapView.vehImg).one('load', function h () {
                      if (view.state == 'proximity') {
                        currCar.drawLine(proximity.pin.data || proximity.pin)
                        return
                      }
                      currCar.add(true)
                    })
                    if (currCar.mapView.vehImg.complete) $(currCar.mapView.vehImg).load()
                  }
                }
              })()
            }
          }
          $(document).trigger('vehicleListChange')
        }
      }
      method[name]()
    }
    function strcmp (a, b) {
      return a > b ? 1 : a < b ? -1 : 0
    }

    function natcmp (a, b) {
      var x = []; var y = []
      var first = a.toLowerCase()
      var second = b.toLowerCase()
      first.replace(/(\d+)|(\D+)/g, function ($0, $1, $2) { x.push([$1 || 0, $2]) })
      second.replace(/(\d+)|(\D+)/g, function ($0, $1, $2) { y.push([$1 || 0, $2]) })

      while (x.length && y.length) {
        var xx = x.shift()
        var yy = y.shift()
        var nn = (xx[0] - yy[0]) || strcmp(xx[1], yy[1])
        if (nn) return nn
      }

      if (x.length) return -1
      if (y.length) return +1

      return 0
    }
    function sort (name, desc) {
      var len = cars.length
      var newCarsOrder = []
      var propName = ''
      var props = []
      var propsLen = 0

      if (name === 'dis') {
        propName = name
      } else if (name === 'registration') {
        propName = 'registrationNumber'
      } else {
        propName = 'renewalDate'
      }

      for (var i = 0; i < len; i++) {
        if (typeof cars[i].data[propName] === 'string') {
          props[i] = cars[i].data[propName]
        } else {
          props[i] = cars[i]
        }
      }

      props.sort((typeof cars[0].data[propName] === 'string') ? natcmp : function (a, b) { return a.data[propName] - b.data[propName] })

      if (desc && name !== 'registration') props.reverse()
      if (!desc && name === 'registration') props.reverse()

      propsLen = props.length

      for (var i = 0; i < propsLen; i++) {
        if (typeof cars[i].data[propName] === 'string') {
          for (var k = 0; k < len; k++) {
            if (props[i] === cars[k].data[propName]) {
              newCarsOrder.push(cars[k].HTML.get(0))
            }
          }
        } else {
          newCarsOrder.push(props[i].HTML.get(0))
        }
      }

      if (view.getActiveState() !== 'dual') {
        leftPanel.find('.right-col .vehicle-holder .slidee').append(newCarsOrder)
      } else {
        cp.fleetFilteredCars = newCarsOrder
        wls.reoderFleetViewSlidee(newCarsOrder)
      }
    }
    function filter (status) {
      var len = cars.length
      var newCarsOrder = []

      if (view.getActiveState() === 'dual') {
        wls.resetSlidee()
      }

      if (status == 'none') {
        if (view.state !== 'dual') {
          leftPanel.find('.right-col .vehicle-holder .slidee').children().attr('style', '')
        }
        wls.removeCars(true)
        for (var i = 0; i < len; i++) {
          var currCar = cars[i]
          if (view.state === 'dual') {
            currCar.HTML.show()
          }
          if (currCar.HTML.hasClass('active')) {
            currCar.add()
          }
        }
      } else if (status == 'incident') {
        for (var i = 0; i < len; i++) {
          var currCar = cars[i]
          if (currCar.data.incidentHappened) {
            currCar.HTML.show()
            if (currCar.HTML.hasClass('active')) {
              currCar.add()
            }
          } else {
            currCar.HTML.hide()
            currCar.remove(true)
          }
        }
      } else if (status == 'plant' || status == 'tag' || status == 'tractor' || status == 'trailerDevice') {
        for (var i = 0; i < len; i++) {
          var currCar = cars[i]
          if (currCar.data[status]) {
            currCar.HTML.show()
            if (currCar.HTML.hasClass('active')) {
              currCar.add()
            }
          } else {
            currCar.HTML.hide()
            currCar.remove(true)
          }
        }
      } else {
        for (var i = 0; i < len; i++) {
          var currCar = cars[i]
          if (currCar.data.state == status) {
            currCar.HTML.show()
            if (currCar.HTML.hasClass('active') && !currCar.mapView.markerIsOnMap) {
              currCar.add()
            }
          } else {
            currCar.HTML.hide()
            currCar.remove(true)
          }
        }
      }

      // for (var i = 0; i < cars.length; i++) {
      //     newCarsOrder.push(cars[i].HTML.get(0));
      // }

      cp.fleetFilteredCars = leftPanel.find('.right-col .vehicle-holder .slidee').children()

      if (view.getActiveState() === 'dual') {
        // wls.resetSlidee();
        // cp.fleetFilteredCars = leftPanel.find(".right-col .vehicle-holder .slidee").children();
        wls.filterFleetViewSlidee(cp.fleetFilteredCars)
      }

      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.right-col .vehicle-holder .scroll-content .slidee').height()])
      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
      if (htmlGroupTabsAllowed) {
        groupTabsetView.renderScroll()
      }
    }

    this.resetSortOrder = function () {
      options.each(function () {
        $(this).data('desc', false)
      })
    }
    this.exec = function (command, sortOrder) {
      cars = wls.getVehicles()
      switch (command) {
        case 'SM_SELECT_ALL':
          if (wls.hasZoomedVehicle()) {
            wls.cancelZoom()
            return
          }
          select('all')
          break
        case 'SM_SELECT_NONE':
          select('none')
          if (view.state == 'fleet') {
            wls.resetSelectionState()
          }
          break
        case 'SM_INVERT_SELECTION':
          if (wls.hasZoomedVehicle()) {
            wls.cancelZoom()
            return
          }
          select('invert')
          break
        case 'SM_REGISTRATION':
          sort('registration', sortOrder)
          localStorage.setItem('orderType', 'SM_REGISTRATION')
          localStorage.setItem('orderDesc', sortOrder ? 'true' : 'false')
          break
        case 'SM_TIME':
          sort('time', sortOrder)
          localStorage.setItem('orderType', 'SM_TIME')
          localStorage.setItem('orderDesc', sortOrder ? 'true' : 'false')
          break
        case 'sm_no_filter':
          filter('none')
          localStorage.setItem('filter', 'sm_no_filter')
          break
        case 'sm_ignition_off':
          filter('Off')
          localStorage.setItem('filter', 'sm_ignition_off')
          break
        case 'sm_idling':
          filter('Idling')
          localStorage.setItem('filter', 'sm_idling')
          break
        case 'sm_ignition_on':
          filter('On')
          localStorage.setItem('filter', 'sm_ignition_on')
          break
        case 'SM_DISTANCE':
          sort('dis', false)
          break
        case 'SM_INCIDENTS':
          filter('incident')
          localStorage.setItem('filter', 'SM_INCIDENTS')
          break
        case 'sm_plant':
          filter('plant')
          localStorage.setItem('filter', 'sm_plant')
          break
        case 'sm_tractor':
          filter('tractor')
          localStorage.setItem('filter', 'sm_tractor')
          break
        case 'sm_trailer_device':
          filter('trailerDevice')
          localStorage.setItem('filter', 'sm_trailer_device')
          break
        case 'sm_trailer_tag':
          filter('tag')
          localStorage.setItem('filter', 'sm_trailer_tag')
          break
        default:
          alert(' command: ' + command + ' not described')
          break
      }
    }
    this.expandAll = function () {
      var info = leftPanel.find('.right-col .vehicle-holder .slidee').find('.row:visible .info')
      $(this).addClass('active')

      info.slideDown(600).promise().done(function () {
        info.toggleClass('exp')
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize')
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
        if (htmlGroupTabsAllowed && groupTabsetView) {
          groupTabsetView.renderScroll()
        }
        if (addInfo && wls.addInformationActive()) {
          wls.calcCoordinates(addInfo)
        }
        if (editVehRegPopupView.isVisible()) {
          wls.calcCoordinates(editVehRegPopupView)
        }

        if (editVehDriverPopupView.isVisible()) {
          wls.calcCoordinates(editVehDriverPopupView)
        }

        if (sendCommandPopupView.isVisible()) {
          wls.calcCoordinates(sendCommandPopupView)
        }

        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
      })
      if (view.state == 'fleet') {
        wls.resetExpandedState()
      }
    }
    this.collapseAll = function () {
      $(this).removeClass('active')
      var info = leftPanel.find('.right-col .vehicle-holder .slidee').find('.row:visible .info')
      info.toggleClass('exp')
      var sum = 0
      info.each(function () {
        if (view.state !== 'dual') {
          sum += $(this).closest('.row').outerHeight() - $(this).height()
        } else {
          sum += ($(this).closest('.row').outerHeight() - $(this).height()) / Math.floor(leftPanel.find('.right-col').width() / 319)
        }
      })
      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [sum])
      info.slideUp(600).promise().done(function () {
        if (htmlGroupTabsAllowed) {
          groupTabsetView.renderScroll()
        }
        if (addInfo && wls.addInformationActive()) {
          wls.calcCoordinates(addInfo)
        }
        if (editVehRegPopupView.isVisible()) {
          wls.calcCoordinates(editVehRegPopupView)
        }

        if (editVehDriverPopupView.isVisible()) {
          wls.calcCoordinates(editVehDriverPopupView)
        }

        if (sendCommandPopupView.isVisible()) {
          wls.calcCoordinates(sendCommandPopupView)
        }

        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
      })

      if (view.state == 'fleet') {
        cars = wls.getVehicles()
        var len = cars.length
        for (var i = 0; i < len; i++) {
          var currCar = cars[i]
          wls.lastExpanded[currCar.data.id] = currCar
        }
        wls.saveExpandedVehicleToStorage()
      }
    }
    this.init = function () {
      var searchText = ''
      $HTML = $('.control-panel')
      var controlHolder = $('.control-holder')
      opener = $HTML.find(' > ul > li > span')
      var checkAll
      var checkboxes
      settings.onLoad(function () {
        if (!filtersLoaded) {
          setUserSMFilterList(function () {
            drop = $HTML.find('.options-drop')
            options = $('>ul>li>span', drop)
            checkAll = drop.find('.checkAll')
            checkboxes = drop.find('input:checkbox:not(.checkAll)')
            options.each(function () {
              var action = $(this).data('action')
              if (!action) return
              $(this).one('click.cache', function () {
                options.off('click.cache')
              }).click(function () {
                var sortOrder = $(this).data('desc', !$(this).data('desc')).data('desc')

                cp.exec($(this).data('action'), sortOrder)
                drop.hide(300)
                opener.parent().not('.expand').removeClass('active')
              })
            })
            checkAll.change(function () {
              var checkboxGroup = $(this).closest('li').siblings().find('label>input:checkbox')
              if (this.checked) {
                checkboxGroup.prop('checked', true)
              } else {
                checkboxGroup.prop('checked', false)
              }
            })
            checkboxes.change(function () {
              var ul = $(this).closest('ul')
              var currCheckAll = ul.find('>li:has(.checkAll) .checkAll')
              var currCheckboxes = ul.find('>li>label>input:checkbox:not(.checkAll)')
              var innerCheckboxes = $(this).closest('li').find('input:checkbox')
              if (currCheckboxes.size() == currCheckboxes.filter(':checked').size()) {
                // currCheckAll.prop( "checked" , true );
                // innerCheckboxes.prop( "checked" , true);
              } else if (!this.checked) {
                currCheckAll.prop('checked', false)
                innerCheckboxes.prop('checked', false)
              }
            })
            $HTML.find('.expand span').click(function () {
              var li = $(this).parent()
              if (leftPanel.find(':animated').size()) return
              if (li.hasClass('active')) {
                cp.collapseAll.apply(li)
              } else {
                cp.expandAll.apply(li)
              }
            })
            var vehicleSearchRow = $('.vehicle-search.row')
            controlHolder.find('.vehicle-search.row .input-holder>input').hover(function (e) {
              var left = leftPanel.hasClass('has-group-tab') ? 35 : 0
              var tooltipTop = vehicleSearchRow.find('.search-tooltip').outerHeight()
              var tooltipLeft = (vehicleSearchRow.width() - vehicleSearchRow.find('.search-tooltip').width()) / 2

              vehicleSearchRow.find('.search-tooltip').css({ left: tooltipLeft, top: -tooltipTop }).show()
            }, function (e) {
              vehicleSearchRow.find('.search-tooltip').hide()
            })
            var debounceTimeout;
            controlHolder.find('.vehicle-search.row .input-holder>input').off('click').keyup(function (e) {
              clearTimeout(debounceTimeout);
              debounceTimeout = setTimeout(function() {
                $('.vehicle-search.row').find('.search-tooltip').hide()
                searchText = e.target.value.toLowerCase()
                searchText = searchText.split(' ').join('')
                searchVehicle.text = searchText
                if (e.keyCode === 13 || e.keyCode == 27) {
                  $(this).blur()
                  searchVehicle.resetResults()
                  searchVehicle.variantsHide()
                }

                // if(leftPanel.find(".actions-holder").is(":Visible")){
                //     searchVehicle.resetActionHolder();
                // }

                if (searchText.length >= 2 && e.keyCode !== 13 && e.keyCode !== 27) {
                  if (searchVehicle.isVisible()) {
                    searchVehicle.hideSearchResult()
                  }
                  searchVehicle.showSearchResults(searchText, function (data) {
                    searchVehicle.carsArray = data
                    searchVehicle.initVariantsTemplate(data)
                    searchVehicle.generateSearchVariant(data)
                    searchVehicle.variantsShow()
                    searchVehicle.bindEvents()
                    if (data.length === 0) {
                      searchVehicle.variantsHide()
                    }
                    if (htmlGroupTabsAllowed) {
                      groupTabsetView.renderScroll()
                    }
                  })
                } else if (searchText.length < 2) {
                  searchVehicle.resetResults()
                  searchVehicle.variantsHide()
                  if (htmlGroupTabsAllowed) {
                    groupTabsetView.renderScroll()
                  }
                }
                e.preventDefault()
              }, 500);
            })
            controlHolder.find('.vehicle-search.row .input-holder>input').focusout(function (e) {
              setTimeout(function () { searchVehicle.variantsHide() }, 300)
            })

            controlHolder.find('.vehicle-search.row .input-holder .button input').off('click').click(function (e) {
              e.preventDefault()
              searchVehicle.variantsHide()
              searchText = controlHolder.find('.vehicle-search.row .input-holder>input').val()
              searchVehicle.text = searchText.split(' ').join('')
              searchVehicle.hideHelpers()
              // searchVehicle.showSearchResults(searchText, function(data){
              // searchVehicle.carsArray = data;
              if (searchVehicle.carsArray.length === 0) {
                alert('The entered value doesn’t exist')
              } else if (searchVehicle.carsArray.length > 1) {
                // searchVehicle.initSearchTemplate(data);
                searchVehicle.generateTable(searchVehicle.carsArray)
                searchVehicle.initDraggable()
                searchVehicle.setHeaderPanelText(searchText, searchVehicle.carsArray.length)
                searchVehicle.showSearchResult()
                searchVehicle.initScroll($('.vehicle-search-popup'), false)
                searchVehicle.bindEvents()
                searchVehicle.resetResults()
              } else {
                if (wls.hasShapes()) window.mapCanvas.cleanUp()

                wls.carsArray = [searchVehicle.carsArray[0].vehicleDto]
                searchVehicle.selectedGroup = searchVehicle.carsArray[0].groupId
                wls.importCars()
                wls.createHtml(function callAfterVehiclesCreated () {

                })

                searchVehicle.cars = wls.getVehicles()
                searchVehicle.showOnMap()

                // if(leftPanel.hasClass('has-group-tab')){
                //     // leftPanel.removeClass('has-group-tab').addClass("no-group-tab");
                // }

                leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
                searchVehicle.actionHolderShow()
                searchVehicle.resetResults()
              }

              // });
              // controlHolder.find(".vehicle-search.row .input-holder>input").val("");
              if (searchVehicle.variantsIsVisible()) {
                if (searchVehicle.variantsIsVisible()) searchVehicle.variantsHide()
              }
            })
            $('input[type=hidden]', options).remove()
            opener.click(function () {
              var index = $(this).parent().index()
              if (!$(this).parent().hasClass('expand')) {
                $(this).parent().toggleClass('active')
              }
              opener.not(this).parent().not('.expand').removeClass('active')
              drop.not(':eq(' + index + ')').hide(300)
              drop.eq(index).toggle(300)
            })
            $(document).click(function (e) {
              if (!$(e.target).closest('.control-panel').size()) {
                drop.hide(300)
                opener.parent().not('.expand').removeClass('active')
              }
            })
          })
          filtersLoaded = true
        }
      })
    }

    this.allGroupSelected = function () {
      var cars = wls.getVehicles()
      var len = cars.length
      var count = 0

      for (var i = 0; i < len; i++) {
        var currCar = cars[i]
        if (currCar.HTML.hasClass('active')) {
          count++
        }
      }

      return count == len
    }
  }
  function SearchVehicle () {
    var $HTML = $('.vehicle-search-popup')
    var $variants = $('.search-variants-holder', leftPanel)
    var $HTMLtableResults = ''
    var $HTMLvariants = ''
    var $HTMLscrollContent = $HTML.find('.scroll-content')
    var search = this
    var $json = null
    var tableGrid
    var $searchTable = null
    var $searchVariants = null
    var $frame = null
    var $wrap = null
    var $html = null
    var headerPanel = $HTML.find('.header-panel .title')
    this.selectedGroup = null
    this.selected = null
    this.selectedAccount = null
    this.text = ''
    this.name = null
    this.carsArray = []
    this.cars = null
    var searchQuery = null
    var dataToSort = null
    var $thIndex = 0
    var $thActive = null

    this.initVariantsTemplate = function (json) {
      if (json.length > 0) {
        $HTMLvariants = new Template('<li class="search-variant">' +
          '<div class="search-variant__name">${registrationNumber}</div>' +
          '</div>' +
          '</li>')
      }
    }
    function removeDuplicates (arr, prop) {
      var newArr = []
      var obj = {}

      for (var i in arr) {
        obj[arr[i][prop]] = arr[i]
      }

      for (i in obj) {
        newArr.push(obj[i])
      }

      return newArr
    }

    this.generateSearchVariant = function (json) {
      var data = removeDuplicates(json, 'vehicleId')
      var dataLength = data.length
      var isInterrupt = false

      for (var i = 0; i < dataLength; i++) {
        var obj = data[i]
        var regNum = data[i].registrationNumber

        if (!regNum.toLowerCase().split(' ').join('').includes(search.text)) {
          for (var key in obj) {
            var str = obj[key]
            if ((key === 'vin' || key === 'fleetNumber' || key === 'fleetId' || key === 'clientInfo') && str.includes(search.text) && !isInterrupt) {
              switch (key) {
                case 'vin':
                  $searchVariants += '<li class="search-variant">' +
                    '<div class="search-variant__name">' + regNum + '</div>' +
                    '<div class="search-helper">' +
                    '<span class="helper-title"><i>Vin: </i></span>' +
                    '<span class="helper-data"><b>' + obj[key] + '</b></span>' +
                    '</div>' +
                    '</li>'
                  isInterrupt = true
                  break
                case 'fleetNumber':

                  $searchVariants += '<li class="search-variant">' +
                    '<div class="search-variant__name">' + regNum + '</div>' +
                    '<div class="search-helper">' +
                    '<span class="helper-title"><i>Fleet num.: </i></span>' +
                    '<span class="helper-data"><b>' + obj[key] + '</b></span>' +
                    '</div>' +
                    '</li>'
                  isInterrupt = true
                  break
                case 'fleetId':

                  $searchVariants += '<li class="search-variant">' +
                    '<div class="search-variant__name">' + regNum + '</div>' +
                    '<div class="search-helper">' +
                    '<span class="helper-title"><i>Fleet ID: </i></span>' +
                    '<span class="helper-data"><b>' + obj[key] + '</b></span>' +
                    '</div>' +
                    '</li>'
                  isInterrupt = true
                  break
                case 'clientInfo':

                  $searchVariants += '<li class="search-variant">' +
                    '<div class="search-variant__name">' + regNum + '</div>' +
                    '<div class="search-helper">' +
                    '<span class="helper-title"><i>Info: </i></span>' +
                    '<span class="helper-data"><b>' + obj[key] + '</b></span>' +
                    '</div>' +
                    '</li>'
                  isInterrupt = true
                  break
                default:
                  break
              }
            }
            isInterrupt = false
          }
        } else {
          $searchVariants += $HTMLvariants.compile(data[i])
          isInterrupt = false
        }
      }
      $variants.find('ul.search-list').html($searchVariants)
    }

    this.resetResults = function () {
      $searchVariants = ''
    }
    this.variantsShow = function () {
      leftPanel.find('.vehicle-holder').hide()

      if (leftPanel.find('.actions-holder').is(':Visible')) {
        search.actionHolderHide()
        leftPanel.find('.actions-holder').addClass('is-hide')
      }

      $variants.css({
        width: leftPanel.width()
      }).show()

      search.initScroll($('.search-variants-holder'), true)

      $('.menu .groups-btn').on('click', function () {
        search.variantsHide()
      })

      if (settings.data.htmlGroupTabsAllowed) {
        groupTabsetView.renderScroll()
      }
    }
    this.variantsHide = function () {
      $variants.hide()
      if (leftPanel.find('.actions-holder').hasClass('is-hide')) {
        search.actionHolderShow()
        leftPanel.find('.actions-holder').removeClass('is-hide')
      }
      leftPanel.find('.vehicle-holder').show()
      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
      if (htmlGroupTabsAllowed) {
        groupTabsetView.renderScroll()
      }
    }
    this.variantsIsVisible = function () {
      return $variants.is(':visible')
    }
    function compileRow (data) {
      var regNum = data.registrationNumber.toLowerCase().split(' ').join('').includes(search.text.toLowerCase().split(' ').join(''))
      var vinNum = data.vin.toLowerCase().split(' ').join('').includes(search.text.toLowerCase().split(' ').join(''))
      var fleetNum = data.fleetNumber.toLowerCase().split(' ').join('').includes(search.text.toLowerCase().split(' ').join(''))
      var fleetId = data.fleetId.toLowerCase().split(' ').join('').includes(search.text.toLowerCase().split(' ').join(''))
      var layout = (
        '<tr data-groupid="' + data.groupId + '">' +
        '<td class="reg_num' + (regNum ? ' bolder' : '') + '">' + ((data.registrationNumber.length > 16) ? '<span title="' + data.registrationNumber + '">' + data.registrationNumber + '</span>' : '<span>' + data.registrationNumber + '</span>') + '</td>' +
        '<td class="' + (vinNum ? 'bolder' : '') + '" style="width: 55px">' + ((data.vin.length > 7) ? '<span title="' + data.vin + '">' + data.vin + '</span>' : '<span>' + data.vin + '</span>') + '</td>' +
        '<td class="' + (fleetNum ? 'bolder' : '') + '"  style="width: 95px">' + ((data.fleetNumber.length > 12) ? '<span title="' + data.fleetNumber + '">' + data.fleetNumber + '</span>' : '<span>' + data.fleetNumber + '</span>') + '</td>' +
        '<td class="' + (fleetId ? 'bolder' : '') + '"  style="width: 70px">' + ((data.fleetId.length > 8) ? '<span title="' + data.fleetId + '">' + data.fleetId + '</span>' : '<span>' + data.fleetId + '</span>') + '</td>' +
        '<td>' + ((data.accountName.length > 19) ? '<span title="' + data.accountName + '">' + data.accountName + '</span>' : '<span>' + data.accountName + '</span>') + '</td>' +
        '<td>' + ((data.groupName.length > 19) ? '<span title="' + data.groupName + '">' + data.groupName + '</span>' : '<span>' + data.groupName + '</span>') + '</td>' +
        '<td>' + ((data.subGroupName.length > 19) ? '<span title="' + data.subGroupName + '">' + data.subGroupName + '</span>' : '<span>' + data.subGroupName + '</span>') + '</td>' +
        '<td>' + ((data.subSubGroupName.length > 19) ? '<span title="' + data.subSubGroupName + '">' + data.subSubGroupName + '</span>' : '<span>' + data.subSubGroupName + '</span>') + '</td>' +
        '</tr>'
      )

      return layout
    }
    this.generateTable = function (json) {
      $searchTable = null
      $HTMLtableResults = ''
      var data = json

      var dataLen = json.length

      for (var i = 0; i < dataLen; i++) {
        $searchTable += compileRow(data[i], false)
      }

      $HTMLscrollContent.find('tbody').html($searchTable)
    }
    this.setTableGrid = function (tableGridInst) {
      tableGrid = tableGridInst
    }
    this.showSearchResults = function (text, callback) {
      search.carsArray = []
      $searchTable = ''
      $searchVariants = ''
      search.resetResults()
      var searchText = text
      $.get('/api/selection-menu/search/', { searchQuery: searchText }).done(function (msg) {
        $json = msg
        if (callback) callback($json)
      }).error()
    }
    this.showSearchResult = function () {
      if (settings.data.singleClickInDataGridAllowed) {
        $HTML.find('.action-row').remove()
      }
      $HTML.find('.action-row .button.save').addClass('btn-disabled')
      $HTML.fadeIn(500)
      search.variantsHide()
    }
    this.hideSearchResult = function () {
      $HTML.fadeOut(500)
      search.hideHelpers()
    }
    this.hideHelpers = function () {
      $HTML.find('.table-drop').hide()
      $HTML.find('thead .table-icon').removeClass('desc').removeClass('asc')
    }
    this.isVisible = function () {
      return $HTML.is(':visible')
    }
    this.resetTableResults = function () {
      search.carsArray = []
      leftPanel.find('.vehicle-search.row .input-holder>input').val('')
    }
    this.isCarsArray = function () {
      return search.carsArray.length > 0
    }
    this.initDraggable = function () {
      $HTML.draggable({
        containment: 'parent',
        cancel: '.close-btn',
        handle: $HTML.find('.header-panel'),
        start: function () {
          $HTML.css('height', 'auto')
        },
        stop: function () {
          $HTML.css('height', 'auto')
        }
      })
    }
    this.setHeaderPanelText = function (text, length) {
      searchQuery = text
      if (length > 1) {
        headerPanel.html('"' + text + '" exist in multiple groups. Select one')
      } else {
        headerPanel.html('"' + text + '" exist in one group.')
      }
    }
    this.initScroll = function ($this, variants) {
      $html = $this
      $frame = $html.find('.scroll-content')
      $wrap = $frame.parent()
      var inited = false

      function initSly () {
        $frame.sly({
          horizontal: 0,
          speed: 300,
          easing: 'linear',
          scrollBar: $wrap.find('.scrollbar'),
          scrollBy: 100,
          dragHandle: 1,
          dynamicHandle: 1,
          clickBar: 1
        })
        inited = true
      }
      initSly()
      function reloadScroll () {
        $frame.attr('style', '')
        if ($frame.height() > $html.outerHeight()) {
          if (variants) {
            $frame.height($html.outerHeight())
          } else {
            $frame.height($html.outerHeight() - $html.find('.search-holder>.search-result').outerHeight() - $html.find('.header-panel').outerHeight() - $html.find('.action-row').outerHeight() - $html.find('.export-row').outerHeight())
          }

          if (!inited) {
            initSly()
          }

          $frame.sly('reload')
          $wrap.find('.scrollbar').show()
        } else {
          $frame.sly('destroy')
          $wrap.find('.scrollbar').hide()
          inited = false
        }
      }
      reloadScroll()
      $(window).resize(reloadScroll)
    }
    this.sort = function (sortName, sortObj) {
      $HTML.find('.table-drop').hide()
      $searchTable = ''
      var arr = search.carsArray
      var valA, valB

      if (sortName === 'asc') {
        arr.sort(function (a, b) {
          valA = a[sortObj]
          valB = b[sortObj]

          return valA > valB ? 1 : -1
        })
        $thActive.find('.table-icon').addClass('asc').removeClass('desc')
      } else {
        arr.sort(function (a, b) {
          valA = a[sortObj]
          valB = b[sortObj]

          return valA < valB ? 1 : -1
        })

        $thActive.find('.table-icon').addClass('desc').removeClass('asc')
      }

      $thActive.siblings().find('.table-icon').removeClass('desc').removeClass('asc')

      search.generateTable(arr)
      search.initScroll($('.vehicle-search-popup'), false)
      search.bindEvents()
    }
    this.actionHolderShow = function () {
      leftPanel.find('.actions-holder').show()
    }
    this.actionHolderHide = function () {
      leftPanel.find('.actions-holder').hide()
    }
    function showVehicle () {
      search.hideSearchResult()

      for (var i = 0; i < search.carsArray.length; i++) {
        if (wls.hasShapes()) window.mapCanvas.cleanUp()

        if (search.carsArray[i].groupId === parseInt(search.selected, 10) && search.carsArray[i].registrationNumber === search.name) {
          wls.carsArray = [search.carsArray[i].vehicleDto]
          wls.importCars()
          wls.createHtml(function callAfterVehiclesCreated () {

          })
          search.selectedGroup = search.carsArray[i].groupId
          search.selectedAccount = search.carsArray[i].accountId

          search.cars = wls.getVehicles()
        }
      }
      if (search.cars !== null) {
        search.showOnMap()
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
        if (settings.data.htmlGroupTabsAllowed) {
          leftPanel.find('.right-col .vehicle-holder').css('min-height', 'auto')
          groupTabsetView.renderScroll()
        }
        search.actionHolderShow()
      }
    }

    this.showOnMap = function () {
      var len = search.cars.length
      var vehicleListChanged = false

      for (var i = 0; i < len; i++) {
        (function () {
          var currCar = search.cars[i]
          if (!currCar.mapView.markerIsOnMap && currCar.HTML.is(':visible')) {
            if (view.state == 'fleet') {
              wls.lastSelected[currCar.data.id] = currCar
              wls.saveSelectedVehicleToStorage()
              vehicleListChanged = true
            }
            if (currCar.cached) {
              if (view.state == 'proximity') {
                currCar.drawLine(proximity.pin.data || proximity.pin)
                return
              }
              // if (!currCar.data.inStealthMode) {
                currCar.add(true)
              // }
            } else {
              currCar.cache()
              $(currCar.mapView.vehImg).one('load', function h () {
                if (view.state == 'proximity') {
                  currCar.drawLine(proximity.pin.data || proximity.pin)
                  return
                }
                // if (!currCar.data.inStealthMode) {
                  currCar.add(true)
                // }
              })
              if (currCar.mapView.vehImg.complete) $(currCar.mapView.vehImg).load()
            }
          }
        })()
      }
      search.resetTableResults()
      if (vehicleListChanged) {
        $(document).trigger('vehicleListChange')
      }
    }
    this.resetFromMap = function () {
      if (search.cars != null && search.cars[0].mapView.markerIsOnMap && search.cars[0].HTML.is(':visible')) {
        search.cars[0].remove()
        search.cars[0].HTML.find('.zoom.active').removeClass('active')
      }

      wls.resetSavedStates()
      $(document).trigger('vehicleListChange')
    }
    this.resetActionHolder = function () {
      search.selectedGroup = $.cookie('lastViewedGroup')
      search.selectedAccount = $.cookie('lastViewedAccount')

      search.resetFromMap()
      search.requestSearchCars(false)
    }
    this.requestSearchCars = function (show) {
      search.actionHolderHide()

      $.post('/api/unit-view/description', {
        unitViewId: search.selectedGroup
      }).done(function (msg) {
        var groupDescr = msg
        console.log('..' + groupDescr)
        $('.hover-tooltip').attr('data-tooltip', groupDescr)
        $('.control-holder .groups-tooltip').text(groupDescr)
        $.cookie('lastViewedGroupDescr', groupDescr)
      }).error(function (msg, errorText) {
        console.error('Failed to load Group descr, reason: ' + errorText)
      })

      sendCurrentAccount(search.selectedAccount, function () {
        settings.get(true).done(function () {
          if (settings.data.showVehReg) {
            wlsMapTools.showVehRegCheckbox()
          } else {
            wlsMapTools.hideVehRegCheckbox()
          }
          if (settings.data.drivingTimePanelAllowed) {
            wlsMapTools.showDrivingTimeButton()
          } else {
            wlsMapTools.hideDrivingTimeButton()
          }

          if (settings.data.refreshSmSeconds) {
            wls.setTimerValue(settings.data.refreshSmSeconds)
          }
          wls.resetSavedStates()
          wls.resetLastZoomed()
          wls.requestCars(search.selectedGroup, function () {
            panel.resetSortOrder()
            leftPanel.find('.expand').removeClass('active')
            leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
            if (settings.data.htmlGroupTabsAllowed) {
              leftPanel.find('.right-col .vehicle-holder').css('min-height', '300px')
            }
            proximity.generateSelectVehicleDropdown()

            // if (view.getActiveState() != "fleet") {
            //     view.switchStateTo("fleet");
            // }
            selectedGroup = search.selectedGroup
            $.cookie('lastViewedGroup', selectedGroup)

            if (show) {
              search.cars = wls.getVehicles()
              search.showOnMap()
            }

            if (view.state !== 'proximity' && settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('no-group-tab') && !leftPanel.find('.incidents').is(':visible')) {
              leftPanel.removeClass('no-group-tab').addClass('has-group-tab')
              $('[data-tabset=group]').show(250)
            }
            if (view.state !== 'proximity' && settings.data.htmlGroupTabsAllowed) {
              $('[data-tabset=group]').show(250)
              window.groupTabsetView.collection.fetch({
                success: function () {
                  $.cookie('lastViewedGroup', null) // reset cookie
                  $.cookie('lastViewedGroup', selectedGroup)
                  groupTabsetView.render()
                }
              })
            } else if (!settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('has-group-tab')) {
              $('[data-tabset=group]').hide()
              leftPanel.removeClass('has-group-tab').addClass('no-group-tab')
            }
          })
        })
      })
    }
    function fileExport () {
      var data = search.carsArray
      var arr = removeDuplicates(data, 'vehicleId')
      var len = arr.length
      var newArr = []
      for (var i = 0; i < len; i++) {
        newArr.push(arr[i].vehicleId)
      }
      return newArr.join(',')
    }
    function filterArray (array, test) {
      var passed = []
      for (var i = 0; i < array.length; i++) {
        if (test(array[i])) { passed.push(array[i]) }
      }
      return passed
    }
    this.bindEvents = function () {
      var newData = []
      $HTML.find('.close-btn').off('click').click(function () {
        search.hideSearchResult()
        search.resetTableResults()
      })
      $HTML.find('.action-row .cancel').off('click').click(function () {
        search.hideSearchResult()
        search.resetTableResults()
      })
      $HTML.find('.search-result>tbody tr').off('click').click(function (e) {
        if ($(this).hasClass('active') && !settings.data.singleClickInDataGridAllowed) {
          $(this).removeClass('active')
          $HTML.find('.action-row .button.save').addClass('btn-disabled')
        } else if (!$(this).hasClass('active') && !settings.data.singleClickInDataGridAllowed) {
          $(this).addClass('active').siblings().removeClass('active')
          $HTML.find('.action-row .button.save').removeClass('btn-disabled')
          search.selected = $(this).attr('data-groupid')
          search.name = $(this).find('.reg_num').text()
        }

        if (settings.data.singleClickInDataGridAllowed) {
          if (settings.data.htmlGroupTabsAllowed) {
            window.groupTabsetView.findSubView('colorPicker').hide()
          }
          search.selected = $(this).attr('data-groupid')
          search.name = $(this).find('.reg_num').text()
          showVehicle()
        }
      })
      $HTML.find('.action-row .button.save').off('click').click(function () {
        if (settings.data.htmlGroupTabsAllowed) {
          window.groupTabsetView.findSubView('colorPicker').hide()
        }
        showVehicle()
      })
      $HTML.find('.export-row .button.pdf-btn').off('click').click(function (e) {
        var str = fileExport()
        var action = '/load-report?report-name=Vehicle Search Report&report-type=VehicleSearch&report-format=PDF&alternate=false&vehicles=' + str
        window.open(action)
      })

      $HTML.find('.export-row .button.xls-btn').off('click').click(function (e) {
        var str = fileExport()
        var action = '/load-report?report-name=Vehicle Search Report&report-type=VehicleSearch&report-format=XLS&alternate=false&vehicles=' + str
        window.open(action)
      })
      $variants.find('.slidee li').off('click').click(function (e) {
        var text = $(this).find('.search-variant__name').text()
        newData = []

        search.hideHelpers()

        search.showSearchResults(search.text, function (data) {
          search.carsArray = data
          newData = filterArray(data, function (car) {
            return car.registrationNumber.toLowerCase() === text.toLowerCase()
          })

          search.text = text

          if (newData.length > 1) {
            search.generateTable(newData)
            search.initDraggable()
            search.setHeaderPanelText(text, newData.length)
            search.showSearchResult()
            search.initScroll($('.vehicle-search-popup'), false)
            search.bindEvents()
          } else {
            if (wls.hasShapes()) window.mapCanvas.cleanUp()

            wls.carsArray = [newData[0].vehicleDto]
            search.selectedGroup = newData[0].groupId
            search.selectedAccount = newData[0].accountId

            wls.importCars()
            wls.createHtml(function callAfterVehiclesCreated () {

            })

            search.cars = wls.getVehicles()

            // if(leftPanel.hasClass('has-group-tab')){
            //     leftPanel.removeClass('has-group-tab').addClass("no-group-tab");
            // }
            leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])

            search.showOnMap()
            search.actionHolderShow()

            if (settings.data.htmlGroupTabsAllowed) {
              leftPanel.find('.right-col .vehicle-holder').css('min-height', 'auto')
              groupTabsetView.renderScroll()
            }
          }
        })

        search.variantsHide()
      })

      $HTML.find('thead th').off('click').click(function (e) {
        var pageX = e.pageX - $HTML.offset().left
        var pageY = e.pageY - $HTML.offset().top
        dataToSort = $(this).attr('data-sorting')
        $thActive = $(this)

        $HTML.find('.table-drop').css({ display: 'block', left: pageX, top: pageY })
      })

      $HTML.find('.table-drop li').off('click').click(function (e) {
        var sortMethod = e.currentTarget.attributes.getNamedItem('data-sort-order').value
        search.sort(sortMethod, dataToSort)
      })

      leftPanel.find('.actions-holder .cancel-btn').off('click').click(function () {
        search.resetActionHolder()
      })
      leftPanel.find('.actions-holder .show-group-btn').off('click').click(function () {
        search.resetFromMap()
        search.requestSearchCars(true)
      })
    }
  }
  function ActivityLog () { // ActivityLog Class
    var av = this
    var date = new Date()
    var $HTML = ''
    var tableHTML = ''
    var $table = ''
    var $frame
    var inited = false
    var activityLogJson = []
    var activityIdLoglist = {}
    var oldTbody = ''
    var newTbody = ''
    var currCar
    var $frame = null
    var $wrap = null
    var logEntryMarker = ''
    var activityLogTrTpl = new Template(
      '<tr data-log-entry-id="${id}">' +
      '<td style="width:38px;">${formatedTime}</td>' +
      '<td style="width: 41px;"><img src="../${ingnitionIconUrl}" alt=""><img src="../${directionIconUrl}" alt=""></td>' +
      '<td title="${streetName}">${streetName}</td>' +
      '<td style="width:64px;">${postcode}</td>' +
      '<td style="width:44px;">${factoredSpeed}</td>' +
      '</tr>'
    )

    this.insertMetrics = function () {
      var speedMetric = 'km/h'
      if (userPrefs.distanceMetric != 'KM') {
        speedMetric = 'MPH'
      }
      $HTML.find('th:last').text(speedMetric.toLowerCase())
    }
    this.reloadScroll = function () {
      $frame.attr('style', '')
      if ($HTML.height() < $HTML.find('.info-holder').outerHeight() + $HTML.find('.header-panel').outerHeight()) {
        $frame.height($HTML.height() - ($HTML.find('.header-panel').outerHeight() + $HTML.find('table.info:first').outerHeight()))
        if (!inited) {
          initSly()
        }
        $frame.sly('reload')
        $wrap.find('.scrollbar').show()
      } else {
        $frame.sly('destroy')
        $wrap.find('.scrollbar').hide()
        $frame.attr('style', '')
        inited = false
      }
    }
    this.updateTitle = function (registrationNumber) {
      $HTML.find('.header-panel .title').text(registrationNumber)
    }
    this.isVisible = function () {
      return $HTML.is(':visible')
    }
    this.setCurrCar = function (carInst) {
      currCar = carInst
    }
    this.showMessage = function () {
      $HTML.find('.info-holder').hide()
      $HTML.find('.message').show()
    }
    this.hideMessage = function () {
      $HTML.find('.info-holder').show()
      $HTML.find('.message').hide()
    }
    this.alingLog = function () {
      $HTML.css({ left: leftPanel.width() + 50 })
    }
    function initSly () {
      $frame.sly({
        horizontal: 0,
        speed: 300,
        easing: 'linear',
        scrollBar: $wrap.find('.scrollbar'),
        scrollBy: 100,
        dragHandle: 1,
        dynamicHandle: 1,
        clickBar: 1
      })
      inited = true
    }
    function getActivityMapDescrFlx (activity, canUserRead, canResellerHover, metrics) {
      var text
      if (canResellerHover) {
        var date = new Date(activity.recDate)
        if (metrics == 'KM') {
          metrics = 'KM/H'
        } else {
          metrics = 'MPH'
        }
        text =
          date.toString() + '<br>' +
          'Ignition ' + (activity.state ? 'On' : 'Off') + '<br>' +
          ((activity.streetName != '' && activity.streetName != null) ? (activity.streetName + '<br>') : '') +
          ((activity.postcode != '' && activity.postcode != null) ? activity.postcode + '<br>' : '') +
          activity.factoredSpeed + metrics + ' ' + '(' + activity.directionCode.toString().toUpperCase() + ')' + '<br>'
      }
      return text
    }
    this.init = function () {
      $HTML = $('.activity-log')
      $frame = $('.scroll-content', $HTML)
      $wrap = $frame.parent()
      $table = $HTML.find('table.info.content')
      oldTbody = $('>tbody', $table)[0]
      $HTML.attr('style', '')
      $HTML.draggable({
        containment: 'parent',
        cancel: '.close-btn',
        handle: $HTML.find('.header-panel')
      })
      initSly()
      $(window).on('resize.ActivityLog', av.reloadScroll)
      $table.on('click', 'tr', function () {
        var tr = $(this)
        var iconUrl = tr.find('img:first').attr('src')
        var statusUrl = tr.find('img:last').attr('src')
        var activityLogEntry = activityIdLoglist[tr.attr('data-log-entry-id')]
        var image = currCar.mapView.vehImg
        var backgorundColor = "style='align:center;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#40fffffff,endColorstr=#40fffffff);background:rgba(255, 255, 255, 0.6)'"
        var htmlMarker = "<span style='font-family:Arial; font-size:x-small;" +
          "color:white; background-color:transparent'>" +
          "<table border='0' cellpadding='5' cellspacing='0' width='" + (image.width + additionalPixelsForHtmlWidthActivity) + "' height='" + (image.height + additionalPixelsForHtmlHeightActivity) + "'" +
          "  style='position:relative;left:" + window.mapCanvas.calculateXOffSet(image.width + additionalPixelsForHtmlWidthActivity) + ';margin-top:' + window.mapCanvas.calculateYOffSet(image.height + additionalPixelsForHtmlHeightActivity) + "' >" +
          '<tr>' +
          '<td ' + backgorundColor + "><img src='" + iconUrl + "'></td><td " + backgorundColor + "><img src='" + statusUrl + "'></td><td " + backgorundColor + '><b style="color:#000;">' + tr.find('td:first-child').text() + "</b></td><td><img src='" + image.src + "'><td></tr>" +
          '</table></span>'
        if (tr.hasClass('active')) return
        av.removeLogEntryMarker()
        window.mapCanvas.addTextMarkerSimple(image,
          getActivityMapDescrFlx(activityLogEntry, true, true, 'KM'),
          activityLogEntry.lon,
          activityLogEntry.lat,
          true,
          function (result) {
            logEntryMarker = result
            rezoomMapFlx()
            tr.siblings().removeClass('active')
            tr.addClass('active')
          },
          htmlMarker)
      })
      $HTML.find('.close-btn').click(function () {
        av.hide()
        av.removeLogEntryMarker()
        currCar.HTML.removeClass('active')
      })
    }
    this.show = function (callback) {
      $HTML.fadeIn(400, callback)
    }
    this.hide = function (callback) {
      $HTML.fadeOut(400, callback)
      if (currCar) {
        currCar.HTML.removeClass('active')
      }
    }
    this.requestActivityLog = function (vehicleId, callback) {
      if (!callback) return
      $.post('/getTodayActivityEventsForVehicle.do', {
        unitId: vehicleId
      }).done(function (json) {
        activityLogJson = json.data
        callback()
      }).error(function (msg, errorTxt) {
        alert('error')
      })
    }
    this.generateLogs = function () {
      var jsonLen = activityLogJson.length
      var currLog
      var activityLogtableTr
      for (var i = 0; i < jsonLen; i++) {
        currLog = activityLogJson[i]
        activityIdLoglist[currLog.id] = currLog
        currLog.formatedTime = formatDateToHM(currLog.recDate)
        if (currLog.journeyStart) {
          currLog.directionIconUrl = 'img/arrows/arrows_flag_start.png'
        } else if (currLog.journeyEnd) {
          currLog.directionIconUrl = 'img/arrows/arrows_flag_end.png'
        } else {
          currLog.directionIconUrl = 'img/arrows/arrows_blue_' + currLog.directionCode + '.png'
        }
        currLog.ingnitionIconUrl = 'img/vehicle_status_' + ((currLog.factoredSpeed) > 0 ? 'moving' : (currLog.factoredSpeed == 0 && currLog.ignitionStr == 'ON') ? 'idle' : 'parked') + '.png'
        activityLogtableTr += activityLogTrTpl.compile(currLog)
      }
      $table.find('tbody').html(activityLogtableTr)
    }
    this.noLogs = function () {
      return activityLogJson.length == 0
    }
    this.clearTable = function () {
      $table.find('tbody').html('')
      av.reloadScroll()
    }
    this.removeLogEntryMarker = function () {
      if (logEntryMarker) {
        window.mapCanvas.removeMarker(logEntryMarker)
        logEntryMarker = null
        rezoomMapFlx()
      }
    }
    function reloadScroll () {
      if ($frame.find('.slidee').height() > $frame.height()) {
        $wrap.find('.scrollbar').show()
      } else {
        $wrap.find('.scrollbar').hide()
      }
      $frame.sly('reload')
    }
  }
  function proximityView () { // proximityView Class
    var pv = this
    var select = null
    var pin = this.pin = null
    var selectInput = null
    var searchInput = null
    var redMarker = new Image()
    var form = ''
    var formDropdown = ''
    redMarker.src = 'img/markers/marker_red.png'
    this.bindEvents = function () {
      var selectVehicle = $('[data-select-vehicle]')
      var selectPOI = $('[data-select-poi]')
      form = leftPanel.find('.proximity-search').closest('.search')
      searchInput = form.find('.proximity-search')
      formDropdown = form.find('.dropdown')
      if (!selectPOI.data('defVal')) {
        selectPOI.data('defVal', selectPOI.find('.title-holder span').text())
        selectVehicle.data('defVal', selectVehicle.find('.title-holder span').text())
      }
      selectVehicle.add(selectPOI).off('click').on('click', function () {
        $(this).toggleClass('active').parent().siblings().find('.select').removeClass('active')
      })
      selectVehicle.add(selectPOI).find('.dropdown li').off('click').click(function () {
        var option = $(this)
        var curSelect = $(this).closest('.select')
        var title = curSelect.find('.title-holder span')
        var mapObject
        var otherSelect = selectVehicle.add(selectPOI).not($(this).closest('.select'))
        title.text(option.text())
        if (htmlGroupTabsAllowed) {
          if (leftPanel.find('.right-col .vehicle-holder').is(':hidden')) {
            leftPanel.find('.right-col .vehicle-holder').show()
            leftPanel.find('.control-panel,.control-panel+.row').show()
            groupTabsetView.renderScroll()
          }
        } else {
          if (leftPanel.find('.right-col .vehicle-holder').css('visibility') == 'hidden') {
            leftPanel.find('.right-col .vehicle-holder').css('visibility', 'visible')
            leftPanel.find('.control-panel,.control-panel+.row').show()
          }
        }
        if (searchVehicle) {
          leftPanel.find('.vehicle-search').hide()
        }
        pv.resetPin()

        if (curSelect.is('[data-select-vehicle]')) {
          mapObject = wls.getIdList()[option.attr('data-id')]
          pin = pv.pin = mapObject
          mapObject.addPin()
          mapObject.ignore = true
          mapObject.HTML.hide()
          leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize')
          leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
        } else if (curSelect.is('[data-select-poi]')) {
          mapObject = wlsPOIHandler.getIdList()[option.attr('data-id')]
          pin = pv.pin = mapObject
          mapObject.addPin()
        }
        wls.showDistances()
        pv.resetSelect(otherSelect.find('.title-holder span'), otherSelect.data('defVal'))
        form.removeClass('active')
        formDropdown.find('ul').html('')
        leftPanel.find('.right-col').removeClass('hide-shadow')
      })
      function geocodeCallback (result) {
        var len = result.length
        var options = ''
        if (!result || len == 0) {
          alert('There were no locations found')
          return
        }
        pv.resetPin()
        for (var i = 0; i < len; i++) {
          options += "<li data-id='" + i + "'>" + result[i].description + '</li>'
        }
        options = $(options)
        formDropdown.find('ul').html(options)
        formDropdown.width(formDropdown.parent().outerWidth())
        function clickHandler (e) {
          var markerData = e.data.markerData
          if (pin) {
            pv.resetPin()
          }
          window.mapCanvas.addTextMarkerSimple(redMarker, 'Chosen Location', markerData.lon, markerData.lat, true, function (result) {
            pin = pv.pin = result
            pin.remove = function () {
              window.mapCanvas.removeMarker(this)
            }
            pin.lon = markerData.lon
            pin.lat = markerData.lat
            form.removeClass('active')
            if (htmlGroupTabsAllowed) {
              if (leftPanel.find('.right-col .vehicle-holder').is(':hidden')) {
                leftPanel.find('.right-col .vehicle-holder').show()
                leftPanel.find('.control-panel,.control-panel+.row').show()
                groupTabsetView.renderScroll()
              }
            } else {
              if (leftPanel.find('.right-col .vehicle-holder').css('visibility') == 'hidden') {
                leftPanel.find('.right-col .vehicle-holder').css('visibility', 'visible')
                leftPanel.find('.control-panel,.control-panel+.row').show()
              }
            }
            if (searchVehicle) {
              leftPanel.find('.vehicle-search').hide()
            }
            wls.showDistances()
            setTimeout(rezoomMapFlx, 1500)
            leftPanel.find('.right-col').removeClass('hide-shadow')
          })
        }
        for (var i = 0; i < len; i++) {
          options.eq(i).on('click', { markerData: result[options[i].getAttribute('data-id')] }, clickHandler)
        }
        options.removeAttr('data-id')
        form.addClass('active')
        pv.resetSelect()
        initSelectScroll($('.scroll-content', form))

        setTimeout(rezoomMapFlx, 1500)
      }
      form.submit(function (e) {
        e.preventDefault()
        if ($.trim(searchInput.val())) {
          window.mapCanvas.geocode(searchInput.val(), geocodeCallback)
        }
      })
      searchInput.focus(function () {
        if (formDropdown.find('li').size()) {
          form.addClass('active')
        }
      })
      $(document).off('click.select').on('click.select', function (e) {
        if (!$(e.target).closest('.select').size()) {
          selectVehicle.add(selectPOI).removeClass('active')
        }
        if (!$(e.target).closest('.search').size()) {
          form.removeClass('active')
        }
      })
    }
    this.generateSelectVehicleDropdown = function () {
      var collection = wls.getVehicles()
      var curSelect = $('[data-select-vehicle]')
      var idList = wls.getIdList()
      var len = collection.length
      var options = ''
      for (var i = 0; i < len; i++) {
        if (!collection[i].data.inStealthMode) {
          options += "<li data-id='" + collection[i].data.id + "'>" + collection[i].data.description + '</li>'
        }
      }
      setOptions(options, $('.dropdown', curSelect))
      curSelect.addClass('active')
      initSelectScroll($('.scroll-content', curSelect))
      curSelect.removeClass('active')
    }
    this.generateSelectPOIDropdown = function (callback) {
      var collection = wlsPOIHandler.OOIs
      var curSelect = $('[data-select-poi]')
      var len = collection.length
      var options = ''
      wlsPOIHandler.requestOOIs(function () {
        collection = wlsPOIHandler.OOIs
        len = collection.length
        for (var i = 0; i < len; i++) {
          options += "<li data-id='" + collection[i].data.id + "'>" + collection[i].data.description + '</li>'
        }
        setOptions(options, $('.dropdown', curSelect))
        curSelect.addClass('active')
        initSelectScroll($('.scroll-content', curSelect))
        curSelect.removeClass('active')
        if (callback) callback()
      })
    }
    function initSelectScroll ($frame) {
      var $wrap = $frame.parent()
      if ('sly' in $frame) {
        $frame.sly('destroy')
      }
      $frame.sly({
        speed: 300,
        easing: 'linear',
        scrollBar: $wrap.find('.scrollbar'),
        scrollBy: 100,
        dragHandle: 1,
        dynamicHandle: 1,
        clickBar: 1
      })
      if ($frame.find('.slidee').height() > $frame.height()) {
        $wrap.find('.scrollbar').show()
      } else {
        $wrap.find('.scrollbar').hide()
      }
    }
    function setOptions (options, $dropdown) {
      options = $(options)
      $dropdown.find('ul').html(options)
    }

    this.resetPin = function () {
      if (pin) {
        pin.remove()
        pin.ignore = false
        if ('HTML' in pin) {
          pin.HTML.show()
        }
        pin = null
        leftPanel.find('.proximity-search').val('')
      }
      if (wls.hasShapes()) {
        wls.removeCars()
        console.log('resetPin()')
        window.mapCanvas.cleanUp('imgMarker')
      }
    }

    this.resetSelect = function (title, val) {
      if (title && val) {
        title.text(val)
      } else {
        $('[data-select-poi],[data-select-vehicle]').each(function (i) {
          $(this).find('.title-holder span').text($(this).data('defVal'))
        })
      }
    }
    this.resetSearchDropdown = function () {
      if (formDropdown) {
        formDropdown.find('ul').html('')
      }
    }
  }

  function carHandler (array) { // car handler Class
    var tooltipUrl = null
    var cars = []
    var timer = null
    var countdownInterval = null
    var timerValue = 30
    var $HTML = ''
    var $HTMLFleetView = ''
    var handler = this
    var lastZoomed = this.lastZoomed = {}
    var lastSelected = this.lastSelected = {}
    var lastExpanded = this.lastExpanded = {}
    var idList = {}
    var lastClickedVehicle = null
    var updateTimestamp
    var activityLogEventBound = false
    this.carsArray = []

    // add information variables
    this.openerVehId = null
    var informationText
    var openerOffsetTop
    var oldText

    var isActivityLogsOnMap = false

    settings.onLoad(function () {
      userPrefs = settings.data
      window.userPrefs = userPrefs
      if (userPrefs.showVehTooltipAllowed) {
        tooltipUrl = '../img/tooltip.png'
      }
      timerValue = userPrefs.refreshSmSeconds
    })

    function lastSelectedNotEmpty () {
      for (var key in lastSelected) {
        return true
      }
      return false
    }

    function lastSelectedHas (currCar) {
      for (var key in lastSelected) {
        if (currCar.data.id == lastSelected[key].id) return true
      }
      return false
    }
    this.getTooltipUrl = function () {
      return tooltipUrl
    }
    this.importCars = function (stopZooming) {
      var len = handler.carsArray.length
      if (cars.length) cars = []
      for (var i = 0; i < len; i++) {
        cars[i] = new car(handler.carsArray[i], stopZooming)
        idList[cars[i].data.id] = cars[i]
      }
    }
    this.getLastClickedVehicle = function () {
      return lastClickedVehicle
    }
    this.getIdList = function () {
      return idList
    }

    this.setTimerValue = function (value) {
      timerValue = value
      this.resetTimer()
      this.startTimer()
    }

    this.hasShapes = function (currCar) {
      var len = cars.length
      for (var i = 0; i < len; i++) {
        if (cars[i].mapView.markerIsOnMap && currCar != cars[i]) return true
      }
      return false
    }

    this.restoreSelectionMenuState = function () {
      handler.selectVehicleFromStorage()
      handler.expandVehicleFromStorage()
      handler.orderVehiclesFromStorage()
      handler.filterVehiclesFromStorage()
    }

    this.orderVehiclesFromStorage = function () {
      if (localStorage.getItem('orderType') != '' && localStorage.getItem('orderDesc') != '') {
        panel.exec(localStorage.getItem('orderType'), localStorage.getItem('orderDesc') == 'true')
      }
    }

    this.filterVehiclesFromStorage = function () {
      if (localStorage.getItem('filter') != '') {
        panel.exec(localStorage.getItem('filter'))
      }
    }

    this.selectVehicleFromStorage = function () {
      if (settings.data.preselectSmOnLogon) {
        panel.exec('SM_SELECT_ALL')
      } else {
        var selectedVehicles = JSON.parse(localStorage.getItem('selectedVehicles'))
        var selectedVehiclesLen = selectedVehicles.length

        for (var i = 0; i < selectedVehiclesLen; i++) {
          var selectedVehiclesId = selectedVehicles[i]
          if (selectedVehiclesId in idList) {
            idList[selectedVehiclesId].HTML.find('.data-holder').click()
          }
        }
      }
    }
    this.saveSelectedVehicleToStorage = function () {
      var selectedVehicleId = []
      for (var key in lastSelected) {
        selectedVehicleId.push(key)
      }
      localStorage.setItem('selectedVehicles', JSON.stringify(selectedVehicleId))
    }

    this.expandVehicleFromStorage = function () {
      var info = leftPanel.find('.right-col .vehicle-holder .slidee').find('.row:visible .info')
      leftPanel.find('.expand').addClass('active')
      info.slideDown(600).promise().done(function () {
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize')
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
        if (htmlGroupTabsAllowed) {
          groupTabsetView.renderScroll()
        }
        if (addInfo && wls.addInformationActive()) {
          wls.calcCoordinates(addInfo)
        }
        if (editVehRegPopupView.isVisible()) {
          wls.calcCoordinates(editVehRegPopupView)
        }

        if (editVehDriverPopupView.isVisible()) {
          wls.calcCoordinates(editVehDriverPopupView)
        }

        if (sendCommandPopupView.isVisible()) {
          wls.calcCoordinates(sendCommandPopupView)
        }

        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
      })
      var expandedVehicles = JSON.parse(localStorage.getItem('expandedVehicles'))
      var expandedVehiclesLen = expandedVehicles.length
      for (var i = 0; i < expandedVehiclesLen; i++) {
        var expandedVehicleId = expandedVehicles[i]
        if (expandedVehicleId in idList) {
          idList[expandedVehicleId].HTML.find('.vehicle-header').click()
        }
      }
    }
    this.saveExpandedVehicleToStorage = function () {
      var expandedVehicleId = []
      for (var key in lastExpanded) {
        expandedVehicleId.push(key)
      }
      localStorage.setItem('expandedVehicles', JSON.stringify(expandedVehicleId))
    }
    this.resetSavedStates = function () {
      this.resetExpandedState()
      this.resetSelectionState()
      this.resetOrderState()
      this.resetFilterState()
    }

    this.resetOrderState = function () {
      localStorage.setItem('orderType', '')
    }

    this.resetFilterState = function () {
      localStorage.setItem('filter', '')
    }

    this.resetSelectionState = function () {
      lastSelected = handler.lastSelected = {}
      handler.saveSelectedVehicleToStorage()
    }

    this.resetExpandedState = function () {
      lastExpanded = handler.lastExpanded = {}
      handler.saveExpandedVehicleToStorage()
    }
    this.resetLastZoomed = function () {
      lastZoomed = handler.lastZoomed = {}
    }
    this.cancelZoom = function () {
      if ($HTML.find('.zoom').hasClass('active')) {
        $HTML.find('.zoom.active').triggerHandler('click')
      }
    }

    this.hasZoomedVehicle = function () {
      var len = cars.length
      for (var i = 0; i < len; i++) {
        if (cars[i].getZoomStatus()) return true
      }
      return false
    }

    this.requestCars = function (groupId, callback, notRestoreCars = false) {
      // incase of switching from Report or any other tab back to Mapping
      if ($.cookie('lastViewedGroup') == groupId && typeof $.cookie('lastViewedGroupDescr') !== 'undefined') {
        // have cookie
        var groupDescr = $.cookie('lastViewedGroupDescr')
        console.log('..' + groupDescr)
        $('.hover-tooltip').attr('data-tooltip', groupDescr)
        $('.control-holder .groups-tooltip').text(groupDescr)
      }
      handler.resetTimer()
      return request = $.post('/getVehiclesByGroup.do', {
        groupId: groupId
      }).done(function (msg) {
        var json = msg
        if (startVehiclesUpdate) {
          if (json.data.length) {
            if (handler.hasShapes()) window.mapCanvas.cleanUp()

            handler.carsArray = json.data
            wlsMapTools.carsArray = handler.carsArray.slice(0)
            handler.importCars(true)
            // if(view.getActiveState() === "dual"){
            //     handler.createFleetViewHtml();
            // } else {
            handler.createHtml(function callAfterVehiclesCreated () {

            })
            // }
            leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize')
          }
          if (!wls.hasZoomedVehicle()) {
            setTimeout(rezoomMapFlx, 1000)
          }
          request = false
          $.cookie('lastViewedGroup', groupId)
          $.cookie('lastRefreshed', Date.now())
          if (timer) {
            handler.resetTimer()
          }
          handler.startTimer()

          if (!updateTimestamp) {
            updateTimestamp = new Date()
            updateTimestamp = new Date(updateTimestamp.valueOf()).getTime() - 300000
          }

          // if (!notRestoreCars) {
          handler.restoreSelectionMenuState()
          // }
        }

        if (callback) callback()
      }).error(function (msg, errorTxt) {
        alert('error')
        request = false
      })
    }
    this.createHtml = function (callAfterLoop) {
      if ($HTML) handler.removeHTML()
      if (panel.fleetFilteredCars) panel.fleetFilteredCars = ''
      for (var i = 0, len = cars.length; i < len; i++) {
        $HTML += cars[i].updateLayout()
      }
      $HTML = $($HTML)
      $('.right-col .vehicle-holder .slidee', leftPanel).append($HTML)
      handler.syncHTML()
      handler.bindEvents()
      if (view.getActiveState() === 'dual') {
        handler.resetSlidee()
        handler.createFleetViewHtml()
      }
    }

    this.reCreateHtml = function () {
      $('.right-col .vehicle-holder .slidee', leftPanel).append($HTML)
      handler.syncHTML()
      handler.bindEvents()
    }

    this.createFleetViewHtml = function (col) {
      var arr = $('.right-col .vehicle-holder .slidee', leftPanel).children()

      handler.filterFleetViewSlidee(arr)
      panel.fleetFilteredCars = arr
    }

    this.reoderFleetViewSlidee = function (cars) {
      var fleetVehicleFarmeWidth = $('.left-panel .right-col .vehicle-frame').width()
      var columns = Math.floor($('.left-panel .right-col').width() / (fleetVehicleFarmeWidth > 323 ? 327 : 323))

      handler.resetSlidee()
      createFleetViewSlidee(cars, columns)
    }

    this.filterFleetViewSlidee = function (cars) {
      var filteredCars = filterCars(cars)
      var fleetVehicleFarmeWidth = $('.left-panel .right-col .vehicle-frame').width()
      var columns = Math.floor($('.left-panel .right-col').width() / (fleetVehicleFarmeWidth > 323 ? 327 : 323))
      var width = 0

      if (filteredCars === 0) {
        width = 327
        leftPanel.find('.right-col').css('width', width)
        columns = 1
      } else if (filteredCars <= columns) {
        width = (filteredCars * (fleetVehicleFarmeWidth > 323 ? 327 : 323)) + (fleetVehicleFarmeWidth > 323 ? 0 : 4)
        leftPanel.find('.right-col').css('width', width)
        columns = filteredCars
      }

      createFleetViewSlidee(cars, columns)
      // handler.bindEvents();
    }

    function filterCars (cars) {
      let count = 0

      for (var i = 0; i < cars.length; i++) {
        if ($(cars[i]).css('display') !== 'none') {
          count++
        }
      }

      return count
    }

    this.resetSlidee = function () {
      // var slideeColumn = $(".right-col .vehicle-holder .slidee",leftPanel).children();
      var slidee = $('.right-col .vehicle-holder .slidee', leftPanel)
      var children = $(slidee).children()

      for (var i = 0; i < children.length; i++) {
        if (!$(children[i]).hasClass('isotope-elem')) {
          slidee.append($(children[i]).children())
          $(children[i]).remove()
        }
      }
    }

    function createFleetViewSlidee (arr, col) {
      var carsOrder = filterCars(arr)
      var carsInColumn = Math.ceil(carsOrder / col)
      var columns = col
      var elemLeft = carsOrder
      var slidee = $('.right-col .vehicle-holder .slidee', leftPanel)

      var $slideeColumn = $("<div class='slidee-column'></div>")

      for (var i = 0, len = arr.length; i < len; i++) {
        if ($(arr[i]).css('display') !== 'none') {
          $slideeColumn.append(arr[i])
          elemLeft--

          if ($slideeColumn.children().length >= carsInColumn) {
            slidee.append($slideeColumn)
            $slideeColumn = $("<div class='slidee-column'></div>")

            columns--

            if (elemLeft % columns === 0) {
              carsInColumn = elemLeft / columns
            }
          }
        }
      }

      if ($slideeColumn.children().length > 0) {
        $('.right-col .vehicle-holder .slidee', leftPanel).append($slideeColumn)
      }
    }

    this.removeSelected = function (currCar) {
      var len = cars.length
      for (var i = 0; i < len; i++) {
        if (cars[i].mapView.markerIsOnMap && cars[i] != currCar) {
          cars[i].remove()
        }
      }
    }

    this.removeCars = function (noRemoveClass) {
      var len = cars.length
      for (var i = 0; i < len; i++) {
        if (cars[i].mapView.markerIsOnMap) cars[i].remove(noRemoveClass)
      }
    }
    this.showDistances = function () {
      var pinData = proximity.pin.data || proximity.pin
      var len = cars.length
      for (var i = 0; i < len; i++) {
        if (!cars[i].data.inStealthMode) {
          cars[i].showDistance(pinData)
        }
      }
      panel.exec('SM_DISTANCE')
    }
    this.addInformationActive = function () {
      return addInfo.is(':visible')
    }
    this.showAddInformation = function () {
      addInfo.show()
    }
    this.hideAddInformation = function () {
      addInfo.hide()
      // informationText;
    }
    function isInformation (arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === parseInt(handler.openerVehId, 10)) {
          if (arr[i].clientInfo !== null && arr[i].clientInfo.length > 0) {
            addInfo.find('.add-info-input label').text('Edit text:')
            addInfo.find('.button.save').text('Save')
            addInfo.find('.header-panel .title .info-action').text('Edit')
            addInfo.find('.add-info-input input').val(arr[i].clientInfo)
            break
          } else {
            addInfo.find('.add-info-input label').text('Add text:')
            addInfo.find('.button.save').text('Save')
            addInfo.find('.header-panel .title .info-action').text('Add')
            addInfo.find('.add-info-input input').val('')
          }
        }
      }
    }
    function addToArray (arr, info) {
      var text = info

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === parseInt(handler.openerVehId, 10)) {
          arr[i].clientInfo = text
        }
      }
    }

    function updateImmobState (id, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === parseInt(id, 10)) {
          arr[i].immobilizationDate = new Date()
        }
      }
    }
    this.calcCoordinates = function (el, $fr) {
      var top = openerOffsetTop.offset().top
      var left = leftPanel.outerWidth() + 8
      if (el instanceof Backbone.View) {
        var view = el
        if (($fr && (top + view.$el.outerHeight()) > ($('.map-wrap').outerHeight() - 50)) || ($fr && top < $('.vehicle-holder').offset().top)) {
          el.hide()
        } else if (!$fr && (top + view.$el.outerHeight()) > ($('.map-wrap').outerHeight() - 50)) {
          el.alignBy(openerOffsetTop)
        } else {
          el.alignBy(openerOffsetTop)
        }
      } else {
        isInformation(handler.carsArray)

        oldText = addInfo.find('.add-info-input input').val()

        if ($fr && (top + el.outerHeight()) > ($('.map-wrap').outerHeight() - 50)) {
          addInfo.hide()
        } else if (!$fr && (top + el.outerHeight()) > ($('.map-wrap').outerHeight() - 50)) {
          el.css({ top: top - el.outerHeight() + 24, left: left })
          el.find('.before').hide()
          el.find('.after').show()
        } else if ($fr && top < $('.vehicle-holder').offset().top) {
          addInfo.hide()
        } else {
          el.css({ top: top - 3, left: left })
          el.find('.after').hide()
          el.find('.before').show()
        }
      }
    }
    function addInformation (vehicleId, info) {
      $.post('/api/selection-menu/action/addInfo/' + vehicleId + '?info=' + info + '').done(function (data) {
        addToArray(handler.carsArray, info)
        // handler.importCars();
        handler.updateCars()
      })
    }
    this.bindEvents = function () {
      var len = cars.length
      var size = Math.round($HTML.size() / 2)
      var optionsDrop = $('body>.options-drop')
      var ignitionIconsAllowed = userPrefs.ignitionIconsVersion > 0
      var todaysJourneysReportName = reportHandler.getReportName('todaysJourneysReport')
      var openerOuterWidth
      var openerTitle = ''

      function showOnMap (currCar) {
        var data = currCar.data
        var dotOnMap = data.dotOnMap

        currCar.HTML.click(function () {
          lastClickedVehicle = currCar
        })
        currCar.HTML.find('.data-holder')
          .one('click.cache', function () {
            currCar.HTML.find('.zoom').off('click.cache')
            currCar.cache()
          })
          .click(function (e) {
            var isLW = $(this).parents('.row').hasClass('lone-worker')

            if (e.target.id !== 'sosNumber' && e.target.id !== 'phoneNumber') {
              var zoomActive = currCar.HTML.siblings().has('.zoom.active')

              if (currCar.mapView.markerIsOnMap) {
                currCar.remove()
                if (view.state == 'fleet' || view.state == 'dual') {
                  delete lastSelected[currCar.data.id]
                  wls.saveSelectedVehicleToStorage()
                  $(document).trigger('vehicleListChange')
                }
                return
              }
              if (view.state == 'fleet' || view.state == 'dual') {
                lastSelected[currCar.data.id] = currCar
                wls.saveSelectedVehicleToStorage()
              }
              $(currCar.mapView.vehImg).one('load', function h () {
                if (view.state == 'proximity') {
                  if (proximity.pin) {
                    currCar.drawLine(proximity.pin.data || proximity.pin)
                  }
                  return
                } else if (view.state == 'activity') {
                  currCar.HTML.addClass('active').siblings().removeClass('active')
                  window.wls.openerVehId = currCar.data.id
                  var lastSelectedVehicles = Object.keys(lastSelected)
                  var lastSelectedLength = lastSelectedVehicles.length
                  for (var i = 0; i < lastSelectedLength; i++) {
                    delete lastSelected[lastSelectedVehicles[i]]
                  }
                  lastSelected[currCar.data.id] = currCar
                  if (activityLogPopupView && activityLogPopupView.isVisible()) {
                    activityLogPopupView.destroy()
                  }
                  liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
                  window.mapCanvas.cleanUp()
                  if (!isLW) {
                    activityLogPopupView = new ActivityLogPopupView(function () {
                      if (currCar) {
                        currCar.HTML.removeClass('active')
                      }
                    })
                  } else {
                    activityLogPopupView = new LoneWorkerActivityLogPopup(function () {
                      if (currCar) {
                        currCar.HTML.removeClass('active')
                      }
                    })
                  }

                  return
                }
                // if (!currCar.data.inStealthMode) {
                  currCar.add()
                // }
              })
              if (currCar.mapView.vehImg.complete) $(currCar.mapView.vehImg).load()
            }
          })
        currCar.HTML.find('.zoom')
          .one('click.cache', function () {
            currCar.HTML.find('.data-holder').off('click.cache')
            currCar.cache()
          })
          .click(function () {
            var zoomActive = currCar.HTML.siblings().has('.zoom.active')

            lastZoomed = {}
            $(this).toggleClass('active')
            if ($(this).hasClass('active')) {
              if (!currCar.mapView.markerIsOnMap) {
                $(currCar.mapView.vehImg).one('load', function h () {
                  currCar.add()
                })
                if (currCar.mapView.vehImg.complete) $(currCar.mapView.vehImg).load()
              }
              currCar.setZoomStatus(true)
            } else {
              currCar.setZoomStatus(false)
              rezoomMapFlx()
            }
            if (zoomActive.size()) {
              idList[zoomActive.find('input[type=hidden]').val()].setZoomStatus(false, true)
              zoomActive.find('.zoom.active').removeClass('active')
            }
          })
        currCar.HTML.find('[data-button=tachograph]').parent().click(function () {
          var $button = $(this)
          var driverWorkingHoursCalendarPopupView = driverWorkingHoursTodayPopupView.findSubView('driverWorkingHoursCalendarPopup')
          var driverWorkingHoursTotalView = driverWorkingHoursTodayPopupView.findSubView('driverWorkingHoursTotal')
          var driverWorkingHoursCalendarView = driverWorkingHoursCalendarPopupView.findSubView('driverWorkingHoursCalendar')
          var driverWorkingHoursDetailsPopupView = driverWorkingHoursCalendarView.findSubView('driverWorkingHoursDetailsPopup')
          var driverWorkingHoursDetailsTableGridView = driverWorkingHoursDetailsPopupView.findSubView('driverWorkingHoursDetailsTableGrid')

          if (driverWorkingHoursCalendarPopupView.isVisible()) {
            driverWorkingHoursCalendarPopupView.hide()
          }
          $button.children().addClass('active').closest('.row').siblings().find('[data-button=tachograph]').removeClass('active')
          if (currCar.data.currentDriverId) {
            driverWorkingHoursTotalView.fetchTodayTotal().done(function (collection) {
              driverWorkingHoursTotalView.render(collection[0])
              driverWorkingHoursTodayPopupView.show()
              driverWorkingHoursTodayPopupView.alignBy($button, 'center', { top: 5, left: 0 })
            })
          } else {
            driverWorkingHoursTotalView.render({ noActiveDriver: true })
            driverWorkingHoursTodayPopupView.show()
            driverWorkingHoursTodayPopupView.alignBy($button, 'center', { top: 5, left: 0 })
          }

          //                        driverWorkingHoursDetailsPopupView.prepare();
          //                        driverWorkingHoursDetailsTableGridView.fetchDriverWorkingHoursDetails().done(function(){
          //                            driverWorkingHoursDetailsTableGridView.render()
          //                            driverWorkingHoursDetailsPopupView.prepareDone();
          //                            driverWorkingHoursDetailsPopupView.show();
          //                        });
          //                        driverWorkingHoursTotalView.render({
          //                            "date": 1461588152586,
          //                            "working": 7200000,
          //                            "driving": 7200000,
          //                            "rest": 7200000,
          //                            "availability": 7200000
          //                        });
          //                        driverWorkingHoursTodayPopupView.show();
          //                        driverWorkingHoursTodayPopupView.alignBy($button,"center",{top: 5,left:0});
        })
      }

      for (var i = 0; i < len; i++) {
        showOnMap(cars[i])
      }

      $('.vehicle-header', $HTML).off('click').click(function (e) {
        var vehicleId = $(this).parent().parent().find('input').val()
        cars = wls.getVehicles()
        var len = cars.length
        if ($(e.target).closest('.btns-holder').size()) return
        // if( leftPanel.find(".right-col .vehicle-holder :animated").size() ) return;
        if ($(this).next().is(':visible')) {
          leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [(leftPanel.find('.right-col .vehicle-holder .slidee').height() - $(this).next().height()) + 5])
          for (var i = 0; i < len; i++) {
            if (cars[i].data.id == vehicleId) {
              wls.lastExpanded[vehicleId] = cars[i]
            }
          }
        } else {
          leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [(leftPanel.find('.right-col .vehicle-holder .slidee').height() + $(this).next().height()) + 5])
          delete wls.lastExpanded[vehicleId]
        }
        wls.saveExpandedVehicleToStorage()
        $(this).next().slideToggle(400, function () {
          $(e.target).next().toggleClass('exp')
          if ($HTML.find('.info:visible').size() >= size) {
            leftPanel.find('.expand').addClass('active')
          } else {
            leftPanel.find('.expand').removeClass('active')
          }
          leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
          if (htmlGroupTabsAllowed) {
            groupTabsetView.renderScroll()
          }
          if (addInfo && wls.addInformationActive()) {
            wls.calcCoordinates(addInfo)
          }
          if (editVehRegPopupView.isVisible()) {
            wls.calcCoordinates(editVehRegPopupView)
          }

          if (editVehDriverPopupView.isVisible()) {
            wls.calcCoordinates(editVehDriverPopupView)
          }

          if (sendCommandPopupView.isVisible()) {
            wls.calcCoordinates(sendCommandPopupView)
          }

          if (heartBeatPopupView) {
            heartBeatPopupView.hide()
          }
          if (phoneNumberPopup) {
            phoneNumberPopup.destroy()
          }
        })
      })
      $('.options', $HTML).off('click').click(function () {
        var $opener = $(this)
        var offset = $opener.offset()
        var c = $opener.closest('.row')
        var vehicleId = parseInt($opener.closest('.row').children('input[type=hidden]').val())
        var vehicle
        for (i = 0; i < cars.length; i++) {
          if (cars[i].data.id === vehicleId) {
            vehicle = cars[i]
          }
        }

        openerOffsetTop = $opener
        handler.openerVehId = $opener.closest('.row').children('input[type=hidden]').val()
        openerTitle = $opener.parents('.vehicle-header').children().children('span:first').text()
        if ($opener.parents('.vehicle-frame').children('.info').children('.data-holder').children('.data-right-col').children('p.added-information').text().length > 0) {
          $('#addInformation .info-action').text('Edit')
        } else {
          $('#addInformation .info-action').text('Add')
        }
        if (c.hasClass('immob')) {
          if (settings.data.immobilizationAllowed) {
            $('#immobilise').show()
            if (vehicle.data.immobilizationDate && vehicle.data.immobilizeStatus) {
              $('#immobilise .immob-action').css('font-style', 'italic')
              $('#immobilise .immob-action').text('Re-mobilising')
            } else if (vehicle.data.immobilizationDate && !vehicle.data.immobilizeStatus) {
              $('#immobilise .immob-action').css('font-style', 'italic')
              $('#immobilise .immob-action').text('Immobilising')
            } else if (c.hasClass('isImmobilized')) {
              $('#immobilise .immob-action').css('font-style', 'normal')
              $('#immobilise .immob-action').text('Re-mobilise')
            } else {
              $('#immobilise .immob-action').css('font-style', 'normal')
              $('#immobilise .immob-action').text('Immobilise')
            }
          }
        } else {
          $('#immobilise').hide()
        }
        if (c.hasClass('lone-worker')) {
          $('#hearBeatOption').show()
          $('#loneWorkerActivityLogOption').show()
          $('#activityLogOption').hide()
          $('#todaysJourneysOption').hide()
          $('#addInformation').hide()
          $('#editVehReg').hide()
          $('#editVehIcon').hide()
          $('#editVehDriver').hide()
        } else {
          $('#hearBeatOption').hide()
          $('#loneWorkerActivityLogOption').hide()
          $('#activityLogOption').show()
          $('#todaysJourneysOption').show()
          $('#addInformation').show()
          if (settings.data.editVehicleIconAllowed) {
            $('#editVehIcon').attr('data-veh-id', handler.openerVehId).show()
          }
          if (settings.data.editVehReg) {
            $('#editVehReg').show()
            $('#editVehReg').attr('data-veh-id', handler.openerVehId)
          }

          if (settings.data.assignDriverToVehicleAllowed) {
            $('#editVehDriver').show()
            $('#editVehDriver').attr('data-veh-id', handler.openerVehId)
            editVehDriverPopupView.renderOption(handler.openerVehId)
          }

          if (vehicle.data.smsSendAllowed) {
            $('#sendSmsMessage').show()
            $('#sendSmsMessage').attr('data-veh-id', handler.openerVehId)
          }
          if (vehicle.data.sendCommandAllowed) {
            $('#sendCommand').show()
            $('#sendCommand').attr('data-veh-id', handler.openerVehId)
          }
        }
        if (view.state === 'dual') {
          $('#addInformation').hide()
          $('#editVehReg').hide()
          $('#editVehIcon').hide()
          $('#editVehDriver').hide()
          if (c.hasClass('lone-worker')) {
            $('#editVehReg').hide()
            $('#editVehIcon').hide()
          }
        }
        if (view.state === 'fleet') {
          if (c.hasClass('lone-worker')) {
            $('#editVehReg').hide()
            $('#editVehIcon').hide()
          }
        }
        if ($opener.hasClass('active')) {
          optionsDrop.hide()
          $opener.removeClass('active')
        } else {
          optionsDrop.show()
          $('.options', $HTML).not($opener).removeClass('active')
          $opener.addClass('active')
          optionsDrop.css({ top: offset.top + $opener.outerHeight() - 6, left: offset.left - optionsDrop.width() + $opener.outerWidth() })
        }
        driverWorkingHoursTodayPopupView.hideActivePopup()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
      })
      optionsDrop.find('[data-action=todaysJourneysReport]').off('click').on('click', function () {
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        // reportHandler.addReport(new Report($(".todays-journeys")),todaysJourneysReportName);
        if (reportHandler.hasActiveReport()) {
          reportHandler.hideReport(reportHandler.getActiveReport())
        }
        reportHandler.initReport('todaysJourneys')
      })
      optionsDrop.find('[data-action=addInformation]').off('click').click(function (e) {
        // var addInfo = $(".add-information-popup");
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }

        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy() // close currently visible instance
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

        handler.hideAddInformation()
        addInfo.find('.info-for').text(openerTitle).prop('title', openerTitle)
        handler.calcCoordinates(addInfo)
        addInfo.find('.button.save').addClass('btn-disabled')
        handler.showAddInformation()
      })
      optionsDrop.find('[data-action=editVehReg], [data-action=editVehIcon], [data-action=editVehDriver]').off('click').click(function (e) {
        // var addInfo = $(".add-information-popup");
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }

        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy() // close currently visible instance
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

        handler.hideAddInformation()
      })
      optionsDrop.find('[data-action=heartBeatOption]').click(function () {
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        if (addInfo && wls.addInformationActive()) {
          wls.hideAddInformation()
        }

        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy() // close currently visible instance
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

        var heartBeatView = heartBeatPopupView.findSubView('heartBeat')

        // heartBeatView.render("{}");
        // heartBeatView.updateHeartBeat(3, 30);
        heartBeatView.getHeartBeat(handler.openerVehId)
        heartBeatView.render('{}')
        heartBeatPopupView.show()
        heartBeatPopupView.alignBy(openerOffsetTop, 'topRight', { top: 0, left: 43 })
      })
      optionsDrop.find('[data-action=immobilise]').off('click').click(function (e) {
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }
        if (addInfo && wls.addInformationActive()) {
          wls.hideAddInformation()
        }
        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy() // close currently visible instance
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
        var groupId = $.cookie('lastViewedGroup')
        var data = { groupId: groupId }

        $.post('/api/selection-menu/action/immobilise/' + handler.openerVehId, data).done(function (msg) {
          console.log(msg)
          updateImmobState(handler.openerVehId, handler.carsArray)
          // handler.importCars();
          handler.updateCars()
        })
      })
      optionsDrop.find('[data-action=sendSmsMessage]').off('click').on('click', function () {
        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }

        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy() // close currently visible instance
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

        handler.hideAddInformation()
        var idsArray = []
        idsArray.push($(this).parent().attr('data-veh-id'))
        sendMessage.loadSendMessage(idsArray)
        // console.log($(".send-sms-message").find('#' + $(this).parent().attr("data-veh-id")).find('span.checkbox'));
      })
      $('#phoneNumber', $HTML).off('dblclick').dblclick(function () {
        var text = $(this).text()
        var el = $(this).parents('.vehicle-frame')
        var id = $(this).closest('.row').children('input[type=hidden]').val()

        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        if (addInfo && wls.addInformationActive()) {
          wls.hideAddInformation()
        }
        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }

        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy() // close currently visible instance
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

        phoneNumberPopup = new PhoneNumberPopupView(el, text, id)
      })
      $('#sosNumber', $HTML).off('dblclick').dblclick(function () {
        var text = $(this).text()
        var el = $(this).parents('.vehicle-frame')
        var id = $(this).closest('.row').children('input[type=hidden]').val()

        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }
        if (addInfo && wls.addInformationActive()) {
          wls.hideAddInformation()
        }
        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }

        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy() // close currently visible instance
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

        phoneNumberPopup = new EmergencyPhoneNumberPopupView(el, text, id)
      })
      if (addInfo) {
        addInfo.find('#addInfo').off('click').keyup(function (e) {
          informationText = e.target.value
          addInfo.find('.button.save').addClass('btn-disabled')
          if (informationText !== oldText) {
            addInfo.find('.button.save').removeClass('btn-disabled')
          }
        })
        addInfo.find('.button.cancel, .close-btn').off('click').click(function () {
          handler.hideAddInformation()
        })
        addInfo.find('.button.save').off('click').click(function (e) {
          addInformation(handler.openerVehId, informationText)
          handler.hideAddInformation()
        })
      }
      if (!activityLogEventBound) {
        optionsDrop.find('[data-action=activityLog]').click(function () {
          liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
          if (phoneNumberPopup) {
            phoneNumberPopup.destroy()
          }
          if (addInfo && wls.addInformationActive()) {
            wls.hideAddInformation()
          }
          if (heartBeatPopupView) {
            heartBeatPopupView.hide()
          }

          if (!activityLogPopupView) {
            window.mapCanvas.cleanUp()
            activityLogPopupView = new ActivityLogPopupView(function () {
              wls.loadState()
            })
          } else {
            if (activityLogPopupView.isVisible()) {
              activityLogPopupView.destroy() // close currently visible instance
            }

            window.mapCanvas.cleanUp()
            activityLogPopupView = new ActivityLogPopupView(function () {
              wls.loadState()
            }) // replace with a new one
          }
        })
        optionsDrop.find('[data-action=loneWorkerActivityLog]').click(function () {
          if (phoneNumberPopup) {
            phoneNumberPopup.destroy()
          }
          if (addInfo && wls.addInformationActive()) {
            wls.hideAddInformation()
          }
          if (heartBeatPopupView) {
            heartBeatPopupView.hide()
          }
          if (!activityLogPopupView) {
            window.mapCanvas.cleanUp()
            activityLogPopupView = new LoneWorkerActivityLogPopup(function () {
              wls.loadState()
            })
          } else {
            if (activityLogPopupView.isVisible()) {
              activityLogPopupView.destroy() // close currently visible instance
            }
            liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

            window.mapCanvas.cleanUp()
            activityLogPopupView = new LoneWorkerActivityLogPopup(function () {
              wls.loadState()
            }) // replace with a new one
          }
        })
        activityLogEventBound = true
      }

      $(document).click(function (e) {
        var target = $(e.target)
        if (!target.closest('.options').size()) {
          optionsDrop.hide()
          $('.options', $HTML).removeClass('active')
        }
      })
    }

    this.getVehicle = function (id) {
      var len = cars.length
      for (var i = 0; i < len; i++) {
        if (cars[i].data.id == id) {
          return cars[i]
        }
      }
      return null
    }

    this.getVehicles = function () {
      var arr = []
      var len = cars.length
      for (var i = 0; i < len; i++) {
        if (!cars[i].ignore) {
          arr.push(cars[i])
        }
      }
      return arr
    }
    this.getSelectedVehicles = function () {
      return lastSelected
    }
    this.startTimer = function () {
      timer = setInterval(handler.updateCars, timerValue * 1000)
      handler.startCountdown(timerValue)
    }

    this.resetTimer = function () {
      clearTimeout(timer)
      timer = null
      clearInterval(countdownInterval)
      countdownInterval = null
    }

    this.isTimerPresent = function () {
      return timer
    }

    this.startCountdown = function (timerValue) {
      $('h2').css('left', '11px')
      $('h2').text(timerValue)
      $('.circle_animation').css('stroke-dashoffset', 0)
      var time = timerValue
      var initialOffset = '0'
      var i = timerValue
      countdownInterval = setInterval(function () {
        i--
        $('.circle_animation').css('stroke-dashoffset',
          initialOffset + ((time - i) * (95 / time)))
        if (i < 10) {
          $('h2').css('left', '15px')
        } else {
          $('h2').css('left', '11px')
        }
        $('h2').text(i)
        if (i <= 0) {
          clearInterval(countdownInterval)
        }
      }, 1000)
    }

    this.updateCars = function (callback, preventTimerRestart) {
      var currCar = null

      function showDigitalInfo (info) {
        if (info == null) {
          return "";
        }
        var hexColor = resolveColor(info)

        return '<dl style="background:' + hexColor + ';">' +
          '<dt></dt>' +
          '<dd><span>' + (info.substring(info.indexOf('_') + 1)) + '</span></dd>' +
          '</dl>'
      }

      handler.resetTimer()
      if (!wls.isActivityLogsOnMap && !pastDate) {
        request = $.post('/getMovableItemsRefreshed.do', {
          date: updateTimestamp
        }).done(function (msg) {
          console.log('updateCars()')
          var json = msg
          var newCars = null
          var date = new Date()
          updateTimestamp = new Date()
          updateTimestamp = new Date(updateTimestamp.valueOf()).getTime() - 300000

          if ('data' in json && json.data.length) {
            newCars = json.data
          } else {
            request = false
            if (!preventTimerRestart) {
              handler.startTimer()
            }
            rezoomMapFlx()
            return
          }

          $.cookie('lastRefreshed', Date.now())

          var len = newCars.length
          var coordinatesChange
          var newData
          var oldLat
          var oldLon
          var liveVideoClass = 'no-video'
          var digitalInputInfo = ''

          console.log('updateCars() length: ' + len)

          for (var i = 0; i < len; i++) {
            currCar = idList[newCars[i].id]
            oldLat = currCar.data.lat
            oldLon = currCar.data.lon
            currCar.cached = false
            if (currCar.isNotUsingStickyRoads()) {
              oldLat = currCar.data.lat
              oldLon = currCar.data.lon
              newData = newCars[i]
              currCar.data = newData
              coordinatesChange = oldLat != newData.lat || oldLon != newData.lon
            } else {
              oldLat = currCar.data.roadLat
              oldLon = currCar.data.roadLon
              newData = newCars[i]
              currCar.data = newData
              coordinatesChange = oldLat != newData.roadLat || oldLon != newData.roadLon
            }
            if (currCar.data.inStealthMode) {
              currCar.HTML.addClass('stealth').removeClass('active')
              currCar.remove()
              currCar.getIgnitionIconImg()
            } else {
              currCar.HTML.removeClass('stealth')
            }

            if (newData.cameraStatus === 'on') {
              liveVideoClass = 'available'
            } else if (newData.cameraStatus === 'bad_connection') {
              liveVideoClass = 'bad-connection'
            }

            digitalInputInfo = newData.digitalInput1Info || newData.digitalInput2Info || newData.digitalInput3Info || newData.digitalInput4Info

            currCar.refreshModel()
            currCar.HTML.find('.data span:last').text((newData.typeDescr === 'Vehicle' ? newData.factoredSpeed + ' - (' + newData.directionOfTravel.toUpperCase() + ')' : newData.description)).end()
              .find('.data img').attr('src', (newData.typeDescr === 'LoneWorker'
                ? '../img/loneWorker/LW_icons_' + (newData.state !== 'none' ? newData.state : 'active')
                : '../img/vehicle_status_' + ((newData.factoredSpeed) > 0 ? 'moving' : (newData.ignitionActive) ? 'idle' : 'parked')) + '.png').end()
              .find('.state').attr('class', 'state ' + (newData.typeDescr === 'LoneWorker'
                ? (newData.state.toLowerCase() !== 'none' ? newData.state.toLowerCase() : 'active')
                : newData.state.toLowerCase())).end()
              .find('.state span').text(newData.stateLabel).end()
              .find('.data-left-col img:first').attr('src', (newData.typeDescr === 'LoneWorker' ? (newData.imageFileName == null ? '../img/loneWorker/LW_icons_user_placeholder_map.png' : newData.imageFileName)
                : '../img/vehicleIcons/' + newData.imageFileName + '_E_100.png')).end()
              .find('.data-left-col img:eq(1)').attr('src', (newData.typeDescr === 'LoneWorker'
                ? '../img/loneWorker/LW_icons_' + (newData.state !== 'none' ? newData.state : 'active')
                : '../img/arrows/arrows_orange_' + newData.directionOfTravel) + '.png').end()
              .find('.data-left-col img:eq(2)').attr('src', '../img/vehicle_status_' + ((newData.factoredSpeed) > 0 ? 'moving' : (newData.ignitionActive) ? 'idle' : 'parked') + '.png').end()
              .find('.data-left-col span').text((newData.typeDescr === 'LoneWorker' ? '' : newData.factoredSpeed + ' - (' + newData.directionOfTravel.toUpperCase() + ')')).end()
              .find('.data-right-col p:first').text($.datepicker.formatDate('dd/mm/yy ', new Date(newData.renewalDate)) + ' ' + formatDateToHM(newData.renewalDate)).end()
              .find('.data-right-col p:eq(1)').text(newData.curAddress).end()
              .find('.data-right-col p:eq(2)').text(newData.aoiPoiDescr).end()
              .find('.data-right-col span.driver-name').text((newData.typeDescr === 'LoneWorker' || !newData.infoLine1) ? '_________________' : newData.infoLine1).end()
              .find('.data-right-col span.todays-cost').text((newData.typeDescr === 'LoneWorker' || !newData.infoLine2) ? '_________________' : newData.infoLine2).end()
              .find('.data-right-col p.added-information').text(newData.clientInfo).end()
              .find('.live-video').attr('class', 'live-video ' + liveVideoClass).end()
              .find('.digital-input').html(showDigitalInfo(digitalInputInfo)).end()

            if (newData.typeDescr === 'LoneWorker') {
              currCar.HTML.find('.battery-lvl img').attr({ src: '../img/loneWorker/LW_icons_battery_' + (newData.batteryLevel > 50 ? '100' : newData.batteryLevel > 25 ? '50' : '25') + '.png', title: (newData.batteryLevel !== null ? newData.batteryLevel : '0') })
            }

            if (currCar.HTML.find('.immobilized').length) {
              currCar.HTML.find('.immobilized').css('visibility', newData.immobilizeStatus ? 'visible' : 'hidden')
            } else if (newData.immobilizable) {
              currCar.HTML.find('.data-holder').html(currCar.HTML.find('.data-holder').html() + '<div class="immobilized" style="visibility: ' + (newData.immobilizeStatus ? 'visible' : 'hidden;"') + '><div class="bg"><img class="warning" src="/img/locked_sign.png"/></div></div>')
            }

            if (newData.immobilizeStatus) {
              currCar.HTML.addClass('isImmobilized')
            } else {
              currCar.HTML.removeClass('isImmobilized')
            }

            if (currCar.HTML.hasClass('active') && !leftPanel.find('.actions-holder').is(':Visible')) {
              if (mapSource && mapSource != 'GOOGLEMAPS') {
                currCar.remove()
                currCar.getIgnitionIconImg()
                currCar.generateHTMLMarker()
                currCar.add()
              } else {
                /** google-maps travel animation **/
                currCar.remove(null, true)
                currCar.getIgnitionIconImg()
                currCar.generateHTMLMarker()
                // await mapCanvas.animateIconMovement();
                currCar.add()
                /** google-maps travel animation **/
              }
            }
          }
          setTimeout(rezoomMapFlx, 1000)
          request = false
          console.log('updateCars() finished')
          if (callback) callback()
          if (!preventTimerRestart) {
            handler.startTimer()
          }
        }).error(function (msg, errorTxt) {
          // alert("error");
          request = false
          if (!preventTimerRestart) {
            handler.startTimer()
          }
        })
      }
    }

    this.syncHTML = function () {
      var len = cars.length
      for (var i = 0; i < len; i++) {
        cars[i].HTML = $HTML.eq(i)
      }
    }

    this.removeHTML = function () {
      $HTML.remove()
      $HTML = ''
    }

    this.resetState = function () {
      handler.resetTimer()
      for (var key in lastZoomed) {
        lastZoomed[key].remove()
        return
      }
      if (lastSelectedNotEmpty()) {
        for (var key in lastSelected) {
          lastSelected[key].remove()
        }
      }
    }

    this.loadState = function (preventTimerRestart) {
      for (var key in lastZoomed) {
        lastZoomed[key].add()
        return
      }

      if (lastSelectedNotEmpty()) {
        for (var key in lastSelected) {
          lastSelected[key].add()
        }
      }

      if (localStorage.getItem('filter') != '') {
        panel.exec(localStorage.getItem('filter'))
      }

      selectedGroup = $.cookie('lastViewedGroup')
      handler.updateCars(null, preventTimerRestart)
    }
  }
  function car (obj, stopZooming) { // car Class
    var cr = this
    var $carHTML = this.HTML = ''
    var model = this.data = obj
    var cancelZoom = stopZooming
    var layout = this.layout = ''
    var addMethod = null
    var ignitionIconsAllowed = userPrefs.ignitionIconsVersion > 0
    var distanceMetric = userPrefs.distanceMetric
    var dotOnMap = model.dotOnMap
    var isZoomed = false
    var noStickyRoads = false
    model.dis = 0
    this.immobilisationShape = null
    this.ignore = false
    this.shape = null
    this.cached = false
    this.activityLog = null
    this.hasActivityLog = false
    this.activityLogHTML = null
    this.mapView = {
      imgUrl: null,
      ignitionIconUrl: null,
      textColor: 'white',
      fleetId: '',
      marker: '',
      vehImg: '',
      anchorX: 0,
      anchorY: 0,
      markerIsOnMap: false
    }

    this.getIgnitionIconImg = function () {
      var data = this.data // this == car instance
      if (data.typeDescr == 'Vehicle') {
        if (userPrefs.ignitionIconsVersion == 0) {
          this.mapView.ignitionIconUrl = ''
        } else {
          var iconsVersion = userPrefs.ignitionIconsVersion - 1
          if ($.cookie('lastViewedAccount') == undefined) {
            $.cookie('lastViewedAccount', settings.data.lastAccountId)
          }
          if ($.cookie('lastViewedAccount') == 699 || $.cookie('lastViewedAccount') == 673 ||
            $.cookie('lastViewedAccount') == 856 ||
            $.cookie('lastViewedAccount') == 861) {
            iconsVersion = 2
          }
          if ($.cookie('lastViewedAccount') == 820) {
            iconsVersion = 0
          }
          var versionLabel = ['', 'version2/', 'version3/'][iconsVersion]
          var directionOfTravel = data.directionOfTravel.toLowerCase()
          var stateLabels = {
            On: ['_direction_' + directionOfTravel, '_direction_' + directionOfTravel, 'green'],
            Moving: ['_direction_' + directionOfTravel, '_direction_' + directionOfTravel, 'green'],
            Off: ['_off', '_off_direction_' + directionOfTravel, 'red'],
            Parked: ['_off', '_off_direction_' + directionOfTravel, 'red'],
            Idling: ['_idling', '_idling_direction_' + directionOfTravel, 'yellow']
          }
          var stateText = (data.state === 'Unplugged' ? stateLabels.Off[iconsVersion] : stateLabels[data.state][iconsVersion])
          var prefix = versionLabel + ((iconsVersion == 2) ? stateText + '-glow_' + directionOfTravel.toUpperCase() + '_' + userPrefs.vehicleIconSize : 'ignition_status' + stateText)
          var postfix = '.png'
          this.mapView.ignitionIconUrl = '../img/arrows/' + prefix + postfix
        }
      } else if (data.typeDescr == 'LoneWorker') {
        this.mapView.ignitionIconUrl = '../img/loneWorker/LW_icons_' + data.state.toLowerCase() + '.png'
      }
    }

    function getVehicleMapDescrFlx (vehicle, canUserRead, canResellerHover, metrics) {
      var text
      if (canResellerHover) {
        var date = new Date(vehicle.renewalDate)
        if (metrics == 'KM') {
          metrics = 'KM/H'
        } else {
          metrics = 'MPH'
        }
        if (vehicle.typeDescr == 'Vehicle') {
          text = vehicle.description + '<br>' +
            (vehicle.coupled ? 'Coupled with: ' + vehicle.coupledVehicleRegNumber + '<br>' : '') +
            'Ignition ' + vehicle.state + '<br>' +
            vehicle.factoredSpeed + metrics + ' ' + '(' + vehicle.directionOfTravel.toString().toUpperCase() + ')' + '<br>' +
            date.toString() + '<br>' +
            ((vehicle.curAddress != '' && vehicle.curAddress != null) ? (vehicle.curAddress + '<br>') : '') +
            ((vehicle.aoiPoiDescr != '' && vehicle.aoiPoiDescr != null) ? vehicle.aoiPoiDescr + '<br>' : '') +
            (vehicle.infoLine1 != undefined ? ('Driver: ' + vehicle.infoLine1 + '<br>') : '') +
            (vehicle.lastJourneyMpg != undefined ? ('Last Journey MPG: ' + vehicle.lastJourneyMpg.toFixed(2) + '<br>') : '') +
            ((canUserRead == 'true' && vehicle.costPerMile != 0)
              ? ("Today's Cost: \u00A3" + vehicle.infoLine2) : '')
        } else if (vehicle.typeDescr == 'LoneWorker') {
          text = vehicle.description.toUpperCase() + '<br>' +
            vehicle.state.toUpperCase() + ' STATE<br>' +
            new Date(vehicle.renewalDate) + '<br>' +
            (vehicle.curAddress == null ? '' : vehicle.curAddress.toUpperCase()) + '<br>' +
            'PHONE NUMBER: ' + vehicle.infoLine1
        }
      } else {
        text = vehicle.registrationNumber
      }
      return text
    }

    if (!isNaN(parseFloat(model.distanceToRoad)) && !(userPrefs.disableOnIgnOff && model.state != null && model.state == 'Off') && userPrefs.stickyRoadsAllowed) {
      if (model.distanceToRoad > 20.0 && (!userPrefs.hideRedPin || userPrefs.drawArrowFromVehIconToRoad)) {
        if (mapSource && mapSource.startsWith('MSVE')) {
          addMethod = function (silent) {
            const displayStyle = userPrefs.ignitionIconsVersion != 3 ? 'table-row' : 'inline-block'
            cr.shape = window.mapCanvas.addTextMarker(cr.mapView.vehImg,
              getVehicleMapDescrFlx(model, true, true, distanceMetric),
              model.lon,
              model.lat,
              model.roadLon,
              model.roadLat,
              model.distanceToRoad,
              ($.cookie('lastViewedAccount') == 699 || $.cookie('lastViewedAccount') == 673 ||
                $.cookie('lastViewedAccount') == 856 ||
                $.cookie('lastViewedAccount') == 861) ? 3 : (($.cookie('lastViewedAccount') == 820) ? 1 : userPrefs.ignitionIconsVersion),
              cr.mapView.marker,
              true,
              model.registrationNumber,
              dotOnMap,
              userPrefs.showVehTooltipAllowed,
              userPrefs.drawArrowFromVehIconToRoad,
              false,
              model.typeDescr != 'LoneWorker',
              cr.mapView.anchorX,
              cr.mapView.anchorY
            )
            cr.mapView.markerIsOnMap = true
            cr.HTML.addClass('active')
            if (!wls.hasZoomedVehicle() && !stopZooming) {
              setTimeout(rezoomMapFlx, 1000)
            }
            if (!silent) {
              $(document).trigger('vehicleListChange')
            }
            if (wlsMapTools.showVehRegIsChecked()) {
              cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').css('display', displayStyle).end().prop('outerHTML')
              $('.uk-license-plate.marker-label').addClass("visible")
            } else {
              cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').hide().end().prop('outerHTML')
              $('.uk-license-plate.marker-label').removeClass("visible")
            }
          }
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          addMethod = async function (silent) {
            console.log('addMethod() async 10752: ' + cr.model)
            const displayStyle = userPrefs.ignitionIconsVersion != 3 ? 'table-row' : 'inline-block'
            cr.shape = await window.mapCanvas.addTextMarker(cr.mapView.vehImg,
              getVehicleMapDescrFlx(model, true, true, distanceMetric),
              model.lon,
              model.lat,
              model.roadLon,
              model.roadLat,
              model.distanceToRoad,
              userPrefs.ignitionIconsVersion,
              cr.mapView.marker,
              true,
              model.registrationNumber,
              dotOnMap,
              userPrefs.showVehTooltipAllowed,
              userPrefs.drawArrowFromVehIconToRoad,
              false,
              model.typeDescr != 'LoneWorker',
              cr.mapView.anchorX,
              cr.mapView.anchorY,
              model,
              cr.mapView.ignitionIconUrl,
              userPrefs.vehicleIconSize,
              function (result) {
              },
                cr.mapView.fleetId
            )
            cr.mapView.markerIsOnMap = true
            cr.HTML.addClass('active')
            if (!wls.hasZoomedVehicle()) {
              setTimeout(rezoomMapFlx, 1000)
            }
            if (!silent) {
              $(document).trigger('vehicleListChange')
            }
            if (wlsMapTools.showVehRegIsChecked()) {
              cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').css('display', displayStyle).end().prop('outerHTML')
              $('.uk-license-plate.marker-label').addClass("visible")
            } else {
              cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').hide().end().prop('outerHTML')
              $('.uk-license-plate.marker-label').removeClass("visible")
            }
          }
        }
      } else {
        if (mapSource && mapSource.startsWith('MSVE')) {
          addMethod = function (silent) {
            const displayStyle = userPrefs.ignitionIconsVersion != 3 ? 'table-row' : 'inline-block'
            cr.shape = window.mapCanvas.addTextMarker(cr.mapView.vehImg,
              getVehicleMapDescrFlx(model, true, true, distanceMetric),
              model.roadLon,
              model.roadLat,
              null,
              null,
              null,
              ($.cookie('lastViewedAccount') == 699 || $.cookie('lastViewedAccount') == 673 ||
                $.cookie('lastViewedAccount') == 856 ||
                $.cookie('lastViewedAccount') == 861) ? 3 : (($.cookie('lastViewedAccount') == 820 || $.cookie('lastViewedAccount') == 524) ? 1 : userPrefs.ignitionIconsVersion),
              cr.mapView.marker,
              true,
              model.registrationNumber,
              dotOnMap,
              userPrefs.showVehTooltipAllowed,
              false,
              false,
              model.typeDescr != 'LoneWorker',
              cr.mapView.anchorX,
              cr.mapView.anchorY)
            cr.mapView.markerIsOnMap = true
            cr.HTML.addClass('active')
            if (!wls.hasZoomedVehicle()) {
              setTimeout(rezoomMapFlx, 1000)
            }
            if (!silent) {
              $(document).trigger('vehicleListChange')
            }
            if (wlsMapTools.showVehRegIsChecked()) {
              cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').css('display', displayStyle).end().prop('outerHTML')
              $('.uk-license-plate.marker-label').addClass("visible")
            } else {
              cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').hide().end().prop('outerHTML')
              $('.uk-license-plate.marker-label').removeClass("visible")
            }
          }
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          addMethod = async function (silent) {
            console.log('addMethod() async 10836: ' + cr.model)
            const displayStyle = userPrefs.ignitionIconsVersion != 3 ? 'table-row' : 'inline-block'
            cr.shape = await window.mapCanvas.addTextMarker(cr.mapView.vehImg,
              getVehicleMapDescrFlx(model, true, true, distanceMetric),
              model.roadLon,
              model.roadLat,
              null,
              null,
              null,
              userPrefs.ignitionIconsVersion,
              cr.mapView.marker,
              true,
              model.registrationNumber,
              dotOnMap,
              userPrefs.showVehTooltipAllowed,
              false,
              false,
              model.typeDescr != 'LoneWorker',
              cr.mapView.anchorX,
              cr.mapView.anchorY,
              model,
              cr.mapView.ignitionIconUrl,
              userPrefs.vehicleIconSize,
              function (result) {
              },
                cr.mapView.fleetId)
            cr.mapView.markerIsOnMap = true
            cr.HTML.addClass('active')
            if (!wls.hasZoomedVehicle()  && !stopZooming) {
              setTimeout(rezoomMapFlx, 1000)
            }
            if (!silent) {
              $(document).trigger('vehicleListChange')
            }
            if (wlsMapTools.showVehRegIsChecked()) {
              cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').css('display', displayStyle).end().prop('outerHTML')
              $('.uk-license-plate.marker-label').addClass("visible")
            } else {
              cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').hide().end().prop('outerHTML')
              $('.uk-license-plate.marker-label').removeClass("visible")
            }
          }
        }
      }
    } else {
      if (mapSource && mapSource.startsWith('MSVE')) {
        addMethod = function (silent) {
          const displayStyle = userPrefs.ignitionIconsVersion != 3 ? 'table-row' : 'inline-block'
          cr.shape = cr.shape = window.mapCanvas.addTextMarker(cr.mapView.vehImg, getVehicleMapDescrFlx(model, true, true, distanceMetric), model.lon, model.lat, null, null, null,
            ($.cookie('lastViewedAccount') == 699 ||
              $.cookie('lastViewedAccount') == 856 ||
              $.cookie('lastViewedAccount') == 861) ? 3 : (($.cookie('lastViewedAccount') == 820 || $.cookie('lastViewedAccount') == 524) ? 1 : userPrefs.ignitionIconsVersion),
            cr.mapView.marker, true, model.registrationNumber, dotOnMap, userPrefs.showVehTooltipAllowed, false, false, model.typeDescr != 'LoneWorker',
            cr.mapView.anchorX,
            cr.mapView.anchorY)
          cr.mapView.markerIsOnMap = true
          cr.HTML.addClass('active')
          noStickyRoads = true
          if (!wls.hasZoomedVehicle()) {
            setTimeout(rezoomMapFlx, 1000)
          }
          if (!silent) {
            $(document).trigger('vehicleListChange')
          }
          if (wlsMapTools.showVehRegIsChecked()) {
            cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').css('display', displayStyle).end().prop('outerHTML')
            $('.uk-license-plate.marker-label').addClass("visible")
          } else {
            cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').hide().end().prop('outerHTML')
            $('.uk-license-plate.marker-label').removeClass("visible")
          }
        }
      } else if (mapSource && mapSource === 'GOOGLEMAPS') {
        /** HERE */
        addMethod = async function (silent) {
          console.log('addMethod() async 10910')
          console.log(model)
          const displayStyle = userPrefs.ignitionIconsVersion != 3 ? 'table-row' : 'inline-block'
          cr.shape = await window.mapCanvas.addTextMarker(
            cr.mapView.vehImg,
            getVehicleMapDescrFlx(model, true, true, distanceMetric),
            model.lon,
            model.lat,
            null,
            null,
            null,
            userPrefs.ignitionIconsVersion,
            cr.mapView.marker,
            true,
            model.registrationNumber,
            dotOnMap,
            userPrefs.showVehTooltipAllowed,
            false,
            false,
            model.typeDescr != 'LoneWorker',
            cr.mapView.anchorX,
            cr.mapView.anchorY,
            model,
            cr.mapView.ignitionIconUrl,
            userPrefs.vehicleIconSize,
            function (result) {
              cr.vehRegShape = result
              if (wlsMapTools.showVehRegIsChecked()) {
                setTimeout(function() {
                  $('.uk-license-plate.marker-label').addClass("visible")
                }, 1000)
              }
            },
              cr.mapView.fleetId)
          cr.mapView.markerIsOnMap = true
          cr.HTML.addClass('active')
          noStickyRoads = true
          if (!wls.hasZoomedVehicle()) {
            setTimeout(rezoomMapFlx, 1000)
          }
          if (!silent) {
            $(document).trigger('vehicleListChange')
          }
          if (wlsMapTools.showVehRegIsChecked()) {
            cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').css('display', displayStyle).end().prop('outerHTML')
            $('.uk-license-plate.marker-label').addClass("visible")
          } else {
            cr.shape._htmlContent = $(cr.shape._htmlContent).find('.veh-reg').hide().end().prop('outerHTML')
            $('.uk-license-plate.marker-label').removeClass("visible")
          }
        }
      }
    }

    function getIconImg () {
      var data = this.data // this == car instance

      if (data.typeDescr == 'Vehicle') {
        var imageFileName = data.imageFileName
        var colors = { on: 'green.png', off: 'red.png', idling: 'orange.png' }
        var folder = ''

        if (data.dotOnMap) {
          imageFileName = colors[data.state.toLowerCase()]
        } else if (userPrefs.ignitionIconsVersion >= 0) {
          imageFileName = imageFileName + '_E_' + userPrefs.vehicleIconSize + '.png'
        } else {
          imageFileName = imageFileName + '_' + (!userPrefs.vehicleIconRotation ? 'E' : data.directionOfTravel.toUpperCase()) + '_' + userPrefs.vehicleIconSize + '.png'
        }

        if (data.dotOnMap) {
          folder = 'dots/'
        } else if (userPrefs.iconGlow) {
          folder = 'glow/' + userPrefs.glowColor + '/'
        }

        this.mapView.imgUrl = '../img/vehicleIcons/' + folder + imageFileName
      } else if (data.typeDescr == 'LoneWorker') {
        if (data.imageFileName == null) {
          this.mapView.imgUrl = '../img/loneWorker/LW_icons_user_placeholder_map.png'
        } else {
          this.mapView.imgUrl = data.imageFileName
        }
      }
    }

    // care!
    // if (userPrefs.ignitionIconsVersion == 0) {
    //     this.mapView.ignitionIconUrl = "";
    // } else {
    //     var iconsVersion = userPrefs.ignitionIconsVersion - 1;
    //     if ($.cookie("lastViewedAccount") == undefined) {
    //         $.cookie("lastViewedAccount", settings.data.lastAccountId);
    //     }
    //     if ($.cookie("lastViewedAccount") == 699 || $.cookie("lastViewedAccount") == 673) {
    //         iconsVersion = 2;
    //     }
    //     if ($.cookie("lastViewedAccount") == 820) {
    //         iconsVersion = 0;
    //     }
    //     var versionLabel = ["", "version2/", "version3/"][iconsVersion];
    //     var directionOfTravel = data.directionOfTravel.toLowerCase();
    //     var stateLabels = {
    //         On: ["_direction_" + directionOfTravel, "_direction_" + directionOfTravel, "green"],
    //         Moving: ["_direction_" + directionOfTravel, "_direction_" + directionOfTravel, "green"],
    //         Off: ["_off", "_off_direction_" + directionOfTravel, "red"],
    //         Parked: ["_off", "_off_direction_" + directionOfTravel, "red"],
    //         Idling: ["_idling", "_idling_direction_" + directionOfTravel, "yellow"]
    //     };
    //     var stateText = (data.ignitionStatus === "Unplugged" ? stateLabels["Off"][iconsVersion] : stateLabels[data.ignitionStatus][iconsVersion]);
    //     var prefix = versionLabel + ((iconsVersion == 2) ? stateText + "-glow_" + directionOfTravel.toUpperCase() + "_" + userPrefs.vehicleIconSize : "ignition_status" + stateText);
    //     var postfix = ".png";
    //     this.mapView.ignitionIconUrl = "../img/arrows/" + prefix + postfix;
    // }

    function generateLicencePlateRow (data) {
      var vehReg = data.registrationNumber.toUpperCase() // WG61 MTE
      if (vehReg.length == 7 && vehReg.indexOf(' ') == -1) {
        vehReg = vehReg.substring(0, 4) + ' ' + vehReg.substring(4, 7)
      }

      return "<tr class='veh-reg' style='display:" + (wlsMapTools.showVehRegIsChecked() ? 'table-row' : 'none') + ";'><td></td>" +
        "<td align='center'>" +
        "<span style='font-family: \"UKNumberPlate\"; font-weight: bold;" +
        ' padding: 2px 5px; position: relative; top: -2px; background:#ffd307;' +
        " text-align: center; vertical-align: middle; font-size: 12px; white-space: nowrap;'>" +
        vehReg +
        '</span></td>' +
        '<td></td></tr>'
    }

    function generateLicencePlateDiv (data) {
      var vehReg = data.registrationNumber.toUpperCase()
      if (vehReg.length == 7 && vehReg.indexOf(' ') == -1) {
        vehReg = vehReg.substring(0, 4) + ' ' + vehReg.substring(4, 7)
      }
      return "<div class='veh-reg' style='font-family: \"UKNumberPlate\"; font-weight: bold; color: #000000; font-size: 12px; display:" + (wlsMapTools.showVehRegIsChecked() ? 'inline-block' : 'none') + '; ' +
        'padding: 2px 5px; position: relative;' +
        'top: -10px; ' +
        "background: #ffd307; text-align: center; vertical-align: middle; white-space: nowrap;'>" +
        vehReg +
        '</div>'
    }

    this.generateHTMLMarker = function () {
      var data = this.data // this == car instance
      var mapView = this.mapView
      var image = mapView.vehImg
      var topMargin = 0
      var color = {
        white: { rgba: '0, 0, 0, 0.4', hex: '#40000000' },
        black: { rgba: '255, 255, 255, 0.4', hex: '#40fffffff' }
      }[mapView.textColor]
      var fontSize = (userPrefs.vehicleIconSize == '50' || userPrefs.vehicleIconSize == '25') ? 6 : 12
      var ignitionImg = ''
      if (data.typeDescr == 'Vehicle') {
        var directionTr = ''
        var directionOfTravel = data.directionOfTravel
        var tr = ['', '']
        var td = []
        var ignitionIconsVersion = userPrefs.ignitionIconsVersion
        if ($.cookie('lastViewedAccount') == 524 && window.location.origin == 'https://secure.vinetelematics.com') {
          // if ($.cookie("lastViewedAccount") == 567 && window.location.origin == "http://test.html5.e-v-t.co.uk") {
          ignitionIconsVersion = 4
        }
        if ($.cookie('lastViewedAccount') == 699 || $.cookie('lastViewedAccount') == 673 ||
          $.cookie('lastViewedAccount') == 856 ||
          $.cookie('lastViewedAccount') == 861) {
          ignitionIconsVersion = 3
        }
        if ($.cookie('lastViewedAccount') == 820) {
          ignitionIconsVersion = 1
        }
        var ignitionImgTd = {
          nw: "<td><img style='position:relative;bottom:-12px;right:-10px;' src='" + mapView.ignitionIconUrl + "'></td><td></td><td></td>",
          n: "<td></td><td align='center'><img style='position:relative;bottom:-6px;' src='" + mapView.ignitionIconUrl + "'></td><td></td>",
          ne: "<td></td><td><td/><td><img style='position:relative;bottom:-12px;left:-10px;' src='" + mapView.ignitionIconUrl + "'></td>",
          sw: "<td><img style='position:relative;top:-14px;right:-14px;' src='" + mapView.ignitionIconUrl + "'></td><td></td><td></td>",
          s: "<td></td><td align='center'><img style='position:relative;top:-8px;' src='" + mapView.ignitionIconUrl + "'></td><td></td>",
          se: "<td></td><td><td/><td><img style='position:relative;top:-14px;left:-14px;' src='" + mapView.ignitionIconUrl + "'></td>"
        }
        if ((data.state == 'On' || ignitionIconsVersion == 2) && directionOfTravel == 'e') {
          ignitionImg = "<div><img style='position:relative;left:-6px;' src='" + mapView.ignitionIconUrl + "'></div>"
        } else if ((data.state == 'Idling' || data.state == 'Off' || data.state == 'Parked') && ignitionIconsVersion != 2) {
          ignitionImg = "<div><img height='16' style='position:relative;left:-5px;' src='" + mapView.ignitionIconUrl + "'></div>"
        }
        if (data.directionOfTravel == 'n') {
          topMargin = -22
        } else if (data.directionOfTravel == 'nw' || data.directionOfTravel == 'ne') {
          topMargin = -18
        }
        if (data.state == 'On' || ignitionIconsVersion == 2) {
          directionTr = '<tr>' + ignitionImgTd[directionOfTravel] + '</tr>'
        }
        if (ignitionIconsVersion == 3) {
          if (userPrefs.vehicleIconSize == '100') {
            mapView.marker = "<span style='font-family:Arial; font-size:x-small; color:white; background-color:transparent'>" +
              '<div style="width:100px; height:100px; overflow:hidden; position:relative; left:' + window.mapCanvas.calculateXOffSet(45 + additionalPixelsForHtmlWidth) + 'px;   top:' + window.mapCanvas.calculateYOffSet(45 + additionalPixelsForHtmlHeight) + "px;     background-repeat: no-repeat; background-image: url('" + mapView.ignitionIconUrl + "');\" >" +
              "<img style='position:relative;  left: 50%;  top: 50%; margin: -" + mapView.vehImg.naturalHeight / 2 + 'px 0 0 -' + mapView.vehImg.naturalWidth / 2 + "px' src='" + mapView.imgUrl + "'>" +
              '</div>' +
              (userPrefs.showVehReg ? generateLicencePlateDiv(data) : '') +
              '</span>'
          } else if (userPrefs.vehicleIconSize == '50') {
            mapView.marker = "<span style='font-family:Arial; font-size:x-small; color:white; background-color:transparent'>" +
              '<div style="width:50px; height:50px; overflow:hidden; position:relative; left:' + window.mapCanvas.calculateXOffSet(45 + additionalPixelsForHtmlWidth) + 'px;   top:' + window.mapCanvas.calculateYOffSet(45 + additionalPixelsForHtmlHeight) + "px;     background-repeat: no-repeat; background-image: url('" + mapView.ignitionIconUrl + "');\" >" +
              "<img style='position:relative;  left: 50%;  top: 45%; margin: -" + mapView.vehImg.naturalHeight / 2 + 'px 0 0 -' + mapView.vehImg.naturalWidth / 2 + "px' src='" + mapView.imgUrl + "'>" +
              '</div>' +
              (userPrefs.showVehReg ? generateLicencePlateDiv(data) : '') +
              '</span>'
          } else if (userPrefs.vehicleIconSize == '25') {
            mapView.marker = "<span style='font-family:Arial; font-size:x-small; color:white; background-color:transparent'>" +
              '<div style="width:45px; height:45px; overflow:hidden; position:relative; left:' + window.mapCanvas.calculateXOffSet(45 + additionalPixelsForHtmlWidth) + 'px;   top:' + window.mapCanvas.calculateYOffSet(45 + additionalPixelsForHtmlHeight) + "px;     background-repeat: no-repeat; background-image: url('" + mapView.ignitionIconUrl + "');\" >" +
              "<img style='position:relative;  left: 50%;  top: 40%; margin: -" + mapView.vehImg.naturalHeight / 2 + 'px 0 0 -' + mapView.vehImg.naturalWidth / 2 + "px' src='" + mapView.imgUrl + "'>" +
              '</div>' +
              (userPrefs.showVehReg ? generateLicencePlateDiv(data) : '') +
              '</span>'
          }
          //                " + ( (userPrefs.vehicleIconSize == "100") ?  'position:relative;  left: 20px;  top: 37px;' : 'position:relative;  left: 11px;  top: 17px; ' ) + "
        } else if (ignitionIconsVersion == 4) {
          mapView.marker = "<span style='font-family:Arial; font-size:x-small;color:white; background-color:transparent'>" +
            "<table width='" + (image.width + additionalPixelsForHtmlWidth) + "' height='" + (image.height + additionalPixelsForHtmlHeight) + "'" +
            "  style='position:relative;' >" +
            (data.directionOfTravel == 'nw' || data.directionOfTravel == 'n' || data.directionOfTravel == 'ne' ? directionTr : '') +
            '<tr>' +
            '<td></td>' +
            "<td style='background-position:center;background-repeat:no-repeat;' background='" + image.src + "' align='center' width='" + (image.width + 10) + "' height='" + image.height + "'>" +
            "<b style='font-size:" + fontSize + 'px;color:' + mapView.textColor + '; filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=' + color.hex + ',endColorstr=' + color.hex + ');zoom: 1;background:rgba(' + color.rgba + ")'>" + mapView.fleetId + '</b>' +
            '</td>' +
            '<td></td>' +
            '</tr>' +
            (userPrefs.showVehReg ? generateLicencePlateRow(data) : '') +
            '' +
            '</table>' +
            '</span>'

          if (data.directionOfTravel == 'nw' || data.directionOfTravel == 'n' || data.directionOfTravel == 'ne') {
            mapView.anchorX = image.width / 2 + additionalPixelsForHtmlWidth / 2
            mapView.anchorY = image.height / 2 + 9
          } else if (data.directionOfTravel == 'sw' || data.directionOfTravel == 's' || data.directionOfTravel == 'se') {
            mapView.anchorX = image.width / 2 + additionalPixelsForHtmlWidth / 2
            mapView.anchorY = image.height / 2 + 9 * 2
          } else if (data.directionOfTravel == 'e') {
            mapView.anchorY = image.height / 2 + 9
            mapView.anchorX = image.width / 2
          } else {
            mapView.anchorY = image.height / 2 + 9
            mapView.anchorX = image.width / 2 + additionalPixelsForHtmlWidth / 2 + 4
          }
        } else {
          mapView.marker = "<span style='font-family:Arial; font-size:x-small;color:white; background-color:transparent'>" +
            "<table width='" + (image.width + additionalPixelsForHtmlWidth) + "' height='" + (image.height + additionalPixelsForHtmlHeight) + "'" +
            "  style='position:relative;' >" +
            (data.directionOfTravel == 'nw' || data.directionOfTravel == 'n' || data.directionOfTravel == 'ne' ? directionTr : '') +
            '<tr>' +
            '<td>' + ((data.state == 'On' || ignitionIconsVersion == 2) && directionOfTravel == 'w' ? "<div><img style='position:relative;right:-8px;' src='" + mapView.ignitionIconUrl + "'></div>" : '') + '</td>' +
            "<td style='background-position:center;background-repeat:no-repeat;' background='" + image.src + "' align='center' width='" + (image.width + 10) + "' height='" + image.height + "'>" +
            "<b style='font-size:" + fontSize + 'px;color:' + mapView.textColor + '; filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=' + color.hex + ',endColorstr=' + color.hex + ');zoom: 1;background:rgba(' + color.rgba + ")'>" + mapView.fleetId + '</b>' +
            '</td>' +
            '<td>' + ignitionImg + '</td>' +
            '</tr>' +
            (userPrefs.showVehReg ? generateLicencePlateRow(data) : '') +
            (data.directionOfTravel == 'sw' || data.directionOfTravel == 's' || data.directionOfTravel == 'se' ? directionTr : '') +
            '</table>' +
            '</span>'

          if (data.directionOfTravel == 'nw' || data.directionOfTravel == 'n' || data.directionOfTravel == 'ne') {
            mapView.anchorX = image.width / 2 + additionalPixelsForHtmlWidth / 2
            mapView.anchorY = image.height / 2 + 9
          } else if (data.directionOfTravel == 'sw' || data.directionOfTravel == 's' || data.directionOfTravel == 'se') {
            mapView.anchorX = image.width / 2 + additionalPixelsForHtmlWidth / 2
            mapView.anchorY = image.height / 2 + 9 * 2
          } else if (data.directionOfTravel == 'e') {
            mapView.anchorY = image.height / 2 + 9
            mapView.anchorX = image.width / 2
          } else {
            mapView.anchorY = image.height / 2 + 9
            mapView.anchorX = image.width / 2 + additionalPixelsForHtmlWidth / 2 + 4
          }
        }
      } else if (data.typeDescr == 'LoneWorker') {
        ignitionImg = "<div><img style='position:relative;left:-18px;top:15px;' src='" + mapView.ignitionIconUrl + "'></div>"
        mapView.marker = "<span style='font-family:Arial; font-size:x-small;color:white; background-color:transparent'>" +
          "<table width='" + (image.width + additionalPixelsForHtmlWidth) + "' height='" + (image.height + additionalPixelsForHtmlHeight) + "'" +
          "  style='position:relative;left:" + window.mapCanvas.calculateXOffSet(image.width + additionalPixelsForHtmlWidth) + 'px;top:' + topMargin + 'px;margin-top:' + window.mapCanvas.calculateYOffSet(image.height + additionalPixelsForHtmlHeight) + "' >" +
          //                        (data.directionOfTravel == "nw" || data.directionOfTravel == "n" || data.directionOfTravel == "ne" ? directionTr : "" ) +
          '<tr>' +
          '<td>' + ((data.state == 'On' || ignitionIconsVersion == 2) && directionOfTravel == 'w' ? "<div><img style='position:relative;right:-8px;' src='" + mapView.ignitionIconUrl + "'></div>" : '') + '</td>' +
          "<td style='background-position:center;background-repeat:no-repeat;' background='" + image.src + "' align='center' width='" + (image.width + 10) + "' height='" + image.height + "'>" +
          "<b style='font-size:" + fontSize + 'px;color:' + mapView.textColor + '; filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=' + color.hex + ',endColorstr=' + color.hex + ');zoom: 1;background:rgba(' + color.rgba + ")'>" + mapView.fleetId + '</b>' +
          '</td>' +
          '<td>' + ignitionImg + '</td>' +
          '</tr>' +
          //                        (data.directionOfTravel == "sw" || data.directionOfTravel == "s" || data.directionOfTravel == "se" ? directionTr : "" ) +
          '</table>' +
          '</span>'
      }
    } // care!
    function generateHTMLMarkerWithTooltipNoIgn () {
      // var data = this.data; //this == car instance
      // var image = this.mapView.vehImg;
      // "<span style='font-family:Arial; font-size:x-small; position:relative; top:-41px;" +
      //   "color:white; background-color:transparent'>" +
      //   "<table width='" + (image.width + 94) + "' height='" + (image.height + 41) + "'" +
      //   " >" +
      //   "<tr><td></td><td height='41' width='94' style='background-image: url(" + wls.getTooltipUrl() + ");background-repeat:no-repeat;padding-top:5px;' align='center' valign='top'>" + data.regNumber + "</td></tr>" +
      //   "<tr><td style='background-position:center; background-repeat:no-repeat;' background='" + image.src + "' align='center' vertical width='" + (image.width + 3) + "' height='" + image.height + "'>" +
      //   "</td><td>" +
      //   "</td></tr>" +
      //   "</table></span>";
    }

    function generateHTMLMarkerWithTooltipIgn () {
      // var data = this.data; //this == car instance
      // var mapView = this.mapView;
      // var image = mapView.vehImg;
      // "<span style='font-family:Arial; font-size:x-small; position:relative; top:-41px;" +
      //   "color:white; background-color:transparent'>" +
      //   "<table width='" + (image.width + 87 + additionalPixelsForHtmlWidth) + "' height='" + (image.height + 41 + additionalPixelsForHtmlHeight) + "'" +
      //   " >" +
      //   "<tr><td height='41' width='87' style='background-image: url(" + wls.getTooltipUrl() + ");background-repeat:no-repeat;padding-top:5px;' align='center' valign='top'>" + data.regNumber + "</td></tr>" +
      //   "<tr><td>" + mapView.marker +
      //   "</td><td>" +
      //   "</td></tr>" +
      //   "</table></span>";
    }

    function rezoom () {
      window.mapCanvas.zoomToMaxExtentToPoint(model.lon, model.lat)
    }

    this.isNotUsingStickyRoads = function () {
      return noStickyRoads
    }
    this.setZoomStatus = function (booleanVal, changeStatusOnly) {
      isZoomed = booleanVal
      if (changeStatusOnly) return
      if (isZoomed) {
        setTimeout(rezoom, 1000)
        cr.HTML.find('.zoom').addClass('active')
      } else {
        cr.HTML.find('.zoom').removeClass('active')
      }
    }
    this.getZoomStatus = function () {
      return isZoomed
    }
    this.refreshModel = function () {
      model = cr.data
    }
    this.cache = function () {
      cr.mapView.vehImg = new Image()
      getIconImg.apply(cr)
      if (cr.data.typeDescr == 'Vehicle' && cr.mapView.imgUrl.substr(cr.mapView.imgUrl.lastIndexOf(':')).match('white') != null) {
        cr.mapView.textColor = 'black'
      }
      if (cr.data.fleetId) {
          cr.mapView.fleetId = cr.data.fleetId
      }
      $(cr.mapView.vehImg).load(function () {
        $(this).off('load')
        if (!dotOnMap) {
          cr.getIgnitionIconImg()
          if (mapSource && mapSource != 'GOOGLEMAPS') {
            cr.generateHTMLMarker()
          }
        }
        if (userPrefs.showVehTooltipAllowed && (!ignitionIconsAllowed || dotOnMap)) {
          generateHTMLMarkerWithTooltipNoIgn.apply(cr)
        } else if (userPrefs.showVehTooltipAllowed && ignitionIconsAllowed && !dotOnMap) {
          generateHTMLMarkerWithTooltipIgn.apply(cr)
        }
      })
      cr.mapView.vehImg.src = cr.mapView.imgUrl
      cr.cached = true
    }

    this.getImgURL = function () {
      return 'img/vehicleIcons/' + model.imageFileName + '_E_100.png'
    }

    this.add = addMethod
    this.drawLine = function (pin) {
      if (!pin) return
      cr.HTML.addClass('active')
      window.mapCanvas.drawLineImgMarker(cr.mapView.vehImg, model.registrationNumber, pin.lon, pin.lat, model.lon, model.lat,
        function (result) {
          cr.shape = result
          cr.mapView.markerIsOnMap = true
          setTimeout(rezoomMapFlx, 1500)
        })
    }
    this.addPin = function () {
      var img = new Image()
      if (cr.shape) return
      img.src = 'img/markers/marker_red.png'
      window.mapCanvas.addTextMarkerSimple(img, 'Chosen Location', model.lon, model.lat, true,
        function (result) {
          cr.shape = result
          cr.mapView.markerIsOnMap = true
          setTimeout(rezoomMapFlx, 1500)
        })
    }
    this.showDistance = function (pin) {
      var d = distanceBetweenCoordinates(pin.lat, pin.lon, model.lat, model.lon) * 0.001
      model.dis = d
      if (d % 1 > 0) {
        d = String(d).match(/[0-9]+\.[0-9]{1,2}/)[0]
      }
      cr.HTML.find('.distance').show()
      cr.HTML.find('.distance span').text(d + ' ' + userPrefs.distanceMetric)
    }
    this.remove = function (noRemoveClass, stopZoom) {
      if (!cr.shape) return
      window.mapCanvas.removeMarker(cr.shape)
      window.mapCanvas.removeMarker(cr.vehRegShape)
      if (cr.immobilisationShape) {
        window.mapCanvas.removeMarker(cr.immobilisationShape)
      }
      cr.shape = null
      cr.immobilisationShape = null
      cr.mapView.markerIsOnMap = false
      if (!noRemoveClass) {
        cr.HTML.removeClass('active')
      }
      cr.setZoomStatus(false)
      if (!wls.hasZoomedVehicle() && !stopZoom) {
        setTimeout(rezoomMapFlx, 1000)
      }
    }

    this.marker = null
    this.updateLayout = function (addVehicleLayout) {
      function showDigitalInfo (info) {
        if (info == null) {
          return "";
        }
        var hexColor = resolveColor(info)

        return '<dl style="background:' + hexColor + ';">' +
          '<dt></dt>' +
          '<dd><span>' + (info.substring(info.indexOf('_') + 1)) + '</span></dd>' +
          '</dl>'
      };
      // to call back: addVehicleLayout(layout);
      if (model.typeDescr === 'Vehicle') {
        var liveVideoClass = 'no-video'
        var liveVideoEl

        if (model.cameraStatus === 'on') {
          liveVideoClass = 'available'
        } else if (model.cameraStatus === 'bad_connection') {
          liveVideoClass = 'bad-connection'
        }

        liveVideoEl = model.cameraPresent && window.settings.data.liveVideoAllowedForUser ? '<span class="live-video ' + liveVideoClass + '"></span>' : ''

        layout = (
          model.digitalInput1Info || model.digitalInput2Info || model.digitalInput3Info || model.digitalInput4Info
            ? '<div class="row has-din isotope-elem' + (model.immobilizable ? ' immob' : '') + (model.immobilizeStatus ? ' isImmobilized' : '') + '" data-id="' + model.id + '">' : '<div class="row isotope-elem' + (model.immobilizable ? ' immob' : '') + (model.immobilizeStatus ? ' isImmobilized' : '') + '" data-id="' + model.id + '">') +
          '<div class="lock">' +
          '<div class="bg"></div>' +
          '<img src="/img/locked_block_icon.png" alt=""/>' +
          '</div>' +
          '<input type="hidden" name="id" value="' + model.id + '">' +
          '<div class="vehicle-frame">' +
          '<div class="vehicle-header">' +
          '<div class="data">' +
          '' + ((model.description.length > 9) ? '<span title="' + model.description + '">' + model.description + '</span>' : '<span>' + model.description + '</span>') + '' +
          (model.typeDescr === 'LoneWorker'
            // '<span'+( (model.registrationNumber.length>9)? ' title="'+model.registrationNumber+'"':'')+'>'+model.registrationNumber.length>9+'</span>'+
            ? '<img src="../img/loneWorker/LW_icons_' + (model.state !== 'none' ? model.state : 'active') + '.png" alt="" />'
            : '<img src="../img/vehicle_status_' + ((model.factoredSpeed) > 0 ? 'moving' : (model.ignitionActive) ? 'idle' : 'parked') + '.png" alt="" />' +
            '<span>' + model.factoredSpeed + ' - (' + model.directionOfTravel.toUpperCase() + ')</span>') +
          '</div>' +
          '<div class="btns-holder">' +
          '' + (model.tcoAllowed ? '<div class="tachograph-wrap"><span class="tachograph-ico" data-button="tachograph"></span></div>' : '') +
          '' + (liveVideoEl) +
          '<div class="options">' +
          '<span class="ico"></span>' +
          '<span class="arrow"></span>' +
          '</div>' +
          '<div class="zoom"><i></i></div>' +
          '</div>' +
          '<div class="distance">' +
          '<i></i>' +
          '<span></span>' +
          '</div>' +
          '</div>' +
          '<div class="info">' +
          '<div class="state ' + (model.state.toLowerCase() !== 'none' && model.ignitionStatusVisibility ? model.state.toLowerCase() : 'active') + '">' +
          '<span>' + (model.state.toLowerCase() !== 'none' && model.ignitionStatusVisibility ? model.stateLabel : '') + '</span>' +
          '</div>' +
          '<div class="data-holder">' +
          ((model.immobilizable && model.immobilizeStatus) ? '<div class="immobilized"><div class="bg"><img class="warning" src="/img/locked_sign.png"/></div></div>' : '') +
          '<div class="line">' +
          '<div class="' + 'data-left-col' + '">' +
          '<div class="row">' +
          '<img src="../img/vehicleIcons/' + model.imageFileName + '_E_100.png" alt="" />' +
          '</div>' +
          '<div class="row">' +
          '<img src="../img/arrows/arrows_orange_' + model.directionOfTravel + '.png" alt="" />' +
          '<i></i>' +
          '<img src="../img/vehicle_status_' + ((model.factoredSpeed) > 0 ? 'moving' : (model.ignitionActive) ? 'idle' : 'parked') + '.png" alt="" />' +
          '</div>' +
          '<span>' + model.factoredSpeed + ' - (' + model.directionOfTravel.toUpperCase() + ')</span>' +
          '</div>' +
          '</div>' +
          '<div class="data-right-col">' +
          '' + ((model.renewalDate) ? '<p>' + ($.datepicker.formatDate('dd/mm/yy ', new Date(model.renewalDate)) + ' ' + formatDateToHM(model.renewalDate)) + '</p>' : '') + '' +
          '' + ((model.curAddress) ? '<p>' + model.curAddress + '</p>' : '') + '' +
          '' + ((model.aoiPoiDescr) ? '<p>' + model.aoiPoiDescr + '</p>' : '') + '' +
          '<dl>' +
          '' + ((model.infoLine1) ? '<dt>Driver:</dt><dd><span class="driver-name">' + model.infoLine1 + '</span></dd>' : '') + '' +
          '' + ((model.infoLine2) ? '<dt>Todays cost:</dt><dd><span class="todays-cost">' + model.infoLine2 + '</span></dd>' : '') + '' +
          '</dl>' +

          '' + ((model.clientInfo) ? '<p class="added-information">' + model.clientInfo + '</p>' : '<p class="added-information"></p>') + '' +
          '</div>' +
          '</div>' +

          '</div>' +
          '' + (
          model.digitalInput1Info || model.digitalInput2Info || model.digitalInput3Info || model.digitalInput4Info
            ? '<div class="digital-input">' +
              (model.digitalInput1Info ? showDigitalInfo(model.digitalInput1Info) : '') + '' +
              (model.digitalInput2Info ? showDigitalInfo(model.digitalInput2Info) : '') + '' +
              (model.digitalInput3Info ? showDigitalInfo(model.digitalInput3Info) : '') + '' +
              (model.digitalInput4Info ? showDigitalInfo(model.digitalInput4Info) : '') + '' +
              '</div>'
            : ''
        ) + '' +
          '</div>' +
          '</div>'
        return layout
      } else if (model.typeDescr === 'LoneWorker') {
        var defaultProfileName = '../img/loneWorker/LW_icons_user_placeholder_map.png'

        layout = '<div class="' + 'row lone-worker' + (model.inStealthMode ? ' stealth' : '') + '" data-id="' + model.id + '">' +
          '<div class="lock">' +
          '<div class="bg"></div>' +
          '<img src="/img/locked_block_icon.png" alt=""/>' +
          '</div>' +
          '<input type="hidden" name="id" value="' + model.id + '">' +
          '<div class="vehicle-frame">' +
          '<div class="vehicle-header">' +
          '<div class="data">' +
          '' + ((model.description.length > 9) ? '<span title="' + model.description + '">' + model.description + '</span>' : '<span>' + model.description + '</span>') +
          '<img src="../img/loneWorker/LW_icons_' + (model.state !== 'none' ? model.state : 'active') + '.png" alt="" />' +
          '</div>' +
          '<div class="btns-holder">' +
          '<div class="battery-lvl">' + '<img src="../img/loneWorker/LW_icons_battery_' +
          (model.batteryLevel > 50 ? '100' : model.batteryLevel > 25 ? '50' : '25') + '.png" title="' + (model.batteryLevel !== null ? model.batteryLevel : '0') + '" />' + '</div>' +
          '' + (model.tcoAllowed ? '<div class="tachograph-wrap"><span class="tachograph-ico" data-button="tachograph"></span></div>' : '') +
          '<div class="options">' +
          '<span class="ico"></span>' +
          '<span class="arrow"></span>' +
          '</div>' +
          '<div class="zoom"><i></i></div>' +
          '</div>' +
          '<div class="distance">' +
          '<i></i>' +
          '<span></span>' +
          '</div>' +
          '</div>' +
          '<div class="info">' +
          '<div class="state ' + (model.state.toLowerCase() !== 'none' ? model.state.toLowerCase() : 'active') + '">' +
          '<span>' + model.stateLabel + '</span>' +
          '</div>' +
          '<div class="data-holder">' +
          '<div class="line">' +
          '<div class="' + 'lone-worker-data data-left-col' + '">' +
          '<div class="row">' +
          '<img src="' + (model.imageFileName ? model.imageFileName : defaultProfileName) + '" alt="" />' +
          '</div>' +
          '<div class="row">' +
          '<img src="../img/loneWorker/LW_icons_' + (model.state !== 'none' ? model.state : 'active') + '.png" alt="" />' +
          '</div>' + '<span></span>' +
          '</div>' +
          '</div>' +
          '<div class="data-right-col">' +
          '' + ((model.renewalDate) ? '<p>' + ($.datepicker.formatDate('dd/mm/yy ', new Date(model.renewalDate)) + ' ' + formatDateToHM(model.renewalDate)) + '</p>' : '') + '' +
          '' + ((model.curAddress) ? '<p>' + model.curAddress + '</p>' : '') + '' +
          '<dl>' +
          '<dt>Phone number:</dt><dd><span id="phoneNumber" title="Double click to edit phone number">' + ((model.infoLine1) ? '' + model.infoLine1 + '' : '_________________') + '</span></dd>' +
          '<dt>SOS:</dt><dd><span id="sosNumber" title="Double click to edit phone number">' + ((model.infoLine2) ? '' + model.infoLine2 + '' : '_________________') + '</span></dd>' +
          '</dl>' +

          '' + ((model.clientInfo) ? '<p class="added-information">' + model.clientInfo + '</p>' : '<p class="added-information"></p>') + '' +
          '</div>' +
          '</div>' +

          '</div>' +
          '' + (
          model.digitalInput1Info || model.digitalInput2Info || model.digitalInput3Info || model.digitalInput4Info
            ? '<div class="digital-input">' +
              (model.digitalInput1Info ? showDigitalInfo(model.digitalInput1Info) : '') + '' +
              (model.digitalInput2Info ? showDigitalInfo(model.digitalInput2Info) : '') + '' +
              (model.digitalInput3Info ? showDigitalInfo(model.digitalInput3Info) : '') + '' +
              (model.digitalInput4Info ? showDigitalInfo(model.digitalInput4Info) : '') + '' +
              '</div>'
            : ''
        ) + '' +
          '</div>' +
          '</div>'
        return layout
      }
    }
  }
  function OOI (json, type) { // POI class
    var point = this
    var data = this.data = json
    var addMethod = null
    var redMarker = ''
    point.data.typeDescr = type
    function getIconImg () {
      var imgUrl
      var image = new Image(20, 25)
      if (settings.data.multiColorPinsPoi) {
        imgUrl = '/img/markers/marker_' + data.pinColour.toLowerCase() + '.png'
      } else {
        imgUrl = '/img/poiTypes/locationIcon.gif'
      }
      image.src = imgUrl
      return image
    }

    if (type == 'aoi') {
      addMethod = function () {
        if (point.data.polygonWkt) {
          if (mapSource && mapSource.startsWith('MSVE')) {
            point.shape = window.mapCanvas.addTextMarkerByObj(point.iconImg, point.data, settings.data.poiAreaColoured, settings.data.choosePoiAreaColour, true, false, false, settings.data.hoverOverAoiAllowed)
          } else if (mapSource && mapSource === 'GOOGLEMAPS') {
            window.mapCanvas.addTextMarkerByObj(point.iconImg, point.data, settings.data.poiAreaColoured, settings.data.choosePoiAreaColour, true, false, false, settings.data.hoverOverAoiAllowed,
              function (result) {
                point.shape = result
              })
          }
        }
      }
    } else {
      addMethod = function () {
        if (mapSource && mapSource.startsWith('MSVE')) {
          point.shape = window.mapCanvas.addTextMarkerByObj(point.iconImg, point.data, false, false, false, settings.data.poiAreaColoured, settings.data.choosePoiAreaColour, false)
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          window.mapCanvas.addTextMarkerByObj(point.iconImg, point.data, false, false, false, settings.data.poiAreaColoured, settings.data.choosePoiAreaColour, false, function (result) {
            point.shape = result
          })
        }
      }
      redMarker = new Image()
      redMarker.src = 'img/markers/marker_red.png'
      this.addPin = function () {
        if (point.shape) return
        window.mapCanvas.addTextMarkerSimple(redMarker, 'Chosen Location', data.lon, data.lat, true, function (result) {
          point.shape = result
          setTimeout(rezoomMapFlx, 1500)
        })
      }
    }
    this.add = addMethod
    this.remove = function () {
      if (point.shape) {
        window.mapCanvas.removeMarker(point.shape)
        window.mapCanvas.cleanUp('poiPin')
        point.shape = null
      }
    }
    this.iconImg = getIconImg()
  }
  function OOIHandler (requestURL, type) { // OOIHandler class
    var handler = this
    var OOIs = this.OOIs = []
    var idList = {}
    var xhr = null
    this.requestOOIs = function (callback) {
      if (xhr) xhr.abort()
      xhr = $.post(requestURL, { active: true }).done(function (json) {
        handler.importOOIs(json)
        xhr = false
        if (callback) callback()
      }).error(function (msg, errorTxt) {
        xhr = false
        // alert("error");
      })
    }
    this.importOOIs = function (OOICollection) {
      var len = OOICollection.data.length
      for (var i = 0; i < len; i++) {
        OOIs[i] = new OOI(OOICollection.data[i], type)
        idList[OOIs[i].data.id] = OOIs[i]
      }
    }
    this.getIdList = function () {
      return idList
    }
    this.addOOIs = function () {
      var accountId = parseInt($.cookie('lastViewedAccount'), 10)
      if (!accountId) {
        accountId = settings.data.lastAccountId
      }

      if (!OOIs.length) {
        handler.requestOOIs(function () {
          handler.addOOIs()
        })
        return
      }

      var len = OOIs.length
      for (var i = 0; i < len; i++) {
        if (OOIs[i].data.accountDto.id === accountId || isNaN(accountId)) {
          OOIs[i].add()
        }
      }
    }
    this.setOOI = function (ooi) {
      OOIs = ooi
    }
    this.removeOOIs = function () {
      var len = OOIs.length
      if (xhr) xhr.abort()
      for (var i = 0; i < len; i++) {
        OOIs[i].remove()
      }

    }
  }
  function TFL (json, type) { // TFL class
    var point = this
    var data = this.data = json
    if (type == 'camera') {
      data.src = '/img/markers/camera.png'
      if (settings.data.liveTrafficCameraVideoAllowed) {
        data.html = '<table width="297" height="279" cellpadding="0" cellspacing="0" style="padding:10px;background-color:#ffffff;border:solid;#bfbfbf 1px;min-height:200px;width:270px;">' +
          '<tr>' +
          '<td>' +
          '<img src="' + data.src + '"/></td></tr><tr><td><video id=' + data.id + ' autoplay control>' +
          '<source src="' + data.videoLink + '" type="video/mp4">' +
          '</video>' +
          '</td>' +
          '</tr>' +
          '</table>'
      } else {
        data.html = '<table width="297" height="279" cellpadding="0" cellspacing="0" style="padding:10px;background-color:#ffffff;border:solid;#bfbfbf 1px;min-height:200px;width:270px;">' +
          '<tr>' +
          '<td>' +
          '<img src="' + data.src + '"/></td></tr><tr><td><img src="' + data.imageLink + '"/>' +
          '</td>' +
          '</tr>' +
          '</table>'
      }
    } else {
      data.src = '/img/markers/' + (data.category == 'Works' ? 'road_works.png' : 'warning_triangle.png')
      data.html = '<table style="font-size:11px; background-color:#ffffff;border: solid #bfbfbf 1px; min-height:200px;width:250px;">' +
        '<tr>' +
        '<td style="padding:10px 0px 0px 10px;" width="25">' +
        '<img src="' + data.src + '"/>' +
        '</td>' +
        '<td style="padding:10px 10px 0px 0px;" align="center">' + data.title + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2">' +
        '<hr align="center" size="1" color="#0074be"/>' +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2">LOCATION: ' + data.location + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2">' +
        '<hr align="center" size="1" color="#0074be"/>' +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2">SEVERITY: ' + data.severity + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2">' +
        '<hr align="center" size="1" color="#0074be"/>' +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2" style="padding:4px">' + data.eventStartDateTime + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2">DESCRIPTION: ' + (!data.descr ? ' - ' : data.descr) + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2"><hr align="center" size="1" color="#0074be"/></td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0 10px;" colspan="2" style="padding:4px">' + (!data.remarkDateTime ? ' - ' : data.remarkDateTime) + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding:0px 10px 10px 10px;" colspan="2">REMARK: ' + (!data.remark ? ' - ' : data.remark) + '</td>' +
        '</tr>' +
        '</table>'
    }
    this.add = function () {
      data.shape = window.mapCanvas.showIncidentShape(data)
    }
    this.remove = function () {
      window.mapCanvas.removeMarker(data.shape)
    }
  }
  function TFLHandler (requestURL, type) { // TFLHandler class
    var handler = this
    var TFLs = []
    var xhr = null
    this.requestTFLs = function (callback) {
      if (xhr) xhr.abort()
      xhr = $.post(requestURL).done(function (json) {
        handler.importTFLs(json)
        xhr = false
        if (callback) callback()
      }).error(function (msg, errorTxt) {
        xhr = false
        // alert("error");
      })
    }
    this.importTFLs = function (TFLCollection) {
      var len = TFLCollection.data.length
      for (var i = 0; i < len; i++) {
        TFLs[i] = new TFL(TFLCollection.data[i], type)
      }
    }
    this.addTFLs = function () {
      if (!TFLs.length) {
        handler.requestTFLs(function () {
          handler.addTFLs()
        })
        return
      }
      var len = TFLs.length
      for (var i = 0; i < len; i++) {
        TFLs[i].add()
      }
    }
    this.removeTFLs = function () {
      var len = TFLs.length
      if (xhr) xhr.abort()
      for (var i = 0; i < len; i++) {
        TFLs[i].remove()
      }
    }
  }
  function SystemAlert () {
    var handle = this
    var $HTML = $('.message-alert-popup')
    var $message = $('.message-alert')
    var timeOut = null
    var alertClass = null

    function init () {
      $HTML.find('.header-panel .close-btn').off('click').click(function () {
        clearTimeout(timeOut)
        handle.hideAlert()
      })
    }

    this.isOpen = function () {
      return $HTML.is(':visible')
    }

    this.initMsg = function (msg) {
      clearTimeout(timeOut)
      handle.clearMessage(alertClass)

      handle.addMessage(msg, 'success')
      handle.showAlert()
      alertClass = 'success'

      timeOut = setTimeout(function () {
        handle.hideAlert()
      }, 4000)
    }

    this.initDialog = function (msg, _callback) {
      handle.clearMessage(alertClass)
      handle.addMessage(msg, 'dialog')
      handle.showAlert()
      alertClass = 'dialog'

      $HTML.find('.action-row').show()
      $HTML.find('.action-row .alert-btn.save').off('click').bind('click', function () {
        handle.hideAlert()
        handle.clearMessage(alertClass)
        _callback(true)
      })
      $HTML.find('.action-row .alert-btn.cancel').off('click').bind('click', function () {
        handle.hideAlert()
        handle.clearMessage(alertClass)
        _callback(false)
      })
    }

    this.initError = function (msg, _error) {
      handle.clearMessage(alertClass)
      handle.addMessage(msg, 'error')
      handle.showAlert()
      alertClass = 'error'

      if (_error) _error($message)
    }

    this.showAlert = function () {
      $HTML.css('display', 'block').animate({ opacity: 1 }, 400)
    }

    this.hideAlert = function () {
      $HTML.find('.action-row').hide()
      $HTML.animate({ opacity: 0 }, 400).css('display', 'none')
    }

    this.addMessage = function (msg, str) {
      $message.addClass(str).find('.message-alert-text').html(msg)
    }

    this.clearMessage = function (str) {
      $message.removeClass(str).find('.message-alert-text').html()
    }
    init()
  }
  function mapTools () { // mapTools class
    var mt = this
    var trafficOn = false
    var pin = null
    var fn = []
    var $HTML = $('.map-tools')
    // var mapToolsFrame = $(".map-tools-frame");
    var $HTMLToolsFrame = $('.map-tools-frame')
    var $HTMLwidth = null
    var cache = []
    var postcode = null
    var $HTMLpd = null // past-date class
    var $HTMLipd = null // input past-date id
    var $HTMLpostcode = null
    var $HTMLpostcodeInput = null
    this.carsArray = []

    this.optionBtnIsActive = false
    this.multipleSelectToolIsActive = false

    this.tflActive = false

    this.tflDisruptionsActive = false
    this.tflLiveTrafficActive = false
    this.tflCamerasActive = false

    this.showAoisActive = false
    this.showPoisActive = false
    this.showVehRegActive = false
    this.showDrivingTimeActive = false
    this.showInputPastDateActive = false
    this.postcodeSearchActive = false
    this.isTraceButtonActive = false
    this.isClusteringButtonActive = false
    this.pauseMapActive = false
    this.googleDropdownActive = false



    var minimiseBtnIsActive = false
    var _html = null // for date validation message alert
    var isValid = false

    this.initCalendar = function () {
      mt.initInput()

      $HTMLipd.datetimepicker({
        showOn: 'button',
        maxDate: '0D',
        showTimepicker: false,
        buttonImage: '',
        showButtonPanel: false,
        showAnim: 'slideDown',
        separator: ' ',
        timeOnlyShowDate: true,
        pickerTimeFormat: 'HH:mm',
        beforeShow: function (input, inst) {
          $(this).closest('.date').find('.calendar-btn').addClass('active')
        },
        onClose: function (selectedDate) {
          var date = new Date()
          var time = date.getHours() + ':' + ((date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes())
          var inputDate = ''
          // $HTMLipd.datetimepicker("setDate", selectedDate);
          if (selectedDate.length === 10) {
            inputDate = selectedDate + ' ' + time
            $HTMLipd.val(inputDate)
          } else {
            $HTMLipd.val('')
          }

          insertPastDateTrigger()
          $(this).closest('.date').find('.calendar-btn').removeClass('active')
        },
        onSelect: function () {
          $(this).closest('.journey-report-wrap').find('.button-list .button.active').removeClass('active')
        }
      })

      insertPastDateTrigger()
    }

    this.initInput = function () { // init local time to input
      // var today = new Date();

      $HTMLipd.val('')
      $HTMLpd.find('.remove-btn').css('visibility', 'hidden')
    }

    function getFormattedDate (date) {
      var day = date.getDate()
      var month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var year = date.getFullYear()
      var time = date.getHours() + ':' + ((date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes())
      return day + '.' + month + '.' + year + ' ' + time
    }

    function insertPastDateTrigger () {
      $HTMLipd.closest('.date').find('.calendar-btn').append($HTMLipd.next('button'))
    }

    function getPastDateCars (timestamp) {
      var groupId = $.cookie('lastViewedGroup')
      var data = { date: timestamp, groupId: groupId }

      $.post('/api/vehicle/past-date', data).done(function (msg) {
        $HTMLpd.find('.remove-btn').css('visibility', 'visible')
        newVehicleList(msg.data, wls.carsArray)
        pastDate = true
      })
    }

    function newVehicleList (newArr, oldArr) {
      for (var i = 0; i < oldArr.length; i++) {
        for (var z = 0; z < newArr.length; z++) {
          if (oldArr[i].id === newArr[z].id) {
            oldArr.splice(i, 1, newArr[z])
          }
        }
      }

      if (newArr.length) {
        if (wls.hasShapes()) window.mapCanvas.cleanUp()
        wls.carsArray = oldArr
        wls.importCars()
        wls.createHtml()
      }

      wls.restoreSelectionMenuState()
      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.right-col .vehicle-holder .scroll-content .slidee').height()])
      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
    }

    this.requestPast = function () {
      var dateString = $HTMLipd.val().split(' ')
      var date = dateString[0].split('.')

      if (!window.isValidDate(dateString[0]) || !dateString[1] || !window.isValidTime(dateString[1])) {
        systemAlert.initError('Invalid date format.', function (html) {
          _html = html
        })
      } else {
        var time = dateString[1].split(':')
        var timeStamp = new Date(date[2], (date[1] - 1), date[0], time[0], time[1])

        if (systemAlert.isOpen()) {
          window._html.html()
          systemAlert.hideAlert()
        }

        getPastDateCars(timeStamp.valueOf())
        _html = null
      }
    }

    function showPreferences () {
      var preferencesView = prefererencesPopupView.findSubView('preferences')
      preferencesView.getPreferences()
      preferencesView.render('{}')
      prefererencesPopupView.show()
      prefererencesPopupView.alignBy($('.svg-menu-icon.svg-map-tools.icon-map-tools-map-preferences'), 'bottomRight', { top: 20, left: -43 })
    }

    function hidePreferences () {
      prefererencesPopupView.hideActivePopup()
    }

    function toggleTools () {
      if (minimiseBtnIsActive) {
        $HTMLwidth = mt.mapToolsWidth($HTML) + 1
        mt.hideToolsFrame()
        $HTMLToolsFrame.hide()
        $HTML.css('width', 'auto')
        $HTML.find('.slide-button').addClass('active')
      } else {
        $HTML.css('width', $HTMLwidth)
        $HTMLToolsFrame.show()
        mt.showToolsFrame()
        $HTML.find('.slide-button').removeClass('active')
        $HTMLwidth = $HTML.width()
      }

      if (view.getActiveState() !== 'dual') {
        mt.correctPosition()
      }
    }

    this.recalculateSize = function (width) {
      $HTML.css('width', '')
    }

    this.toggleMapTools = function () {
      // var $HTMLToolsFrame = $(".map-tools-frame");
      $HTMLToolsFrame.show()

      var elWidth = mt.mapToolsWidth($HTML)

      $HTML.css('width', elWidth + 1)

      if (minimiseBtnIsActive) {
        if (view.getActiveState() !== 'dual') {
          mt.showToolsFrame()
          // mt.hideToolsFrame.css({"visibility": "visible"});
          $HTML.find('.slide-button').removeClass('active')
        }

        minimiseBtnIsActive = !minimiseBtnIsActive
      }

      if (view.getActiveState() === 'dual') {
        mt.hideToolsFrame()
      }
      if (view.getActiveState() !== 'dual') {
        mt.correctPosition()
      }
    }

    this.mapToolsWidth = function (el) {
      var elWidth = el.find('.option-frame.first').outerWidth()
      // var elWidth = el.find(".option-frame.first").width();

      el.find('ul > li').each(function (idx, $el) {
        if ($($el).css('display') !== 'none') {
          elWidth += $($el).outerWidth()
        }
      })

      return elWidth
    }

    this.deactivateDrivingTime = function() {
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-driving-time').removeClass("active")
      mt.showDrivingTimeActive = false
    }

    this.deactivatePreferences = function() {
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-map-preferences').removeClass("active")
      mt.optionBtnIsActive = false
    }

    this.deactivateShowVehReg = function() {
      mt.showVehRegActive = false;
      hideVehReg()
      $(".svg-menu-icon.svg-map-tools.icon-map-tools-show-vehreg.rich-tooltip-container.bottom.active.svg-inactive").removeClass("active")
    }

    this.activateDropdowns = function() {
      mt.tflActive = false;
      $(".tfl-dropdown").hide()
      $(".tfl-dropdown").css("display", "block")
      $(".tfl-dropdown").css("visibility", "hidden")
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-tfl').removeClass("active")

      mt.showInputPastDateActive = false;
      $(".input-past-date-dropdown").hide()
      $(".input-past-date-dropdown").css("display", "block")
      $(".input-past-date-dropdown").css("visibility", "hidden")
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-calendar').removeClass("active")

      mt.postcodeSearchActive = false;
      $(".postcode-search-dropdown").hide()
      $(".postcode-search-dropdown").css("display", "block")
      $(".postcode-search-dropdown").css("visibility", "hidden")
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-postcode-search').removeClass("active")
    }

    this.init = function () {
      $HTML = $('.map-tools')
      $HTMLToolsFrame = $('.map-tools-frame')
      $('.map-tools *').off()
      $HTMLpd = $(".map-tools-dropdown.input-past-date-dropdown").find('.past-date')
      $HTMLpdCloseBtn = $(".map-tools-dropdown.input-past-date-dropdown").find('.close-btn')
      $HTMLpdCloseBtn.click(function(e) {
        $(".svg-menu-icon.svg-map-tools.icon-map-tools-calendar").removeClass("active")
        mt.showInputPastDateActive = false
        $(".input-past-date-dropdown").css("visibility", "hidden");
      })
      $HTMLipd = $HTMLpd.find('input#past-date')

      $HTML.find('.modern-map-tools').hide()


      $HTML.find('input:checkbox').change(function () {
        var checked = Number(this.checked)
        mt.exec(this.value, checked)
      })
      $HTMLpostcode = $('.map-tools-dropdown.postcode-search-dropdown')
      $HTMLpostcodeInput = $HTMLpostcode.find('input.postcode')
      $HTMLpostcodeCloseBtn = $(".map-tools-dropdown.postcode-search-dropdown").find('.close-btn')
      $HTMLpostcodeCloseBtn.click(function(e) {
        $(".svg-menu-icon.svg-map-tools.icon-map-tools-postcode-search").removeClass("active")
        mt.postcodeSearchActive = false
        $(".postcode-search-dropdown").css("visibility", "hidden");
      })

      $HTMLpostcode.find('.button.submit').click(function (e) {
        e.preventDefault()
        window.mapCanvas.geocode($HTMLpostcodeInput.val(), addPostcodePin)
      })
      $('.map-tools-dropdown.postcode-search-dropdown').find('.remove-btn').click(function (e) {
        e.preventDefault()
        removePostcodePin()
        $HTMLpostcodeInput.val('')
      })
      $HTML.fadeIn(800, function () {
        var doc = $(document)

        /*
        $HTML.draggable({
          containment: [0, 0, doc.width() - 20, doc.height() - 10],
          handle: '.ui-handle',
          create: function () {
            var elWidth = mt.mapToolsWidth($HTML)
            $HTML.css('width', elWidth + 1)
          },
          start: function (event, $HTML) {
            $HTML.helper.css('width', $HTML.helper.outerWidth() + 0.5)
          },
          stop: function (event, $HTML) {
            $HTML.helper.css('width', $HTML.helper.outerWidth() - 0.7)
            mt.correctPosition()
          }
        })
         */
      })

      $HTML.find('.option-btn').click(function (e) {
        e.preventDefault()
        mt.optionBtnIsActive = !mt.optionBtnIsActive
        if (!mt.optionBtnIsActive) {
          hidePreferences()
        } else {
          showPreferences()
        }
      })

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-trace').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        mt.isTraceButtonActive = !mt.isTraceButtonActive
        $(this).toggleClass('active')
        if (!mt.isTraceButtonActive) {
          console.log('Trace Inactive')
          mapCanvas.disableClusteringTraces()
        } else {
          console.log('Trace Active')
          mapCanvas.enableClusteringTraces()
        }
      })

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-cluster').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        mt.isClusteringButtonActive = !mt.isClusteringButtonActive
        $(this).toggleClass('active')
        if (!mt.isClusteringButtonActive) {
          console.log('Clustering Inactive')
          mapCanvas.disableClustering()
        } else {
          console.log('Clustering Active')
          mapCanvas.enableClustering()
        }
      })


      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-sms-ms').click(function (e) {
        e.preventDefault()
        mt.multipleSelectToolIsActive = !mt.multipleSelectToolIsActive
        if (!$(this).hasClass('active')) {
          $(this).addClass('active')
          $('body').css('cursor', 'crosshair')
        } else {
          $(this).removeClass('active')
          $('body').css('cursor', 'auto')
          if (mapSource && mapSource.startsWith('MSVE')) {
            window.mapCanvas.map.entities.pop()
          } else if (mapSource && mapSource === 'GOOGLEMAPS') {
            window.mapCanvas.cleanUp()
          }
          $('.select-to-sms-popup').css('visibility', 'hidden')
        }
      })

      $HTML.find('.slide-button').click(function (e) {
        // e.preventDefault();
        minimiseBtnIsActive = !minimiseBtnIsActive
        toggleTools()
      })

      $HTMLpd.find('.remove-btn').click(function () {
        mt.initInput()

        $(this).find('.remove-btn').css('visibility', 'hidden')
        pastDate = false
        if (settings.data.refreshSmSeconds) {
          wls.setTimerValue(settings.data.refreshSmSeconds)
        }
        newVehicleList(mt.carsArray, wls.carsArray)
      })

      $HTMLpd.find('.button.submit').click(function () {
        mt.requestPast()
      })

      // mt.correctDragCanvas();
      mt.initCalendar()

      // SVG MAP TOOLS

      window.mapCanvas.initMapToolsControls()

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-show-aoi').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(this).toggleClass("active")
        mt.showAoisActive = !mt.showAoisActive
        if (!mt.showAoisActive) {
          wlsAOIHandler.removeOOIs()
        } else {
          wlsAOIHandler.addOOIs()
        }
      })

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-show-poi').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(this).toggleClass("active")
        mt.showPoisActive = !mt.showPoisActive
        if (!mt.showPoisActive) {
          wlsPOIHandler.removeOOIs()
        } else {
          wlsPOIHandler.addOOIs()
        }
      })

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-show-vehreg').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(this).toggleClass("active")
        mt.showVehRegActive = !mt.showVehRegActive
        if (!mt.showVehRegActive) {
          hideVehReg()
        } else {
          showVehReg()
        }
      })

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-driving-time').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(this).toggleClass("active")
        mt.showDrivingTimeActive = !mt.showDrivingTimeActive
        const reportName = 'DrivingTime.do'
        if (!mt.showDrivingTimeActive) {
          if (reportHandler.hasReport(reportName)) {
            reportHandler.hideReport(reportName)
          }
        } else {
          var report
          if (!reportHandler.hasReport(reportName)) {
            reportHandler.initReport(reportName)
          } else {
            report = reportHandler.getReport(reportName)
            if (!report.isVisible()) {
              report.getHTML().css({ opacity: 0, display: 'block' })
              reportHandler.generateVehicleList(reportName)
              reportHandler.reloadReportScroll(reportName)
              report.getHTML().attr('style', '')
              reportHandler.showReport(reportName)
            } else {
              reportHandler.showReport(reportName)
            }
          }
        }
      })

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-map-preferences').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(this).toggleClass("active")
        mt.optionBtnIsActive = !mt.optionBtnIsActive
        if (!mt.optionBtnIsActive) {
          hidePreferences()
        } else {
          showPreferences()
        }
      })

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-pause-map').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(this).toggleClass("active")
        mt.pauseMapActive = !mt.pauseMapActive
        if (mt.pauseMapActive) {
          pauseMap()
        } else {
          unpauseMap()
        }
      })

      const mapWrap = $(".map-wrap")
      const tflDropdown = $(".tfl-dropdown")
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-tfl').click(function (e) {
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(this).toggleClass("active")
        mt.tflActive = !mt.tflActive
        if (!mt.tflActive) {
          tflDropdown.css("visibility", "hidden");
        } else {
          tflDropdown.css("visibility", "visible");
        }
      })

      const inputPastDateDropdown = $(".input-past-date-dropdown")
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-calendar').click(function (e) {
        let index = $(this).parent().parent().index()
        if ($("#mapToolsElementShowVehReg").css('display') === 'none') {
          index--
        }
        const offset = index * 44 - (inputPastDateDropdown.width() / 2) + 22
        inputPastDateDropdown.css("left", offset)
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(this).toggleClass("active")
        mt.showInputPastDateActive = !mt.showInputPastDateActive
        if (!mt.showInputPastDateActive) {
          inputPastDateDropdown.css("visibility", "hidden");
        } else {
          inputPastDateDropdown.css("visibility", "visible");
        }
      })

      const postcodeSearchDropdown = $(".postcode-search-dropdown")
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-postcode-search').click(function (e) {
        let index = $(this).parent().parent().index()
        if ($("#mapToolsElementShowVehReg").css('display') === 'none') {
          index--
        }
        const offset = index * 44 - (postcodeSearchDropdown.width() / 2) + 22
        postcodeSearchDropdown.css("left", offset)
        e.preventDefault()
        if ($(this).hasClass("svg-inactive")) {
          return
        }
        $(".svg-menu-icon.rich-tooltip-container:hover .rich-tooltip").css("visibility", "hidden")
        $(this).toggleClass("active")
        mt.postcodeSearchActive = !mt.postcodeSearchActive
        if (!mt.postcodeSearchActive) {
          postcodeSearchDropdown.css("visibility", "hidden");
        } else {
          postcodeSearchDropdown.css("visibility", "visible");
        }
      })

      $("#tflDisruptions").click(function(e) {
        mt.tflDisruptionsActive = !mt.tflDisruptionsActive
        if (mt.tflDisruptionsActive) {
          $(".tfl-icon.svg-menu-icon.svg-map-tools.icon-map-tools-tfl-disruptions").addClass("active")
          TFLDisruptions.addTFLs()
        } else {
          $(".tfl-icon.svg-menu-icon.svg-map-tools.icon-map-tools-tfl-disruptions").removeClass("active")
          TFLDisruptions.removeTFLs()
        }
      })
      $("#tflLiveTraffic").click(function(e) {
        mt.tflLiveTrafficActive = !mt.tflLiveTrafficActive
        $(".tfl-icon.svg-menu-icon.svg-map-tools.icon-map-tools-tfl-live-traffic").toggleClass("active")
        if (mt.tflLiveTrafficActive) {
          $(".tfl-icon.svg-menu-icon.svg-map-tools.icon-map-tools-tfl-live-traffic").addClass("active")
          showTraffic()
        } else {
          $(".tfl-icon.svg-menu-icon.svg-map-tools.icon-map-tools-tfl-live-traffic").removeClass("active")
          hideTraffic()
        }
      })
      $("#tflCameras").click(function(e) {
        mt.tflCamerasActive = !mt.tflCamerasActive
        $(".tfl-icon.svg-menu-icon.svg-map-tools.icon-map-tools-tfl-cameras").toggleClass("active")
        if (mt.tflCamerasActive) {
          $(".tfl-icon.svg-menu-icon.svg-map-tools.icon-map-tools-tfl-cameras").addClass("active")
          TFLCameras.addTFLs()
        } else {
          $(".tfl-icon.svg-menu-icon.svg-map-tools.icon-map-tools-tfl-cameras").removeClass("active")
          TFLCameras.removeTFLs()
        }
      })

      var isDragging = false

      $HTML.mousedown(function(e) {
        isDragging = true
      })
      $HTML.mousemove(function(e) {
        const yTop = parseInt($HTML.css("top"), 10);
        const xLeft = parseInt($HTML.css("left"), 10);
      })
      $HTML.mouseup(function(e) {
        isDragging = false
      })

      const width = this.mapToolsWidth($HTML);
      console.log("width: " + width)
      const cssLeft = window.screen.width / 2 - width / 2
      $HTML.css("left", cssLeft)

      const tooltip = $(".svg-menu-icon .rich-tooltip")
      tooltip.click(function(e){
        e.stopPropagation()
      })
      // SVG MAP TOOLS
    }
    this.correctElPositionResize = function (e) { // Correcting element position after resize.
      var $wind = $(window)
      var $el = $HTML
      var posVertical = null
      var posHorizontal = null

      if ($wind.height() < $el.offset().top) {
        posVertical = -(($el.offset().top - $wind.height()) + 20)
      }

      if ($wind.width() < $el.offset().left) {
        posHorizontal = -(($el.offset().left - $wind.width()) + 20)
      }

      if (posVertical) {
        $el.animate({ top: '+=' + (posVertical) }, 400)
      }

      if (posHorizontal) {
        $el.animate({ left: '+=' + (posHorizontal) }, 400)
      }
    }
    this.correctPosition = function () {
      var shapes = $('.left-panel,.slide-button,.user-menu')
      var shape = null
      var insideOfShape = null
      var shapesLen = shapes.size()
      var point = null
      var points = []
      var pointsLen = null
      var shapePoint = null
      var pos = null
      var leftSideOnBlock = false
      var rightSideOnBlock = false
      // var mapToolsFrame = $(".map-tools-frame");

      if ($HTML.is(':hidden')) {
        return
      }

      point = $HTML.offset()
      points[0] = [point.left, point.top]
      points[1] = [point.left + $HTML.outerWidth(), point.top]
      pointsLen = points.length
      for (var i = 0; i < pointsLen; i++) {
        for (var k = 0; k < shapesLen; k++) {
          shape = shapes.eq(k)
          shapePoint = shape.offset()
          if (inside(points[i][0], points[i][1], shapePoint.left, shapePoint.top, shapePoint.left + shape.outerWidth(), shapePoint.top + shape.outerHeight())) {
            insideOfShape = shape
            if (i == 1) {
              //                                pos = shapePoint.left - points[i][0] - 38;
              rightSideOnBlock = true
            } else {
              //                                pos = (shapePoint.left + shape.outerWidth()) - points[i][0] + 38;
              leftSideOnBlock = true
            }
            break
          }
        }
      }

      if (leftSideOnBlock && rightSideOnBlock && (insideOfShape.hasClass('left-panel') || insideOfShape.hasClass('slide-button')) || (leftSideOnBlock || rightSideOnBlock) && (insideOfShape.hasClass('left-panel') || insideOfShape.hasClass('slide-button'))) {
        pos = (insideOfShape.offset().left + insideOfShape.outerWidth()) - points[0][0] + 38
      } else if ($HTMLToolsFrame.css('visibility') !== 'hidden' && leftSideOnBlock && rightSideOnBlock && insideOfShape.hasClass('user-menu') ||
        $HTMLToolsFrame.css('visibility') !== 'hidden' && rightSideOnBlock ||
        $HTMLToolsFrame.css('visibility') !== 'hidden' && leftSideOnBlock && insideOfShape.hasClass('user-menu') ||
        $HTMLToolsFrame.css('visibility') === 'hidden' && leftSideOnBlock && insideOfShape.hasClass('user-menu')) {
        pos = insideOfShape.offset().left - points[1][0] - 38
      }

      if (pos) {
        $HTML.animate({ left: '+=' + (pos) }, 400)
      }
      function inside (p1, p2, x1, y1, x2, y2) {
        if ((p1 >= x1 && p1 <= x2) && (p2 >= y1 && p2 <= y2)) {
          return true
        }
        return false
      }
    }
    this.exec = function (command, action) {
      fn[command][action]()
    }
    this.reset = function () {
      $HTML.find('input:checkbox').attr('checked', false)
      hideTraffic()
      removePostcodePin()
    }
    this.hideToolsFrame = function () {
      $HTMLToolsFrame.delay(400).css({ visibility: 'hidden' })
    }
    this.showToolsFrame = function () {
      $HTMLToolsFrame.delay(400).css({ visibility: 'visible' })
    }
    this.hide = function () {
      // $HTML.fadeOut(400);
      $HTML.delay(400).css({ visibility: 'hidden' })
      // $HTML.css("z-index", 0);
    }
    this.show = function () {
      // $HTML.fadeIn(400);
      $HTML.delay(400).css({ visibility: 'visible' })
      // $HTML.css("z-index", 3);
    }
    this.saveState = function () {
      if (cache.length) return
      if (pin) {
        cache.push(
          {
            checkbox: {},
            fn: function () {
              window.mapCanvas.geocode(postcode.val(), addPostcodePin)
            }
          }
        )
      }
      $HTML.find('input:checkbox:checked').each(function (i) {
        cache.push({ checkbox: this, fn: fn[this.value][1] })
        fn[this.value][0]()
      }).attr('checked', false)
      removePostcodePin()
      showMapTools()
    }
    this.loadState = function () {
      var len = cache.length
      for (var i = 0; i < len; i++) {
        cache[i].checkbox.checked = true
        // cache[i].fn()
      }
      cache = []
    }
    this.hideVehRegCheckbox = function () {
      $HTML.find('#mapToolsElementShowVehReg').css("display", "none");
      if (view.getActiveState() !== 'dual') {
        mt.correctPosition()
        mt.recalculateSize()
      }
    }
    this.showVehRegCheckbox = function () {
      $HTML.find('#mapToolsElementShowVehReg').css("display", "block");
      if (view.getActiveState() !== 'dual') {
        mt.correctPosition()
        mt.recalculateSize()
      }
    }
    this.showVehRegIsChecked = function () {
      return mt.showVehRegActive;
      /*
      if (cache.length) {
        for (var i = 0; i < cache.length; i++) {
          if (cache[i].checkbox.value === '7') {
            return true
          }
        }
      } else {
        return $HTML.find('input[value=7]').prop('checked')
      }
       */
    }
    this.hideDrivingTimeButton = function () {
      $HTML.find('#drivingTimeButton').closest('li').hide()
      if (view.getActiveState() !== 'dual') {
        mt.correctPosition()
      }
    }
    this.showDrivingTimeButton = function () {
      $HTML.find('#drivingTimeButton').closest('li').show()
      if (view.getActiveState() !== 'dual') {
        mt.correctPosition()
      }
    }
    this.dropActiveFeatures = function() {
      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-show-aoi').removeClass('active')
      mt.showAoisActive = false
      wlsAOIHandler.removeOOIs()

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-show-poi').removeClass('active')
      mt.showPoisActive = false
      wlsPOIHandler.removeOOIs()

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-pause-map').removeClass('active')
      mt.pauseMapActive = false
      unpauseMap()

      $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-show-vehreg').removeClass('active')
      mt.showVehRegActive = false
      hideVehReg()
    }
    this.hideLegacyTools = function () {
      // Deactivate everything (almost) except CLustering & Trace buttons
      if ($HTML) {
        $HTML.find('.svg-menu-icon.svg-map-tools').removeClass('svg-active')
        $HTML.find('.svg-menu-icon.svg-map-tools').addClass('svg-inactive')

        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-trace').addClass('svg-active')
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-trace').removeClass('svg-inactive')
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-cluster').addClass('svg-active')
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-cluster').removeClass('svg-inactive')

        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-map-preferences').removeClass('svg-inactive')
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-map-preferences').addClass('svg-active')
      }
    }
    this.showLegacyTools = function () {
      // Activate everything (almost) except CLustering & Trace buttons
      if ($HTML) {
        $HTML.find('.svg-menu-icon.svg-map-tools').removeClass('svg-inactive')
        $HTML.find('.svg-menu-icon.svg-map-tools').addClass('svg-active')

        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-trace').removeClass('svg-active')
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-trace').addClass('svg-inactive')
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-cluster').removeClass('svg-active')
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-cluster').addClass('svg-inactive')

        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-trace').removeClass('active')
        mt.isTraceButtonActive = false
        mapCanvas.disableClusteringTraces()
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-cluster').removeClass('active')
        mt.isClusteringButtonActive = false
        mapCanvas.disableClustering()

        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-map-preferences').removeClass('svg-inactive')
        $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-map-preferences').addClass('svg-active')
      }
    }
    this.hideModernTools = function () {
      // Deactivate Clustering & Trace buttons
      // TODO rename functions!!!
      if ($HTML) {
        // console.log('#####' + $HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-trace'));
        // $HTML.find('.modern-map-tools').hide()
      }
    }
    this.showModernTools = function () {
      // Activate Clustering & Trace buttons
      // TODO rename functions!!!
      if ($HTML) {
        // console.log($HTML.find('.svg-menu-icon.svg-map-tools.icon-map-tools-trace'));
        // $HTML.find('.modern-map-tools').show()
      }
    }
    this.clusteringButtonAcive = function () {
      return this.isClusteringButtonActive
    }
    this.traceButtonActive = function () {
      return this.isTraceButtonActive
    }
    function addPostcodePin (places) {
      var img = new Image()
      var poi = {}
      if (!places || places.length == 0) {
        alert('There were no locations found')
        return
      }
      if (pin) window.mapCanvas.removeMarker(pin)
      img.src = 'img/markers/marker_red.png'
      poi.lat = places[0].lat
      poi.lon = places[0].lon
      poi.typeDescr = 'poi'
      poi.radius = 0
      poi.areaColour = 'red'
      window.mapCanvas.addTextMarkerByObj(img, poi, false, false, false, false, 'red', false,
        function (result) {
          pin = result
          pin.longitude = poi.lon
          pin.latitude = poi.lat
          setTimeout(function () {
            window.mapCanvas.zoomToMaxExtentToPoint(poi.lon, poi.lat)
          }, 1500)
        })
    }
    function removePostcodePin () {
      if (pin) {
        window.mapCanvas.removeMarker(pin)
        pin = null
        rezoomMapFlx()
      }
    }
    function showVehReg () {
      if (view.state != 'fleet') return
      $('.uk-license-plate.marker-label').addClass("visible") // google
      if (userPrefs.ignitionIconsVersion != 3) { // bing
        $('.veh-reg').css('display', 'table-row')
      } else {
        $('.veh-reg').css('display', 'inline-block')
      }
    }
    function hideVehReg () {
      if (view.state != 'fleet') return
      $('.uk-license-plate.marker-label').removeClass("visible") // google
      $('.veh-reg').hide() // bing
    }
    function hideMapTools () {
      $('#MicrosoftNav').stop().fadeTo(400, 0)
      $('.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom').css('display', 'none')
      $('.gmnoprint.gm-style-mtc').css('display', 'none')
    }
    function showMapTools () {
      $('#MicrosoftNav').stop().fadeTo(400, 1)
      $('.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom').css('display', '')
      $('.gmnoprint.gm-style-mtc').css('display', '')
    }
    function pauseMap () {
      mapPaused = true
    }
    function unpauseMap () {
      mapPaused = false
      rezoomMapFlx()
    }
    function showTraffic () {
      trafficOn = true
      window.mapCanvas.showTraffic(settings.data.liveTrafficShowIncidentsAllowed, settings.data.liveTrafficShowLegendAllowed, settings.data.liveTrafficShowFlowAllowed)
      $('.MicrosoftMap .TrafficLegend').css('display', 'block')
    }
    function hideTraffic () {
      if (trafficOn) {
        trafficOn = false
        window.mapCanvas.hideTraffic()
        $('.MicrosoftMap .TrafficLegend').css('display', 'none')
      }
    }

    fn[0] = [wlsPOIHandler.removeOOIs, wlsPOIHandler.addOOIs]
    fn[1] = [wlsAOIHandler.removeOOIs, wlsAOIHandler.addOOIs]
    fn[2] = [showMapTools, hideMapTools]
    fn[3] = [unpauseMap, pauseMap]
    fn[4] = [TFLDisruptions.removeTFLs, TFLDisruptions.addTFLs]
    fn[5] = [TFLCameras.removeTFLs, TFLCameras.addTFLs]
    fn[6] = [hideTraffic, showTraffic]
    fn[7] = [hideVehReg, showVehReg]

    var resizeable

    window.onresize = function () {
      var doc = $(document)

      // $HTML.draggable('option', 'containment', [0, 0, doc.width() - 20, doc.height() - 10])

      clearTimeout(resizeable)
      resizeable = setTimeout(function () {
        mt.correctElPositionResize()
      }, 200)
    }
  }
  function viewChanger () { // viewChanger class
    var vc = this
    var $HTML = null
    var fn = []
    var states = {
      fleet: null,
      activity: null,
      dual: null
    }
    this.state = 'fleet'
    this.init = function () {
      $HTML = $('[data-view]')
      $HTML.each(function () {
        var $this = $(this)
        $this.click(function () {
          if ($this.hasClass('active')) {
            return
          }
          vc.switchStateTo($this.attr('data-view'))
        })
      })
      $HTML = leftPanel
    }
    function reloadScroll () {
      $(window).triggerHandler('resize.maxH')
      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [(leftPanel.find('.right-col .vehicle-holder .slidee').height()) + 5])
      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
    }
    function initResizable () {
      var $rightCol = leftPanel.find('.right-col')
      var fleetGrid = $rightCol.find('.row.isotope-elem').width()
      var maxWidth = calculateWidthResizable(fleetGrid)
      var gridWidth = fleetGrid > 323 ? 327 : 323
      // var columns = Math.floor(maxWidth / 324);

      $rightCol.resizable({
        handles: 'e',
        maxWidth: maxWidth,
        minWidth: 327,
        grid: gridWidth,
        create: function () {
          $rightCol.css('width', maxWidth)
          panel.fleetFilteredCars = ''
          wls.createFleetViewHtml(Math.floor(maxWidth / gridWidth))

          // leftPanel.find(".right-col .vehicle-holder .scroll-content").triggerHandler("resize",[leftPanel.find(".right-col .vehicle-holder .scroll-content .slidee").height()]);

          // reloadScroll();
        },
        resize: function (e, ui) {
          wls.reoderFleetViewSlidee(panel.fleetFilteredCars)

          reloadScroll()
        },
        stop: function (e, ui) {
          wls.reoderFleetViewSlidee(panel.fleetFilteredCars)
          reloadScroll()
        }
      })
      isResizable = true
      leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
      function checkMaxWidthResizable (fleetGrid) {
        if (maxWidth > calculateWidthResizable(fleetGrid)) {
          $rightCol.css('width', calculateWidthResizable(fleetGrid))
        }

        if (isResizable) {
          $rightCol.resizable('option', 'maxWidth', calculateWidthResizable(fleetGrid))
        }
      }

      $(window).on('resize', checkMaxWidthResizable(fleetGrid))
    }
    function resetResizable () {
      leftPanel.find('.right-col').resizable('destroy')
      leftPanel.find('.right-col').css('width', 327)
      rebuildSlidee()
      isResizable = false
    }

    function rebuildSlidee () {
      leftPanel.find('.right-col .vehicle-holder .slidee').children().remove()
      wls.reCreateHtml()
    }

    function calculateWidthResizable (elWidth) {
      var width = 0

      for (var i = 327; i < window.outerWidth; i = i + (elWidth > 323 ? 327 : 323)) {
        width = i
      }

      return width
    }

    function fleetState (preventTimerRestart) { // <----- this is Dual view
      vc.state = 'fleet'

      if (searchVehicle) {
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }
        if (!leftPanel.find('.vehicle-search').is(':Visible')) {
          // leftPanel.find(".vehicle-search").show();
          leftPanel.find('.vehicle-search').slideDown(300)
        }
      }

      if (isResizable) {
        resetResizable()
      }

      $('[data-view=' + vc.state + ']').addClass('active').siblings(':not(.groups-btn)').removeClass('active')

      activityLog.removeLogEntryMarker()
      activityLog.clearTable()
      activityLog.hide()

      $('.right-col', leftPanel).removeClass('activity-active')
      $('.proximity-view', leftPanel).hide()

      leftPanel.find('.right-col .vehicle-holder').css('visibility', 'visible')
      leftPanel.find('.right-col .vehicle-holder .row').removeClass('active')
      leftPanel.find('.control-panel,.control-panel+.row').show()
      leftPanel.find('.btns-holder').show()
      leftPanel.find('.distance').hide()

      proximity.resetSelect()
      proximity.resetSearchDropdown()
      proximity.resetPin()

      if (leftPanel.find('.actions-holder').is(':Visible')) {
        searchVehicle.resetActionHolder()
      }

      if (!searchVehicle) {
        leftPanel.find('.vehicle-search').hide()
      }

      reloadScroll()
      wls.loadState(preventTimerRestart)
      if (!reportHandler.hasActiveReport()) {
        wlsMapTools.show()
        wlsMapTools.showToolsFrame()
        wlsMapTools.loadState()
        wlsMapTools.hideModernTools()
        wlsMapTools.showLegacyTools()
        wlsMapTools.activateDropdowns()
        wlsMapTools.deactivateShowVehReg()
        wlsMapTools.recalculateSize()
      }
      leftPanel.find('.right-col').removeClass('hide-shadow')

      if (addPoiPinByRightClickFromActivityLog) {
        var $activeLog = $('.advanced-activity-log')

        $activeLog.show()

        addPoiPinByRightClickFromActivityLog = false
      } else {
        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy()
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
      }

      if (settings.data.htmlGroupTabsAllowed) {
        $('[data-tabset=group]').show(250)
        if (leftPanel.hasClass('no-group-tab')) {
          leftPanel.animate({ paddingLeft: 'auto' }, 300)
          leftPanel.removeClass('no-group-tab').addClass('has-group-tab')
        }
        leftPanel.find('.right-col .vehicle-holder').show()
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
        groupTabsetView.vehicleHolderHandler(leftPanel)
        groupTabsetView.render()
      } else if (!settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('has-group-tab')) {
        $('[data-tabset=group]').hide()
        leftPanel.removeClass('has-group-tab').addClass('no-group-tab')
        leftPanel.find('.right-col .vehicle-holder').show()
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
        groupTabsetView.vehicleHolderHandler(leftPanel)
        groupTabsetView.renderScroll()
      } else if (!settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('no-group-tab')) {
        leftPanel.find('.right-col .vehicle-holder').show()
        groupTabsetView.vehicleHolderHandler(leftPanel)
      }
    }
    function dualState (preventTimerRestart) { // <----- this is Fleet view
      vc.state = 'dual'
      var animated = leftPanel.find(':animated')

      if (isResizable) {
        resetResizable()
      }

      $('[data-view=' + vc.state + ']').addClass('active').siblings(':not(.groups-btn)').removeClass('active')

      prefererencesPopupView.hideActivePopup()

      activityLog.removeLogEntryMarker()
      activityLog.clearTable()
      activityLog.hide()

      $('.right-col', leftPanel).removeClass('activity-active')
      $('.proximity-view', leftPanel).hide()

      leftPanel.find('.right-col .vehicle-holder').css('visibility', 'visible')
      leftPanel.find('.right-col .vehicle-holder .row').removeClass('active')
      leftPanel.find('.control-panel,.control-panel+.row').show()
      leftPanel.find('.btns-holder').show()
      leftPanel.find('.distance').hide()

      proximity.resetSelect()
      proximity.resetSearchDropdown()
      proximity.resetPin()

      if (leftPanel.find('.actions-holder').is(':Visible')) {
        searchVehicle.resetActionHolder()
      }

      if (!searchVehicle) {
        leftPanel.find('.vehicle-search').hide()
      }

      if (animated.size()) {
        stopLeftPanelAnimation()
      }

      initResizable()
      reloadScroll()
      wls.resetState()
      wls.loadState(preventTimerRestart)
      wlsMapTools.saveState()
      wlsMapTools.hideToolsFrame()
      wlsMapTools.hide()
      wlsMapTools.activateDropdowns()
      wlsMapTools.deactivateShowVehReg()

      if (reportHandler.hasActiveReport()) {
        reportHandler.hideReport(reportHandler.getActiveReport())
      }

      leftPanel.find('.right-col').removeClass('hide-shadow')

      if (addPoiPinByRightClickFromActivityLog) {
        var $activeLog = $('.advanced-activity-log')

        $activeLog.show()

        addPoiPinByRightClickFromActivityLog = false
      } else {
        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy()
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
      }

      if (settings.data.htmlGroupTabsAllowed) {
        $('[data-tabset=group]').show(250)
        if (leftPanel.hasClass('no-group-tab')) {
          leftPanel.animate({ paddingLeft: 'auto' }, 300)
          leftPanel.removeClass('no-group-tab').addClass('has-group-tab')
        }
        leftPanel.find('.right-col .vehicle-holder').show()
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
        groupTabsetView.vehicleHolderHandler(leftPanel)
        groupTabsetView.render()
      } else if (!settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('has-group-tab')) {
        $('[data-tabset=group]').hide()
        leftPanel.removeClass('has-group-tab').addClass('no-group-tab')
        leftPanel.find('.right-col .vehicle-holder').show()
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
        groupTabsetView.vehicleHolderHandler(leftPanel)
        groupTabsetView.renderScroll()
      } else if (!settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('no-group-tab')) {
        leftPanel.find('.right-col .vehicle-holder').show()
        groupTabsetView.vehicleHolderHandler(leftPanel)
      }
    }
    function activityState () {
      vc.state = 'activity'
      $('[data-view=' + vc.state + ']').addClass('active').siblings(':not(.groups-btn)').removeClass('active')
      var animated = leftPanel.find(':animated')

      if (isResizable) {
        resetResizable()
      }

      driverWorkingHoursTodayPopupView.hideActivePopup()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (reportHandler.hasActiveReport()) {
        reportHandler.hideReport(reportHandler.getActiveReport())
      }
      if (animated.size()) {
        stopLeftPanelAnimation()
      }
      if ($('.select-to-sms-popup').css('visibility') === 'visible') {
        $('.select-to-sms-popup').css('visibility', 'hidden'); if (mapSource && mapSource.startsWith('MSVE')) {
          window.mapCanvas.map.entities.pop()
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          window.mapCanvas.cleanUp()
        }
        window.wlsMapTools.multipleSelectToolIsActive = false
      }

      $('.proximity-view', leftPanel).hide()
      leftPanel.find('.right-col .vehicle-holder').css('visibility', 'visible')
      leftPanel.find('.control-panel,.control-panel+.row').show()
      leftPanel.find('.btns-holder').hide()
      leftPanel.find('.distance').hide()
      leftPanel.find('.right-col').addClass('activity-active')

      proximity.resetSelect()
      proximity.resetSearchDropdown()
      proximity.resetPin()
      if (activityLogPopupView && activityLogPopupView.isVisible()) {
        activityLogPopupView.destroy()
      }
      liveVideoPlayerPopup && liveVideoPlayerPopup.hide()
      // Search actions
      if (searchVehicle) {
        searchVehicle.resetTableResults()
        if (searchVehicle.isVisible()) {
          searchVehicle.hideSearchResult()
        }
        if (searchVehicle.variantsIsVisible()) {
          searchVehicle.variantsHide()
        }

        leftPanel.find('.vehicle-search').hide()
      }
      if (leftPanel.find('.actions-holder').is(':Visible')) {
        searchVehicle.resetActionHolder()
      }
      if (!searchVehicle) {
        leftPanel.find('.vehicle-search').hide()
      }
      // Search actions end
      reloadScroll()
      wls.resetState()
      wlsMapTools.showToolsFrame()
      wlsMapTools.saveState()
      wlsMapTools.hideLegacyTools()
      wlsMapTools.dropActiveFeatures()
      wlsMapTools.activateDropdowns()
      wlsMapTools.deactivateShowVehReg()
      /** GOOGLE Clustering */
      if (window.settings.data.nextGenArrowsAllowed && mapSource && mapSource === 'GOOGLEMAPS') {
        wlsMapTools.showModernTools()
      }
      // wlsMapTools.recalculateSize()
      /** GOOGLE Clustering **/
      leftPanel.find('.right-col').removeClass('hide-shadow')

      if (settings.data.htmlGroupTabsAllowed) {
        $('[data-tabset=group]').show(250)
        if (leftPanel.hasClass('no-group-tab')) {
          leftPanel.animate({ paddingLeft: 'auto' }, 300)
          leftPanel.removeClass('no-group-tab').addClass('has-group-tab')
        }
        leftPanel.find('.right-col .vehicle-holder').show()
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
        groupTabsetView.vehicleHolderHandler(leftPanel)
        groupTabsetView.renderScroll()
      } else if (!settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('no-group-tab')) {
        leftPanel.find('.right-col .vehicle-holder').show()
        groupTabsetView.vehicleHolderHandler(leftPanel)
      }
      $('.select-to-sms-popup').css('visibility', 'hidden'); if (mapSource && mapSource.startsWith('MSVE')) {
        window.mapCanvas.map.entities.pop()
      } else if (mapSource && mapSource === 'GOOGLEMAPS') {
        window.mapCanvas.cleanUp()
      }
    }
    this.getActiveState = function () {
      return vc.state
    }
    this.switchStateTo = function (stateName, preventTimerRestart) {
      states[stateName](preventTimerRestart)
    }
    states.fleet = fleetState
    states.activity = activityState
    states.dual = dualState
    fn[0] = fleetState
    fn[1] = activityState
    fn[2] = dualState
  }

  var cameraEvents = new CameraEvents()
  var fleetManagement = new FleetManagementPlus()
  var smsManager = new SmsManager()
  var manageDrivers = new ManageDrivers()
  var sendMessage = new SendMessage()
  var dallasKeyManager = new DallasKeyManager()
  var tachographKeyManager = new TachographKeyManager()
  var permissionsPlus = new PermissionsPlus()
  var systemAlert = new SystemAlert()
  var settings = new userSettings()
  var wlsPOIHandler = new OOIHandler('/getPoiForCurrentUser.do', 'poi')
  var wlsAOIHandler = new OOIHandler('/getAoiForCurrentUser.do', 'aoi')
  var TFLCameras = new TFLHandler('/getLastCameraIncidentList.do', 'camera')
  var TFLDisruptions = new TFLHandler('/getLastDisruptionIncidentList.do', 'disruption')
  var wlsMapTools = new mapTools()
  var proximity = new proximityView()
  var activityLog = new ActivityLog()
  var wls = new carHandler()
  var panel = new controlPanel()
  var view = new viewChanger()
  var reportLoader = new ReportLoader()
  var reportHandler = new ReportHandler()

  window.systemAlert = systemAlert
  window.wls = wls
  window.wlsMapTools = wlsMapTools
  window.settings = settings
  window.panel = panel
  window.reportHandler = reportHandler
  window.proximity = proximity
  window.cameraEvents = cameraEvents
  window.smsManager = smsManager
  window.view = view
  window.wlsPOIHandler = wlsPOIHandler

  if ($('.map-tools').size() || $('.left-panel').size()) {
    vehicleListMaxH()
    if ($('#content .left-col .scroll-content').size()) {
      initScroll($('#content .left-col .scroll-content'), 0)
    }
    panel.init()
    view.init()
    // $.get("api/settings/user").done(function(json){
    //     userProfileInfo = json;
    // });
    settings.onLoad(function () {
      $.cookie('lastViewedAccount', settings.data.lastAccountId)
      htmlGroupTabsAllowed = settings.data.htmlGroupTabsAllowed

      if (leftPanel.size()) {
        videoPlayer = new VideoPlayer($('.incident-details .tab.video .video-player'))
        if (htmlGroupTabsAllowed) {
          groupTabsetView = new GroupTabsetView()
          window.groupTabsetView = groupTabsetView
        }
        if (settings.data.selectionMenuExpanded) {
          panel.expandAll.apply(leftPanel.find('.expand'))
        }
        if (!searchVehicle &&
          (settings.data.advancedVinSearchAllowed ||
            settings.data.fleetIdSearchAllowed ||
            settings.data.fleetNumberSearchAllowed ||
            settings.data.informationSearchAllowed ||
            settings.data.vehicleRegistrationSearchAllowed)) {
          searchVehicle = new SearchVehicle()
        } else {
          searchVehicle = false
          leftPanel.find('.vehicle-search').hide()
        }
        window.searchVehicle = searchVehicle

        if (!htmlGroupTabsAllowed && leftPanel.hasClass('has-group-tab')) {
          leftPanel.removeClass('has-group-tab').addClass('no-group-tab')
          groupTabsetView = new GroupTabsetView()
          window.groupTabsetView = groupTabsetView
        }

        if (leftPanel.find('.vehicle-search').is(':visible')) {
          if (settings.data.searchTooltipMessage !== null && settings.data.searchTooltipMessage.length > 0) {
            leftPanel.find('.vehicle-search .search-tooltip').text(settings.data.searchTooltipMessage)
          }
          if (settings.data.searchInputMask !== null && settings.data.searchInputMask.length > 0) {
            leftPanel.find('.vehicle-search .input-holder>input').attr('placeholder', settings.data.searchInputMask)
          }
        }

        wls.requestCars(selectedGroup, function () {
          // setTimeout(function(){initScroll($(".right-col .vehicle-holder .scroll-content",leftPanel), 69);}, 300);
          initScroll($('.right-col .vehicle-holder .scroll-content', leftPanel), 69)
          activityLog.init()
        })

        if (settings.data.showVehReg) {
          wlsMapTools.showVehRegCheckbox()
        } else {
          wlsMapTools.hideVehRegCheckbox()
        }
        if (settings.data.drivingTimePanelAllowed) {
          wlsMapTools.showDrivingTimeButton()
        } else {
          wlsMapTools.hideDrivingTimeButton()
        }
        wlsMapTools.init()
        initLiveVideo()
        wlsMapTools.show()

        Incidents().setIncidentsWindow($('.left-panel .incidents'))
        Incidents().setIncidentsTable($('.incidents table.info.content'))
        Incidents().setSnailTrail(new SnailTrail())
        Incidents().setAccountColumnIndex(Incidents().getIncidentsWindow().find('th[data-head=account]').index())
        Incidents().setSubGroupColumnIndex()
        $('.inner-logo').fadeIn(800)
      }
      JourneyDetails().insertMetrics()

      // wlsMapTools.correctDragCanvas();
      setActionMenuVisibility()
    })
    settings.checkTimeStamp()
  }
  if ($('.user-menu').size()) {
    userMenuHover()
    modalPopupInit()
    initReportClickHandler()
    UserProfile().getSettings()
  }

  addPoi = new AddPOI()
  window.AddPOI = addPoi

  function getFormData ($form) {
    var names = {}
    $form.find('input:text,input:password,input:radio:checked,input:checkbox:checked,input[type=hidden],select,textarea').each(function () {
      names[this.name] = this.value
    })
    return names
  }

  (function login () {
    var form = $('form.login')
    var forgotten = $('.forgotten-pass')
    var errorCounter = 1

    function shake (el, loops) {
      var counter = 1
      var time = 100
      var offset = 50
      var left = parseInt(el.css('left'))

      function start () {
        time = 100 / counter
        el.animate({
          left: left + (offset - (counter / loops * offset))
        }, time, function () {
          el.animate({
            left: left - (offset - (counter / loops * offset))
          }, time, function () {
            if (counter == loops) return
            start()
            counter++
          })
        })
      }
      start()
    }

    form.submit(function (e) { // login
      e.preventDefault()
      var btn = form.find('input:submit')
      var data = getFormData($(this))
      data.isRest = true
      if (!request) {
        btn.addClass('loading')
        form.find('input').attr('disabled', true)
        request = $.post(this.action, data).done(function (msg) {
          var html = $(msg)
          var $mapTools = html.filter('.map-tools')

          leftPanel = html.filter('.left-panel')
          addInfo = html.filter('.add-information-popup.edit-info')
          btn.addClass('correct').removeClass('error').removeClass('loading')
          form.find('input').attr('disabled', false)
          $('.popup').fadeOut(800, function () {
            $(this).removeClass('active')
            this.removeAttribute('style')
            html.css('visibility', 'hidden')
            $('#content').prepend(html.filter('.left-col'))
            $('#content .map-wrap').prepend(leftPanel)
            $('#content .map-wrap').prepend(addInfo)
            $('#content .map-wrap').prepend($mapTools.hide())
            $('.inner-logo').fadeIn(800)
            mapCanvas = getMap('wlsmap')
            window.mapCanvas = mapCanvas

            selectedGroup = $.cookie('lastViewedGroup')
            settings.onLoad(function () {
              $.cookie('lastViewedAccount', settings.data.lastAccountId)
              JourneyDetails().insertMetrics()

              if (settings.data.advancedVinSearchAllowed ||
                settings.data.fleetIdSearchAllowed ||
                settings.data.fleetNumberSearchAllowed ||
                settings.data.informationSearchAllowed ||
                settings.data.vehicleRegistrationSearchAllowed) {
                searchVehicle = new SearchVehicle()
              } else {
                searchVehicle = false
                leftPanel.find('.vehicle-search').hide()
              }
              window.searchVehicle = searchVehicle

              if (leftPanel.find('.vehicle-search').is(':visible')) {
                if (settings.data.searchTooltipMessage !== null && settings.data.searchTooltipMessage.length > 0) {
                  leftPanel.find('.vehicle-search .search-tooltip').text(settings.data.searchTooltipMessage)
                }
                if (settings.data.searchInputMask !== null && settings.data.searchInputMask.length > 0) {
                  leftPanel.find('.vehicle-search .input-holder>input').attr('placeholder', settings.data.searchInputMask)
                }
              }

              wls.requestCars(selectedGroup, function () {
                if (panel.allGroupSelected()) {
                  panel.exec('SM_SELECT_ALL')
                }
                view.init()
                activityLog.init()
                leftPanelAnimation(function () {
                  htmlGroupTabsAllowed = settings.data.htmlGroupTabsAllowed
                  vehicleListMaxH()
                  initScroll($('.right-col .vehicle-holder .scroll-content', leftPanel), 69)
                  if (localStorage.getItem('selectedVehicles').length == 0 ||
                    settings.data.preselectSmOnLogon) {
                    panel.expandAll.apply(leftPanel.find('.expand'))
                    panel.exec('SM_SELECT_ALL')
                  }
                  if (htmlGroupTabsAllowed && window.groupTabsetView === undefined) {
                    groupTabsetView = new GroupTabsetView()
                    window.groupTabsetView = groupTabsetView
                  } else if (htmlGroupTabsAllowed && window.groupTabsetView) {
                    groupTabsetView.render()
                  } else if (!htmlGroupTabsAllowed && leftPanel.hasClass('has-group-tab')) {
                    leftPanel.removeClass('has-group-tab').addClass('no-group-tab')
                    groupTabsetView = new GroupTabsetView()
                    window.groupTabsetView = groupTabsetView
                  }
                })
                if ($('#content .left-col .scroll-content').size()) {
                  initScroll($('#content .left-col .scroll-content'), 0)
                }
                var hoverTooltip = $('.hover-tooltip')
                if (typeof $.cookie('lastViewedGroupDescr') === 'undefined') {
                  // no cookie
                  var tempGroupDescr = 'Checking Group Name...'
                  hoverTooltip.attr('data-tooltip', tempGroupDescr)
                  $.post('/api/unit-view/description', {
                    unitViewId: $.cookie('lastViewedGroup')
                  }).done(function (msg) {
                    var groupDescr = msg
                    console.log('..' + groupDescr)
                    $('.hover-tooltip').attr('data-tooltip', groupDescr)
                    $('.control-holder .groups-tooltip').text(groupDescr)
                    $.cookie('lastViewedGroupDescr', groupDescr)
                  }).error(function (msg, errorText) {
                    console.error('Failed to load Group descr, reason: ' + errorText)
                  })
                }

                hoverTooltip.mousemove(function (e) {
                  var hovertext = $(this).attr('data-tooltip')
                  var groupsTooltip = $('.control-holder .groups-tooltip')
                  if (settings.data.selectionMenuGroupNameVisible) {
                    groupsTooltip.text(hovertext).show()
                    if ($('.left-panel').hasClass('active')) {
                      groupsTooltip.css('top', e.clientY - 18).css('left', e.clientX - 268)
                    } else {
                      groupsTooltip.css('top', e.clientY - 18).css('left', e.clientX + 14)
                    }
                  }
                }).mouseout(function () {
                  $('.control-holder .groups-tooltip').hide()
                })

                html.css({
                  visibility: 'visible'
                })
              })
              wlsMapTools.init()
              multipleSelect = new MultipleSelect()
              window.multipleSelect = multipleSelect
              initLiveVideo()
              if (settings.data.showVehReg) {
                wlsMapTools.showVehRegCheckbox()
              } else {
                wlsMapTools.hideVehRegCheckbox()
              }
              if (settings.data.drivingTimePanelAllowed) {
                wlsMapTools.showDrivingTimeButton()
              } else {
                wlsMapTools.hideDrivingTimeButton()
              }
              wlsMapTools.show()
              // wlsMapTools.correctDragCanvas();
              setActionMenuVisibility()
            })
            Incidents().setIncidentsWindow($('.left-panel .incidents'))
            Incidents().setIncidentsTable($('.incidents table.info.content'))
            Incidents().setSnailTrail(new SnailTrail())
            initSlide()
            // placeholderFix();
            panel.init()
            videoPlayer = new VideoPlayer($('.incident-details .tab.video .video-player'))
            initSettings()

            var topBar = $('#content').find('.top-bar')
            if (!topBar.is(':visible') && !topBar.is(':hidden')) {
              topBar.show()
            }

            getUserMenu(function () {
              modalPopupInit()
              userMenuHover()
              initReportClickHandler()
              UserProfile().getSettings()
              addPoi = new AddPOI()
              window.AddPOI = addPoi

            })
          })
          form.parent().removeClass('loading')
          request = false
          form.off('submit')
          form.submit(function (e) {
            e.preventDefault()
          })
        }).error(function (msg, errorTxt) {
          request = false
          form.find('input').attr('disabled', false)
          btn.removeClass('correct').addClass('error').removeClass('loading')
          if (errorCounter == 3) {
            form.closest('.login-wrap').addClass('forgotten')
            form.closest('.frontpage-wrapper').addClass('forgotten')
            return
          }
          errorCounter++
          if (!form.parent().is(':animated')) {
            shake(form.parent(), 2)
          }
        })
      }
    })
    form.find('a').click(function (e) {
      errorCounter = 1
      form.closest('.login-wrap').addClass('forgotten')
      form.closest('.frontpage-wrapper').addClass('forgotten')
      e.preventDefault()
    })
    $('.forgotten-pass .go-back .btn-holder').click(function () {
      errorCounter = 1
      form.closest('.login-wrap').removeClass('forgotten')
      form.closest('.frontpage-wrapper').removeClass('forgotten')
      forgotten.removeClass('ok').removeClass('error')
    })
    forgotten.submit(function (e) {
      var data = getFormData($(this))
      $.post(this.action, data).done(function (msg) {
        var json = msg
        if (!json.status) {
          forgotten.addClass('ok')
          forgotten.addClass('ok')
        } else if (json.status) {
          forgotten.removeClass('ok').addClass('error')
        }
        forgotten.find('.message span').text(json.data)
      }).error(function (msg, errorTxt) {
        alert('error')
      })
      e.preventDefault()
    })
    form.css('visibility', 'visible')
  })();
  (function popupAlign () {
    var popup = $('.popup')
    var login = popup.find('.login-wrap')
    if (!login.size()) {
      return
    }
    function handler () {
      login.removeClass('center')
      if (login.offset().top < 0) {
        login.not('.center').addClass('center')
      } else {
        login.not('.center').removeClass('center')
      }
    }
    handler()
    $(window).resize(handler)
  })();
  (function focus () {
    $('input').focus(function () {
      $(this).parent().addClass('active')
    }).blur(function () {
      $(this).parent().removeClass('active')
    })
  })()

  // function placeholderFix() {
  //     var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  //     if (isMac) {
  //         console.log("HEre!");
  //         $("input").placeholder();
  //     }
  // }
  // placeholderFix();

  function leftPanelAnimation (callback) {
    var controlHolder = $('.control-holder', leftPanel)
    var vehicleHolder = $('.vehicle-holder', leftPanel)
    var slideBtn = $('.slide-button', leftPanel)

    slideBtn.hide().css('right', 0)
    leftPanel.find('.right-col').addClass('hide-shadow')
    controlHolder.css({
      position: 'relative',
      left: -controlHolder.outerWidth(),
      'z-index': 1
    })
    vehicleHolder.css({
      position: 'relative',
      top: -(vehicleHolder.outerHeight() + controlHolder.height())
    }).hide()
    controlHolder.animate({
      left: 0
    }, 800, function () {
      vehicleHolder.show().animate({
        top: 0
      }, 800, function () {
        slideBtn.show().animate({
          right: -slideBtn.outerWidth()
        }, 400, function () {
          slideBtn.add(controlHolder).add(vehicleHolder).attr('style', '')
          leftPanel.find('.right-col').removeClass('hide-shadow')
          if (callback) callback()
        })
      })
    })
  }
  function stopLeftPanelAnimation () {
    var controlHolder = $('.control-holder', leftPanel)
    var vehicleHolder = $('.vehicle-holder', leftPanel)
    var slideBtn = $('.slide-button', leftPanel)
    controlHolder.stop(true, true)
    vehicleHolder.stop(true, true)
    slideBtn.stop(true, true)
    leftPanel.find('.right-col').removeClass('hide-shadow')
  }
  function initSlide () {
    var leftCol = $('.left-col', content)
    var rightCol = $('.right-col', leftPanel)
    $('.menu .groups-btn', leftPanel).click(function () {
      var mapWrap = content.find('.map-wrap')
      var topBar = content.find('.top-bar')

      driverWorkingHoursTodayPopupView.hideActivePopup()
      refreshSettingsPopupView.hideActivePopup()
      prefererencesPopupView.hideActivePopup()
      heartBeatPopupView.hideActivePopup()
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      if (leftPanel.hasClass('active')) {
        mapWrap.animate({
          'margin-left': 0
        }, 800, function () {
          leftPanel.removeClass('active')
          mapWrap.removeClass('active')
        })
        if (topBar.is(':visible')) {
          topBar.animate({
            'margin-left': 0
          }, 800, null)
        }
      } else {
        mapWrap.animate({
          'margin-left': leftCol.outerWidth()
        }, 800, function () {
          leftPanel.addClass('active')
          mapWrap.addClass('active')
        })
        if (topBar.is(':visible')) {
          topBar.animate({
            'margin-left': leftCol.outerWidth()
          }, 800, null)
        }
      }
    })
    $('.slide-button', leftPanel).click(function () {
      var mapWrap = content.find('.map-wrap')
      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
        leftPanel.animate({
          left: 0
        }, 800, wlsMapTools.correctPosition)
        $('.top-bar').animate({
          left: 0
        }, 800, wlsMapTools.correctPosition)
      } else {
        driverWorkingHoursTodayPopupView.hideActivePopup()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
        $(this).addClass('active')
        if (mapWrap.hasClass('active')) {
          mapWrap.animate({
            'margin-left': 0
          }, 800, function () {
            leftPanel.animate({
              left: -leftPanel.outerWidth(true)
            }, 800, function () {
              leftPanel.removeClass('active')
              wlsMapTools.correctPosition()
            })
            $('.top-bar').animate({
              left: -leftPanel.outerWidth(true)
            }, 800)
          })
          $('.top-bar').animate({
            'margin-left': 0
          }, 800)
        } else {
          leftPanel.animate({
            left: -leftPanel.outerWidth(true)
          }, 800, function () {
            leftPanel.removeClass('active')
            wlsMapTools.correctPosition()
          })
          $('.top-bar').animate({
            left: -leftPanel.outerWidth(true)
          }, 800)
        }
      }
      if (addInfo && wls.addInformationActive()) {
        wls.hideAddInformation()
      }
      if (heartBeatPopupView) {
        heartBeatPopupView.hide()
      }
      if (phoneNumberPopup) {
        phoneNumberPopup.destroy()
      }
    })
    $('.left-col .account-list .account-folder', content).find('.ico-holder').click(function () {
      // initScroll($("#content .left-col .scroll-content"),0);

      if ($(this).parent().next().is(':visible')) {
        $('#content .left-col .scroll-content').triggerHandler('resize', [($('#content .left-col .slidee').height() - $(this).next().height())])
      } else {
        $(this).parent().next().show()
        $('#content .left-col .scroll-content').triggerHandler('resize', [($('#content .left-col .slidee').height())])
        $(this).parent().next().hide()
      }
      $(this).parent().next().slideToggle(400, function () {
        $(this).parent().closest('li').toggleClass('active')
        $('#content .left-col .scroll-content').triggerHandler('resize', [($('#content .left-col .slidee').height())])
        $('#content>.left-col .scroll-content').triggerHandler('animationEnd')
      })
    })
    if (!htmlGroupTabsAllowed || htmlGroupTabsAllowed) {
      $('.account-list .account-folder', content).dblclick(function (e) {
        if (window.reportPageIsActive) {
          var activeReport = reportHandler.getActiveReport()
          if (reportHandler.getReport('DrivingTime.do') == activeReport) {
            reportHandler.hideReport(activeReport)
          } else {
            return
          }
        }
        driverWorkingHoursTodayPopupView.hideActivePopup()
        refreshSettingsPopupView.hideActivePopup()
        prefererencesPopupView.hideActivePopup()
        if (activityLogPopupView && activityLogPopupView.isVisible()) {
          activityLogPopupView.destroy()
        }
        liveVideoPlayerPopup && liveVideoPlayerPopup.hide()

        if (phoneNumberPopup) {
          phoneNumberPopup.destroy()
        }

        if (addInfo && wls.addInformationActive()) {
          wls.hideAddInformation()
        }
        if (sendMessage.isVisible()) {
          sendMessage.destroyEl()
        }
        if ($('.select-to-sms-popup').css('visibility') === 'visible') {
          $('.select-to-sms-popup').css('visibility', 'hidden')
          window.wlsMapTools.multipleSelectToolIsActive = false
        }
        $('.multiple-select-btn').removeClass('active')
        $('body').css('cursor', 'auto')
        window.wlsMapTools.multipleSelectToolIsActive = false
        if (mapSource && mapSource.startsWith('MSVE')) {
          window.mapCanvas.map.entities.pop()
        } else if (mapSource && mapSource === 'GOOGLEMAPS') {
          window.mapCanvas.cleanUp()
        }

        if (heartBeatPopupView) {
          heartBeatPopupView.hide()
        }

        if (!request && selectedGroup != $(this).find('input:hidden').val() && $(this).find('input:hidden').val() !== undefined) {
          selectedGroup = $(this).find('input:hidden').val()
          if (selectedGroup) {
            var tempGroupDescr = 'Checking Group Name...'
            $('.hover-tooltip').attr('data-tooltip', tempGroupDescr)
            $.post('/api/unit-view/description', {
              unitViewId: selectedGroup
            }).done(function (msg) {
              var groupDescr = msg
              console.log('..' + groupDescr)
              $('.hover-tooltip').attr('data-tooltip', groupDescr)
              $('.control-holder .groups-tooltip').text(groupDescr)
              $.cookie('lastViewedGroupDescr', groupDescr)
            }).error(function (msg, errorText) {
              console.error('Failed to load Group descr, reason: ' + errorText)
            })
          }
          var accountId

          if ($(this).parents('li.active').size() > 0) {
            accountId = $(this).closest('li:has(*[data-accountid])').children().attr('data-accountid')
          } else {
            accountId = $(this).attr('data-accountid')
          }

          sendCurrentAccount(accountId, function () {
            settings.get(true).done(function () {
              if (settings.data.showVehReg) {
                wlsMapTools.showVehRegCheckbox()
              } else {
                wlsMapTools.hideVehRegCheckbox()
              }
              if (settings.data.drivingTimePanelAllowed) {
                wlsMapTools.showDrivingTimeButton()
              } else {
                wlsMapTools.hideDrivingTimeButton()
              }

              wlsMapTools.mapToolsWidth($('.map-tools'))

              if (settings.data.refreshSmSeconds) {
                wls.setTimerValue(settings.data.refreshSmSeconds)
              }
              $('.map-tools-frame ul li').each(function (i, el) {
                if ($(el).find('input[value=0]').is(':checked')) {
                  wlsPOIHandler.removeOOIs()
                  wlsPOIHandler.addOOIs()
                } else if ($(el).find('input[value=1]').is(':checked')) {
                  wlsAOIHandler.removeOOIs()
                  wlsAOIHandler.addOOIs()
                }
              })

              wls.resetSavedStates()
              wls.resetLastZoomed()
              wls.requestCars(selectedGroup, function () {
                addPoi.lon = null
                addPoi.lat = null

                wlsMapTools.initInput()
                panel.resetSortOrder()
                leftPanel.find('.expand').removeClass('active')
                leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
                //                            wlsMapTools.reset();
                proximity.generateSelectVehicleDropdown()

                if (searchVehicle) {
                  searchVehicle.resetTableResults()
                  if (searchVehicle.isVisible()) {
                    searchVehicle.hideSearchResult()
                  }

                  if (leftPanel.find('.actions-holder').is(':Visible')) {
                    searchVehicle.actionHolderHide()
                  }
                }

                if (reportHandler.hasActiveReport()) {
                  $(document).triggerHandler('vehicleListChange')
                }
                if (view.getActiveState() === 'activity') {
                  view.switchStateTo('fleet')
                }

                wlsMapTools.toggleMapTools()

                if (settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('no-group-tab') && !leftPanel.find('.incidents').is(':visible')) {
                  leftPanel.removeClass('no-group-tab').addClass('has-group-tab')
                  $('[data-tabset=group]').show(250)
                }
                if (settings.data.htmlGroupTabsAllowed && !leftPanel.find('.incidents').is(':visible')) {
                  $('[data-tabset=group]').show(250)
                  window.groupTabsetView.collection.fetch({
                    success: function () {
                      $.cookie('lastViewedGroup', null) // reset cookie
                      $.cookie('lastViewedGroup', selectedGroup)
                      groupTabsetView.render()
                    }
                  })
                } else if (settings.data.htmlGroupTabsAllowed && leftPanel.find('.incidents').is(':visible')) {
                  $('[data-tabset=group]').hide()
                  leftPanel.removeClass('has-group-tab').addClass('no-group-tab')
                  window.groupTabsetView.collection.fetch({
                    success: function () {
                      $.cookie('lastViewedGroup', null) // reset cookie
                      $.cookie('lastViewedGroup', selectedGroup)
                    }
                  })
                } else if (!settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('no-group-tab')) {
                  $('[data-tabset=group]').hide()
                  window.groupTabsetView.collection.fetch({
                    success: function () {
                      $.cookie('lastViewedGroup', null) // reset cookie
                      $.cookie('lastViewedGroup', selectedGroup)
                      groupTabsetView.render()
                    }
                  })
                  // leftPanel.removeClass("has-group-tab").addClass("no-group-tab");
                  groupTabsetView.vehicleHolderHandler(leftPanel)
                } else if (!settings.data.htmlGroupTabsAllowed && leftPanel.hasClass('has-group-tab')) {
                  $('[data-tabset=group]').hide()
                  window.groupTabsetView.collection.fetch({
                    success: function () {
                      $.cookie('lastViewedGroup', null) // reset cookie
                      $.cookie('lastViewedGroup', selectedGroup)
                      groupTabsetView.render()
                    }
                  })
                  leftPanel.removeClass('has-group-tab').addClass('no-group-tab')
                  groupTabsetView.vehicleHolderHandler(leftPanel)
                }
              })

              setActionMenuVisibility()
            })
          })
        }
        return false
      })
    }
    $('.account-list .group', leftPanel).mousedown(function (e) {
      e.preventDefault()
    })
  }

  if (leftPanel.size()) {
    initSlide()
  }

  function initScroll ($frame, offset) {
    var $wrap = $frame.parent()
    var defH = $frame.height()
    var oldState = ''
    var paddingRight = 0
    // Call Sly on frame
    function slyInit (e, innerFrameH) {
      if ($frame.height() > (innerFrameH || $frame.children().height())) {
        slyDestroy()
        return
      }
      if ($wrap.hasClass('active')) {
        if (e.isTrigger) {
          $frame.one('animationEnd', function () {
            $frame.sly('reload')
          })
        } else {
          $frame.sly('reload')
        }
      } else {
        if (!oldState) oldState = view.state
        $wrap.addClass('active')
        $wrap.attr('style', '')
        paddingRight = parseInt($wrap.css('padding-right'))
        $wrap.removeClass('active')
        $wrap.animate({ 'padding-right': paddingRight }, 100, function () {
          var maxH = parseInt($frame.css('max-height'))

          if (maxH) {
            $frame.height(maxH)
          } else {
            $frame.height(Math.round($(document).height() - ($frame.offset().top + 5)))
          }

          $frame.sly({
            speed: 300,
            easing: 'linear',
            scrollBar: $wrap.find('.scrollbar'),
            scrollBy: 100,
            dragHandle: 1,
            dynamicHandle: 1,
            clickBar: 1
          }, {
            moveStart: function () {
              activeOptionBtn.removeClass('active')
              $('body>.options-drop').css('display', 'none')
            },
            moveEnd: function () {
              if (addInfo && wls.addInformationActive()) {
                wls.calcCoordinates(addInfo, $frame)
              }
              if (editVehRegPopupView.isVisible()) {
                wls.calcCoordinates(editVehRegPopupView, $frame)
              }

              if (editVehDriverPopupView.isVisible()) {
                wls.calcCoordinates(editVehDriverPopupView, $frame)
              }

              if (sendCommandPopupView.isVisible()) {
                wls.calcCoordinates(sendCommandPopupView, $frame)
              }

              if (heartBeatPopupView) {
                heartBeatPopupView.hide()
              }
              if (phoneNumberPopup) {
                phoneNumberPopup.destroy()
              }
            }
          })
          showScrollbar()
        })
      }
    }
    function showScrollbar () {
      $wrap.find('.scrollbar').fadeTo(100, 0.5, function () {
        $wrap.addClass('active')
        if (oldState != view.state) {
          $frame.triggerHandler('resize')
          $frame.triggerHandler('animationEnd')
          oldState = ''
        }
      })
    }
    function slyDestroy () {
      var maxH = $frame.css('max-height')
      $frame.attr('style', '')
      if (maxH) $frame.css('max-height', maxH)
      $frame.sly('toStart')
      $frame.sly('destroy')
      $wrap.removeClass('active')
      $frame.children().attr('style', '')
      $wrap.find('.scrollbar').fadeTo(600, 0, function () {
        $wrap.animate({ 'padding-right': 0 }, 600, function () {
          $(this).attr('style', '')
        })
      })
    }

    function resizeH (e, frameH, scrollType) {
      var maxH = parseInt($frame.css('max-height'))

      if (view.state == 'activity') {
        frameH = frameH || $frame.find('.slidee').height()
      } else {
        frameH = typeof frameH !== 'undefined' ? frameH : $frame.height()
      }
      if (frameH > maxH && (view.state == 'fleet' || view.state == 'dual')) frameH = maxH
      if ($(window).height() - maxH == offset) {
        var scrollContent = leftPanel.find('.vehicle-holder .scroll-content:not(.no-max-height)')
        var controlHolder = $('.control-holder', leftPanel)
        scrollContent.css('max-height', ($(window).height() - offset - (controlHolder.height() + 59)) - 10)
      }
      if (($(window).height() - offset <= $frame.offset().top + frameH) || (view.state == 'activity' && frameH > maxH)) {
        slyInit(e, frameH)
      } else {
        !$wrap.hasClass('active') || slyDestroy()
      }
    }
    $(window).on('resize.sly', resizeH)
    $frame.on('resize', resizeH)
    resizeH()
  }
  function vehicleListMaxH () {
    var scrollContent = leftPanel.find('.vehicle-holder .scroll-content:not(.no-max-height)')
    var controlHolder = $('.control-holder', leftPanel)
    var $activityView = $('.activity-view', leftPanel)
    var offset = 0
    function resizeH (e, getMaxH) {
      // proximity.is(":visible") ? offset = proximity.height() : offset = 0;
      if (view.state == 'activity') {
        offset = $activityView.height()
      } else {
        offset = 0
      }
      if (getMaxH) {
        return ($(window).height() - offset - (controlHolder.height())) - 10
      }
      scrollContent.attr('style', '')
      scrollContent.css('max-height', ($(window).height() - offset - (controlHolder.height())) - 10)
    }
    resizeH()
    $(window).on('resize.maxH', resizeH)
  }
  var RADIUS_OF_EARTH_IN_M = 6378000
  function distanceBetweenCoordinates (lat1, lon1, lat2, lon2) {
    var R = RADIUS_OF_EARTH_IN_M
    var dLat = (lat2 - lat1) * Math.PI / 180
    var dLon = (lon2 - lon1) * Math.PI / 180

    var lat1inRadians = lat1 * Math.PI / 180
    var lat2inRadians = lat2 * Math.PI / 180

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) *
      Math.cos(lat1inRadians) * Math.cos(lat2inRadians)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c

    return d
  }
}

export default initJqueryBackboneApp
