export default function getAreaColorsRGB (areaColorStr, areaColoured, chooseColor, alpha) {
  if (alpha !== undefined) {
    if (!areaColoured) {
      return [255, 255, 255, 0]
    }
    if (!chooseColor) {
      return [0, 128, 0, 0.3 * alpha]
    }
    switch (areaColorStr.toLowerCase()) {
      case 'black':
        return [0, 0, 0, 1.2 * alpha]
      case 'green':
        return [0, 128, 0, 1.2 * alpha]
      case 'blue':
        return [0, 0, 255, 1.2 * alpha]
      case 'purple':
        return [160, 32, 240, 1.2 * alpha]
      case 'orange':
        return [255, 165, 0, 1.2 * alpha]
      case 'yellow':
        return [255, 255, 0, 1.2 * alpha]
      case 'white':
        return [255, 255, 255, 1.2 * alpha]
      case 'red':
        return [255, 0, 0, 1.2 * alpha]
      case 'no fill':
        return [255, 255, 255, alpha]
      default:
        return [0, 128, 0, 1.2 * alpha]
    }
  } else {
    if (!areaColoured) {
      return [255, 255, 255, 0]
    }
    if (!chooseColor) {
      return [0, 128, 0, 0.3]
    }
    switch (areaColorStr.toLowerCase()) {
      case 'black':
        return [0, 0, 0, 1.2]
      case 'green':
        return [0, 128, 0, 1.2]
      case 'blue':
        return [0, 0, 255, 1.2]
      case 'purple':
        return [160, 32, 240, 1.2]
      case 'orange':
        return [255, 165, 0, 1.2]
      case 'yellow':
        return [255, 255, 0, 1.2]
      case 'white':
        return [255, 255, 255, 1.2]
      case 'red':
        return [255, 0, 0, 1.2]
      case 'no fill':
        return [255, 255, 255, 0]
      default:
        return [0, 128, 0, 1.2]
    }
  }
}
