import $ from 'jquery'
import View from './View'

const DropdownTimePickerView = View.extend({
  elSelector: '[data-date-time-picker-range]',
  constructor: function (option) {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find(view.elSelector)
    view.el = view.$el

    var json = view.getJSON()

    $('.flexdatalist').flexdatalist({
      minLength: 0,
      valueProperty: 'value',
      selectionRequired: true,
      visibleProperties: ['value'],
      searchIn: 'value',
      data: json,
      noResultsText: ''
    })
    /*
                $('#fromTime-flexdatalist').on('keyup', (function() {
                    console.log($('#fromTime-flexdatalist').val())
                    $('#fromTime').val($('#fromTime-flexdatalist').val())
                }))
    */
    view.today()

    view.initForDatePicker()
    view.insertDatepickerTrigger()

    View.apply(view, arguments)
  },
  insertDatepickerTrigger: function () {
    var view = this

    view.$('[data-input=date]').each(function () {
      $(this).closest('.date').find('.calendar-btn').append($(this).next('button'))
    })
  },
  initForDatePicker: function () {
    var view = this

    view.$('[data-input=date]').datepicker({
      showOn: 'button',
      buttonImage: '',
      beforeShow: function (input, inst) {
        $(this).closest('.date').find('.calendar-btn').addClass('active')
      },
      onClose: function (selectedDate) {
        view.$('[data-input=to]').datepicker('option', 'minDate', selectedDate)
        view.insertDatepickerTrigger()
        $(this).closest('.date').find('.calendar-btn').removeClass('active')
      },
      onSelect: function () {
        $(this).closest('.report-wrap').find('.button-list .button.active').removeClass('active')
      }
    })
  },
  getJSON: function () {
    return [
      { value: '00:00' }, { value: '00:30' },
      { value: '01:00' }, { value: '01:30' },
      { value: '02:00' }, { value: '02:30' },
      { value: '03:00' }, { value: '03:30' },
      { value: '04:00' }, { value: '04:30' },
      { value: '05:00' }, { value: '05:30' },
      { value: '06:00' }, { value: '06:30' },
      { value: '07:00' }, { value: '07:30' },
      { value: '08:00' }, { value: '08:30' },
      { value: '09:00' }, { value: '09:30' },
      { value: '10:00' }, { value: '10:30' },
      { value: '11:00' }, { value: '11:30' },
      { value: '12:00' }, { value: '12:30' },
      { value: '13:00' }, { value: '13:30' },
      { value: '14:00' }, { value: '14:30' },
      { value: '15:00' }, { value: '15:30' },
      { value: '16:00' }, { value: '16:30' },
      { value: '17:00' }, { value: '17:30' },
      { value: '18:00' }, { value: '18:30' },
      { value: '19:00' }, { value: '19:30' },
      { value: '20:00' }, { value: '20:30' },
      { value: '21:00' }, { value: '21:30' },
      { value: '22:00' }, { value: '22:30' },
      { value: '23:00' }, { value: '23:30' }
    ]
  },
  today: function () {
    var view = this
    var today = new Date()

    view.$('[data-input=date]').datepicker('setDate', today)

    today.setHours(0, 0)
    view.$('#fromTime-flexdatalist').datetimepicker('setDate', today)
    today.setHours(23, 59)
    view.$('#toTime-flexdatalist').datetimepicker('setDate', today)
    view.insertDatepickerTrigger()
  },
  destroy: function () {
    this.remove()
  }
})

export default DropdownTimePickerView
