import $ from 'jquery'
import JqueryUiWidgetView from './JqueryUiWidget'
import SelectModel from '../model/Select'
import SelectScrollView from './SelectScroll'

const SelectView = JqueryUiWidgetView.extend({
  model: SelectModel,
  mergeEvents: true,
  events: {
    selectmenuopen: 'selectmenuopenHandler'
  },
  selectmenuopenHandler: function () {
    if (!this.hasSubView('scroll')) {
      this.renderScroll()
    }
  },
  renderScroll: function () {
    var view = this
    var $selectMenuDrop = view.$el.selectmenu('widget').next()
    var $scroll = $('<div class="scroll-frame">' +
      '<div class="scrollbar vertical">' +
      '<div class="handle">' +
      '<div class="mousearea"></div>' +
      '<div class="bg"></div>' +
      '</div>' +
      '</div>' +
      '<div class="scroll-content">' +
      '<div class="slidee"></div>' +
      '</div>' +
      '</div>')

    $selectMenuDrop.append($scroll)
    $scroll.find('.slidee').append($selectMenuDrop.find('ul'))
    view.addSubView('scroll', SelectScrollView, { el: $scroll }).render()
  },
  destroy: function () {
    this.remove()
  }
})

export default SelectView
