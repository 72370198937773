import LWJourneyReportPopupView from './LWJourneyReportPopup'
import LWJourneyReportSelectionMenuView from './LWJourneyReportSelectionMenu'
import ReportPageView from '../ReportPage'
import LWJourneyReportView from './LWJourneyReport'

const LWJourneyReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="loneWorkerJourney"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', LWJourneyReportPopupView)
    view.addSubView('selectionMenu', LWJourneyReportSelectionMenuView)
    view.addSubView('report', LWJourneyReportView)
    view.render()
  }
})

export default LWJourneyReportPageView
