import $ from 'jquery'
import CompactUnitViewReportFormView from '../CompactUnitViewReportForm'
import VehicleDefectReportFormModel from '../../../model/report/vehicleDefectReport/VehicleDefectReportForm'

const VehicleDefectReportFormView = CompactUnitViewReportFormView.extend({
  model: VehicleDefectReportFormModel,
  generatePdfXlsReportURL: function (reportFormat) { // implementation
    var model = this.model
    var data = {
      'report-name': 'VehicleDefectReport',
      'report-type': 'VehicleDefectReport',
      'report-format': reportFormat,
      from: model.get('from'),
      to: model.get('to'),
      unit_view_id: $.cookie('lastViewedGroup')
    }
    return $.param(data)
  },
  submit: function () { // override
    var view = this
    var compactReportTableGridView = view.getParentView().findSubView('compactReportTableGrid')

    compactReportTableGridView.hideActivePopup()

    CompactUnitViewReportFormView.prototype.submit.apply(this, arguments)
  }
})

export default VehicleDefectReportFormView
