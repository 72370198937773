import ReportFormModel from '../ReportForm'

const SpeedingReportFormModel = ReportFormModel.extend({
  defaults: {
    percentSpeedCriteria: null,
    mileSpeedCriteria: null,
    operation: ''
  }
})

export default SpeedingReportFormModel
