import $ from 'jquery'
import PopupView from '../Popup'
import LoneWorkerActivityLogFormView from './LoneWorkerActivityLogForm'
import LoneWorkerActivityLogTableGridView from './LoneWorkerActivityLogTableGrid'
import LoneWorkerActivityLogPopupModel from '../../model/loneWorker/LoneWorkerActivityLogPopup'

const LoneWorkerActivityLogPopupView = PopupView.extend({
  templateUrl: 'template/loneWorker/activityLogPopup.html',
  url: 'js/html/app/src/template/loneWorker/activityLogPopup.html',
  model: LoneWorkerActivityLogPopupModel,
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'closeBtnHandler',
    'click [data-button=save]': 'submit',
    'click .plot-all :checkbox': 'plotAll',
    'click .max-min_btn': 'maxMin'
  },

  initialize: function (closeCallback) {
    var view = this

    this.plotModeOn = false
    if (closeCallback) {
      this.closeCallback = closeCallback
    }

    $('#content').append(view.$el)
    this.somaTemplate = view.$el.html()
    this.createTemplate(view.el)

    // set $.cookie('timestamp', Date.now())
    // get $.cookie('lastViewedGroup')

    view.addSubView('compactReportTableGrid', LoneWorkerActivityLogTableGridView, this.$('[data-table-grid=loneWorkerActivityLog]')).once('ready', function () {
      view.addSubView('activityLogForm', LoneWorkerActivityLogFormView)
    })

    this.render({
      description: window.wls.getLastClickedVehicle().data.description
    })
  },
  closeBtnHandler: function () { // override
    console.log('LoneWorkerActivityLogPopup: closeBtnHandler()')
    var view = this

    this.clearMap()

    view.findSubView('compactReportTableGrid').destroy()
    view.remove()
    window.wls.isActivityLogsOnMap = false
    if (this.closeCallback) {
      this.closeCallback()
    }
  },
  maxMin: function () {
    var view = this

    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.$el.animate({ top: view.model.get('position').top, left: view.model.get('position').left }, 300)
      view.maximiseIncidentDetails()
    } else {
      view.model.set('position', { top: view.$el[0].offsetTop, left: view.$el[0].offsetLeft })
      view.minimiseIncidentDetails()
      view.$el.animate({ top: window.innerHeight * 0.96 + 'px', left: '260px' }, 300)
    }
  },
  maximiseIncidentDetails: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.find('.info-holder').show()
    view.$el.find('.control-holder').show()
    view.$el.css('height', 'auto')
  },
  minimiseIncidentDetails: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.find('.info-holder').hide()
    view.$el.find('.control-holder').hide()
    view.$el.css('height', 'auto')
  },
  destroy: function () {
    var view = this
    this.clearMap()
    view.remove()
  },

  isVisible: function () {
    return this.$el && this.$el.is(':visible')
  },

  render: function (json) {
    if (json) {
      this.somaTemplate.scope.json = json
      this.somaTemplate.render()
      this.model.set('visible', true)
    } else {
      PopupView.prototype.render.apply(this, arguments)
    }
    return this
  },
  plotAll: function () {
    var view = this
    if (this.plotModeOn) {
      view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
      view.findSubView('compactReportTableGrid').logsOnMap = []
      view.findSubView('compactReportTableGrid').markAllActive(false)
      this.plotModeOn = false
    } else {
      view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
      view.findSubView('compactReportTableGrid').plotAll()
      view.findSubView('compactReportTableGrid').markAllActive(true)
      this.plotModeOn = true
    }
  },

  clearMap: function () {
    var view = this
    view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
  },

  setPlotAllActive: function (active) {
    var view = this

    if (!active) {
      view.$('.plot-all').addClass('disabled')
    } else {
      if (active.length > 0) {
        view.$('.plot-all').removeClass('disabled')
      } else {
        view.$('.plot-all').addClass('disabled')
      }
    }
  }
})

export default LoneWorkerActivityLogPopupView
