/**
 * Created by George on 12.08.2017.
 */
import AlertTableGrid from '../AlertTableGrid';
import OverspeedAlertList from '../../../collection/alert/overspeed/OverspeedAlertList';

const OverspeedAlertTableGrid = AlertTableGrid.extend({
    defaults: {
        tableRow: OverspeedAlertList
    }
});

export default OverspeedAlertTableGrid;
