import SelectionMenuVehicleListView from '../../SelectionMenuVehicleList'
import SpeedingReportSelectionMenuVehicleList from '../../../collection/report/speedingReport/SpeedingReportSelectionMenuVehicleList'
import SpeedingReportSelectionMenuVehicleListScrollView from './SpeedingReportSelectionMenuVehicleListScroll'

const SpeedingReportSelectionMenuVehicleListView = SelectionMenuVehicleListView.extend({
  collection: SpeedingReportSelectionMenuVehicleList,
  templateUrl: 'template/report/speedingReport/speedingReportSelectionMenuVehicleList.html',
  elSelector: '[data-vehicle-list=speeding]',
  initialize: function () {
    var view = this

    view.addSubView('scroll', SpeedingReportSelectionMenuVehicleListScrollView)
    view.render()
  }
})

export default SpeedingReportSelectionMenuVehicleListView
