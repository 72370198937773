import View from '../../View'
import TowAwaySelectRecipientTableGridView from './TowAwaySelectRecipientTableGrid'
import TowAwayAlertAddRecipientPopupView from './TowAwayAlertAddRecipientPopup'

const TowAwayAlertSelectRecipientView = View.extend({
  addRecipientPopupView: null,
  recipientTableGridView: null,
  events: {
    'click [data-button=addRecipient]': 'beforeShowRecipientPopup'
  },
  constructor: function () {
    var view = this
    view.$el = this.getParentView().$el.find('[data-tab=selectRecipient]')
    view.el = view.$el[0]
    view.setSubView({
      addRecipientPopup: TowAwayAlertAddRecipientPopupView,
      selectRecipientTableGrid: TowAwaySelectRecipientTableGridView
    })
    View.apply(this, arguments)
  },
  beforeShowRecipientPopup: function () {
    var addRecipientPopupView = this.findSubView('addRecipientPopup')
    this.findSubView('selectRecipientTableGrid').resetSelectedItemAndRow()
    if (addRecipientPopupView.model.get('htmlReady')) {
      addRecipientPopupView.model.set('dataMode', 'create')
      this.alignRecipientPopup()
      this.showRecipientPopup()
    }
  },
  showRecipientPopup: function () {
    this.findSubView('addRecipientPopup').model.set('visible', true)
  },
  alignRecipientPopup: function () {
    this.findSubView('addRecipientPopup').alignBy(this.$el.find('[data-button=addRecipient]'), 'center', { top: 5, left: 0 })
  }
})

export default TowAwayAlertSelectRecipientView
