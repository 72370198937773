import $ from 'jquery'
import _ from 'underscore'
import View from './View'
import SelectView from './Select'

var deviceOnlineMessage = 'The camera is online, we\'re getting your requested video. \nYou can wait here or close the window. We\'ll E-mail you as soon as your video is available to watch.\n'
var deviceOfflineMessage = 'The camera is offline, we\'ve saved your request, and as soon as the camera comes back online we\'ll request your video. You can wait here or close the window. We’ll E-mail you as soon as your video is available to watch.'
const VideoPlayerView = View.extend({
  events: {
    'click .vp-stop-btn': 'onPauseClick',
    'click .vp-play-btn': 'onPlayClick',
    'click .vp-fullscreen-btn': 'toggleFullScreen'
  },
  constructor: function (option) {
    this.el = option.el
    this.$el = option.$el
    this.isPausedBeforeSeeking = false

    View.apply(this, arguments)
  },
  initialize: function () {
    var view = this
    var $video = this.$el.find('video')
    var $selectmenu = this.$el.find('.select-holder select')

    this.addSubView('select', SelectView, { el: this.$el.find('.select-holder select') })
    $selectmenu.selectmenu('instance')._renderItem = function (ul, item) {
      var ico = $('<span class="camera-ico"></span>')
      var li = $('<li>')
      var data = JSON.parse(item.value)
      let icoClass = ''

      switch (data.videoStatus) {
        case -1:
          icoClass = 'no-video'
          break
        case 0:
          icoClass = 'video-download'
          break
        case 1:
          icoClass = 'video-uploading'
          break
        case 2:
          icoClass = 'video-uploaded'
          break
      }

      this._setText(li, item.label)
      ico.prependTo(li)
      ico.addClass(icoClass)
      if (item.disabled) {
        li.addClass('ui-state-disabled')
      }

      return li.appendTo(ul)
    }
    $selectmenu.on('selectmenuopen', function (event, ui) {
      view.parentView.$el.css('overflow', 'visible')
    })
    $selectmenu.on('selectmenuselect', function (event, ui) {
      var data = JSON.parse(ui.item.value)
      view.setProp('src', data.videoUrl)
      if (data.videoStatus === 1) {
        view.renderMessage()
      } else if (data.videoStatus === 0) {
        view.parentView.showGetMorePopup()
      }
      switch (data.videoStatus) {
        case 0:
          view.parentView.showGetMorePopup()
          break
        case 1:
          view.renderMessage()
          break
        case 2:
          view.hideMessage()
          break
      }

      view.reset()
    })
    this.$el.find('.select-holder').hover(function () {

    }, function () {
      view.parentView.$el.css('overflow', 'hidden')
      $selectmenu.selectmenu('close')
    })
    this.$el.find('.vp-volume-slider').slider({
      range: 'min',
      min: 0,
      max: 100,
      value: 50,
      slide: function (e, ui) {
        $video.prop('volume', ui.value / 100)
      }
    })
    $video.on('play', $.proxy(this.onPlay, this))
    $video.on('pause', $.proxy(this.onPause, this))
    $video.on('progress', $.proxy(this.onProgress, this))
    $video.on('timeupdate', $.proxy(this.updateTime, this))
    $video.on('loadedmetadata', $.proxy(this.loadedMetadata, this))
    $video.on('ended', $.proxy(this.onVideoEnded, this))
  },
  updateTime: function () {
    var video = this.$el.find('video')[0]
    var $timeSlider = this.$el.find('.vp-progress-line-container')

    this.$el.find('.vp-time-tracker span:first').text(this.formatSecondsToMS(video.currentTime))
    $timeSlider.slider('option', 'value', video.currentTime / video.duration * $timeSlider.slider('option', 'max'))
  },
  toggleFullScreen: function () {
    var HTML = this.el
    if (!document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) { // current working methods
      if (HTML.requestFullscreen) {
        HTML.requestFullscreen()
      } else if (HTML.msRequestFullscreen) {
        HTML.msRequestFullscreen()
      } else if (HTML.mozRequestFullScreen) {
        HTML.mozRequestFullScreen()
      } else if (HTML.webkitRequestFullscreen) {
        HTML.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
      this.$el.addClass('fullscreen-active')
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
      this.$el.removeClass('fullscreen-active')
    }
  },
  formatSecondsToMS: function (duration) {
    var seconds = duration % 60
    var minutes = duration / 60
    seconds = Math.floor(seconds)
    minutes = Math.floor(minutes)
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return minutes + ':' + seconds
  },
  loadedMetadata: function () {
    var view = this
    var $video = this.$el.find('video')
    var video = $video[0]
    var videoDuration = video.duration
    var $timeSlider = this.$el.find('.vp-progress-line-container')

    this.$el.find('.vp-time-tracker span:last').text(this.formatSecondsToMS(videoDuration))
    $timeSlider.slider({
      range: 'min',
      min: 0,
      max: videoDuration * 1000,
      value: 0,
      start: function (e, ui) {
        view.isPausedBeforeSeeking = video.paused
        video.pause()
        $(document).one('mouseup', function () {
          if (!view.isPausedBeforeSeeking && !video.ended) {
            view.isPausedBeforeSeeking = null
            view.play()
          }
        })
      },
      slide: function (e, ui) {
        video.currentTime = ui.value / $timeSlider.slider('option', 'max') * videoDuration
        view.updateTime()
      }
    })
  },
  play: function () {
    this.$el.find('video')[0].play()
  },
  pause: function () {
    this.$el.find('video')[0].pause()
  },
  onPlay: function () {
    var video = this.$el.find('video')[0]

    if (video.ended) {
      video.currentTime = 0
    }
    this.$el.find('.vp-play-btn').addClass('vp-stop-btn').removeClass('vp-play-btn')
    this.play()
  },
  onPause: function () {
    // if(this.isPausedBeforeSeeking === false) return
    this.$el.find('.vp-stop-btn').addClass('vp-play-btn').removeClass('vp-stop-btn')
  },
  onPlayClick: function () {
    this.play()
  },
  onPauseClick: function () {
    this.pause()
  },
  onProgress: function () {
    var video = this.$el.find('video')[0]
    var videoDuration = video.duration
    var percentage = (video.buffered.end(video.buffered.length - 1) / videoDuration * 100)
    // $bufferedProgress.css('width', percentage + '%')
    if (percentage === 100) {
      this.$el.find('video').off('progress')
    }
  },
  onVideoEnded: function () {
    this.$el.find('.vp-stop-btn').addClass('vp-play-btn').removeClass('vp-stop-btn')
  },
  setProp: function (key, value) {
    this.$el.find('video').prop(key, value)
  },
  setProps: function (props, selectedChannelNumber) {
    this.props = props
    var videoUrl = props.channels[0].videoUrl
    var $select = null

    if (selectedChannelNumber) {
      var selectedChannel = _.find(props.channels, function (channel) {
        return channel.channelNumber === selectedChannelNumber
      })
      videoUrl = selectedChannel.videoUrl
    }

    this.setProp('src', videoUrl)
    this.setProp('autoplay', true)
    this.setProp('loop', true)
    this.$el.find('.vp-download-btn a').attr('href', videoUrl)
    $select = this.renderSelect(props.channels, selectedChannelNumber)
    if (this.isVideoStatusUploading($select.val())) {
      this.renderMessage()
    }
  },
  renderSelect: function (channels, selectedChannelNumber) {
    var $select = this.$el.find('.select-holder select')
    var options = ''

    _.each(channels, function (channel) {
      var data = JSON.stringify({
        videoStatus: channel.videoStatus,
        videoUrl: channel.videoUrl
      })
      var isSelected = selectedChannelNumber && selectedChannelNumber === channel.channelNumber
      var selected = isSelected ? 'selected' : ''
      var disabled = channel.videoStatus === -1 ? 'disabled' : ''

      options += '<option ' + disabled + ' ' + selected + ' value=' + data + '>' + channel.name + '</option>'
    })
    $select.html(options)
    $select.selectmenu('refresh')

    return $select
  },
  renderMessage: function () {
    var ignitionStatus = this.getSelectedVehicleIgnitionStatus(this.props.vehicleId)
    var $messageEl = this.$el.find('.video-player-message')
    var message = ignitionStatus === 'On' || ignitionStatus === 'Idling' ? deviceOnlineMessage : deviceOfflineMessage

    $messageEl.show()
    $messageEl.find('span').text(message)
    this.$el.find('.player-control > div:not(.select-holder)').hide()
  },
  hideMessage: function () {
    this.$el.find('.video-player-message').hide()
    this.$el.find('.player-control > div:not(.select-holder)').show()
  },
  isVideoStatusUploading: function (json) {
    return JSON.parse(json).videoStatus === 1
  },
  getSelectedVehicleIgnitionStatus: function (vehicleId) {
    return window.wls.getVehicle(vehicleId).data.ignitionStatus
  },
  reset: function () {
    this.onPause()
  }
})

export default VideoPlayerView
