import PopupModel from '../Popup'

const ActivityLogPopupModel = PopupModel.extend({
  defaults: {
    registrationNumber: '',
    isDraggable: true,
    position: {},
    extended: 'with-din'

  }
})

export default ActivityLogPopupModel
