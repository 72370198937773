import $ from 'jquery'
import View from './View'

const DatepickerRangeView = View.extend({
  events: {
    'click [data-button=today]': 'today',
    'click [data-button=yesterday]': 'yesterday',
    'click [data-button=lastWeek]': 'lastWeek',
    'click [data-button=previousMonth]': 'previousMonth'
  },
  initialize: function (option) {
    var view = this

    view.initFromDatepicker()
    view.initToDatepicker()
    view.insertDatepickerTrigger()
    view.today()
  },
  insertDatepickerTrigger: function () {
    var view = this

    view.$('[data-input=from],[data-input=to]').each(function () {
      $(this).closest('.date').find('.calendar-btn').append($(this).next('button'))
    })
  },
  initFromDatepicker: function () {
    var view = this

    view.$('[data-input=from]').datepicker({
      showOn: 'button',
      buttonImage: '',
      beforeShow: function (input, inst) {
        $(this).closest('.date').find('.calendar-btn').addClass('active')
      },
      onClose: function (selectedDate) {
        view.$('[data-input=to]').datepicker('option', 'minDate', selectedDate)
        view.insertDatepickerTrigger()
        $(this).closest('.date').find('.calendar-btn').removeClass('active')
      },
      onSelect: function () {
        $(this).closest('.report-wrap').find('.button-list .button.active').removeClass('active')
      }
    })
  },
  initToDatepicker: function () {
    var view = this

    view.$('[data-input=to]').datepicker({
      showOn: 'button',
      buttonImage: '',
      beforeShow: function (input, inst) {
        $(this).closest('.date').find('.calendar-btn').addClass('active')
      },
      onClose: function (selectedDate) {
        view.$('[data-input=from]').datepicker('option', 'maxDate', selectedDate)
        view.insertDatepickerTrigger()
        $(this).closest('.date').find('.calendar-btn').removeClass('active')
      },
      onSelect: function () {
        $(this).closest('.report-wrap').find('.button-list .button.active').removeClass('active')
      }
    })
  },
  switchActiveButton: function ($button) {
    if ($button.hasClass('active')) {
      $button.removeClass('active')
      return
    }
    $button.addClass('active').parent().siblings().find('.button').removeClass('active')
  },
  resetMinMaxDate: function () {
    var view = this

    view.$('[data-input=to]').datepicker('option', 'maxDate', null)
    view.$('[data-input=to]').datepicker('option', 'minDate', null)
    view.$('[data-input=from]').datepicker('option', 'minDate', null)
    view.$('[data-input=from]').datepicker('option', 'maxDate', null)
  },
  resetTime: function () {
    var view = this

    view.$('[data-input=fromTime]').val('00:00')
    view.$('[data-input=toTime]').val('23:59')
  },
  resetDateAndTime: function (e) {
    var view = this

    view.resetMinMaxDate()
    view.resetTime()
  },
  today: function () {
    var view = this

    view.resetDateAndTime()
    view.switchActiveButton(view.$('[data-button=today]'))
    view.$('[data-input=from]').datepicker('setDate', '+0d')
    view.$('[data-input=to]').datepicker('setDate', '+0d')
    view.insertDatepickerTrigger()
  },
  yesterday: function () {
    var view = this

    view.resetDateAndTime()
    view.switchActiveButton(view.$('[data-button=yesterday]'))
    view.$('[data-input=from]').datepicker('setDate', '-1d')
    view.$('[data-input=to]').datepicker('setDate', '-1d')
    view.insertDatepickerTrigger()
  },
  lastWeek: function () {
    var view = this
    var date = new Date()

    view.resetDateAndTime()
    view.switchActiveButton(view.$('[data-button=lastWeek]'))
    date.setDate(date.getDate() - (date.getDay() + 6))
    view.$('[data-input=from]').datepicker('setDate', date)
    date.setDate(date.getDate() + 6)
    view.$('[data-input=to]').datepicker('setDate', date)
    view.insertDatepickerTrigger()
  },
  previousMonth: function () {
    var view = this
    var date = new Date()

    view.resetDateAndTime()
    view.switchActiveButton(view.$('[data-button=previousMonth]'))
    date.setDate(0)
    view.$('[data-input=to]').datepicker('setDate', date)
    date.setDate(1)
    view.$('[data-input=from]').datepicker('setDate', date)
    view.insertDatepickerTrigger()
  },
  destroy: function () {
    this.remove()
  }
})

export default DatepickerRangeView
