import $ from 'jquery'
import View from '../../View'

const LeagueTableDateListView = View.extend({
  templateUrl: 'template/report/leagueTable/dateList.html',
  json: [],
  constructor: function (option) {
    var view = this
    var args = arguments

    this.el = option.el
    this.$el = $(this.el)
    this.json = this.renderJSON()

    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, args)
    })
  },
  renderJSON: function () {
    var dateSelect = []
    var date = new Date()
    var year = date.getFullYear()
    var month = date.getMonth()
    var monthName = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]

    for (var i = 12; i > 0; i--) {
      dateSelect.push({ val: year + ' ' + month, label: '' + monthName[month] + ' ' + year })

      month -= 1

      if (month < 0) {
        year -= 1
        month = 11
      }
    }

    return dateSelect
  },
  initialize: function () {
    var view = this
  },
  render: function () {
    var json = this.json
    var html = this.dotTemplateToHtml(json)
    var $select = this.getParentView().$el

    this.$el.html(html)

    $select.selectmenu().val(json[0].val)
    $select.selectmenu('refresh')
  }
})

export default LeagueTableDateListView
