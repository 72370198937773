/**
 * Created by George on 16.07.2017.
 */
import Model from '../../Model';

const OverspeedSelectedVehicles = Model.extend({
    url: function () {
        const id = this.get('id') || '';
        return '/services/overspeed/selected-vehicles/' + id;
    }
});

export default OverspeedSelectedVehicles;
