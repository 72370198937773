import ReportSelectionMenuView from '../../ReportSelectionMenu'
import IdlingReportFuelUsageSelectionMenuModel from '../../../model/report/idlingReportFuelUsage/IdlingReportFuelUsageSelectionMenu'
import IdlingReportFuelUsageSelectionMenuVehicleListView from './IdlingReportFuelUsageSelectionMenuVehicleList'

const IdlingReportFuelUsageSelectionMenuView = ReportSelectionMenuView.extend({
  model: IdlingReportFuelUsageSelectionMenuModel,
  templateUrl: 'template/report/idlingReportFuelUsage/idlingReportFuelUsageSelectionMenu.html',
  elSelector: '[data-selection-menu=idlingReportFuelUsage]',
  initialize: function () {
    var view = this

    view.addSubView('vehicleList', IdlingReportFuelUsageSelectionMenuVehicleListView)
    view.render()
  }
})

export default IdlingReportFuelUsageSelectionMenuView
