import $ from 'jquery'
import ReportFormView from '../ReportForm'
import IdlingReportFuelUsageFormModel from '../../../model/report/idlingReportFuelUsage/IdlingReportFuelUsageForm'
import SelectView from '../../Select'
import CounterView from '../../Counter'
import SafeInputView from '../../SafeInput'

const IdlingReportFuelUsageFormView = ReportFormView.extend({
  model: IdlingReportFuelUsageFormModel,
  initialize: function (option) {
    var view = this

    view.addSubView('select', SelectView, { el: view.$('[data-select=operation]') })
    view.addSubView('litersCounter', CounterView, { el: view.$('[data-counter=liters]') })
    view.addSubView('idlingTimeCounter', CounterView, { el: view.$('[data-counter=idlingTime]') })
    view.addSubView('idlingTimeSafeInput', SafeInputView, { el: view.$('[data-counter=idlingTime] input') }).model.set('allowedChars', ['digits', 'edit', 'navigation'])
    view.addSubView('litersSafeInput', SafeInputView, { el: view.$('[data-counter=liters] input') }).model.set('allowedChars', ['digits', 'edit', 'navigation'])
  },
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'IdlingReportFuelUsage',
      'report-type': 'IdlingReportFuelUsage',
      'report-format': model.get('repFormat'),
      alternate: false,
      excludeWeekends: model.get('excludeWeekends'),
      driverFilter: model.get('groupJourneysByDriver'),
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/'),
      idlingTimeGreaterThan: model.get('idlingTimeGreaterThan'),
      litersUsedGreaterThan: model.get('litersUsedGreaterThan'),
      operation: model.get('operation')
    }
    return $.param(data)
  },
  destroy: function () {
    var view = this

    view.findSubView('select').destroy()
    view.findSubView('litersCounter').destroy()
    view.findSubView('idlingTimeCounter').destroy()
    ReportFormView.prototype.destroy.apply(this, arguments)
  }
})

export default IdlingReportFuelUsageFormView
