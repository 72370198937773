import View from '../View'
import GroupColorPickerFormView from './GroupColorPickerForm'

const GroupColorPickerView = View.extend({
  elSelector: '[data-dropdown=colorPicker]',
  events: {
    'click [data-button=close]': 'close'
  },
  initialize: function () {
    this.resetElement()
    this.addSubView('form', GroupColorPickerFormView)
  },
  close: function (e) {
    this.hide()
  },
  show: function (top, left) {
    this.$el.css({ top: top, left: left }).show()
  },
  hide: function () {
    this.$el.hide()
  },
  destroy: function () {
    this.remove()
  }
})

export default GroupColorPickerView
