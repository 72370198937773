import Model from './Model'

const SelectionMenuUserModel = Model.extend({
  defaults: {
    batteryLevel: null,
    clientDescr: '',
    clientInfo: '',
    curAddress: null,
    description: '',
    digitalInput1Info: '',
    digitalInput2Info: '',
    digitalInput3Info: '',
    digitalInput4Info: '',
    directionOfTravel: 'n',
    distanceToRoad: null,
    factoredSpeed: null,
    id: null,
    ignitionActive: false,
    imageFileName: '',
    infoLine1: '',
    infoLine2: null,
    lat: null,
    lon: null,
    renewalDate: null,
    state: '',
    stateLabel: '',
    typeDescr: ''
  }
})

export default SelectionMenuUserModel
