import $ from 'jquery'
import ReportFormView from '../ReportForm'
import OutOfHoursQueryReportFormModel from '../../../model/report/outOfHoursQuery/OutOfHoursQueryReportForm'
import OutOfHoursProfileDropdownView from '../OutOfHoursProfileDropdown'

const OutOfHoursQueryReportFormView = ReportFormView.extend({
  model: OutOfHoursQueryReportFormModel,
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form:first')
    view.el = view.$el[0]

    view.addSubView('outOfHoursProfileDropDown', OutOfHoursProfileDropdownView, { el: view.$('.select-holder.aoi-poi') })

    ReportFormView.apply(this, arguments)
  },
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'Out_Of_Hours_Query',
      'report-type': 'Out_Of_Hours_Query',
      'report-format': model.get('repFormat'),
      alternate: false,
      outOfHoursProfileId: model.get('outOfHoursProfileId'),
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/')
    }
    return $.param(data)
  }
})

export default OutOfHoursQueryReportFormView
