import $ from 'jquery'
import View from './View'
import LocationSearchModel from '../model/LocationSearch'

const LocationSearchView = View.extend({
  model: LocationSearchModel,
  elSelector: '.location-search',
  view: null,
  constructor: function (option) {
    var view = LocationSearchView.prototype.view = this
    view.model = new view.model()
    view.$el = view.getParentView().$(view.elSelector)
    // view.listenTo(view.model,'change',view.render)
    View.apply(this, arguments)
  },
  initialize: function () {
    var view = this
    var $el = this.$el

    var searchInput = $el.find('.proximity-search')
    var searchButton = $el.find('.button')
    var formDropdown = $el.find('.dropdown')
    var mapCanvas = window.mapCanvas

    searchButton.click(function (e) {
      e.preventDefault()
      if ($.trim(searchInput.val())) {
        mapCanvas.geocode(searchInput.val(), view.geocodeCallback)
      }
    })

    $(document).off('click.search').on('click.search', function (e) {
      if (!$(e.target).closest('.search').size()) {
        formDropdown.hide()
      }
    })

    this.render()
  },

  geocodeCallback: function (result) {
    var view = LocationSearchView.prototype.view
    var formDropdown = view.$el.find('.dropdown')
    var len = result.length
    var options = ''

    if (!result || len === 0) {
      alert('There were no locations found')
      return
    }
    //            pv.resetPin()
    for (var i = 0; i < len; i++) {
      options += '<li data-id="' + i + '">' + result[i].description + '</li>'
    }
    options = $(options)
    formDropdown.find('ul').html(options)
    formDropdown.width(formDropdown.parent().outerWidth())
    formDropdown.show()

    function clickHandler (e) {
      formDropdown.parent().find('input:text').val(e.data.markerData.description)
      formDropdown.hide()
      if (typeof LocationSearchView.prototype.getParentView().handlePostcodeSearch === 'function') {
        LocationSearchView.prototype.getParentView().handlePostcodeSearch(e)
      }
    }

    for (var i = 0; i < len; i++) {
      options.eq(i).on('click', { markerData: result[options[i].getAttribute('data-id')] }, clickHandler)
    }
    options.removeAttr('data-id')
  }

})

export default LocationSearchView
