
import Model from '../Model'
import Validator from '../../util/Validator'

const EditVehRegModel = Model.extend({
    defaults: {
        currentVehReg: '',
        newVehReg: ''
    },
    validate: function(attrs){
        if (attrs.newVehReg.trim() === '') {
            return 'Please enter Vehicle Registration Number.'
        }
        if(!Validator.checkValidity('vehReg', attrs.newVehReg, true)){
            return 'Please enter Vehicle Registration Number in correct format.'
        }
    },
    urlRoot: 'api/selection-menu/action/edit-vehicle/reg-number'
})

export default EditVehRegModel
