import CompactReportTableDropView from './CompactReportTableDrop'

const CompactReportComplexHeaderTableDropView = CompactReportTableDropView.extend({
  saveFilter: function (filterData, $activeTh, index) {
    var thIndex = index || $activeTh.closest('table').find('th').index($activeTh)

    this.filterData[thIndex] = filterData
  },
  clearFilterData: function ($activeTh, index) {
    var thIndex = index || $activeTh.closest('table').find('th').index($activeTh)

    if (this.filterData.hasOwnProperty(thIndex)) {
      delete this.filterData[thIndex]
    }
  }
})

export default CompactReportComplexHeaderTableDropView
