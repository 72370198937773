import JourneyReportPopupView from './JourneyReportPopup'
import JourneyReportSelectionMenuView from './JourneyReportSelectionMenu'
import ReportPageView from '../ReportPage'
import JourneyReportView from './JourneyReport'

const JourneyReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="journey"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', JourneyReportPopupView)
    view.addSubView('selectionMenu', JourneyReportSelectionMenuView)
    view.addSubView('report', JourneyReportView)
    view.render()
  }
})

export default JourneyReportPageView
