import $ from 'jquery'
import FormView from './Form'
import CommentModel from '../model/Comment'

const CommentFormView = FormView.extend({
  model: CommentModel,
  elSelector: '[data-form]',
  mergeEvents: true,
  events: {
    'keypress [data-count-input]': 'checkCharLimit',
    'paste [data-count-input]': 'paste',
    'keyup [data-count-input]': 'countChars'
  },
  constructor: function () {
    var view = this
    var parentView = view.getParentView()
    if (parentView) {
      view.$el = parentView.$(view.elSelector)
    } else {
      view.$el = $(view.elSelector)
    }
    view.el = view.$el[0]

    FormView.apply(this, arguments)
  },
  resetTextArea: function () {
    this.clearText()
    this.resetCounter()
  },
  clearText: function () {
    this.$('textarea').val('')
  },
  resetCounter: function () {
    this.$('[data-count-display]').text(0)
  },
  paste: function (e) {
    var view = this
    var input = e.currentTarget
    var clipboardValue = (e.originalEvent || e).clipboardData.getData('text/plain')
    var maxChars = Number(view.$('[data-max-chars]').attr('data-max-chars'))
    var nextValue = input.value + clipboardValue

    if (nextValue.length > maxChars) {
      nextValue = nextValue.substring(0, maxChars)
      input.value = nextValue
      e.preventDefault()
    }
  },
  checkCharLimit: function (e) {
    var view = this
    var input = e.currentTarget
    var keyCode = e.keyCode
    var maxChars = Number(view.$('[data-max-chars]').attr('data-max-chars'))
    var nextCount

    nextCount = (input.value + String.fromCharCode(e.charCode)).length
    if (nextCount > maxChars) {
      e.preventDefault()
    }
  },
  countChars: function (e) {
    var view = this
    var $input = $(e.currentTarget)

    view.$('[data-count-display]').text($input.val().length)
  }
})

export default CommentFormView
