import ReportPopupView from '../ReportPopup'
import MileageReportFormView from './MileageReportForm'

const MileageReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/Mileage.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', MileageReportFormView)
  }
})

export default MileageReportPopupView
