import $ from 'jquery'
import View from '../View'

const SnailTrailStepPlayView = View.extend({
  interval: 1,
  speed: ['1x', '2x', '3x'],
  intervalValue: [3000, 2000, 1000],
  isActive: false,
  events: {
    'click [data-button=play]': 'setActivePlaySnailTrail',
    'click [data-button=stop]': 'resetSnailTrailPlay',
    'click [data-button=rwd]': 'setRwdSpeed',
    'click [data-button=fwd]': 'setFwdSpeed',
    'change .play-range input': 'fastRewind'
  },
  timeout: null,
  constructor: function (option) {
    this.el = option.el
    this.$el = $(this.el)

    View.apply(this, arguments)
  },
  setActivePlaySnailTrail: function (e) {
    var view = this
    var $playBtn = $(e.currentTarget)
    var snailTrailPopup = view.getParentView()
    var snailTrailGrid = snailTrailPopup.findSubView('snailTrailTableGrid')
    var selectIndex = snailTrailGrid.selectedIndex

    if (selectIndex === -1) {
      selectIndex = 0
    }

    if (!view.isActive) {
      $playBtn.addClass('active')
      view.isActive = true
      view.activeSnailTrailPlay(selectIndex)

      if (!snailTrailPopup.getParentView().$el.find('[data-button=slide]').hasClass('active')) {
        snailTrailPopup.getParentView().$el.find('[data-button=slide]').click()
      }

      if (!snailTrailPopup.$el.find('.max-min_btn').hasClass('active')) {
        snailTrailPopup.$el.find('.max-min_btn').click()
      }

      view.stepPlaySnailTrail(selectIndex)
    } else {
      $playBtn.removeClass('active')
      view.isActive = false

      if (snailTrailPopup.$el.find('.max-min_btn').hasClass('active')) {
        snailTrailPopup.$el.find('.max-min_btn').click()
      }

      clearInterval(view.timeout)
      window.rezoomMapFlx()
    }
  },
  resetSnailTrailPlay: function (e) {
    var view = this
    var $btn = view.$el.find('[data-button=play]')
    var snailTrailPopup = view.getParentView()
    var snailTrailGrid = snailTrailPopup.findSubView('snailTrailTableGrid')
    var selectIndex = snailTrailGrid.selectedIndex
    var $tr = $(snailTrailGrid.$el.find('tr').siblings()[selectIndex])
    var activeId = $tr.attr('data-id')

    $btn.removeClass('active')
    view.isActive = false
    snailTrailGrid.$el.find('tr').siblings().removeClass('active')
    clearInterval(view.timeout)

    if (snailTrailPopup.$el.find('.max-min_btn').hasClass('active')) {
      snailTrailPopup.$el.find('.max-min_btn').click()
    }

    snailTrailGrid.setSelectedItem($tr, activeId)
    window.mapCanvas.setAllShapesIconColorToDefault()
    view.$el.find('.play-range input').val(0)
  },
  activeSnailTrailPlay: function (selectIndex) {
    var view = this

    let streetViewTimeoutOffset = 0
    if (window.mapCanvas.isStreetViewVisible()) {
      streetViewTimeoutOffset = 800
    }
    view.timeout = setInterval(function () {
      view.stepPlaySnailTrail(++selectIndex)
    }, view.intervalValue[view.interval] + streetViewTimeoutOffset)
  },
  stepPlaySnailTrail: function (selectIndex) {
    var view = this
    var $btn = view.$el.find('[data-button=play]')
    var snailTrailPopup = view.getParentView()
    var snailTrailGrid = snailTrailPopup.findSubView('snailTrailTableGrid')
    var $tr = $(snailTrailGrid.$el.find('tr').siblings()[selectIndex])
    var activeId = $tr.attr('data-id')

    if (selectIndex >= snailTrailGrid.collection.models.length) {
      $tr = $(snailTrailGrid.$el.find('tr').siblings()[selectIndex - 1])
      $btn.removeClass('active')
      $tr.removeClass('active')
      view.isActive = !view.isActive
      clearInterval(view.timeout)
      view.$el.find('.play-range input').val(0)

      if (snailTrailPopup.$el.find('.max-min_btn').hasClass('active')) {
        snailTrailPopup.$el.find('.max-min_btn').click()
      }
    } else {
      $tr.addClass('active').siblings().removeClass('active')
      view.$el.find('.play-range input').val(selectIndex)
      snailTrailGrid.findSubView('scroll').$frame.sly('toStart', $tr)
    }

    snailTrailGrid.setSelectedItem($tr, activeId)
  },
  setFwdSpeed: function (e) {
    var view = this
    var $btn = $(e.currentTarget)
    var snailTrailGrid = view.getParentView().findSubView('snailTrailTableGrid')
    var selectIndex = snailTrailGrid.selectedIndex

    view.interval += 1

    if (view.interval > 1) {
      $btn.addClass('disabled')
      view.interval = 2
    }

    if (view.$el.find('[data-button=rwd]').hasClass('disabled')) {
      view.$el.find('[data-button=rwd]').removeClass('disabled')
    }

    view.$el.find('.controller-speed').text('' + view.speed[view.interval])

    if (view.isActive) {
      clearInterval(view.timeout)
      view.activeSnailTrailPlay(selectIndex)
    }
  },
  setRwdSpeed: function (e) {
    var view = this
    var $btn = $(e.currentTarget)
    var snailTrailGrid = view.getParentView().findSubView('snailTrailTableGrid')
    var selectIndex = snailTrailGrid.selectedIndex

    view.interval -= 1

    if (view.interval < 1) {
      $btn.addClass('disabled')
      view.interval = 0
    }

    if (view.$el.find('[data-button=fwd]').hasClass('disabled')) {
      view.$el.find('[data-button=fwd]').removeClass('disabled')
    }

    view.$el.find('.controller-speed').text('' + view.speed[view.interval])

    if (view.isActive) {
      clearInterval(view.timeout)
      view.activeSnailTrailPlay(selectIndex)
    }
  },
  fastRewind: function (e) {
    e.preventDefault()
    var view = this
    var selectIndex = parseInt(e.currentTarget.value, 10)
    var snailTrailGrid = view.getParentView().findSubView('snailTrailTableGrid')
    var $tr = $(snailTrailGrid.$el.find('tr').siblings()[selectIndex])

    if (view.isActive) {
      clearInterval(view.timeout)
      view.activeSnailTrailPlay(selectIndex)
      view.stepPlaySnailTrail(selectIndex)
    } else {
      view.stepPlaySnailTrail(selectIndex)
    }

    snailTrailGrid.findSubView('scroll').$frame.sly('toStart', $tr)
  },
  resetSnailTrailStep: function () {
    this.isActive = false
    this.interval = 1
    this.$el.find('[data-button=play]').removeClass('active')
    this.$el.find('.play-range input').val(0)
    this.getParentView().findSubView('snailTrailTableGrid').selectedIndex = -1
    this.getParentView().findSubView('snailTrailTableGrid').selectedItem = {}
    clearInterval(this.timeout)
  },
  destroy: function () {
    this.resetSnailTrailStep()
    this.remove()
  }
})

export default SnailTrailStepPlayView
