import ReportSelectionMenuView from '../../ReportSelectionMenu'
import TimeOnSiteReportSelectionMenuModel from '../../../model/report/timeOnSite/TimeOnSiteSelectionMenu'
import TimeOnSiteSelectionMenuVehicleListView from './TimeOnSiteSelectionMenuVehicleList'

const TimeOnSiteSelectionMenuView = ReportSelectionMenuView.extend({
  model: TimeOnSiteReportSelectionMenuModel,
  templateUrl: 'template/report/timeOnSite/timeOnSiteReportSelectionMenu.html',
  elSelector: '[data-selection-menu=timeOnSite]',
  initialize: function () {
    var view = this

    view.addSubView('vehicleList', TimeOnSiteSelectionMenuVehicleListView)
    view.render()
  }
})

export default TimeOnSiteSelectionMenuView
