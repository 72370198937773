/**
 * Created by George on 08.07.2015.
 */
import ProfileBuilderFormModel from '../profileManager/ProfileBuilderForm';

const OverspeedProfileBuilderForm = ProfileBuilderFormModel.extend({
    url: function(){
        const id = this.get('id') || '';
        return '/services/overspeed/profile/' + id;
    },
    defaults: {
        overspeed: 0,
        alerts: [],
        vehicles: []
    },
    validate: function(attrs, options){
        const message = {
            wrong_profile_name_format: "Please verify the Profile Name format.",
            wrong_max_permitted_speed: "Please verify the Maximum Permitted Speed Name format.",
            wrong_selected_vehicle_count: "Please select at least one vehicle."
        };
        const json = {
            button: ["OK"]
        };

        if (ProfileBuilderFormModel.prototype.validate.apply(this, [attrs, options, json, message])) {
            return json;
        }

        if (isNaN(parseFloat(attrs.overspeed)) || parseFloat(attrs.overspeed) < 0) {
            json.msgId = "wrong_max_permitted_speed";
            json.title = "Maximum permitted speed verification";
            json.message = message[json.msgId];
            return json;
        }
    }
});

export default OverspeedProfileBuilderForm;
