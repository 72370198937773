import $ from 'jquery'
import FormView from '../Form'
import HeartBeatPopupModel from '../../model/modalPanel/HeartBeatPopup'
import CounterView from '../Counter'

const HeartBeatView = FormView.extend({
  model: HeartBeatPopupModel,
  elSelector: '[data-form=heartbeat]',
  mergeEvents: true,
  initialize: function (option) {
    var view = this

    view.resetElement()
    view.model.set('heartbeatFrequency', view.model.get('heartbeatFrequency'))
    view.model.set('heartbeatDuration', view.model.get('heartbeatDuration'))
    view.on('save', function (model) {
      // userPrefs.refreshSmSeconds = model.get('refreshSmSeconds')
      // userPrefs.refreshSmSeconds = model.get('refreshSmSeconds')
      window.settings.get(true)
      // wls.setTimerValue(userPrefs.refreshSmSeconds)
    })
    view.addSubView('heartbeatMinEvery', CounterView, { el: $('[data-counter=minEvery]'), minVal: 1, maxVal: 60 })
    view.addSubView('heartbeatMinNext', CounterView, { el: $('[data-counter=minNext]'), minVal: 60, maxVal: 120 })
    view.inputObserve()
  },

  updateHeartBeat: function (freq, dur, id) {
    this.model = new HeartBeatPopupModel({ heartbeatFrequency: freq, heartbeatDuration: dur, userId: parseInt(id, 10) })
    this.render()
  },

  inputObserve: function () {
    var view = this
    var heartBeatFreq = $('input[name=heartbeatFrequency]')
    var heartBeatDurat = $('input[name=heartbeatDuration]')

    heartBeatFreq.on('focusout', function () {
      view.validateHeartBeat(heartBeatFreq, heartBeatDurat)
    })
    heartBeatDurat.on('focusout', function () {
      view.validateHeartBeat(heartBeatFreq, heartBeatDurat)
    })
  },

  validateHeartBeat: function (freq, dur) {
    if (parseInt(freq.val(), 10) > 60) {
      freq.val(60)
    }
    if (parseInt(dur.val(), 10) < parseInt(freq.val(), 10)) {
      freq.val(dur.val() - 1)
    }
  },

  getHeartBeat: function (id) {
    var view = this

    $.get('/api/lone-worker/heartbeat-settings/' + id).done(function (res) {
      if (res.heartbeatFrequency !== null && res.heartbeatDuration !== null) {
        view.updateHeartBeat(res.heartbeatFrequency, res.heartbeatDuration, id)
      }
    })
  },

  submit: function () {
    var view = this

    view.getParentView().hide()

    FormView.prototype.submit.apply(this, arguments)
  },

  destroy: function () {
    var view = this
    view.findSubView('heartbeatMinEvery').destroy()
    view.findSubView('heartbeatMinNext').destroy()
    view.remove()
  }

})

export default HeartBeatView
