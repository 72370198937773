import _ from 'underscore'
import $ from 'jquery'
import SelectionMenuView from './SelectionMenu'
import ReportSelectionMenuModel from '../model/ReportSelectionMenu'

const ReportSelectionMenuView = SelectionMenuView.extend({
  model: ReportSelectionMenuModel,
  mergeEvents: true,
  events: {
    'click [data-select]': 'select',
    'click [data-sort]': 'sort',
    'click [data-filter]': 'filter'
  },
  selectGroupHandler: function (e) {
    var view = this
    var selectedGroup
    var $accountFolder = $(e.currentTarget)
    var vehicleListView = view.findSubView('vehicleList')
    var $input = $accountFolder.find('input:hidden')
    var reportFormSelectedVehicleView, accountId
    var reportDriversSelect = view.getParentView()
      .findSubView('reportPopup')
      .findSubView('reportForm')
      .findSubView('reportDriversSelect')

    if (view.request) {
      view.request.abort()
    }

    if (!$accountFolder.hasClass('active') && !$input.hasClass('active')) {
      // console.log('I'm here')
      // if(userPrefs.htmlGroupTabsAllowed){
      //     selectedGroup = $accountFolder.attr('data-id')
      //     $accountFolder.addClass('active').siblings().removeClass('active')
      // }
      // console.log('Selected group is --- ', selectedGroup)
      selectedGroup = $input.addClass('active').val()
      $('.left-col .account-list input:hidden').not($input).removeClass('active')
      if (selectedGroup) {
        var tempGroupDescr = 'Checking Group Name...'

        $('.hover-tooltip-report').attr('data-tooltip', tempGroupDescr)

        $.post('/api/unit-view/description', {
          unitViewId: selectedGroup
        }).done(function (msg) {
          var groupDescr = msg
          console.log('..' + groupDescr)

          $('.hover-tooltip-report').attr('data-tooltip', groupDescr)
          $('.groups-tooltip').text(groupDescr)
          $.cookie('lastViewedGroupDescr', groupDescr)
        }).error(function (msg, errorText) {
          console.error('Failed to load Group descr, reason: ' + errorText)
        })

        $.cookie('lastViewedGroup', selectedGroup)
      }
      // var accountId = $accountFolder.attr('data-accountId')
      if ($accountFolder.parents('li.active').size() > 0) {
        accountId = $accountFolder.closest('li:has(*[data-accountid])').children().attr('data-accountid')
      } else {
        accountId = $accountFolder.attr('data-accountid')
      }

      window.sendCurrentAccount(accountId, function () {
        window.settings.get(true).done(function () {
          if (window.settings.data.showVehReg) {
            window.wlsMapTools.showVehRegCheckbox()
          } else {
            window.wlsMapTools.hideVehRegCheckbox()
          }
          if (window.settings.data.drivingTimePanelAllowed) {
            window.wlsMapTools.showDrivingTimeButton()
          } else {
            window.wlsMapTools.hideDrivingTimeButton()
          }

          if (window.groupTabsetView && window.settings.data.htmlGroupTabsAllowed) {
            window.groupTabsetView.collection.fetch({
              success: function () {
                $.cookie('lastViewedGroup', null) // reset cookie
                $.cookie('lastViewedGroup', selectedGroup)
                window.groupTabsetView.render()
              }
            })
          } else {
            $.cookie('lastViewedGroup', null)
            $.cookie('lastViewedGroup', selectedGroup)
          }
        })
      })
      view.request = $.post('/getVehiclesByGroup.do', {
        groupId: selectedGroup,
        repType: view.model.get('repType')
      }).done(function (msg) {
        var json = msg
        if (json.data.length) {
          view.model.set('vehicles', json.data)
          vehicleListView.collection.set(json.data)
          vehicleListView.render()
          reportFormSelectedVehicleView = view.getParentView().getReportFormSelectedVehicleView()
          reportFormSelectedVehicleView.syncSelectedVehicleList([])
          reportFormSelectedVehicleView.render()
          view.switchExpandTitle()
          if (reportDriversSelect) {
            reportDriversSelect.fetchDriverList()
          }
        }
        view.request = false
      }).error(function (msg, errorTxt) {
        alert('error')
        view.request = false
      })
    }
    e.preventDefault()
  },
  select: function (e) {
    var view = this
    var $selectionOption = $(e.currentTarget)
    var selectionName = $selectionOption.attr('data-select')

    switch (selectionName) {
      case 'SM_SELECT_ALL':
        view.selectAll($selectionOption)
        break
      case 'SM_SELECT_NONE':
        view.selectNone($selectionOption)
        break
      case 'SM_INVERT_SELECTION':
        view.invertSelection($selectionOption)
        break
      default:
        break
    }
    view.hideDropdown($selectionOption.closest('[data-dropdown]'))
    view.getParentView().findSubView('reportPopup').findSubView('reportForm').reportFormSyncSelectedVehicles()
  },
  selectAll: function ($selectionOption) {
    var view = this
    var i = 0
    var selectionMenuVehicleListView = view.findSubView('vehicleList')

    selectionMenuVehicleListView.collection.each(function (vehicleModel) {
      i++
      selectionMenuVehicleListView.selectVehicle(vehicleModel.get('id'), true)
      if (i === selectionMenuVehicleListView.collection.length) {
        selectionMenuVehicleListView.getParentView().getParentView().findSubView('reportPopup').resetWindowSize()
      }
    })
  },
  selectNone: function () {
    var view = this
    var i = 0
    var selectionMenuVehicleListView = view.findSubView('vehicleList')

    selectionMenuVehicleListView.collection.each(function (vehicleModel) {
      i++
      selectionMenuVehicleListView.deselectVehicle(vehicleModel.get('id'), true)
      if (i === selectionMenuVehicleListView.collection.length) {
        selectionMenuVehicleListView.getParentView().getParentView().findSubView('reportPopup').resetWindowSize()
      }
    })
  },
  invertSelection: function () {
    var view = this
    var i = 0
    var selectionMenuVehicleListView = view.findSubView('vehicleList')

    selectionMenuVehicleListView.collection.each(function (vehicleModel) {
      i++
      if (vehicleModel.get('selected')) {
        selectionMenuVehicleListView.deselectVehicle(vehicleModel.get('id'), true)
      } else {
        selectionMenuVehicleListView.selectVehicle(vehicleModel.get('id'), true)
      }
      if (i === selectionMenuVehicleListView.collection.length) {
        selectionMenuVehicleListView.getParentView().getParentView().findSubView('reportPopup').resetWindowSize()
      }
    })
  },
  filter: function (e) {
    var view = this
    var $selectionOption = $(e.currentTarget)
    var selectionName = $selectionOption.attr('data-filter')
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var filtredVehicles
    var selectedVehicles
    var oldCollection

    if ($selectionOption.hasClass('active') || $selectionOption.parent().siblings().find('[data-filter]').hasClass('active')) {
      view.findSubView('vehicleList').collection.add(view.model.get('vehicles'), { silent: true })
    }
    oldCollection = view.findSubView('vehicleList').collection.toJSON()
    view.model.set('vehicles', oldCollection)
    $selectionOption.addClass('active').parent().siblings().removeClass('active')
    switch (selectionName) {
      case 'sm_no_filter':
        filtredVehicles = view.noFilter()
        $selectionOption.removeClass('active')
        break
      case 'sm_tractor':
        filtredVehicles = view.filterTractor()
        break
      case 'sm_plant':
        filtredVehicles = view.filterPlant()
        break
      case 'sm_trailer_device':
        filtredVehicles = view.filterTrailerDevice()
        break
      case 'sm_trailer_tag':
        filtredVehicles = view.filterTrailerTag()
        break
      case 'sm_ignition_on':
        filtredVehicles = view.filterIgnitionOn()
        break
      case 'sm_ignition_off':
        filtredVehicles = view.filterIgnitionOff()
        break
      case 'sm_idling':
        filtredVehicles = view.filterIdling()
        break
      default:
        break
    }
    view.$('[data-sort].asc,[data-sort].desc').removeClass('asc').removeClass('desc')
    view.hideDropdown($selectionOption.closest('[data-dropdown]'))
    view.findSubView('vehicleList').collection.set(filtredVehicles, { silent: true })
    selectedVehicles = _.filter(filtredVehicles, 'selected')
    selectionMenuVehicleListView.render(filtredVehicles)
    view.getParentView().findSubView('reportPopup').findSubView('reportForm').reportFormSyncSelectedVehicles(selectedVehicles)
    // if(userPrefs.htmlGroupTabsAllowed){
    //     view.findSubView('groupTabset').renderScroll()
    // }
  },
  noFilter: function () {
    var view = this
    var vehicles = view.model.get('vehicles')

    return vehicles
  },
  filterTractor: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.filter(selectionMenuVehicleListView.collection.toJSON(), 'tractor')

    return json
  },
  filterPlant: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.filter(selectionMenuVehicleListView.collection.toJSON(), 'plant')

    return json
  },
  filterTrailerDevice: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.filter(selectionMenuVehicleListView.collection.toJSON(), 'trailerDevice')

    return json
  },
  filterTrailerTag: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.filter(selectionMenuVehicleListView.collection.toJSON(), 'tag')

    return json
  },
  filterIgnitionOn: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.filter(selectionMenuVehicleListView.collection.toJSON(), { ignitionStatus: 'On' })

    return json
  },
  filterIgnitionOff: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.filter(selectionMenuVehicleListView.collection.toJSON(), { ignitionStatus: 'Off' })

    return json
  },
  filterIdling: function () {
    var view = this
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var json = _.filter(selectionMenuVehicleListView.collection.toJSON(), { ignitionStatus: 'Idling' })

    return json
  }
})

export default ReportSelectionMenuView
