/**
 * Created by George on 29.05.2015.
 */
import Collection from '../../Collection';
import Profile from '../../../model/alert/outOfHours/Profile';

const OverspeedAlertAvailableProfileList = Collection.extend({
    model: Profile,
    url: function(){
        return "/services/overspeed/profile/" + (this.id ? this.id : "");
    }
});

export default OverspeedAlertAvailableProfileList;
