import TableGridModel from '../../TableGrid@'
import DrivingTimeList from '../../../collection/report/drivingTimeReport/DrivingTimeList'

const DrivingTimeReportTableGridModel = TableGridModel.extend({
  defaults: {
    reloadScrollOnWindowResize: true,
    selectAllowed: false,
    tableHead: [{
      tableHeadName: 'Veh. Reg.',
      field: 'vehReg'
    },
    {
      tableHeadName: 'Driver Name',
      field: 'driverName'
    },
    {
      tableHeadName: 'Driving Time',
      field: 'drivingTime'
    },
    {
      tableHeadName: 'Stopped Time',
      field: 'stoppedTime'
    },
    {
      tableHeadName: 'Idling Time',
      field: 'idlingTime'
    },
    {
      tableHeadName: 'Total Time',
      field: 'totalTime'
    }],
    tableRow: DrivingTimeList
  }
})

export default DrivingTimeReportTableGridModel
