import ReportFormModel from '../ReportForm'

const DigitalInputReportFormModel = ReportFormModel.extend({
  defaults: {
    unitIdList: '',
    from: '',
    fromTime: '',
    to: '',
    toTime: '',
    repFormat: '',
    fromDate: null,
    toDate: null,
    hideIgnition: ''
  },
  validate: function (attrs, options) {
    if (!attrs.unitIdList) {
      alert('Select at least one vehicle.')
      return 'Select at least one vehicle.'
    }
  }
})

export default DigitalInputReportFormModel
