/**
 * Created by George on 16.07.2017.
 */
import CheckboxListView from "../../CheckboxList";
import DriverBehaviorSelectedVehiclesModel from "../../../model/alert/driverBehavior/DriverBehaviorSelectedVehicles";

const DriverBehaviorSelectedVehiclesView = CheckboxListView.extend({
    templateUrl: 'template/alert/overspeed/overspeedCheckboxList.html',
    model: DriverBehaviorSelectedVehiclesModel
});

export default DriverBehaviorSelectedVehiclesView;
