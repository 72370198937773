import $ from 'jquery'
import FormView from '../Form'
import EditVehRegModel from '../../model/editVehicle/EditVehRegModel'

const EditVehRegFormView = FormView.extend({
  model: EditVehRegModel,
  elSelector: '[data-form]',
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'cancel',
    'change #newVehReg': 'checkNewVehReg',
    'keyup #newVehReg': 'checkNewVehReg'
  },
  constructor: function () {
    var view = this
    var parentView = view.getParentView()
    if (parentView) {
      view.$el = parentView.$(view.elSelector)
    } else {
      view.$el = $(view.elSelector)
    }
    view.el = view.$el[0]

    FormView.apply(this, arguments)
  },
  initialize: function () {
    var view = this
    view.listenTo(view.model, 'change:currentVehReg', view.changeCurrentVehReg)
    view.on('save', function (model) {

      if (model.get('success')) {
        var $vehicleData = $('.row[data-id=' + model.get('id') + ']')
        var $vehReg = $vehicleData.find('.data span:first')
        var newVehReg = model.get('newVehReg')

        if (newVehReg.length > 16) {
          $vehReg.attr('title', newVehReg)
        } else {
          $vehReg.attr('title', '')
        }

        $vehReg.text(newVehReg)
        window.wls.getVehicle(view.model.get('id')).data.registrationNumber = newVehReg

        view.cancel() // hide popup and return UI to default state
      } else {
        view.error(model, 'Vehicle with Registration Number "' + model.get('newVehReg') + '" already exists. Please enter a unique Vehicle Registration Number.')
      }
    })
    view.model.on('invalid', this.error)
  },
  changeCurrentVehReg: function (formModel) {
    this.$('input#currentVehReg').val(formModel.get('currentVehReg'))
  },
  cancel: function () {
    this.getParentView().hide()
    $('#newVehReg').parent().find('.validation-ico').removeClass('error').removeClass('ok')
    this.$('.button.save').addClass('disabled')
    FormView.prototype.cancel.apply(this, arguments)
  },
  checkNewVehReg: function () {
    this.syncData()
    var errorMessage = this.model.validate(this.model.attributes)

    if (errorMessage) {
      this.error(this.model, errorMessage)
    } else {
      this.ok()
    }
  },
  error: function (model, errorMessage) {
    var $validationIco = $('#newVehReg').parent().find('.validation-ico')

    $validationIco.addClass('error').removeClass('ok')
    $validationIco.attr('title', errorMessage)
    this.$('.button.save').addClass('disabled')
  },
  ok: function () {
    var $validationIco = $('#newVehReg').parent().find('.validation-ico')

    $validationIco.addClass('ok').removeClass('error')
    $validationIco.attr('title', '')
    this.$('.button.save').removeClass('disabled')
  },
  submitHandler: function (e) {
    if (this.$('.button.save').hasClass('disabled')) {
      return e && e.preventDefault()
    }
    FormView.prototype.submitHandler.apply(this, arguments)
  }
})

export default EditVehRegFormView
