/**
 * Created by George on 16.07.2015.
 */
import AlertListPopupView from '../AlertListPopup';
import OverspeedAlertTableGridView from './OverspeedAlertTableGrid';
import OverspeedAlertPopupModel from '../../../model/alert/overspeed/OverspeedAlertPopup';

const OverspeedAlertListPopupView = AlertListPopupView.extend({
    model: OverspeedAlertPopupModel,
    AlertTableGridView: OverspeedAlertTableGridView
});

export default OverspeedAlertListPopupView;
