import AlertPanelModel from '../../model/alert/AlertPanel'
import TabsetView from '../Tabset'
import View from '../View'
import $ from 'jquery'
import ProfileManagerPopupView from './profileManager/ProfileManagerPopup'

const AlertPanelView = View.extend({
  model: AlertPanelModel,
  tabData: null,
  AlertListPopupView: null,
  tabsetName: '',
  events: {
    'click [data-button=alert-list]': 'beforeShowAlertList',
    'click [data-button=manage-profiles]': 'showProfileManager'
  },
  constructor: function (option) {
    var view = this

    view.initAlertListPopupView()

    if (!view.towAway && view.$el.attr('data-tab') !== 'unpluggedDevice') { // remove after refactoring
      view.initProfileManagerPopupView()
    }



    view.initTabset()
    view.bindAlertListTableGridItemSelect()

    view.model = new view.model()
    view.listenTo(view.model, 'change:dataMode', function (model, dataMode) {
      var activeTabView = view.tabsetView.getActiveTabView()

      view.switchTitleHandler()
      activeTabView.dataMode = dataMode
    })
    view.listenTo(view.model, 'change:alertId', function (model, alertId) {
      var activeTabName = view.tabsetView.model.get('activeTab')

      view.tabData[activeTabName].alertId = alertId
    })

    view.tabData = {}
    View.apply(view, arguments)
  },
  initAlertListPopupView: function (AlertListPopupView) {
    var view = this
    view.alertListPopupView = view.addSubView('alertListPopup', this.AlertListPopupView)
    return view
  },
  initProfileManagerPopupView: function () {
    var view = this

    view.profileManagerPopupView = view.addSubView('profileManagerPopup', view.ProfileManagerPopupView || ProfileManagerPopupView)
    return view
  },
  initTabset: function () {
    var view = this

    TabsetView.prototype.parentView = this
    this.tabsetView = new TabsetView({
      el: this.$el.find('[data-tabset=' + this.tabsetName + ']'),
      beforeInitTabCallback: (tabsetView, tabsetModel) => {
        tabsetView.on('initTab', function (tabView, activeTabName) {
          if (!tabView.innerView.isReady) {
            tabView.innerView.on('ready', function () {
              view.changeActiveTabHandler(tabView, activeTabName)
            })
          } else {
            view.changeActiveTabHandler(tabView, activeTabName)
          }
        })
      },
      afterInitTabCallback: (tabsetView, tabsetModel) => {
        tabsetView.listenTo(tabsetView.model, 'change:activeTab', function (tabsetModel, activeTabName) {
          if (view.findSubView('profileManagerPopup')) {
            view.findSubView('profileManagerPopup').hide()
            if (view.findSubView('profileManagerPopup').findSubView('profileManagerBuilderPopup')) {
              view.findSubView('profileManagerPopup').findSubView('profileManagerBuilderPopup').hide()
            }
          }

          var tabData = view.tabData[activeTabName]
          if (!tabData || tabData.alertId === view.model.get('alertId') && tabData.dataMode === view.model.get('dataMode')) {
            return
          }

          view.changeActiveTabHandler(this.getActiveTabView(), activeTabName)
        })
      }
    })

    $('[data-tabset=outOfHours]>[data-button=close]').one('click', function () {
      view.close()
    })
    return this
  },
  close: function () {
    window.frameElement.parentNode.remove()
  },
  changeActiveTabHandler: function (activeTabView, activeTabName) {
    var view = this
    var alertListTableGridView = view.alertListPopupView.alertListTableGridView
    var id = view.model.get('alertId')
    var isSelectRecipient = !activeTabView.innerView.hasSubView('availableSelectedTableGrid')
    var recipientTableGridView
    var selectedList

    if (view.model.get('dataMode') === 'edit') {
      id = alertListTableGridView.getActiveRowId()

      if (!isSelectRecipient) {
        selectedList = alertListTableGridView.model.get('tableRow').get(id).get(view.tabsetView.get$ActiveTab().data('selected-list'))
        activeTabView.innerView.findSubView('availableSelectedTableGrid').availableTableGridCollection.id = id
      } else {
        selectedList = alertListTableGridView.model.get('tableRow').get(id).get('selectedAlertRecipientList')
      }
    } else {
      if (!isSelectRecipient) {
        activeTabView.innerView.findSubView('availableSelectedTableGrid').availableTableGridCollection.id = null
      } else {
        selectedList = []
      }
    }
    view.tabData[activeTabName] = {
      alertId: view.model.get('alertId'),
      dataMode: view.model.get('dataMode')
    }
    if (!isSelectRecipient) {
      activeTabView.innerView.findSubView('availableSelectedTableGrid').reloadContent(selectedList, true)
    } else {
      recipientTableGridView = activeTabView.innerView.findSubView('selectRecipientTableGrid')
      if (!recipientTableGridView.isReady) {
        recipientTableGridView.on('ready', function () {
          this.renderSelectedList(selectedList)
        })
      } else {
        recipientTableGridView.renderSelectedList(selectedList)
      }
    }
  },
  bindAlertListTableGridItemSelect: function () {
    var view = this

    view.alertListPopupView.on('ready', function () {
      this.alertListTableGridView.on('itemSelect', function ($tr, id) {
        var activeTabView = view.tabsetView.getActiveTabView()
        var selectedList
        var isSelectRecipient = !activeTabView.innerView.hasSubView('availableSelectedTableGrid')
        var selectRecipientTableGridView

        if ($tr.is('.active')) {
          view.model.set('alertId', id)
          selectedList = this.model.get('tableRow').get(id).get(view.tabsetView.get$ActiveTab().data('selected-list'))
          view.findSubView('alertForm').model.set(this.model.get('tableRow').get(id).toJSON())
          view.switchTitle('edit')
          view.tabData[view.tabsetView.model.get('activeTab')].dataMode = 'edit'
          //                            if(!isSelectRecipient){
          //                                activeTabView.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.id = view.model.get("alertId");
          //                            }
          view.$el.find('input[name=name]').val(this.model.get('tableRow').get(id).get('name'))
        } else {
          view.model.set('alertId', null)
          view.switchTitle('create')
          view.tabData[view.tabsetView.model.get('activeTab')].dataMode = 'create'
          if (!isSelectRecipient) {
            activeTabView.innerView.findSubView('availableSelectedTableGrid').availableTableGridCollection.id = null
          }
          view.$el.find('input[name=name]').val('')
        }
        if (isSelectRecipient) {
          if (view.model.get('dataMode') === 'edit') {
            selectedList = this.model.get('tableRow').get(id).get('selectedAlertRecipientList')
          }
          selectRecipientTableGridView = activeTabView.innerView.findSubView('selectRecipientTableGrid')
          selectRecipientTableGridView.renderSelectedList(selectedList)
          view.tabsetView.selectProfile.innerView.findSubView('availableSelectedTableGrid').reloadContent(this.model.get('tableRow').get(id).get('selectedAlertProfileList'), true)
        } else {
          activeTabView.innerView.findSubView('availableSelectedTableGrid').reloadContent(selectedList, true)
        }
      })
    })
    return view
  },
  showAlertList: function () {
    this.alertListPopupView.model.set('visible', true)
    this.alertListPopupView.alignBy(this.$el.find('[data-button=alert-list]'), 'topRight', { top: 0, left: 5 })
  },
  beforeShowAlertList: function () {
    if (!this.towAway && this.$el.attr('data-tab') !== 'unpluggedDevice') { // remove after refactoring
      var profileManager = this.findSubView('profileManagerPopup')
      var profileBuilder = profileManager.findSubView('profileManagerBuilderPopup')
      if (profileBuilder) {
        profileBuilder.hide()
      }
      if (profileManager) {
        profileManager.hide()
      }
    }

    if (this.alertListPopupView.model.get('htmlReady')) {
      this.showAlertList()
    }
  },
  showProfileManager: function () {
    this.profileManagerPopupView.show()
    this.profileManagerPopupView.alignBy(this.$el.find('[data-button=manage-profiles]'), 'center', { top: 10, left: 5 })
  },
  switchTitle: function (title) {
    this.model.set('dataMode', title)
  },
  switchTitleHandler: function () {
    var view = this

    this.$el.find('[data-title=' + this.model.get('dataMode') + ']').show().siblings('[data-title]').hide()
  },
  destroy: function () {
    if (this.$el.attr('data-tab') !== 'towAway' && this.$el.attr('data-tab') !== 'unpluggedDevice') { // remove after refactoring
      this.profileManagerPopupView.destroy()
    }
    this.alertListPopupView.destroy()
    this.remove()
  }
})

export default AlertPanelView
