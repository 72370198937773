import $ from 'jquery'
import View from '../../View'
import DetailedVehicleDefectReportModel from '../../../model/report/vehicleDefectReport/DetailedVehicleDefectReport'
import DetailedVehicleDefectReportScrollView from './DetailedVehicleDefectReportScroll'

const DetailedVehicleDefectReportView = View.extend({
  model: DetailedVehicleDefectReportModel,
  templateUrl: 'template/report/vehicleDefectReport/detailedVehicleDefectReport.html',
  elSelector: '[data-report=detailedVehicleDefect]',
  events: {
    'mouseover [data-button=tooltip]': 'showTooltip',
    'mouseout [data-button=tooltip]': 'hideTooltip',
    'click [data-button=defectImage]': 'showImagePopup'
  },
  initialize: function (option) {
    var view = this

    view.model = new view.model()
    view.addSubView('scroll', DetailedVehicleDefectReportScrollView)
    view.loadDotTemplate(function () {
      view.compileTemplate()
    })
  },
  render: function (id) {
    var view = this
    var detailedVehicleDefectReportPopupView = view.getParentView()

    detailedVehicleDefectReportPopupView.$el.find('.title span').text(id)
    view.model.set('id', parseInt(id))
    view.model.fetch({
      success: function () {
        if (document.body.contains(view.el)) {
          view.$el.html($(view.dotTemplateToHtml(view.model.toJSON())).html())
        } else {
          view.getParentView().$el.append(view.dotTemplateToHtml(view.model.toJSON()))
          view.resetElement()
        }
        if (detailedVehicleDefectReportPopupView.isVisible()) {
          view.findSubView('scroll').render()
        } else {
          detailedVehicleDefectReportPopupView.prepare()
          view.findSubView('scroll').render()
          detailedVehicleDefectReportPopupView.prepareDone()
          detailedVehicleDefectReportPopupView.align()
          detailedVehicleDefectReportPopupView.show()
        }
      }
    })

    return view
  },
  showImagePopup: function (e) {
    var view = this
    var imagePopup = view.getParentView().findSubView('imagePopup')
    var $html = $(e.currentTarget)

    imagePopup.showPopup($html)
  },
  showTooltip: function (e) {
    var view = this
    var $button = $(e.currentTarget)
    var $tooltip = $button.closest('li').find('[data-tooltip]')

    $tooltip.css({
      top: $button.offset().top - view.getParentView().$el.offset().top - ($tooltip.height() + $button.height()) + 5,
      left: $button.offset().left - view.$el.offset().left - (($tooltip.width() - $button.width()) / 2)
    }).show()
  },
  hideTooltip: function (e) {
    var $button = $(e.currentTarget)

    $button.closest('li').find('[data-tooltip]').hide()
  },
  destroy: function () {
    var view = this

    view.findSubView('scroll').destroy()
    view.remove()
  }
})

export default DetailedVehicleDefectReportView
