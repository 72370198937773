import PopupView from './Popup'
import AddEditPopupModel from '../model/AddEditPopup'

const AddEditPopupView = PopupView.extend({
  model: AddEditPopupModel,
  initialize: function () {
    this.listenTo(this.model, 'change:dataMode', this.switchTitle)
  },
  switchTitle: function (addEditPopupModel) {
    addEditPopupModel = this.model
    this.$el.find('[data-title=' + addEditPopupModel.get('dataMode') + ']').show().siblings('[data-title]').hide()
  }
})

export default AddEditPopupView
