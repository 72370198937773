import ProfileManagerPopupView from '../profileManager/ProfileManagerPopup'
import OutOfHoursProfileManagerTableGridView from './OutOfHoursProfileManagerTableGrid'
import OutOfHoursProfileBuilderPopupView from "./OutOfHoursProfileBuilderPopup";

const OutOfHoursProfileManagerPopupView = ProfileManagerPopupView.extend({
    title: 'Out of Hours Profiles',
    ProfileManagerTableGrid: OutOfHoursProfileManagerTableGridView,
    ProfileManagerBuilderPopupView: OutOfHoursProfileBuilderPopupView
});

export default OutOfHoursProfileManagerPopupView