import ScrollView from '../Scroll'

const SnailTrailTableGridScrollView = ScrollView.extend({
  calculateScrollContentSize: function () {
    var $popup = this.$el.closest('[data-popup]')
    this.$frame.height($popup.height() - ($popup.find('.header-panel').outerHeight() + $popup.find('.step-play').outerHeight() + $popup.find('.info').outerHeight()))
  },
  checkContentSize: function () {
    var $popup = this.$el.closest('[data-popup]')
    return this.$('.slidee').height() > $popup.height() - ($popup.find('.header-panel').outerHeight() + $popup.find('.step-play').outerHeight() + $popup.find('.info').outerHeight())
  }
})

export default SnailTrailTableGridScrollView
