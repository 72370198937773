import Model from './Model'

const SafeInput = Model.extend({
  defaults: {
    keyCode: null,
    allowedChars: []
  },
  validate: function (attrs, options) {
    var allow = {
      letters: function (key) {
        return key < 65 || key > 90
      },
      digits: function (key) {
        return (key < 48 || key > 57) && (key < 96 || key > 105)
      },
      navigation: function (key) {
        return (key < 35 || key > 40) && key !== 9
      },
      edit: function (key) {
        return key !== 8 && key !== 13 && key !== 46
      },
      period: function period (key) {
        return key !== 190 && key !== 191
      },
      subtract: function (key) {
        return key !== 189
      },
      colon: function (key) {
        return key !== 186
      },
      f5: function (key) {
        return key !== 116
      }
    }
    var block = true
    var len = attrs.allowedChars.length

    for (var i = 0; i < len; i++) {
      if (!allow[attrs.allowedChars[i]](attrs.keyCode)) {
        block = false
      }
    }
    if (block) {
      return 'illegal char'
    }
  }
})

export default SafeInput
