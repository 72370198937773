/**
 ** Created by Paul Zelenov
 **/

import Model from "./Model";

const Imei = Model.extend({
    defaults: {
        id: 0,
        imei: ""
    },
    urlRoot: "/services/vehicle"
});

export default Imei;

