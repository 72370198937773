import Model from './Model'

const AoiModel = Model.extend({
  defaults: {
    id: 0,
    description: ''
  },
  urlRoot: '/services/aoi'
})

export default AoiModel
