
import AlertListPopupView from "../AlertListPopup";
import UnpluggedDeviceAlertTableGridView from "./UnpluggedDeviceAlertTableGrid";
import UnpluggedDeviceAlertListPopup from "../../../model/alert/unpluggedDevice/UnpluggedDeviceAlertListPopup";

const UnpluggedDeviceAlertListPopupView = AlertListPopupView.extend({
    model: UnpluggedDeviceAlertListPopup,
    AlertTableGridView: UnpluggedDeviceAlertTableGridView
});

export default UnpluggedDeviceAlertListPopupView;
