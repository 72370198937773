import Model from '../Model'

const LWReportFormModel = Model.extend({
  defaults: {
    unitIdList: '',
    from: '',
    fromTime: '',
    to: '',
    toTime: '',
    repFormat: '',
    excludeWeekends: false,
    groupJourneysByDriver: false,
    fromDate: null,
    toDate: null
  },
  validate: function (attrs, options) {
    if (!attrs.unitIdList) {
      alert('Select at least one user.')
      return 'Select at least one user.'
    }
  }
});

export default LWReportFormModel
