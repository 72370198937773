import Model from '../Model'

const ActivityLogModel = Model.extend({
  defaults: {
    recDate: null,
    directionCode: '',
    id: null,
    ignitionStr: '',
    inStealthMode: false,
    input1Str: '',
    input2Str: '',
    input3Str: '',
    journeyEnd: false,
    journeyStart: false,
    lat: null,
    lon: null,
    streetName: '',
    postcode: '',
    poiDescr: '',
    factoredSpeed: null,
    ignitionIconUrl: '',
    directionIconUrl: '',
    vehicleIconUrl: '',
    cameraEvent: '',
    videoStatus: null,
    channels: []
  }
})

export default ActivityLogModel
