import $ from 'jquery'
import TableGridView from '../TableGrid@'
import DriverWorkingHoursDetailsTableGridModel from '../../model/driverWorkingHours/DriverWorkingHoursDetailsTableGrid'
import TableGridDropView from '../TableGridDrop'
import PopupTableGridScrollView from '../PopupTableGridScroll'

const DriverWorkingHoursDetailsTableGridView = TableGridView.extend({
  model: DriverWorkingHoursDetailsTableGridModel,
  templateUrl: 'template/driverWorkingHours/driverWorkingHoursDetailsTableGrid.html',
  elSelector: '[data-table-grid=driverWorkingHoursDetailsTableGrid]',
  initialize: function () {
    var view = this

    view.addSubView('tableDrop', TableGridDropView)
    view.addSubView('scroll', PopupTableGridScrollView)
    view.model.set('tableRow', [])
  },
  fetchDriverWorkingHoursDetails: function () {
    var view = this
    var calendarView = view.getParentView().getParentView()
    var driverId = calendarView.getParentView().$el.find('[data-select=driver]').val()
    var $activeTd = calendarView.$el.find('td.active')
    var milliseconds = parseInt($activeTd.attr('data-date'))
    var today = new Date(milliseconds)
    var param = {
      driverId: driverId, // view.getParentView().findSubview('select').$el.val()
      startDate: (function () {
        today.setHours(0, 0, 0, 0)
        return today.getTime()
      })(),
      endDate: (function () {
        today.setHours(23, 59, 59, 999)
        return today.getTime()
      })()
    }

    return view.model.get('tableRow').fetch({ data: $.param(param) })
  }
})

export default DriverWorkingHoursDetailsTableGridView
