import $ from 'jquery'
import View from '../View'
import DriverWorkingHoursDetailsPopupView from './DriverWorkingHoursDetailsPopup'
import DriverWorkingHoursCalendarModel from '../../model/driverWorkingHours/DriverWorkingHoursCalendar'

const DriverWorkingHoursCalendarView = View.extend({
  templateUrl: 'template/driverWorkingHours/driverWorkingHoursCalendar.html',
  elSelector: '[data-container=calendar]',
  model: DriverWorkingHoursCalendarModel,
  events: {
    'click td[data-date]': 'tdClickHandler',
    'click [data-button]:not(.disabled)': 'arrowButtonHandler'
  },
  constructor: function () {
    var view = this

    view.model = new view.model()
    view.loadDotTemplate(function () {
      view.compileTemplate()
      view.resetElement()
    })
    View.apply(view, arguments)
  },
  initialize: function () {
    var view = this

    view.addSubView('driverWorkingHoursDetailsPopup', DriverWorkingHoursDetailsPopupView)
    view.listenTo(view.model.get('driverWorkingHoursList'), 'request', function (collection, xhr) {
      xhr.done(function (calendarData) {
        view.getParentView().model.set('calendarData', calendarData)
        view.getParentView().renderFortnightTotal()
      })
    })
  },
  render: function () {
    var view = this
    var json = view.model.toJSON()
    var html = view.dotTemplateToHtml(json)

    view.$el.html(html)
  },
  arrowButtonHandler: function (e) {
    var view = this
    var $button = $(e.currentTarget)
    var buttonName = $button.attr('data-button')
    var currentDate = new Date(view.model.get('currentDate'))
    var dateRange

    switch (buttonName) {
      case 'nextYear':
        currentDate.setFullYear(currentDate.getFullYear() + 1)
        view.model.set('currentDate', currentDate.valueOf())
        dateRange = view.getFirstFortnight()
        view.model.set('currentDate', dateRange[1])
        break
      case 'lastYear':
        currentDate.setFullYear(currentDate.getFullYear() - 1)
        view.model.set('currentDate', currentDate.valueOf())
        dateRange = view.getFirstFortnight()
        view.model.set('currentDate', dateRange[1])
        break
      case 'nextMonth':
        currentDate.setMonth(currentDate.getMonth() + 1)
        view.model.set('currentDate', currentDate.valueOf())
        dateRange = view.getFirstFortnight()
        view.model.set('currentDate', dateRange[1])
        break
      case 'lastMonth':
        currentDate.setMonth(currentDate.getMonth() - 1)
        view.model.set('currentDate', currentDate.valueOf())
        dateRange = view.getFirstFortnight()
        view.model.set('currentDate', dateRange[1])
        break
      case 'nextWeek':
        dateRange = view.getNextWeek()
        currentDate.setDate(currentDate.getDate() + 7)
        view.model.set('currentDate', currentDate.valueOf())
        break
      case 'lastWeek':
        dateRange = view.getLastWeek()
        currentDate.setDate(currentDate.getDate() - 7)
        view.model.set('currentDate', currentDate.valueOf())
        break
    }

    view.fetchDriverWorkingHoursList(dateRange).done(function () {
      view.render()
    })
  },
  fetchDriverWorkingHoursList: function (dateRange) {
    var view = this
    var driverId = view.getParentView().$el.find('[data-select=driver]').val()
    var json = {
      driverId: driverId,
      startDate: dateRange[0],
      endDate: dateRange[1]
    }

    return view.model.get('driverWorkingHoursList').fetch({ data: $.param(json) })
  },
  updateCalendarOnDriverChange: function () {
    var view = this
    var date = new Date(view.model.get('driverWorkingHoursList').at(-1).get('date'))
    var dateRange = [0, 0]

    date.setDate(date.getDate() - ((date.getDay() || 7) - 1) + 6)
    dateRange[1] = date.setHours(23, 59, 59, 999)
    date.setDate(date.getDate() - 7)
    date.setDate(date.getDate() - ((date.getDay() || 7) - 1))
    dateRange[0] = date.setHours(0, 0, 0, 0)
    view.fetchDriverWorkingHoursList(dateRange).done(function () {
      view.render()
    })
  },
  getNextWeek: function () {
    var view = this
    var date = new Date(view.model.get('currentDate'))
    var firstDay
    var lastDay

    date.setDate(date.getDate() - ((date.getDay() || 7) - 1))
    firstDay = date.setHours(0, 0, 0, 0)
    date.setDate(date.getDate() + 7)
    date.setDate(date.getDate() - ((date.getDay() || 7) - 1) + 6)
    lastDay = date.setHours(23, 59, 59, 999)

    return [firstDay, lastDay]
  },
  getLastWeek: function () {
    var view = this
    var date = new Date(view.model.get('currentDate'))
    var firstDay
    var lastDay

    date.setDate(date.getDate() - 7)
    date.setDate(date.getDate() - ((date.getDay() || 7) - 1) + 6)
    lastDay = date.setHours(23, 59, 59, 999)
    date.setDate(date.getDate() - 7)
    date.setDate(date.getDate() - ((date.getDay() || 7) - 1))
    firstDay = date.setHours(0, 0, 0, 0)

    return [firstDay, lastDay]
  },
  getFirstFortnight: function () {
    var view = this
    var date = new Date(view.model.get('currentDate'))
    var firstDay
    var lastDay

    date.setDate(1)
    date.setDate(date.getDate() - ((date.getDay() || 7) - 1))
    firstDay = date.setHours(0, 0, 0, 0)
    date.setDate(date.getDate() + 13)
    date.setDate(date.getDate() - ((date.getDay() || 7) - 1) + 6)
    lastDay = date.setHours(23, 59, 59, 999)

    return [firstDay, lastDay]
  },
  tdClickHandler: function (e) {
    var view = this
    var $td = $(e.currentTarget)
    var driverWorkingHoursDetailsPopupView = view.findSubView('driverWorkingHoursDetailsPopup')
    var driverWorkingHoursDetailsTableGridView = driverWorkingHoursDetailsPopupView.findSubView('driverWorkingHoursDetailsTableGrid')

    $td.closest('tbody').find('td').removeClass('active')
    $td.addClass('active')
    if (driverWorkingHoursDetailsPopupView.isVisible()) {
      driverWorkingHoursDetailsTableGridView.fetchDriverWorkingHoursDetails().done(function () {
        driverWorkingHoursDetailsTableGridView.render()
        driverWorkingHoursDetailsPopupView.updateTitle(parseInt($td.attr('data-date')))
        driverWorkingHoursDetailsPopupView.alignBy($td, 'topRight', { top: 25, left: -25 })
      })
    } else {
      driverWorkingHoursDetailsPopupView.prepare()
      driverWorkingHoursDetailsTableGridView.fetchDriverWorkingHoursDetails().done(function () {
        driverWorkingHoursDetailsTableGridView.render()
        driverWorkingHoursDetailsPopupView.alignBy($td, 'topRight', { top: 25, left: -25 })
        driverWorkingHoursDetailsPopupView.updateTitle(parseInt($td.attr('data-date')))
        driverWorkingHoursDetailsPopupView.prepareDone()
        driverWorkingHoursDetailsPopupView.show()
      })
    }
  }
})

export default DriverWorkingHoursCalendarView
