import _ from 'underscore'

const extendProperty = function (object, propertyName) {
  var proto = Object.getPrototypeOf(object)
  var allObjects = []
  while (proto) {
    if (proto && (propertyName in proto)) {
      allObjects.push(proto[propertyName])
    }
    proto = Object.getPrototypeOf(proto)
  }
  if (allObjects.length > 1) {
    allObjects.push({})
    allObjects.reverse()
    object[propertyName] = _.extend.apply(null, allObjects)
  }
}

export default extendProperty
