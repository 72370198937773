/**
 * Created by George on 16.07.2017.
 */
import Model from '../../Model';

const DriverBehaviorSelectedVehicles = Model.extend({
    url: function() {
        var id = this.get('id') || '';
        return `/services/driver-behaviour/selected-vehicles/${id}`;
    }
})

export default DriverBehaviorSelectedVehicles;
