function formatDateToDayTime (timeSeconds) {
  var days = parseInt(timeSeconds / (24 * 60 * 60))
  var hours = parseInt(timeSeconds / (60 * 60) - days * 24)
  var minutes = parseInt(timeSeconds / 60 - days * 24 * 60 - hours * 60)
  var seconds = parseInt(timeSeconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60)

  return (days > 0 ? days.toString() + ' days ' : '') + (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
};

export default formatDateToDayTime
