import $ from 'jquery'
import View from '../../View'
import TowAwayAlertView from './TowAwayAlert'

const TowAwayAlertManagerView = View.extend({
  templateUrl: 'template/alert/towAway/towAwayAlertManager.html',
  events: {
    'click .slide-button': 'slideButtonHandler'
  },
  constructor: function (option) {
    var self = this
    var args = arguments

    this.loadDotTemplate(function () {
      self.compileTemplate()
      self.$el = $('<div class="tabset-report-panel" data-report-page="towAwayAlertManeger">' + self.dotTemplateToHtml({}) + '</div>')
      self.el = self.$el[0]
      View.apply(self, [{ el: self.el }])
    })
  },
  initialize: function () {
    var view = this

    view.render()

    view.addSubView('towAwayAlert', TowAwayAlertView, {
      el: view.$('[data-tabset="alertsManager"]')
    })
  },
  slideButtonHandler: function () {
    var left = 0

    if (!this.$('.slide-button').hasClass('active')) {
      left = -this.$el.width()
    }
    this.$el.animate({ left: left }, 400)
    this.$('.slide-button').toggleClass('active')
  },
  isVisible: function () {
    return this.$el.is(':visible')
  },
  getHTML: function () {
    return this.$el
  },
  render: function () {
    var $leftPanel = $('.left-panel')
    var $mapWrap = $('.map-wrap')

    if ($leftPanel.hasClass('active')) {
      $mapWrap.animate({
        'margin-left': 0
      }, 800, function () {
        $leftPanel.removeClass('active')
        $mapWrap.removeClass('active')
      })
    }
    $leftPanel.fadeOut(400)
    $mapWrap.append(this.$el)
    this.$el.fadeIn(400)
  },
  destroy: function () {
    var view = this
    const alertListPopupView = view.findSubView('towAwayAlert').findSubView('alertListPopup')

    if (alertListPopupView) {
      alertListPopupView.destroy()
    }

    this.$el.fadeOut(400, function () {
      view.remove()
    })
  }
})

export default TowAwayAlertManagerView
