import Model from '../Model'

const DriverWorkingHoursDetailsModel = Model.extend({
  defaults: {
    status: null,
    dateTime: null,
    duration: null,
    location: '',
    postcode: '',
    poiAoi: ''
  },
  url: 'api/tachograph/driverWorkingHoursDetails'
})

export default DriverWorkingHoursDetailsModel
