import $ from 'jquery'
import ReportFormView from '../ReportForm'
import SpeedingReportFormModel from '../../../model/report/speedingReport/SpeedingReportForm'
import SelectView from '../../Select'
import CounterView from '../../Counter'

const SpeedingReportFormView = ReportFormView.extend({
  model: SpeedingReportFormModel,
  initialize: function (option) {
    var view = this

    view.addSubView('select', SelectView, { el: view.$('[data-select=operation]') })
    view.addSubView('speedCounter', CounterView, { el: view.$('[data-counter=speed]') })
    view.addSubView('overspeedPercantageCounter', CounterView, { el: view.$('[data-counter=overspeedPercantage]') })
  },
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'Speeding',
      'report-type': 'Speeding',
      'report-format': model.get('repFormat'),
      alternate: false,
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/'),
      percentSpeedCriteria: model.get('percentSpeedCriteria'),
      mileSpeedCriteria: model.get('mileSpeedCriteria'),
      mphSpeedCriteria: model.get('mileSpeedCriteria'),
      operation: model.get('operation')
    }
    return $.param(data)
  },
  destroy: function () {
    var view = this

    view.findSubView('select').destroy()
    view.findSubView('speedCounter').destroy()
    view.findSubView('overspeedPercantageCounter').destroy()
    ReportFormView.prototype.destroy.apply(this, arguments)
  }
})

export default SpeedingReportFormView
