import Model from '../../Model'

const ChecklistFormModel = Model.extend({
  defaults: {
    id: null,
    createdDate: null,
    modifiedDate: null,
    creator: null,
    modifier: null,
    revision: '',
    accountId: null,
    status: '',
    descr: '',
    vehicleDefectDailyCheckTypeList: []
  },
  url: '/api/report/vehicleDefectReport/checklist',
  validate: function (attrs, options) {
  }
})

export default ChecklistFormModel
