import SelectView from '../Select'

const DriverSelectView = SelectView.extend({
  events: {
    selectmenuchange: 'selectChangeHandler'
  },
  selectChangeHandler: function (e, ui) {
    var view = this
    var driverWorkingHoursCalendarPopupView = view.getParentView()
    var driverWorkingHoursCalendarView = driverWorkingHoursCalendarPopupView.findSubView('driverWorkingHoursCalendar')

    driverWorkingHoursCalendarView.updateCalendarOnDriverChange()
  }
})

export default DriverSelectView
