import Model from '../Model';

const HeartBeatPopupModel = Model.extend({
  // idAttribute: 'refreshSmSeconds',
  defaults: {
    userId: null,
    heartbeatFrequency: 3,
    heartbeatDuration: 60
  },
  'url': 'api/lone-worker/heartbeat-settings'
});

export default HeartBeatPopupModel
