import CommentFormView from '../../CommentForm'
import CloseDefectCommentModel from '../../../model/report/vehicleDefectReport/CloseDefectComment'

const ClosedDefectCommentFormView = CommentFormView.extend({
  model: CloseDefectCommentModel,
  elSelector: '[data-form=comment]',
  initialize: function () {
    var view = this

    view.on('save', function (model) {
      var json = model.toJSON()
      var tableGridView = view.getParentView().getParentView()
      var $tr = tableGridView.$('tr[data-id=' + json.defectId + ']')

      tableGridView.model.get('tableRow').get(json.defectId).get('vehicleDefectCheckCommentDtoList').push(json)
      $tr.find('td:last').addClass('vdri-closed').attr('data-closed', '')
      $tr.find('[data-button=lock]').parent().text('Closed')
      tableGridView.resetSelectedItemAndRow()
      view.getParentView().hide()
    })
  },
  syncData: function () {
    var view = this

    view.model.set(view.getFormData())
    view.model.set('defectId', view.getParentView().getParentView().getActiveRowId())
  },
  destroy: function () {
    var view = this

    view.off('save')
    view.remove()
  }
})

export default ClosedDefectCommentFormView
