import $ from 'jquery'
import View from '../View'
import DriverWorkingHoursList from '../../collection/driverWorkingHours/DriverWorkingHoursList'

const DriverWorkingHoursTotalView = View.extend({
  templateUrl: 'template/driverWorkingHours/driverWorkingHoursTotal.html',
  elSelector: '[data-container=total]',
  collection: DriverWorkingHoursList,
  constructor: function () {
    var view = this

    view.collection = new view.collection()
    view.loadDotTemplate(function () {
      view.compileTemplate()
      view.resetElement()
      view.render({})
    })
    View.apply(view, arguments)
  },
  fetchTodayTotal: function () {
    var view = this
    var today = new Date()
    var vehicleId = $('[data-button=tachograph].active').closest('.row').find('input:hidden').val()
    var vehicleData = window.wls.getIdList()[vehicleId].data
    var param = {
      driverId: vehicleData.currentDriverId,
      startDate: (function () {
        today.setHours(0, 0, 0, 0)
        return today.getTime()
      })(),
      endDate: (function () {
        today.setHours(23, 59, 59, 999)
        return today.getTime()
      })()
    }

    return view.collection.fetch({ data: $.param(param) })
  },
  render: function (json) {
    var view = this
    var html = view.dotTemplateToHtml(json)

    view.$el.html(html)
  }
})

export default DriverWorkingHoursTotalView
