import CompactReportTableGridView from '../CompactReportTableGrid'
import LeagueTableExcludeDriversTableGridScrollView from './LeagueTableExcludeDriversTableGridScroll'

const LeagueTableExcludeDriversTableGridView = CompactReportTableGridView.extend({
  templateUrl: 'template/report/leagueTable/driverExcludeTableGrid.html',
  constructor: function (option) {
    var view = this

    view.setSubView({
      scroll: LeagueTableExcludeDriversTableGridScrollView
    })

    CompactReportTableGridView.apply(view, arguments)
  },
  initialize: function () {
    var view = this

    view.model = view.getParentView().getParentView().findSubView('compactReportTableGrid').model

    view.trigger('ready')
    view.render()
  },
  syncModel: function () {
    var view = this

    view.model = view.getParentView().getParentView().findSubView('compactReportTableGrid').model

    view.render()
  },
  renderEmptyRows: function (collection) {
    var view = this
    var maxEmptyRows = 0
    var rowCount = collection.models[0].attributes.greenDrivingNotAllowedRecordList.length || 0
    var emptyRowArray = []

    view.emptyRow = '<tr class="empty-row">'

    for (var i = 0; i < view.$('th').size(); i++) {
      view.emptyRow += '<td></td>'
    }

    view.emptyRow += '</tr>'

    view.$el.find('.empty-row').remove()
    if (rowCount < 6) {
      maxEmptyRows = 6 - rowCount
      for (i = 0; i < maxEmptyRows; i++) {
        emptyRowArray.push(view.emptyRow)
      }
      view.$el.find('tbody:last').append(emptyRowArray.join(''))
    }
    return this
  },
  destroy: function () {
    var view = this

    CompactReportTableGridView.prototype.destroy.apply(view, arguments)
  }
})

export default LeagueTableExcludeDriversTableGridView
