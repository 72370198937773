const sort = {
  parseSortValues: function (a, b, index) {
    var valA = a.children[index].getAttribute('data-sortValue')
    var valB = b.children[index].getAttribute('data-sortValue')
    valA = isNaN(valA) ? valA : parseInt(valA)
    valB = isNaN(valB) ? valB : parseInt(valB)

    return [valA, valB]
  },
  asc: function ($nodeList, index) {
    var obj = this

    return $nodeList.sort(function (a, b) {
      var values = obj.parseSortValues(a, b, index)

      return values[0] > values[1] ? 1 : -1
    })
  },
  desc: function ($nodeList, index) {
    var obj = this

    return $nodeList.sort(function (a, b) {
      var values = obj.parseSortValues(a, b, index)

      return values[0] < values[1] ? 1 : -1
    })
  }
}

export default sort
