/**
 * Created by George on 28.08.2015.
 */
import View from './View'
import SafeInputView from './SafeInput'

const CounterView = View.extend({
  events: {
    'click [data-button=up]': 'up',
    'click [data-button=down]': 'down'
  },
  initialize: function (option) {
    var option = option || {}
    var view = this
    var allowedChars = option.allowedChars || ['digits', 'edit', 'navigation']
    this.step = option.step || 1
    this.minVal = option.minVal || 0
    this.maxVal = option.maxVal || Infinity
    this.digitsAfterDecimalPoint = option.digitsAfterDecimalPoint || 1
    view.$input = this.$el.find('input:text')
    view.addSubView('safeInput', SafeInputView, { el: view.$el })
      .model
      .set('allowedChars', allowedChars)
  },
  up: function () {
    var val = parseFloat(this.$input.val())
    if (isNaN(val)) {
      val = 0
    }

    if (val + this.step > this.maxVal) {
      val = this.maxVal
    } else {
      val += this.step
    }

    this.$input.val(parseFloat(val.toFixed(this.digitsAfterDecimalPoint)))
  },
  down: function () {
    var val = parseFloat(this.$input.val())
    if (isNaN(val)) {
      val = 0
    }
    if (val - this.step < this.minVal) {
      val = this.minVal
    } else {
      val -= this.step
    }
    this.$input.val(parseFloat(val.toFixed(this.digitsAfterDecimalPoint)))
  },
  destroy: function () {
    this.remove()
  }
})

export default CounterView
