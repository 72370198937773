import Backbone from 'backbone'
import Hogan from 'hogan.js'
import dot from '../common/doT'
import formatDateToHms from '../util/formatDateToHms'
import formatDateToDMY from '../util/formatDateToDMY'
import extendProperty from '../util/extendProperty'
import formatDateToDayTime from '../util/formatDateToDayTime'
import resolveColor from '../util/resolveColor'
import somaTemplateEngine from 'soma-template'
import $ from 'jquery'
import _ from 'underscore'

// const somaTemplateEngine = require('soma-template')

const View = Backbone.View.extend({
  hogan: Hogan,
  dot,
  hoganTemplate: '',
  soma: somaTemplateEngine,
  templateOptions: null,
  somaTemplate: '',
  dotTemplate: '',
  templateUrl: '',
  isReady: false,
  mergeEvents: false,
  subView: null,
  parentView: null,
  elSelector: '',
  hoganRenderer: function (context) {
    var hoganTemplate = this.hoganTemplate
    return function (text) {
      return hoganTemplate.c.compile(text, hoganTemplate.options).render(context)
    }
  },
  hoganHelperManual: function (lambda) {
    var view = this
    var hoganRenderer = view.hoganRenderer
    return function () {
      return function (text) {
        var render = hoganRenderer.apply(view, [this])
        return lambda.call(this, text, render)
      }
    }
  },
  hoganHelperAutomatic: function (lambda) {
    return this.hoganHelperManual(function (text, render) {
      return lambda.call(this, render(text))
    })
  },
  hoganHelperFormatDate: function (lambda) {
    return this.hoganHelperAutomatic(function (text) {
      text = $.datepicker.formatDate('dd.mm.yy ', new Date(parseInt(text))) + formatDateToHms(parseInt(text))
      return lambda.call(this, text)
    })
  },
  //        compileTemplate: function(){
  //            this.hoganTemplate = this.hogan.compile(this.hoganTemplate)
  //            return this
  //        },
  //        loadTemplate:function(callback){
  //            require(['text!' + this.templateUrl],callback)
  //            return this
  //        },
  //        loadAndCompileTemplate: function(){
  //            var view = this
  //            view.loadTemplate(function(template){
  //                view.hoganTemplate = template
  //                view.compileTemplate()
  //            })
  //            return this
  //        },
  loadTemplate: function (callback) {
    $.get(`/js/html/app/src/${this.templateUrl}`).then((template) => {
      this.somaTemplate = template
      if (callback) {
        callback()
      }
    })
    // require(['text!' + view.templateUrl], function (template) {
    //   view.somaTemplate = template
    //   if (callback) {
    //     callback()
    //   }
    // })
    return this
  },
  loadDotTemplate: function (callback) {
    $.get(`/js/html/app/src/${this.templateUrl}`).then((template) => {
      this.dotTemplate = template
      if (callback) {
        callback()
      }
    })
    // require(['text!' + view.templateUrl], function (template) {
    //   view.dotTemplate = template
    //   if (callback) {
    //     callback()
    //   }
    // })
    return this
  },
  dotTemplateToHtml: function (json) {
    return this.dotTemplate(json, this.dot.helper, this.templateOptions)
  },
  compileTemplate: function () {
    this.dotTemplate = this.dot.compile(this.dotTemplate)
  },
  createTemplate: function (element) {
    if (this.somaTemplate) {
      this.somaTemplate = this.soma.create(this.somaTemplate, element)
      return this
    }
  },
  addSubView: function (viewName, View, viewOption) {
    View.prototype.parentView = this
    this.subView[viewName] = new View(viewOption)
    this.subView[viewName].parentView = this
    return this.subView[viewName]
  },
  hasSubView: function (viewName) {
    return this.subView.hasOwnProperty(viewName)
  },
  setSubView: function (object) {
    var parentView = this
    var _View
    var subViewPrototype

    if (this.subView) {
      object = _.extend(object, this.subView)
    }
    _.mapObject(object, function (property, key) {
      var option

      if (!(property instanceof Backbone.View)) {
        if ('view' in property) {
          subViewPrototype = property.view.prototype
          _View = property.view
          option = property.option
        } else {
          subViewPrototype = property.prototype
          _View = property
        }
        subViewPrototype.parentView = parentView
        object[key] = new _View(option)
        object[key].parentView = parentView
      }
    })
    this.subView = object
  },
  removeSubView: function (viewName) {
    delete this.subView[viewName]
  },
  findSubView: function (viewName) {
    return this.subView[viewName]
  },
  getParentView: function () {
    return this.parentView
  },
  findParentView: function (ParentView) {
    var view = this
    while (view = view.getParentView()) {
      if (view instanceof ParentView) {
        break
      }
    }
    return view
  },
  setParentView: function (view) {
    this.parentView = view
  },
  setDotTemplate: function (template) {
    this.dotTemplate = template
  },
  constructor: function (option) {
    var view = this
    var args = arguments
    if (option) {
      if (option.somaTemplate) {
        this.somaTemplate = option.somaTemplate
      }
      if (option.templateUrl) {
        this.templateUrl = option.templateUrl
      }
    }
    if (this.mergeEvents) {
      extendProperty(this, 'events')
    }
    if (!this.subView) {
      this.subView = {}
    } else if (!this.hasOwnProperty('subView')) { // in use for OOHA-6. Should remove this and fix OOHA
      this.setSubView(_.clone(this.subView))
    }
    Backbone.View.apply(view, args)
    if (_.size(view.subView)) {
      _.mapObject(view.subView, function (value, key) {
        value.once('ready', function () {
          var ready = true
          _.mapObject(view.subView, function (view) {
            if (!view.isReady) {
              ready = false
              return
            }
          })
          if (ready) {
            view.isReady = true
            view.trigger('ready')
          }
        })
        if (value.isReady) {
          value.trigger('ready')
        }
      })
    } else {
      this.isReady = true
      this.trigger('ready')
    }
  },
  disableEvent: function () {
    this.events['click tr'] = ''
  },
  enableEvent: function (selector, handler) {
    this.events[selector] = handler
  },
  isElementInDom: function () {
    return document.body.contains(this.el)
  },
  resetElement: function () {
    var view = this
    var parentView = view.getParentView()
    var $el

    if (!view.isElementInDom()) { // if element not in DOM
      if (parentView) {
        $el = parentView.$(view.elSelector)
      } else {
        $el = $(view.elSelector)
      }
      view.setElement($el)
    }
  }
})

// template helpers
somaTemplateEngine.helpers({
  formatDate: function (dateMilliseconds, noTime) {
    if (noTime) {
      return $.datepicker.formatDate('dd.mm.yy ', new Date(dateMilliseconds))
    } else {
      return $.datepicker.formatDate('dd.mm.yy ', new Date(dateMilliseconds)) + formatDateToHms(dateMilliseconds)
    }
  },
  if: function (variable, trueString, falseString) {
    if (arguments.length === 1) {
      if (variable) {
        return variable
      } else {
        return null
      }
    }
    if (variable) {
      return trueString
    } else {
      return falseString
    }
  }
})
dot.registerHelper('formatDate', function (dateMilliseconds) {
  return $.datepicker.formatDate('dd.mm.yy ', new Date(dateMilliseconds))
})
dot.registerHelper('formatDateToHM', function (milliseconds) {
  var date = new Date(milliseconds)
  var hours = date.getHours()
  var minutes = date.getMinutes()
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return hours + ':' + minutes
})
dot.registerHelper('formatDateToHms', formatDateToHms)
dot.registerHelper('formatDateToDMY', formatDateToDMY)
dot.registerHelper('resolveColor', resolveColor)
dot.registerHelper('formatDateToDayTime', formatDateToDayTime)
dot.registerHelper('convertToMilliseconds', function (string) {
  var milliseconds = 0
  var second = 1000
  var minute = second * 60
  var hour = minute * 60
  var day = hour * 24
  var timeArr = [day, hour, minute, second]
  var parsedTime = string.split(/days|:/)
  var parsedTimeLen = parsedTime.length
  var timeArrLen = timeArr.length
  for (var i = parsedTimeLen; i !== 0; i--) {
    milliseconds += timeArr[timeArrLen - i] * parseInt(parsedTime[(timeArrLen - i) - (timeArrLen - parsedTimeLen)])
  }
  return milliseconds
})
dot.registerHelper('formatMillisecondsToHM', function (milliseconds) {
  var hours = milliseconds / 60 / 60 / 1000
  var minutes = hours % 1 * 60

  hours -= hours % 1
  minutes -= minutes % 1
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return hours + 'h ' + minutes + 'm'
})
dot.registerHelper('getWeekNumber', function (date) {
  var target = new Date(date.valueOf())
  var dayNr = (date.getDay() + 6) % 7
  var firstThursday
  var retVal

  target.setDate(target.getDate() - dayNr + 3)
  firstThursday = target.valueOf()
  target.setMonth(0, 1)
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7)
  }
  retVal = 1 + Math.ceil((firstThursday - target) / 604800000)

  return retVal
})

export default View
