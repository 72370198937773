import IdlingReportFuelUsageSelectionMenuVehicleList from '../../../collection/report/idlingReportFuelUsage/IdlingReportFuelUsageSelectionMenuVehicleList'
import IdlingReportFuelUsageSelectionMenuVehicleListScrollView from './IdlingReportFuelUsageSelectionMenuVehicleListScroll'
import SelectionMenuVehicleListView from '../../SelectionMenuVehicleList'

const IdlingReportFuelUsageSelectionMenuVehicleListView = SelectionMenuVehicleListView.extend({
  collection: IdlingReportFuelUsageSelectionMenuVehicleList,
  templateUrl: 'template/report/idlingReportFuelUsage/idlingReportFuelUsageSelectionMenuVehicleList.html',
  elSelector: '[data-vehicle-list=idlingReportFuelUsage]',
  initialize: function () {
    var view = this

    view.addSubView('scroll', IdlingReportFuelUsageSelectionMenuVehicleListScrollView)
    view.render()
  }
})

export default IdlingReportFuelUsageSelectionMenuVehicleListView
