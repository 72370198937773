import FormView from '../../../Form'
import ChecklistFormModel from '../../../../model/report/vehicleDefectReport/ChecklistForm'
import ChecklistTableGridView from './ChecklistModalTableGrid'

const ChecklistFormView = FormView.extend({
  model: ChecklistFormModel,
  mergeEvents: true,
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form')
    view.el = view.$el[0]
    view._html = null

    view.addSubView('compactReportTableGrid', ChecklistTableGridView, this.$('[data-table-grid=checklist-constructor]'))

    FormView.apply(this, arguments)
  },
  destroy: function () {
    FormView.prototype.destroy.apply(this, arguments)
  },
  syncData: function () {
    var view = this

    view.model.set(this.getFormData())
    view.submit()
  },
  submittedAction: function () {
    var view = this
    var parentView = view.getParentView()
    var checklistView = parentView.getParentView().findSubView('checklistsModal')
    var vehicleListModal = parentView.getParentView().findSubView('vehicleListModal')

    parentView.hide()

    if (checklistView.editebleChecklist) {
      setTimeout(function () {
        checklistView.getList()
        checklistView.show()
        checklistView.editebleChecklist = false
      }, 1000)
    }

    if (checklistView.duplChecklist) {
      setTimeout(function () {
        checklistView.getList()
        checklistView.show()
        checklistView.duplChecklist = false
      }, 1000)
    }

    if (vehicleListModal.vehicleListActive) {
      vehicleListModal.getList()
      vehicleListModal.show()
    }
  },
  submit: function () {
    var view = this

    if (!view.model.isClientSide()) {
      view.model.save().done(function () {
        window.systemAlert.initMsg('Checklist successfully saved')

        view.submittedAction()
        view.trigger('save', view.model)
      }).error(function (error) {
        window.systemAlert.initError('Checklist name is required', null)
        console.log(error)
        setTimeout(function () {
          window.systemAlert.hideAlert()
        }, 3000)
      })
    } else {
      view.trigger('save', view.model)
    }
  },
  isModelEmpty: function (model) {
    if (model.data === undefined || model.data == null) {
      return true
    } else {
      return model.data && !model.data.length
    }
  }
})

export default ChecklistFormView
