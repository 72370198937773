/**
 * Created by George on 12.08.2017.
 */
import AlertTableGrid from '../AlertTableGrid';
import DriverBehaviorAlertList from '../../../collection/alert/driverBehavior/DriverBehaviorAlertList';

const DriverBehaviorAlertTableGrid = AlertTableGrid.extend({
    defaults : {
        tableRow: DriverBehaviorAlertList
    }
})

export default DriverBehaviorAlertTableGrid;
