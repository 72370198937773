/**
 * Created by George on 22.07.2015.
 */
import ProfileBuilderFormView from '../profileManager/ProfileBuilderForm';
import OverspeedProfileBuilderFormModel from '../../../model/alert/overspeed/OverspeedProfileBuilderForm';
import OverspeedProfileBuilderTabsetView from '../overspeed/OverspeedProfileBuilderTabset';

const OverspeedProfileBuilderForm = ProfileBuilderFormView.extend({
    model: OverspeedProfileBuilderFormModel,
    ProfileBuilderTabsetView: OverspeedProfileBuilderTabsetView,
    setFields: function(formData) {
        ProfileBuilderFormView.prototype.setFields.apply(this, arguments);
        formData.overspeed = settings.data.distanceMetric === "Miles" ? this.convertMphToKmh(formData.overspeed) : formData.overspeed;
    },
    render: function() {
        const $el = this.$el;
        const json = this.model.toJSON();

        if (settings.data.distanceMetric === "Miles") {
            json.overspeed = this.convertKmhToMph(json.overspeed);
        }

        Object.entries(json).forEach(([key, val]) => {
            const $formElement = $el.find(`[name=${key}]`);
            if ($formElement.is("input:checkbox")) {
                $formElement.prop("checked", val);
            } else if ($formElement.is("input:radio")) {
                $formElement.filter(`[value=${val}]`).prop("checked", true);
            } else {
                $formElement.val(val);
            }
        });
    },
    convertKmhToMph: function(kilometres) {
        return Math.ceil(kilometres * 0.621371);
    },
    convertMphToKmh: function(miles) {
        return Math.floor(miles * 1.60934);
    }
});

export default OverspeedProfileBuilderForm;
