import $ from 'jquery'
import View from '../../View'
import CompactReportView from '../CompactReport'
import VehicleDefectReportFormView from './VehicleDefectReportForm'
import VehicleDefectReportTableGridView from './VehicleDefectReportTableGrid'
import NotificationsPopupView from './NotificationsPopup'
import ChecklistModalView from './checklist/ChecklistModal'
import CheckListsModalView from './checklist/CheckListsModal'
import VehicleListModalView from './checklist/VehicleListModal'

const VehicleDefectReportView = CompactReportView.extend({
  mergeEvents: true,
  templateUrl: 'template/report/vehicleDefectReport/vehicleDefectReport.html',
  events: {
    'click [data-flex]': 'showManageDrivers',
    'click [data-popup=notes] [data-button=close]': 'hideNote',
    'click [data-button=notifications]': 'showNotifications',
    'click [data-button=newChecklist]': 'showAddChecklist',
    'click [data-button=checklists]': 'showCheckLists',
    'click [data-button=vehicleList]': 'showVehicleList',
  },
  initialize: function (option) {
    var view = this

    view.addSubView('notificationsPopup', NotificationsPopupView)
    view.addSubView('vehicleListModal', VehicleListModalView)
    view.addSubView('checklistsModal', CheckListsModalView)
    view.addSubView('checklistModal', ChecklistModalView)
    view.addSubView('compactReportTableGrid', VehicleDefectReportTableGridView, this.$('[data-table-grid=vehicle-defect]')).once('ready', function () {
      view.addSubView('compactReportForm', VehicleDefectReportFormView)
    })
  },
  hideNote: function () {
    this.findSubView('compactReportTableGrid').hideNote()
  },
  showManageDrivers: function (e) {
    var view = this
    var $button = $(e.currentTarget)

    $('iframe').attr('src', $button.attr('data-flex'))
    $('.modal-popup .header-panel .title').text('Manage Drivers')
  },
  showVehicleList: function () {
    var view = this
    var vehicleListModal = view.findSubView('vehicleListModal')

    vehicleListModal.getList()
    vehicleListModal.show()
    vehicleListModal.align()
  },
  showAddChecklist: function (e) {
    var view = this
    var checklistModal = view.findSubView('checklistModal')

    checklistModal.loadDefaultList()
  },
  showCheckLists: function () {
    var view = this
    var checklistsModal = view.findSubView('checklistsModal')

    checklistsModal.getList()
    checklistsModal.show()
    checklistsModal.align()
  },
  showNotifications: function (e) {
    var $button = $(e.currentTarget)
    var view = this
    var notificationsPopupView = view.findSubView('notificationsPopup')
    var vehicleDefectReportTableGridView = view.findSubView('compactReportTableGrid')

    vehicleDefectReportTableGridView.hideActivePopup()
    vehicleDefectReportTableGridView.resetSelectedItemAndRow()
    notificationsPopupView.renderForm()
    notificationsPopupView.alignBy($button, 'center', { top: 5 })
    notificationsPopupView.show()
  },
  hideNotifications: function () {
    var view = this

    view.findSubView('notificationsPopup').hide()
  },
  slide: function (e) { // override
    this.findSubView('compactReportTableGrid').hideActivePopup()
    this.hideNotifications()
    CompactReportView.prototype.slide.apply(this, arguments)
  },
  destroy: function () {
    this.findSubView('notificationsPopup').destroy()
    CompactReportView.prototype.destroy.apply(this, arguments)
  }
})

export default VehicleDefectReportView
