import Model from '../Model'

const RefreshSettingsModel = Model.extend({
  idAttribute: 'refreshSmSeconds',
  defaults: {
    refreshSmSeconds: 60
  },
  'urlRoot': 'api/settings/refreshSmSeconds'
})

export default RefreshSettingsModel
