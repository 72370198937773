/**
 * Created by George on 10.04.2017.
 */
import ProfileBuilderTabsetView from '../profileManager/ProfileBuilderTabset';

const OverspeedProfileBuilderTabset = ProfileBuilderTabsetView.extend({
    templateUrl: "template/alert/overspeed/overspeedProfileBuilderTabset.html",
    initialize: function () {
        ProfileBuilderTabsetView.prototype.initialize.apply(this, arguments);
        if (window.settings) {
            this.$el.find('.distance-metric').text(window.settings.data.distanceMetric === 'Miles' ? 'mph.' : 'kmh.');
        } else {
            this.$el.find('.distance-metric').text('mph.');
        }
    }
});

export default OverspeedProfileBuilderTabset;
