import Model from '../../Model'

const LeagueTableReportFormModel = Model.extend({
  defaults: {
    unit_id: '',
    month: '',
    year: ''
  },
  url: 'api/driver-behaviour/league-table'
})

export default LeagueTableReportFormModel
