import Model from '../../Model'

const GeoMonitorModel = Model.extend({
  defaults: {
    id: null,
    logId: null,
    vehReg: '',
    regDriver: '',
    date: null,
    time: null,
    poiAoiName: '',
    entryExit: '',
    geoFenceAlert: '',
    alertName: '',
    recipient: '',
  }
})

export default GeoMonitorModel
