import View from '../../View'
import AoiListView from './AoiList'

const AoiListAlertView = View.extend({
  el: '#content',
  events: {
    'click .close-btn': 'closeBtnHandler'
  },
  initialize: function () {
    var view = this

    view.addSubView('aoiList', AoiListView)
    view.render()
  },
  closeBtnHandler: function () {
    this.destroy()
  },
  destroy: function () {
    var view = this

    view.findSubView('aoiList').destroy()
  }
})

export default AoiListAlertView
