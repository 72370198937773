import ReportGroupTabsetScrollView from './ReportGroupTabsetScroll'
import GroupTabsetView from './GroupTabset'

const ReportGroupTabsetView = GroupTabsetView.extend({
  addScrollView: function () { // override
    this.addSubView('scroll', ReportGroupTabsetScrollView)
  },
  selectGroupHandler: function (e) {
    var view = this

    view.getParentView().selectGroupHandler(e)
  }
})

export default ReportGroupTabsetView
