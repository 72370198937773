import Model from '../../model/Model'

const ReportFormModel = Model.extend({
  defaults: {
    unitIdList: '',
    driverIdList: '',
    from: '',
    fromTime: '',
    to: '',
    toTime: '',
    repFormat: '',
    excludeWeekends: false,
    groupJourneysByDriver: false,
    negativeDriving: false,
    fromDate: null,
    toDate: null,
    removeZeroCostJourneys: false
  },
  validate: function (attrs, options) {
    if (!attrs.unitIdList && !attrs.driverIdList) {
      alert('Select at least one vehicle.')
      return 'Select at least one vehicle.'
    }
  }
})

export default ReportFormModel
