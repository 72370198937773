import $ from 'jquery'
import CommandList from '../../collection/editVehicle/CommandList'
import View from '../View'

const CommandListView = View.extend({
    templateUrl: 'template/editVehicle/commandList.html',
    collection: CommandList,
    constructor: function (option) {
        var view = this
        var args = arguments
        // this.model = new this.model()
        this.el = option.el
        this.$el = $(this.el)
        this.collection = new this.collection()

        view.loadDotTemplate(function () {
            view.compileTemplate()
            View.apply(view, args)
        })
    },
    initialize: function () {
        var view = this
    },
    fetchList: function (options) {
        this.collection.fetch(options)
    },
    fetchListSuccess: function (defaultOption) {
        this.render(defaultOption)
    },
    render: function (defaultOption) {
        var noneOption = '<option value="0">None</option>'
        var html = $(noneOption + this.dotTemplateToHtml(this.collection.toJSON()))
        var $select = this.getParentView().$el
        var defaultOptionValue = null
        this.$el.html(html)

        defaultOptionValue = $select.selectmenu().find('option:contains(' + defaultOption + ')').attr('value')
        $select.selectmenu().val(defaultOptionValue)
        $select.selectmenu('refresh')
    }
})

export default CommandListView
