import $ from 'jquery'
import PopupView from '../../Popup'
import DetailedVehicleDefectReportView from './DetailedVehicleDefectReport'
import DetailedVehicleDefectReportPopupModel from '../../../model/report/vehicleDefectReport/DetailedVehicleDefectReportPopup'
import DetailedVehicleDefectReportImagePopupView from './DetailedVehicleDefectReportImagePopup'

const DetailedVehicleDefectReportPopupView = PopupView.extend({
  url: 'js/html/app/src/template/report/vehicleDefectReport/detailedVehicleDefectReportPopup.html',
  mergeEvents: true,
  model: DetailedVehicleDefectReportPopupModel,
  events: {
    'click [data-button=pdf]': 'exportToPdf'
  },
  initialize: function () {
    this.addSubView('detailedVehicleDefectReport', DetailedVehicleDefectReportView)
    this.addSubView('imagePopup', DetailedVehicleDefectReportImagePopupView)
  },
  closeBtnHandler: function () {
    var view = this
    view.getParentView().resetSelectedItemAndRow()
    view.hide()
  },
  exportToPdf: function () {
    window.open('/load-report?' + this.generatePdfReportURL())
  },
  generatePdfReportURL: function () {
    var view = this
    var data = {
      id: view.getParentView().getActiveRowId(),
      'report-name': 'VehicleDefectReport',
      'report-type': 'VehicleDefectReportDetailed',
      'report-format': 'PDF'
    }
    return $.param(data)
  },
  destroy: function () {
    var view = this

    view.findSubView('detailedVehicleDefectReport').destroy()
    view.remove()
  }
})

export default DetailedVehicleDefectReportPopupView
