import View from './View'
import JqueryUiWidgetModel from '../model/JqueryUiWidget'
import _ from 'underscore'

const JqueryUiWidgetView = View.extend({
  model: JqueryUiWidgetModel,
  constructor: function (option) {
    var view = this
    view.model = new view.model()
    view.listenTo(view.model, 'change', view.render)
    View.apply(this, arguments)
  },
  initialize: function () {
    this.render()
  },
  renderOption: function () {
    var view = this

    _.mapObject(view.model.changed, function (val, key) {
      view.$el[view.model.get('widgetName')]('option', key, val)
    })
  },
  render: function (model) {
    var view = this
    if (model) {
      view.renderOption()
    } else {
      this.$el[view.model.get('widgetName')](this.model.attributes)
    }
  }
})

export default JqueryUiWidgetView
