import JourneyReportSelectionMenuVehicleListView from './JourneyReportSelectionMenuVehicleList'
import JourneyReportSelectionMenuModel from '../../../model/report/journeyReport/JourneyReportSelectionMenu'
import ReportSelectionMenuView from '../../ReportSelectionMenu'

const JourneyReportSelectionMenuView = ReportSelectionMenuView.extend({
  model: JourneyReportSelectionMenuModel,
  templateUrl: 'template/report/journeyReport/journeyReportSelectionMenu.html',
  elSelector: '[data-selection-menu=journey]',
  initialize: function () {
    var view = this

    view.addSubView('vehicleList', JourneyReportSelectionMenuVehicleListView)
    view.render()
  }
})

export default JourneyReportSelectionMenuView
