/**
 * Created by George on 29.05.2015.
 */
/**
 * Created by George on 25.03.2015.
 */
import AlertPanelView from '../AlertPanel';
import OverspeedAlertListPopupView from './OverspeedAlertListPopup';
import OverspeedAlertFormView from './OverspeedAlertForm';
import OverspeedProfileManagerPopupView from './OverspeedProfileManagerPopup';

const OverspeedAlert = AlertPanelView.extend({
    constructor: function (option) {
        this.setSubView({});
        this.AlertListPopupView = OverspeedAlertListPopupView; // use addSubView
        this.tabsetName = "overspeedAlert";
        this.ProfileManagerPopupView = OverspeedProfileManagerPopupView;
        if (option.el) {
            this.$el = option.el;
            this.el = option.el[0];
        } else {
            this.$el = $("[data-tab=outOfHoursAlert]");
            this.el = this.$el[0];
        }
        this.addSubView("alertForm", OverspeedAlertFormView);
        AlertPanelView.apply(this, arguments);
    },
    initialize: function() { // should remove this
        const view = this;
        view.alertListPopupView.on("ready", function() {
            this.alertListTableGridView.on("itemSelect", function($tr, id) {
                const activeTabName = view.tabsetView.model.get("activeTab");
                if (view.model.get("dataMode") === "edit") {
                    if (activeTabName === "selectRecipient") {
                        if (view.tabsetView.selectProfile) {
                            view.tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.set(this.selectedItem[id].get("selectedAlertProfileList"));
                        }
                    } else {
                        if (view.tabsetView.selectRecipient) {
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").collection.set(this.selectedItem[id].get("selectedAlertRecipientList"));
                        }
                    }
                } else {
                    if (activeTabName === "selectRecipient") {
                        if (view.tabsetView.selectProfile) {
                            view.tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.set([]);
                        }
                    } else {
                        if (view.tabsetView.selectRecipient) {
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").collection.set([]);
                        }
                    }
                }
            });
        });
    }
});

export default OverspeedAlert;
