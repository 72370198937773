import Model from '../Model'

const CompactReportFormModel = Model.extend({
  defaults: {
    from: '',
    to: ''
  },
  validate: function (attrs, options) {
    var fromDateStringArray = attrs.from.split(/[//.\s:]/ig)
    var toDateStringArray = attrs.to.split(/[//.\s:]/ig)
    var fromDate = new Date(parseInt(fromDateStringArray[2]), parseInt(fromDateStringArray[1]) - 1, parseInt(fromDateStringArray[0]), parseInt(fromDateStringArray[3]), parseInt(fromDateStringArray[4]))
    var toDate = new Date(parseInt(toDateStringArray[2]), parseInt(toDateStringArray[1]) - 1, parseInt(toDateStringArray[0]), parseInt(toDateStringArray[3]), parseInt(toDateStringArray[4]))
    var message = {
      invalid_date_range: 'Please verify date range.',
      invalid_date_format: 'Please verify date format.'
    }
    var json = {
      button: ['Ok']
    }
    if (!(/[0-9]{2}\.[0-9]{2}\.[0-9]{4}\s[0-9]{2}:[0-9]{2}/).test(attrs.from) || !(/[0-9]{2}\.[0-9]{2}\.[0-9]{4}\s[0-9]{2}:[0-9]{2}/).test(attrs.to)) {
      json.msgId = 'invalid_date_format'
      json.title = 'Date format verification'
      json.message = message[json.msgId]
      return json
    }
    if (fromDate > toDate || fromDate === '' || toDate === '') {
      json.msgId = 'invalid_date_range'
      json.title = 'Date range verification'
      json.message = message[json.msgId]
      return json
    }
  }
})

export default CompactReportFormModel
