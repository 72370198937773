import Model from '../../Model'
import Validator from '../../../util/Validator'

const GeoFenceAlertModel = Model.extend({
  urlRoot: '/services/geofence/alert',
  defaults: {
    id: null,
    name: '',
    selectedAlertRecipientList: [],
    selectedPoiList: [],
    selectedAoiList: [],
    selectedVehicleList: [],
    sendToSelf: true,
    active: true
  },
  validate: function (attrs, options) {
    var message = {
      empty_alert_name: 'Please enter Alert name',
      wrong_alert_name: 'Please verify the Alert name format.'
      // profile_not_selected: 'Please select profile.'
    }
    var json = {
      button: ['Ok']
    }
    if (attrs.name.trim() === '') {
      json.msgId = 'empty_alert_name'
      json.title = 'Alert name verification'
      json.message = message[json.msgId]
      return json
    }
    if (!Validator.checkValidity('alertName', attrs.name)) {
      json.msgId = 'wrong_alert_name'
      json.title = 'Alert name verification'
      json.message = message[json.msgId]
      return json
    }
    // if(!attrs.selectedAlertProfileList.length){
    //     json.msgId = 'profile_not_selected'
    //     json.title = 'Profile verification'
    //     json.message = message[json.msgId]
    //     return json
    // }
  }
})

export default GeoFenceAlertModel
