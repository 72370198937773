import Backbone from 'backbone'
import $ from 'jquery'
import FormView from '../Form'
import LoneWorkerActivityLogFormModel from '../../model/loneWorker/LoneWorkerActivityLogForm'
import DropdownTimePickerView from '../DropdownTimePicker'
import SafeInputView from '../SafeInput'

const LoneWorkerActivityLogFormView = FormView.extend({
  model: LoneWorkerActivityLogFormModel,
  elSelector: '[data-form=refresh]',
  mergeEvents: true,
  events: {
    'click [data-button=pdf]': 'exportToPdf',
    'click [data-button=xls]': 'exportToXls',
    'keyup #fromTime-flexdatalist': 'onKeyUpFromTime',
    'keyup #toTime-flexdatalist': 'onKeyUpToTime'
  },
  onKeyUpFromTime: function (e) {
    var view = this
    if (!isNaN(e.key)) {
      e.preventDefault()
      var cursorPosition = view.$('#fromTime-flexdatalist').getCursorPosition()
      view.$('#fromTime').val(view.$('#fromTime-flexdatalist').val())
      view.$('#fromTime-flexdatalist').setCursorPosition(cursorPosition)
    }
  },
  onKeyUpToTime: function (e) {
    var view = this
    if (!isNaN(e.key)) {
      e.preventDefault()
      var cursorPosition = view.$('#toTime-flexdatalist').getCursorPosition()
      view.$('#toTime').val(view.$('#toTime-flexdatalist').val())
      view.$('#toTime-flexdatalist').setCursorPosition(cursorPosition)
    }
  },
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form:first')
    view.el = view.$el[0]
    view.addSubView('dateTimePickerRange', DropdownTimePickerView)
    view.addSubView('safeInputFromTime', SafeInputView, { el: view.$('#fromTime-flexdatalist') }).model.set('allowedChars', ['digits', 'colon', 'edit', 'navigation'])
    view.addSubView('safeInputToTime', SafeInputView, { el: view.$('#toTime-flexdatalist') }).model.set('allowedChars', ['digits', 'colon', 'edit', 'navigation'])

    FormView.apply(this, arguments)
  },
  initialize: function (option) {
    var view = this

    view.model.set('userId', window.wls.getLastClickedVehicle().data.id)
    view.resetElement()
    view.findSubView('dateTimePickerRange').today()
    view.syncData()
    view.submit()
  },
  submit: function () {
    var view = this
    var tableGrid = view.getParentView().findSubView('compactReportTableGrid')
    var parentView = view.getParentView()
    parentView.clearMap()
    view.$('input:checkbox:checked').prop('checked', false)
    parentView.plotModeOn = false
    tableGrid.logsOnMap = []

    if (tableGrid) {
      tableGrid.model.set('tableRow', [])
      tableGrid.render(function () {
        tableGrid.getParentView().$('.table-spinner').addClass('active')
      })

      var cmodel = this.model

      var dateParams = cmodel.get('date').split(/[\.]/)
      var yyyy = dateParams[2]
      var mm = dateParams[1]
      var dd = dateParams[0]
      var dateStr = mm + '/' + dd + '/' + yyyy
      var from = Date.parse(dateStr + ' ' + cmodel.get('fromTime'))
      var to = Date.parse(dateStr + ' ' + cmodel.get('toTime'))

      var params =
      {
        fromDate: from,
        toDate: to,
        userId: cmodel.get('userId')
      }

      Backbone.sync('read', view.model, {
        data: $.param(params),
        success: function (collection) {
          tableGrid.model.set('tableRow', collection)
          window.toPoiCollection = collection
          var sortData = tableGrid.findSubView('tableDrop')
          tableGrid.updateLogs()
          tableGrid.refreshLogOrder(sortData.sortData.sortOrder)
          tableGrid.render()
          tableGrid.findSubView('scroll').render()
          tableGrid.getParentView().$('.table-spinner').removeClass('active')
          parentView.setPlotAllActive(collection)
          parentView.$el.css('height', '')
        },
        error: function (msg) {
          console.log(msg.status + ' ' + msg.statusText)
          tableGrid.getParentView().$('.table-spinner').removeClass('active')
          parentView.setPlotAllActive(false)
          parentView.$el.css('height', '')
        }
      })
    }
  },

  destroy: function () {
    var view = this
    view.remove()
  },

  exportToPdf: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('PDF'))
  },
  exportToXls: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('XLS'))
  },
  generatePdfXlsReportURL: function (repFormat) { // implementation
    var model = this.model
    var dateParams = model.get('date').split(/[\.]/)
    var yyyy = dateParams[2]
    var mm = dateParams[1]
    var dd = dateParams[0]
    var dateStr = mm + '/' + dd + '/' + yyyy
    var from = Date.parse(dateStr + ' ' + model.get('fromTime'))
    var to = Date.parse(dateStr + ' ' + model.get('toTime'))
    var data = {
      'report-name': 'LoneWorkerActivityLog',
      'report-type': 'LoneWorkerActivityLog',
      'report-format': repFormat,
      fromDate: from,
      toDate: to,
      userId: model.get('userId'),
      timeOffsetMinutes: new Date().getTimezoneOffset()
    }
    return $.param(data)
  }

})

export default LoneWorkerActivityLogFormView
