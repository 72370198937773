import IdlingReportFuelUsagePopupView from './IdlingReportFuelUsagePopup'
import IdlingReportFuelUsageSelectionMenuView from './IdlingReportFuelUsageSelectionMenu'
import ReportPageView from '../ReportPage'
import IdlingReportFuelUsageView from './IdlingReportFuelUsage'

const IdlingReportFuelUsagePageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="idlingReportFuelUsage"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', IdlingReportFuelUsagePopupView)
    view.addSubView('selectionMenu', IdlingReportFuelUsageSelectionMenuView)
    view.addSubView('report', IdlingReportFuelUsageView)
    view.render()
  }
})

export default IdlingReportFuelUsagePageView
