import $ from 'jquery'
import View from '../../view/View'
import GroupTabsetGroupList from '../../collection/groupTabset/GroupTabsetGroupList'
import GroupTabsetScrollView from './GroupTabsetScroll'
import GroupColorPickerView from './GroupColorPicker'

const GroupTabsetView = View.extend({
  templateUrl: 'template/groupTabset.html',
  collection: GroupTabsetGroupList,
  elSelector: '[data-tabset=group]',
  events: {
    'click [data-item=group]>a': 'selectGroupHandler',
    'click [data-icon=color]': 'showColorPicker'
    // 'mouseover [data-scroll=groupTabset] ': 'onScroll'
  },
  initialize: function () {
    var view = this

    view.collection = new view.collection()
    view.addScrollView()
    view.loadDotTemplate(function () {
      view.compileTemplate()
      view.collection.fetch({
        success: function () {
          view.resetElement()
          view.addSubView('colorPicker', GroupColorPickerView)
          view.render()
        }
      })
    })

    view.listenTo(view.collection, 'change:colour', function (model, colour) {
      view.$('[data-id=' + model.get('id') + ']').css('background-color', colour)
    })
  },
  addScrollView: function () {
    var view = this

    view.addSubView('scroll', GroupTabsetScrollView)
  },
  render: function () {
    var view = this
    var json = view.collection.toJSON()
    var $tabset = $(view.dotTemplateToHtml(json))

    view.$el.find('[data-scroll=groupTabset] .slidee').html($tabset)
    view.selectGroup($.cookie('lastViewedGroup'))
    view.renderScroll()
  },
  renderScroll: function () {
    var view = this
    var leftPanel = $('.left-panel')
    var $fr = leftPanel.find('[data-tabset=group] [data-scroll=groupTabset] .scroll-content')

    $fr.sly({
      mouseDragging: 83,
      touchDragging: 83,
      releaseSwing: 83,
      speed: 300,
      easing: 'linear',
      scrollBar: $fr.parent().find('.scrollbar'),
      scrollBy: 83,
      dragHandle: 1,
      dynamicHandle: 1,
      clickBar: 1
    }, {
      move: function () {
        view.onScroll(this, $fr)
      },
      load: function () {
        view.onScroll(this, $fr)
      }
    })

    view.findSubView('scroll').render()
  },
  onScroll: function (el, frame) {
    $(frame).find('.slidee').find('li').each(function (i) {
      if (($(this)[0].offsetTop + 5) > (el.pos.cur + $(frame).outerHeight() - 83)) {
        $(this).css('visibility', 'hidden')
      } else {
        $(this).css('visibility', 'visible')
      }
    })
  },
  showColorPicker: function (e) {
    var view = this
    var $group = $(e.currentTarget).parents('li.active')
    var groupId = parseInt($group.attr('data-id'), 10)
    var top = $group.offset().top + $group.outerHeight() / 2
    var left = $group.offset().left + 340
    var colorPickerView = view.findSubView('colorPicker')
    var formView = colorPickerView.findSubView('form')

    var $wrapper = $('.wrapper')
    var $colorPicker = $wrapper.find('.color-picker')

    $colorPicker.detach()
    $wrapper.append($colorPicker)

    $colorPicker.draggable({
      containment: '.wrapper',
      cancel: '.close-btn',
      handle: $colorPicker.find('.header-panel')
    })

    $('[data-dropdown=colorPicker] [name=colour]').wheelColorPicker('setValue', view.collection._byId[groupId].attributes.colour)

    if (top + colorPickerView.$el.outerHeight() > $(document).height()) {
      top = $(document).height() - colorPickerView.$el.outerHeight()
    }

    colorPickerView.show(top, left)
    formView.model.set(view.collection.get(groupId).toJSON())
    formView.render()
    e.preventDefault()
  },
  vehicleHolderHandler: function ($el) {
    var $slidee = $el.find('.right-col .vehicle-holder .slidee')
    var $slideeLength = $slidee.find('>.row').length

    if (window.view.state !== 'fleet') {
      if ($slideeLength < 3) {
        $slidee.find('.row:visible .info').slideDown(600)
        $el.find('.right-col .control-panel').css('display', 'none')
        // $el.find('.right-col .vehicle-holder').css('min-height', '328px')
      } else {
        $el.find('.right-col .control-panel').css('display', 'block')
        // $el.find('.right-col .vehicle-holder').css('min-height', '300px')
      }
    }
  },
  selectGroupHandler: function (e) {
    var view = this
    var groupDescr = $(e.currentTarget).attr('title')
    var $group = $(e.currentTarget).parent()
    var groupId = $group.attr('data-id')

    $.cookie('lastViewedGroupDescr', groupDescr)
    // Multiple Select
    $('.multiple-select-btn').removeClass('active')
    $('body').css('cursor', 'auto')
    window.wlsMapTools.multipleSelectToolIsActive = false
    if (window.settings.data.mapSource && window.settings.data.mapSource.startsWith('MSVE')) {
      window.mapCanvas.map.entities.pop()
    } else if (window.settings.data.mapSource && window.settings.data.mapSource === 'GOOGLEMAPS') {
      window.mapCanvas.cleanUp()
    }
    // Multiple Select
    $('.hover-tooltip').attr('data-tooltip', groupDescr)
    $('.groups-tooltip').text(groupDescr)

    view.selectGroup(groupId)
    window.wls.resetSavedStates()
    e.preventDefault()
  },
  selectGroup: function (groupId) {
    var view = this
    var $group = view.$('[data-id=' + groupId + ']')
    var selectedGroup = $.cookie('lastViewedGroup')
    var leftPanel = $('.left-panel')
    var $fr = leftPanel.find('[data-tabset=group] [data-scroll=groupTabset] .scroll-content')
    var accountId = $('.left-col').find('[value=' + groupId + ']').closest('li:has(*[data-accountid])').children().attr('data-accountid')
    var colorPickerView = view.findSubView('colorPicker')

    if (window.searchVehicle) {
      if (leftPanel.find('.actions-holder').is(':Visible')) {
        window.searchVehicle.actionHolderHide()
      }
    }

    colorPickerView.hide()
    $group.addClass('active').siblings().removeClass('active')
    $fr.find('[data-id=' + groupId + ']').find('.color-icon').addClass('active').parents('li.active').siblings().find('.color-icon.active').removeClass('active')
    if (selectedGroup !== groupId) {
      selectedGroup = groupId
      window.sendCurrentAccount(accountId)

      window.wls.requestCars(selectedGroup, function () {
        window.panel.resetSortOrder()
        leftPanel.find('.expand').removeClass('active')
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize', [leftPanel.find('.vehicle-holder .slidee').height()])
        window.wlsMapTools.reset()
        window.proximity.generateSelectVehicleDropdown()

        view.vehicleHolderHandler(leftPanel)

        if (window.reportHandler.hasActiveReport()) {
          $(document).triggerHandler('vehicleListChange')
        }
        if (window.view.getActiveState() === 'activity') {
          window.view.switchStateTo('fleet')
        }

        $.cookie('lastViewedGroup', selectedGroup)
        window.wls.resetLastZoomed()
      })
    } else {
      view.vehicleHolderHandler(leftPanel)
    }

    view.renderScroll()
    $fr.sly('toStart', $group)
  },
  destroy: function () {
    this.findSubView('scroll').destroy()
    this.remove()
  }
})

export default GroupTabsetView
