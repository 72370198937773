import ModalReportPageView from '../ModalReportPage'
import DrivingTimeReportPopupView from './DrivingTimeReportPopup'

const DrivingTimeReportPageView = ModalReportPageView.extend({
  el: '<div class="report-page" data-report-page="driving-time"></div>',
  initialize: function () {
    console.log('initialize()')
    var view = this
    view.addSubView('reportPopup', DrivingTimeReportPopupView)
    view.render()
  }
})

export default DrivingTimeReportPageView
