import AlertTableGridModel from '../AlertTableGrid'
import TowAwayAlertList from '../../../collection/alert/towAway/TowAwayAlertList'

const TowAwayAlertTableGridModel = AlertTableGridModel.extend({
  defaults: {
    tableRow: TowAwayAlertList
  }
})

export default TowAwayAlertTableGridModel
