import $ from 'jquery'
import _ from 'underscore'
import CommentListView from '../../CommentList'
import VehicleDefectReportCommentListScrollView from './VehicleDefectReportCommentListScroll'

const VehicleDefectCommentListView = CommentListView.extend({
  initialize: function () {
    var view = this

    view.addSubView('scroll', VehicleDefectReportCommentListScrollView)
  },
  render: function (list) { // override
    var view = this
    var vehicleDefectReportCommentPopupView = view.getParentView()
    var json

    view.collection.set(list)
    json = _.sortBy(view.collection.toJSON(), 'createdAt').reverse()

    if (document.body.contains(view.el)) {
      view.$el.html($(view.dotTemplateToHtml(json)).html())
    } else {
      vehicleDefectReportCommentPopupView.$el.append(view.dotTemplateToHtml(json))
      view.resetElement()
    }
    view.findSubView('scroll').render()
  },
  destroy: function () {
    var view = this

    view.findSubView('scroll').destroy()
    view.remove()
  }
})

export default VehicleDefectCommentListView
