import $ from 'jquery'
import ScrollView from '../../view/Scroll'
import GroupTabsetScrollView from './GroupTabsetScroll'

const ReportGroupTabsetScrollView = GroupTabsetScrollView.extend({
  initialize: function () {
    var view = this
    var timeout = null

    $(window).on('resize.reportGroupTabsetScroll', function () {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(function () {
        view.render()
      }, 10)
    })
  },
  destroy: function () {
    $(window).off('resize.reportGroupTabsetScroll')
    ScrollView.prototype.destroy.apply(this, arguments)
  }
})

export default ReportGroupTabsetScrollView
