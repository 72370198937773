import $ from 'jquery'
import ModalView from '../../../Modal'
import SelectView from './ChecklistSelect'
import ChecklistFormView from './ChecklistModalForm'

const ChecklistModalView = ModalView.extend({
  url: 'js/html/app/src/template/report/vehicleDefectReport/checklist/vehicleDefectChecklistModal.html',
  mergeEvents: true,
  events: {
    'click [data-button=active], [data-button=draft]': 'validateCheckListName'
  },
  defaultOptions: [
    {value: 1, tag: "<label class='circle-yes'><i></i><span>Yes</span></label><label class='circle-no'><i></i><span>No</span></label>"},
    {value: 2, tag: "<label class='circle-yes'><i></i><span>Yes</span></label><label class='circle-no'><i></i><span>No</span></label><label class='circle-applicable'><i></i><span>Not Applicable</span></label>"},
    {value: 3, tag: "<label class='circle-yes'><i></i><span>Checked - No Defect(s)</span></label><label class='circle-warn'><i></i><span>Checked - Minor Defect(s)</span></label><label class='circle-no'><i></i><span>Checked - Safety Critical Defect(s)</span></label><label class='circle-applicable'><i></i><span>Not Applicable</span></label>"}
  ],
  initialize: function () {
    this.checkList = []
    this.editableElement = false
    this.addSubView('checklistForm', ChecklistFormView)
  },
  loadDefaultList: function () {
    var view = this
    var title = view.$el.find('.header-panel .title')
    var checklistForm = view.findSubView('checklistForm')
    var tableGrid = checklistForm.findSubView('compactReportTableGrid')

    title.text('New Checklist')
    checklistForm.model.set(checklistForm.model.defaults)

    $.get('api/report/vehicleDefectReport/predefined-checklist').done(function (json) {
      view.checkList = view.createModel(json)

      checklistForm.$el.find('input[name=descr]').val('')

      tableGrid.model.set('tableRow', view.checkList)
      tableGrid.render()

      view.addSelectViews(view.checkList)
      view.bindEvents()

      view.show()
      view.align()
    })
  },
  bindEvents: function () {
    var view = this

    view.initSortableRow()
    view.bindEventInit()
  },
  initSortableRow: function () {
    $('.sortable-table').sortable({
      items: "tr",
      handle: '.drag-checklist',
      placeholder: '<tr class="tr-placeholder"/>'
    })
  },
  bindEventInit: function () {
    var view = this
    var checklistForm = view.findSubView('checklistForm')
    var tableGrid = checklistForm.findSubView('compactReportTableGrid')

    $('.sortable-table input').focus(function (e) {
      $(this).next('.remove-checklist').show(1)
    }).blur(function (e) {
      var $this = $(this)
      var _id = $this.closest('tr').attr('data-id')
      var value = $this.val()

      if (value.trim().length === 0) {
        window.systemAlert.initError('Daily check name is required', null)

        setTimeout(function () {
          window.systemAlert.hideAlert()
        }, 2000)
        $(this).focus()
      } else {
        $this.val(value.trim())
        tableGrid.setChecksItem(_id, value)

        $this.next('.remove-checklist').hide(500)
      }
    })
  },
  createModel: function (json) {
    var view = this
    var newList = []
    var defaultValue = 3

    for (var i = 0; i < json.length; i++) {
      newList.push({
        id: json[i].id ? json[i].id : i + 1,
        checkName: json[i].name ? json[i].name : json[i],
        opts: view.defaultOptions,
        value: json[i].responseType ? json[i].responseType : defaultValue
      })
    }

    return newList
  },
  addSelectViews: function (selects) {
    var view = this

    for (var i = 0; i < selects.length; i++) {
      view.addSubView('checklistSelect-' + selects[i].id + '', SelectView, { el: view.$('[data-select=response-' + selects[i].id + ']') })
    }
  },
  removeSelectViews: function (selects) {
    var view = this

    for (var i = 0; i < selects.length; i++) {
      view.removeSubView('checklistSelect-' + selects[i].id + '', SelectView, { el: view.$('[data-select=response-' + selects[i].id + ']') })
    }
  },
  editChecks: function (item, dupl) {
    var view = this
    var title = view.$el.find('.header-panel .title')
    var checklistForm = view.findSubView('checklistForm')
    var tableGrid = checklistForm.findSubView('compactReportTableGrid')
    var checksList = item.attributes.vehicleDefectDailyCheckTypeList

    title.text('Duplicate Checklist')
    checklistForm.$el.find('input[name=descr]').val('')
    checklistForm.model.set(checklistForm.model.defaults)

    if (!dupl) {
      title.text('Edit Checklist')
      checklistForm.$el.find('input[name=descr]').val(item.attributes.descr)
      view.editableElement = item
    }

    if (checksList.length === 0) {
      view.loadDefaultList()
      return
    }

    view.checkList = view.createModel(checksList)

    tableGrid.model.set('tableRow', view.checkList)
    tableGrid.render()

    view.addSelectViews(view.checkList)
    view.bindEvents()
    view.show()
    view.align()
  },
  validateCheckListName: function (e) {
    var view = this
    var checklistForm = view.findSubView('checklistForm')
    var name = checklistForm.$el.find('input[name=descr]').val()
    var accId = $.cookie('lastViewedAccount')
    var _id = (!view.editableElement ? 0 : view.editableElement.attributes.id)

    $.get('api/report/vehicleDefectReport/checklist/check-name/', { name: name, account_id: accId, check_list_id: _id }).done(function (json) {
      view.saveChecklist(e)
    }).error(function (err) {
      if (err.statusText === 'error') {
        window.systemAlert.initError('Checklist name is exist', null)

        setTimeout(function () {
          window.systemAlert.hideAlert()
        }, 3000)
      } else {
        window.systemAlert.initError('Checklist name is required', null)

        setTimeout(function () {
          window.systemAlert.hideAlert()
        }, 3000)
      }
    })
  },
  saveChecklist: function (e) {
    var view = this
    var checklistForm = view.findSubView('checklistForm')
    var status = $(e.currentTarget).attr('data-button')
    var checkList = []

    checklistForm.findSubView('compactReportTableGrid').$el.find('.sortable-table tbody').children().each(function (a, el) {
      checkList.push({ name: view.getCheckName($(el)), responseType: view.getResponseType($(el)) })
    })

    checklistForm.model.set('status', status)
    checklistForm.model.set('vehicleDefectDailyCheckTypeList', checkList)

    if (!view.editableElement) {
      checklistForm.model.set('accountId', parseInt($.cookie('lastViewedAccount')))
    } else {
      checklistForm.model.set('accountId', view.editableElement.attributes.accountId)
      checklistForm.model.set('id', view.editableElement.attributes.id)
      view.editableElement = false
    }

    checklistForm.syncData()
  },
  getCheckName: function ($el) {
    return $el.find('input').attr('value')
  },
  getResponseType: function ($el) {
    return parseInt($el.find('select').val())
  },
  closeBtnHandler: function () {
    var view = this
    var checklistView = view.getParentView().findSubView('checklistsModal')
    var vehicleListModal = view.getParentView().findSubView('vehicleListModal')

    view.editableElement = false
    view.hide()

    if (checklistView.editebleChecklist || checklistView.duplChecklist) {
      checklistView.editebleChecklist = false
      checklistView.duplChecklist = false
      checklistView.show()
    }

    if (vehicleListModal.vehicleListActive) {
      vehicleListModal.getList()
      vehicleListModal.show()
    }
  },
  destroy: function () {
    var view = this

    view.remove()
  }
})

export default ChecklistModalView
