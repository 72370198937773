import SelectionMenuModel from '../../SelectionMenu'

const SpeedingReportSelectionMenuModel = SelectionMenuModel.extend({
  defaults: {
    repType: 'Speeding'
  },
  url: 'api/selection-menu/speeding'
})

export default SpeedingReportSelectionMenuModel
