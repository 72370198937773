import SelectionMenuVehicleListView from '../../SelectionMenuVehicleList'
import LWJourneyReportSelectionMenuUserList from '../../../collection/report/loneworkerReport/LWJourneyReportSelectionMenuUserList'
import LWJourneyReportSelectionMenuUserListScrollView from './LWJourneyReportSelectionMenuUserListScroll'

const LWJourneyReportSelectionMenuUserListView = SelectionMenuVehicleListView.extend({
  collection: LWJourneyReportSelectionMenuUserList,
  templateUrl: 'template/report/loneworkerReport/LWJourneyReportSelectionMenuVehicleList.html',
  elSelector: '[data-vehicle-list=journey]',
  initialize: function () {
    var view = this

    view.addSubView('scroll', LWJourneyReportSelectionMenuUserListScrollView)
    view.render()
  }
})

export default LWJourneyReportSelectionMenuUserListView
