import Model from './Model'

const PoiModel = Model.extend({
  defaults: {
    id: 0,
    description: ''
  },
  urlRoot: '/services/poi'
})

export default PoiModel
