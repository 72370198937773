import ScrollView from '../../Scroll'

const LeagueTableExcludeDriversTableGridScrollView = ScrollView.extend({
  calculateScrollContentSize: function () {
    this.$frame.height(174)
  },
  checkContentSize: function () {
    return this.$el.find('.slidee').height() > 168
  }
})

export default LeagueTableExcludeDriversTableGridScrollView
