import PopupView from '../../Popup'
import ProfileManagerTableGridView from './ProfileManagerTableGrid'
import $ from 'jquery'

const ProfileManagerPopupView = PopupView.extend({
  ProfileManagerBuilderPopupView: null,
  url: 'template/alert/profileManager/profileManagerPopup.html',
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'cancel',
    'click [data-button=save]': 'save',
    'click [data-button=create-profile]': 'createProfile'
  },
  initialize: function (option) {
    var view = this

    view.initProfileManagerTableGridView()
    if (view.ProfileManagerBuilderPopupView != null) {
      view.addSubView('profileManagerBuilderPopup', view.ProfileManagerBuilderPopupView)
    }
    view.profileManagerTableGridView.once('ready', function () {
      view.on('beforeShow', function () {
        // var profileList = view.getParentView().tabsetView.selectProfile.innerView.findSubView('availableSelectedTableGrid').availableTableGridView.collection

        // view.profileManagerTableGridView.collection.set(profileList.toJSON());
        // view.profileManagerTableGridView.render();

        if (!view.profileManagerTableGridView.collection || !view.profileManagerTableGridView.collection.length) {
          view.model.set('lockAnimation', true)
          view.profileManagerTableGridView.fetchProfileList(function (json) {
            view.model.set({ lockAnimation: false, visible: true }, { silent: true })
            view.showAnimation()
          })
        }
      })
    })
  },
  initProfileManagerTableGridView: function () {
    this.profileManagerTableGridView = this.addSubView('profileManagerTableGrid', this.ProfileManagerTableGrid || ProfileManagerTableGridView, {
      el: this.$el.find('.info-holder')
    })
    return this
  },
  loadHTML: function (url, callback) { // override
    var view = this
    view.templateUrl = url

    this.loadDotTemplate(function () {
      view.compileTemplate()
      view.$el = $(view.dotTemplateToHtml({ title: view.title }))
      view.el = view.$el[0]
      view.render()
      view.model.set('htmlReady', true)
      if (callback) callback()
    })
    return this
  },
  cancel: function () {
    var view = this
    this.profileManagerTableGridView.resetSelectedItemAndRow()
    this.hide()
    this.findSubView('profileManagerBuilderPopup').hide()
  },
  save: function () {
    this.hide()
    this.profileManagerTableGridView.resetSelectedItemAndRow()
    this.findSubView('profileManagerBuilderPopup').hide()
  },
  closeBtnHandler: function () {
    this.findSubView('profileManagerBuilderPopup').hide()
    this.hide()
  },
  createProfile: function () {
    var profileManagerBuilderPopup = this.findSubView('profileManagerBuilderPopup')
    var selectVehiclesScroll = profileManagerBuilderPopup.findSubView('profileBuilderForm').findSubView('profileBuilderTabset').selectVehicles.innerView.findSubView('scroll')

    this.findSubView('profileManagerTableGrid').resetSelectedItemAndRow()
    profileManagerBuilderPopup.findSubView('profileBuilderForm').cancel()
    profileManagerBuilderPopup.findSubView('profileBuilderForm').setMode('create')
    profileManagerBuilderPopup.findSubView('profileBuilderForm').resetVehicles()
    profileManagerBuilderPopup.show()
    profileManagerBuilderPopup.alignBy(this.$el.find('[data-button=create-profile]'), 'topRight', { left: 4 })
    selectVehiclesScroll.render()
    profileManagerBuilderPopup.$el.find('input:checkbox').prop('indeterminate', false)
    profileManagerBuilderPopup.$el.find('input:checkbox').prop('checked', false)
    // profileManagerBuilderPopup.findSubView('profileBuilderForm').findSubView('profileBuilderTabset').selectVehicles.innerView.render();
  },
  destroy: function () {
    this.findSubView('profileManagerTableGrid').destroy()
    this.findSubView('profileManagerBuilderPopup').destroy()
    $(document).off('click.profileManager')
    this.remove()
  }
})

export default ProfileManagerPopupView
