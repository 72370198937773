import TabsetView from '../../Tabset'
import Tabset from '../../../model/Tabset'
import $ from 'jquery'

const VehicleChecksView = TabsetView.extend({
  templateUrl: 'template/report/vehicleDefectReport/vehicleChecks.html',
  events: {
    'click .slide-button': 'slideButtonHandler'
  },
  constructor: function (option) {
    var view = this
    var args = arguments

    this.loadDotTemplate(function () {
      view.compileTemplate()
      view.$el = $('<div class="tabset-report-panel" data-report-page="vehicleDefect" style="width: 90%">' + view.dotTemplateToHtml({}) + '</div>')
      view.el = view.$el[0]
      TabsetView.apply(view, [{ el: view.el }])
    })
  },
  initialize: function () {
    var view = this

    view.setUncheckedNotificationStatus(window.userPrefs.vehicleDefectUncheckedNotificationStatus)
    view.render()
    view.listenTo(view.model, 'change:activeTab', function (tabsetModel, activeTab) {
      var checkedVehicleTab = this.checkedVehicle
      var compactReportTableGrid = checkedVehicleTab.innerView.findSubView('compactReportTableGrid')

      checkedVehicleTab.innerView.findSubView('notificationsPopup').hide()
      compactReportTableGrid.findSubView('detailedVehicleDefectReportPopup').hide()
      compactReportTableGrid.findSubView('statusPopup').hide()
      compactReportTableGrid.findSubView('vehicleDefectReportCommentPopup').hide()
      compactReportTableGrid.resetPin()
      compactReportTableGrid.resetSelectedItemAndRow()
    })
  },
  setUncheckedNotificationStatus: function (val) {
    var view = this

    view.$el.find('input.switch-input').prop('checked', (val === 0 ? false : true))
  },
  slideButtonHandler: function () {
    var view = this
    var vehicleCheckedDefectReport = view.checkedVehicle.innerView
    var left = 0

    if (!this.$('.slide-button').hasClass('active')) {
      left = -this.$el.width()
    }

    this.$el.animate({ left: left }, 400)
    this.$('.slide-button').toggleClass('active')

    vehicleCheckedDefectReport.findSubView('compactReportTableGrid').hideActivePopup()
    vehicleCheckedDefectReport.hideNotifications()
  },
  isVisible: function () {
    return this.$el.is(':visible')
  },
  getHTML: function () {
    return this.$el
  },
  render: function () {
    var $leftPanel = $('.left-panel')
    var $mapWrap = $('.map-wrap')

    if ($leftPanel.hasClass('active')) {
      $mapWrap.animate({
        'margin-left': 0
      }, 800, function () {
        $leftPanel.removeClass('active')
        $mapWrap.removeClass('active')
      })
    }
    $leftPanel.fadeOut(400)
    $('.user-menu>ul>li').removeClass('active')
    $mapWrap.append(this.$el)
    this.$el.fadeIn(400)
  },
  destroy: function () {
    var view = this

    window.reportHandler.removeReport(this)
    if (this.checkedVehicle) {
      this.checkedVehicle.innerView.destroy()
    }
    if (this.uncheckedVehicle) {
      this.uncheckedVehicle.innerView.destroy()
    }
    this.$el.fadeOut(400, function () {
      view.remove()
    })
  }
})

export default VehicleChecksView
