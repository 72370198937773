import TableGridModel from '../../TableGrid@'
import AoiList from '../../../collection/alert/geoZones/AoiList'

const AoiListTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: false,
    tableHead: [{
      tableHeadName: 'Id',
      field: 'id',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        }
      ]
    },
    {
      tableHeadName: 'AOI Category',
      field: 'categoryDtoDescr',
      noDrop: true
    },
    {
      tableHeadName: 'Name',
      field: 'description',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'description',
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'string'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Account',
      field: 'accountDtoDescr',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'accountDtoDescr',
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'string'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Status',
      field: 'status',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          dataButton: 'sortAsc',
          iconClass: 'asc'
        },
        {
          optionName: 'Sort Descending',
          dataButton: 'sortDesc',
          iconClass: 'desc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'status',
                select: {
                  name: 'filterAndValue',
                  filterFn: 'equal',
                  dataType: 'string',
                  options: [
                    {
                      option: 'Active',
                      value: 'Active'
                    },
                    {
                      option: 'Inactive',
                      value: 'Inactive'
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Activate/Deactivate',
      field: 'statusAction',
      noDrop: true
    }],
    tableRow: AoiList
  }
})

export default AoiListTableGridModel
