/**
 * Created by George on 08.07.2015.
 */
import ProfileBuilderFormModel from '../profileManager/ProfileBuilderForm'


const DriverBehaviorProfileBuilderFormModel = ProfileBuilderFormModel.extend({
    url: function() {
        const id = this.get('id') || '';
        return `/services/driver-behaviour/profile/${id}`;
    },

    defaults : {
        maxAccelerationForce: 0.25,
        maxBrakingForce: 0.25,
        maxCorneringAngle: 0,
        alerts: [],
        vehicles: []
    },

    validate: function(attrs, options) {
        var message = {
            wrong_profile_name_format: "Please verify the Profile Name format.",
            wrong_max_acceleration_force_format: "Please verify the Max. Acceleration Force format.",
            wrong_max_braking_force_format: "Please verify the Max. Braking Force format.",
            wrong_max_cornering_angle_format: "Please verify the Max. Cornering Angle format.",
            wrong_selected_vehicle_count: "Please select at least one vehicle."
        };
        var json = {
            button: ["OK"]
        };

        if (ProfileBuilderFormModel.prototype.validate.apply(this,[attrs, options, json, message])) {
            return json;
        }

        if (isNaN(parseFloat(attrs.maxAccelerationForce)) || parseFloat(attrs.maxAccelerationForce) < 0.25 || parseFloat(attrs.maxAccelerationForce) > 0.85) {
            json.msgId = "wrong_max_acceleration_force_format";
            json.title = "Max. Acceleration Force verification";
            json.message = message[json.msgId];
            return json;
        }

        if (isNaN(parseFloat(attrs.maxBrakingForce)) || parseFloat(attrs.maxBrakingForce) < 0.25 || parseFloat(attrs.maxBrakingForce) > 0.85) {
            json.msgId = "wrong_max_braking_force_format";
            json.title = "Max. Braking Force verification";
            json.message = message[json.msgId];
            return json;
        }

        if (isNaN(parseInt(attrs.maxCorneringAngle)) || parseInt(attrs.maxCorneringAngle) < 0 || parseInt(attrs.maxCorneringAngle) > 360) {
            json.msgId = "wrong_max_cornering_angle_format";
            json.title = "Max. Cornering Angle verification";
            json.message = message[json.msgId];
            return json;
        }
    }
})

export default DriverBehaviorProfileBuilderFormModel;
