import JourneyCostReportPopupView from './JourneyCostReportPopup'
import JourneyReportSelectionMenuView from '../journeyReport/JourneyReportSelectionMenu'
import ReportPageView from '../ReportPage'
import JourneyCostReportView from './JourneyCostReport'

const JourneyCostReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="journey"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', JourneyCostReportPopupView)
    view.addSubView('selectionMenu', JourneyReportSelectionMenuView)
    view.addSubView('report', JourneyCostReportView)
    view.render()
  }
})

export default JourneyCostReportPageView
