import FormView from '../../Form'

const ProfileBuilderFormView = FormView.extend({
    ProfileBuilderTabsetView: null,
    mode: 'create',
    constructor: function(){
        this.$el = this.parentView.$el.find("form");
        this.el = this.$el[0];
        FormView.apply(this,arguments);
    },
    initialize: function(){
        var view = this;
        var notificationPopupView = view.findSubView('notificationPopup');

        this.addSubView('profileBuilderTabset', this.ProfileBuilderTabsetView);
        view.model.on("invalid", function(model, error) {
            notificationPopupView.render(error);
        });
//            notificationPopupView.on("ok",function(message){
//                alert(123);
//            });
        view.on("save",function(){
            var profileManagerPopup  = view.getParentView().getParentView();
            var availableSelectedTableGrid = profileManagerPopup.getParentView().tabsetView.selectProfile.innerView.findSubView('availableSelectedTableGrid');
            var profileManagerTableGrid = profileManagerPopup.findSubView('profileManagerTableGrid');

            availableSelectedTableGrid.fetchProfileList().done(function () {
                availableSelectedTableGrid.reloadContent(availableSelectedTableGrid.selectedTableGridCollection.toJSON(), true);
            });
            profileManagerTableGrid.fetchProfileList();
            profileManagerTableGrid.resetSelectedItemAndRow();
            view.reset();
            view.getParentView().save();
        });
        view.on("cancel",function(){
            if(view.findSubView('profileBuilderTabset').hasOwnProperty('timeRange')){
                view.findSubView('profileBuilderTabset').timeRange.innerView.findSubView('timeRangeWeekMap').resetWeekModel();
            }
        });
    },
    getMode: function () {
        return this.mode;
    },
    setMode: function (mode) {
        this.mode = mode;
    },
    setFields: function (formData) {
        formData.vehicles = [];
    },
    syncData: function(){ // override
        var formData = this.getFormData();
        var selectedVehicle = this.$el.find('input[name=Vehicle]:checkbox:checked');

        this.setFields(formData);

        $.each(selectedVehicle, function (i, item) {
            var $checkbox = $(item);
            formData.vehicles.push({
                id: parseInt($checkbox.val()),
                registrationNumber: $checkbox.siblings('.registration-number').text()
            })
        });

        this.model.set(formData);
    },
    resetVehicles: function() {
        var formData = this.getFormData();
        this.setFields(formData);
    },
    destroy: function () {
        this.findSubView('profileBuilderTabset').destroy();
        this.remove();
    }
})

export default ProfileBuilderFormView
