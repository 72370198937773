import TableGridView from '../../../TableGrid@'
import CheckListsTableGridModel from '../../../../model/report/vehicleDefectReport/CheckListsTableGrid'
import ChecklistModalTableGridScrollView from './ChecklistModalTableGridScroll'
import CompactReportTableDropView from '../../CompactReportTableDrop'

const VehicleDefectCheckListsModalTableGridView = TableGridView.extend({
  model: CheckListsTableGridModel,
  templateUrl: 'template/report/vehicleDefectReport/checklist/vehicleDefectCheckListsModalTableGrid.html',
  mergeEvents: true,
  events: {
    'click tbody tr:not(.empty-row)': ''
  },
  constructor: function (option) {
    var view = this
    view.el = view.parentView.$el.find('.info-holder')

    view.setSubView({
      tableDrop: CompactReportTableDropView,
      scroll: ChecklistModalTableGridScrollView
    })

    TableGridView.apply(view, arguments)
  },
  renderEmptyRows: function (collection) {
    var view = this
    var maxEmptyRows = 0
    var rowCount = collection.length || 0
    var emptyRowArray = []

    view.emptyRow = '<tr class="empty-row">'

    for (var i = 0; i < view.$('th').size(); i++) {
      view.emptyRow += '<td></td>'
    }

    view.emptyRow += '</tr>'

    view.$el.find('.empty-row').remove()
    if (rowCount < 15) {
      maxEmptyRows = 15 - rowCount
      for (i = 0; i < maxEmptyRows; i++) {
        emptyRowArray.push(view.emptyRow)
      }
      view.$el.find('tbody:last').append(emptyRowArray.join(''))
    }
    return this
  },
  destroy: function () {
    var view = this

    TableGridView.prototype.destroy.apply(view, arguments)
  }
})

export default VehicleDefectCheckListsModalTableGridView
