import TableGridModel from '../TableGrid@'
import LoneWorkerActivityLogList from '../../collection/loneWorker/LoneWorkerActivityLogList'

const LoneWorkerActivityLogTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: true,
    tableHead: [
      {
        tableHeadName: 'Time',
        field: 'recDate',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      },
      {
        tableHeadName: 'Icon',
        field: 'directionCode',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      },
      {
        tableHeadName: 'Location',
        field: 'streetName',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      },
      {
        tableHeadName: 'Postcode',
        field: 'postcode',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      },
      {
        tableHeadName: 'POI/AOI',
        field: 'poiDescr',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      },
      {
        tableHeadName: 'km/h',
        field: 'factoredSpeed',
        distanceMetric: true,
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Miles:',
                  filterByField: 'factoredSpeed',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Greater Than',
                        value: 'greaterThan'
                      },
                      {
                        option: 'Less Than',
                        value: 'lessThan'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter Value',
                    name: 'value',
                    dataType: 'float'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Details',
        field: 'details',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      }],
    tableRow: LoneWorkerActivityLogList
  }
})

export default LoneWorkerActivityLogTableGridModel
