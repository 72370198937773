import TableDropView from '../TableDrop'

const SelectRecipientTableDropView = TableDropView.extend({
  appendToBody: true,
  saveSort: function (sortData) {
    this.sortData = sortData
  },
  clearSortData: function () {
    this.sortData = {}
  },
  saveFilter: function (filterData, $activeTh, index) {
    var thIndex = index || $activeTh.index()

    this.filterData[thIndex] = filterData
  },
  clearFilterData: function ($activeTh, index) {
    if (!$activeTh) return
    var thIndex = index || $activeTh.index()

    if (this.filterData.hasOwnProperty(thIndex)) {
      delete this.filterData[thIndex]
    }
  }
})

export default SelectRecipientTableDropView
