import $ from 'jquery'
import TabsetView from '../Tabset'
import OutOfHoursAlertView from "./outOfHours/outOfHoursAlert";
import DriverBehaviorAlertView from "./driverBehavior/DriverBehaviorAlert";
import OverspeedAlert from "./overspeed/OverspeedAlert";
import UnpluggedDeviceAlert from "./unpluggedDevice/UnpluggedDeviceAlert";

const AlertManagerView = TabsetView.extend({
  templateUrl: 'template/alert/alertManager.html',
  events: {
    'click .slide-button': 'slideButtonHandler'
  },
  constructor: function (option) {
    var self = this
    var args = arguments

    this.loadDotTemplate(function () {
      self.compileTemplate()
      self.$el = $('<div class="tabset-report-panel" data-report-page="alertManager">' + self.dotTemplateToHtml({}) + '</div>')
      self.el = self.$el[0]
      TabsetView.apply(self, [{ el: self.el,
        lazyTabView: { 'view/alert/outOfHours/outOfHoursAlert': OutOfHoursAlertView,
                       'view/alert/driverBehavior/DriverBehaviorAlert' : DriverBehaviorAlertView,
                       'view/alert/overspeed/OverspeedAlert' : OverspeedAlert,
                        'view/alert/unpluggedDevice/UnpluggedDeviceAlert' : UnpluggedDeviceAlert} }])
    })
  },
  initialize: function () {
    var view = this
    view.resetPin()
    view.render()
    view.listenTo(view.model, 'change:activeTab', function (tabsetModel, activeTab) {
      var driverBehaviorAlert = this.driverBehaviorAlert
      var overspeedAlert = this.overspeedAlert
      var outOfHoursAlert = this.outOfHoursAlert

      driverBehaviorAlert && driverBehaviorAlert.innerView.alertListPopupView.hide()
      driverBehaviorAlert && driverBehaviorAlert.innerView.profileManagerPopupView.hide()
      driverBehaviorAlert && driverBehaviorAlert.innerView.profileManagerPopupView.findSubView('profileManagerBuilderPopup').hide()

      overspeedAlert && overspeedAlert.innerView.alertListPopupView.hide()
      overspeedAlert && overspeedAlert.innerView.profileManagerPopupView.hide()
      overspeedAlert && overspeedAlert.innerView.profileManagerPopupView.findSubView('profileManagerBuilderPopup').hide()

      outOfHoursAlert && outOfHoursAlert.innerView.alertListPopupView.hide()
      outOfHoursAlert && outOfHoursAlert.innerView.profileManagerPopupView.hide()
      outOfHoursAlert && outOfHoursAlert.innerView.profileManagerPopupView.findSubView('profileManagerBuilderPopup').hide()
    })
  },
  resetPin: function () {
    if (window.wls.hasShapes()) {
      window.wls.removeCars()
    }

    window.mapCanvas.cleanUp()
  },
  slideButtonHandler: function () {
    var left = 0

    if (!this.$('.slide-button').hasClass('active')) {
      left = -this.$el.width()
    }
    this.$el.animate({ left: left }, 400)
    this.$('.slide-button').toggleClass('active')
  },
  isVisible: function () {
    return this.$el.is(':visible')
  },
  getHTML: function () {
    return this.$el
  },
  render: function () {
    var $leftPanel = $('.left-panel')
    var $mapWrap = $('.map-wrap')

    if ($leftPanel.hasClass('active')) {
      $mapWrap.animate({
        'margin-left': 0
      }, 800, function () {
        $leftPanel.removeClass('active')
        $mapWrap.removeClass('active')
      })
    }
    $leftPanel.fadeOut(400)
    $('.user-menu>ul>li').removeClass('active')
    $mapWrap.append(this.$el)
    this.$el.fadeIn(400)
  },
  destroy: function () {
    var view = this

    window.reportHandler.removeReport(this)
    if (this.driverBehaviorAlert) {
      this.driverBehaviorAlert.innerView.destroy()
    }
    if (this.overspeedAlert) {
      this.overspeedAlert.innerView.destroy()
    }
    if (this.outOfHoursAlert) {
      this.outOfHoursAlert.innerView.destroy()
    }
    this.$el.fadeOut(400, function () {
      view.remove()
    })
  }
})

export default AlertManagerView
