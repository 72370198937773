import $ from 'jquery'
import PopupView from '../Popup'
import MultiCameraVideoPlayerView from './MultiCameraVideoPlayer'
import MultiCameraRequestVideoPopupView from './MultiCameraRequestVideoPopup'
import Model from '../../model/Model'

const MultiCameraPopupView = PopupView.extend({
  url: 'js/html/app/src/template/multiCamera/multiCameraPopup.html',
  activityLogId: null,
  mergeEvents: false,
  events: {
    'click .header-panel .vp-fullscreen-btn i': 'onFullscreen',
    'click [data-button=closeMultiCamera]': 'closeBtnHandler',
    'click .max-min_btn': 'maxMin',
    'click [data-button=favorite]': 'addFavorite'
  },
  constructor: function () {
    this.model = Model.extend({
      defaults: {
        visible: false,
        lockAnimation: false,
        htmlReady: false,
        isDraggable: true
      }
    })

    PopupView.apply(this, arguments)
  },
  initialize: function (option) {
    var view = this
    var aspectRatioHeight = 1080 / 1920
    var height = ((parseInt(view.$el.css('width')) * 0.74 * aspectRatioHeight) + 32)

    height = height / $(window).width() * 100
    view.addSubView('multiCameraVideoPlayer', MultiCameraVideoPlayerView, { el: this.$el.find('.vp-container')[0] })
    view.addSubView('requestVideo', MultiCameraRequestVideoPopupView)

    view.on('hideAnimationEnd', function () {
      if (view.$el.hasClass('minimize')) {
        view.maximisePopup()
      }
    })

    view.$el.css({
      height: height + 'vw'
    })
  },
  markAsWatched: function (activityLog) {
    var incidentId = activityLog.videoRequestDto && activityLog.videoRequestDto.incidentId

    $.post(`api/incidents/${incidentId}/mark-watched`)
    this.getParentView().$el.find('[data-id=' + activityLog.id + '] .camera-ico').removeClass('unwatched')
  },
  addFavorite: function () {
    if (this.favorite) return

    var view = this
    var logId = this.incidentId
    var data = JSON.stringify({ favorite: true })
    var url = `/api/incidents/${logId}/favorite`
    var favoriteButton = this.$el.find('[data-button]')

    $.ajax({
      url: url,
      type: 'POST',
      data: data,
      dataType: 'json',
      contentType: 'application/json',
      complete: function (response) {
        view.setFavorite(response.responseJSON)
        if (response.responseJSON) {
          favoriteButton.addClass('disabled')
        } else {
          favoriteButton.removeClass('disabled')
        }
      }
    })
  },
  closeBtnHandler: function () {
    var view = this
    var timeoutId = view.getVideoPlayer().timeoutId

    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    view.hide()
    this.getVideoPlayer().setProp('muted', true)
  },
  maxMin: function () {
    var view = this

    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.maximisePopup()
    } else {
      view.minimisePopup()
    }
  },
  maximisePopup: function () {
    var view = this
    var videoPlayer = this.getVideoPlayer()

    view.model.set('isDraggable', true)
    view.$el.removeClass('minimize')
    videoPlayer.showSideVideos()
    view.$el.css({ top: view.model.get('position').top, left: view.model.get('position').left })
    view.$el.find('.max-min_btn').removeClass('active')
    videoPlayer.renderMiniVideos()
  },
  minimisePopup: function () {
    var view = this
    var top = 0
    var left = 0
    var videoPlayer = this.getVideoPlayer()

    view.model.set('isDraggable', false)
    view.$el.addClass('minimize')
    videoPlayer.hideSideVideos()
    top = $(window).height() - view.$el.height() - 20
    left = $(window).width() - view.$el.width() - 20
    view.model.set('position', { top: view.$el[0].offsetTop, left: view.$el[0].offsetLeft })
    view.$el.css({ top: top + 'px', left: left + 'px' })
    view.$el.find('.max-min_btn').addClass('active')
  },
  getActivityLogTableGrid: function () {
    return this.parentView
  },
  getConfirmVideoRequestPopup: function () {
    return this.findSubView('requestVideo')
  },
  setVideoProps: function (props) {
    var $title = this.$el.find('.header-panel .title')
    var videoPlayer = this.getVideoPlayer()
    var timeoutId = videoPlayer.timeoutId
    var videoRequestDto = props.videoRequestDto
    var $favoriteButton = this.$el.find('[data-button=favorite]')
    // var channels = [
    //     {id: 40, name: 'Channel #1', videoStatus: 0, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
    //     {id: 41, name: 'Channel #2', videoStatus: 1, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
    //     {id: 42, name: 'Channel #3', videoStatus: 0, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
    //     {id: 43, name: 'Channel #4', videoStatus: -1, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
    //     {id: 44, name: 'Channel #5', videoStatus: 1, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
    //     {id: 45, name: 'Channel #6', videoStatus: 2, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
    //     {id: 46, name: 'Channel #7', videoStatus: 2, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
    //     {id: 47, name: 'Channel #8', videoStatus: 2, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'}
    // ]
    // props = {
    //     channels: channels
    // }

    if (props.unwatched) {
      this.markAsWatched(props)
    }

    if (this.$el.hasClass('minimize')) {
      this.maximisePopup()
    }

    videoPlayer.sideSectionWidth = parseInt(videoPlayer.$el.find('.side-video-section').css('width'))
    $title.find('span:first').text(props.registrationNumber)
    $title.find('span:last').text(new Date(props.recDate).format('dd.MM.yy HH:mm:ss'))
    videoPlayer.setSelectedChannelId(null)
    videoPlayer.setProps(props)
    this.setIncidentId(videoRequestDto.incidentId)
    this.setFavorite(videoRequestDto.favorite)

    if (videoRequestDto.favorite) {
      $favoriteButton.addClass('disabled')
    } else {
      $favoriteButton.removeClass('disabled')
    }

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    videoPlayer.keepChannelsFresh()

    this.findSubView('requestVideo').hide()
  },
  setActivityLogId: function (activityLogId) {
    this.activityLogId = activityLogId
  },
  setIncidentId: function (incidentId) {
    this.incidentId = incidentId
  },
  setFavorite: function (favorite) {
    this.favorite = favorite
  },
  getVideoPlayer: function () {
    return this.findSubView('multiCameraVideoPlayer')
  },
  enterFullscreen: function () {
    var videoPlayer = this.getVideoPlayer()

    videoPlayer.enterFullscreen()
    videoPlayer.showSideVideos()
    videoPlayer.renderScroll()
  },
  onFullscreen: function () {
    this.enterFullscreen()
  },
  destroy: function () {
    this.getVideoPlayer().destroy()
    this.remove()
  }
})

export default MultiCameraPopupView
