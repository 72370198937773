import $ from 'jquery'
import PopupView from '../Popup'
import AlertTableGridView from './AlertTableGrid'
import Loader from '../../util/loader'

const AlertListPopupView = PopupView.extend({
  url: 'template/alert/alertListPopup.html',
  initialize: function (option) {
    var view = this
    view.initAlertListTableGridView()
    view.alertListTableGridView.once('ready', function () {
      view.on('beforeShow', function () {
        if (!view.alertListTableGridView.model.get('tableRow').length) {
          view.model.set('lockAnimation', true)
          Loader.on()
          view.alertListTableGridView.fetchAlertlist(function (json) {
            Loader.off()
            if (json.length) {
              view.model.set({ lockAnimation: false, visible: true })
            } else {
              alert('No data to display.')
            }
          })
        }
      })
    })
  },
  loadHTML: function (url, callback) { // override
    var view = this
    view.templateUrl = url

    this.loadDotTemplate(function () {
      view.compileTemplate()
      view.$el = $(view.dotTemplateToHtml({}))
      view.el = view.$el[0]
      view.render()
      view.model.set('htmlReady', true)
      if (callback) callback()
    })
    return this
  },
  initAlertListTableGridView: function () {
    this.alertListTableGridView = this.addSubView('alertTableGrid', this.AlertTableGridView, {
      el: this.$el.find('[data-table-grid]')
    })
    return this
  },
  destroy: function () {
    this.findSubView('alertTableGrid').destroy()
    this.remove()
  },
  closeBtnHandler: function () {
    this.hide()
  }
})

export default AlertListPopupView
