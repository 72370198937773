import $ from 'jquery'
import ScrollView from './Scroll'

const SelectionMenuVehicleListScrollView = ScrollView.extend({
  constructor: function () {
    var view = this

    $(window).on('resize.selectionMenuVehicleListScroll', function () {
      view.render()
    })
    ScrollView.apply(view, arguments)
  },
  calculateScrollContentSize: function () {
    var selectionMenuView = this.getParentView().getParentView()
    this.$frame.height($(window).height() - selectionMenuView.$el.find('.control-holder').height() - 10) // 62 control-holder height
  },
  checkContentSize: function () {
    return this.$el.offset().top + this.$('.slidee').height() > $(window).height()
  },
  destroy: function () {
    $(window).off('resize.selectionMenuVehicleListScroll')
    ScrollView.prototype.destroy.apply(this, arguments)
  }
})

export default SelectionMenuVehicleListScrollView
