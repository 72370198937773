import PopupView from '../../Popup'
import DrivingTimeSettingsFormView from './DrivingTimeSettingsForm'

const DrivingTimeSettingsPopupView = PopupView.extend({
  //        url: '/getReportWindow/DrivingTimeSettings.do',
  url: '/js/html/app/src/template/report/drivingTimeReport/drivingTimeReportSettingsPopup.jsp',
  events: {
    'click [data-button=close]': 'closeHandler',
    'click [data-button=cancel]': 'closeHandler',
    'click [data-button=save]': 'submit',
    'click [data-button=reset]': 'handleReset'
  },
  initialize: function () {
    var view = this
    view.addSubView('drivingTimeSettingsForm', DrivingTimeSettingsFormView)
  },
  closeHandler: function () {
    this.remove()
  },
  render: function () {
    this.getParentView().$el.append(this.el) // append popup to report page
    return this
  },
  destroy: function () {
    this.findSubView('drivingTimeSettingsForm').destroy()
    this.remove()
  },
  submit: function () {
    this.findSubView('drivingTimeSettingsForm').submit()
  },
  handleReset: function () {
    this.findSubView('drivingTimeSettingsForm').handleReset()
  }
})

export default DrivingTimeSettingsPopupView
