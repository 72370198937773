import PopupView from '../../Popup'
import LeagueTableExcludeDriversTableGridView from './LeagueTableExcludeDriversTableGrid'

const LeagueTableExcludeDriversPopupView = PopupView.extend({
  url: 'js/html/app/src/template/report/leagueTable/driverExclude.html',
  initialize: function () {
    var view = this

    view.addSubView('excludeDriversTableGrid', LeagueTableExcludeDriversTableGridView, this.$('[data-table-grid=exclude-drivers]'))
  },
  destroy: function () {
    var view = this

    view.remove()
  }
})

export default LeagueTableExcludeDriversPopupView
