import View from '../../View'
import IdlingFuelUsageReportModel from '../../../model/report/idlingReportFuelUsage/IdlingReportFuelUsage'
import IdlingReportFuelUsageSnailTrailPopupView from './IdlingReportFuelUsageSnailTrailPopup'
import ReportView from '../Report'
import IdlingReportFuelUsageScrollView from './IdlingReportFuelUsageScroll'

const IdlingReportFuelUsageView = ReportView.extend({
  model: IdlingFuelUsageReportModel,
  templateUrl: 'template/report/idlingReportFuelUsage/idlingReportFuelUsage.html',
  initialize: function (option) {
    var view = this

    view.addSubView('snailTrailPopup', IdlingReportFuelUsageSnailTrailPopupView)
    view.addSubView('scroll', IdlingReportFuelUsageScrollView)
  }
})

export default IdlingReportFuelUsageView
