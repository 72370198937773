import PopupView from '../Popup'
import ConfirmVideoRequestFormView from './ConfirmVideoRequestForm'

const ConfirmVideoRequestPopupView = PopupView.extend({
  url: 'js/html/app/src/template/camera/confirmVideoRequestPopup.html',
  initialize: function (option) {
    var view = this
    view.addSubView('confirmVideoRequestForm', ConfirmVideoRequestFormView)
  },
  closeBtnHandler: function () {
    var view = this
    view.hide()
  },
  getConfirmVideoRequestForm: function () {
    return this.findSubView('confirmVideoRequestForm')
  },
  renderForm: function (activityLog) {
    this.$el.find('[data-value]').hide()
    this.$el.find('.select-holder').show()
    this.getConfirmVideoRequestForm().setProps(activityLog)
  },
  renderGetMoreForm: function (activityLog) {
    this.renderForm(activityLog)

    var $period = this.$el.find('[data-value]')
    var videoRequestDto = activityLog.videoRequestDto
    var formModel = this.getConfirmVideoRequestForm().model

    $period.show()
    this.$el.find('.select-holder').hide()
    $period.eq(0).text(videoRequestDto.periodBefore + 's')
    $period.eq(1).text(videoRequestDto.periodAfter + 's')
    formModel.set('periodBefore', videoRequestDto.periodBefore)
    formModel.set('periodAfter', videoRequestDto.periodAfter)
  }
})

export default ConfirmVideoRequestPopupView
