import Model from '../../Model'

const OutOfHoursDaysModel = Model.extend({
    defaults: {
        days: [
            {day: "monday", mondayPermitted: false, mondayProhibited: false, mondayStartTime: "09:00", mondayEndTime: "17:30"},
            {day: "tuesday", tuesdayPermitted: false, tuesdayProhibited: false, tuesdayStartTime: "09:00", tuesdayEndTime: "17:30"},
            {day: "wednesday", wednesdayPermitted: false, wednesdayProhibited: false, wednesdayStartTime: "09:00", wednesdayEndTime: "17:30"},
            {day: "thursday", thursdayPermitted: false, thursdayProhibited: false, thursdayStartTime: "09:00", thursdayEndTime: "17:30"},
            {day: "friday", fridayPermitted: false, fridayProhibited: false, fridayStartTime: "09:00", fridayEndTime: "17:30"},
            {day: "saturday", saturdayPermitted: false, saturdayProhibited: false, saturdayStartTime: "09:00", saturdayEndTime: "17:30"},
            {day: "sunday", sundayPermitted: false, sundayProhibited: false, sundayStartTime: "09:00", sundayEndTime: "17:30"},
        ]
    }

})

export default OutOfHoursDaysModel
