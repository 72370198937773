import TableGridModel from '../../TableGrid@'
import CheckListsList from '../../../collection/report/vehicleDefectReport/CheckListsList'

const CheckListsTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: true,
    tableHead: [
      {
        tableHeadName: 'Checklist Name',
        field: 'descr',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'descr',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Created By',
        field: 'creatorFullName',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'creatorFullName',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Created On',
        field: 'createdDate',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      },
      {
        tableHeadName: 'Last Edited By',
        field: 'modifierFullName',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'modifierFullName',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Last Edited On',
        field: 'modifiedDate',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      },
      {
        tableHeadName: 'Status',
        field: 'statusValue',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'statusValue',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Current Revision',
        field: 'revision',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          }
        ]
      },
      {
        tableHeadName: 'Edit',
        noDrop: true
      },
      {
        tableHeadName: 'Duplicate',
        noDrop: true
      }],
    tableRow: CheckListsList
  }
})

export default CheckListsTableGridModel
