import JqueryUiWidgetModel from '../../../model/JqueryUiWidget'

const ChecklistSelectModel = JqueryUiWidgetModel.extend({
  defaults: {
    widgetName: 'imgselectmenu',
    appendTo: null,
    disabled: false,
    icons: { button: 'ui-icon-triangle-1-s' },
    position: { my: 'left top+3', at: 'left bottom', collision: 'none' },
    width: null

  }
})

export default ChecklistSelectModel
