import CompactReportTableGridView from '../CompactReportTableGrid'
import LeagueTableReportTableGridModel from '../../../model/report/leagueTable/LeagueTableReportTableGrid'

const LeagueTableReportTableGridView = CompactReportTableGridView.extend({
  model: LeagueTableReportTableGridModel,
  templateUrl: 'template/report/leagueTable/leagueTableReportTableGrid.html',
  mergeEvents: true,
  events: {
  },
  initialize: function () {
    var view = this

    view.trigger('ready')
  },
  renderEmptyRows: function (collection) {
    var view = this
    var maxEmptyRows = 0
    var rowCount = collection.models[0].attributes.greenDrivingAllowedRecordList.length || 0
    var emptyRowArray = []

    view.emptyRow = '<tr class="empty-row">'

    for (var i = 0; i < view.$('th').size(); i++) {
      view.emptyRow += '<td></td>'
    }

    view.emptyRow += '</tr>'

    view.$el.find('.empty-row').remove()
    if (rowCount < 9) {
      maxEmptyRows = 9 - rowCount
      for (i = 0; i < maxEmptyRows; i++) {
        emptyRowArray.push(view.emptyRow)
      }
      view.$el.find('tbody:last').append(emptyRowArray.join(''))
    }
    return this
  },
  destroy: function () {
    var view = this

    CompactReportTableGridView.prototype.destroy.apply(view, arguments)
  }
})

export default LeagueTableReportTableGridView
