import CommentModel from '../../Comment'

const CloseDefectCommentModel = CommentModel.extend({
  idAttribute: 'defectId',
  url: 'api/report/resolveDefectCheck',
  validate: function (attrs, options) { // override

  }
})

export default CloseDefectCommentModel
