import View from '../../View'
import TimeCounterView from './TimeCounter'
import OutOfHoursDaysModel from "../../../model/alert/outOfHours/OutOfHoursDays";

const TimeRangeWeekMapFormView = View.extend({
    model: OutOfHoursDaysModel,
    templateUrl: "template/alert/outOfHours/outOfHoursDays.html",
    events: {
        "click [data-button=up]": "up",
        "click [data-button=down]": "down",
        "click .ooh--day .w-check input": "changeDay"
    },
    defaults: null,
    constructor: function () {
        var view = this;
        var args = arguments;

        view.model = new this.model();
        view.defaults = view.model.get('days');

        view.loadDotTemplate(function () {
            view.compileTemplate();
            view.setToModel();
            view.renderModel();

            view.listenTo(view.model, 'change', function(model) {
                view.renderModel();
                view.el = view.$el[0];
                View.apply(view, args);
            });

            view.el = view.$el[0];
            View.apply(view, args);
        });
    },
    initialize: function () {
        var view = this;
        var days = view.model.get('days');

        for(var i = 0; i < days.length; i++){
            view.addSubView(days[i].day + 'StartTime', TimeCounterView, {
                el: this.$el.find("[data-counter=" + days[i].day + 'StartTime' + "]")[0],
                step: 15,
                minVal: 0,
                maxVal: 1439
            })
            view.addSubView(days[i].day + 'EndTime', TimeCounterView, {
                el: this.$el.find("[data-counter=" + days[i].day + 'EndTime' + "]")[0],
                step: 15,
                minVal: 0,
                maxVal: 1439
            })
        }
    },
    renderModel: function () {
        this.$el = $(this.dotTemplateToHtml(this.model.toJSON()));
        this.getParentView().$el.find('.ooh-builder').html(this.$el);
    },
    setToModel: function() {
        var view = this;
        var profileBuilderModel = view.getParentView().getParentView().getParentView().model;
        var daysModel = {};

        daysModel = view.oohParseStringToDays(profileBuilderModel.get('weekMap'));
        view.model.set('days', daysModel);
    },
    setToFormModel: function (data){
        var days = data;
        var view = this;
        var profileBuilderModel = view.getParentView().getParentView().getParentView().model;
        var daysToStr = [];

        days.forEach(function(d, idx){
            if(d[d.day + 'Permitted']){
                daysToStr.push(idx + '=' + '00:00:23:59');
            } else if(d[d.day + 'Prohibited']) {
                daysToStr.push(idx + '=');
            } else {
                daysToStr.push(idx + '=' + d[d.day + "StartTime"] + ':' + d[d.day + "EndTime"])
            }
        });

        profileBuilderModel.set('weekMap', daysToStr.join('|'));
    },
    changeDay: function(e) {
        e.preventDefault();
        var view = this;
        var model = JSON.parse(JSON.stringify(view.model));
        var days = model.days;

        days.filter(function(d, i){
            if(e.currentTarget.name in d){
                if(e.currentTarget.name != d.day + "Prohibited" && d[d.day + "Prohibited"]){
                    d[d.day + "Prohibited"] = false;
                } else if(e.currentTarget.name != d.day + "Permitted" && d[d.day + "Permitted"]) {
                    d[d.day + "Permitted"] = false;
                }
                d[e.currentTarget.name] = e.currentTarget.checked;
            }
        });
        view.setToFormModel(days);
        view.model.set('days', days);
    },
    changeDayTimeInModel: function(time, name) {
        var view = this;
        var model = JSON.parse(JSON.stringify(view.model));
        var days = model.days;

        days.filter(function(d, i){
            // if(name in d){
            d[name] = time;
            // }
        });

        view.setToFormModel(days);
        view.model.set('days', days);
    },
    oohParseStringToDays: function (data) {
        var d = data.split('|');
        var model = JSON.parse(JSON.stringify(this.model));
        var days = model.days;

        for(var i = 0; i < d.length; i++) {
            var a = d[i].split('=');

            if(a[1].length < 1){
                days[i][days[i].day + 'Prohibited'] = true;
            } else {
                var time = a[1].split(':');

                if((time[0] === '00' || time[0] == '0') && (time[1] === '00' || time[1] == '0') && time[2] === '23' && time[3] === '59'){
                    days[i][days[i].day + 'Permitted'] = true;
                } else {
                    days[i][days[i].day + 'StartTime'] = time[0] + ":" + time[1];
                    days[i][days[i].day + 'EndTime'] = time[2] + ":" + time[3];
                }
            }
        }

        return days;
    },
    resetWeekModel: function() {
        this.model.set('days', this.defaults);
    }
});

export default TimeRangeWeekMapFormView;
