import SpeedingReportPopupView from './SpeedingReportPopup'
import SpeedingReportSelectionMenuView from './SpeedingReportSelectionMenu'
import ReportPageView from '../ReportPage'
import SpeedingReportView from './SpeedingReport'

const SpeedingReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="speeding"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', SpeedingReportPopupView)
    view.addSubView('selectionMenu', SpeedingReportSelectionMenuView)
    view.addSubView('report', SpeedingReportView)
    view.render()
  }
})

export default SpeedingReportPageView
