import SelectionMenuVehicleListView from '../../SelectionMenuVehicleList'
import TimeOnSiteReportSelectionMenuVehicleList from '../../../collection/report/timeOnSite/TimeOnSiteReportSelectionMenuVehicleList'
import TimeOnSiteSelectionMenuVehicleListScrollView from './TimeOnSiteSelectionMenuVehicleListScroll'

const TimeOnSiteSelectionMenuVehicleListView = SelectionMenuVehicleListView.extend({
  collection: TimeOnSiteReportSelectionMenuVehicleList,
  templateUrl: 'template/report/timeOnSite/timeOnSiteReportSelectionMenuVehicleList.html',
  elSelector: '[data-vehicle-list=timeOnSite]',
  initialize: function () {
    var view = this

    view.addSubView('scroll', TimeOnSiteSelectionMenuVehicleListScrollView)
    view.render()
  }
})

export default TimeOnSiteSelectionMenuVehicleListView
