import Model from '../Model'

const EmergencyPhoneNumberModel = Model.extend({
  defaults: {
    userId: null,
    phoneNumber: '0000 000 000 000'
  },
  'url': 'api/lone-worker/emergency-phone-number'
})

export default EmergencyPhoneNumberModel
