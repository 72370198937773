import ReportPopupView from '../ReportPopup'
import JourneyCostReportFormView from './JourneyCostReportForm'

const JourneyCostReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/JourneyCost.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', JourneyCostReportFormView)
  }
})

export default JourneyCostReportPopupView
