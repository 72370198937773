import TableGridModel from '../../TableGrid@'
import VehicleList from '../../../collection/VehicleList'

const VehicleListTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: true,
    tableHead: [
      {
        tableHeadName: 'check',
        noDrop: true
      },
      {
        tableHeadName: 'Group',
        field: 'group',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'group',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Vehicle Reg.',
        field: 'vehReg',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'vehReg',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Fleet ID',
        field: 'fleetId',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'fleetId',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Make',
        field: 'make',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'make',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Model',
        field: 'model',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'model',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Vehicle Description',
        field: 'description',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'description',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Assigned Checklist Name',
        field: 'checklistName',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'checklistName',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      }],
    tableRow: VehicleList
  }
})

export default VehicleListTableGridModel
