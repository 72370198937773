import FormView from '../../view/Form'
import GroupTabsetGroupModel from '../../model/groupTabset/GroupTabsetGroup'

const GroupColorPickerFormView = FormView.extend({
  model: GroupTabsetGroupModel,
  elSelector: 'form',
  initialize: function () {
    var view = this

    view.resetElement()
    view.on('save', function (model) {
      view.getParentView().getParentView().collection.get(model.get('id')).set(model.toJSON())
      view.getParentView().hide()
    })
  },
  destroy: function () {
    this.off('save')
    this.remove()
  }
})

export default GroupColorPickerFormView
