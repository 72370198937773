import ReportPopupView from '../ReportPopup'
import IdlingReportFuelUsageFormView from './IdlingReportFuelUsageForm'

const IdlingReportFuelUsagePopupView = ReportPopupView.extend({
  url: '/getReportWindow/IdlingReportFuelUsage.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', IdlingReportFuelUsageFormView)
  }
})

export default IdlingReportFuelUsagePopupView
