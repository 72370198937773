import Model from './Model'

const SelectionMenuModel = Model.extend({
  defaults: {
    vehicles: []
  },
  url: 'api/selection-menu'
})

export default SelectionMenuModel
