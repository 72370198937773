import Backbone from 'backbone'
import $ from 'jquery'
import _ from 'underscore'
import FormView from '../Form'
import ActivityLogFormModel from '../../model/modalPanel/ActivityLogForm'
import DropdownTimePickerView from '../DropdownTimePicker'
import SafeInputView from '../SafeInput'

const ActivityLogFormView = FormView.extend({
  model: ActivityLogFormModel,
  elSelector: '[data-form=refresh]',
  mergeEvents: true,
  events: {
    'click [data-button=pdf]': 'exportToPdf',
    'click [data-button=xls]': 'exportToXls',
    'keyup #fromTime-flexdatalist': 'onKeyUpFromTime',
    'keyup #toTime-flexdatalist': 'onKeyUpToTime'
  },

  onKeyUpFromTime: function (e) {
    var view = this
    if (!isNaN(e.key)) {
      e.preventDefault()
      console.log(view.$('#fromTime-flexdatalist').val())
      var cursorPosition = view.$('#fromTime-flexdatalist').getCursorPosition()
      view.$('#fromTime').val(view.$('#fromTime-flexdatalist').val())
      view.$('#fromTime-flexdatalist').setCursorPosition(cursorPosition)
    }
  },

  onKeyUpToTime: function (e) {
    var view = this
    if (!isNaN(e.key)) {
      e.preventDefault()
      console.log(view.$('#toTime-flexdatalist').val())
      var cursorPosition = view.$('#toTime-flexdatalist').getCursorPosition()
      view.$('#toTime').val(view.$('#toTime-flexdatalist').val())
      view.$('#toTime-flexdatalist').setCursorPosition(cursorPosition)
    }
  },

  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form:first')
    view.el = view.$el[0]
    view.addSubView('dateTimePickerRange', DropdownTimePickerView)
    view.addSubView('safeInputFromTime', SafeInputView, { el: view.$('#fromTime-flexdatalist') }).model.set('allowedChars', ['digits', 'colon', 'edit', 'navigation'])
    view.addSubView('safeInputToTime', SafeInputView, { el: view.$('#toTime-flexdatalist') }).model.set('allowedChars', ['digits', 'colon', 'edit', 'navigation'])

    FormView.apply(this, arguments)
  },

  initialize: function (option) {
    var view = this
    console.log('initialize()')

    view.model.set('unitId', window.wls.getLastClickedVehicle().data.id)
    view.resetElement()
    view.findSubView('dateTimePickerRange').today()
    view.syncData()
    view.submit()
  },

  submit: function (doneCallback) {
    var view = this
    console.log('submit()')
    var tableGrid = view.getParentView().findSubView('compactReportTableGrid')
    var parentView = view.getParentView()
    parentView.clearMap()
    view.$('input:checkbox:checked').prop('checked', false)
    parentView.plotModeOn = false
    parentView.findSubView('compactReportTableGrid').logsOnMap = []

    if (tableGrid) {
      _.defer(function () { // allow loader rendering without blocking the UI thread from updating
        var tableRowLen = tableGrid.model.get('tableRow').length
        tableGrid.model.set('tableRow', [])

        if (!tableRowLen) {
          tableGrid.render()
        }
        tableGrid.showLoader()
      })

      var cmodel = this.model

      var dateParams = cmodel.get('date').split(/[\.]/)
      var yyyy = dateParams[2]
      var mm = dateParams[1]
      var dd = dateParams[0]
      var dateStr = mm + '/' + dd + '/' + yyyy
      var from = Date.parse(dateStr + ' ' + cmodel.get('fromTime'))
      var to = Date.parse(dateStr + ' ' + cmodel.get('toTime'))

      // console.log(from)
      // console.log(to)

      var params = {
        fromDate: from,
        toDate: to,
        unitId: cmodel.get('unitId')
      }

      Backbone.sync('read', view.model, {
        data: $.param(params),
        success: function (collection) {
          tableGrid.model.set('tableRow', collection.activityLogRecordList)
          window.toPoiCollection = collection.activityLogRecordList
          tableGrid.render()
          tableGrid.hideLoader()
          tableGrid.getParentView().$('.table-spinner').removeClass('active')
          tableGrid.findSubView('scroll').render()
          parentView.setPlotAllActive(collection.activityLogRecordList.length > 0)
          doneCallback && doneCallback(collection.activityLogRecordList)
          // parentView.$el.css('height', '')
        },
        error: function (msg) {
          console.log(msg.status + ' ' + msg.statusText)
          tableGrid.hideLoader()
          tableGrid.getParentView().$('.table-spinner').removeClass('active')
          parentView.setPlotAllActive(false)
          parentView.$el.css('height', '')
        }
      })
    }
  },

  destroy: function () {
    var view = this
    view.remove()
  },

  exportToPdf: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('PDF'))
  },
  exportToXls: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('XLS'))
  },
  generatePdfXlsReportURL: function (repFormat) { // implementation
    var model = this.model
    var dateParams = model.get('date').split(/[\.]/)
    var yyyy = dateParams[2]
    var mm = dateParams[1]
    var dd = dateParams[0]
    var dateStr = mm + '/' + dd + '/' + yyyy
    var from = Date.parse(dateStr + ' ' + model.get('fromTime'))
    var to = Date.parse(dateStr + ' ' + model.get('toTime'))
    var data = {
      'report-name': 'ActivityLog',
      'report-type': 'ActivityLog',
      'report-format': repFormat,
      fromDate: from,
      toDate: to,
      unitId: model.get('unitId'),
      VEHICLE_REGISTRATION: model.get('registration_number')
    }
    return $.param(data)
  }
})

export default ActivityLogFormView
