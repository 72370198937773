/**
 * Created by George on 29.05.2015.
 */
import Collection from '../../Collection';
import Profile from '../../../model/alert/outOfHours/Profile';

const DriveBehaviorAlertAvailableProfileList = Collection.extend( {
    model: Profile,

    url : function () {
        return `/services/driver-behaviour/profile/${this.id ? this.id : ""}`;
    }
})

export default DriveBehaviorAlertAvailableProfileList;
