import View from '../../View'
import LocationReportFormView from './LocationReportForm'
import LocationReportView from './LocationReport'

const LocationReportPageView = View.extend({
  el: '<div class="location-report-view"></div>',
  initialize: function () {
    var view = this
    view.addSubView('locationReportForm', LocationReportFormView)
    view.addSubView('locationReport', LocationReportView)
    view.render()
  }
})

export default LocationReportPageView
