/**
 * Created by George on 20.07.2015.
 */
import ProfileManagerTableGridView from "../profileManager/ProfileManagerTableGrid";
import ProfileList from "../../../collection/alert/driverBehavior/DriveBehaviorAlertAvailableProfileList";

const DriverBehaviorProfileManagerTableGridView = ProfileManagerTableGridView.extend({
    collection: ProfileList
});

export default DriverBehaviorProfileManagerTableGridView;
