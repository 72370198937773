import $ from 'jquery'
import PopupView from '../Popup'
import DriverWorkingHoursTotalView from './DriverWorkingHoursTotal'
import DriverWorkingHoursCalendarPopupView from './DriverWorkingHoursCalendarPopup'

const DriverWorkingHoursTodayPopupView = PopupView.extend({
  url: 'js/html/app/src/template/driverWorkingHours/driverWorkingHoursTodayPopup.html',
  mergeEvents: true,
  events: {
    'click [data-button=expand]': 'expandButtonHandler'
  },
  initialize: function () {
    var view = this

    view.addSubView('driverWorkingHoursTotal', DriverWorkingHoursTotalView)
    view.addSubView('driverWorkingHoursCalendarPopup', DriverWorkingHoursCalendarPopupView)
  },
  expandButtonHandler: function () {
    var view = this
    var driverWorkingHoursCalendarPopupView = view.findSubView('driverWorkingHoursCalendarPopup')

    view.hide()
    driverWorkingHoursCalendarPopupView.fetchCalendarPopupData().done(function () {
      driverWorkingHoursCalendarPopupView.prepare()
      driverWorkingHoursCalendarPopupView.render()
      driverWorkingHoursCalendarPopupView.prepareDone()
      driverWorkingHoursCalendarPopupView.show()
      driverWorkingHoursCalendarPopupView.alignBy($('[data-button=tachograph].active').closest('.row'), 'topRight', { top: 0, left: 5 })
      if (driverWorkingHoursCalendarPopupView.isOutsideBottomBorder()) {
        driverWorkingHoursCalendarPopupView.moveTo($(window).height() - driverWorkingHoursCalendarPopupView.$el.height() - 37)
      }
    })
  },
  hideActivePopup: function () {
    var view = this
    var driverWorkingHoursCalendarPopupView = view.findSubView('driverWorkingHoursCalendarPopup')
    var driverWorkingHoursDetailsPopupView = driverWorkingHoursCalendarPopupView.findSubView('driverWorkingHoursCalendar').findSubView('driverWorkingHoursDetailsPopup')

    if (view.isVisible()) {
      view.hide()
    }
    if (driverWorkingHoursCalendarPopupView.isVisible()) {
      driverWorkingHoursCalendarPopupView.hide()
    }
    if (driverWorkingHoursDetailsPopupView.isVisible()) {
      driverWorkingHoursDetailsPopupView.hide()
    }
    $('[data-button=tachograph].active').removeClass('active')
  },
  closeBtnHandler: function () { // override
    $('[data-button=tachograph]').removeClass('active')
    this.hide()
  }
})

export default DriverWorkingHoursTodayPopupView
