import _ from 'underscore'
import View from '../View'
import SelectedVehicleList from '../../collection/report/SelectedVehicleList'
import HorizontalScroll from '../HorizontalScroll'

const SelectedVehicleView = View.extend({
  templateUrl: 'template/report/selectedVehicleList.html',
  collection: SelectedVehicleList,
  constructor: function () {
    var view = this
    var args = arguments
    view.collection = new this.collection()

    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, args)
    })
  },
  initialize: function () {
    var view = this

    view.addSubView('scroll', HorizontalScroll)
  },
  syncSelectedVehicleList: function (selectedVehicles) {
    var vehicleListInput = this.$('[data-input=unitIdList]')

    this.collection.set(selectedVehicles)
    vehicleListInput.val(_.pluck(selectedVehicles, 'id').join(','))
  },
  render: function () {
    var view = this
    var html = view.dotTemplateToHtml(view.collection.toJSON())

    view.$el.find('ul').html(html)
    view.imageLoad(function () {
      view.findSubView('scroll').render()
    })
    return this
  },
  imageLoad: function (callback) {
    var view = this
    var $image = view.$el.find('ul img')
    var length = $image.size()
    var count = 0
    if (length) {
      $image.one('load', function () {
        if (++count === length) {
          if (callback) callback()
          view.trigger('imageLoad')
        }
      })
    } else {
      if (callback) callback()

      view.trigger('imageLoad')
    }
  },
  destroy: function () {
    this.findSubView('scroll').destroy()
    this.remove()
  }

})

export default SelectedVehicleView
