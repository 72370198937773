import PopupModel from '../Popup'

const DriverWorkingHoursCalendarPopupModel = PopupModel.extend({
  defaults: {
    driverList: [],
    calendarData: [],
    isDraggable: true
  },
  url: 'api/tachograph/driverWorkingHoursCalendar'
})

export default DriverWorkingHoursCalendarPopupModel
