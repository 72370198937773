import CompactReportPageView from '../CompactReportPage'
import FuelUsageReportView from './FuelUsageReport'

const FuelUsageReportPageView = CompactReportPageView.extend({
  el: '<div class="report-page" data-report-page="fuelUsage"></div>',
  initialize: function () {
    var view = this

    view.addSubView('compactReport', FuelUsageReportView)
    view.render()
  }
})

export default FuelUsageReportPageView
