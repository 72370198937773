/**
 * Created by George on 07.04.2015.
 */
/**
 * Created by George on 26.03.2015.
 */
import ProfileManagerPopupView from "../profileManager/ProfileManagerPopup";
import DriverBehaviorProfileManagerTableGridView from "./DriverBehaviorProfileManagerTableGrid";
import DriverBehaviorProfileBuilderPopupView from "./DriverBehaviorProfileBuilderPopup";

const DriverBehaviorProfileManagerPopupView = ProfileManagerPopupView.extend({
    title: 'Driver Behaviour Parameters Profiles',
    ProfileManagerTableGrid: DriverBehaviorProfileManagerTableGridView,
    ProfileManagerBuilderPopupView: DriverBehaviorProfileBuilderPopupView
});

export default DriverBehaviorProfileManagerPopupView;
