import $ from 'jquery'
import CompactAccountReportFormView from '../CompactAccountReportForm'
import FuelUsageReportFormModel from '../../../model/report/fuelUsageReport/FuelUsageReportForm'

const FuelUsageReportFormView = CompactAccountReportFormView.extend({
  model: FuelUsageReportFormModel,
  generatePdfXlsReportURL: function (repFormat) { // implementation
    var model = this.model
    var accountId = $('input[name=groupId][value=' + $.cookie('lastViewedGroup') + ']').closest('.account-list').parent().find('[data-accountid]').attr('data-accountid')
    var data = {
      'report-name': 'FuelUsage',
      'report-type': 'FuelUsage',
      'report-format': repFormat,
      from: model.get('from'),
      to: model.get('to'),
      account_id: accountId
    }
    return $.param(data)
  }
})

export default FuelUsageReportFormView
