import Model from '../Model'

const SendCommandModel = Model.extend({
    defaults: {
        commandKey: '',
        commandValue: '',
        payload: '',
        unitId: ''
    },
    urlRoot: 'api/selection-menu/action/send-command'
})

export default SendCommandModel
