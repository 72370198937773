import $ from 'jquery'
import CompactReportView from '../../report/CompactReport'
import PoiListTableGridView from './PoiListTableGrid'

const PoiListView = CompactReportView.extend({
  templateUrl: 'template/alert/geoZones/poiList.html',
  initialize: function () {
    var view = this

    view.addSubView('compactReportTableGrid', PoiListTableGridView, this.$('[data-table-grid=poiList]'))
    view.loadList()
  },
  loadList: function () {
    var view = this
    var tableGrid = view.findSubView('compactReportTableGrid')

    $.post('/getAllPoisForCurrentUser.do', { active: true }).done(function (json) {
      var data = tableGrid.updatePoiList(json.data)

      tableGrid.model.set('tableRow', data)
      tableGrid.render()
      view.show()
      tableGrid.findSubView('scroll').render()
    })
  },
  show: function () {
    this.$el.css({ visibility: 'visible', display: 'none', left: $('.tabset-report-panel').width() }).fadeIn(400, function () {
      $('.map-wrap').removeClass('active').css('margin-left', 0)
      $('.left-panel').removeClass('active')
    })
  },
  render: function () {
    var view = this

    view.getParentView().$el.append(view.$el)
    return view
  },
  destroy: function () {
    var view = this

    view.findSubView('compactReportTableGrid').destroy()
    view.remove()
  }
})

export default PoiListView
