import RecipientList from '../../../collection/alert/RecipientList'
import TableGrid from '../../TableGrid@'

const TowAwayAlertSelectRecipientTableGridModel = TableGrid.extend({
  defaults: {
    selectAllowed: false,
    tableHead: [
      {
        tableHeadName: 'First Name',
        field: 'firstName',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            dataButton: 'sortAsc',
            iconClass: 'asc'
          },
          {
            optionName: 'Sort Descending',
            dataButton: 'sortDesc',
            iconClass: 'desc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'firstName',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Last Name',
        field: 'lastName',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            dataButton: 'sortAsc',
            iconClass: 'asc'
          },
          {
            optionName: 'Sort Descending',
            dataButton: 'sortDesc',
            iconClass: 'desc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'lastName',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'Email Address',
        field: 'email',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            dataButton: 'sortAsc',
            iconClass: 'asc'
          },
          {
            optionName: 'Sort Descending',
            dataButton: 'sortDesc',
            iconClass: 'desc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Show items with value that:',
                  filterByField: 'email',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Starts with',
                        value: 'startsWith'
                      },
                      {
                        option: 'Ends With',
                        value: 'endsWith'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter value',
                    name: 'value',
                    dataType: 'string'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        tableHeadName: 'EMAIL',
        field: 'sendByEmail',
        noDrop: true
      },
      {
        tableHeadName: 'Delete',
        noDrop: true
      },
      {
        tableHeadName: 'Edit',
        noDrop: true
      }
    ],
    tableRow: RecipientList
  }
})

export default TowAwayAlertSelectRecipientTableGridModel
