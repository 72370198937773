import $ from 'jquery'
import SnailTrailPopupView from '../SnailTrailPopup'
import DriverBehaviourSnailTrailTableGridView from './DriverBehaviourSnailTrailTableGrid'
import SnailTrailStepPlayView from '../SnailTrailStepPlay'

const DriverBehaviourSnailTrailPopupView = SnailTrailPopupView.extend({
  url: '/getJourneyDetailsPopup.do',
  initialize: function () {
    this.addSubView('SnailTrailStepPlayer', SnailTrailStepPlayView, { el: this.$('[data-player=stepPlay]') })
    this.addSubView('snailTrailTableGrid', DriverBehaviourSnailTrailTableGridView)
    this.findSubView('snailTrailTableGrid').collection.url = 'api/report/driver-behaviour/snailTrail'
  },
  show: function () { // override
    $('[data-popup="journeyDetails"]').addClass('journey-report')
    $('[data-popup="journeyDetails"]').addClass('driver-behaviour')
    $('#content').prepend($('[data-popup="journeyDetails"]'))

    SnailTrailPopupView.prototype.show.apply(this, arguments)
  },
  exportToPdf: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('PDF'))
  },
  generatePdfXlsReportURL: function (repFormat) { // implementation
    var view = this
    var parentView = view.getParentView()
    var tableGridView = view.findSubView('snailTrailTableGrid')
    var journeyIds = parentView.getSelectedJourneyList()
    var mapMetadata = window.mapCanvas.map.getCenter()

    var data = {
      'report-name': 'DriverBehaviourImg',
      'report-type': 'DriverBehaviourImg',
      'report-format': repFormat,
      alternate: false,
      journeyIdList: journeyIds.join('/'),
      centerLat: mapMetadata.latitude ? mapMetadata.latitude : mapMetadata.lat(),
      centerLon: mapMetadata.longitude ? mapMetadata.longitude : mapMetadata.lng(),
      zoomLevel: window.mapCanvas.map.getZoom(),
      selectedIndex: tableGridView.selectedIndex
    }

    return $.param(data)
  }
})

export default DriverBehaviourSnailTrailPopupView
