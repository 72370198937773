import $ from 'jquery'
import FormView from '../../Form'
import DrivingTimeSettingsModel from '../../../model/report/drivingTimeReport/DrivingTimeSettingsForm'
import CounterView from '../../Counter'

const DrivingTimeSettingsFormView = FormView.extend({
  model: DrivingTimeSettingsModel,
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form')
    view.el = view.$el[0]

    FormView.apply(this, arguments)
  },

  initialize: function (option) {
    var view = this

    view.addSubView('secondsCounter', CounterView, { el: $('[data-counter=secondsCounter]') })
    window.settings.get(true)
    view.model.fetch()
    view.findSubView('secondsCounter').$input.val(view.model.get('drivingTimeIdlingSeconds'))
  },

  submit: function () {
    var view = this

    var drivingTimeIdlingSeconds = view.findSubView('secondsCounter').$input.val()
    view.model.set('drivingTimeIdlingSeconds', drivingTimeIdlingSeconds)
    window.settings.data.drivingTimeIdlingSeconds = drivingTimeIdlingSeconds
    view.model.save()
    var parentView = view.getParentView()
    view.remove()
    parentView.destroy()
  },

  handleReset: function () {
    var view = this
    var parentView = view.getParentView()

    view.model.set('drivingTimeIdlingSeconds', 0)
    window.settings.data.drivingTimeIdlingSeconds = 0
    view.model.save()

    view.remove()
    parentView.destroy()
  },

  destroy: function () {
    var view = this
    view.findSubView('secondsCounter').destroy()
    view.remove()
  }

})

export default DrivingTimeSettingsFormView
