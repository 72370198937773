import AlertListPopupView from '../AlertListPopup'
import GeoFenceAlertTableGridView from './GeoFenceAlertTableGrid'
import GeoFenceAlertListPopupModel from '../../../model/alert/geofence/GeoFenceAlertListPopup'

const GeoFenceAlertListPopupView = AlertListPopupView.extend({
  model: GeoFenceAlertListPopupModel,
  AlertTableGridView: GeoFenceAlertTableGridView
})

export default GeoFenceAlertListPopupView
