import View from '../../View'
import CompactReportView from '../CompactReport'
import FuelUsageReportFormView from './FuelUsageReportForm'
import FuelUsageReportTableGridView from './FuelUsageReportTableGrid'

const FuelUsageReportView = CompactReportView.extend({
  templateUrl: 'template/report/fuelUsageReport/fuelUsageReport.html',
  initialize: function (option) {
    var view = this

    view.addSubView('compactReportTableGrid', FuelUsageReportTableGridView, this.$('[data-table-grid=fuelUsage]')).once('ready', function () {
      view.addSubView('compactReportForm', FuelUsageReportFormView)
    })
  }
})

export default FuelUsageReportView
