import PopupView from '../../Popup'
import LocationReportTableGridView from './LocationReportTableGrid'
import LocationReportPopupModel from '../../../model/report/location/LocationReportPopup'

const LocationReportView = PopupView.extend({
  templateUrl: 'template/report/location/locationReport.html',
  url: 'js/html/app/src/template/report/location/locationReport.html',
  model: LocationReportPopupModel,
  mergeEvents: true,
  events: {
    'click [data-button=close]': 'closeBtnHandler',
    'click [data-button=cancel]': 'closeHandler',
    'click [data-button=save]': 'submit',
    'click .plot-all :checkbox': 'plotAll',
    'click .max-min_btn': 'maxMin'
  },

  initialize: function (closeCallback) {
    var view = this
    console.log('initialize()')

    this.plotModeOn = false
    if (closeCallback) {
      this.closeCallback = closeCallback
    }

    view.getParentView().$el.append(view.$el)
    this.somaTemplate = view.$el.html()
    this.createTemplate(view.el)

    view.addSubView('compactReportTableGrid', LocationReportTableGridView, this.$('[data-table-grid=activityLog]'))

    view.render()
  },

  closeBtnHandler: function () { // override
    var view = this

    view.clearMap()
    view.$el.css('visibility', 'hidden')
  },

  maxMin: function () {
    var view = this

    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.$el.animate({ top: view.model.get('position').top, left: view.model.get('position').left }, 300)
      view.maximiseIncidentDetails()
    } else {
      view.model.set('position', { top: view.$el[0].offsetTop, left: view.$el[0].offsetLeft })
      view.minimiseIncidentDetails()
      view.$el.animate({ top: window.innerHeight * 0.96 + 'px', left: '260px' }, 300)
    }
  },

  maximiseIncidentDetails: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.find('.info-holder').show()
    view.$el.find('.control-holder').show()
    view.$el.css('height', 'auto')
  },

  minimiseIncidentDetails: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.find('.info-holder').hide()
    view.$el.find('.control-holder').hide()
    view.$el.css('height', 'auto')
  },

  destroy: function () {
    var view = this

    this.clearMap()

    view.remove()
  },

  isVisible: function () {
    return this.$el && this.$el.is(':visible')
  },

  render: function (show) {
    var view = this

    view.model.set('visible', true)

    if (show) {
      this.$el.css('visibility', 'visible')
    }

    view.getParentView().$el.append(view.$el)

    return this
  },

  plotAll: function () {
    var view = this

    if (this.plotModeOn) {
      view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
      view.findSubView('compactReportTableGrid').logsOnMap = []
      view.findSubView('compactReportTableGrid').markAllActive(false)
      this.plotModeOn = false
    } else {
      view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
      view.findSubView('compactReportTableGrid').plotAll()
      view.findSubView('compactReportTableGrid').markAllActive(true)
      this.plotModeOn = true
    }
  },

  clearMap: function () {
    var view = this
    view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
  },

  setPlotAllActive: function (active) {
    var view = this

    if (active) {
      view.$('.plot-all').removeClass('disabled')
    } else {
      view.$('.plot-all').addClass('disabled')
    }
  }
})

export default LocationReportView
