import TableGridView from '../../TableGrid'
import ProfileManagerTableGridModel from '../../../model/alert/profileManager/ProfileManagerTableGrid'
import NotificationPopupView from '../../NotificationPopup'
import ProfileManagerTableGridScrollView from './ProfileManagerTableGridScroll'
import $ from 'jquery'

const ProfileManagerTableGridView = TableGridView.extend({
  templateUrl: 'template/alert/profileManager/profileManagerTableGrid.html',
  model: ProfileManagerTableGridModel,
  events: {
    'click [data-button=delete]:not([data-show=false])': 'deleteProfile',
    'click [data-button=edit]': 'edit'
  },
  initialize: function (option) {
    var view = this
    var profileManagerPanelView = view.getParentView().getParentView()
    var outOfHoursProfileManagerFormView = profileManagerPanelView.findSubView('profileManagerForm')

    view.addSubView('scroll', ProfileManagerTableGridScrollView)
    view
      .listenTo(view.collection, 'change:active', function (profileManagerModel) {
        profileManagerModel.save().done(function () {
          view.renderRow(profileManagerModel)
        })
      })
      .listenTo(view.collection, 'destroy', function (profileManagerModel, profileManagerCollection, _option) {
        _option.xhr.done(function () {
          view.$el.find('[data-id=' + profileManagerModel.get('id') + ']').remove()
        })
      })
  },
  deleteProfile: function (e) {
    var view = this
    var $tr = this.$(e.currentTarget).closest('tr')
    var profileModel = this.collection.get($tr.attr('data-id'))
    NotificationPopupView.render({
      title: 'Confirm Action',
      message: 'Are you sure you want to Delete this Profile?',
      button: ['Cancel', 'Confirm']
    })
    NotificationPopupView.once('answer', function (actionName) {
      if (actionName === 'ok') {
        view.findSubView('scroll').render()
        var profileManagerBuilderPopup = view.getParentView().findSubView('profileManagerBuilderPopup')
        profileManagerBuilderPopup.hide()
        view.resetSelectedItemAndRow()

        var profileManagerPopup = view.getParentView()
        var availableSelectedTableGrid = profileManagerPopup.getParentView().tabsetView.selectProfile.innerView.findSubView('availableSelectedTableGrid')
        var profileManagerTableGrid = profileManagerPopup.findSubView('profileManagerTableGrid')
        profileModel.destroy().done(function () {
          availableSelectedTableGrid.fetchProfileList().done(function () {
            if (availableSelectedTableGrid.selectedTableGridCollection.get(profileModel.get('id'))) {
              availableSelectedTableGrid.selectedTableGridCollection.remove(profileModel.get('id'))
            }

            availableSelectedTableGrid.reloadContent(availableSelectedTableGrid.selectedTableGridCollection.toJSON(), true)
          })
          profileManagerTableGrid.fetchProfileList()
          profileManagerTableGrid.resetSelectedItemAndRow()
        })
      }
    })
  },
  edit: function (e) {
    var profileManagerBuilderPopup = this.getParentView().findSubView('profileManagerBuilderPopup')
    var profileBuilderFormView = this.getParentView().findSubView('profileManagerBuilderPopup').findSubView('profileBuilderForm')
    var profileBuilderFormModel = profileBuilderFormView.model
    var selectVehiclesView = profileManagerBuilderPopup.findSubView('profileBuilderForm').findSubView('profileBuilderTabset').selectVehicles.innerView
    var selectVehiclesModel = selectVehiclesView.model
    var selectVehiclesScroll = profileBuilderFormView.findSubView('profileBuilderTabset').selectVehicles.innerView.findSubView('scroll')
    var id = ''

    e.currentTarget = $(e.currentTarget).closest('tr')[0]

    id = $(e.currentTarget).attr('data-id')

    selectVehiclesModel.set('id', id)
    profileBuilderFormModel.set('id', id)
    profileManagerBuilderPopup.findSubView('profileBuilderForm').setMode('edit')
    selectVehiclesView.fetchList({
      success: function () {
        profileBuilderFormModel.fetch({
          success: function () {
            profileBuilderFormView.render()
          }
        })
        selectVehiclesView.fetchListSuccess()
        selectVehiclesView.render()
      }
    })
    profileManagerBuilderPopup.show()
    profileManagerBuilderPopup.alignBy($(profileManagerBuilderPopup.getParentView().el), 'topRight')
    this.setActiveRow(e)
    selectVehiclesScroll.render()
  },
  fetchProfileList: function (success, noRender) {
    var view = this
    var renderScroll = function () {
      view.findSubView('scroll').render()
    }
    var options = {
      success: success,
      requestType: 'fetch'
    }

    if (noRender) {
      renderScroll = options.requestType = null
    }
    this.collection.fetch(options).done(renderScroll)
  },
  resetSelectedItemAndRow: function () {
    TableGridView.prototype.resetSelectedItemAndRow.apply(this, arguments)
    this.getParentView().getParentView().model.set('profileId', null)
  },
  destroy: function () {
    this.remove()
    this.findSubView('scroll').destroy()
  }
})

export default ProfileManagerTableGridView
