import $ from 'jquery'
import CompactReportTableGridView from '../../report/CompactReportTableGrid'
import PoiListTableGridModel from '../../../model/alert/geoZones/PoiListTableGrid'

const PoiListTableGridView = CompactReportTableGridView.extend({
  events: {
    'click [data-button=activateDeactivate]': 'activateDeactivate',
    'click [data-button=edit]': 'edit'
  },
  model: PoiListTableGridModel,
  templateUrl: 'template/alert/geoZones/poiListTableGrid.html',
  activateDeactivate: function (e) {
    var view = this
    var tr = $(e.currentTarget).closest('tr')
    var poiId = tr.attr('data-id')
    var tableRow = view.model.get('tableRow')
    var currPoi = tableRow.get(poiId)

    $.ajax({
      type: 'POST',
      url: '/activateDeactivatePoi.do',
      data: JSON.stringify({ id: currPoi.id, active: currPoi.attributes.status !== 'Active' }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      success: function (json) {
        view.updateRecord(json)
        window.wlsPOIHandler.setOOI([])
        view.render()
      }
    })
  },
  edit: function (e) {
    var tr = $(e.currentTarget).closest('tr')
    var poiId = tr.attr('data-id')
    var tableRow = this.model.get('tableRow')
    var currPoi = tableRow.get(poiId)
    var $HTML = $('.tabset-report-wrap [data-tab=poiManager]')
    var $inputs = $HTML.find('[data-input]')
    var $selects = $HTML.find('[data-select]')
    var $colorpickers = $HTML.find('[data-colorpicker]')

    window.createdPoiPin = false

    tr.addClass('active').siblings().removeClass('active')

    $inputs.filter(function () {
      this.value = currPoi.attributes[this.getAttribute('data-input')]
    })

    if (currPoi.attributes.accountDto.id === $selects.filter('[data-select=accountDto]').val()) {
      $selects.filter('[data-select=categoryDto]').val(currPoi.attributes.categoryDto.id)
    }

    $selects.filter('[data-select=accountDto]').val(currPoi.attributes.accountDto.id)
    $selects.selectmenu('refresh')

    $colorpickers.filter(function () {
      var currColorpicker = this
      var color = currPoi.attributes[currColorpicker.getAttribute('data-colorpicker')].toLowerCase()

      $.each(currColorpicker.options, function (index) {
        if (this.value === color) {
          currColorpicker.selectedIndex = index
          return false
        }
      })
    })

    $colorpickers.colorpicker('refresh')
    window.poiManager.switchDataMode('edit')
    window.poiManager.addPoiPin($inputs.filter('[data-input=lon]').val(), $inputs.filter('[data-input=lat]').val())
  },
  updatePoiList: function (json) {
    var data = json
    var dataLen = json.length

    for (var i = 0; i < dataLen; i++) {
      var currData = data[i]

      if (currData.status === 'Active') {
        currData.statusAction = 'Deactivate'
      } else {
        currData.statusAction = 'Activate'
      }
    }

    return data
  },
  updateRecord: function (json) {
    var tableRow = this.model.get('tableRow')
    var currPoi = tableRow.get(json.id)

    currPoi.attributes = json

    if (currPoi.attributes.status === 'Active') {
      currPoi.attributes.statusAction = 'Deactivate'
    } else {
      currPoi.attributes.statusAction = 'Activate'
    }
  },
  render: function () {
    var view = this
    var tableDropView = view.findSubView('tableDrop')
    var filterData = tableDropView.getFilterData()
    if (filterData && Object.keys(filterData).length === 0) {
      var preselectedFilterData = { field: 'status', filterFnName: 'equal', value: 'Active' }
      tableDropView.filterData = { 7: [preselectedFilterData] }
    }
    CompactReportTableGridView.prototype.render.apply(this, arguments)
  }
})

export default PoiListTableGridView
