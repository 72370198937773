import Model from './Model'

const ScrollModel = Model.extend({
  defaults: {
    horizontal: false, // Switch to horizontal mode.
    dragHandle: true,

    scrollBy: 100, // Pixels or items to move per one mouse scroll. 0 to disable scrolling.

    scrollBar: '.scrollbar', // Selector or DOM element for scrollbar container.
    minHandleSize: 50, // Minimal height or width (depends on sly direction) of a handle in pixels.

    // Mixed options
    speed: 300, // Animations speed in milliseconds. 0 to disable animations.
    easing: 'linear', // Easing for duration based (tweening) animations.
    dynamicHandle: true // Scrollbar handle represents the ratio between hidden and visible content.
  }
})

export default ScrollModel
