import $ from 'jquery'
import _ from 'underscore'
import View from './View'
import TableGridModel from '../model/TableGrid@'

const TableGridView = View.extend({
  selectedItem: null,
  model: TableGridModel,
  templateUrl: null,
  collection: null,
  elSelector: '[data-table-grid]',
  events: {
    'click tbody tr:not(.empty-row)': 'setActiveRow',
    'click th': 'showTableDrop'
  },
  constructor: function (option) {
    var view = this
    var args = arguments

    view.$el = view.getParentView().$(view.elSelector)
    view.el = view.$el[0]
    view.initializeModel()
    view.templateOptions = {
      hasGroup: true,
      hasSubGroup: true,
      distanceMetric: window.settings ? window.settings.data.distanceMetric : 'Miles'
    }

    if (view.collection) {
      view.initializeCollection()
    }

    view.bindListenersLoadAndCreateTemplate(view.$el, view, args)
  },
  initializeModel: function () {
    this.model = new this.model()
  },
  initializeCollection: function () {
    this.collection = new this.collection()
  },
  render: function (callback) {
    var view = this
    var html
    var json
    var tableDropView = view.findSubView('tableDrop')
    var sortData = tableDropView.getSortData()
    var filterData = tableDropView.getFilterData()
    var sortDataSize = _.size(sortData)
    var filterDataSize = _.size(filterData)

    if (!view.$el.hasClass('veh-list')) {
      view.checkGroupColumn()
    }

    if (!view.getParentView().$el.hasClass('ooi-list-popup') &&
      !view.getParentView().$el.hasClass('exclude-driver') &&
      !view.getParentView().$el.hasClass('league-table') &&
      !view.getParentView().$el.hasClass('todays-journeys') &&
      !view.$el.hasClass('checklist-items') &&
      !view.$el.hasClass('veh-list')) {
      view.checkSubGroupColumn()
    }

    if (filterDataSize || sortDataSize) {
      if (!view.templateOptions.hasGroup || !view.templateOptions.hasSubGroup) {
        if (sortData.field === 'group' || sortData.field === 'subGroup') {
          tableDropView.clearSortData()
          sortData = tableDropView.getSortData()
          sortDataSize = 0
        }
        if (!view.templateOptions.hasGroup) {
          tableDropView.clearFilterData(null, 1)
        }
        if (!view.templateOptions.hasSubGroup) {
          tableDropView.clearFilterData(null, 2)
        }
        filterDataSize = _.size(filterData)
      }
      json = {
        tableHead: view.model.get('tableHead'),
        tableRow: view.model.get('tableRow').toJSON()
      }
      if (filterDataSize) {
        json.tableRow = view.filter(filterData, json.tableRow)
      }
      if (sortDataSize) {
        json.tableRow = view[sortData.sortOrder](sortData.field, json.tableRow)
      }
    }

    html = view.dotTemplateToHtml(json || this.model.toJSON())
    view.$el.html(html)
    view.updateTableHeadColumnIcon(sortData, filterData)

    if (view.model.get('columnWidth').length && view.$el.is(':visible')) {
      view.calculateColumnWidth()
    }

    view.findSubView('scroll').render()
    if (!view.$el.hasClass('checklist-items')) {
      this.renderEmptyRows(json && json.tableRow || view.model.get('tableRow'))
    }

    if (callback) {
      _(function () {
        callback()
      }).defer()
    }

    return view
  },
  bindListenersLoadAndCreateTemplate: function ($el, view, args) {
    if (!view.model.get('selectAllowed')) {
      $el.addClass('unselectable')
      view.disableEvent('click tr')
    }
    if (view.$el.children().size()) {
      view.setDotTemplate(view.$el.html())
      view.compileTemplate()
      View.apply(view, args)
    } else {
      view.loadDotTemplate(function () {
        view.compileTemplate()
        view.selectedItem = {}
        View.apply(view, args)
      })
    }
    $(document).on('click.tableDrop', function (e) {
      var currEl = $(e.target)

      if (!currEl.closest('th').size() && !currEl.closest('[data-drop]').size()) {
        const tableDropView = view.findSubView('tableDrop')

        view.$('th.active').removeClass('active')
        tableDropView && tableDropView.hide()
      }
    })
    view
      .listenTo(view.collection, 'remove', function () {
        view.render()
      })
      .listenTo(view.model, 'change:selectAllowed', function (model, selectAllowed) {
        if (!selectAllowed) {
          $el.addClass('unselectable')
          view.disableEvent('click tr')
        } else {
          $el.removeClass('unselectable')
        }
      })
    //                .loadTemplate(function(){
    //                    var $emptyRow
    //
    //                    view
    //                        .createTemplate($el.find('tbody:last')[0])
    //                        .listenTo(view.collection, 'request', function (_collection, xhr, _option) {
    //                            if (_option && _option.requestType == 'fetch') {
    //                                xhr.done(function () {
    //                                    view.render()
    //                                })
    //                            }
    //                        })
    //                    $emptyRow = $el.find('tbody:last tr').clone().children().html('').end()
    //                    view.emptyRow = '<tr class='empty-row'>' + ($emptyRow.html()) + '</tr>'
    //                    view.selectedItem = {}// prevent from prototyping
    //
    //                    View.apply(view,args)
    //                })
  },
  addClassActiveTh: function ($th) {
    if (!$th.hasClass('active')) {
      $th.toggleClass('active').siblings().removeClass('active')
    }
  },
  showSortIcon: function ($th, sortOrder) {
    var iconClass = sortOrder === 'sortAsc' ? 'asc' : 'desc'

    $th.find('[data-icon=sort]').removeClass('asc').removeClass('desc').addClass(iconClass)
  },
  showFilterIcon: function ($th) {
    $th.find('[data-icon=filter]').addClass('filter')
  },
  hideSortIcon: function ($th) {
    $th.find('[data-icon=sort]').removeClass('asc').removeClass('desc')
  },
  hideFilterIcon: function ($th) {
    $th.find('[data-icon=filter]').removeClass('filter')
  },
  hideAllFilterIcons: function () {
    this.$('[data-icon=filter]').removeClass('filter')
  },
  getRelativeX: function ($th, pageX) {
    return pageX - this.$el.offset().left
  },
  getRelativeY: function ($th, pageY) {
    return pageY - this.$el.offset().top
  },
  showTableDrop: function (e) {
    var view = this
    var $th = $(e.currentTarget)
    var pageX = e.pageX
    var pageY = e.pageY
    var relativePageX = view.getRelativeX($th, pageX)
    var relativePageY = view.getRelativeY($th, pageY)
    var tableHeadOptions = view.findTableHeadOptions($th)

    if (tableHeadOptions.noDrop) {
      return
    }

    if ($th.parents('div.advanced-activity-log').is(':Visible')) {
      pageX = pageX - $th.parents('div.advanced-activity-log').offset().left
      pageY = pageY - $th.parents('div.advanced-activity-log').offset().top
    }

    if (view.getParentView().$el.hasClass('checklists') || view.getParentView().$el.hasClass('vehiclelist')) {
      pageX = pageX - view.getParentView().$el.find('.modal-layer-content').offset().left
      pageY = pageY - view.getParentView().$el.find('.modal-layer-content').offset().top
    }

    if (view.getParentView().$el.hasClass('ooi-list-popup')) {
      pageX = pageX - view.getParentView().$el.offset().left
      pageY = pageY - view.getParentView().$el.offset().top
    }
    // if($th.parents('div.advanced-loneworker-activity-log').is(':Visible')) {
    //     pageX = pageX - $th.parents('div.advanced-loneworker-activity-log').offset().left
    //     pageY = pageY - $th.parents('div.advanced-loneworker-activity-log').offset().top
    // }

    view.findSubView('tableDrop').render(tableHeadOptions)
    view.findSubView('tableDrop').show(pageX, pageY, relativePageX, relativePageY)
    view.addClassActiveTh($th)
  },
  findTableHeadOptions: function ($th) {
    return this.model.get('tableHead')[$th.index()]
  },
  sort: function (e) {
    var view = this
    var $li = $(e.currentTarget)
    var $th = view.$('th.active')
    var thIndex = $th.index()
    var $table = view.$('[data-table-grid=journey]:has(th.active)')
    var $tr = $table.find('tr')
    var order = $li.attr('data-sort-order')

    $table.append(view.sort[order]($tr, thIndex))
    view.$('[data-drop=table]').hide()
    $th.siblings().find('.table-icon').removeClass('asc').removeClass('desc')
    $th.removeClass('active').find('.table-icon').removeClass('asc').removeClass('desc').addClass(order)
  },
  sortAsc: function (field, collection) {
    return _.sortByNat(collection, field)
  },
  sortDesc: function (field, collection) {
    return _.sortByNat(collection, field).reverse()
  },
  filter: function (filterData, collection) {
    var view = this
    var filterDataLen = _.size(filterData)

    function iteratee (element) {
      collection = _.filter(collection, function (model) {
        if (typeof model[element.field] !== 'boolean') {
          return view[element.filterFnName]('' + model[element.field], element.value)
        } else {
          return view[element.filterFnName](model[element.field], element.value)
        }
      })
    }
    if (filterDataLen >= 1) {
      _.each(filterData, function (value) {
        _.each(value, iteratee)
      })
    } else {
      throw new Error('filterData is empty')
    }
    return collection
  },
  greaterThan: function (fieldValue, inputValue) {
    return fieldValue > inputValue
  },
  lessThan: function (fieldValue, inputValue) {
    return fieldValue < inputValue
  },
  startsWith: function (fieldValue, inputValue) {
    var regExp = new RegExp('^' + inputValue, 'gi')

    return (regExp).test(fieldValue.toLowerCase())
  },
  endsWith: function (fieldValue, inputValue) {
    var regExp = new RegExp('' + inputValue.toLowerCase())

    return (regExp).test(fieldValue.toLowerCase())
  },
  equal: function (fieldValue, inputValue) {
    var regExp = new RegExp('^' + inputValue, 'gi')

    return fieldValue === inputValue
  },
  updateTableHeadColumnIcon: function (sortData, filterData) {
    var view = this
    var index = view.findIndexBySortField(sortData)
    var $th = view.$('th')

    if (index >= 0) {
      view.showSortIcon($th.eq(index), sortData.sortOrder)
    }
    _.each(_.keys(filterData), function (thIndex) {
      view.showFilterIcon($th.eq(thIndex))
    })
  },
  findIndexBySortField: function (sortData) {
    return _.findIndex(this.model.get('tableHead'), { field: sortData.field })
  },
  checkGroupColumn: function () {
    var view = this
    var groups = view.model.get('tableRow').pluck('group')

    groups = _.compact(groups)

    if (!groups.length) return

    if (groups.length > 1 && _.uniq(groups).length === 1) {
      view.templateOptions.hasGroup = false
      view.setGroupNoRender(true)
    } else {
      view.templateOptions.hasGroup = true
      view.setGroupNoRender(false)
    }
  },
  checkSubGroupColumn: function () {
    var view = this
    var subGroups = view.model.get('tableRow').pluck('subGroup')

    subGroups = _.compact(subGroups)

    if (subGroups.length === 0) {
      view.templateOptions.hasSubGroup = false
      view.setSubGroupNoRender(false)
    } else if (subGroups.length > 1 && _.uniq(subGroups).length === 1) {
      view.templateOptions.hasSubGroup = false
      view.setSubGroupNoRender(true)
    } else {
      view.templateOptions.hasSubGroup = true
      view.setSubGroupNoRender(false)
    }
  },
  setGroupNoRender: function (boolean) {
    this.model.get('tableHead')[1].noRender = boolean
  },
  setSubGroupNoRender: function (boolean) {
    this.model.get('tableHead')[2].noRender = boolean
  },
  calculateColumnWidth: function () {
    var view = this
    var columnWidth = _.clone(view.model.get('columnWidth'))
    var columnWidthLength = columnWidth.length
    var $th = view.$('th:not(.complex-header)')
    var $td = view.$('tbody tr:first td')
    var $thHidden = view.$('th:hidden')
    var counter = 0
    var sum = 0

    $thHidden.each(function () {
      var index = $(this).index()

      sum += columnWidth[index]
    })
    for (var i = sum; i > 0;) {
      var $currentTh
      var $currentTd

      if (counter > columnWidthLength - 1) {
        counter = 0
      }
      $currentTh = $th.eq(counter)
      $currentTd = $td.eq(counter)
      if ($currentTh.is(':visible')) {
        columnWidth[$th.index($currentTh)] += 1
        $currentTh.add($currentTd).css('width', (columnWidth[$th.index($currentTh)]) + '%')
        i--
      }
      counter++
    }
  },
  renderTableBody: function (html) {
    var view = this

    view.$el.find('tbody').html($(html).find('tbody').html())
  },
  renderRow: function (model) {
    var view = this
    var id = model.get('id')
    var node = this.somaTemplate.getNode(this.$el.find('tr[data-id=' + id + ']')[0])
    node.scope.item = model.toJSON()
    node.update()
    node.render()
    return this
  },
  renderEmptyRows: function (collection) {
    var view = this
    var maxEmptyRows = 0
    var rowCount = collection.length || 0
    var emptyRowArray = []

    view.emptyRow = '<tr class="empty-row">'

    for (var i = 0; i < view.$('th').size(); i++) {
      view.emptyRow += '<td></td>'
    }

    view.emptyRow += '</tr>'

    view.$el.find('.empty-row').remove()
    if (rowCount < 10) {
      maxEmptyRows = 10 - rowCount
      for (i = 0; i < maxEmptyRows; i++) {
        emptyRowArray.push(view.emptyRow)
      }
      view.$el.find('tbody:last').append(emptyRowArray.join(''))
    }
    return this
  },
  setActiveRow: function (e) {
    var $tr = $(e.currentTarget)
    var multiSelect = this.model.get('multiSelect')
    var activeId

    if (!multiSelect) {
      activeId = $tr.siblings().andSelf().filter('tr.active').attr('data-id')
    } else if ($tr.hasClass('active')) {
      activeId = $tr.attr('data-id')
    }
    if ($tr.hasClass('active')) {
      $tr.removeClass('active')
    } else {
      if (!multiSelect) {
        $tr.addClass('active').siblings().removeClass('active')
      } else {
        $tr.addClass('active')
      }
    }
    this.setSelectedItem($tr, activeId)
  },
  setSelectedItem: function ($tr, activeId) {
    var id = $tr.attr('data-id')

    if (activeId in this.selectedItem) {
      delete this.selectedItem[activeId]
    }
    if (activeId !== id) {
      this.selectedItem[id] = this.model.get('tableRow').get(id)
    }
    this.trigger('itemSelect', $tr, id)
  },
  getSelectedItem: function () {
    return this.selectedItem
  },
  fetchSelectedItemModel: function (callback) {
    this.collection.get(_.keys(this.selectedItem)[0]).fetch({ success: callback })
  },
  fetchCollection: function (option) {
    return this.collection.fetch(option)
  },
  getActiveRowId: function () {
    return this.$el.find('tr[data-id=' + _.first(_.keys(this.selectedItem)) + ']').attr('data-id')
  },
  findActiveTh: function () {
    return this.$('th.active')
  },
  hasSelectedItem: function () {
    return !!_.size(this.selectedItem)
  },
  resetSelectedItemAndRow: function () {
    this.selectedItem = {}
    this.$el.find('tr.active').removeClass('active')
  },
  destroy: function () {
    var view = this

    $(document).off('click.tableDrop')
    view.findSubView('scroll').destroy()
    view.remove()
  }
})

export default TableGridView
