import AoiModel from '../../model/Aoi'
import Collection from '../Collection'

const AlertAvailableAoiList = Collection.extend({
  model: AoiModel,
  id: null,
  url: function () {
    return 'services/geofence/alert-available-aoi/' + (this.id ? this.id : '')
  }
})

export default AlertAvailableAoiList
