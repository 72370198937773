import _ from 'underscore'
import CompactReportTableGridView from './CompactReportTableGrid'
import CompactReportComplexHeaderTableDropView from './CompactReportComplexHeaderTableDrop'

const CompactReportComplexHeaderTableGridView = CompactReportTableGridView.extend({
  constructor: function () {
    this.setSubView({
      tableDrop: CompactReportComplexHeaderTableDropView
    })
    CompactReportTableGridView.apply(this, arguments)
  },
  findTableHeadOptions: function ($th) { // override
    var trIndex = $th.closest('tr').index()

    return this.model.get('tableHead')[trIndex][$th.index()]
  },
  setGroupNoRender: function (boolean) {
    this.model.get('tableHead')[0][1]['noRender'] = boolean
  },
  setSubGroupNoRender: function (boolean) {
    this.model.get('tableHead')[0][2]['noRender'] = boolean
  },
  findIndexBySortField: function (sortData) { // override
    var index = _.findIndex(this.model.get('tableHead')[0], { field: sortData.field })

    if (index < 0) {
      index = _.findIndex(this.model.get('tableHead')[1], { field: sortData.field })
      if (index >= 0) {
        index += this.model.get('tableHead')[0].length
      }
    }

    return index
  }
})

export default CompactReportComplexHeaderTableGridView
