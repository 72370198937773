import TabsetView from '../../Tabset'

const ProfileBuilderTabsetView = TabsetView.extend({
    constructor: function (option) {
        var self = this;
        var args = arguments;

        this.loadDotTemplate(function () {
            self.compileTemplate();
            self.$el = $(self.dotTemplateToHtml({}));
            self.el = self.$el[0];
            TabsetView.apply(self, [ {el: self.el}]);
        });
    },
    initialize: function () {
        this.render();
    },
    render: function () {
        this.getParentView().$el.find('.tabset-container').append(this.$el);
    },
    destroy: function () {
        this.remove();
    }
});
export default ProfileBuilderTabsetView