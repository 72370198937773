import $ from 'jquery'
import CompactReportPageView from '../CompactReportPage'
import TodaysJourneyReportView from './TodaysJourney'

const TodaysJourneyPageView = CompactReportPageView.extend({
  el: '<div class="report-page" data-report-page="todays-journeys"></div>',
  initialize: function () {
    var view = this

    view.addSubView('compactReport', TodaysJourneyReportView)
    view.render()
  },
  render: function () {
    window.reportPageIsActive = true

    $('.map-wrap').append(this.el)

    return this
  }
})

export default TodaysJourneyPageView
