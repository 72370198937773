import TimeOnSitePopupView from './TimeOnSitePopup'
import TimeOnSiteSelectionMenuView from './TimeOnSiteSelectionMenu'
import ReportPageView from '../ReportPage'
import TimeOnSiteView from './TimeOnSite'

const TimeOnSitePageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="timeOnSite"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', TimeOnSitePopupView)
    view.addSubView('selectionMenu', TimeOnSiteSelectionMenuView)
    view.addSubView('report', TimeOnSiteView)
    view.render()
  }
})

export default TimeOnSitePageView
