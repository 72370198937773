import TableGridModel from '../../TableGrid@'
import FuelTankLevelVarianceList from '../../../collection/report/fuelTankLevelVarianceReport/FuelTankLevelVarianceList'

const FuelTankLevelVarianceReportTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: false,
    columnWidth: [7, 6, 7, 13, 7, 13, 6, 6, 13, 6, 6, 5, 5],
    tableHead: [
      [
        {
          tableHeadName: 'Veh. Reg.',
          field: 'vehReg',
          rowspan: 2
        },
        {
          tableHeadName: 'Group',
          field: 'group',
          rowspan: 2
        },
        {
          tableHeadName: 'Sub-Group',
          field: 'subGroup',
          rowspan: 2
        },
        {
          tableHeadName: 'Location',
          field: 'address',
          rowspan: 2
        },
        {
          tableHeadName: 'Postcode',
          field: 'postcode',
          rowspan: 2
        },
        {
          tableHeadName: 'Ignition Off',
          noDrop: true,
          colspan: 3
        },
        {
          tableHeadName: 'Ignition On',
          noDrop: true,
          colspan: 3
        },
        {
          tableHeadName: 'Variance',
          noDrop: true,
          colspan: 2
        }
      ],
      [
        {
          tableHeadName: 'Date | Time',
          field: 'fuelLevelEngineOffDate',
          tableDropOption: [
            {
              optionName: 'Sort Ascending',
              dataButton: 'sortAsc',
              iconClass: 'asc'

            },
            {
              optionName: 'Sort Descending',
              dataButton: 'sortDesc',
              iconClass: 'desc'
            }
          ]
        },
        {
          tableHeadName: 'Litres',
          field: 'fuelLevelEngineOffLitres',
          tableDropOption: [
            {
              optionName: 'Sort Ascending',
              dataButton: 'sortAsc',
              iconClass: 'asc'

            },
            {
              optionName: 'Sort Descending',
              dataButton: 'sortDesc',
              iconClass: 'desc'
            },
            {
              optionName: 'Filter',
              iconClass: 'filter',
              subDrop: {
                dataLeftButtonValue: 'filter',
                dataRightButtonValue: 'clearFilter',
                leftButtonText: 'Filter',
                rightButtonText: 'Clear',
                subDropSection: [
                  {
                    title: 'Litres:',
                    filterByField: 'fuelLevelEngineOffLitres',
                    select: {
                      name: 'filter',
                      options: [
                        {
                          option: 'Greater Than',
                          value: 'greaterThan'
                        },
                        {
                          option: 'Less Than',
                          value: 'lessThan'
                        }
                      ]
                    },
                    input: {
                      placeholder: 'Enter value',
                      name: 'value',
                      dataType: 'float'
                    }
                  }
                ]
              }
            }
          ]
        },
        {
          tableHeadName: 'Tank %',
          field: 'fuelLevelEngineOffTankPercent',
          tableDropOption: [
            {
              optionName: 'Sort Ascending',
              dataButton: 'sortAsc',
              iconClass: 'asc'

            },
            {
              optionName: 'Sort Descending',
              dataButton: 'sortDesc',
              iconClass: 'desc'
            },
            {
              optionName: 'Filter',
              iconClass: 'filter',
              subDrop: {
                dataLeftButtonValue: 'filter',
                dataRightButtonValue: 'clearFilter',
                leftButtonText: 'Filter',
                rightButtonText: 'Clear',
                subDropSection: [
                  {
                    title: 'Litres:',
                    filterByField: 'fuelLevelEngineOffTankPercent',
                    select: {
                      name: 'filter',
                      options: [
                        {
                          option: 'Greater Than',
                          value: 'greaterThan'
                        },
                        {
                          option: 'Less Than',
                          value: 'lessThan'
                        }
                      ]
                    },
                    input: {
                      placeholder: 'Enter value',
                      name: 'value',
                      dataType: 'float'
                    }
                  }
                ]
              }
            }
          ]
        },
        {
          tableHeadName: 'Date | Time',
          field: 'fuelLevelEngineOnDate',
          tableDropOption: [
            {
              optionName: 'Sort Ascending',
              dataButton: 'sortAsc',
              iconClass: 'asc'

            },
            {
              optionName: 'Sort Descending',
              dataButton: 'sortDesc',
              iconClass: 'desc'
            }
          ]
        },
        {
          tableHeadName: 'Litres',
          field: 'fuelLevelEngineOnLitres',
          tableDropOption: [
            {
              optionName: 'Sort Ascending',
              dataButton: 'sortAsc',
              iconClass: 'asc'

            },
            {
              optionName: 'Sort Descending',
              dataButton: 'sortDesc',
              iconClass: 'desc'
            },
            {
              optionName: 'Filter',
              iconClass: 'filter',
              subDrop: {
                dataLeftButtonValue: 'filter',
                dataRightButtonValue: 'clearFilter',
                leftButtonText: 'Filter',
                rightButtonText: 'Clear',
                subDropSection: [
                  {
                    title: 'Litres:',
                    filterByField: 'fuelLevelEngineOffLitres',
                    select: {
                      name: 'filter',
                      options: [
                        {
                          option: 'Greater Than',
                          value: 'greaterThan'
                        },
                        {
                          option: 'Less Than',
                          value: 'lessThan'
                        }
                      ]
                    },
                    input: {
                      placeholder: 'Enter value',
                      name: 'value',
                      dataType: 'float'
                    }
                  }
                ]
              }
            }
          ]
        },
        {
          tableHeadName: 'Tank %',
          field: 'fuelLevelEngineOnTankPercent',
          tableDropOption: [
            {
              optionName: 'Sort Ascending',
              dataButton: 'sortAsc',
              iconClass: 'asc'

            },
            {
              optionName: 'Sort Descending',
              dataButton: 'sortDesc',
              iconClass: 'desc'
            },
            {
              optionName: 'Filter',
              iconClass: 'filter',
              subDrop: {
                dataLeftButtonValue: 'filter',
                dataRightButtonValue: 'clearFilter',
                leftButtonText: 'Filter',
                rightButtonText: 'Clear',
                subDropSection: [
                  {
                    title: 'Litres:',
                    filterByField: 'fuelLevelEngineOnTankPercent',
                    select: {
                      name: 'filter',
                      options: [
                        {
                          option: 'Greater Than',
                          value: 'greaterThan'
                        },
                        {
                          option: 'Less Than',
                          value: 'lessThan'
                        }
                      ]
                    },
                    input: {
                      placeholder: 'Enter value',
                      name: 'value',
                      dataType: 'float'
                    }
                  }
                ]
              }
            }
          ]
        },
        {
          tableHeadName: 'Litres',
          field: 'fuelLevelVarianceLitres',
          tableDropOption: [
            {
              optionName: 'Sort Ascending',
              iconClass: 'asc',
              dataButton: 'sortAsc'
            },
            {
              optionName: 'Sort Descending',
              iconClass: 'desc',
              dataButton: 'sortDesc'
            },
            {
              optionName: 'Filter',
              iconClass: 'filter',
              subDrop: {
                dataLeftButtonValue: 'filter',
                dataRightButtonValue: 'clearFilter',
                leftButtonText: 'Filter',
                rightButtonText: 'Clear',
                subDropSection: [
                  {
                    title: 'Litres:',
                    filterByField: 'fuelLevelVarianceLitres',
                    select: {
                      name: 'filter',
                      options: [
                        {
                          option: 'Greater Than',
                          value: 'greaterThan'
                        },
                        {
                          option: 'Less Than',
                          value: 'lessThan'
                        }
                      ]
                    },
                    input: {
                      placeholder: 'Enter Value',
                      name: 'value',
                      dataType: 'float'
                    }
                  }
                ]
              }
            }
          ]
        },
        {
          tableHeadName: 'Tank %',
          field: 'fuelLevelVarianceTankPercent',
          tableDropOption: [
            {
              optionName: 'Sort Ascending',
              dataButton: 'sortAsc',
              iconClass: 'asc'

            },
            {
              optionName: 'Sort Descending',
              dataButton: 'sortDesc',
              iconClass: 'desc'
            },
            {
              optionName: 'Filter',
              iconClass: 'filter',
              subDrop: {
                dataLeftButtonValue: 'filter',
                dataRightButtonValue: 'clearFilter',
                leftButtonText: 'Filter',
                rightButtonText: 'Clear',
                subDropSection: [
                  {
                    title: 'Litres:',
                    filterByField: 'fuelLevelVarianceTankPercent',
                    select: {
                      name: 'filter',
                      options: [
                        {
                          option: 'Greater Than',
                          value: 'greaterThan'
                        },
                        {
                          option: 'Less Than',
                          value: 'lessThan'
                        }
                      ]
                    },
                    input: {
                      placeholder: 'Enter value',
                      name: 'value',
                      dataType: 'float'
                    }
                  }
                ]
              }
            }
          ]
        }
      ]
    ],
    tableRow: FuelTankLevelVarianceList
  }
})

export default FuelTankLevelVarianceReportTableGridModel
