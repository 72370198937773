import TableGridView from '../../view/TableGrid@'
import CompactReportTableDropView from './CompactReportTableDrop'
import CompactReportTableGridScrollView from './CompactReportTableGridScroll'

const CompactReportTableGridView = TableGridView.extend({
  mergeEvents: true,
  events: {
    'click tbody tr:not(.empty-row)': ''
  },
  constructor: function (option) {
    var view = this

    view.setSubView({
      tableDrop: CompactReportTableDropView,
      scroll: CompactReportTableGridScrollView
    })

    TableGridView.apply(view, arguments)
  }
})

export default CompactReportTableGridView
