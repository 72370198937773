import $ from 'jquery'
import PopupView from '../Popup'
import EmergencyPhoneNumberView from './EmergencyPhoneNumber'

const EmergencyPhoneNumberPopupView = PopupView.extend({
  url: 'js/html/app/src/template/phoneNumber/emergencyPhoneNumber.html',
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'closeHandler',
    'click [data-button=save]': 'submit'
  },

  hideActivePopup: function () {
    var view = this

    if (view.isVisible()) {
      view.hide()
    }
  },
  initialize: function (el, tel, id) {
    var view = this

    window.phoneEditNumberId = parseInt(id, 10)

    view.addSubView('emergencyPhoneNumber', EmergencyPhoneNumberView)

    view.findSubView('emergencyPhoneNumber').updateModel(tel)
    $('#newPhoneNumber').val('')
    view.show()
    view.alignBy(el, 'topRight', { top: 6, left: 6 })
  },
  closeBtnHandler: function () { // override
    this.destroy()
  },

  destroy: function () {
    this.findSubView('emergencyPhoneNumber').destroy()
    this.remove()
  },
  submit: function () {
    this.findSubView('emergencyPhoneNumber').submit()
  }

})

export default EmergencyPhoneNumberPopupView
