import ReportPopupView from '../ReportPopup'
import JourneyReportFormView from './JourneyReportForm'

const JourneyReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/Journey.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', JourneyReportFormView)
  }
})

export default JourneyReportPopupView
