import CompactReportTableGridView from '../report/CompactReportTableGrid'
import ActivityLogTableGridModel from '../../model/modalPanel/ActivityLogTableGrid'
import ConfirmVideoRequestPopupView from '../camera/ConfirmVideoRequestPopup'
import VideoPlayerPopupView from '../camera/VideoPlayerPopup'
import MultiCameraPopupView from '../multiCamera/MultiCameraPopup'
import $ from 'jquery'
import _ from 'underscore'

var $tooltip = $('<div class="groups-tooltip" style="display:block;visibility:hidden"></div>')

$('#content').append($tooltip)

const ActivityLogTableGridView = CompactReportTableGridView.extend({
  model: ActivityLogTableGridModel,
  templateUrl: 'template/modalPanel/activityLogTableGrid.html',
  events: {
    'click tbody tr': 'plotLog',
    'click .drop-poi-btn': 'addNewPoi',
    'contextmenu tbody tr>td.poi-address': 'goToPOI',
    'click table': 'outFromPOI',
    'click .camera-ico.video-download': 'confirmVideoRequest',
    'click .camera-ico.video-uploaded': 'showVideoPlayerPopup',
    'mouseover .camera-ico': 'renderTooltip',
    'mouseout .camera-ico': 'hideTooltip'
  },
  initialize: function () {
    this.logsOnMap = []
    this.addSubView('confirmVideoRequestPopup', ConfirmVideoRequestPopupView)
    this.addSubView('videoPlayerPopup', VideoPlayerPopupView)
    this.addSubView('multiCameraPopup', MultiCameraPopupView)
  },
  plotLog: function (e) {
    var $tr = $(e.currentTarget)
    var $ico = $(e.target)
    var view = this
    var modelJson = this.model.toJSON()

    if ($ico.hasClass('camera-ico')) return

    if (modelJson) {
      var activityLogs = modelJson.tableRow
      var curLog = activityLogs[$tr.index()]
      $tr.toggleClass('active')
      if (this.logsSize() === 0) {
        var size = activityLogs.length
        for (var i = 0; i < size; i++) {
          this.addLog({}, i)
        }
      }

      if (!this.logPutOnMap(curLog)) {
        this.addLog(curLog, $tr.index())
        view.$('input:checkbox:checked').prop('checked', false)
      } else {
        var index = this.getLogIndex(curLog)
        this.removeLog(index)
      }

      if (this.logsSize() >= 0) {
        this.removeSnailTrailFromMap()
      }

      if (this.logsSize() === 0) {
        window.wls.loadState()
      } else {
        this.showLogs()
      }

      if (view.getParentView().plotModeOn) {
        if (this.logsSize() !== this.rowsSize()) {
          view.getParentView().$('.plot-all input:checkbox:checked').prop('checked', false)
          view.getParentView().plotModeOn = false
        }
      }
    }
  },
  goToPOI: function (e) {
    e.preventDefault()

    if ($('.table-drop').is(':Visible')) {
      this.findSubView('tableDrop').hide()
    }

    var $el = $('#addPoiMenu')
    var _$el = $(e.currentTarget)
    var $curr = _$el.parent('tr').attr('data-id')
    var pageX = _$el.position().left + _$el.width() - $el.width() + 10
    var pageY = _$el.position().top + (_$el.outerHeight() / 2) - ($el.height() / 2) - 1

    this.getPOIcoordinates(window.toPoiCollection, $curr)

    $el.css({ left: pageX, top: pageY })
    $el.delay(100).show()
  },

  outFromPOI: function () {
    var $el = $('#addPoiMenu')

    $el.hide()
  },

  addNewPoi: function () {
    var $report = $('.advanced-activity-log')

    $report.hide()

    this.outFromPOI()

    window.AddPOI.goToPOIAOI('fromActivityLog')
  },

  getPOIcoordinates: function (arr, el) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].id === parseInt(el, 10)) {
        window.AddPOI.lat = arr[i].lat
        window.AddPOI.lon = arr[i].lon
      }
    }
    if (window.AddPOI.lat === 0 || window.AddPOI.lon === 0) {
      window.AddPOI.lat = 51.507351
      window.AddPOI.lon = -0.127758
    }
  },

  plotAll: function () {
    var modelJson = this.model.toJSON()
    if (modelJson && modelJson.tableRow) {
      var data = modelJson.tableRow
      var log
      for (var i = 0; i < data.length; i++) {
        log = data[i]
        this.addLog(log, i)
      }
      this.showLogs()
    }
  },

  markAllActive: function (active) {
    var view = this
    if (active) {
      view.$el.find('tr:not(.empty-row)').addClass('active')
    } else {
      view.$el.find('tr').removeClass('active')
    }
  },

  showSnailTrailOnMap: function (data) {
    if (data.length && data.length > 0) {
      const dataLen = data.length
      let directionIconUrl
      let ignitionIconUrl
      let vehicleIconUrl
      let currData

      for (let i = 0; i < dataLen; i++) {
        currData = data[i]
        if (data[i].id && data[i].lon !== 0 && data[i].lat !== 0) {
          if (currData.directionIconUrl.includes("arrows_flag_start") ||
              currData.directionIconUrl.includes("arrows_flag_end")) {
            directionIconUrl = "../" + currData.directionIconUrl
          } else {
            if (window.settings.data.nextGenArrowsAllowed) {
              directionIconUrl = "../../img/nextGenArrows/" + currData.directionCode.toUpperCase() + "_blue.png"
            } else {
              directionIconUrl = "../" + currData.directionIconUrl
            }
          }

          ignitionIconUrl = '../' + currData.ignitionIconUrl
          vehicleIconUrl = '../' + currData.vehicleIconUrl

          window.mapCanvas.addActivityMarker(
            currData.id,
            directionIconUrl,
            ignitionIconUrl,
            vehicleIconUrl,
            currData.recDate,
            currData.lat,
            currData.lon,
            function (shape) {
              shape.logIndex = i
              shape.mapIndex = i
              window.mapCanvas.addToShapeLayer(shape)
              window.onMap = true
            },
            currData.streetName,
            currData.postcode,
            currData.directionCode,
            currData.ignitionStr === 'ON',
            currData.factoredSpeed,
            currData.ignitionStr,
            currData.registrationNumber,
            currData.driverName)
        }
      }
      if (window.settings.data.nextGenArrowsAllowed) {
        if (window.wlsMapTools.traceButtonActive()) {
          window.mapCanvas.enableClusteringTraces()
        }
        window.mapCanvas.subscribeZoomChangedListener(this)
      }
      window.rezoomMapFlx()
    } else {
      let img
      window.mapCanvas.addActivityMarker(data.id, data.directionIconUrl, data.ignitionIconUrl, img, data.recDate, data.lat, data.lon, function (shape) {
        shape.logIndex = data.index
        shape.mapIndex = data.index
        window.mapCanvas.addToShapeLayer(shape)
        window.rezoomMapFlx()
        window.onMap = true
      })
    }
  },

  removeSnailTrailFromMap: function () {
    if (window.mapCanvas) {
      window.mapCanvas.cleanUp();
      window.mapCanvas.cleanUp('imgMarker');
      window.mapCanvas.cleanUp('snappedPolyline');
      window.mapCanvas.unsubscribeStreetViewVisibleChangedListener(this);
      if (window.settings.data.nextGenArrowsAllowed) {
        window.mapCanvas.unsubscribeZoomChangedListener(this);
        // window.mapCanvas.cleanUp('snailTrailPolyline');
        window.mapCanvas.removeAllProximityLines();
        window.mapCanvas.disableClusteringEvents();
        window.mapCanvas.cleanUpClustering();
      }
    } else {
      window.map.cleanUp()
    }
    window.onMap = false
  },

  logPutOnMap: function (log) {
    if (log) {
      for (var i = 0; i < this.logsOnMap.length; i++) {
        var item = this.logsOnMap[i]
        if (item.id === log.id) {
          return true
        }
      }
      return false
    } else {
      return false
    }
  },

  zoomChanged: function (currentZoomLevel) {
    if (window.settings.data.nextGenArrowsAllowed) {
      const markerType = 'imgMarker'
      switch (currentZoomLevel) {
        case 21: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.000005, 0.000000)
          break
        }
        case 20: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.00001, -0.000000)
          break
        }
        case 19: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.00002, -0.000004)
          break
        }
        case 18: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.00004, -0.000004)
          break
        }
        case 17: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.00008, -0.000008)
          break
        }
        case 16: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.0002, -0.00001)
          break
        }
        case 15: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.0004, -0.00006)
          break
        }
        case 14: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.0008, 0)
          break
        }
        case 13: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.0013, 0.0001)
          break
        }
        case 12: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.003, 0)
          break
        }
        case 11: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.005, 0)
          break
        }
        case 10: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.002, 0)
          break
        }
      }
    }
  },

  addLog: function (log, index) {
    this.logsOnMap[index] = log
  },

  removeLog: function (index) {
    this.logsOnMap[index] = {}
  },

  getLogIndex: function (log) {
    var size = this.logsOnMap.length
    for (var i = 0; i < size; i++) {
      if (this.logsOnMap[i].id === log.id) {
        return i
      }
    }
    return -1
  },

  logsSize: function () {
    var count = 0
    var curLog
    if (this.logsOnMap) {
      for (var i = 0; i < this.logsOnMap.length; i++) {
        curLog = this.logsOnMap[i]
        if (curLog && curLog.id !== undefined) {
          count++
        }
      }
    }
    return count
  },

  rowsSize: function () {
    return this.logsOnMap.length
  },

  showLogs: function () {
    if (this.logsSize() === 0) {
      window.wls.loadState()
    } else {
      this.showSnailTrailOnMap(this.logsOnMap)
    }
  },
  confirmVideoRequest: function (e) {
    var videoPlayerPopupView = this.findSubView('videoPlayerPopup')
    var confirmVideoRequestPopupView = this.findSubView('confirmVideoRequestPopup')
    var activityLogId = $(e.target).closest('tr').data().id

    this.showPopup(confirmVideoRequestPopupView)
    videoPlayerPopupView.hide()
    confirmVideoRequestPopupView.renderForm(this.getActivityLog(activityLogId))
  },
  showVideoPlayerPopup: function (e) {
    var videoPlayerPopupView = this.findSubView('videoPlayerPopup')
    var multiCameraPopupView = this.findSubView('multiCameraPopup')
    var confirmVideoRequestPopupView = this.findSubView('confirmVideoRequestPopup')
    var activityLogId = $(e.target).closest('tr').data().id
    var activityLog = this.getActivityLog(activityLogId)

    if (confirmVideoRequestPopupView.$el.find('[data-value]:visible').size() === 0) {
      confirmVideoRequestPopupView.hide()
    }

    // this.showPopup(multiCameraPopupView)
    // multiCameraPopupView.setActivityLogId(activityLogId)
    // multiCameraPopupView.setVideoProps(activityLog)

    this.showPopup(multiCameraPopupView)
    multiCameraPopupView.setActivityLogId(activityLogId)
    multiCameraPopupView.setVideoProps(activityLog)
  },
  showPopup: function (PopupView) {
    var isPopupVisible = PopupView.isVisible()
    !isPopupVisible && PopupView.prepare()
    var top = this.$el.offset().top + ((this.$el.height() / 2) - (PopupView.$el.height() / 2))
    var left = this.$el.offset().left + ((this.$el.width() / 2) - (PopupView.$el.width() / 2))

    if (top < 0) top = 1
    if (left < 0) left = 1
    PopupView.moveTo(top, left)
    !isPopupVisible && PopupView.prepareDone()
    PopupView.show()
  },
  getActivityLog: function (activityLogId) {
    return this.model.attributes.tableRow.get(activityLogId).attributes
  },
  renderEmptyRows: function (collection) {
    CompactReportTableGridView.prototype.renderEmptyRows.apply(this, [collection])
    var $td = this.$el.find('tr.empty-row:first td')
    var $th = this.$el.find('th')

    _.each($th, function (th, index) {
      $td.eq(index).width($(th).width() + 5)
    })

    return this
  },
  render: function () {
    CompactReportTableGridView.prototype.render.apply(this, arguments)
    var $td = this.$el.find('tbody tr:first td')
    var $th = this.$el.find('th')

    _.each($th, function (th, index) {
      $td.eq(index).width($(th).width() + 5)
    })
  },
  findTableHeadOptions: function ($th) {
    var field = $th.attr('data-field')

    return _.find(this.model.get('tableHead'), function (item) {
      return item.field === field
    })
  },
  dotTemplateToHtml: function (json) {
    var vehicleId = this.parentView.findSubView('activityLogForm').model.attributes.unitId
    var vehicleData = window.wls.getVehicle(vehicleId).data

    this.templateOptions.cameraPresent = vehicleData.cameraPresent
    this.templateOptions.hasDin2 = !!vehicleData.digitalInput2Name

    if (!this.templateOptions.cameraPresent) {
      _.each(this.model.get('tableHead'), function (item) {
        if (item.tableHeadName === 'Camera Event' || item.tableHeadName === 'Video') {
          item.noRender = true
        }
      })
    } else {
      _.each(this.model.get('tableHead'), function (item) {
        if (item.tableHeadName === 'Camera Event' || item.tableHeadName === 'Video') {
          item.noRender = false
        }
      })
    }

    if (!this.templateOptions.hasDin2) {
      _.each(this.model.get('tableHead'), function (item) {
        if (item.tableHeadName === 'Crane' || item.tableHeadName.startsWith('\u2009')) {
          item.tableHeadName = '\u2009' + window.wls.getVehicle(window.wls.openerVehId).data.digitalInput2Name
          item.noRender = true
        }
      })
    } else {
      _.each(this.model.get('tableHead'), function (item) {
        if (item.tableHeadName === 'Crane' || item.tableHeadName.startsWith('\u2009')) { // this.wls.lastSelected[Object.keys(this.wls.lastSelected)[0]].data
          item.tableHeadName = '\u2009' + window.wls.getVehicle(window.wls.openerVehId).data.digitalInput2Name
          item.noRender = false
        }
      })
    }

    return this.dotTemplate(json, this.dot.helper, this.templateOptions)
  },
  renderTooltip: function (e) {
    var $cameraIco = $(e.target)

    if ($cameraIco.hasClass('no-video')) {
      $tooltip.text('Video is unavailable')
    }
    if ($cameraIco.hasClass('video-download')) {
      $tooltip.text('Video available to download')
    }
    if ($cameraIco.hasClass('video-uploading')) {
      $tooltip.text('Uploading to Cloud')
    }
    if ($cameraIco.hasClass('video-uploaded')) {
      $tooltip.text('Video uploaded to Cloud')
    }

    $tooltip.css({
      top: ($cameraIco.offset().top - $tooltip.outerHeight()) - 5,
      left: $cameraIco.offset().left - (($tooltip.outerWidth() - $cameraIco.width()) / 2),
      visibility: 'visible'
    })
  },
  hideTooltip: function () {
    $tooltip.css({
      top: -9999,
      left: -9999,
      visibility: 'hidden'
    })
  },
  showLoader: function () {
    this.$el.find('.loader-frame').show()
  },
  hideLoader: function () {
    this.$el.find('.loader-frame').hide()
  },
  filterAvailable: function (fieldValue) {
    return parseInt(fieldValue) === 0
  },
  filterNotAvailable: function (fieldValue) {
    return parseInt(fieldValue) === -1
  },
  filterUploading: function (fieldValue) {
    return parseInt(fieldValue) === 1
  },
  filterUploaded: function (fieldValue) {
    return parseInt(fieldValue) === 2
  },
  destroy: function () {
    this.findSubView('confirmVideoRequestPopup').remove()
    this.findSubView('videoPlayerPopup').remove()
    this.findSubView('multiCameraPopup').destroy()
    this.remove()
  }
})

export default ActivityLogTableGridView
