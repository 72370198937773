/**
 * Created by George on 22.07.2015.
 */
import ProfileBuilderFormView from '../profileManager/ProfileBuilderForm';
import DriverBehaviorProfileBuilderFormModel from '../../../model/alert/driverBehavior/DriverBehaviorProfileBuilderForm';
import DriverBehaviorProfileBuilderTabsetView from './DriverBehaviorProfileBuilderTabset';

const DriverBehaviorProfileBuilderForm = ProfileBuilderFormView.extend({
    model: DriverBehaviorProfileBuilderFormModel,
    ProfileBuilderTabsetView: DriverBehaviorProfileBuilderTabsetView
});

export default DriverBehaviorProfileBuilderForm;
