import $ from 'jquery'
import PopupView from '../Popup'
import EditVehIconPopupModel from '../../model/editVehicle/EditVehIconPopup'
import VehicleIconView from './VehicleIcon'
import VehicleIconListView from './VehicleIconList'

const EditVehIconPopupView = PopupView.extend({
  model: EditVehIconPopupModel,
  url: 'js/html/app/src/template/editVehicle/editVehIconPopup.html',
  mergeEvents: true,
  events: {
    'click .button.cancel': 'closeBtnHandler',
    'click .button.select-icon': 'showSelectIconPopup',
    'click .save': 'saveIcon',
    'click [data-color]': 'changeColor',
    'click .vehicle-icon': 'setActiveIcon'
  },
  initialize: function () {
    var view = this
    var $editVehIcon = $('#editVehIcon')
    var vehicleIconView = view.addSubView('vehicleIcon', VehicleIconView, { el: view.$('[data-veh-icon]')[0] })
    var vehicleIconList = view.addSubView('vehicleIconList', VehicleIconListView, { el: this.$('[data-list=vehicle-icon-list]') })
    this.vehId = null

    $editVehIcon.on('click', function () {
      var vehId = $editVehIcon.attr('data-veh-id')
      var $vehicleData = $('.row[data-id=' + vehId + ']')
      var vehicle = window.wls.getVehicle(vehId)
      var vehReg = vehicle.data.registrationNumber

      if (view.isVisible() && view.vehId === vehId) {
        return
      } else {
        view.disableSaveButton()
      }

      view.alignCenter()
      view.$('.header-panel .info-for').text(vehReg)
      vehicleIconView.model.set('imageFileName', vehicle.data.imageFileName)
      vehicleIconView.model.set('id', vehId)
      view.vehId = vehId
      view.show()
    })

    $(document).on('click.editVehIcon', function (e) {
      var target = $(e.target)

      if (!target.is('#content') && !target.closest('.select-veh-icon').size() && !target.closest('.vehicle-holder').size() && !target.closest('td.active[data-view="fleet"]').size() && !target.closest('.control-panel').size() && !target.closest('.map-tools').size() && !target.closest('#wlsmap').size() && !target.closest('.options').size() && !target.closest('#editVehIcon').size() && !target.closest('.edit-veh-icon').size() && view.isVisible()) {
        view.hide()
      }
    })
    $(window).resize($.proxy(this.resizeHandler, this))
  },
  show: function () {
    var view = this
    var vehicleIconList = this.findSubView('vehicleIconList')
    var vehicleIcon = this.findSubView('vehicleIcon')
    var color = vehicleIcon.model.color()

    this.setActiveColor(color)
    vehicleIconList.fetchList({
      data: { color: color },
      success: function () {
        vehicleIconList.fetchListSuccess()

        var $icon = view.findActiveIcon()

        if ($icon) {
          view.setActiveIcon({ currentTarget: $icon[0] })
        }

        view.resizeHandler(function () {
          view.scrollToIcon($icon)
        })
      }
    })
    PopupView.prototype.show.apply(this, arguments)
  },
  setActiveColor: function (color) {
    this.$('[data-color=' + color + ']').addClass('active').siblings().removeClass('active')
  },
  setActiveIcon: function (e) {
    var $icon = $(e.currentTarget)
    var $selectedIcon = $(e.currentTarget).closest('.vehicle-icon').addClass('active')
    var selectedIconImageFileName = this.findSubView('vehicleIconList').collection.at($selectedIcon.attr('data-index')).get('imageFileName')

    $selectedIcon.siblings().removeClass('active')
    this.findSubView('vehicleIcon').model.set('imageFileName', selectedIconImageFileName.replace('_E_100.png', ''))
    this.enableSaveButton()
  },
  changeColor: function (e) {
    var view = this
    var color = $(e.currentTarget).closest('[data-color]').attr('data-color')
    var vehicleIconList = view.findSubView('vehicleIconList')

    view.setActiveColor(color)
    vehicleIconList.fetchList({
      data: { color: color },
      success: function () {
        vehicleIconList.fetchListSuccess()

        var $icon = view.findActiveIcon()

        if ($icon) {
          view.setActiveIcon({ currentTarget: $icon[0] })
        }

        view.resizeHandler(function () {
          view.scrollToIcon($icon)
        })
      }
    })
  },
  findActiveIcon: function () {
    var view = this
    var vehicleIconList = view.findSubView('vehicleIconList')
    var vehicleIcon = view.findSubView('vehicleIcon')
    var iconIndex = null
    var $icon = null

    vehicleIconList.collection.find(function (model, i) {
      if (model.get('imageFileName').replace('_E_100.png', '') === vehicleIcon.model.get('imageFileName')) {
        iconIndex = i
        return true
      }
    })

    if (iconIndex !== null) {
      $icon = view.$('.vehicle-icon[data-index=' + iconIndex + ']')
    }

    return $icon
  },
  scrollToIcon: function ($icon) {
    var view = this
    var vehicleIconList = view.findSubView('vehicleIconList')
    var top = 0
    var $scrollFrame = vehicleIconList.findSubView('scroll').$frame

    if ($icon) {
      top = $icon.position().top - 10 - parseInt($scrollFrame.find('.slidee').css('transform').split(',')[5])
      var maxTopPosition = view.$('.slidee').height() - view.$('.scroll-content').height()

      if (top > maxTopPosition) {
        top = maxTopPosition
      }

      $icon.click()
    }
    if (top) $scrollFrame.sly('slideTo', top)
  },
  alignCenter: function () {
    var $popup = this.$el
    $popup.css('top', ($(window).height() / 2) - ($popup.outerHeight() / 2))
    $popup.css('left', ($(window).width() / 2) - ($popup.outerWidth() / 2))
  },
  hide: function () {
    this.disableSaveButton()
    PopupView.prototype.hide.apply(this, arguments)
  },
  showSelectIconPopup: function () {
    var selectVehiclePopupView = this.findSubView('selectIconPopup')

    selectVehiclePopupView.show()
    selectVehiclePopupView.alignBy(this.$el, 'topRight', { left: 10 })
  },
  enableSaveButton: function () {
    this.$('.save').removeClass('disabled')
  },
  disableSaveButton: function () {
    this.$('.save').addClass('disabled')
  },
  saveIcon: function () {
    if (!this.$('.save').hasClass('disabled')) {
      this.findSubView('vehicleIcon').save()
      this.disableSaveButton()
      this.closeBtnHandler()
    }
  },
  resizeHandler: function (done) {
    if (this.isVisible()) {
      this.resize(done)
    }
  },
  resize: function (done) {
    var maxRowCount = 5
    var minRowCount = 2
    var paddingVertical = 20
    var $vehicleIcon = this.$('.vehicle-icon:first')
    var marginBottom = parseInt($vehicleIcon.css('margin-bottom'))
    var rowHeight = $vehicleIcon.outerHeight() + marginBottom
    var defaultScrollContentHeight = maxRowCount * rowHeight + marginBottom
    var $scrollContent = this.$el.find('.scroll-content')
    var offset = $(window).height() - defaultScrollContentHeight
    var rowCount = Math.round(offset / rowHeight)
    var scroll = this.findSubView('vehicleIconList').findSubView('scroll')

    if (rowCount > maxRowCount) {
      rowCount = maxRowCount
    }

    if (rowCount < minRowCount) {
      rowCount = minRowCount
    }

    $scrollContent.height(rowCount * rowHeight + marginBottom)
    this.alignCenter()
    scroll.render(done, true)
  }
})

export default EditVehIconPopupView
