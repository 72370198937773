import $ from 'jquery'
import FormView from '../Form'
import PreferencesModel from '../../model/modalPanel/Preferences'

const PreferencesView = FormView.extend({
  model: PreferencesModel,
  elSelector: '[data-form=preferences]',
  mergeEvents: true,
  initialize: function (option) {
    var view = this

    view.resetElement()
    view.on('save', function (model) {
      // userPrefs.distanceMetric = model.get('distanceMetric')
      // userPrefs.replaceDriverNameWithRegNum = model.get('replaceDriverNameWithRegNum')
      // userPrefs.vehicleIconSize = model.get('vehicleIconSize')
      window.settings.get(false)
    })
  },
  updatePreferences: function (userPrefs) {
    this.model = new PreferencesModel({
      distanceMetric: userPrefs.distanceMetric,
      replaceDriverNameWithRegNum: userPrefs.replaceDriverNameWithRegNum,
      vehicleIconSize: userPrefs.vehicleIconSize
    })
    this.render()
  },

  getPreferences: function () {
    var view = this
    $.get('/api/settings/preferences/').done(function (res) {
      view.updatePreferences(res)
    })
  },
  submit: function () {
    var view = this

    view.getParentView().hide()
    window.wlsMapTools.deactivatePreferences()

    FormView.prototype.submit.apply(this, arguments)
  },

  destroy: function () {
    var view = this
    view.remove()
  }

})

export default PreferencesView
