import CompactReportTableDropView from '../../view/report/CompactReportTableDrop'

const PlottableReportTableDropView = CompactReportTableDropView.extend({
  saveSort: function (sortData) {
    console.log('saveSort()')
    if (sortData !== this.sortData) {
      var tableGrid = this.getParentView()
      tableGrid.reverseLogOrder()
    }
    this.sortData = sortData
  },
  clearSortData: function () {
    console.log('clearSortData()')
    this.sortData = {}
  },
  saveFilter: function (filterData, $activeTh, index) {
    console.log('saveFilter()')
    var thIndex = index || $activeTh.index()

    this.filterData[thIndex] = filterData
  },
  clearFilterData: function ($activeTh, index) {
    console.log('clearFilterData()')
    var thIndex = index || $activeTh.index()

    if (this.filterData.hasOwnProperty(thIndex)) {
      delete this.filterData[thIndex]
    }
  }
})

export default PlottableReportTableDropView
