import Model from '../../Model'

const OutOfHoursSelectedVehiclesModel = Model.extend({
    url: function () {
        var id = this.get('id') || '';

        return '/services/out-of-hours/selected-vehicles/' + id
    }
})

export default OutOfHoursSelectedVehiclesModel
