import $ from 'jquery'
import View from '../../View'
import ModalReportView from '../ModalReport'
import DrivingTimeReportFormView from './DrivingTimeReportForm'
import DrivingTimeReportTableGridView from './DrivingTimeReportTableGrid'
import DrivingTimeSettingsPopupView from './DrivingTimeSettingsPopup'

const DrivingTimeReportView = ModalReportView.extend({
  templateUrl: 'template/report/drivingTimeReport/drivingTimeReport.html',
  events: {
    'click [data-button=edit-driving-time]': 'editDrivingTime'
  },
  initialize: function (option) {
    console.log('initialize()')
    var view = this

    view.addSubView('compactReportTableGrid', DrivingTimeReportTableGridView, this.$('[data-table-grid=driving-time]')).once('ready', function () {
      view.addSubView('compactReportForm', DrivingTimeReportFormView)
    })

    if (window.settings.data.drivingTimeIdlingSecondsAllowed) {
      $('#editDrivingTimeButton').show()
    } else {
      $('#editDrivingTimeButton').hide()
    }
  },
  editDrivingTime: function () {
    var view = this
    view.addSubView('settingsPopup', DrivingTimeSettingsPopupView)
  },
  destroy: function () {
    var view = this
    if (view.hasSubView('settingsPopup')) {
      view.findSubView('settingsPopup').destroy()
    }
    window.wlsMapTools.deactivateDrivingTime()
    view.remove()
  }
})

export default DrivingTimeReportView
