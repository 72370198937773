import $ from 'jquery'
import View from '../../View'
import SelectGroupList from '../../../collection/report/leagueTable/SelectGroupList'

const LeagueTableGroupListView = View.extend({
  templateUrl: 'template/report/leagueTable/groupList.html',
  collection: SelectGroupList,
  constructor: function (option) {
    var view = this
    var args = arguments
    // this.model = new this.model()
    this.el = option.el
    this.$el = $(this.el)
    this.collection = new this.collection()

    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, args)
    })
  },
  initialize: function () {
    var view = this
  },
  fetchList: function (options) {
    this.collection.fetch(options)
  },
  fetchListSuccess: function (defaultOption) {
    this.render(defaultOption)
  },
  render: function (defaultOption) {
    var html = this.dotTemplateToHtml(this.collection.toJSON())
    var $select = this.getParentView().$el

    this.$el.html(html)

    $select.selectmenu().val(defaultOption)
    $select.selectmenu('refresh')
  }
})

export default LeagueTableGroupListView
