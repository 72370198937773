import SelectionMenuModel from '../../SelectionMenu'

const DriverBehaviourReportSelectionMenuModel = SelectionMenuModel.extend({
  defaults: {
    repType: 'DriverBehaviour'
  },
  url: 'api/selection-menu/driver-behaviour'
})

export default DriverBehaviourReportSelectionMenuModel
