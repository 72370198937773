import $ from 'jquery'
import SpeedingSnailTrailView from './SpeedingSnailTrail'
import SpeedingReportSnailTrailTableGridView from './SpeedingReportSnailTrailTableGrid'
import SnailTrailStepPlayView from '../SnailTrailStepPlay'

const SpeedingReportSnailTrailPopupView = SpeedingSnailTrailView.extend({
  url: '/getJourneyDetailsPopup.do',
  initialize: function () {
    this.addSubView('SnailTrailStepPlayer', SnailTrailStepPlayView, { el: this.$('[data-player=stepPlay]') })
    this.addSubView('snailTrailTableGrid', SpeedingReportSnailTrailTableGridView)
  },
  exportToPdf: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('PDF'))
  },
  generatePdfXlsReportURL: function (repFormat) { // implementation
    var view = this
    var parentView = view.getParentView()
    var tableGridView = view.findSubView('snailTrailTableGrid')
    var journeyIds = parentView.getSelectedJourneyList()
    var mapMetadata = window.mapCanvas.map.getCenter()
    var model = window.modelForSnailTrailSpeedingReport

    var data = {
      'report-name': 'SpeedingImg',
      'report-type': 'SpeedingImg',
      'report-format': repFormat,
      alternate: false,
      journeyIdList: journeyIds.join('/'),
      centerLat: mapMetadata.latitude ? mapMetadata.latitude : mapMetadata.lat(),
      centerLon: mapMetadata.longitude ? mapMetadata.longitude : mapMetadata.lng(),
      zoomLevel: window.mapCanvas.map.getZoom(),
      selectedIndex: tableGridView.selectedIndex,
      percentSpeedCriteria: model.get('percentSpeedCriteria'),
      mphSpeedCriteria: model.get('mileSpeedCriteria'),
      operation: model.get('operation')
    }

    return $.param(data)
  },
  show: function () { // override
    $('[data-popup="journeyDetails"]').addClass('speeding-report')
    $('#content').prepend($('[data-popup="journeyDetails"]'))

    SpeedingSnailTrailView.prototype.show.apply(this, arguments)
  }

})

export default SpeedingReportSnailTrailPopupView
