import $ from 'jquery'
import PopupView from '../../Popup'
import ClosedDefectCommentFormView from './ClosedDefectCommentForm'

const StatusPopupView = PopupView.extend({
  url: 'js/html/app/src/template/report/vehicleDefectReport/statusPopup.html',
  elSelector: '[data-popup=status]',
  initialize: function () {
    this.addSubView('commentForm', ClosedDefectCommentFormView)
    this.findSubView('commentForm').$('.save span').text('Close')
  },
  closeBtnHandler: function () {
    var view = this

    view.findSubView('commentForm').resetTextArea()
    view.getParentView().resetSelectedItemAndRow()
    view.hide()
  },
  switchToClosed: function () {
    var view = this

    view.$('[data-title]').text('Defect is closed')
    view.$el.addClass('defect-closed')
  },
  switchToOpen: function () {
    var view = this

    view.$('[data-title]').text('Close Defect')
    view.$el.removeClass('defect-closed')
  },
  render: function (json) { // override
    var view = this
    var html

    if (view.isElementInDom()) {
      html = view.dotTemplateToHtml(json)
      view.$el.html($(html).html())
    } else {
      html = view.dotTemplateToHtml(json)
      $('#content').append(html)
      view.$el = $(view.elSelector)
      view.el = view.$el[0]
    }
    if (view.subView && view.hasSubView('commentForm')) {
      view.findSubView('commentForm').resetElement()
    }
    return this
  },
  loadHTML: function (url, callback) { // override
    var view = this
    $.post(url, function (msg) {
      view.dotTemplate = msg
      view.compileTemplate()
      view.render()
      view.model.set('htmlReady', true)
      if (callback) callback()
    })
    return this
  },
  destroy: function () {
    var view = this

    view.findSubView('commentForm').destroy()
    view.remove()
  }
})

export default StatusPopupView
