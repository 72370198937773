import FormView from '../../Form'
import TowAwayAlertModel from '../../../model/alert/towAway/TowAwayAlert'
import $ from 'jquery'

const TowAwayAlertFormView = FormView.extend({
  model: TowAwayAlertModel,
  mergeEvents: true,
  events: {
    'change input[name=sendToSelf]': 'sendToSelfChange'
  },
  constructor: function () {
    var TowAwayAlert = this.parentView

    this.el = TowAwayAlert.$el.find('form')
    FormView.apply(this, arguments)
  },
  initialize: function () {
    var view = this
    var notificationPopupView = view.findSubView('notificationPopup')
    var TowAwayAlertView = this.parentView
    var TowAwayAlertTabsetView
    var TowAwayAlertListPopup
    var TowAwayAlertTableGridView

    view.listenTo(view.model, 'change:sendToSelf', function (model, sendToSelf) {
      view.renderSendToSelf(sendToSelf)
    })
    TowAwayAlertView.on('ready', function () {
      TowAwayAlertTabsetView = TowAwayAlertView.tabsetView
      TowAwayAlertListPopup = TowAwayAlertView.findSubView('alertListPopup')
      TowAwayAlertTableGridView = TowAwayAlertListPopup.findSubView('alertTableGrid')

      view.on('cancel', function () {
        var selectVehicle
        var selectRecipientTableGridView

        view.getParentView().switchTitle('create')
        TowAwayAlertTableGridView.resetSelectedItemAndRow()
        if ('selectVehicle' in TowAwayAlertTabsetView) {
          selectVehicle = TowAwayAlertTabsetView.selectVehicle.innerView
          selectVehicle.reloadContent()
        }
        if ('selectRecipient' in TowAwayAlertTabsetView) {
          selectRecipientTableGridView = TowAwayAlertTabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid')
          selectRecipientTableGridView.reset()
        }
      })
      view.on('save', function () {
        TowAwayAlertTableGridView.model.get('tableRow').reset()
        if (TowAwayAlertListPopup.isVisible()) {
          TowAwayAlertTableGridView.fetchAlertlist()
        }
        view.cancel()
        notificationPopupView.render({
          msgId: 'successfully_saved',
          title: '',
          message: 'Successfully saved.',
          button: ['OK']
        })
      })
      view.model.on('invalid', function (model, error) {
        notificationPopupView.render(error)
      })
    })
  },
  sendToSelfChange: function (e) {
    var $checkbox = $(e.currentTarget)

    this.model.set('sendToSelf', $checkbox.prop('checked'), { silent: true })
  },
  renderSendToSelf: function () {
    this.$el.find('input[name=sendToSelf]').prop('checked', this.model.get('sendToSelf'))
  },
  cancel: function () {
    this.getParentView().model.set({ dataMode: 'create' })
    // this.getParentView().tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.id = 0;
    this.reset()
    this.trigger('cancel')
  },
  syncData: function () {
    var TowAwayAlertView = this.getParentView()
    var TowAwayAlertTabsetView = TowAwayAlertView.tabsetView

    if ('selectVehicle' in TowAwayAlertTabsetView) {
      this.model.set('selectedAlertVehicleList', TowAwayAlertTabsetView.selectVehicle.innerView.selectedTableGridCollection.toJSON())
    }

    if ('selectRecipient' in TowAwayAlertTabsetView) {
      this.model.set('selectedAlertRecipientList', TowAwayAlertTabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid').model.get('tableRow').toJSON())
    }

    this.model.set(this.getFormData())

    if (TowAwayAlertView.model.get('dataMode') === 'edit') {
      this.model.set('id', TowAwayAlertView.model.get('alertId'))
      this.model.set('active', TowAwayAlertView.alertListPopupView.findSubView('alertTableGrid').model.get('tableRow').get(this.model).get('active'))
    } else {
      TowAwayAlertView.model.set('alertId', null)
      this.model.set('id', null)
    }
  }
})

export default TowAwayAlertFormView
