
import AddEditPopupView from "../../AddEditPopup";
import UnpluggedDeviceAlertAddRecipientFormView from "./UnpluggedDeviceAlertAddRecipientForm";

const UnpluggedDeviceAlertAddRecipientPopup = AddEditPopupView.extend({
    url: "/getUnpluggedDeviceAddRecipientPopup.do",
    subView: {
        "addRecipientForm": UnpluggedDeviceAlertAddRecipientFormView
    },
    initialize: function(option) {
        AddEditPopupView.prototype.initialize.apply(this, arguments);

        const view = this;
        let addRecipientFormView;

        addRecipientFormView = view.findSubView("addRecipientForm");

        view.listenTo(view.model, "change:dataMode", function(model, dataMode) {
            view.$el.toggleClass("tooltip-arrow");
            if (dataMode === "create") {
                addRecipientFormView.reset();
            }
        });

        $(document).on("click.recipientPopup", function(e) {
            const $target = $(e.target);
            const map = $("#labelCanvasId");
            if (
                !$target.closest("[data-button=addRecipient]").length &&
                !$target.closest(".add-drop").length &&
                !$target.closest(".popup-wrap").length &&
                !$target.closest("[data-button=edit]").length &&
                $target[0] !== map[0]
            ) {
                view.model.set("visible", false);
                addRecipientFormView.cancel();
            }
        });
    },
    closeBtnHandler: function() {
        const view = this;
        view.hide();
    }
});

export default UnpluggedDeviceAlertAddRecipientPopup;
