import TableGridView from '../TableGrid@'
import PlottableReportTableDropView from './PlottableReportTableDrop'
import CompactReportTableGridScrollView from '../../view/report/CompactReportTableGridScroll'

const PlottableReportTableGridView = TableGridView.extend({
  mergeEvents: true,
  events: {
    'click tbody tr:not(.empty-row)': ''
  },
  constructor: function (option) {
    var view = this

    view.setSubView({
      tableDrop: PlottableReportTableDropView,
      scroll: CompactReportTableGridScrollView
    })

    TableGridView.apply(view, arguments)
  },
  destroy: function () {
    var view = this
    view.findSubView('tableDrop').remove()
    view.findSubView('scroll').destroy()
    view.remove()
  }
})

export default PlottableReportTableGridView
