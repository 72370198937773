import AlertListPopupView from '../AlertListPopup'
import TowAwayAlertTableGridView from './TowAwayAlertTableGrid'
import TowAwayAlertListPopupModel from '../../../model/alert/towAway/TowAwayAlertListPopup'

const TowAwayAlertListPopupView = AlertListPopupView.extend({
  model: TowAwayAlertListPopupModel,
  AlertTableGridView: TowAwayAlertTableGridView
})

export default TowAwayAlertListPopupView
