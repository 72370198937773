import TableGridModel from '../../TableGrid@'
import FuelUsageList from '../../../collection/report/fuelUsageReport/FuelUsageList'

const FuelUsageReportTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: false,
    tableHead: [{
      tableHeadName: 'Veh. Reg.',
      field: 'vehReg'
    },
    {
      tableHeadName: 'Group',
      field: 'group'
    },
    {
      tableHeadName: 'Sub-Group',
      field: 'subGroup'
    },
    {
      tableHeadName: 'Start Odometer',
      distanceMetric: true,
      field: 'startOdometer',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Miles:',
                filterByField: 'startOdometer',
                select: {
                  name: 'filter',
                  options: [
                    {
                      option: 'Greater Than',
                      value: 'greaterThan'
                    },
                    {
                      option: 'Less Than',
                      value: 'lessThan'
                    }
                  ]
                },
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'float'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'End Odometer',
      distanceMetric: true,
      field: 'endOdometer',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Miles:',
                filterByField: 'endOdometer',
                select: {
                  name: 'filter',
                  options: [
                    {
                      option: 'Greater Than',
                      value: 'greaterThan'
                    },
                    {
                      option: 'Less Than',
                      value: 'lessThan'
                    }
                  ]
                },
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'float'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Distance',
      field: 'distance',
      distanceMetric: true,
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Miles:',
                filterByField: 'distance',
                select: {
                  name: 'filter',
                  options: [
                    {
                      option: 'Greater Than',
                      value: 'greaterThan'
                    },
                    {
                      option: 'Less Than',
                      value: 'lessThan'
                    }
                  ]
                },
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'float'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Litres',
      field: 'fuelLevelVarianceLitres',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Litres:',
                filterByField: 'fuelLevelVarianceLitres',
                select: {
                  name: 'filter',
                  options: [
                    {
                      option: 'Greater Than',
                      value: 'greaterThan'
                    },
                    {
                      option: 'Less Than',
                      value: 'lessThan'
                    }
                  ]
                },
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'float'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'mpg',
      field: 'mpg',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'mpg:',
                filterByField: 'mpg',
                select: {
                  name: 'filter',
                  options: [
                    {
                      option: 'Greater Than',
                      value: 'greaterThan'
                    },
                    {
                      option: 'Less Than',
                      value: 'lessThan'
                    }
                  ]
                },
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'float'
                }
              }
            ]
          }
        }
      ]
    }],
    tableRow: FuelUsageList
  }
})

export default FuelUsageReportTableGridModel
