import ReportFormModel from '../ReportForm'

const TimeOnSiteFormModel = ReportFormModel.extend({
  defaults: {
    excludeOnSiteMovements: null,
    duration: null,
    aoiPoiId: '',
    postcodeFilter: ''
  }
})

export default TimeOnSiteFormModel
