import Model from '../Model'

const SelectVehicleDropdownModel = Model.extend({
  urlRoot: 'api/proximity/vehicle-options',
  defaults: {
    data: []
  }
})

export default SelectVehicleDropdownModel
