import $ from 'jquery'
import View from '../View'
import ProximityModel from '../../model/proximity/Proximity'
import ProximityLocationSearchView from './ProximityLocationSearch'
import ProximitySelectionMenuView from './ProximitySelectionMenu'
import SelectVehicleDropdownView from './SelectVehicleDropdown'
import PoiDropdownView from '../PoiDropdown'

const ProximityView = View.extend({
  model: ProximityModel,
  el: '<div class="proximity-wrap"></div>',
  initialize: function () {
    var view = this
    view.model = new ProximityModel()

    view.addSubView('aoiPoiDropDown', PoiDropdownView, { el: view.$('.select-holder.aoi-poi') })
    view.addSubView('selectVehicle', SelectVehicleDropdownView, { el: view.$('[data-select=vehicle]') })
    view.addSubView('proximityLocationSearch', ProximityLocationSearchView, { el: view.$('.proximity__location-search') })
    // view.addSubView('proximityForm', ProximityFormView)

    view.generateSelectVehicleDropdown()
    view.generateSelectPOIDropdown(view.bindEvents)

    view.render()
  },
  bindEvents: function (view) {
    var selectionMenu = view.getParentView().findSubView('selectionMenu')
    var resetPinFunction = ProximityView.prototype.resetPin
    var resetSelectFunction = ProximityView.prototype.resetSelect
    var pin = view.model.get('pin')

    var leftPanel = $('.tabset-report-panel.proximity .left-panel.proximity')
    var selectVehicle = $('[data-select-vehicle-proximity]')
    var selectPOI = $('[data-select-poi-proximity]')
    var form = $(view.el).find('div.search')
    var searchInput = form.find('.proximity-geocode')
    var searchSubmit = form.find('.button')
    var formDropdown = form.find('.dropdown')
    var htmlGroupTabsAllowed = window.settings.data.htmlGroupTabsAllowed

    if (!selectVehicle.data('defVal')) {
      selectPOI.data('defVal', selectPOI.find('.title-holder span').text())
      selectVehicle.data('defVal', selectVehicle.find('.title-holder span').text())
    }
    selectVehicle.add(selectPOI).off('click').on('click', function () {
      $(this).toggleClass('active').parents('.row').siblings().find('.select').removeClass('active')
    })
    selectVehicle.add(selectPOI).find('.dropdown li').off('click').click(function () {
      $('.left-panel.proximity').show()
      var option = $(this)
      var curSelect = $(this).closest('.select')
      var title = curSelect.find('.title-holder span')
      var mapObject
      var otherSelect = selectVehicle.add(selectPOI).not($(this).closest('.select'))

      title.text(option.text())

      if (htmlGroupTabsAllowed) {
        if (leftPanel.find('.right-col .vehicle-holder').is(':hidden')) {
          leftPanel.find('.right-col .vehicle-holder').show()
          leftPanel.find('.control-panel,.control-panel+.row').show()
          window.groupTabsetView.renderScroll()
        }
      } else {
        if (leftPanel.find('.right-col .vehicle-holder').css('visibility') == 'hidden') {
          leftPanel.find('.right-col .vehicle-holder').css('visibility', 'visible')
          leftPanel.find('.control-panel,.control-panel+.row').show()
        }
      }
      if (!window.searchVehicle) {
        leftPanel.find('.vehicle-search').hide()
      }

      view.resetPin()
      selectionMenu.resetSelection()

      if (curSelect.is('[data-select=vehicle]')) {
        mapObject = window.wls.getIdList()[option.attr('data-id')]
        pin = mapObject
        view.model.set('pin', pin)
        mapObject.addPin()
        mapObject.ignore = true
        window.cid = mapObject.data.id
        mapObject.HTML.hide()
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('resize')
        leftPanel.find('.right-col .vehicle-holder .scroll-content').triggerHandler('animationEnd')
      } else if (curSelect.is('[data-select=aoiPoi]')) {
        mapObject = window.wlsPOIHandler.getIdList()[option.attr('data-id').split(':', 1)]
        pin = mapObject
        view.model.set('pin', pin)
        mapObject.addPin()
      }

      window.pin = pin
      selectionMenu.showDistances(pin)
      resetSelectFunction(otherSelect.find('.title-holder span'), otherSelect.data('defVal'))
      view.resetSearch()
      form.removeClass('active')
      formDropdown.find('ul').html('')
      leftPanel.find('.right-col').removeClass('hide-shadow')
    })

    $(document).off('click.select').on('click.select', function (e) {
      if (!$(e.target).closest('.select').size()) {
        selectVehicle.add(selectPOI).removeClass('active')
      }
    })
  },
  generateSelectVehicleDropdown: function () {
    var view = this
    var collection = window.wls.getVehicles()
    var curSelect = $('[data-select=vehicle]')
    var idList = window.wls.getIdList()
    var len = collection.length
    var options = ''

    for (var i = 0; i < len; i++) {
      if (!collection[i].data.inStealthMode) {
        options += '<li data-id="' + collection[i].data.id + '">' + collection[i].data.description + '</li>'
      }
    }

    view.setOptions(options, $('.dropdown', curSelect))
    curSelect.addClass('active')
    view.initSelectScroll($('.scroll-content', curSelect))
    curSelect.removeClass('active')
  },
  generateSelectPOIDropdown: function (callback) {
    var view = this
    var collection
    var curSelect = $('[data-select=aoiPoi]')
    var len
    var options = '' // /getPoiForCurrentUser.do

    window.wlsPOIHandler.requestOOIs(function () {
      collection = window.wlsPOIHandler.OOIs
      len = collection.length
      for (var i = 0; i < len; i++) {
        options += '<li data-id="' + collection[i].data.id + '">' + collection[i].data.description + '</li>'
      }
      view.setOptions(options, $('.dropdown', curSelect))
      curSelect.addClass('active')
      view.initSelectScroll($('.scroll-content', curSelect))
      curSelect.removeClass('active')
      if (callback) callback(view)
    })
  },
  initSelectScroll: function ($frame) {
    var $wrap = $frame.parent()

    if ('sly' in $frame) {
      $frame.sly('destroy')
    }

    $frame.sly({
      speed: 300,
      easing: 'linear',
      scrollBar: $wrap.find('.scrollbar'),
      scrollBy: 100,
      dragHandle: 1,
      dynamicHandle: 1,
      clickBar: 1
    })

    if ($frame.find('.slidee').height() > $frame.height()) {
      $wrap.find('.scrollbar').show()
    } else {
      $wrap.find('.scrollbar').hide()
    }
  },
  setOptions: function (options, $dropdown) {
    options = $(options)
    $dropdown.find('ul').html(options)
  },
  resetPin: function () {
    var view = this
    var leftPanel = $('.tabset-report-panel.proximity .left-panel')
    var pin = view.model.get('pin')

    if (window.wls.hasShapes()) {
      window.wls.removeCars()
      window.mapCanvas.cleanUp()
    }
    if (pin) {
      pin.remove()
      pin.ignore = false
      if ('HTML' in pin) {
        pin.HTML.show()
      }
      pin = null
      leftPanel.find('.proximity-search').val('')
    }
  },
  resetSelect: function (title, val) {
    if (title && val) {
      title.text(val)
    } else {
      $('[data-select-poi],[data-select-vehicle]').each(function (i) {
        $(this).find('.title-holder span').text($(this).data('defVal'))
      })
    }
  },
  resetSearch: function () {
    var form = $('.proximity-wrap div.search')
    var searchInput = form.find('.proximity-geocode')

    searchInput.val('')
  },
  resetSearchDropdown: function () {
    var leftPanel = $('.tabset-report-panel.proximity .left-panel')
    var form = leftPanel.find('.proximity-search').closest('.search')
    var formDropdown = form.find('.dropdown')
    if (formDropdown) {
      formDropdown.find('ul').html('')
    }
  },
  handlePostcodeSearch: function (e) {
    var view = this

    $('.left-panel.proximity').show()
    var selectionMenu = view.findSubView('selectionMenu')
    var leftPanel = $('.tabset-report-panel.proximity .left-panel.proximity')
    var form = leftPanel.find('.proximity-search').closest('.search')
    var redMarker = new Image()
    redMarker.src = 'img/markers/marker_red.png'
    var markerData = e.data.markerData
    var pin = view.model.get('pin')

    if (pin) {
      view.resetPin()
    }

    window.mapCanvas.addTextMarkerSimple(redMarker, 'Chosen Location', markerData.lon, markerData.lat, true,
      function (result) {
        pin = result
        view.model.set('pin', pin)
        pin.remove = function () {
          window.mapCanvas.removeMarker(this)
        }
        pin.lon = markerData.lon
        pin.lat = markerData.lat
        form.removeClass('active')
        if (leftPanel.find('.proximity-view .right-col .vehicle-holder').is(':hidden')) {
          leftPanel.find('.proximity-view .right-col .vehicle-holder').css('visibility', 'visible')
          leftPanel.find('.proximity-view .control-panel,.control-panel+.row').show()
        }
        selectionMenu.showDistances(pin)
        setTimeout(window.rezoomMapFlx, 1500)
        leftPanel.find('.right-col').removeClass('hide-shadow')
      })
  },
  getSelectionMenuVehicleList: function () {
    return this.findSubView('selectionMenu').findSubView('vehicleList')
  },
  getReportFormSelectedVehicleView: function () {
    return this.findSubView('proximityForm').findSubView('selectedVehicle')
  },

  isVisible: function () {
    return this.$el.is(':visible')
  },
  destroy: function (dontShowLeftPanel) {
    var view = this
    //            view.findSubView('proximityForm').destroy()
    view.resetPin()
    //             reportHandler.removeReport(view)
    //             window.reportPageIsActive = false
    //             wls.resetExpandedState()
    //             if(!dontShowLeftPanel){
    //                 if(userPrefs.htmlGroupTabsAllowed){
    //                     window.groupTabsetView.collection.fetch({success: function(){
    //                             var groupId = $.cookie('lastViewedGroup')
    //
    //                             $.cookie('lastViewedGroup',null) //reset cookie
    //                             window.groupTabsetView.selectGroup(groupId)
    //                             $('.left-panel').fadeIn(400)
    //                             $.cookie('lastViewedGroup',groupId)
    //                             view.getSelectionMenuVehicleList().saveSelectedVehicleToStorage()
    //                             groupTabsetView.render()
    //                         }})
    //                 }else{
    //                     view.getSelectionMenuVehicleList().saveSelectedVehicleToStorage()
    //                     wls.requestCars($.cookie('lastViewedGroup'),function(){
    //                         $('.left-panel').fadeIn(400,function(){
    // //                            wls.removeCars()
    //                             $('.left-panel .right-col .vehicle-holder .scroll-content').triggerHandler('resize' ,[ $('.left-panel .vehicle-holder .slidee').height() ])
    //                         })
    //                     })
    //                 }
    //             }else{
    //                 view.getSelectionMenuVehicleList().saveSelectedVehicleToStorage()
    //             }
    // $('.top-bar').animate({'margin-left': 0},600).removeClass('active')
    view.remove()
  }
})

export default ProximityView
