/**
 * Created by George on 24.07.2015.
 */
import FormView from '../../Form';
import DriverBehaviorAlert from '../../../model/alert/driverBehavior/DriverBehaviorAlert';

const DriverBehaviorAlertFormView = FormView.extend({
    model: DriverBehaviorAlert,
    mergeEvents: true,
    events: {
        "change input[name=sendToSelf]": "sendToSelfChange"
    },
    constructor: function(){
        const DriverBehaviorAlert = this.parentView;
        this.el = DriverBehaviorAlert.$el.find("form");
        FormView.apply(this, arguments);
    },
    initialize: function(){
        const view = this;
        const notificationPopupView = view.findSubView("notificationPopup");
        const DriverBehaviorAlertView = this.parentView;
        let DriverBehaviorAlertTabsetView;
        let DriverBehaviorAlertListPopup;
        let DriverBehaviorAlertTableGridView;

        view.listenTo(view.model, "change:sendToSelf", function(model, sendToSelf){
            view.renderSendToSelf(sendToSelf);
        });
        DriverBehaviorAlertView.on("ready", function(){
            DriverBehaviorAlertTabsetView = DriverBehaviorAlertView.tabsetView;
            DriverBehaviorAlertListPopup = DriverBehaviorAlertView.findSubView("alertListPopup");
            DriverBehaviorAlertTableGridView = DriverBehaviorAlertListPopup.findSubView("alertTableGrid");

            view.on("cancel", function(){
                let selectProfile;
                let selectRecipientTableGridView;

                view.getParentView().switchTitle("create");
                DriverBehaviorAlertTableGridView.resetSelectedItemAndRow();
                if ("selectProfile" in DriverBehaviorAlertTabsetView){
                    selectProfile = DriverBehaviorAlertTabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid");
                    selectProfile.reloadContent();
                }
                if ("selectRecipient" in DriverBehaviorAlertTabsetView){
                    selectRecipientTableGridView = DriverBehaviorAlertTabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid");
                    selectRecipientTableGridView.reset();
                }
            });
            view.on("save", function(){
                DriverBehaviorAlertTableGridView.model.get('tableRow').reset();
                if (DriverBehaviorAlertListPopup.isVisible()){
                    DriverBehaviorAlertTableGridView.fetchAlertlist();
                }
                view.cancel();
                notificationPopupView.render({
                    msgId: "successfully_saved",
                    title: "",
                    message: "Successfully saved.",
                    button: ["OK"]
                });
            });
            view.model.on("invalid", function(model, error) {
                notificationPopupView.render(error);
            });
        });
    },
    sendToSelfChange: function(e){
        const $checkbox = $(e.currentTarget);
        this.model.set("sendToSelf", $checkbox.prop("checked"), {silent: true});
    },
    renderSendToSelf: function(){
        this.$el.find("input[name=sendToSelf]").prop("checked", this.model.get("sendToSelf"));
    },
    cancel: function(){
        const parentView = this.getParentView();
        parentView.model.set({dataMode: "create"});
        parentView.tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.id = 0;
        this.reset();
        this.trigger("cancel");
    },
    syncData: function(){
        const DriverBehaviorAlertView = this.getParentView();
        const DriverBehaviorAlertTabsetView = DriverBehaviorAlertView.tabsetView;

        if ("selectProfile" in DriverBehaviorAlertTabsetView){
            this.model.set("selectedAlertProfileList", DriverBehaviorAlertTabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").selectedTableGridCollection.toJSON());
        }
        if ("selectRecipient" in DriverBehaviorAlertTabsetView){
            this.model.set("selectedAlertRecipientList", DriverBehaviorAlertTabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").model.get('tableRow').toJSON());
        }
        this.model.set(this.getFormData());
        if (DriverBehaviorAlertView.model.get("dataMode") === "edit"){
            this.model.set("id", DriverBehaviorAlertView.model.get("alertId"));
            this.model.set("active", DriverBehaviorAlertView.alertListPopupView.findSubView("alertTableGrid").model.get('tableRow').get(this.model).get("active"));
        } else {
            DriverBehaviorAlertView.model.set("alertId", null);
            this.model.set('id', null);
        }
    }
});

export default DriverBehaviorAlertFormView;
