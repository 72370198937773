import Model from '../Model'

const PhoneNumberModel = Model.extend({
  defaults: {
    userId: null,
    phoneNumber: '0000 000 000 000'
  },
  url: 'api/lone-worker/phone-number'
})

export default PhoneNumberModel
