import ReportSelectionMenuView from '../../ReportSelectionMenu'
import DriverBehaviourReportSelectionMenuModel from '../../../model/report/driverBehaviourReport/DriverBehaviourSelectionMenu'
import DriverBehaviourSelectionMenuVehicleListView from './DriverBehaviourSelectionMenuVehicleList'

const DriverBehaviourSelectionMenuView = ReportSelectionMenuView.extend({
  model: DriverBehaviourReportSelectionMenuModel,
  templateUrl: 'template/report/driverBehaviour/driverBehaviourReportSelectionMenu.html',
  elSelector: '[data-selection-menu=driverBehaviour]',
  initialize: function () {
    var view = this

    view.addSubView('vehicleList', DriverBehaviourSelectionMenuVehicleListView)
    view.render()
  }
})

export default DriverBehaviourSelectionMenuView
