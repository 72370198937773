import $ from 'jquery'
import ScrollView from './Scroll'
import HorizontalScrollModel from '../model/HorizontalScroll'

const HorizontalScrollView = ScrollView.extend({
  model: HorizontalScrollModel,
  checkContentSize: function () {
    return this.$('.slidee').width() > this.$el.width()
  },
  calculateScrollContentSize: function () {
    var view = this
    var $slidee = view.$el.find('.slidee')
    var $item = $slidee.find('[data-item]')
    var slideeWidth = 1
    $slidee.css('width', 'auto')
    $item.each(function () {
      slideeWidth += $(this).outerWidth()
    })
    $slidee.width(slideeWidth)
  }
})

export default HorizontalScrollView
