import PlottableReportTableGridView from './PlottableReportTableGrid'
import LoneWorkerActivityLogTableGridModel from '../../model/loneWorker/LoneWorkerActivityLogTableGrid'
import $ from 'jquery'

const LoneWorkerActivityLogTableGridView = PlottableReportTableGridView.extend({
  model: LoneWorkerActivityLogTableGridModel,
  templateUrl: 'template/loneWorker/activityLogTableGrid.html',
  events: {
    'click tbody tr': 'plotLog',
    'click .drop-poi-btn': 'addNewPoi',
    'contextmenu tbody tr>td.poi-address': 'goToPOI',
    'click table': 'outFromPOI'
  },

  initialize: function () {
    this.logsOnMap = []
    this.logs = []
  },

  plotLog: function (e) {
    var $tr = $(e.currentTarget)
    var view = this
    if (this.logs.length === 0) {
      this.updateLogs()
    }
    var curLog = this.logs[$tr.index()]
    $tr.toggleClass('active')
    if (this.logsSize() === 0) {
      var size = this.logs.length
      for (var i = 0; i < size; i++) {
        this.addLog({}, i)
      }
    }

    if (!this.logPutOnMap(curLog)) {
      this.addLog(curLog, $tr.index())
      view.$('input:checkbox:checked').prop('checked', false)
    } else {
      var index = this.getLogIndex(curLog)
      this.removeLog(index)
    }

    if (this.logsSize() >= 0) {
      this.removeSnailTrailFromMap()
    }

    if (this.logsSize() === 0) {
      window.wls.loadState()
    } else {
      this.showLogs()
    }

    if (view.getParentView().plotModeOn) {
      if (this.logsSize() !== this.rowsSize()) {
        view.getParentView().$('.plot-all input:checkbox:checked').prop('checked', false)
        view.getParentView().plotModeOn = false
      }
    }
  },

  goToPOI: function (e) {
    e.preventDefault()

    var $el = $('#addPoiMenu')
    var _$el = $(e.currentTarget)
    var $curr = _$el.parent('tr').attr('data-id')
    var pageX = _$el.position().left + _$el.width() - $el.width() + 10
    var pageY = _$el.position().top + (_$el.outerHeight() / 2) - ($el.height() / 2) - 1

    this.getPOIcoordinates(window.toPoiCollection, $curr)

    $el.css({ left: pageX, top: pageY })
    $el.delay(100).show()
  },

  outFromPOI: function () {
    var $el = $('#addPoiMenu')

    $el.hide()
  },

  addNewPoi: function () {
    var $report = $('.advanced-activity-log')

    $report.hide()

    this.outFromPOI()

    window.AddPOI.goToPOIAOI('fromActivityLog')
  },

  getPOIcoordinates: function (arr, el) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].id === parseInt(el, 10)) {
        window.AddPOI.lat = arr[i].lat
        window.AddPOI.lon = arr[i].lon
      }
    }
    if (window.AddPOI.lat === 0 || window.AddPOI.lon === 0) {
      window.AddPOI.lat = 51.507351
      window.AddPOI.lon = -0.127758
    }
  },

  plotAll: function () {
    var modelJson = this.model.toJSON()
    if (modelJson && modelJson.tableRow) {
      var data = modelJson.tableRow
      var log
      for (var i = 0; i < data.length; i++) {
        log = data[i]
        this.addLog(log, i)
      }
      this.showLogs()
    }
  },

  markAllActive: function (active) {
    var view = this
    if (active) {
      view.$el.find('tr:not(.empty-row)').addClass('active')
    } else {
      view.$el.find('tr').removeClass('active')
    }
  },

  showSnailTrailOnMap: function (data) {
    if (data.length && data.length > 0) {
      window.wls.isActivityLogsOnMap = true
      var dataLen = data.length
      var directionIconUrl
      var applicationStatusUrl
      var movingStateUrl
      var loneWorkerIconUrl
      var img = null
      for (var i = 0; i < dataLen; i++) {
        var currData = data[i]
        if (data[i].id) {
          directionIconUrl = currData.directionIconUrl
          applicationStatusUrl = currData.applicationStatusUrl
          movingStateUrl = currData.movingStateUrl
          loneWorkerIconUrl = currData.vehicleIconUrl

          window.mapCanvas.addLoneWorkerMarker(
            directionIconUrl,
            applicationStatusUrl,
            movingStateUrl,
            loneWorkerIconUrl,
            currData.recDate,
            currData.lat,
            currData.lon,
            function (shape) {
              shape.logIndex = i
              shape.mapIndex = i
              window.mapCanvas.addToShapeLayer(shape)
              window.rezoomMapFlx()
              window.onMap = true
            })
        }
      }
    } else {
      window.mapCanvas.addLoneWorkerMarker(data.directionIconUrl, data.applicationStatusUrl, img, data.recDate, data.lat, data.lon, function (shape) {
        shape.logIndex = data.index
        shape.mapIndex = data.index
        window.mapCanvas.addToShapeLayer(shape)
        window.rezoomMapFlx()
        window.onMap = true
      })
    }
  },

  removeSnailTrailFromMap: function () {
    window.mapCanvas.cleanUp()
    window.onMap = false
  },

  logPutOnMap: function (log) {
    if (log) {
      for (var i = 0; i < this.logsOnMap.length; i++) {
        var item = this.logsOnMap[i]
        if (item && item.id === log.id) {
          return true
        }
      }
      return false
    } else {
      return false
    }
  },

  addLog: function (log, index) {
    this.logsOnMap[index] = log
  },

  removeLog: function (index) {
    this.logsOnMap[index] = {}
  },

  getLogIndex: function (log) {
    var size = this.logsOnMap.length
    for (var i = 0; i < size; i++) {
      if (this.logsOnMap[i].id === log.id) {
        return i
      }
    }
    return -1
  },

  logsSize: function () {
    var count = 0
    var curLog
    if (this.logsOnMap) {
      for (var i = 0; i < this.logsOnMap.length; i++) {
        curLog = this.logsOnMap[i]
        if (curLog && curLog.id !== undefined) {
          count++
        }
      }
    }
    return count
  },

  rowsSize: function () {
    return this.logsOnMap.length
  },

  showLogs: function () {
    if (this.logsSize() === 0) {
      window.wls.isActivityLogsOnMap = false
      window.wls.loadState()
    } else {
      this.showSnailTrailOnMap(this.logsOnMap)
    }
  },

  reverseLogOrder: function () {
    this.removeSnailTrailFromMap()
    this.logsOnMap = []
    if (this.logs.length === 0) {
      this.updateLogs()
    }
    var tempArray = []
    this.logsOnMap = []
    for (var i = this.logs.length - 1; i >= 0; i--) {
      tempArray[this.logs.length - i - 1] = this.logs[i]
    }
    this.logs = tempArray
  },

  refreshLogOrder: function (sortOrder) {
    if (sortOrder && sortOrder !== 'sortAsc') {
      this.reverseLogOrder()
    }
  },

  updateLogs: function () {
    var modelJson = this.model.toJSON()
    if (modelJson) {
      this.logs = modelJson.tableRow
    }
  }
})

export default LoneWorkerActivityLogTableGridView
