import Model from '../Model'

const DriverModel = Model.extend({
  defaults: {
    driverId: null,
    name: null,
    id: null
  },
  urlRoot: 'api/selection-menu/action/edit-vehicle/driver'
})

export default DriverModel
