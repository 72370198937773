import View from '../View'
import AvailableTableGridView from './AvailableTableGrid'
import SelectedTableGridView from './SelectedTableGrid'
import $ from 'jquery'
import _ from 'underscore'

const AvailableSelectedTableGridView = View.extend({
  availableProfileListCopy: null,
  alertId: null,
  events: {
    'click .button-holder [data-button]': 'handleButton'
  },
  constructor: function (option) {
    var view = this
    var args = arguments
    view.$el = $(option.el)

    view.onFetchProfileListSuccess = _.bind(view.onFetchProfileListSuccess, view)

    view
      .initAvailableTableGrid()
      .initSelectedTableGrid()

    view.availableTableGridView.on('ready', function () {
      if (view.selectedTableGridView.isReady) {
        View.apply(view, args)
      }
    })
    view.selectedTableGridView.on('ready', function () {
      if (view.availableTableGridView.isReady) {
        view.fetchProfileList().done(function () {
          view.render()
        })
        View.apply(view, args)
      }
    })
  },
  fetchProfileList: function () {
    var view = this

    view.trigger('beforeFetch', view.availableTableGridView.collection)

    return view.availableTableGridView.collection.fetch({ silent: true }).done(view.onFetchProfileListSuccess)
  },
  onFetchProfileListSuccess: function () {
    var view = this

    view.availableProfileListCopy = view.availableTableGridView.collection.toJSON()
    view.resetButtons()
  },
  render: function () {
    var view = this

    view.availableTableGridView.renderGrid()
    view.selectedTableGridView.renderGrid()
    view.updateSelectedRow(this.availableTableGridView)
      .updateSelectedRow(this.selectedTableGridView)
    view.$el.closest('.tabset-report-panel').css('visibility', 'visible')
  },
  initAvailableTableGrid: function () {
    var view = this

    view.availableTableGridView = new AvailableTableGridView({
      el: view.$el.find('.info-holder:first')
    })
    view.availableTableGridView.on('itemSelect', function () {
      if (view.availableTableGridView.$el.has('.active').size()) {
        view.setButtonState(true, 'copyItem')
      } else {
        view.setButtonState(false, 'copyItem')
      }
    })
    view.availableTableGridView.on('ready', function () {
      view.availableTableGridCollection = view.availableTableGridView.collection
    })

    return view
  },
  initSelectedTableGrid: function () {
    var view = this

    view.selectedTableGridView = new SelectedTableGridView({
      el: view.$el.find('.info-holder:last')
    })
    view.selectedTableGridView.on('itemSelect', function () {
      if (view.selectedTableGridView.$el.has('.active').size()) {
        view.setButtonState(true, 'removeItem')
      } else {
        view.setButtonState(false, 'removeItem')
      }
    })
    view.selectedTableGridView.on('ready', function () {
      view.selectedTableGridCollection = view.selectedTableGridView.collection
    })
    return this
  },
  updateSelectedRow: function (tableGridView) {
    var selectedItem = tableGridView.getSelectedItem()

    if (!_.size(selectedItem)) {
      tableGridView.$el.find('tr').removeClass('active')
      return this
    }
    _.each(selectedItem, function (model, id) {
      tableGridView.$el.find('tr[data-id=' + id + ']').addClass('active').siblings().removeClass('active')
    })
    return this
  },
  setButtonState: function (enable) {
    var view = this
    var buttons = []

    _.each(Array.prototype.slice.call(arguments, 1, arguments.length), function (buttonName) {
      buttons.push(view.getButton(buttonName)[0])
    })

    if (enable) {
      $(buttons).removeClass('disabled')
    } else {
      $(buttons).addClass('disabled')
    }
    return this
  },
  getButton: function (buttonName) {
    return this.$el.find('[data-button=' + buttonName + ']')
  },
  handleButton: function (e) {
    var $button = $(e.currentTarget)

    if (!$button.hasClass('disabled')) {
      this[$button.data('button')]()
    }
  },
  copyAll: function () {
    var view = this

    var models = this.availableTableGridView.collection.models

    view.setButtonState(true, 'removeAll')
      .setButtonState(false, 'copyAll', 'copyItem')
    view.availableTableGridCollection.each(function (model) {
      var newModel = new view.selectedTableGridCollection.model()
      mergeModelAttributes(newModel, model)
      view.selectedTableGridCollection.add(newModel, { silent: true, at: view.availableTableGridView.getElementIndex(model) })
    })
    view.availableTableGridCollection.remove(models, { silent: true })
    view.availableTableGridView.resetSelectedItemAndRow()
    view.render()
  },
  copyItem: function () {
    var view = this
    var availableTableGridSelectedItem = this.availableTableGridView.getSelectedItem()

    view.setButtonState(true, 'removeAll')
      .setButtonState(false, 'copyItem')
    _.each(availableTableGridSelectedItem, function (model) {
      var newModel = new view.selectedTableGridCollection.model()
      mergeModelAttributes(newModel, model)
      view.availableTableGridCollection.remove(model, { silent: true, remove: false })
      view.selectedTableGridCollection.add(newModel, { silent: true, at: 0 })
    })

    if (!view.availableTableGridCollection.size()) {
      view.setButtonState(false, 'copyItem', 'copyAll')
    }

    view.availableTableGridView.resetSelectedItemAndRow()
    view.render()
  },
  removeItem: function () {
    var view = this
    var selectedTableGridSelectedItem = this.selectedTableGridView.getSelectedItem()

    view
      .setButtonState(true, 'copyAll')
      .setButtonState(false, 'removeItem')

    _.each(selectedTableGridSelectedItem, function (model) {
      var newModel = new view.availableTableGridCollection.model()
      view.selectedTableGridCollection.remove(model, { silent: true })
      mergeModelAttributes(newModel, model)
      view.availableTableGridCollection.add(newModel, { silent: true })
    })

    if (!view.selectedTableGridCollection.size()) {
      view.setButtonState(false, 'removeItem', 'removeAll')
    }

    view.selectedTableGridView.resetSelectedItemAndRow()
    view.availableTableGridView.sortByIndex()
    view.render()
  },
  removeAll: function () {
    var view = this
    var selectedTableGridmodels = view.selectedTableGridCollection.models

    view.setButtonState(false, 'removeAll', 'removeItem')
      .setButtonState(true, 'copyAll')

    _.each(selectedTableGridmodels, function (model) {
      var newModel = new view.availableTableGridCollection.model()

      mergeModelAttributes(newModel, model)
      view.availableTableGridCollection.add(newModel, { silent: true })
    })

    view.selectedTableGridCollection.remove(selectedTableGridmodels, { silent: true })

    if (view.availableTableGridCollection.size() > 1) {
      view.availableTableGridView.sortByIndex()
    }

    view.selectedTableGridView.resetSelectedItemAndRow()
    view.render()
  },
  resetButtons: function () {
    this.setButtonState(false, 'removeItem', 'copyItem')

    if (this.selectedTableGridView.collection.length) {
      this.setButtonState(true, 'removeAll')
    } else {
      this.setButtonState(false, 'removeAll')
    }

    if (!this.availableTableGridView.collection.length) {
      this.setButtonState(false, 'copyAll')
    } else {
      this.setButtonState(true, 'copyAll')
    }
  },
  reloadContent: function (selectedList, excludeSelected) {
    var view = this

    view.availableTableGridView.collection.set(view.availableProfileListCopy, { silent: true })
    view.availableTableGridView.resetSelectedItemAndRow()
    view.selectedTableGridView.resetSelectedItemAndRow()

    if (selectedList) {
      view.selectedTableGridCollection.set(selectedList, { silent: true })
      if (excludeSelected) {
        view.selectedTableGridCollection.forEach(function (model, index) {
          var id = model.get('id')

          if (view.availableTableGridView.collection.get(id)) {
            view.availableTableGridView.collection.remove(id)
          }
        })
      }
    } else {
      view.selectedTableGridCollection.reset()
    }

    view.render()
    view.availableTableGridView.setElementIndex()
    view.resetButtons()
  }
})

function mergeModelAttributes (newModel, model) {
  _.each(newModel.keys(), function (property) {
    var propertyValue = model.get(property)
    if (propertyValue) {
      newModel.set(property, propertyValue)
    }
  })
}

export default AvailableSelectedTableGridView
