import $ from 'jquery'
import View from '../View'
import sort from '../../util/sort'

const CompactReportView = View.extend({
  events: {
    'click [data-button=slide]': 'slide'
  },
  constructor: function () {
    var view = this
    var args = arguments

    view.loadDotTemplate(function () {
      view.$el = $(view.dotTemplate)
      view.el = view.$el[0]
      view.render()
      View.apply(view, args)
    })
  },
  render: function () {
    var view = this

    view.getParentView().$el.append(view.$el)
    view.$el.css({ visibility: 'hidden', display: 'block' })
    return view
  },
  show: function () {
    this.$el.css({ visibility: 'visible', display: 'none' }).fadeIn(400, function () {
      $('.map-wrap').removeClass('active').css('margin-left', 0)
      $('.left-panel').removeClass('active')
    })
  },
  slide: function (e) {
    var slideBtn = $(e.currentTarget)
    var leftPosition = 0

    if (slideBtn.hasClass('active')) {
      leftPosition = 0
    } else {
      leftPosition = -this.$el.width()
    }

    slideBtn.toggleClass('active')
    this.$el.stop().animate({ left: leftPosition }, 600)
  },
  isVisible: function () {
    return this.$el.is(':visible')
  },
  destroy: function () {
    var view = this

    view.findSubView('compactReportTableGrid').destroy()
    view.findSubView('compactReportForm').destroy()
    view.remove()
  }
})

export default CompactReportView
