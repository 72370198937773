import $ from 'jquery'
import View from '../View'
import OutOfHoursProfileDropdownModel from '../../model/report/outOfHoursQuery/OutOfHoursProfileDropdown'
import SelectItemList from '../../collection/SelectItemList'

const OutOfHoursProfileDropdownView = View.extend({
  model: OutOfHoursProfileDropdownModel,
  collection: SelectItemList,
  elSelector: '.select-holder.aoi-poi',
  constructor: function (option) {
    var view = this
    view.model = new view.model()
    view.$el = view.getParentView().$el.find(view.elSelector)
    view.el = view.$el
    view.collection = new view.collection(view.model.get('data'))
    var select = view.el.find('[data-select=outOfHoursProfileId]')

    if (!select.data('defVal')) {
      select.data('defVal', select.find('.title-holder span').text())
    }
    select.off('click').on('click', function () {
      $(this).toggleClass('active').parent().siblings().find('.select').removeClass('active')
    })

    var curSelect

    view.model.fetch({
      success: function () {
        var collection = view.model.get('data')
        curSelect = select
        var len = collection.length
        var options = ''

        if (len > 0) {
          var sel = curSelect.closest('.select')
          var ttl = sel.find('.title-holder span')
          var input = $('#outOfHoursProfileId')
          input.val(collection[0].value)
          ttl.text(collection[0].label)
        }

        for (var i = 0; i < len; i++) {
          options += '<li data-id="' + collection[i].value + '">' + collection[i].label + '</li>'
        }
        view.setOptions(options, $('.dropdown', curSelect))
        curSelect.addClass('active')

        curSelect.removeClass('active')
        curSelect.find('.dropdown li').off('click').click(function () {
          var option = $(this)
          var curSelect = $(this).closest('.select')
          var title = curSelect.find('.title-holder span')
          var input = curSelect.find('input[type=hidden]')
          console.log(option.data('id'))
          input.val(option.data('id'))

          title.text(option.text())
        })
      },
      error: function (e) {
        console.error(e)
      }
    }).done(function () {
      view.initSelectScroll($('.scroll-content', curSelect))
    })

    View.apply(this, arguments)
  },
  initialize: function () {
    console.log('AoiPoiDropdown: initialize()')
  },

  initSelectScroll: function ($frame) {
    var $wrap = $frame.parent()
    var ulHeight
    var drop = $frame.closest('.ui-selectmenu-menu')

    drop.show()
    ulHeight = $frame.find('ul').css('height', 'auto').height()
    if ($wrap.height() < ulHeight) {
      $frame.height($wrap.outerHeight(true))
      if (!$frame.hasClass('hasScroll')) {
        $frame.sly({
          horizontal: false,
          speed: 300,
          easing: 'linear',
          scrollBar: $wrap.find('.scrollbar'),
          scrollBy: 10,
          dragHandle: 1,
          dynamicHandle: 1,
          clickBar: 1
        })
        $frame.addClass('hasScroll')
      }
      $frame.sly('reload')
      $wrap.find('.scrollbar').show()
    } else {
      $frame.sly('destroy')
      $wrap.find('.scrollbar').hide()
      $frame.attr('style', '')
      $frame.removeClass('hasScroll')
    }
    drop.attr('style', '')
  },

  setOptions: function (options, $dropdown) {
    options = $(options)
    $dropdown.find('ul').html(options)
  }

})

export default OutOfHoursProfileDropdownView
