import AlertPanelView from '../AlertPanel'
import OutOfHoursAlertListPopupView from './OutOfHoursAlertListPopup'
import OutOfHoursAlertFormView from './OutOfHoursAlertForm'
import OutOfHoursProfileManagerPopupView from "./OutOfHoursProfileManagerPopup";

const OutOfHoursAlertView = AlertPanelView.extend({
    constructor: function(option){
        this.setSubView({});
        this.AlertListPopupView = OutOfHoursAlertListPopupView; //use addSubView
        this.tabsetName = "outOfHoursAlert";
        this.ProfileManagerPopupView = OutOfHoursProfileManagerPopupView;
        if (option.el) {
            this.$el = option.el;
            this.el = option.el[0];
        } else {
            this.$el = $("[data-tab=outOfHoursAlert]");
            this.el = this.$el[0];
        }
        this.addSubView("alertForm", OutOfHoursAlertFormView);
        AlertPanelView.apply(this,arguments);
    },
    initialize: function(){ // should remove this
        var view = this;

        view.alertListPopupView.on("ready",function(){

            this.alertListTableGridView.on("itemSelect",function($tr,id){
                var activeTabName = view.tabsetView.model.get("activeTab");

                if(view.model.get("dataMode") == "edit"){

                    if(activeTabName === "selectRecipient"){

                        if(view.tabsetView.selectProfile) {
                            view.tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.set(this.selectedItem[id].get("selectedAlertProfileList"));
                        }
                    } else {

                        if(view.tabsetView.selectRecipient){
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").collection.set(this.selectedItem[id].get("selectedAlertRecipientList"));
                        }
                    }
                } else {

                    if(activeTabName === "selectRecipient"){

                        if(view.tabsetView.selectProfile) {
                            view.tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.set([]);
                        }
                    } else {

                        if(view.tabsetView.selectRecipient) {
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").collection.set([]);
                        }
                    }
                }
            });
        });
    }
});

export default OutOfHoursAlertView;
