import $ from 'jquery'
import CompactReportTableGridView from '../CompactReportTableGrid'
import LocationReportTableGridModel from '../../../model/report/location/LocationReportTableGrid'

const LocationReportTableGridView = CompactReportTableGridView.extend({
  model: LocationReportTableGridModel,
  templateUrl: 'template/report/location/locationReportTableGrid.html',
  events: {
    'click tbody tr': 'plotLog',
    'click .drop-poi-btn': 'addNewPoi',
    'contextmenu tbody tr>td.poi-address': 'goToPOI',
    'click table': 'outFromPOI'
  },

  initialize: function () {
    this.logsOnMap = []
  },
  plotLog: function (e) {
    var $tr = $(e.currentTarget)
    var view = this
    var modelJson = this.model.toJSON()

    if (modelJson) {
      var activityLogs = modelJson.tableRow
      var curLog = activityLogs[$tr.index()]
      $tr.toggleClass('active')

      if (this.logsSize() === 0) {
        var size = activityLogs.length
        for (var i = 0; i < size; i++) {
          this.addLog({}, i)
        }
      }

      if (!this.logPutOnMap(curLog)) {
        this.addLog(curLog, $tr.index())
        view.$('input:checkbox:checked').prop('checked', false)
      } else {
        var index = this.getLogIndex(curLog)
        this.removeLog(index)
      }

      if (this.logsSize() >= 0) {
        this.removeSnailTrailFromMap()
      }

      if (this.logsSize() !== 0) {
        this.showLogs()
      }

      if (view.getParentView().plotModeOn) {
        if (this.logsSize() !== this.rowsSize()) {
          view.getParentView().$('.plot-all input:checkbox:checked').prop('checked', false)
          view.getParentView().plotModeOn = false
        }
      }
    }
  },
  goToPOI: function (e) {
    e.preventDefault()

    if ($('.table-drop').is(':Visible')) {
      this.findSubView('tableDrop').hide()
    }

    var $el = $('#addPoiMenu')
    var _$el = $(e.currentTarget)
    var $curr = _$el.parent('tr').attr('data-id')
    var pageX = _$el.position().left + _$el.width() - $el.width() + 10
    var pageY = _$el.position().top + (_$el.outerHeight() / 2) - ($el.height() / 2) - 1

    this.getPOIcoordinates(window.toPoiCollection, $curr)

    $el.css({ left: pageX, top: pageY })
    $el.delay(100).show()
  },

  outFromPOI: function () {
    var $el = $('#addPoiMenu')

    $el.hide()
  },

  addNewPoi: function () {
    var $report = $('.advanced-activity-log')

    $report.hide()

    this.outFromPOI()

    window.AddPOI.goToPOIAOI('fromActivityLog')
  },

  getPOIcoordinates: function (arr, el) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].id === parseInt(el, 10)) {
        window.AddPOI.lat = arr[i].lat
        window.AddPOI.lon = arr[i].lon
      }
    }
    if (window.AddPOI.lat === 0 || window.AddPOI.lon === 0) {
      window.AddPOI.lat = 51.507351
      window.AddPOI.lon = -0.127758
    }
  },

  plotAll: function () {
    var modelJson = this.model.toJSON()
    if (modelJson && modelJson.tableRow) {
      var data = modelJson.tableRow
      var log
      for (var i = 0; i < data.length; i++) {
        log = data[i]
        this.addLog(log, i)
      }
      this.showLogs()
    }
  },

  markAllActive: function (active) {
    var view = this
    if (active) {
      view.$el.find('tr:not(.empty-row)').addClass('active')
    } else {
      view.$el.find('tr').removeClass('active')
    }
  },

  showSnailTrailOnMap: function (data) {
    if (data.length && data.length > 0) {
      const dataLen = data.length
      let directionIconUrl
      let ignitionIconUrl
      let vehicleIconUrl
      let currData

      for (var i = 0; i < dataLen; i++) {
        currData = data[i]
        if (data[i].id) {
          directionIconUrl = '../' + currData.directionIconUrl
          ignitionIconUrl = '../' + currData.ignitionIconUrl
          vehicleIconUrl = '../../img/vehicleIcons/vehicle_car_estate_red_E_100.png'

          window.mapCanvas.addActivityMarker(
            currData.id,
            directionIconUrl,
            ignitionIconUrl,
            vehicleIconUrl,
            currData.recDate,
            currData.lat,
            currData.lon,
            function (shape) {
              shape.logIndex = i
              shape.mapIndex = i
              window.mapCanvas.addToShapeLayer(shape)
              // rezoomMapFlx();
              window.onMap = true
            },
            currData.streetName,
            currData.postcode,
            currData.directionCode,
            currData.state,
            currData.factoredSpeed,
            currData.ignitionStr,
            currData.registrationNumber,
            currData.driverName)
        }
      }
      window.rezoomMapFlx()
    } else {
      let img
      window.mapCanvas.addActivityMarker(data.id, data.directionIconUrl, data.ignitionIconUrl, img, data.recDate, data.lat, data.lon, function (shape) {
        shape.logIndex = data.index
        shape.mapIndex = data.index
        window.mapCanvas.addToShapeLayer(shape)
        window.rezoomMapFlx()
        window.onMap = true
      })
    }
  },

  removeSnailTrailFromMap: function () {
    window.mapCanvas.cleanUp()
    window.onMap = false
  },

  logPutOnMap: function (log) {
    if (log) {
      for (var i = 0; i < this.logsOnMap.length; i++) {
        var item = this.logsOnMap[i]
        if (item.id === log.id) {
          return true
        }
      }
      return false
    } else {
      return false
    }
  },

  addLog: function (log, index) {
    this.logsOnMap[index] = log
  },

  removeLog: function (index) {
    this.logsOnMap[index] = {}
  },

  getLogIndex: function (log) {
    var size = this.logsOnMap.length
    for (var i = 0; i < size; i++) {
      if (this.logsOnMap[i].id === log.id) {
        return i
      }
    }
    return -1
  },

  logsSize: function () {
    var count = 0
    var curLog
    if (this.logsOnMap) {
      for (var i = 0; i < this.logsOnMap.length; i++) {
        curLog = this.logsOnMap[i]
        if (curLog && curLog.id !== undefined) {
          count++
        }
      }
    }
    return count
  },

  rowsSize: function () {
    return this.logsOnMap.length
  },

  showLogs: function () {
    if (this.logsSize() === 0) {
      window.wls.loadState()
    } else {
      this.showSnailTrailOnMap(this.logsOnMap)
    }
  }
})

export default LocationReportTableGridView
