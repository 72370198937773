
import PopupModel from "../../Popup";

const UnpluggedDeviceAlertListPopup = PopupModel.extend({
    defaults: {
        isDraggable: true
    }
});

export default UnpluggedDeviceAlertListPopup;
