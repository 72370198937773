import Model from './Model'

const ModalModel = Model.extend({
  defaults: {
    visible: false,
    lockAnimation: false,
    htmlReady: false
  }
})

export default ModalModel
