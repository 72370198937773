import SelectionMenuVehicleListView from '../../SelectionMenuVehicleList'
import JourneyReportSelectionMenuVehicleList from '../../../collection/report/journeyReport/JourneyReportSelectionMenuVehicleList'
import JourneyReportSelectionMenuVehicleListScrollView from '../journeyReport/JourneyReportSelectionMenuVehicleListScroll'

const DetailedReportSelectionMenuVehicleListView = SelectionMenuVehicleListView.extend({
  collection: JourneyReportSelectionMenuVehicleList,
  templateUrl: 'template/report/journeyReport/journeyReportSelectionMenuVehicleList.html',
  elSelector: '[data-vehicle-list=journey]',
  initialize: function () {
    var view = this

    setTimeout(function () {
      if (view.collection.models.length > 0) {
        var vehicleModel = view.collection.models[0]
        view.$('[data-item=vehicle][data-id=' + vehicleModel.id + ']').addClass('active')
        vehicleModel.set('selected', true)
        view.collection.models.forEach(function (model) {
          if (model.id != vehicleModel.id) {
            view.$('[data-item=vehicle][data-id=' + model.id + ']').removeClass('active')
            model.set('selected', false)
          }
        })
      }
    }, 100)

    view.addSubView('scroll', JourneyReportSelectionMenuVehicleListScrollView)
    view.render()
  },
  selectVehicle: function (vehicleId, silent) {
    var view = this
    var vehicleModel = view.collection.get(vehicleId)

    if (vehicleModel.get('locked')) return
    if (vehicleModel.get('inStealthMode')) return
    view.$('[data-item=vehicle][data-id=' + vehicleId + ']').addClass('active')
    vehicleModel.set('selected', true, { silent: silent })
    view.collection.models.forEach(function (model) {
      if (model.id != vehicleId) {
        view.$('[data-item=vehicle][data-id=' + model.id + ']').removeClass('active')
        model.set('selected', false, { silent: silent })
      }
    })
  }
})

export default DetailedReportSelectionMenuVehicleListView
