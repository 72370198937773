import SelectView from '../Select'

const CompactReportSelectView = SelectView.extend({
  events: {
    selectmenuchange: 'selectChange'
  },
  selectChange: function (e, ui) {
    var view = this

    if (ui.item.value === 'none') {
      return
    }
    view.getParentView().findSubView('dateTimePickerRange')[ui.item.value]()
    view.getParentView().submitHandler()
  },
  reset: function () {
    var view = this

    view.el.value = 'none'
    view.$el.selectmenu('refresh')
  }
})

export default CompactReportSelectView
