import AlertTableGrid from '../AlertTableGrid'
import AlertList from '../../../collection/alert/geofence/GeoFenceAlertList'

const GeoFenceAlertTableGridModel = AlertTableGrid.extend({
  defaults: {
    tableRow: AlertList
  }
})

export default GeoFenceAlertTableGridModel
