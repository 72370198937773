import $ from 'jquery'
import Model from '../../Model'

const DrivingTimeSettingsModel = Model.extend({
  defaults: {
    drivingTimeIdlingSeconds: null
  },
  sync: function (method, model, options) {
    if (method === 'create') {
      return $.ajax({
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-HTTP-Method-Override', 'POST')
        },
        dataType: 'json',
        url: '/api/report/drivingTime/save',
        data: {
          drivingTimeIdlingSeconds: this.get('drivingTimeIdlingSeconds')
        }
      })
    } else if (method === 'read') {
      if (window.settings.data) {
        this.set('drivingTimeIdlingSeconds', window.settings.data.drivingTimeIdlingSeconds)
      }
    }
  },
  url: '/api/report/drivingTime/save'
})

export default DrivingTimeSettingsModel
