
import $ from 'jquery'
import TabsetView from '../Tabset'
import ProximitySelectionMenuView from './ProximitySelectionMenu'

const ProximityPageView = TabsetView.extend({
  templateUrl: 'template/proximity/proximity.html',
  events: {
    'click [data-button=slide]': 'slide'
  },
  constructor: function () {
    var view = this
    var args = arguments

    this.loadDotTemplate(function () {
      view.compileTemplate()
      view.$el = $('<div class="tabset-report-panel proximity" data-report-page="proximity"><div class="slide-button" data-button="slide"><span class="line"></span><span class="line"></span><span class="line"></span></div>' + view.dotTemplateToHtml({}) + '</div>')
      view.el = view.$el[0]
      TabsetView.apply(view, [{ el: view.el }])
    })
  },
  initialize: function () {
    var view = this
    /*
    this.tabsetView = new TabsetView({
        el: this.$el.find('[data-tabset=proximity]')
    })
    */
    // ProximityView (Current)
    // LocationReportPage (Previous)

    // view.addSubView('proximityTab', ProximityView)
    // view.addSubView('locationReportTab', LocationReportPageView)
    view.addSubView('selectionMenu', ProximitySelectionMenuView)
    view.listenTo(view.model, 'change:activeTab', function (tabsetModel, activeTab) {
      if (window.wls.hasShapes()) {
        window.mapCanvas.cleanUp()
      }

      if (view.proximity) {
        view.proximity.innerView.resetPin()
      }

      if (activeTab === 'location') {
        if (view.findSubView('selectionMenu').isVisible()) {
          view.findSubView('selectionMenu').hide(0)
        }

        if (view.location) {
          view.location.innerView.findSubView('locationReport').render()
          view.location.innerView.findSubView('locationReportForm').resetPin()
        }
      } else {
        view.location.innerView.findSubView('locationReport').closeBtnHandler()
        view.proximity.innerView.resetSelect()
        view.proximity.innerView.resetSearch()
        view.proximity.innerView.resetPin()
        view.proximity.innerView.generateSelectVehicleDropdown()
        view.proximity.innerView.generateSelectPOIDropdown(view.proximity.innerView.bindEvents)
      }
    })

    view.render()
  },
  render: function () {
    var $leftPanel = $('.left-panel')
    var $mapWrap = $('.map-wrap')

    if ($leftPanel.hasClass('active')) {
      $('.top-bar').animate({ 'margin-left': 0 }, 800)
      $mapWrap.animate({
        'margin-left': 0
      }, 800, function () {
        $leftPanel.removeClass('active')
        $mapWrap.removeClass('active')
      })
    }
    // $('.top-bar').animate({'margin-left': 0},600)
    $leftPanel.fadeOut(400)
    $mapWrap.append(this.$el)
    this.$el.fadeIn(400)
  },
  slide: function (e) {
    var slideBtn = $(e.currentTarget)
    var leftPosition = 0

    if (slideBtn.hasClass('active')) {
      leftPosition = 0
    } else {
      leftPosition = -this.$el.width()
    }

    slideBtn.toggleClass('active')
    this.$el.stop().animate({ left: leftPosition }, 600)
  },
  isVisible: function () {
    return this.$el.is(':visible')
  },
  destroy: function (item, dontShowLeftPanel) {
    var view = this

    if (item.hasClass('mapping')) {
      if (window.userPrefs.htmlGroupTabsAllowed) {
        window.groupTabsetView.collection.fetch({
          success: function () {
            var groupId = $.cookie('lastViewedGroup')

            $.cookie('lastViewedGroup', null) // reset cookie
            window.groupTabsetView.selectGroup(groupId)
            $('.left-panel').fadeIn(400)
            $.cookie('lastViewedGroup', groupId)
            // view.getSelectionMenuVehicleList().saveSelectedVehicleToStorage()

            window.groupTabsetView.render()
          }
        })
      } else {
        // view.getSelectionMenuVehicleList().saveSelectedVehicleToStorage()
        window.wls.requestCars($.cookie('lastViewedGroup'), function () {
          window.wls.removeCars()
          window.wls.restoreSelectionMenuState()
          $('.left-panel').fadeIn(400, function () {
            //                            wls.removeCars()
            $('.left-panel .right-col .vehicle-holder .scroll-content').triggerHandler('resize', [$('.left-panel .vehicle-holder .slidee').height()])
          })
        })
      }
    }

    $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
    view.remove()
  }
})

export default ProximityPageView
