import ReportPopupView from '../ReportPopup'
import TimeOnSiteFormView from './TimeOnSiteForm'

const TimeOnSitePopupView = ReportPopupView.extend({
  url: '/getReportWindow/TimeOnSite.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', TimeOnSiteFormView)
  }
})

export default TimeOnSitePopupView
