import CompactReportPageView from '../CompactReportPage'
import FuelTankLevelVarianceReportView from './FuelTankLevelVarianceReport'

const FuelTankLevelVarianceReportPageView = CompactReportPageView.extend({
  el: '<div class="report-page" data-report-page="fuelTankLevelVariance"></div>',
  initialize: function () {
    var view = this

    view.addSubView('compactReport', FuelTankLevelVarianceReportView)
    view.render()
  }
})

export default FuelTankLevelVarianceReportPageView
