import PopupView from '../Popup'
import HeartBeatView from './HeartBeat'

const HeartBeatPopupView = PopupView.extend({
  url: 'js/html/app/src/template/modalPanel/heartBeatPopup.html',
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'closeHandler',
    'click [data-button=save]': 'submit'
  },

  hideActivePopup: function () {
    var view = this

    if (view.isVisible()) {
      view.hide()
    }
  },
  initialize: function () {
    var view = this
    view.addSubView('heartBeat', HeartBeatView)
  },
  closeBtnHandler: function () { // override
    this.hide()
  },

  destroy: function () {
    this.findSubView('heartBeat').destroy()
    this.remove()
  },
  submit: function () {
    this.findSubView('heartBeat').submit()
  }

})

export default HeartBeatPopupView
