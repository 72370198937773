import Model from './Model'

const CommentModel = Model.extend({
  defaults: {
    comment: ''
  },
  validate: function (attrs, options) {
    if (!attrs.comment.trim()) {
      return 'empty comment'
    }
  }
})

export default CommentModel
