import ReportPopupView from '../ReportPopup'
import SpeedingReportFormView from './SpeedingReportForm'

const SpeedingReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/Speeding.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', SpeedingReportFormView)
  }
})

export default SpeedingReportPopupView
