import Model from './Model'

const Vehicle = Model.extend({
  defaults: {
    id: 0,
    registrationNumber: ''
  },
  urlRoot: '/services/vehicle'
})

export default Vehicle
