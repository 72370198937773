import View from '../View'
import SelectView from '../Select'
import ScrollView from '../Scroll'
import $ from 'jquery'
import Sortable from 'sortablejs'
import _ from 'underscore'

const MultiCameraVideoPlayerView = View.extend({
  events: {
    'click .vp-stop-btn': 'onPauseClick',
    'click .vp-play-btn': 'onPlayClick',
    'click .player-control .vp-fullscreen-btn': 'toggleFullScreen',
    'click .mini-video:not(.status)': 'onSelectChannel',
    'click .mini-video.status:has(.video-download)': 'requestVideo',
    'click .vp-volume-btn': function () {
      this.handleMuted(true)
    },
    'click .vp-volume-mute-btn': function () {
      this.handleMuted(false)
    }
  },
  constructor: function (option) {
    this.el = option.el
    this.$el = option.$el
    this.isPausedBeforeSeeking = false

    View.apply(this, arguments)
  },
  initialize: function () {
    var view = this
    var $video = this.$el.find('.video-player video')
    var $selectmenu = this.$el.find('.select-holder select')
    var VideosScrollView = ScrollView.extend({
      calculateScrollContentSize: function () { } // override
    })

    this.volumeBeforeMute = 1
    this.addSubView('scroll', VideosScrollView)
    this.addSubView('select', SelectView, { el: this.$el.find('.select-holder select') })
    $selectmenu.selectmenu({
      position: { my: 'top+12' }
    })
    $selectmenu.selectmenu('instance')._renderItem = function (ul, item) {
      var ico = $('<span class="camera-ico"></span>')
      var li = $('<li>')
      var data = view.getChannelById(item.value)
      let icoClass = ''

      switch (data.videoStatus) {
        case -1:
          icoClass = 'no-video'
          break
        case 0:
          icoClass = 'video-download'
          break
        case 1:
          icoClass = 'video-uploading'
          break
        case 2:
          icoClass = 'video-uploaded'
          break
      }

      this._setText(li, item.label)
      ico.prependTo(li)
      ico.addClass(icoClass)
      if (item.disabled) {
        li.addClass('ui-state-disabled')
      }

      return li.appendTo(ul)
    }
    $selectmenu.on('selectmenuopen', function (event, ui) {
      view.parentView.$el.css('overflow', 'visible')
    })
    $selectmenu.on('selectmenuselect', function (event, ui) {
      if (!event.bubbles) return
      var data = view.getChannelById(ui.item.value)

      if (data.videoStatus === 0) {
        view.requestVideo()
        view.previousSelectVal = ui.item.value
        return
      }

      var channels = view.props.channels
      var selectedChannel = _.find(channels, function (channel) {
        return channel.id === data.id
      })
      var videoUrl = selectedChannel.videoUrl

      view.selectedChannelId = data.id
      view.setDownloadUrl(videoUrl)
      view.setChannelName(selectedChannel.name)
      view.setProp('src', videoUrl)
      view.renderMiniVideos()
    })
    this.$el.find('.select-holder').hover(function () {

    }, function () {
      view.parentView.$el.css('overflow', 'hidden')
      $selectmenu.selectmenu('close')
    })
    this.$volumeEl = this.$el.find('.vp-volume-slider').slider({
      range: 'min',
      min: 0,
      max: 100,
      value: 100,
      slide: function (e, ui) {
        $video.prop('volume', ui.value / 100)
        view.setMuted(ui.value === 0)
      },
      stop: function (e, ui) {
        var value = ui.value / 100
        view.volumeBeforeMute = (value === 0 && view.volumeBeforeMute > 0.1 && view.volumeBeforeMute) || (value > 0.1 && value) || 0.1
        $video.prop('volume', value)
      }
    })
    $video.on('play', $.proxy(this.onPlay, this))
    $video.on('pause', $.proxy(this.onPause, this))
    $video.on('progress', $.proxy(this.onProgress, this))
    $video.on('timeupdate', $.proxy(this.updateTime, this))
    $video.on('loadedmetadata', $.proxy(this.loadedMetadata, this))
    $video.on('ended', $.proxy(this.onVideoEnded, this))

    this.fullscreenchangeListener = $.proxy(this.onEscPress, this)

    document.addEventListener('fullscreenchange', this.fullscreenchangeListener)
    document.addEventListener('webkitfullscreenchange', this.fullscreenchangeListener)
    document.addEventListener('mozfullscreenchange', this.fullscreenchangeListener)
    document.addEventListener('MSFullscreenChange', this.fullscreenchangeListener)
  },
  handleMuted: function (muted) {
    var value = muted ? 0 : this.volumeBeforeMute * 100

    this.setMuted(muted)
    this.$volumeEl.slider('value', value)

    !muted && this.$el.find('.video-player video').prop('volume', this.volumeBeforeMute)
  },
  onEscPress: function () {
    if (!this.isFullscreen()) {
      if (this.parentView.$el.hasClass('minimize')) {
        this.parentView.maximisePopup()
      } else {
        this.showSideVideos()
      }
      this.renderScroll()
      this.$el.removeClass('fullscreen-active')
    }

    this.calcMiniVideosHeight()
  },
  updateTime: function () {
    var video = this.$el.find('.video-player video')[0]
    var $timeSlider = this.$el.find('.vp-progress-line-container')

    this.$el.find('.vp-time-tracker span:first').text(this.formatSecondsToMS(video.currentTime))
    $timeSlider.slider('option', 'value', video.currentTime / video.duration * $timeSlider.slider('option', 'max'))
  },
  toggleFullScreen: function () {
    if (this.isFullscreen()) {
      this.exitFullscreen()
    } else {
      this.enterFullscreen()
      this.hideSideVideos()
    }
  },
  enterFullscreen: function () {
    var HTML = this.el
    if (HTML.requestFullscreen) {
      HTML.requestFullscreen()
    } else if (HTML.msRequestFullscreen) {
      HTML.msRequestFullscreen()
    } else if (HTML.mozRequestFullScreen) {
      HTML.mozRequestFullScreen()
    } else if (HTML.webkitRequestFullscreen) {
      HTML.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    }
    this.$el.addClass('fullscreen-active')
  },
  exitFullscreen: function () {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
    this.$el.removeClass('fullscreen-active')
    if (this.parentView.$el.hasClass('minimize')) {
      this.parentView.maximisePopup()
    } else {
      this.showSideVideos()
    }
    this.renderScroll()
  },
  isFullscreen: function () {
    return document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement
  },
  hideSideVideos: function () {
    this.$el.find('.side-video-section').hide()
  },
  showSideVideos: function () {
    if (this.props.channels.length > 1) {
      this.$el.find('.side-video-section').show()
    }
  },
  formatSecondsToMS: function (duration) {
    var seconds = duration % 60
    var minutes = duration / 60
    seconds = Math.floor(seconds)
    minutes = Math.floor(minutes)
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return minutes + ':' + seconds
  },
  loadedMetadata: function () {
    var view = this
    var $video = this.$el.find('.video-player video')
    var video = $video[0]
    var videoDuration = video.duration
    var $timeSlider = this.$el.find('.vp-progress-line-container')

    this.$sideVideos = this.$el.find('.mini-video video')

    this.$el.find('.vp-time-tracker span:last').text(this.formatSecondsToMS(videoDuration))
    $timeSlider.slider({
      range: 'min',
      min: 0,
      max: videoDuration * 1000,
      value: 0,
      start: function (e, ui) {
        view.isPausedBeforeSeeking = video.paused
        view.pause()

        $(document).one('mouseup', function () {
          if (!view.isPausedBeforeSeeking && !video.ended) {
            view.isPausedBeforeSeeking = null
            view.play()
          }
        })
      },
      slide: function (e, ui) {
        var currentTime = ui.value / $timeSlider.slider('option', 'max') * videoDuration

        video.currentTime = currentTime
        _.forEach(view.$sideVideos, function (item) {
          item.currentTime = currentTime
        })
        view.updateTime()
      }
    })
  },
  play: function () {
    var $allVideos = this.$el.find('video')

    _.forEach($allVideos, function (video) {
      video.play()
    })
  },
  pause: function () {
    var $allVideos = this.$el.find('video')

    _.forEach($allVideos, function (video) {
      video.pause()
    })
  },
  onPlay: function () {
    var video = this.$el.find('.video-player video')[0]

    if (video.ended) {
      video.currentTime = 0
    }
    this.$el.find('.vp-play-btn').addClass('vp-stop-btn').removeClass('vp-play-btn')
    this.play()
  },
  onPause: function () {
    // if(this.isPausedBeforeSeeking === false) return
    this.$el.find('.vp-stop-btn').addClass('vp-play-btn').removeClass('vp-stop-btn')
  },
  onPlayClick: function () {
    this.play()
  },
  onPauseClick: function () {
    this.pause()
  },
  onProgress: function () {
    var video = this.$el.find('.video-player video')[0]
    var videoDuration = video.duration
    var percentage = (video.buffered.end(video.buffered.length - 1) / videoDuration * 100)

    if (percentage === 100) {
      this.$el.find('.video-player video').off('progress')
    }
  },
  onVideoEnded: function () {
    this.$el.find('.vp-stop-btn').addClass('vp-play-btn').removeClass('vp-stop-btn')
  },
  onSelectChannel: function (e) {
    var view = this
    var channels = view.props.channels
    var currentChannel = _.find(channels, function (channel) {
      return channel.id === view.selectedChannelId
    })
    var $miniVideo = $(e.target).closest('.mini-video')
    var selectedChannelId = parseInt($miniVideo.attr('data-channelid'))
    var selectedChannel = _.find(channels, function (channel) {
      return channel.id === selectedChannelId
    })
    var videoUrl = selectedChannel.videoUrl
    var currentTime = this.$el.find('.video-player video')[0].currentTime

    this.selectedChannelId = selectedChannelId
    $miniVideo.attr('data-channelid', currentChannel.id)
    this.setDownloadUrl(videoUrl)
    this.setChannelName(selectedChannel.name)
    this.renderSelect()
    this.setProp('src', videoUrl)
    $miniVideo.find('video').prop('src', currentChannel.videoUrl)
    $miniVideo.find('.channel-name').text(currentChannel.name)
    this.setMuted(!selectedChannel.audio)
    this.setVolumeControlDisplay(selectedChannel.audio)
    _.forEach(this.$el.find('video'), function (item) { // set time for all videos
      item.currentTime = currentTime
    })
    view.play()
    view.updateSideVideosOrder()
  },
  getMultiCameraPopup: function () {
    return this.parentView
  },
  requestVideo: function () {
    var multiCameraPopup = this.getMultiCameraPopup()
    var activityTableGridView = multiCameraPopup.getActivityLogTableGrid()
    var confirmVideoRequestPopupView = multiCameraPopup.getConfirmVideoRequestPopup()
    var activityLog = activityTableGridView.getActivityLog(multiCameraPopup.activityLogId)

    activityTableGridView.showPopup(confirmVideoRequestPopupView)
    confirmVideoRequestPopupView.show()
    confirmVideoRequestPopupView.$el.css({
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -53%)'
    })

    confirmVideoRequestPopupView.renderGetMoreForm(activityLog)
  },
  setMuted: function (muted) {
    if (muted) {
      this.$el.find('.vp-volume-btn').removeClass('vp-volume-btn').addClass('vp-volume-mute-btn')
      // this.$volumeEl.slider('value', 0)
    } else {
      this.$el.find('.vp-volume-mute-btn').removeClass('vp-volume-mute-btn').addClass('vp-volume-btn')
      // this.$volumeEl.slider('value', this.volumeBeforeMute * 100)
    }
    this.setProp('muted', muted)
  },
  setProp: function (key, value) {
    this.$el.find('.video-player video').prop(key, value)
  },
  setProps: function (props) {
    var view = this
    this.props = {}
    var channels = this.props.channels = _.clone(props.channels)
    var selectedChannel = null
    var currentTime = null

    if (view.selectedChannelId) {
      selectedChannel = view.getSelectedChannel()
      currentTime = view.$el.find('.video-player video')[0].currentTime
    } else {
      selectedChannel = view.getFirstDownloadedChannel()
      this.setProp('src', selectedChannel.videoUrl)
      this.setProp('loop', true)
    }

    this.setSelectedChannelId(selectedChannel.id)
    this.setMuted(!selectedChannel.audio)
    this.setVolumeControlDisplay(selectedChannel.audio)

    this.setDownloadUrl(selectedChannel.videoUrl)
    this.setChannelName(selectedChannel.name)
    this.renderSelect()

    if (channels.length > 1) {
      this.setProp('autoplay', false)
      this.renderMiniVideos(currentTime)
    } else {
      this.setProp('autoplay', true)
      this.hideSideVideos()
    }

    if (channels.length > 2) {
      this.renderScroll()
      new Sortable(view.$el.find('#sortable')[0], {
        animation: 150,
        onEnd: function () {
          view.updateSideVideosOrder()
        }
      })
    }
  },
  updateSideVideosOrder: function () {
    var channels = this.props.channels
    var $miniVideos = this.$el.find('.mini-video')
    _.forEach($miniVideos, function (sideVideo, index) {
      var channelId = parseInt($(sideVideo).attr('data-channelid'))
      var currentChannel = _.find(channels, function (channel) {
        return channelId === channel.id
      })

      currentChannel.order = index
    })
  },
  setChannelName: function (name) {
    this.$el.find('.player-control .channel-name').text(name)
  },
  setDownloadUrl: function (videoUrl) {
    this.$el.find('.vp-download-btn a').attr('href', videoUrl)
  },
  setVolumeControlDisplay: function (display) {
    var $volumeContainerEl = this.$el.find('.vp-volume-container')

    display && $volumeContainerEl.show() || $volumeContainerEl.hide()
  },
  renderSelect: function () {
    var view = this
    var channels = view.props.channels
    var $select = view.$el.find('.select-holder select')
    var options = ''

    _.each(channels, function (channel) {
      var data = channel.id
      var disabled = channel.videoStatus === -1 || channel.videoStatus === 1 ? 'disabled' : ''
      var selected = channel.id === view.selectedChannelId ? 'selected' : ''

      options += '<option ' + disabled + ' ' + selected + ' value=' + data + '>' + channel.name + '</option>'
    })
    $select.html(options)
    $select.selectmenu('refresh')

    return $select
  },
  setSelectedChannelId: function (id) {
    this.selectedChannelId = id
  },
  getSelectedChannel: function () {
    var view = this

    return _.find(view.props.channels, function (channel) {
      return channel.id === view.selectedChannelId
    })
  },
  getFirstDownloadedChannel: function () {
    return _.find(this.props.channels, function (channel) {
      return channel.videoStatus === 2
    })
  },
  selectPreviousValue: function () {
    var $select = this.$el.find('.player-control select')

    $select.val(this.selectedChannelId)
    $select.selectmenu('refresh')
  },
  renderMiniVideos: function (currentTime) {
    var view = this
    var channels = view.props.channels
    var $content = view.$el.find('.side-video-section .slidee #sortable')
    var videos = ''
    var hasOrder = _.some(channels, function (channel) {
      return channel.order
    })

    if (hasOrder) {
      channels = _.sortBy(channels, function (item) {
        return item.order
      })
    }

    _.forEach(channels, function (channel, index) {
      if (view.selectedChannelId === channel.id) return
      var videoStatus = channel.videoStatus

      if (videoStatus === 2) {
        videos += '<div class="mini-video ui-state-default ui-sortable-handle" data-channelid="' + channel.id + '">'
        videos += '<video loop src="' + channel.videoUrl + '" style="width: 100%; height: 100%;" muted></video>'
      } else if (videoStatus === 1) {
        videos += '<div class="mini-video status ui-state-default ui-sortable-handle" data-channelid="' + channel.id + '">'
        videos += '<span class="camera-ico video-uploading" ></span>'
      } else if (videoStatus === 0) {
        videos += '<div class="mini-video status ui-state-default ui-sortable-handle" style="cursor: pointer" data-channelid="' + channel.id + '">'
        videos += '<span class="camera-ico video-download" ></span>'
      } else if (videoStatus === -1) {
        videos += '<div class="mini-video status ui-state-default ui-sortable-handle" data-channelid="' + channel.id + '">'
        videos += '<span class="camera-ico no-video"></span>'
      }

      videos += '<span class="channel-name">' + channel.name + '</span>'
      videos += '</div>'
    })
    $content.html(videos)
    this.$sideVideos = this.$el.find('.mini-video video')

    this.calcMiniVideosHeight()

    view.pause()

    if (!currentTime) {
      currentTime = view.$el.find('.video-player video')[0].currentTime
    }

    var $videos = view.$el.find('video')

    _.forEach($videos, function (item) { // set time for all videos
      item.currentTime = currentTime
    })
    view.play()
  },
  getChannelById: function (channelId) {
    channelId = parseInt(channelId)
    return _.find(this.props.channels, function (channel) {
      return channel.id === channelId
    })
  },
  keepChannelsFresh: function () {
    var view = this
    if (view.props.channels.length === 1) return
    view.timeoutId = setTimeout(function () {
      $.get('/api/activity-log/channels-info', { logId: view.parentView.activityLogId }).then(function (freshChannels) {
        var currentChannels = view.props.channels
        var channelChangedStatus = false
        // freshChannels = [
        //     {id: 40, name: 'Channel #1', videoStatus: 0, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
        //     {id: 41, name: 'Channel #2', videoStatus: 1, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
        //     {id: 42, name: 'Channel #3', videoStatus: 2, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
        //     {id: 43, name: 'Channel #4', videoStatus: -1, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
        //     {id: 44, name: 'Channel #5', videoStatus: 1, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
        //     {id: 45, name: 'Channel #6', videoStatus: 2, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
        //     {id: 46, name: 'Channel #7', videoStatus: 2, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'},
        //     {id: 47, name: 'Channel #8', videoStatus: 2, pictureStatus: 0, videoUrl: 'https://storage.googleapis.com/download/storage/v1/b/test-roadstream-video/o/864394040582001-1621436127708.mp4?generation=1621436288259894&alt=media'}
        // ]

        channelChangedStatus = _.find(freshChannels, function (freshChannel) {
          return _.find(currentChannels, function (currentChannel) {
            return currentChannel.id === freshChannel.id && currentChannel.videoStatus !== freshChannel.videoStatus
          })
        })

        if (channelChangedStatus) {
          var multiCameraPopup = view.parentView
          var requestVideoForm = multiCameraPopup.findSubView('requestVideo').findSubView('confirmVideoRequestForm')

          $.get('/api/activity-log/' + multiCameraPopup.activityLogId).then(function (activityLog) {
            requestVideoForm.updateActivityLogTableRow(activityLog)
          })

          view.setProps({
            channels: freshChannels
          })
        }
        view.keepChannelsFresh()
      })
    }, 30000)
  },
  renderScroll: function () {
    this.findSubView('scroll').render()
  },
  destroyScroll: function () {
    this.findSubView('scroll').destroySly()
  },
  calcMiniVideosHeight: function () {
    var aspectRatioHeight = 1080 / 1920
    var outerWidth = window.outerWidth
    var popupWidth = outerWidth * 0.7
    var listWidth = popupWidth * 0.26
    var height = listWidth * aspectRatioHeight

    if (this.isFullscreen()) {
      height = outerWidth * 0.26 * aspectRatioHeight
    }

    this.$el.find('.mini-video').css({ height: height.toFixed(2) + 'px' })
  },
  allVideosLoaded: function () {
    var $videos = this.$el.find('video')
    var counter = 0

    return new Promise(function (resolve) {
      $videos.one('canplaythrough', function () {
        counter++
        if (counter === $videos.size()) {
          resolve()
        }
      })
    })
  },
  reset: function () {
    this.onPause()
  },
  destroy: function () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    document.removeEventListener('fullscreenchange', this.fullscreenchangeListener)
    document.removeEventListener('webkitfullscreenchange', this.fullscreenchangeListener)
    document.removeEventListener('mozfullscreenchange', this.fullscreenchangeListener)
    document.removeEventListener('MSFullscreenChange', this.fullscreenchangeListener)
    this.remove()
  }
})

export default MultiCameraVideoPlayerView
