import SelectItemDropdownView from '../../SelectItemDropdown'
import DateRangeDropdownModel from '../../../model/report/location/DateRangeDropdown'

const DateRangeDropdownView = SelectItemDropdownView.extend({
  model: DateRangeDropdownModel,
  elSelector: '.select-holder.date-range',
  selectSelector: 'dateRange'
})

export default DateRangeDropdownView
