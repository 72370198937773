import ScrollView from '../../../Scroll'

const VehicleAssignScrollView = ScrollView.extend({
  calculateScrollContentSize: function () {
    this.$frame.height(this.getParentView().$el.height())
  },
  checkContentSize: function () {
    var assignChecklist = this.$el.closest('.assign-checklist')

    return this.$('.slidee').height() > assignChecklist.height()
  }
})

export default VehicleAssignScrollView
