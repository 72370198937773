import ReportSelectionMenuView from '../../ReportSelectionMenu'
import JourneyReportSelectionMenuModel from '../../../model/report/journeyReport/JourneyReportSelectionMenu'
import DetailedReportSelectionMenuVehicleListView from './DetailedReportSelectionMenuVehicleList'

const DetailedReportSelectionMenuView = ReportSelectionMenuView.extend({
  model: JourneyReportSelectionMenuModel,
  templateUrl: 'template/report/journeyReport/journeyReportSelectionMenu.html',
  elSelector: '[data-selection-menu=journey]',
  initialize: function () {
    var view = this

    view.addSubView('vehicleList', DetailedReportSelectionMenuVehicleListView)
    view.render()
  }
})

export default DetailedReportSelectionMenuView
