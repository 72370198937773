import AddEditPopupView from '../../AddEditPopup'
import TowAwayAlertAddRecipientFormView from './TowAwayAlertAddRecipientForm'
import $ from 'jquery'

const TowAwayAlertAddRecipientPopupView = AddEditPopupView.extend({
  url: '/getTowAwayAddRecipientPopup.do',
  subView: {
    addRecipientForm: TowAwayAlertAddRecipientFormView
  },
  initialize: function (option) {
    AddEditPopupView.prototype.initialize.apply(this, arguments)

    var view = this
    var addRecipientFormView
    //            view.addSubView("addRecipientForm",AddRecipientFormView);
    addRecipientFormView = view.findSubView('addRecipientForm')

    view.listenTo(view.model, 'change:dataMode', function (model, dataMode) {
      view.$el.toggleClass('tooltip-arrow')
      if (dataMode === 'create') {
        addRecipientFormView.reset()
      }
    })
    $(document).on('click.recipientPopup', function (e) {
      var $target = $(e.target)
      var map = $('#labelCanvasId')
      if (!$target.closest('[data-button=addRecipient]').size() && !$target.closest('.add-drop').size() && !$target.closest('.popup-wrap').size() && !$target.closest('[data-button=edit]').size() && $target[0] !== map[0]) {
        view.model.set('visible', false)
        addRecipientFormView.cancel()
      }
    })
  },
  closeBtnHandler: function () {
    var view = this
    view.hide()
  }
})

export default TowAwayAlertAddRecipientPopupView
