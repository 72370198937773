import PopupView from '../../Popup'
import DetailedVehicleDefectReportPopupModel from '../../../model/report/vehicleDefectReport/DetailedVehicleDefectReportPopup'

const DetailedVehicleDefectReportImagePopupView = PopupView.extend({
  url: 'js/html/app/src/template/report/vehicleDefectReport/detailedVehicleDefectReportImage.html',
  model: DetailedVehicleDefectReportPopupModel,
  mergeEvents: true,
  showPopup: function ($html) {
    var view = this

    view.$el.find('.title').text($html.attr('data-imgtype'))
    view.$el.find('.img-comment').text('').hide()

    if ($html.attr('data-imgcomment')) {
      view.$el.find('.img-comment').text($html.attr('data-imgcomment'))
      view.$el.find('.img-comment').show()
    }

    view.$el.find('[data-button=download]').attr('href', $html.attr('data-img'))
    view.$el.find('img').attr('src', $html.attr('data-img'))

    view.show()
    view.align()
  },
  closeBtnHandler: function () {
    var view = this

    view.hide()
  },
  destroy: function () {
    var view = this

    view.remove()
  }
})

export default DetailedVehicleDefectReportImagePopupView
