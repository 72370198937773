import PopupModel from '../../Popup'

var LocationReportPopupModel = PopupModel.extend({
  defaults: {
    isDraggable: true,
    position: {}
  }
})

export default LocationReportPopupModel
