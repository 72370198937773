import TableGridModel from '../../TableGrid@'
import LeagueTableList from '../../../collection/report/leagueTable/LeagueTableList'

const LeagueTableReportTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: false,
    tableRow: LeagueTableList
  }
})

export default LeagueTableReportTableGridModel
