import JqueryUiWidgetModel from './JqueryUiWidget'

const DraggableModel = JqueryUiWidgetModel.extend({
  defaults: {
    widgetName: "draggable",
    addClasses: true,
    appendTo: "parent",
    axis: false,
    cancel: "input,textarea,button,select,option",
    connectToSortable: false,
    containment: false,
    cursor: "auto",
    cursorAt: false,
    delay: 0,
    disabled: false,
    distance: 1,
    grid: false,
    handle: false,
    helper: "original",
    iframeFix: false,
    opacity: false,
    refreshPositions: false,
    revert: false,
    revertDuration: 500,
    scope: "default",
    scroll: true,
    scrollSensitivity: 20,
    scrollSpeed: 20,
    snap: false,
    snapMode: "both",
    snapTolerance: 20,
    stack: false,
    zIndex: false
  }
});

export default DraggableModel