import $ from 'jquery'
import View from './View'
import Tabset from '../model/Tabset'
import Proximity from './proximity/Proximity'
import LocationReportPageView from './report/location/LocationReportPage'
import AvailableSelectedTableGridView from './alert/AvailableSelectedTableGrid'
import SelectRecipientView from './alert/SelectRecipient'
import SelectProfileView from './alert/SelectProfile'
import OutOfHoursSelectedVehiclesView from "./alert/outOfHours/OutOfHoursSelectedVehicles"
import TimeRangeProfileBuilderFormView from "./alert/outOfHours/TimeRange"
import DriverBehaviorSelectedVehiclesView from "./alert/driverBehavior/DriverBehaviorSelectedVehicles"
import OverspeedSelectedVehicles from "./alert/overspeed/OverspeedSelectedVehicles";
// import DriverBehaviorAlertView from "./alert/driverBehavior/DriverBehaviorAlert";
import UnpluggedDeviceAlertSelectRecipient from "./alert/unpluggedDevice/UnpluggedDeviceAlertSelectRecipient";
import VehicleCheckedDefectReport from "./report/vehicleDefectReport/VehicleCheckedDefectReport"


let tabView = {
  'view/proximity/Proximity': Proximity,
  'view/report/location/LocationReportPage': LocationReportPageView,
  'view/alert/AvailableSelectedTableGrid': AvailableSelectedTableGridView,
  'view/alert/SelectRecipient': SelectRecipientView,
  'view/alert/SelectProfile': SelectProfileView,
  'view/alert/outOfHours/OutOfHoursSelectedVehicles': OutOfHoursSelectedVehiclesView,
  'view/alert/outOfHours/TimeRange': TimeRangeProfileBuilderFormView,
  'view/alert/driverBehavior/DriverBehaviorSelectedVehicles' : DriverBehaviorSelectedVehiclesView,
  'view/alert/overspeed/OverspeedSelectedVehicles' : OverspeedSelectedVehicles,
  'view/alert/unpluggedDevice/UnpluggedDeviceAlertSelectRecipient' : UnpluggedDeviceAlertSelectRecipient,
  'view/report/vehicleDefectReport/VehicleCheckedDefectReport' : VehicleCheckedDefectReport
}

const TabsetView = View.extend({
  model: Tabset,
  mergeEvents: true,
  events: {
    'click [data-tabset-control]:first [data-target]': 'switchTabHandler'
  },
  constructor: function (option) {
    var view = this
    view.$el = $(option.el)
    view.model = new view.model()


    if (option.lazyTabView) {
      Object.keys(option.lazyTabView).forEach(tabName => {
        tabView[tabName] = option.lazyTabView[tabName]
      })
    }

    option.beforeInitTabCallback && option.beforeInitTabCallback(view, view.model)

    view
      .listenTo(view.model, 'change:activeTab', function (tabsetModel, activeTab) {
        view.switchTab(tabsetModel, activeTab)
        if (activeTab === "unpluggedDevice") {
          view.initTab("selectRecipient")
        }
        view.initTab(activeTab)
      })
      .model
      .set('activeTab', view.$el.find('[data-target]:first').attr('data-target'))

    option.afterInitTabCallback && option.afterInitTabCallback(view, view.model)

    View.apply(this, arguments)
  },
  initTab: function (activeTab) {
    var view = this
    var $activeTab = view.$el.find('[data-tab=' + activeTab + ']')

    if (!(activeTab in view)) {
      const InnerView = tabView[$activeTab.data('view')]

      console.log($activeTab.data('view'))

      InnerView.prototype.parentView = view
      view[activeTab] = {
        innerView: new InnerView({
          el: $activeTab
        })
      }
      view.trigger('initTab', view[activeTab], activeTab)
    }
  },
  getActiveTabView: function () {
    return this[this.model.get('activeTab')]
  },
  get$ActiveTab: function () {
    return this.$el.find('[data-tab=' + this.model.get('activeTab') + ']')
  },
  switchTab: function (tabsetModel, activeTab) {
    this
      .switchClassActive(this.$el, '[data-tab=' + activeTab + ']')
      .switchClassActive(this.$el, '[data-target=' + activeTab + ']')
    return this
  },
  switchClassActive: function ($el, selector) {
    $el
      .find(selector)
      .addClass('active')
      .siblings()
      .removeClass('active')
    return this
  },
  switchTabHandler: function (e) {
    this.model.set('activeTab', e.currentTarget.getAttribute('data-target'))
  },
  setTabView: function (tabName) {
    // this[tabName]
  }
})

export default TabsetView
