import $ from 'jquery'
import PopupView from '../Popup'
import LiveVideoPlayerView from '../LiveVideoPlayer'
import Model from '../../model/Model'

const LiveVideoPlayerPopup = PopupView.extend({
  url: 'js/html/app/src/template/liveVideoPlayerPopup.html',
  activityLogId: null,
  mergeEvents: true,
  constructor: function () {
    this.model = Model.extend({
      defaults: {
        visible: false,
        lockAnimation: false,
        htmlReady: false,
        isDraggable: true
      }
    })

    PopupView.apply(this, arguments)
  },
  initialize: function (option) {
    var view = this
    view.addSubView('liveVideoPlayer', LiveVideoPlayerView, { el: this.$el.find('.vp-container')[0] })
  },
  closeBtnHandler: function () {
    var view = this

    view.hide()
  },
  hide: function () {
    if (!this.isVisible()) {
      return
    }

    PopupView.prototype.hide.apply(this, arguments)
    this.deactivatePlayer()
  },
  deactivatePlayer: function () {
    var player = this.findSubView('liveVideoPlayer')

    player.dispose()
  },
  setVideoProps: function (props) {
    var $title = this.$el.find('.header-panel .title')
    $title.find('span:first').text(props.regNumber)
    this.findSubView('liveVideoPlayer').setProps(props)
  },
  align: function () {
    const popup = this.$el

    popup.css('top', ($(window).height() / 2) - (popup.outerHeight() / 2))
    popup.css('left', ($(window).width() / 2) - (popup.outerWidth() / 2))
  }
})

export default LiveVideoPlayerPopup
