import $ from 'jquery'
import PopupView from '../view/Popup'

const NotificationPopupView = PopupView.extend({ // singleton
  url: 'template/notificationPopup.html',
  mergeEvents: true,
  events: {
    'click .button': 'resolveUserAction'
  },
  initialize: function (option) {
    var view = this
    this.createTemplate(view.el)
    this.on('answer', function () {
      this.model.set('visible', false)
    })
    $(window).resize(function () {
      view.align()
    })
  },
  loadHTML: function (url, callback) { // override
    var view = this
    view.templateUrl = url

    this.loadTemplate(function () {
      view.$el = $(view.somaTemplate)
      view.el = view.$el[0]
      view.somaTemplate = view.$el.html()
      $(document).ready(() => {
        $('#content').append(view.$el)
        view.render()
        view.model.set('htmlReady', true)
        if (callback) callback()
      })
    })
    return this
  },
  render: function (json) {
    if (json) {
      this.somaTemplate.scope.json = json
      this.somaTemplate.render()
      this.model.set('visible', true)
      this.align()
    }
    return this
  },
  align: function () {
    var popup = this.$el.find('.notification-popup')
    PopupView.prototype.align.apply(this, [popup])
  },
  resolveUserAction: function (e) {
    var actionName
    var buttonCount = this.$el.find('.button').size()
    var $currButton = $(e.currentTarget)
    var message = this.somaTemplate.scope.json

    if ($currButton.is(':last-child') && buttonCount === 2) {
      actionName = 'ok'
    } else if (buttonCount === 2) {
      actionName = 'cancel'
    }
    if ($currButton.is(':first-child') && buttonCount === 1) {
      actionName = 'ok'
    }
    this.trigger(actionName, message)
    this.trigger('answer', actionName)
  }
})

export default new NotificationPopupView()
