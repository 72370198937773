import ReportPopupView from '../ReportPopup'
import DigitalInputReportFormView from './DigitalInputReportForm'

const DigitalInputReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/DigitalInput.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', DigitalInputReportFormView)
  }
})

export default DigitalInputReportPopupView
