/**
 * Created by George on 26.03.2015.
 */
import Collection from '../../Collection';
import DriverBehaviorAlert from '../../../model/alert/driverBehavior/DriverBehaviorAlert';

const DriverBehaviorAlertList = Collection.extend( {
    model : DriverBehaviorAlert,
    url : "/services/driver-behaviour/alert"
})

export default DriverBehaviorAlertList;
