import Backbone from 'backbone'
import $ from 'jquery'
import CompactAccountReportFormView from '../../report/CompactAccountReportForm'
import GeoMonitorFormModel from '../../../model/alert/geoMonitor/GeoMonitorForm'

const GeoMonitorFormView = CompactAccountReportFormView.extend({
  model: GeoMonitorFormModel,
  mergeEvents: true,
  parseDateToMs: function (date) {
    var year = date[2].split(/[\ ]/)[0]
    var time = date[2].split(/[\ ]/)[1]
    var parsedDateToMs = new Date(year + '-' + date[1] + '-' + date[0] + ' ' + time).getTime()

    return parsedDateToMs
  },
  parseFromTo: function () {
    var view = this
    var model = view.model
    var from = model.get('from').split(/[\.]/)
    var fromDate = view.parseDateToMs(from)
    var to = model.get('to').split(/[\.]/)
    var toDate = view.parseDateToMs(to)

    model.set('from', fromDate)
    model.set('to', toDate)
  },
  submit: function (e) { // override
    var view = this
    var tableGrid = view.getParentView().findSubView('compactReportTableGrid')
    var model = view.model

    view.parseFromTo()

    Backbone.sync('read', model, {
      data: $.param(model.toJSON()),
      success: function (collection) {
        tableGrid.model.set('tableRow', collection)
        tableGrid.render()
        if (view.getParentView().$el.css('visibility') === 'hidden') {
          view.getParentView().show() // show report
        }
      }
    })
  },
  generatePdfXlsReportURL: function (repFormat) { // implementation
    var view = this
    var model = view.model
    var accountId = $.cookie('lastViewedAccount')

    view.syncData()
    view.parseFromTo()

    var data = {
      'report-name': 'GeoMonitor',
      'report-type': 'GeoMonitor',
      'report-format': repFormat,
      startPeriod: model.get('from'),
      endPeriod: model.get('to'),
      accountId: accountId
    }

    return $.param(data)
  }
})

export default GeoMonitorFormView
