import SelectionMenuVehicleModel from '../../SelectionMenuVehicle'

const SpeedingReportSelectionMenuVehicleModel = SelectionMenuVehicleModel.extend({
  defaults: {
    id: null,
    accelAvailable: false,
    activeDinsCount: null,
    alwaysDisplayDriverName: false,
    aoiPoiDescr: '',
    boxTypeId: null,
    cabPhone: '',
    clientDescr: '',
    clientInfo: '',
    costPerMile: null,
    infoLine2: null,
    coupled: false,
    coupledVehicleRegNumber: null,
    curAddress: null,
    digitalInput1Info: null,
    digitalInput2Info: null,
    digitalInput3Info: null,
    digitalInput4Info: null,
    directionOfTravel: '',
    distanceToRoad: null,
    dotOnMap: false,
    expanded: false,
    factoredSpeed: null,
    fleetId: null,
    group: null,
    ignitionActive: false,
    ignitionColor: '',
    ignitionLabel: '',
    ignitionStatus: '',
    ignitionStatusVisibility: false,
    imageFileName: '',
    immobilizationDate: null,
    immobilizationNumber: null,
    immobilizeStatus: false,
    inStealthMode: false,
    incidentHappened: false,
    lastJourneyMpg: null,
    lat: null,
    locked: false,
    lon: null,
    plant: false,
    registrationNumber: '',
    renewalDate: null,
    replicationStatus: false,
    roadLat: null,
    roadLon: null,
    selected: false,
    subGroup: null,
    tag: false,
    tractor: false,
    trailerDevice: false,
    typeDescr: '',
    vehicleColour: '',
    infoLine1: null,
    vehicleType: '',
    vehicleVariant: ''
  }
})

export default SpeedingReportSelectionMenuVehicleModel
