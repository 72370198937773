import $ from 'jquery'
import ReportFormView from '../ReportForm'
import ReportFormModel from '../../../model/report/ReportForm'
import SelectView from '../../Select'
import CounterView from '../../Counter'
import ReportDriverSelectView from './ReportDriverSelect'

const JourneyReportFormView = ReportFormView.extend({
  model: ReportFormModel,
  initialize: function (option) {
    var view = this

    view.addSubView('reportDriversSelect', ReportDriverSelectView, { el: view.$('[data-select=reportDrivers]') })
    view.addSubView('select', SelectView, { el: view.$('[data-select=operation]') })
    view.addSubView('speedCounter', CounterView, { el: view.$('[data-counter=speed]') })
    view.addSubView('overspeedPercantageCounter', CounterView, { el: view.$('[data-counter=overspeedPercantage]') })
  },
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'Journey',
      'report-type': 'Journey',
      'report-format': model.get('repFormat'),
      alternate: false,
      excludeWeekends: model.get('excludeWeekends'),
      selectedDrivers: model.get('driverIdList').replace(/,+/g, '/'),
      driverFilter: model.get('groupJourneysByDriver'),
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/')
    }
    return $.param(data)
  },
  destroy: function () {
    var view = this

    view.findSubView('reportDriversSelect').destroy()
    view.findSubView('select').destroy()
    view.findSubView('speedCounter').destroy()
    view.findSubView('overspeedPercantageCounter').destroy()
    ReportFormView.prototype.destroy.apply(this, arguments)
  }
})

export default JourneyReportFormView
