import SelectView from '../../Select'
import LeagueTableDateListView from './LeagueTableDateList'

const LeagueTableDateSelectView = SelectView.extend({
  mergeEvents: true,
  events: {
    selectmenuchange: 'selectChange'
  },
  initialize: function () {
    this.addSubView('dateList', LeagueTableDateListView, { el: this.el })
    SelectView.prototype.initialize.apply(this, arguments)
  },
  selectChange: function (e, ui) {
    var view = this
    var leagueTableReport = view.getParentView()
    var compactReportForm = leagueTableReport.findSubView('compactReportForm')
    var dateParse = ui.item.value.split(' ')

    compactReportForm.model.set('month', dateParse[1])
    compactReportForm.model.set('year', dateParse[0])

    leagueTableReport.hideActivePopups()
    compactReportForm.submit()
  },
  reset: function () {
    var view = this

    view.el.value = 'none'
    view.$el.selectmenu('refresh')
  }
})

export default LeagueTableDateSelectView
