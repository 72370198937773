import $ from 'jquery'
import View from '../View'
import LocationSearchModel from '../../model/LocationSearch'

const ProximityLocationSearchView = View.extend({
  model: LocationSearchModel,
  elSelector: '.proximity__location-search',
  view: null,
  constructor: function (option) {
    var view = ProximityLocationSearchView.prototype.view = this

    view.model = new view.model()
    view.$el = view.getParentView().$(view.elSelector)
    // ProximityLocationSearchView.prototype.view = view
    //            view.listenTo(view.model,'change',view.render)
    View.apply(this, arguments)
  },
  initialize: function () {
    var view = this
    var $el = this.$el

    var searchInput = $el.find('.proximity-geocode')
    var searchButton = $el.find('.button')
    var formDropdown = $el.find('.dropdown')
    var mapCanvas = window.mapCanvas

    searchButton.click(function (e) {
      e.preventDefault()
      if ($.trim(searchInput.val())) {
        mapCanvas.geocode(searchInput.val(), view.geocodeCallback)
      }
    })

    $(document).off('click.search').on('click.search', function (e) {
      if (!$(e.target).closest('.search').size()) {
        formDropdown.hide()
      }
    })

    view.render()
  },

  geocodeCallback: function (result) {
    var view = ProximityLocationSearchView.prototype.view
    var $el = view.$el
    var len = result.length
    var formDropdown = $el.find('.dropdown')
    var htmlGroupTabsAllowed = window.settings.data.htmlGroupTabsAllowed
    var leftPanel = view.getParentView().$el.find('.left-panel')
    var options = ''
    var proximityTab = view.getParentView()

    if (!result || len === 0) {
      alert('There were no locations found')
      return
    }

    proximityTab.resetPin()

    for (var i = 0; i < len; i++) {
      options += '<li data-id="' + i + '">' + result[i].description + '</li>'
    }

    options = $(options)
    formDropdown.find('ul').html(options)
    formDropdown.width(formDropdown.parent().outerWidth())

    function clickHandler (e) {
      $('.left-panel.proximity').show()
      var redMarker = new Image()
      var markerData = e.data.markerData
      var pin = proximityTab.model.get('pin')

      formDropdown.parent().find('input:text').val(markerData.description)
      redMarker.src = 'img/markers/marker_red.png'

      if (pin) {
        proximityTab.resetPin()
      }
      // addTextMarkerSimple : function(img, text, lon, lat, showTooltip, returnCallback)
      window.mapCanvas.addTextMarkerSimple(redMarker, 'Chosen Location', markerData.lon, markerData.lat, true,
        function (result) {
          pin = result
          proximityTab.model.set('pin', pin)
          pin.remove = function () {
            window.mapCanvas.removeMarker(this)
          }

          pin.lon = markerData.lon
          pin.lat = markerData.lat
          formDropdown.hide()

          if (htmlGroupTabsAllowed) {
            if (leftPanel.find('.right-col .vehicle-holder').is(':hidden')) {
              leftPanel.find('.proximity-view .right-col .vehicle-holder').show()
              leftPanel.find('.proximity-view .control-panel,.control-panel+.row').show()
              window.groupTabsetView.renderScroll()
            }
          } else {
            if (leftPanel.find('.right-col .vehicle-holder').css('visibility') === 'hidden') {
              leftPanel.find('.right-col .vehicle-holder').css('visibility', 'visible')
              leftPanel.find('.control-panel,.control-panel+.row').show()
            }
          }
          if (window.searchVehicle) {
            leftPanel.find('.proximity-view .vehicle-search').hide()
          }
          window.pin = pin
          proximityTab.getParentView().findSubView('selectionMenu').showDistances(pin)

          setTimeout(window.rezoomMapFlx, 1500)
          leftPanel.find('.proximity-view .right-col').removeClass('hide-shadow')
        })
      // pin = mapCanvas.addTextMarker(redMarker, 'Chosen Location', markerData.lon, markerData.lat,null, null, null, false,'',true, '',false, false,false)
    }
    for (var i = 0; i < len; i++) {
      options.eq(i).on('click', { markerData: result[options[i].getAttribute('data-id')] }, clickHandler)
    }
    options.removeAttr('data-id')
    formDropdown.show()
    proximityTab.getParentView().findSubView('selectionMenu').resetSelection()

    setTimeout(window.rezoomMapFlx, 1500)
  }

})

export default ProximityLocationSearchView
