import TableGridModel from '../../TableGrid@'
import PoiList from '../../../collection/alert/geoZones/PoiList'

const PoiListTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: false,
    tableHead: [{
      tableHeadName: 'Id',
      field: 'id',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        }
      ]
    },
    {
      tableHeadName: 'POI Category',
      field: 'categoryDtoDescr',
      noDrop: true
    },
    {
      tableHeadName: 'Name',
      field: 'description',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'description',
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'string'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Lat',
      field: 'lat',
      noDrop: true
    },
    {
      tableHeadName: 'Lon',
      field: 'lon',
      noDrop: true
    },
    {
      tableHeadName: 'Radius',
      field: 'radius',
      noDrop: true
    },
    {
      tableHeadName: 'Account',
      field: 'accountDescr',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'accountDescr',
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'string'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Status',
      field: 'status',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          dataButton: 'sortAsc',
          iconClass: 'asc'
        },
        {
          optionName: 'Sort Descending',
          dataButton: 'sortDesc',
          iconClass: 'desc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'status',
                select: {
                  name: 'filterAndValue',
                  filterFn: 'equal',
                  dataType: 'string',
                  options: [
                    {
                      option: 'Active',
                      value: 'Active'
                    },
                    {
                      option: 'Inactive',
                      value: 'Inactive'
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Activate/Deactivate',
      field: 'statusAction',
      noDrop: true
    }],
    tableRow: PoiList
  }
})

export default PoiListTableGridModel
