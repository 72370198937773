import CommentFormView from '../../CommentForm'
import VehicleDefectCommentModel from '../../../model/report/vehicleDefectReport/VehicleDefectComment'

const VehicleDefectReportCommentFormView = CommentFormView.extend({
  model: VehicleDefectCommentModel,
  elSelector: '[data-form=comment]',
  initialize: function () {
    var view = this
  },
  syncData: function () {
    var view = this

    view.model.set(view.getFormData())
    view.model.unset('createdAt')
    view.model.unset('user')
    view.model.set('defectId', view.getParentView().getParentView().getActiveRowId())
  },
  destroy: function () {
    var view = this

    view.off('save')
    view.remove()
  }
})

export default VehicleDefectReportCommentFormView
