import AlertListPopupView from '../AlertListPopup'
import OutOfHoursAlertTableGridView from './OutOfHoursAlertTableGrid'
import OutOfHoursProfileBuilderPopupModel from '../../../model/alert/outOfHours/OutOfHoursProfileBuilderPopup'

const OutOfHoursAlertListPopupView = AlertListPopupView.extend({
    model: OutOfHoursProfileBuilderPopupModel,
    AlertTableGridView: OutOfHoursAlertTableGridView
})

export default OutOfHoursAlertListPopupView
