import PopupView from '../../Popup'
import NotificationsFormView from './NotificationsForm'

const NotificationsPopupView = PopupView.extend({
  url: 'js/html/app/src/template/report/vehicleDefectReport/notificationsPopup.html',
  initialize: function () {
    this.addSubView('notificationsForm', NotificationsFormView)
    this.$('a').attr('href', 'mailto:' + window.userPrefs.email)
  },
  renderForm: function () {
    var view = this

    view.findSubView('notificationsForm').render()
  },
  destroy: function () {
    var view = this

    view.findSubView('notificationsForm').destroy()
    view.remove()
  }
})

export default NotificationsPopupView
