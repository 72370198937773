import $ from 'jquery'
import _ from 'underscore'
import View from '../../View'
import AlertListPopupView from '../AlertListPopup'
import TabsetView from '../../Tabset'
import GeoFenceAlert from '../../../model/alert/geofence/GeoFenceAlert'
import AlertPanel from '../../../model/alert/AlertPanel'
import GeoFenceAlertListPopupView from './GeoFenceAlertListPopup'
import GeoFenceAlertFormView from './GeoFenceAlertForm'

const GeoFenceAlertView = View.extend({
  model: new AlertPanel(),
  events: {
    'click [data-button=alert-list]': 'beforeShowAlertList'
  },
  tabData: {},
  initialize: function (option) {
    var view = this

    view.AlertListPopupView = GeoFenceAlertListPopupView // use addSubView
    view.tabsetName = 'geoFenceAlert'
    view.$el = $('[data-tab=geoFenceAlert]')
    view.el = this.$el[0]
    view.addSubView('alertForm', GeoFenceAlertFormView)

    view
      .initAlertListPopupView()
      .initGeofenceTabset()
      .bindAlertListTableGridItemSelect()

    view.listenTo(this.model, 'change:dataMode', function (model, dataMode) {
      var activeTabView = view.tabsetView.getActiveTabView()

      view.switchTitleHandler(model)
      activeTabView.dataMode = dataMode
    })
    view.listenTo(this.model, 'change:alertId', function (model, alertId) {
      var activeTabName = view.tabsetView.model.get('activeTab')

      view.tabData[activeTabName].alertId = alertId
      view.updateAllTabs(alertId)
    })
  },
  updateAllTabs: function (alertId) {
    var tabsetView = this.tabsetView
    var tabList = [tabsetView.SelectPoi.innerView]

    tabsetView.selectAoi && tabList.push(tabsetView.selectAoi.innerView)
    tabsetView.selectVehicle && tabList.push(tabsetView.selectVehicle.innerView)

    _.forEach(tabList, function (tab, i) {
      tab.availableTableGridView.collection.id = alertId
      tab.fetchProfileList().done(function () {
        tab.render()
      })
    })
  },
  initAlertListPopupView: function () {
    var view = this

    view.alertListPopupView = view.addSubView('alertListPopup', this.AlertListPopupView)
    return view
  },
  initGeofenceTabset: function () {
    var view = this
    this.tabsetView = new TabsetView({
      el: this.$el.find('[data-tabset=geoFenceAlert]'),
      beforeInitTabCallback: (tabsetView, tabsetModel) => {
        tabsetView.on('initTab', function (tabView, activeTabName) {
          tabView.innerView.on('beforeFetch', $.proxy(view.syncCollectionId, view))
          if (!tabView.innerView.isReady) {
            tabView.innerView.on('ready', function () {
              view.changeActiveTabHandler(tabView, activeTabName)
            })
          } else {
            view.changeActiveTabHandler(tabView, activeTabName)
          }
        })
      },
      afterInitTabCallback: (tabsetView, tabsetModel) => {
        tabsetView.listenTo(tabsetModel, 'change:activeTab', function (tabsetModel, activeTabName) {
          var tabData = view.tabData[activeTabName]
          if (this.getActiveTabView() && this.getActiveTabView().innerView.availableTableGridView) {
            this.getActiveTabView().innerView.availableTableGridView.render().scroll.render()
            this.getActiveTabView().innerView.selectedTableGridView.render().scroll.render()
          }
          if (!tabData || tabData.alertId === view.model.get('alertId') && tabData.dataMode === view.model.get('dataMode')) {
            return
          }
          view.changeActiveTabHandler(this.getActiveTabView(), activeTabName)
        })
      }
    })
    return this
  },
  syncCollectionId: function (collection) {
    collection.id = this.model.get('alertId')
  },
  changeActiveTabHandler: function (activeTabView, activeTabName) {
    var view = this
    var alertListTableGridView = view.alertListPopupView.alertListTableGridView
    var id = view.model.get('alertId')
    var isSelectRecipient = !activeTabView.innerView.availableTableGridView
    var selectedList, recipientTableGridView

    if (view.model.get('dataMode') === 'edit') {
      id = alertListTableGridView.getActiveRowId()

      if (!isSelectRecipient) {
        selectedList = alertListTableGridView.model.get('tableRow').get(id).get(view.tabsetView.get$ActiveTab().data('selected-list'))
        activeTabView.innerView.availableTableGridCollection.id = id
      } else {
        selectedList = alertListTableGridView.model.get('tableRow').get(id).get('selectedAlertRecipientList')
      }
    } else {
      if (!isSelectRecipient) {
        activeTabView.innerView.availableTableGridCollection.id = null
      } else {
        selectedList = []
      }
    }

    view.tabData[activeTabName] = {
      alertId: view.model.get('alertId'),
      dataMode: view.model.get('dataMode')
    }

    if (!isSelectRecipient) {
      activeTabView.innerView.reloadContent(selectedList, true)
    } else {
      recipientTableGridView = activeTabView.innerView.findSubView('selectRecipientTableGrid')
      if (!recipientTableGridView.isReady) {
        recipientTableGridView.on('ready', function () {
          this.renderSelectedList(selectedList)
        })
      } else {
        recipientTableGridView.renderSelectedList(selectedList)
      }
    }
  },
  bindAlertListTableGridItemSelect: function () {
    var view = this

    view.alertListPopupView.on('ready', function () {
      this.alertListTableGridView.on('itemSelect', function ($tr, id) {
        var activeTabView = view.tabsetView.getActiveTabView()
        var selectedList

        view.model.set('alertId', id)
        selectedList = this.model.get('tableRow').get(id).get(view.tabsetView.get$ActiveTab().data('selected-list'))

        if ($tr.is('.active')) {
          view.model.set('dataMode', 'edit')
          view.$el.find('input[name=name]').val(this.model.get('tableRow').get(id).get('name'))
          view.$el.find('input[name=sendToSelf]').prop('checked', this.model.get('tableRow').get(id).get('sendToSelf'))

          if (activeTabView.innerView.availableTableGridCollection) {
            activeTabView.innerView.availableTableGridCollection.id = view.model.get('alertId')
            activeTabView.innerView.reloadContent(selectedList, true)
          } else {
            activeTabView.innerView.findSubView('selectRecipientTableGrid').renderSelectedList(selectedList)
          }
        } else {
          view.model.set('dataMode', 'create')
          view.$el.find('input[name=name]').val('')
          if (activeTabView.innerView.availableTableGridCollection) {
            activeTabView.innerView.availableTableGridCollection.id = null
            activeTabView.innerView.reloadContent()
          } else {
            activeTabView.innerView.findSubView('selectRecipientTableGrid').renderSelectedList()
          }
        }
      })
    })
    return view
  },
  showAlertList: function () {
    this.alertListPopupView.model.set('visible', true)
    this.alertListPopupView.alignBy(this.$el.find('[data-button=alert-list]'), 'topRight', { top: 0, left: 5 })
  },
  beforeShowAlertList: function () {
    if (this.alertListPopupView.model.get('htmlReady')) {
      this.showAlertList()
    }
  },
  switchTitle: function (title) {
    this.model.set('dataMode', title)
  },
  switchTitleHandler: function (model) {
    var view = this

    this.$el.find('[data-title=' + model.get('dataMode') + ']').show().siblings('[data-title]').hide()
  }
})

export default GeoFenceAlertView
