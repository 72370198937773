import TableGridModel from '../../TableGrid@'
import VehicleDefectList from '../../../collection/report/vehicleDefectReport/VehicleDefectList'

const VehicleDefectReportTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: false,
    tableHead: [{
      tableHeadName: 'Defect No.',
      field: 'defectId'
    },
    {
      tableHeadName: 'Vehicle Reg.',
      field: 'vehReg'
    },
    {
      tableHeadName: 'Fleet ID',
      field: 'fleetId'
    },
    {
      tableHeadName: 'Driver Name',
      field: 'driverName'
    },
    {
      tableHeadName: 'Date | Time',
      field: 'date',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        }
      ]
    },
    {
      tableHeadName: 'Location',
      field: 'location'
    },
    {
      tableHeadName: 'Postcode',
      field: 'postcode'
    },
    {
      tableHeadName: 'POI/AOI',
      field: 'poiAoi'
    },
    {
      tableHeadName: 'Odometer',
      field: 'odometer'
    },
    {
      tableHeadName: 'Notes',
      noDrop: true
    },
    {
      tableHeadName: 'Defect',
      field: 'defect'
    },
    {
      tableHeadName: 'Read By',
      field: 'readBy'
    },
    {
      tableHeadName: 'View',
      noDrop: true
    },
    {
      tableHeadName: 'Comment',
      noDrop: true
    },
    {
      tableHeadName: 'Status',
      field: 'status'
    }],
    tableRow: VehicleDefectList
  }
})

export default VehicleDefectReportTableGridModel
