import $ from 'jquery'
import PopupView from '../Popup'
import ActivityLogFormView from './ActivityLogForm'
import ActivityLogTableGridView from './ActivityLogTableGrid'
import ActivityLogPopupModel from '../../model/modalPanel/ActivityLogPopup'

const ActivityLogPopupView = PopupView.extend({
  templateUrl: 'template/modalPanel/activityLogPopup.html',
  url: 'js/html/app/src/template/modalPanel/activityLogPopup.html',
  model: ActivityLogPopupModel,
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'closeHandler',
    'click [data-button=save]': 'submit',
    'click .plot-all :checkbox': 'plotAll',
    'click .max-min_btn': 'maxMin'
  },

  initialize: function (closeCallback) {
    var view = this
    console.log('initialize()')

    this.plotModeOn = false
    if (closeCallback) {
      this.closeCallback = closeCallback
    }

    $('#content').append(view.$el)
    this.somaTemplate = view.$el.html()
    this.createTemplate(view.el)

    // set $.cookie('timestamp', Date.now())
    // get $.cookie('lastViewedGroup')

    view.addSubView('compactReportTableGrid', ActivityLogTableGridView, this.$('[data-table-grid=activityLog]')).once('ready', function () {
      view.addSubView('activityLogForm', ActivityLogFormView)
    })
    view.render({
      registrationNumber: window.wls.getLastClickedVehicle().data.registrationNumber,
      extended: window.wls.getLastClickedVehicle().data.digitalInput2Name != null
        && window.wls.getLastClickedVehicle().data.digitalInput2Name === 'CRANE' ? 'with-din' : ''
    })
    if (window.settings.data.nextGenArrowsAllowed) {
      if (window.wlsMapTools.traceButtonActive()) {
        window.mapCanvas.enableClusteringTraces()
      }
      if (window.wlsMapTools.clusteringButtonAcive()) {
        window.mapCanvas.enableClustering()
      }
    }
  },
  closeBtnHandler: function () { // override
    var view = this

    this.destroy()
    if (this.closeCallback) {
      this.closeCallback()
    }
  },
  maxMin: function () {
    var view = this

    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.$el.animate({ top: view.model.get('position').top, left: view.model.get('position').left }, 300)
      view.maximiseIncidentDetails()
    } else {
      view.model.set('position', { top: view.$el[0].offsetTop, left: view.$el[0].offsetLeft })
      view.minimiseIncidentDetails()
      view.$el.animate({ top: window.innerHeight * 0.96 + 'px', left: '260px' }, 300)
    }
  },
  maximiseIncidentDetails: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.find('.info-holder').show()
    view.$el.find('.control-holder').show()
    view.$el.css('height', 'auto')
  },
  minimiseIncidentDetails: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.find('.info-holder').hide()
    view.$el.find('.control-holder').hide()
    view.$el.css('height', 'auto')
  },
  destroy: function () {
    var view = this
    this.clearMap()
    view.findSubView('compactReportTableGrid').destroy()
    view.remove()
  },

  isVisible: function () {
    return this.$el && this.$el.is(':visible')
  },

  render: function (json) {
    console.log('render()')
    if (json) {
      this.somaTemplate.scope.json = json
      this.somaTemplate.render()
      this.model.set('visible', true)
    } else {
      PopupView.prototype.render.apply(this, arguments)
    }
    if ($(window).width() <= 1280) {
      var left = ($(window).width() / 2) - (1024 / 2)
      this.moveTo(46, left)
    }
    return this
  },

  plotAll: function () {
    var view = this
    console.log('plotAll()')
    if (this.plotModeOn) {
      view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
      view.findSubView('compactReportTableGrid').logsOnMap = []
      view.findSubView('compactReportTableGrid').markAllActive(false)
      this.plotModeOn = false
    } else {
      view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
      view.findSubView('compactReportTableGrid').plotAll()
      view.findSubView('compactReportTableGrid').markAllActive(true)
      this.plotModeOn = true
    }
  },

  clearMap: function () {
    var view = this
    view.findSubView('compactReportTableGrid').removeSnailTrailFromMap()
  },

  setPlotAllActive: function (active) {
    var view = this

    if (active) {
      view.$('.plot-all').removeClass('disabled')
    } else {
      view.$('.plot-all').addClass('disabled')
    }
  }
})

export default ActivityLogPopupView
