import FormView from '../../Form'
import OutOfHoursAlertModel from '../../../model/alert/outOfHours/OutOfHoursAlert'
import NotificationPopupView from '../../NotificationPopup'

const outOfHoursAlertFormView = FormView.extend({
    model: OutOfHoursAlertModel,
    mergeEvents: true,
    events: {
        "change input[name=sendToSelf]": "sendToSelfChange"
    },
    constructor: function(){
        var outOfHoursAlert = this.parentView;
        this.el = outOfHoursAlert.$el.find("form");
        FormView.apply(this,arguments);
    },
    initialize: function(){
        var view = this,
            outOfHoursAlertView = this.parentView,
            outOfHoursAlertTabsetView,
            outOfHoursAlertListPopup,
            outOfHoursAlertTableGridView;

        view.listenTo(view.model,"change:sendToSelf",function(model,sendToSelf){
            view.renderSendToSelf(sendToSelf);
        });
        outOfHoursAlertView.on("ready",function(){
            outOfHoursAlertTabsetView = outOfHoursAlertView.tabsetView;
            outOfHoursAlertListPopup = outOfHoursAlertView.findSubView("alertListPopup");
            outOfHoursAlertTableGridView = outOfHoursAlertListPopup.findSubView("alertTableGrid");

            view.on("cancel",function(){
                var selectProfile;
                var selectRecipientTableGridView;

                view.getParentView().switchTitle("create");
                outOfHoursAlertTableGridView.resetSelectedItemAndRow();
                if("selectProfile" in outOfHoursAlertTabsetView){
                    selectProfile = outOfHoursAlertTabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid");
                    selectProfile.reloadContent();
                }
                if("selectRecipient" in outOfHoursAlertTabsetView){
                    selectRecipientTableGridView = outOfHoursAlertTabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid");
                    selectRecipientTableGridView.reset();
                }
            });
            view.on("save",function(){
                outOfHoursAlertTableGridView.model.get('tableRow').reset();
                if(outOfHoursAlertListPopup.isVisible()){
                    outOfHoursAlertTableGridView.fetchAlertlist();
                }
                view.cancel();
                NotificationPopupView.render({
                    msgId: "successfully_saved",
                    title: "",
                    message: "Successfully saved.",
                    button: ["Ok"]
                });
            });
            view.model.on("invalid", function(model, error) {
                NotificationPopupView.render(error);
            });
        });
    },
    sendToSelfChange: function(e){
        var $checkbox = $(e.currentTarget);

        this.model.set("sendToSelf",$checkbox.prop("checked"),{silent: true});
    },
    renderSendToSelf: function(){
        this.$el.find("input[name=sendToSelf]").prop("checked", this.model.get("sendToSelf"));
    },
    cancel: function(){
        this.getParentView().model.set({dataMode: "create"});
        this.getParentView().tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.id = 0;
        this.reset();
        this.trigger("cancel");
    },
    syncData: function(){
        var outOfHoursAlertView = this.getParentView();
        var outOfHoursAlertTabsetView = outOfHoursAlertView.tabsetView;


        if("selectProfile" in outOfHoursAlertTabsetView){
            this.model.set("selectedAlertProfileList",outOfHoursAlertTabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").selectedTableGridCollection.toJSON());
        }
        if("selectRecipient" in outOfHoursAlertTabsetView){
            this.model.set("selectedAlertRecipientList",outOfHoursAlertTabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").model.get('tableRow').toJSON());
        }
        this.model.set(this.getFormData());
        if(outOfHoursAlertView.model.get("dataMode") == "edit"){
            this.model.set("id",outOfHoursAlertView.model.get("alertId"));
            this.model.set("active", outOfHoursAlertView.alertListPopupView.findSubView("alertTableGrid").model.get('tableRow').get(this.model).get("active"));
        }else{
            outOfHoursAlertView.model.set("alertId", null);
            this.model.set("id", null);
        }
    }
})

export default outOfHoursAlertFormView
