import ProfileBuilderFormModel from '../profileManager/ProfileBuilderForm'

const OutOfHoursProfileBuilderFormModel = ProfileBuilderFormModel.extend({
    url: function(){
        var id = this.get('id') || '';
        return '/api/out-of-hours/profile/' + id;
    },
    defaults: {
        // overspeed: 0,
        journeyBeganInside: false,
        journeyBeganOutside: false,
        journeysMetres: 10,
        journeysMinutes: 10,
        weekMap: "0=9:00:17:30|1=9:00:17:30|2=9:00:17:30|3=9:00:17:30|4=9:00:17:30|5=9:00:17:30|6=9:00:17:30",
        // weekMap: "0=00:00:23:59|1=|2=10:00:17:30|3=7:00:17:30|4=9:00:17:30|5=11:00:19:30|6=13:00:20:30",
        alerts: [],
        vehicles: []
    },
    validate: function(attrs, options){
        var message = {
            wrong_profile_name_format: "Please verify the Profile Name format.",
            // wrong_max_permitted_speed: "Please verify the Maximum Permitted Speed Name format.",
            wrong_selected_vehicle_count: "Please select at least one vehicle."
        };
        var json = {
            button: ["OK"]
        };

        if (ProfileBuilderFormModel.prototype.validate.apply(this,[attrs, options, json, message])) {
            return json;
        }

        // if(isNaN(parseFloat(attrs.overspeed)) || parseFloat(attrs.overspeed) < 0){
        //     json.msgId = "wrong_max_permitted_speed";
        //     json.title = "Maximum permitted speed verification";
        //     json.message = message[json.msgId];
        //     return json;
        // }
    }
})

export default OutOfHoursProfileBuilderFormModel
