import SelectView from '../../Select'
import ReportDriverListView from './ReportDriverList'

const ReportDriverSelectView = SelectView.extend({
  events: {
    selectmenuchange: 'selectChange'
  },
  initialize: function () {
    this.addSubView('driverList', ReportDriverListView, { el: this.el })
    this.fetchDriverList()

    SelectView.prototype.initialize.apply(this, arguments)
  },
  selectmenuopenHandler: function () {
    if (!this.hasSubView('scroll')) {
      this.renderScroll()
    } else {
      this.findSubView('scroll').render()
    }
  },
  fetchDriverList: function () {
    var driverList = this.findSubView('driverList')

    driverList.fetchList({
      success: function () {
        // var defaultOption = 'None'

        driverList.fetchListSuccess()
      }
    })
  },
  selectChange: function (e, ui) {
    var view = this
    var journeyReportModel = view.getParentView().model
    var val = ui.item.value

    if (val === -1) {
      val = ''
    }

    journeyReportModel.set('driverIdList', val)
  },
  reset: function () {
    var view = this

    view.$el.selectmenu('refresh')
  }
})

export default ReportDriverSelectView
