import $ from 'jquery'
import TableGridView from '../TableGrid'
import SnailTrailList from '../../collection/report/SnailTrailList'
import View from '../View'
import SnailTrailTableGridScrollView from './SnailTrailTableGridScroll'

const SnailTrailTableGridView = TableGridView.extend({
  collection: SnailTrailList,
  selectedIndex: -1,
  constructor: function (option) { // should remove this
    var view = this
    var args = arguments

    view.templateUrl = view.templateUrl ? view.templateUrl : option.templateUrl
    view.model = new view.model()

    view.collection = new view.collection()
    window.toPoiCollection = view.collection
    view.bindListenersLoadAndCreateTemplate(view, args)
  },
  bindListenersLoadAndCreateTemplate: function (view, args) { // should remove this
    var $slider = view.getParentView().findSubView('SnailTrailStepPlayer')

    view.on('itemSelect', function ($tr, id) {
      var index = $tr.index()
      if ($tr.hasClass('active')) {
        this.selectedIndex = index
        window.mapCanvas.swapShapeIconToGlowedByIndex(index)
        if (window.mapCanvas.isStreetViewVisible()) {
          const streetViewPhotographPosition = window.mapCanvas.panToIndexInStreetView(index)
        } else if (window.mapCanvas.streetViewRunning) {
          console.log('was running!')
        }
        // mapCanvas.zoomToSubShapeList(index);
      } else {
        this.selectedIndex = -1
        window.mapCanvas.setAllShapesIconColorToDefault()
      }

      if (!$slider.isActive) {
        $slider.$el.find('.play-range input').val(index)
      }
    })
    view.loadDotTemplate(function () {
      var $emptyRow

      view.compileTemplate()

      view.selectedItem = {}// prevent from prototyping;
      view.templateOptions = window.settings.data
      View.apply(view, args)
    })
  },
  loadSnailTrail: function (data) {
    return this.collection.fetch({
      data: data
    })
  },
  showSnailTrailOnMap: function () {
    console.log('SnailTrailTableGrid: showSnailTrailOnMap()')
    var data = this.collection.toJSON()
    var dataLen = data.length
    var img
    var shape
    var currData
    var tooltipText = ''
    var date
    var distanceMetric = window.settings ? (window.settings.data.distanceMetric === 'Miles' ? 'mph' : 'km/h') : ''
    for (let i = 0; i < dataLen; i++) {
      currData = data[i]
      date = new Date(currData.recDate)
      img = new Image()
      img.src = '../' + currData.iconUrl
      tooltipText = currData.registrationNumber + '<br>' +
        date.toDateString() + ' ' + date.toLocaleTimeString() + '<br>' +
        'Ignition ' + currData.ignitionStatus + '<br>' +
        Math.round(currData.speed) + ' ' + distanceMetric + ' (' + currData.directionCode.toString().toUpperCase() + ')' + '<br>' +
        ((currData.address !== '' && currData.address != null) ? currData.address : '') + ' ' +
        ((currData.postcode !== '' && currData.postcode != null) ? (currData.postcode + '<br>') : '')
      if (currData.lon !== 0 && currData.lat !== 0) {
        console.log('SnailTrailTableGrid: addImgMarker ' + img.src + ' ' + Date.now())
        if (window.settings.data.nextGenArrowsAllowed) {
          shape = window.mapCanvas.addImgMarker(img, currData.lon, currData.lat, tooltipText, false, currData.direction, 0.0005, 0)
        } else {
          shape = window.mapCanvas.addImgMarker(img, currData.lon, currData.lat, tooltipText, false, currData.direction)
        }
      } else {
        console.log('SnailTrailTableGrid: zero coords')
      }
      shape.logIndex = i
      shape.mapIndex = i
    }
    if (window.settings.data.nextGenArrowsAllowed) {
      window.mapCanvas.subscribeZoomChangedListener(this)
      if (dataLen > 1) {
        let cur, next
        for (let i = 0; i < dataLen - 1; i++) {
          cur = data[i]
          next = data[i + 1]
          window.mapCanvas.addSnailTrailPolyline(cur.lat, cur.lon, next.lat, next.lon,
              '#3376c8')
        }
      }
    }
    if (dataLen > 1) {
      window.mapCanvas.toggleStreetViewButton(data[0].lat, data[0].lon, data[0].direction);
    }
    if (dataLen > 0 && window.mapCanvas.isStreetViewVisible()) {
      window.mapCanvas.panToIndexInStreetView(0)
    }
    window.rezoomMapFlx()
    window.onMap = true
  },
  zoomChanged: function (currentZoomLevel) {
    console.log('ZOOM CHANGED! :' + currentZoomLevel)
    if (window.settings.data.nextGenArrowsAllowed) {
      const markerType = 'imgMarker'
      switch (currentZoomLevel) {
        case 21: {
          window.mapCanvas.adjustMarkerPosition(markerType, 0.001, 0)
          break
        }
        case 20: {
          window.mapCanvas.adjustMarkerPosition(markerType, 0.001, 0)
          break
        }
        case 19: {
          window.mapCanvas.adjustMarkerPosition(markerType, 0.001, 0)
          break
        }
        case 18: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.00001, -0.000004)
          break
        }
        case 17: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.00008, -0.000008)
          break
        }
        case 16: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.0002, -0.00001)
          break
        }
        case 15: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.0004, -0.00006)
          break
        }
        case 14: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.0008, 0)
          break
        }
        case 13: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.0013, 0.0001)
          break
        }
        case 12: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.003, 0)
          break
        }
        case 11: {
          window.mapCanvas.adjustMarkerPosition(markerType, -0.005, 0)
          break
        }
      }
    }
  },
  removeSnailTrailFromMap: function () {
    console.log('SnailTrailTableGrid: removeSnailTrailFromMap()')
    window.mapCanvas.cleanUp('imgMarker')
    window.mapCanvas.unsubscribeZoomChangedListener && window.mapCanvas.unsubscribeZoomChangedListener(this)
    window.mapCanvas.unsubscribeStreetViewVisibleChangedListener && window.mapCanvas.unsubscribeStreetViewVisibleChangedListener(this)
    window.mapCanvas.toggleStreetViewButton()
    if (window.settings.data.nextGenArrowsAllowed) {
      window.mapCanvas.cleanUp('snailTrailPolyline')
    }
    window.onMap = false
  },
  setActiveRow: function (e) {
    var $tr = $(e.currentTarget)
    var multiSelect = this.model.get('multiSelect')
    var $slider = this.getParentView().findSubView('SnailTrailStepPlayer')
    var activeId

    if (!multiSelect) {
      activeId = $tr.siblings().andSelf().filter('tr.active').attr('data-id')
    }

    if ($tr.hasClass('active')) {
      $tr.removeClass('active')
    } else {
      $tr.addClass('active').siblings().removeClass('active')
    }

    if ($slider.isActive) {
      clearInterval($slider.timeout)
      $slider.activeSnailTrailPlay($tr.index())
    } else {
      this.setSelectedItem($tr, activeId)
    }
  },
  render: function (options) {
    var view = this
    var parentView = view.getParentView()
    var json = options || view.collection.toJSON()
    var $tableGrid = $(view.dotTemplateToHtml(json))

    view.getParentView().findSubView('SnailTrailStepPlayer').$el.find('.play-range input').attr('max', view.collection.length - 1)

    if (view.$el.attr('data-table-grid')) {
      view.$el.html($tableGrid.html())
      view.findSubView('scroll').render()
    } else {
      view.setElement($tableGrid)
      parentView.$el.append(view.$el)
      view.addSubView('scroll', SnailTrailTableGridScrollView, { el: view.$('[data-scroll]') }).render()
    }
    return this
  },
  destroy: function () {
    this.removeSnailTrailFromMap()
    this.off('itemSelect')
    this.getParentView().findSubView('SnailTrailStepPlayer').destroy()
    this.remove()
  }
})

export default SnailTrailTableGridView
