import Model from '../Model'

const AlertAoiModel = Model.extend({
  defaults: {
    id: 0,
    description: '',
    entry: false,
    exit: false
  }
})

export default AlertAoiModel
