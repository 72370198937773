import DigitalInputReportPopupView from './DigitalInputReportPopup'
import JourneyReportSelectionMenuView from '../journeyReport/JourneyReportSelectionMenu'
import ReportPageView from '../ReportPage'
import DigitalInputReportView from './DigitalInputReport'

const DigitalInputReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="journey"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', DigitalInputReportPopupView)
    view.addSubView('selectionMenu', JourneyReportSelectionMenuView)
    view.addSubView('report', DigitalInputReportView)
    view.render()
  }
})

export default DigitalInputReportPageView
