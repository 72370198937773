import Model from './Model'

const SelectItemModel = Model.extend({
  defaults: {
    description: '',
    disabled: false,
    label: '',
    value: null,
    escape: true
  }
})

export default SelectItemModel
