import TableDropView from '../TableDrop'

const AlertTableDropView = TableDropView.extend({
  elSelector: '[data-drop=alertDrop]',
  templateUrl: 'template/alert/alertTableDrop.html',
  saveSort: function (sortData) {
    this.sortData = sortData
  },
  clearSortData: function () {
    this.sortData = {}
  },
  saveFilter: function (filterData, $activeTh, index) {
    var thIndex = index || $activeTh.index()

    this.filterData[thIndex] = filterData
  },
  clearFilterData: function ($activeTh, index) {
    if (!$activeTh) return
    var thIndex = index || $activeTh.index()

    if (this.filterData.hasOwnProperty(thIndex)) {
      delete this.filterData[thIndex]
    }
  },
  setDropPosition: function (pageX, pageY, relativePageX, relativePageY) {
    this.$el.css({
      display: 'block',
      top: relativePageY,
      left: relativePageX
    })
  }
})

export default AlertTableDropView
