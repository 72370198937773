import PopupView from '../Popup'
import PreferencesView from './Preferences'

const PreferencesPopupView = PopupView.extend({
  url: 'js/html/app/src/template/modalPanel/preferencesPopup.html',
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'closeHandler',
    'click [data-button=save]': 'submit'
  },

  hideActivePopup: function () {
    var view = this

    if (view.isVisible()) {
      window.wlsMapTools.optionBtnIsActive = false
      view.hide()
    }
  },
  initialize: function () {
    var view = this
    view.addSubView('preferences', PreferencesView)
  },
  closeBtnHandler: function () { // override
    window.wlsMapTools.deactivatePreferences()
    this.hide()
  },

  destroy: function () {
    this.findSubView('preferences').destroy()
    this.remove()
  },
  submit: function () {
    this.findSubView('preferences').submit()
  }

})

export default PreferencesPopupView
