import SelectionMenuVehicleListView from '../../SelectionMenuVehicleList'
import DriverBehaviourReportSelectionMenuVehicleList from '../../../collection/report/driverBehaviour/DriverBehaviourReportSelectionMenuVehicleList'
import DriverBehaviourSelectionMenuVehicleListScrollView from './DriverBehaviourSelectionMenuVehicleListScroll'

const DriverBehaviourSelectionMenuVehicleListView = SelectionMenuVehicleListView.extend({
  collection: DriverBehaviourReportSelectionMenuVehicleList,
  templateUrl: 'template/report/driverBehaviour/driverBehaviourReportSelectionMenuVehicleList.html',
  elSelector: '[data-vehicle-list=driverBehaviour]',
  initialize: function () {
    var view = this

    view.addSubView('scroll', DriverBehaviourSelectionMenuVehicleListScrollView)
    view.render()
  }
})

export default DriverBehaviourSelectionMenuVehicleListView
