import ScrollView from '../../Scroll'

const VehicleDefectReportCommentListScrollView = ScrollView.extend({
  calculateScrollContentSize: function () {
    this.$frame.height(232)
  },
  checkContentSize: function () {
    return this.$('.slidee').height() > 232
  }
})

export default VehicleDefectReportCommentListScrollView
