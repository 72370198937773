import $ from 'jquery'
import View from '../View'

const ReportPageView = View.extend({
  render: function () {
    window.reportPageIsActive = true

    $('.map-wrap').append(this.el)
    window.wls.resetState()
    $('.left-panel').fadeOut(400)

    return this
  },
  getSelectionMenuVehicleList: function () {
    return this.findSubView('selectionMenu').findSubView('vehicleList')
  },
  getReportFormSelectedVehicleView: function () {
    return this.findSubView('reportPopup').findSubView('reportForm').findSubView('selectedVehicle')
  },
  isVisible: function () {
    return this.$el.is(':visible')
  },
  destroy: function (dontShowLeftPanel) {
    var view = this

    view.findSubView('report').destroy()
    view.findSubView('reportPopup').destroy()
    view.findSubView('selectionMenu').destroy()
    window.reportHandler.removeReport(view)
    window.reportPageIsActive = false
    window.wls.resetSavedStates()
    if (!dontShowLeftPanel) {
      if (window.userPrefs.htmlGroupTabsAllowed) {
        window.groupTabsetView.collection.fetch({
          success: function () {
            var groupId = $.cookie('lastViewedGroup')

            $.cookie('lastViewedGroup', null) // reset cookie
            window.groupTabsetView.selectGroup(groupId)
            $('.left-panel').fadeIn(400)
            $.cookie('lastViewedGroup', groupId)
            view.getSelectionMenuVehicleList().saveSelectedVehicleToStorage()
            window.groupTabsetView.render()
          }
        })
      } else {
        view.getSelectionMenuVehicleList().saveSelectedVehicleToStorage()
        window.wls.requestCars($.cookie('lastViewedGroup'), function () {
          $('.left-panel').fadeIn(400, function () {
            // wls.removeCars()
            $('.left-panel .right-col .vehicle-holder .scroll-content').triggerHandler('resize', [$('.left-panel .vehicle-holder .slidee').height()])
          })
        })
      }
    } else {
      view.getSelectionMenuVehicleList().saveSelectedVehicleToStorage()
    }
    $('.top-bar').animate({ 'margin-left': 0 }, 600).removeClass('active')
    view.remove()
  }
})

export default ReportPageView
