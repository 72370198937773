
function resolveColor (info) {
  var color = info.match(/[0-9]/i);
  var hexColor = '#828282';
  if (color) {
    switch (color[0]) {
      case '0':   // Blue
        hexColor = '#0175d7';
        break;
      case '1':   // Grey
        hexColor = '#828282';
        break;
      case '2':   // Black
        hexColor = '#000';
        break;
      case '3':   // Indigo
        hexColor = '#4b0082';
        break;
      case '4':   // Red
        hexColor = '#ff0000';
        break;
      case '5':   // Orange
        hexColor = '#ff9c00';
        break;
      case '6':   // Violet
        hexColor = '#ee82ee';
        break;
      case '7':   // Green
        hexColor = '#0db900';
        break;
    }
  }
  return hexColor;
}

export default resolveColor
