import ReportPopupView from '../ReportPopup'
import DriverBehaviourFormView from './DriverBehaviourForm'

const DriverBehaviourPopupView = ReportPopupView.extend({
  url: '/getReportWindow/DriverBehaviour.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', DriverBehaviourFormView)
  }
})

export default DriverBehaviourPopupView
