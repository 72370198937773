import ConfirmVideoRequestPopupView from '../camera/ConfirmVideoRequestPopup'
import MultiCameraConfirmVideoRequestFormView from './MultiCameraConfirmVideoRequestForm'

const MultiCameraRequestVideoPopupView = ConfirmVideoRequestPopupView.extend({
  initialize: function (option) {
    this.addSubView('confirmVideoRequestForm', MultiCameraConfirmVideoRequestFormView)
  },
  render: function () {
    this.parentView.findSubView('multiCameraVideoPlayer').$el.append(this.el)
    return this
  },
  closeBtnHandler: function () {
    this.parentView.getVideoPlayer().selectPreviousValue()

    ConfirmVideoRequestPopupView.prototype.closeBtnHandler.apply(this, arguments)
  }
})

export default MultiCameraRequestVideoPopupView
