import Model from '../../Model'

const DateIntervalDropdownModel = Model.extend({
  urlRoot: 'api/report/location/date-interval/',
  defaults: {
    data: []
  }
})

export default DateIntervalDropdownModel
