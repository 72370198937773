import $ from 'jquery'
import CompactAccountReportFormView from '../CompactAccountReportForm'
import FuelTankLevelVarianceReportFormModel from '../../../model/report/fuelTankLevelVarianceReport/FuelTankLevelVarianceReportForm'
import FuelVarianceFilterSelectView from './FuelVarianceFilterSelect'

const FuelTankLevelVarianceReportFormView = CompactAccountReportFormView.extend({
  model: FuelTankLevelVarianceReportFormModel,
  mergeEvents: true,
  events: {
    'click [data-button=clearFilters]': 'clearFiltersButtonHandler'
  },
  constructor: function () {
    this.setSubView({
      fuelVarianceSelect: {
        view: FuelVarianceFilterSelectView,
        option: { el: $('[data-select=fuelVarianceFilter]') }
      }
    })
    CompactAccountReportFormView.apply(this, arguments)
  },
  clearFiltersButtonHandler: function () { // override
    var fuelVarianceSelectView = this.findSubView('fuelVarianceSelect')

    fuelVarianceSelectView.$el.val('noFilter')
    fuelVarianceSelectView.$el.selectmenu('refresh')

    CompactAccountReportFormView.prototype.clearFiltersButtonHandler.apply(this, arguments)
  },
  generatePdfXlsReportURL: function (repFormat) { // implementation
    var model = this.model
    var accountId = $('input[name=groupId][value=' + $.cookie('lastViewedGroup') + ']').closest('.account-list').parent().find('[data-accountid]').attr('data-accountid')
    var data = {
      'report-name': 'FuelTankLevelVariance',
      'report-type': 'FuelTankLevelVariance',
      'report-format': repFormat,
      from: model.get('from'),
      to: model.get('to'),
      account_id: accountId
    }
    return $.param(data)
  }
})

export default FuelTankLevelVarianceReportFormView
