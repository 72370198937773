import $ from 'jquery'
import ReportFormView from '../ReportForm'
import DigitalInputReportFormModel from '../../../model/report/digitalInput/DigitalInputReportForm'

const DigitalInputReportFormView = ReportFormView.extend({
  model: DigitalInputReportFormModel,
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form:first')
    view.el = view.$el[0]

    ReportFormView.apply(this, arguments)
  },
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'DigitalInput',
      'report-type': 'DigitalInput',
      'report-format': model.get('repFormat'),
      alternate: true,
      hideIgnition: model.get('hideIgnition'),
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/')
    }
    return $.param(data)
  }
})

export default DigitalInputReportFormView
