import Model from '../../Model'

const DetailedVehicleDefectReportModel = Model.extend({
  defaults: {
    vehicleDefectDto: {},
    vehicleDefectCheckSeenByDtoList: [],
    vehicleDefectDailyCheckEntryDtoList: []
  },
  urlRoot: 'api/report/detailedVehicleDefectReport'
})

export default DetailedVehicleDefectReportModel
