import Vehicle from '../../model/Vehicle'
import Collection from '../Collection'

const AlertAvailableVehicleList = Collection.extend({
  model: Vehicle,
  id: null,
  url: function () {
    return 'services/geofence/alert-available-vehicle/' + (this.id ? this.id : '')
  }
})

export default AlertAvailableVehicleList
