import Model from './Model'
import Collection from '../collection/Collection'

const TableGrid = Model.extend({
  defaults: {
    selectAllowed: true,
    multiSelect: false,
    hasScroll: true,
    reloadScrollOnWindowResize: false,
    columnWidth: [],
    tableHead: [],
    tableRow: []
  },
  set: function (attributes, options) { // override
    if (attributes.tableRow && attributes.tableRow !== this.defaults.tableRow && !(attributes.tableRow instanceof Collection)) {
      attributes.tableRow = new this.defaults.tableRow(attributes.tableRow)
    } else if (attributes === 'tableRow' && options !== this.defaults.tableRow && !(options instanceof Collection)) {
      options = new this.defaults.tableRow(attributes.tableRow || options)
    }
    return Model.prototype.set.call(this, attributes, options)
  },
  toJSON: function () { // override
    var json = Model.prototype.toJSON.apply(this, arguments)

    if (this.attributes.tableRow instanceof Collection) {
      json.tableRow = this.attributes.tableRow.toJSON()
    }
    return json
  }
})

export default TableGrid
