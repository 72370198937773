import View from '../../View'
import CompactReportView from '../CompactReport'
import FuelTankLevelVarianceReportFormView from './FuelTankLevelVarianceReportForm'
import FuelTankLevelVarianceReportTableGridView from './FuelTankLevelVarianceReportTableGrid'

const FuelTankLevelVarianceReportView = CompactReportView.extend({
  templateUrl: 'template/report/fuelTankLevelVarianceReport/fuelTankLevelVarianceReport.html',
  initialize: function (option) {
    var view = this

    view.addSubView('compactReportTableGrid', FuelTankLevelVarianceReportTableGridView, this.$('[data-table-grid=fuelTankLevelVariance]')).once('ready', function () {
      view.addSubView('compactReportForm', FuelTankLevelVarianceReportFormView)
    })
  }
})

export default FuelTankLevelVarianceReportView
