import ReportPopupView from '../ReportPopup'
import DetailedReportFormView from './DetailedReportForm'

const DetailedReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/Detailed.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', DetailedReportFormView)
  }
})

export default DetailedReportPopupView
