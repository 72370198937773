import $ from 'jquery'
import ReportFormView from '../ReportForm'
import TimeOnSiteFormModel from '../../../model/report/timeOnSite/TimeOnSiteForm'
import AoiPoiDropdownView from '../../AoiPoiDropdown'
import LocationSearchView from '../../LocationSearch'
import CounterView from '../../Counter'

const TimeOnSiteFormView = ReportFormView.extend({
  model: TimeOnSiteFormModel,
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form:first')
    view.el = view.$el[0]

    view.addSubView('aoiPoiDropDown', AoiPoiDropdownView, { el: view.$('.select-holder.aoi-poi .select') })
    view.addSubView('locationSearch', LocationSearchView, { el: view.$('.select-holder.location-search') })
    view.addSubView('durationCounter', CounterView, { el: view.$('[data-counter=duration]') })

    ReportFormView.apply(this, arguments)
  },
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'TimeOnSite',
      'report-type': 'TimeOnSite',
      'report-format': model.get('repFormat'),
      alternate: false,
      excludeOnSiteMovements: model.get('excludeOnSiteMovements'),
      aoiPoiId: model.get('aoiPoiId'),
      duration: model.get('duration'),
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/'),
      postcodeFilter: model.get('postcodeFilter')
    }
    return $.param(data)
  }
})

export default TimeOnSiteFormView
