/**
 * Created by George on 20.07.2015.
 */
import ProfileManagerTableGridView from '../profileManager/ProfileManagerTableGrid';
import OverspeedAlertAvailableProfileList from '../../../collection/alert/overspeed/OverspeedAlertAvailableProfileList';

const OverspeedProfileManagerTableGrid = ProfileManagerTableGridView.extend({
    collection: OverspeedAlertAvailableProfileList
});

export default OverspeedProfileManagerTableGrid;
