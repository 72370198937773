import $ from 'jquery'
import CompactAccountReportFormModel from '../../model/report/CompactAccountReportForm'
import CompactReportFormView from './CompactReportForm'

const CompactAccountReportFormView = CompactReportFormView.extend({
  model: CompactAccountReportFormModel,
  assignReportDataId: function () { // override
    var view = this
    var accountId = $('input[name=groupId][value=' + $.cookie('lastViewedGroup') + ']').closest('.account-list').parent().find('[data-accountid]').attr('data-accountid')
    if (!accountId) {
      accountId = window.settings.data.lastAccountId
    }

    view.model.set('account_id', accountId)
  }
})

export default CompactAccountReportFormView
