import OutOfHoursQueryReportPopupView from './OutOfHoursQueryReportPopup'
import JourneyReportSelectionMenuView from '../journeyReport/JourneyReportSelectionMenu'
import ReportPageView from '../ReportPage'
import OutOfHoursQueryReportView from './OutOfHoursQueryReport'

const OutOfHoursQueryReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="journey"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', OutOfHoursQueryReportPopupView)
    view.addSubView('selectionMenu', JourneyReportSelectionMenuView)
    view.addSubView('report', OutOfHoursQueryReportView)
    view.render()
  }
})

export default OutOfHoursQueryReportPageView
