import ReportModel from '../Report'

const SpeedingReportModel = ReportModel.extend({
  defaults: {
    repJourneySections: []
  },
  urlRoot: 'api/report/speeding'
})

export default SpeedingReportModel
