import $ from 'jquery'
import View from '../../View'
import CompactReportView from '../CompactReport'
import LeagueTableReportFormView from './LeagueTableForm'
import LeagueTableReportTableGridView from './LeagueTableTableGrid'
import LeagueTableGroupSelectView from './LeagueTableGroupSelect'
import LeagueTableDateSelectView from './LeagueTableDateSelect'
import LeagueTableKeyPopupView from './LeagueTableKeyPopup'
import LeagueTableExcludeDriversPopupView from './LeagueTableExcludeDriversPopup'

const LeagueTableReportView = CompactReportView.extend({
  mergeEvents: true,
  templateUrl: 'template/report/leagueTable/leagueTableReport.html',
  events: {
    'click [data-button=tableKey]': 'showTableKey',
    'click [data-button=excludeDrivers]': 'showExcludeDrivers'
  },
  initialize: function (option) {
    var view = this

    view.addSubView('selectGroup', LeagueTableGroupSelectView, { el: view.$('[data-select=groupsSelect]') })
    view.addSubView('selectPeriod', LeagueTableDateSelectView, { el: view.$('[data-select=datePeriod]') })
    view.addSubView('tableKey', LeagueTableKeyPopupView)

    view.addSubView('compactReportTableGrid', LeagueTableReportTableGridView, this.$('[data-table-grid=vehicle-defect]')).once('ready', function () {
      view.addSubView('compactReportForm', LeagueTableReportFormView)
      view.addSubView('excludeDrivers', LeagueTableExcludeDriversPopupView)
      view.fetchGroupList()
    })
  },
  showTableKey: function (e) {
    var $button = $(e.currentTarget)
    var view = this
    var tableKeyPopupView = view.findSubView('tableKey')
    // var leagueTableReportTableGridView = view.findSubView('compactReportTableGrid')

    view.hideActivePopups()
    // leagueTableReportTableGridView.resetSelectedItemAndRow()
    // notificationsPopupView.renderForm()
    tableKeyPopupView.alignBy($button, 'center', { top: 5 })
    tableKeyPopupView.show()
  },
  showExcludeDrivers: function (e) {
    var $button = $(e.currentTarget)
    var view = this
    var excludeDriversPopupView = view.findSubView('excludeDrivers')
    var excludeDriversTableGridViewModel = excludeDriversPopupView.findSubView('excludeDriversTableGrid')

    // leagueTableReportTableGridView.hideActivePopup()
    // leagueTableReportTableGridView.resetSelectedItemAndRow()
    // notificationsPopupView.renderForm()
    view.hideActivePopups()
    excludeDriversTableGridViewModel.syncModel()
    excludeDriversPopupView.alignBy($button, 'center', { top: 5 })
    excludeDriversPopupView.show()
  },
  fetchGroupList: function () {
    var select = this.findSubView('selectGroup')
    var groupList = select.findSubView('groupList')
    var selectDate = this.findSubView('selectPeriod')
    var dateList = selectDate.findSubView('dateList')

    groupList.fetchList({
      success: function () {
        var defaultOption = $.cookie('lastViewedGroup')

        dateList.render()
        groupList.fetchListSuccess(defaultOption)
      }
    })
  },
  hideTableKey: function () {
    var view = this

    view.findSubView('tableKey').hide()
  },
  hideExcludeDrivers: function () {
    var view = this

    view.findSubView('excludeDrivers').hide()
  },
  hideActivePopups: function () {
    this.hideTableKey()
    this.hideExcludeDrivers()
  },
  slide: function (e) { // override
    this.hideActivePopups()

    CompactReportView.prototype.slide.apply(this, arguments)
  },
  destroy: function () {
    var view = this

    view.findSubView('excludeDrivers').destroy()
    view.findSubView('tableKey').destroy()

    CompactReportView.prototype.destroy.apply(this, arguments)
  }
})

export default LeagueTableReportView
