import DriverBehaviourPopupView from './DriverBehaviourPopup'
import DriverBehaviourSelectionMenuView from './DriverBehaviourSelectionMenu'
import ReportPageView from '../ReportPage'
import DriverBehaviourView from './DriverBehaviour'

const DriverBehaviourPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="driverBehaviour"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', DriverBehaviourPopupView)
    view.addSubView('selectionMenu', DriverBehaviourSelectionMenuView)
    view.addSubView('report', DriverBehaviourView)
    view.render()
  }
})

export default DriverBehaviourPageView
