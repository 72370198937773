import $ from 'jquery'
import SafeInputView from '../SafeInput'
import SelectedVehicleView from './SelectedVehicle'
import DatepickerRangeView from '../DatepickerRange'
import ReportFormModel from '../../model/report/ReportForm'
import FormView from '../Form'

const ReportFormView = FormView.extend({
  model: ReportFormModel,
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form')
    view.el = view.$el[0]

    view.addSubView('selectedVehicle', SelectedVehicleView, { el: view.$('[data-list=selectedVehicle]') })
    view.getParentView().getParentView().findSubView('selectionMenu').once('ready', function () {
      var selectionMenuVehicleListView = this.findSubView('vehicleList')

      view.reportFormSyncSelectedVehicles()
      view.listenTo(selectionMenuVehicleListView.collection, 'change:selected', function () {
        var selectedVehicles = selectionMenuVehicleListView.collection.filter({ selected: true, locked: false })

        view.findSubView('selectedVehicle').syncSelectedVehicleList(selectedVehicles)
        view.findSubView('selectedVehicle').render()
      })
    })
    view.addSubView('datepickerRange', DatepickerRangeView, { el: view.$('[data-calendar=dateRange]') })
    view.addSubView('safeInputFromTime', SafeInputView, { el: view.$('[data-input=fromTime]') }).model.set('allowedChars', ['digits', 'colon', 'edit', 'navigation'])
    view.addSubView('safeInputToTime', SafeInputView, { el: view.$('[data-input=toTime]') }).model.set('allowedChars', ['digits', 'colon', 'edit', 'navigation'])

    FormView.apply(this, arguments)
  },
  reportFormSyncSelectedVehicles: function (_selectedVehicles) {
    var view = this
    var selectionMenuView = view.getParentView().getParentView().findSubView('selectionMenu')

    if (selectionMenuView.isReady) {
      view.selectedVehicleReadyHandler(selectionMenuView, _selectedVehicles)
    } else {
      selectionMenuView.once('ready', function () {
        view.selectedVehicleReadyHandler(selectionMenuView, _selectedVehicles)
      })
    }
  },
  selectedVehicleReadyHandler: function (selectionMenuView, _selectedVehicles) {
    var view = this
    var selectedVehicles = _selectedVehicles ? _selectedVehicles : selectionMenuView.findSubView('vehicleList').collection.filter({ selected: true, locked: false })

    view.findSubView('selectedVehicle').syncSelectedVehicleList(selectedVehicles)
    view.findSubView('selectedVehicle').render()

    if (window.groupTabsetView) {
      window.groupTabsetView.findSubView('colorPicker').hide()
    }

    if (typeof $.cookie('lastViewedGroupDescr') === 'undefined') {
      // no cookie
      console.log('no cookie')
      var tempGroupDescr = 'Checking Group Name...'
      var hoverTooltip = $('.hover-tooltip-report')
      hoverTooltip.attr('data-tooltip', tempGroupDescr)
      console.log(tempGroupDescr)
      $.post('/api/unit-view/description', {
        unitViewId: $.cookie('lastViewedGroup')
      }).done(function (msg) {
        var groupDescr = msg
        console.log('..' + groupDescr)
        $('.hover-tooltip-report').attr('data-tooltip', groupDescr)
        $('.groups-tooltip').text(groupDescr)
        $.cookie('lastViewedGroupDescr', groupDescr)
      }).error(function (msg, errorText) {
        console.error('Failed to load Group descr, reason: ' + errorText)
      })
    } else {
      // have cookie
      console.log('have cookie')
      var groupDescr = $.cookie('lastViewedGroupDescr')
      console.log('..' + groupDescr)
      $('.hover-tooltip-report').attr('data-tooltip', groupDescr)
      $('.groups-tooltip').text(groupDescr)
    }
  },
  submit: function () { // override
    var view = this
    var repFormat = this.model.get('repFormat')

    if (repFormat === 'PDF' || repFormat === 'XLS') {
      window.open('/load-report?' + view.generatePdfXlsReportURL())
    } else {
      this.getParentView().getParentView().findSubView('report').fetchReportModel()
    }
  },
  syncData: function () {
    var obj = this.getFormData()
    var allDrivers = []

    /**
     * [*Fix*] Here we check if driverIdList not ignore
     */
    if (obj.driverIdList === '-1') {
      obj.driverIdList = ''
    } else if (obj.driverIdList === '0') {
      this.findSubView('reportDriversSelect').findSubView('driverList').collection.forEach(function (d, idx) {
        if (d.get('driverId') !== 0 && d.get('driverId') !== -1) {
          allDrivers.push(d.get('driverId'))
        }
      })

      obj.driverIdList = allDrivers.join(',')
    }

    this.model.set(obj)
  },
  generatePdfXlsReportURL: function () {
    throw new Error('generatePdfXlsReportURL not implemented')
  },
  destroy: function () {
    this.findSubView('datepickerRange').destroy()
    this.findSubView('selectedVehicle').destroy()
    this.remove()
  }
})

export default ReportFormView
