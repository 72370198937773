import $ from 'jquery'
import FormView from '../Form'
import RefreshSettingsModel from '../../model/modalPanel/RefreshSettings'
import CounterView from '../Counter'

const RefreshSettingsView = FormView.extend({
  model: RefreshSettingsModel,
  elSelector: '[data-form=refresh]',
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'cancel'
    // 'click [data-button=save]': 'submit'
  },
  constructor: function () {
    var view = this
    var parentView = view.getParentView()
    if (parentView) {
      view.$el = parentView.$(view.elSelector)
    } else {
      view.$el = $(view.elSelector)
    }
    view.el = view.$el[0]

    FormView.apply(this, arguments)
  },
  initialize: function (option) {
    var view = this

    view.resetElement()
    view.model.set('refreshSmSeconds', window.userPrefs ? window.userPrefs.refreshSmSeconds : 30)
    view.addSubView('secondsCounter', CounterView, { el: $('[data-counter=seconds]') })
    view.on('save', function (model) {
      window.userPrefs.refreshSmSeconds = model.get('refreshSmSeconds')
      window.settings.get(true)
      window.wls.setTimerValue(window.userPrefs.refreshSmSeconds)
      view.cancel()
    })
  },
  updateRefreshSettings: function (newRefreshValue) {
    this.model = new RefreshSettingsModel({ refreshSmSeconds: newRefreshValue })
  },
  cancel: function () {
    this.getParentView().hide()

    FormView.prototype.cancel.apply(this, arguments)
  },
  submitHandler: function (e) {
    FormView.prototype.submitHandler.apply(this, arguments)
  },

  destroy: function () {
    var view = this
    view.findSubView('secondsCounter').destroy()
    view.remove()
  }

})

export default RefreshSettingsView
