import Model from '../Model'

const VehicleIconModel = Model.extend({
  defaults: {
    imageFileName: ''
  },
  imgUrl: function () {
    return 'img/vehicleIcons/' + this.get('imageFileName') + '_E_100.png'
  },
  color: function () {
    var color = this.get('imageFileName').match(/(black)|(white)|(red)|(green)|(blue)|(yellow)|(purple)|(special)/gi)

    if (color) {
      color = color[0]
    } else {
      color = 'special'
    }

    return color
  },
  urlRoot: 'api/selection-menu/action/edit-vehicle/icon'
})

export default VehicleIconModel
