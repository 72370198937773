import $ from 'jquery'
import _ from 'underscore'
import CompactReportTableGridView from '../CompactReportTableGrid'
import VehicleDefectReportTableGridModel from '../../../model/report/vehicleDefectReport/VehicleDefectReportTableGrid'
import DetailedVehicleDefectReportPopupView from './DetailedVehicleDefectReportPopup'
import VehicleDefectReportCommentPopupView from './VehicleDefectReportCommentPopup'
import StatusPopupView from './StatusPopup'

const VehicleDefectReportTableGridView = CompactReportTableGridView.extend({
  model: VehicleDefectReportTableGridModel,
  templateUrl: 'template/report/vehicleDefectReport/vehicleDefectReportTableGrid.html',
  mergeEvents: true,
  events: {
    'mouseover [data-note] div': 'showNote',
    'mouseout [data-note] div': 'hideActivePopup',
    'click [data-button=view]': 'viewDetails',
    'click [data-button=comment]': 'viewComments',
    'click [data-button=lock]': 'showStatusPopup',
    'mouseover [data-closed] div': 'showStatusPopup',
    'click tbody tr:not(.empty-row)': 'showPinMap'
  },
  initialize: function () {
    var view = this

    view.addSubView('detailedVehicleDefectReportPopup', DetailedVehicleDefectReportPopupView)
    view.addSubView('statusPopup', StatusPopupView)
    view.addSubView('vehicleDefectReportCommentPopup', VehicleDefectReportCommentPopupView)
    view.trigger('ready')
  },
  showPinMap: function (e) {
    var view = this
    var $tr = $(e.currentTarget).closest('tr')
    var _id = $tr.attr('data-id')

    e.currentTarget = $tr[0]
    view.resetPin()

    if (!$tr.hasClass('active')) {
      view.setActiveRow(e)
      view.showPinOnMap(_id)
    }

    setTimeout(window.rezoomMapFlx, 1500)
  },
  resetPin: function () {
    if (window.wls.hasShapes()) {
      window.wls.removeCars()
    }

    window.mapCanvas.cleanUp()
  },
  showPinOnMap: function (id) {
    var view = this
    var vehicleDefectReport = view.getParentView().getParentView()
    var $slideButton = vehicleDefectReport.$el.find('.slide-button')
    var redMarker = new Image()
    var model = view.model.get('tableRow')
    var vehicle = model.get(id).attributes

    redMarker.src = 'img/markers/plot_on_map.png'

    if (!$slideButton.hasClass('active') && view.$el.height() > $(window).height() / 2) {
      vehicleDefectReport.slideButtonHandler()
    }

    window.mapCanvas.addImgMarker(redMarker, vehicle.lon, vehicle.lat)
  },
  setActiveRow: function (e, noHideActivePopup) { // override
    var $tr = $(e.currentTarget).closest('tr')
    var view = this

    e.currentTarget = $tr[0]
    view.resetSelectedItemAndRow()
    if (!noHideActivePopup) {
      view.hideActivePopup()
    }
    CompactReportTableGridView.prototype.setActiveRow.apply(this, [e]) // call super
  },
  viewDetails: function (e) {
    var view = this
    var detailedVehicleDefectReportPopupView = view.findSubView('detailedVehicleDefectReportPopup')
    var detailedVehicleDefectReportView = detailedVehicleDefectReportPopupView.findSubView('detailedVehicleDefectReport')
    var $tr = $(e.currentTarget).closest('tr')
    var id = $tr.attr('data-id')

    view.setActiveRow(e)
    detailedVehicleDefectReportView.render(id)
  },
  viewComments: function (e) {
    var view = this
    var $commentButton = $(e.currentTarget)
    var $tr = $commentButton.closest('tr')
    var id = $tr.attr('data-id')
    var commentList = view.model.get('tableRow').get(id).get('vehicleDefectCheckCommentDtoList')
    var vehicleDefectReportCommentPopupView = view.findSubView('vehicleDefectReportCommentPopup')

    if (id === view.getActiveRowId()) {
      return
    }

    if (!vehicleDefectReportCommentPopupView.isVisible()) {
      view.setActiveRow(e)
      vehicleDefectReportCommentPopupView.prepare()
      vehicleDefectReportCommentPopupView.findSubView('commentList').render(commentList)
      vehicleDefectReportCommentPopupView.alignBy($commentButton, 'commentsBox', { top: 10 })
      if (vehicleDefectReportCommentPopupView.isOutsideBottomBorder()) {
        vehicleDefectReportCommentPopupView.moveTo($(window).height() - vehicleDefectReportCommentPopupView.$el.height())
      }
      vehicleDefectReportCommentPopupView.prepareDone()
      vehicleDefectReportCommentPopupView.show()
    } else {
      view.setActiveRow(e, true)
      vehicleDefectReportCommentPopupView.findSubView('commentList').render(commentList)
      vehicleDefectReportCommentPopupView.alignBy($commentButton, 'commentsBox', { top: 10 })
      if (vehicleDefectReportCommentPopupView.isOutsideBottomBorder()) {
        vehicleDefectReportCommentPopupView.moveTo($(window).height() - vehicleDefectReportCommentPopupView.$el.height())
      }
    }
  },
  showStatusPopup: function (e) {
    var view = this
    var statusPopupView = view.findSubView('statusPopup')
    var $button = $(e.currentTarget)
    var $td = $button.closest('td')
    var $tr = $td.closest('tr')
    var id
    var vehicleDefectCheckCommentDtoList

    view.getParentView().hideNotifications()
    if ($td[0].hasAttribute('data-closed')) {
      id = $tr.attr('data-id')
      vehicleDefectCheckCommentDtoList = view.model.get('tableRow').get(id).get('vehicleDefectCheckCommentDtoList')
      statusPopupView.render(_.findWhere(vehicleDefectCheckCommentDtoList, { lastComment: true }))
      statusPopupView.$el.hide()
      statusPopupView.switchToClosed()
      view.resetSelectedItemAndRow()
      view.hideActivePopup()
      statusPopupView.$el.show()
      statusPopupView.show({ silent: true })
    } else {
      statusPopupView.render()
      if (!statusPopupView.isVisible()) {
        view.setActiveRow(e)
        statusPopupView.show()
      } else {
        view.setActiveRow(e, true)
      }
      statusPopupView.switchToOpen()
    }
    statusPopupView.alignBy($button, 'center', { top: 7 })
    if (statusPopupView.isOutsideBottomBorder()) {
      statusPopupView.moveTo($(window).height() - statusPopupView.$el.height())
    }
  },
  showNote: function (e) {
    var view = this
    var $td = $(e.currentTarget)
    // var $note = view.getParentView().$('[data-popup=notes]')
    var $note = view.getParentView().$el.parents('.vehicle-defect').find('[data-popup=notes]')
    var top
    var left

    $note.find('.note-text span').text($td.closest('[data-note]').attr('data-note'))
    top = $td.offset().top + $td.outerHeight()
    left = $td.offset().left - ($note.width() / 2 - $td.outerWidth() / 2) - 10
    if (top + $note.height() > $(window).height()) {
      top = $(window).height() - $note.height()
    }
    $note.css({ top: top, left: left }).show()
  },
  hideNote: function (e) {
    var view = this
    var $note = view.getParentView().$el.parents('.vehicle-defect').find('[data-popup=notes]')

    $note.hide()
  },
  hideActivePopup: function () {
    var view = this
    var detailedVehicleDefectReportPopupView = view.findSubView('detailedVehicleDefectReportPopup')
    var statusPopupView = view.findSubView('statusPopup')
    var vehicleDefectReportCommentPopupView = view.findSubView('vehicleDefectReportCommentPopup')

    if (detailedVehicleDefectReportPopupView.isVisible()) {
      detailedVehicleDefectReportPopupView.hide()
    } else if (statusPopupView.isVisible()) {
      statusPopupView.hide()
    } else {
      vehicleDefectReportCommentPopupView.hide()
    }
    view.hideNote()
  },
  destroy: function () {
    var view = this

    view.resetPin()
    view.findSubView('detailedVehicleDefectReportPopup').destroy()
    view.findSubView('statusPopup').destroy()
    view.findSubView('vehicleDefectReportCommentPopup').destroy()

    CompactReportTableGridView.prototype.destroy.apply(view, arguments)
  }
})

export default VehicleDefectReportTableGridView
