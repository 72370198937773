import $ from 'jquery'
import PopupView from '../Popup'
import SendCommandPopupModel from '../../model/editVehicle/SendCommandPopupModel.js'
import SendCommandFormView from './SendCommandFormView.js'
import CommandSelectView from '../editVehicle/CommandSelect.js'

const SendCommandPopupView = PopupView.extend({
    model: SendCommandPopupModel,
    url: 'js/html/app/src/template/editVehicle/sendCommandPopup.html',
    initialize: function () {
        var view = this
        var select = view.addSubView('select', CommandSelectView, { el: view.$('[data-select=command]') })
        var $sendCommand = $('#sendCommand')
        var formView = view.addSubView('form', SendCommandFormView)
        this.vehId = null

        // formView.listenTo(formView.model,'change:currentVehReg',this.changeCurrentVehReg)
        $('#sendCommand').on('click', function () {
            var vehId = $sendCommand.attr('data-veh-id')
            var $vehicleData = $('.row[data-id=' + vehId + ']')
            view.alignBy($vehicleData, 'topRight', { left: 12 })

            if (view.isVisible() && this.vehId !== vehId) {
                formView.reset()
                formView.$('.button.save').addClass('disabled')
            }
            this.vehId = vehId
            formView.model.set('unitId', vehId)
            view.show()
            view.fetchCommandList()
        })

        $(document).on('click.sendCommand', function (e) {
            var target = $(e.target)

            if (!target.closest('.vehicle-holder').size()
                && !target.closest('td.active[data-view="fleet"]').size()
                && !target.closest('.control-panel').size() && !target.closest('.map-tools').size()
                && !target.closest('#wlsmap').size() && !target.closest('.options').size()
                && !target.closest('#sendCommand').size() && !target.closest('.send-command').size()
                && view.isVisible()) {
                formView.cancel()
                view.hide()
            }
        })
    },
    fetchCommandList: function () {
        var select = this.findSubView('select')
        var commandList = select.findSubView('commandList')
        var vehicle = window.wls.getVehicle(this.vehId)

        commandList.fetchList({
            data: {vehId : $('#sendCommand').attr('data-veh-id')},
            success: function () {
                var defaultOption = 'None'
                commandList.fetchListSuccess(defaultOption)
            }
        })
    },
    alignBy: function (opener) {
        PopupView.prototype.alignBy.apply(this, [opener.closest('.row'), 'topRight', { left: 12 }])
    },
    closeBtnHandler: function () {
        this.findSubView('form').cancel()
    },
    enableSave: function () {
        this.$('.save').removeClass('disabled')
    },
    disableSave: function () {
        this.$('.save').addClass('disabled')
    },
    disablePayload: function () {
        let payload = this.$('.payload')
        payload.addClass('hidden_input');

    },
    enablePayload: function () {
        this.$('.payload').removeClass('hidden_input')
    },
    setKeyValue: function (key) {
       this.$('.command-key').val(key)
    }
})

export default SendCommandPopupView
