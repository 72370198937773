import $ from 'jquery'
import PopupView from '../../Popup'
import SnailTrailPopupModel from '../../../model/report/SnailTrailPopup'

const SpeedingSnailTrailView = PopupView.extend({
  model: SnailTrailPopupModel,
  showSnailTrail: function () {
    var view = this
    var model = window.modelForSnailTrailSpeedingReport
    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.resetWindowSize()
    }
    var parentView = view.getParentView()
    var tableGridView = view.findSubView('snailTrailTableGrid')
    var data = $.param({
      journeyIds: parentView.getSelectedJourneyList(),
      permDescr: 'Full Day Snail Trail',
      percentSpeedCriteria: model.get('percentSpeedCriteria'),
      mileSpeedCriteria: model.get('mileSpeedCriteria'),
      mphSpeedCriteria: model.get('mileSpeedCriteria'),
      operation: model.get('operation')

    })
    tableGridView.collection.url = 'api/report/speeding/snailTrail'
    if (!view.isVisible()) {
      view.prepare(function () {
        tableGridView.collection.fetch({
          data: data
        }).done(function () {
          tableGridView.render()
          view.prepareDone()
          view.show()
          tableGridView.removeSnailTrailFromMap()
          tableGridView.showSnailTrailOnMap()
        })
      })
    } else {
      tableGridView.collection.fetch({
        data: data
      }).done(function () {
        tableGridView.render()
        tableGridView.removeSnailTrailFromMap()
        tableGridView.showSnailTrailOnMap()
      })
    }
  },
  closeBtnHandler: function () { // override
    var view = this
    var tableGridView = view.findSubView('snailTrailTableGrid')
    view.hide()
    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.resetWindowSize()
    }
    view.getParentView().resetSelected()
    if ($('.report .slide-button').hasClass('active')) {
      $('.report .slide-button').click()
    }
    tableGridView.removeSnailTrailFromMap()
  },
  exportToPdf: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('PDF'))
  },
  generatePdfXlsReportURL: function (repFormat) { // implementation
    var view = this
    var parentView = view.getParentView()
    var tableGridView = view.findSubView('snailTrailTableGrid')
    var journeyIds = parentView.getSelectedJourneyList()
    var mapMetadata = window.mapCanvas.map.getCenter()

    var data = {
      'report-name': 'JourneyImg',
      'report-type': 'JourneyImg',
      'report-format': repFormat,
      alternate: false,
      journeyIdList: journeyIds.join('/'),
      centerLat: mapMetadata.latitude,
      centerLon: mapMetadata.longitude,
      zoomLevel: window.map.map.getZoom(),
      selectedIndex: tableGridView.selectedIndex
    }

    return $.param(data)
  },
  goToPOI: function (el) {
    el.preventDefault()

    var $el = $('#addPoiMenu')
    var _$el = $(el.currentTarget)
    var $curr = _$el.parent('tr').attr('data-id')
    var pageX = _$el.position().left + _$el.width() - $el.width() + 10
    var pageY = _$el.position().top + (_$el.outerHeight() / 2) - ($el.height() / 2) - 1

    this.getPOIcoordinates(window.toPoiCollection.models, $curr)

    $el.css({ left: pageX, top: pageY })
    $el.delay(100).show()
  },
  outFromPOI: function (e) {
    var $el = $('[data-popup="journeyDetails"] #addPoiMenu')

    $el.hide()
  },
  addNewPoi: function () {
    var $HTML = $('[data-popup="journeyDetails"]')
    var $report = $('[data-report="journey"]')

    $HTML.hide()
    $report.hide()

    this.outFromPOI()

    window.AddPOI.goToPOIAOI('fromJourney')
  },
  getPOIcoordinates: function (arr, el) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].attributes.id === parseInt(el, 10)) {
        window.AddPOI.lat = arr[i].attributes.lat
        window.AddPOI.lon = arr[i].attributes.lon
      }
    }
    if (window.AddPOI.lat === 0 || window.AddPOI.lon === 0) {
      window.AddPOI.lat = 51.507351
      window.AddPOI.lon = -0.127758
    }
  },
  hide: function (option) { // override
    var view = this

    if (view.isVisible()) {
      view.model.set('visible', false, option)
      $('#content').append($('[data-popup="journeyDetails"]'))
      view.findSubView('snailTrailTableGrid').removeSnailTrailFromMap()
    }
  },
  destroy: function () {
    this.findSubView('snailTrailTableGrid').destroy()
    this.remove()
  }
})

export default SpeedingSnailTrailView
