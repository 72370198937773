import $ from 'jquery'
import PopupView from '../Popup'
import EditVehDriverPopupModel from '../../model/editVehicle/EditVehDriverPopup'
import DriverSelectView from '../editVehicle/DriverSelect'
import DriverModel from '../../model/editVehicle/Driver'

const EditVehDriverPopupView = PopupView.extend({
  model: EditVehDriverPopupModel,
  url: 'js/html/app/src/template/editVehicle/editVehDriverPopup.html',
  mergeEvents: true,
  events: {
    'click .save': 'saveHandler',
    'click .cancel': 'closeBtnHandler'
  },
  initialize: function () {
    var view = this
    var $editVehDriver = $('#editVehDriver')
    var select = view.addSubView('select', DriverSelectView, { el: view.$('[data-select=driver]') })

    this.vehId = null

    $('#editVehDriver').on('click', function () {
      var vehId = $editVehDriver.attr('data-veh-id')
      var $vehicleData = $('.row[data-id=' + vehId + ']')
      var vehReg = window.wls.getVehicle(vehId).data.registrationNumber

      view.alignBy($vehicleData, 'topRight', { left: 12 })

      if (view.isVisible() && view.vehId !== vehId) {
        view.disableSave()
      }
      view.$('.header-panel .info-for').text(vehReg)
      view.vehId = vehId
      if ($vehicleData.find('dt:contains(Driver:)').size()) {
        view.$('.header-panel .info-action').text('Edit Driver for a Vehicle | ')
      } else {
        view.$('.header-panel .info-action').text('Assign Driver to a Vehicle | ')
      }
      view.show()
      view.fetchDriverList()
    })

    $(document).on('click.editVehDriver', function (e) {
      var target = $(e.target)
      select.$el.selectmenu('widget')

      if (!target.is('#content') && !target.closest('.ui-menu').size() && !target.closest('.vehicle-holder').size() && !target.closest('td.active[data-view="fleet"]').size() && !target.closest('.control-panel').size() && !target.closest('.map-tools').size() && !target.closest('#wlsmap').size() && !target.closest('.options').size() && !target.closest('#editVehDriver').size() && !target.closest('.edit-veh-driver').size() && view.isVisible()) {
        view.hide()
      }
    })
  },
  fetchDriverList: function () {
    var select = this.findSubView('select')
    var driverList = select.findSubView('driverList')
    var vehicle = window.wls.getVehicle(this.vehId)

    driverList.fetchList({
      success: function () {
        var defaultOption = 'None'

        if (vehicle.data.vehicleDriverDescr) {
          defaultOption = vehicle.data.vehicleDriverDescr
        }

        driverList.fetchListSuccess(defaultOption)
      }
    })
  },
  enableSave: function () {
    this.$('.save').removeClass('disabled')
  },
  disableSave: function () {
    this.$('.save').addClass('disabled')
  },
  alignBy: function (opener) {
    PopupView.prototype.alignBy.apply(this, [opener.closest('.row'), 'topRight', { left: 12 }])
  },
  saveHandler: function () {
    var $saveButton = this.$('.save')

    if ($saveButton.hasClass('disabled')) {
      return
    }

    var selectView = this.findSubView('select')
    var driver = new DriverModel()
    var $select = selectView.$el.selectmenu()
    var $vehicleData = $('.row[data-id=' + this.vehId + ']')
    var vehicle = window.wls.getVehicle(this.vehId)
    var driverName = $select.find('[value= ' + $select.val() + ']').text()

    driver.set('driverId', $select.val())
    driver.set('name', driverName)
    driver.set('id', this.vehId)
    driver.save()

    if ($select.val() === '0') {
      $vehicleData.find('dt:contains(Driver:)').next().remove().end().remove()
      vehicle.data.vehicleDriverDescr = ''
    } else {
      if (!$vehicleData.find('dt:contains(Driver:)').size()) {
        $vehicleData.find('dl').prepend($('<dd><span>' + driverName + '</span></dd>'))
        $vehicleData.find('dl').prepend($('<dt>Driver:</dt>'))
      } else {
        $vehicleData.find('dt:contains(Driver:)').next().find('span').text(driverName)
      }

      vehicle.data.vehicleDriverDescr = driverName
    }

    this.updateVehReg($select.val(), driverName)

    this.hide()
  },
  updateVehReg: function (selectVal, driverName) {
    var $vehicleData = $('.row[data-id=' + this.vehId + ']')
    var settings = $('input[name=replaceDriverNameWithRegNum]')
    var vehReg = window.wls.getVehicle(this.vehId).data.registrationNumber
    var $vehReg = $vehicleData.find('.data span:first')
    var text = ''
    var replaceDriverNameWithRegNum = window.settings.data.replaceDriverNameWithRegNum

    if (replaceDriverNameWithRegNum) {
      if (selectVal === '0') {
        text = vehReg
        this.renderTooltip($vehReg, text)
      } else {
        text = driverName
        $vehReg.attr('title', text)
      }

      $vehReg.text(text)
    }
  },
  renderTooltip: function ($el, text) {
    if (text.length > 16) {
      $el.attr('title', text)
    } else {
      $el.attr('title', '')
    }
  },
  renderOption: function (vehId) {
    var driver = window.wls.getVehicle(vehId).data.vehicleDriverDescr
    var $option = $('#editVehDriver').find('span')
    var text = 'Edit Vehicle Driver'

    if (!driver) {
      text = 'Assign Driver'
    }

    $option.text(text)
  }
})

export default EditVehDriverPopupView
