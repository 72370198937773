import $ from 'jquery'

var $loader = $('<div id="alt_loader"></div>')
const Loader = {
  on: function () {
    $('.loader_c').show()
    $('.loader_c').append($loader)
  },
  off: function () {
    $loader.remove()
    $('.loader_c').hide()
  }
}

export default Loader
