import $ from 'jquery'
import SelectionMenuVehicleListView from '../SelectionMenuVehicleList'
import JourneyReportSelectionMenuVehicleList from '../../collection/report/journeyReport/JourneyReportSelectionMenuVehicleList'
import ProximitySelectionMenuVehicleListScrollView from './ProximitySelectionMenuVehicleListScroll'

const ProximitySelectionMenuVehicleListView = SelectionMenuVehicleListView.extend({
  collection: JourneyReportSelectionMenuVehicleList,
  templateUrl: 'template/proximity/proximitySelectionMenuVehicleList.html',
  elSelector: '[data-vehicle-list=proximity]',
  initialize: function () {
    var view = this
    view.addSubView('scroll', ProximitySelectionMenuVehicleListScrollView)

    if (window.wls.hasShapes()) {
      window.mapCanvas.cleanUp()
    }

    // console.log('collection --- ', view.collection.toJSON())
    window.wls.carsArray = view.collection.toJSON()
    window.wls.importCars()
    window.wls.createHtml(function callAfterVehiclesCreated () {

    })

    // view.$el.find('.row').removeClass('active')
    view.render()
  },
  resetSelection: function () {
    var view = this
    view.collection.each(function (vehicleModel) {
      view.deselectVehicle(vehicleModel.get('id'))
    })
  },
  hideVehicle: function (id) {
    var view = this
    view.$el.find('.row:hidden').removeClass('active')
    view.$el.find('.row:hidden').show()
    view.$el.find('.row[data-id="' + id + '"]').hide()
  },
  selectVehicle: function (vehicleId, silent) {
    var view = this
    var vehicleModel = view.collection.get(vehicleId)
    var pin = view.getParentView().getParentView().proximity.innerView.model.get('pin')

    if (vehicleModel.get('locked')) return
    if (vehicleModel.get('inStealthMode')) return
    view.$('[data-item=vehicle][data-id=' + vehicleId + ']').addClass('active')
    vehicleModel.set('selected', true, { silent: silent ? true : false })
    view.drawLine(vehicleId, pin)
  },
  deselectVehicle: function (vehicleId, silent) {
    var view = this
    var vehicleModel = view.collection.get(vehicleId)
    var cr = window.wls.getVehicle(vehicleId)

    view.$('[data-item=vehicle][data-id=' + vehicleId + ']').removeClass('active')
    vehicleModel.set('selected', false, { silent: silent ? true : false })
    cr.remove()
    window.mapCanvas.removeAllProximityLines()
  },
  toggleActive: function (e) {
    var view = this
    var $vehicleHolder = $(e.currentTarget)
    var vehicleId = $vehicleHolder.closest('[data-item=vehicle]').attr('data-id')
    if ($vehicleHolder.closest('[data-item=vehicle]').hasClass('active')) {
      view.deselectVehicle(vehicleId)
    } else {
      view.selectVehicle(vehicleId)
    }
    //            view.getParentView().getParentView().findSubView('reportPopup').resetWindowSize()
  },
  showDistances: function (pin) {
    var view = this
    view.collection.each(function (model) {
      var d
      if (pin.data) {
        d = view.distanceBetweenCoordinates(pin.data.lat, pin.data.lon, model.get('lat'), model.get('lon')) * 0.001
      } else {
        d = view.distanceBetweenCoordinates(pin.lat, pin.lon, model.get('lat'), model.get('lon')) * 0.001
      }

      model.set('dis', d)
      if (d % 1 > 0) {
        d = String(d).match(/[0-9]+\.[0-9]{1,2}/)[0]
      }
      view.$el.find('.row[data-id="' + model.get('id') + '"] .distance').show()
      view.$el.find('.row[data-id="' + model.get('id') + '"] .distance span').text(d + ' ' + window.userPrefs.distanceMetric)
    })
  },
  drawLine: function (vehicleId, pin) {
    if (!pin) return
    var view = this
    view.$('[data-item=vehicle][data-id=' + vehicleId + ']').addClass('active')
    var cr = window.wls.getVehicle(vehicleId)
    cr.cache()

    function returnCallback (result) {
      cr.shape = result
      cr.mapView.markerIsOnMap = true

      setTimeout(window.rezoomMapFlx, 1500)
    }

    if (pin.data) {
      window.mapCanvas.drawLineImgMarker(cr.mapView.vehImg, cr.data.registrationNumber, pin.data.lon, pin.data.lat, cr.data.lon, cr.data.lat,
        returnCallback)
    } else {
      window.mapCanvas.drawLineImgMarker(cr.mapView.vehImg, cr.data.registrationNumber, pin.lon, pin.lat, cr.data.lon, cr.data.lat,
        returnCallback)
    }
  },
  distanceBetweenCoordinates: function (lat1, lon1, lat2, lon2) {
    var RADIUS_OF_EARTH_IN_M = 6378000
    var R = RADIUS_OF_EARTH_IN_M
    var dLat = (lat2 - lat1) * Math.PI / 180
    var dLon = (lon2 - lon1) * Math.PI / 180

    var lat1inRadians = lat1 * Math.PI / 180
    var lat2inRadians = lat2 * Math.PI / 180

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) *
      Math.cos(lat1inRadians) * Math.cos(lat2inRadians)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c

    return d
  },
  initSelectScroll: function ($frame) {
    var $wrap = $frame.parent()
    if ('sly' in $frame) {
      $frame.sly('destroy')
    }
    $frame.sly({
      speed: 300,
      easing: 'linear',
      scrollBar: $wrap.find('.scrollbar'),
      scrollBy: 100,
      dragHandle: 1,
      dynamicHandle: 1,
      clickBar: 1
    })
    if ($frame.find('.slidee').height() > $frame.height()) {
      $wrap.find('.scrollbar').show()
    } else {
      $wrap.find('.scrollbar').hide()
    }
  }
})

export default ProximitySelectionMenuVehicleListView
