import SelectView from '../Select'
import CommandList from '../editVehicle/CommandList'

const CommandSelectView = SelectView.extend({
    events: {
        selectmenuchange: 'selectChange'
    },
    initialize: function () {
        this.addSubView('commandList', CommandList, { el: this.el })
        SelectView.prototype.initialize.apply(this, arguments)
    },
    selectChange: function (e, ui) {
        var view = this

        if (ui.item.label === 'None') {
            this.getParentView().disableSave()
            return
        }
        if (ui.item.value.includes("_payload")) {
            this.getParentView().enablePayload()
        } else {
            this.getParentView().disablePayload()
        }
        this.getParentView().setKeyValue(ui.item.value)
        this.getParentView().enableSave()

    },
    reset: function () {
        var view = this

        view.el.value = 'none'
        view.$el.selectmenu('refresh')
    }
})

export default CommandSelectView
