import Backbone from 'backbone'
import $ from 'jquery'
import FormView from '../Form'
import CompactReportFormModel from '../../model/report/CompactReportForm'
import DateTimePickerRangeView from '../DateTimePickerRange'
import CompactReportSelectView from './CompactReportSelect'
import notificationPopupView from '../NotificationPopup'

const CompactReportFormView = FormView.extend({
  model: CompactReportFormModel,
  mergeEvents: true,
  events: {
    'click [data-button=pdf]': 'exportToPdf',
    'click [data-button=xls]': 'exportToXls',
    'click [data-button=clearFilters]': 'clearFiltersButtonHandler'
  },
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form:first')
    view.el = view.$el[0]
    view.addSubView('dateTimePickerRange', DateTimePickerRangeView)
    view.addSubView('select', CompactReportSelectView, { el: view.$('[data-select=dateRange]') })

    FormView.apply(this, arguments)
  },
  initialize: function () {
    var view = this

    view.model.on('invalid', function (model, error) {
      notificationPopupView.render(error)
    })
    view.syncData()
    view.assignReportDataId()
    view.submit()
  },
  assignReportDataId: function () {
    throw new Error('assignReportDataId is not implemented')
  },
  submit: function (e) { // override
    var view = this
    var tableGrid = view.getParentView().findSubView('compactReportTableGrid')

    Backbone.sync('read', view.model, {
      data: $.param(view.model.toJSON()),
      success: function (collection) {
        tableGrid.model.set('tableRow', collection)
        tableGrid.render()
        if (view.getParentView().$el.css('visibility') === 'hidden') {
          view.getParentView().show() // show report
        }
      }
    })
  },
  clearFiltersButtonHandler: function () {
    var view = this
    var tableGridView = view.getParentView().findSubView('compactReportTableGrid')
    var tableDropView = tableGridView.findSubView('tableDrop')

    tableDropView.clearAllFilterData()
    tableGridView.hideAllFilterIcons()
    tableGridView.render()
  },
  generatePdfXlsReportURL: function () {
    throw new Error('generatePdfXlsReportURL not implemented')
  },
  exportToPdf: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('PDF'))
  },
  exportToXls: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('XLS'))
  },
  destroy: function () {
    this.model.off('invalid')
    this.findSubView('dateTimePickerRange').destroy()
    this.findSubView('select').destroy()
    this.remove()
  }
})

export default CompactReportFormView
