import TableGridModel from '../TableGrid@'
import DriverWorkingHoursDetailsList from '../../collection/driverWorkingHours/DriverWorkingHoursDetailsList'

const DriverWorkingHoursDetailsTableGridModel = TableGridModel.extend({
  defaults: {
    tableHead: [
      {
        tableHeadName: 'Icon',
        noDrop: true
      },
      {
        tableHeadName: 'Status',
        noDrop: true
      },
      {
        tableHeadName: 'Date | Time',
        noDrop: true
      },
      {
        tableHeadName: 'Duration',
        noDrop: true
      },
      {
        tableHeadName: 'Location',
        noDrop: true
      },
      {
        tableHeadName: 'Postcode',
        noDrop: true
      },
      {
        tableHeadName: 'POI/AOI',
        noDrop: true
      }
    ],
    tableRow: DriverWorkingHoursDetailsList
  }
})

export default DriverWorkingHoursDetailsTableGridModel
