import $ from 'jquery'
import Backbone from 'backbone'
import FormView from '../../Form'
import TodaysJourneyFormModel from '../../../model/report/todaysJourney/TodaysJourneyForm'

const TodaysJourneyFormView = FormView.extend({
  model: TodaysJourneyFormModel,
  elSelector: '[data-form=refresh]',
  constructor: function () {
    FormView.apply(this, arguments)
  },
  initialize: function (option) {
    var view = this
    var today = $.datepicker.formatDate('dd.mm.yy ', new Date())

    view.model.set('unitIdList', window.wls.getLastClickedVehicle().data.id)
    view.model.set('from', today)
    view.model.set('to', today)
    view.submit()
  },

  submit: function () {
    var view = this
    var tableGrid = view.getParentView().findSubView('compactReportTableGrid')

    view.$('input:checkbox:checked').prop('checked', false)

    if (tableGrid) {
      tableGrid.model.set('tableRow', [])

      Backbone.sync('create', view.model, {
        data: $.param(view.model.toJSON()),
        success: function (collection) {
          if (collection.journeySections && !collection.journeySections.length) {
            view.getParentView().getParentView().destroy()
            alert('No data against this Unit for today')
          } else {
            window.wls.resetState()
            window.wlsMapTools.saveState()
            window.wlsMapTools.hideToolsFrame()
            window.wlsMapTools.hide()
            $('.left-panel').fadeOut(400)

            tableGrid.model.set('tableRow', collection.journeySections[0].daySubSections[0].journeyList)
            tableGrid.render()
            view.getParentView().$el.css({ display: 'block' }).fadeIn(300)
            tableGrid.findSubView('scroll').render()
          }
          // window.toPoiCollection = collection.activityLogRecordList
          // tableGrid.getParentView().$('.table-spinner').removeClass('active')
          // parentView.setPlotAllActive(collection.activityLogRecordList.length > 0)
          // parentView.$el.css('height', '')
        },
        error: function (msg) {
          console.log(msg.status + ' ' + msg.statusText)
          view.getParentView().getParentView().destroy()
        }
      })
    }
  },

  destroy: function () {
    var view = this
    view.remove()
  }
})

export default TodaysJourneyFormView
