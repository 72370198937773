import BusinessPrivateReportPopupView from './BusinessPrivateReportPopup'
import JourneyReportSelectionMenuView from '../journeyReport/JourneyReportSelectionMenu'
import ReportPageView from '../ReportPage'
import BusinessPrivateReportView from './BusinessPrivateReport'

const BusinessPrivateReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="journey"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', BusinessPrivateReportPopupView)
    view.addSubView('selectionMenu', JourneyReportSelectionMenuView)
    view.addSubView('report', BusinessPrivateReportView)
    view.render()
  }
})

export default BusinessPrivateReportPageView
