import $ from 'jquery'
import SelectionMenuVehicleListScrollView from '../SelectionMenuVehicleListScroll'

const ProximitySelectionMenuVehicleListScrollView = SelectionMenuVehicleListScrollView.extend({
  elSelector: '[data-scroll=proximitySelectionMenuVehicleList]',
  calculateScrollContentSize: function () {
    var selectionMenuView = this.getParentView().getParentView()
    var menuTabsetHeight = selectionMenuView.getParentView().proximity.innerView.$el.height()
    this.$frame.height($(window).height() - selectionMenuView.$el.find('.control-holder').height() - 10 - menuTabsetHeight) // 62 control-holder height
  }
})

export default ProximitySelectionMenuVehicleListScrollView
