import ReportFormModel from '../ReportForm'

const IdlingReportFuelUsageFormModel = ReportFormModel.extend({
  defaults: {
    idlingTimeGreaterThan: null,
    litersUsedGreaterThan: null,
    operation: ''
  }
})

export default IdlingReportFuelUsageFormModel
