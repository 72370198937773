import FormView from '../Form'
import EmergencyPhoneNumberModel from '../../model/phoneNumber/EmergencyPhoneNumber'

const EmergencyPhoneNumberView = FormView.extend({
  model: EmergencyPhoneNumberModel,
  elSelector: '[data-form=phoneNumber]',
  mergeEvents: true,
  initialize: function (option) {
    var view = this

    view.resetElement()
    view.model.set('phoneNumber', view.model.get('phoneNumber'))

    view.on('save', function (model) {
      window.settings.get(true)
    })
  },

  updateModel: function (tel) {
    this.model = new EmergencyPhoneNumberModel({ phoneNumber: tel, userId: window.phoneEditNumberId })
    this.render()
  },

  submit: function () {
    var view = this

    view.getParentView().hide()

    FormView.prototype.submit.apply(this, arguments)

    setTimeout(function () {
      window.wls.updateCars()
    }, 1000)
  },

  destroy: function () {
    var view = this
    view.remove()
  }

})

export default EmergencyPhoneNumberView
