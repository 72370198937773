import VehicleDefectReportFormView from './VehicleDefectReportForm';
import VehicleDefectReportTableGridView from './VehicleDefectReportTableGrid';
import NotificationsPopupView from './NotificationsPopup';
import ChecklistModalView from './checklist/ChecklistModal';
import CheckListsModalView from './checklist/CheckListsModal';
import VehicleListModalView from './checklist/VehicleListModal';
import View from '../../View.js';


const VehicleCheckedDefectReportView = View.extend({
    mergeEvents: true,
    // templateUrl: "template/report/vehicleDefectReport/vehicleDefectReport.html";

    events: {
        "click [data-flex]": "showManageDrivers",
        "click [data-popup=notes] [data-button=close]": "hideNote",
        "click [data-button=notifications]": "showNotifications",
        "click [data-button=newChecklist]": "showAddChecklist",
        "click [data-button=checklists]": "showCheckLists",
        "click [data-button=vehicleList]": "showVehicleList",
    },

    initialize(option) {
        View.prototype.initialize.call(this, option);

        this.addSubView("notificationsPopup", NotificationsPopupView);
        this.addSubView("vehicleListModal", VehicleListModalView);
        this.addSubView("checklistsModal", CheckListsModalView);
        this.addSubView("checklistModal", ChecklistModalView);
        this.addSubView(
            "compactReportTableGrid",
            VehicleDefectReportTableGridView,
            this.$("[data-table-grid=vehicle-defect]")
        ).once("ready", () => {
            this.addSubView("compactReportForm", VehicleDefectReportFormView);
        });
    },

    hideNote() {
        this.findSubView("compactReportTableGrid").hideNote();
    },

    showManageDrivers(e) {
        const $button = $(e.currentTarget);

        $("iframe").attr("src", $button.attr("data-flex"));
        $(".modal-popup .header-panel .title").text("Manage Drivers");
    },

    showVehicleList() {
        const vehicleListModal = this.findSubView("vehicleListModal");

        vehicleListModal.getList();
        vehicleListModal.show();
        vehicleListModal.align();
    },

    showAddChecklist(e) {
        const checklistModal = this.findSubView("checklistModal");

        checklistModal.loadDefaultList();
    },

    showCheckLists() {
        const checklistsModal = this.findSubView("checklistsModal");

        checklistsModal.getList();
        checklistsModal.show();
        checklistsModal.align();
    },

    showNotifications(e) {
        const $button = $(e.currentTarget);
        const notificationsPopupView = this.findSubView("notificationsPopup");
        const vehicleDefectReportTableGridView = this.findSubView("compactReportTableGrid");

        vehicleDefectReportTableGridView.hideActivePopup();
        vehicleDefectReportTableGridView.resetSelectedItemAndRow();
        notificationsPopupView.renderForm();
        notificationsPopupView.alignBy($button, "center", { top: 5 });
        notificationsPopupView.show();
    },

    hideNotifications() {
        this.findSubView("notificationsPopup").hide();
    },

    slide(e) {
        // override
        this.findSubView("compactReportTableGrid").hideActivePopup();
        this.hideNotifications();
    },

    destroy() {
        this.findSubView("notificationsPopup").destroy();
        this.findSubView("compactReportTableGrid").destroy();
        this.findSubView("compactReportForm").destroy();
        this.remove();
    }
});

export default VehicleCheckedDefectReportView;

