import PoiModel from '../../model/Poi'
import Collection from '../Collection'

const AlertAvailablePoiList = Collection.extend({
  model: PoiModel,
  id: null,
  url: function () {
    return 'services/geofence/alert-available-poi/' + (this.id ? this.id : '')
  }
})

export default AlertAvailablePoiList
