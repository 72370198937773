import CounterView from '../../Counter'

const TimeCounterView = CounterView.extend({
    events:{
        "click [data-button=up]": "up",
        "click [data-button=down]": "down",
        "keyup input[type=text]": "inputEventHandler"
    },
    eventTimeout: null,
    up: function(e){
        var val = this.$input.val().split(':');
        var totalTime = parseInt(val[0]) * 60 + parseInt(val[1]);
        var hour, mins;
        var name = $(e.currentTarget).parents('label').attr('data-counter');

        // if(isNaN(val)){
        //     val = 0;
        // }

        if(totalTime + this.step > this.maxVal){
            totalTime = this.maxVal;
        }else{
            totalTime += this.step
        }

        hour = Math.floor(totalTime / 60);
        mins = totalTime % 60;
        var time = (hour < 10 ? '0' + hour : hour) + ":" + (mins < 10 ? '0' + mins : mins);

        this.$input.val(time);
        this.getParentView().changeDayTimeInModel(time, name);
    },
    down: function(e){
        var val = this.$input.val().split(':');
        var totalTime = parseInt(val[0]) * 60 + parseInt(val[1]);
        var hour, mins;
        var name = $(e.currentTarget).parents('label').attr('data-counter');

        // if(isNaN(val)){
        //     val = 0;
        // }
        if(totalTime - this.step < this.minVal){
            totalTime = this.minVal;
        }else{
            totalTime -= this.step
        }

        hour = Math.floor(totalTime/60);
        mins = totalTime % 60;
        var time = (hour < 10 ? '0' + hour : hour) + ":" + (mins < 10 ? '0' + mins : mins);

        this.$input.val(time);
        this.getParentView().changeDayTimeInModel(time, name);
    },
    inputEventHandler: function(e) {
        var view = this;
        var val = view.$input.val();
        var time = val.split(':');
        var totalTime = parseInt(time[0]) * 60 + parseInt(time[1]);
        var name = $(e.currentTarget).attr('data-input');

        clearTimeout(this.eventTimeout)

        if (isNaN(totalTime)){
            val = "00:00";
        }

        if(totalTime > this.maxVal){
            val = "23:59"
        }

        view.eventTimeout = setTimeout(function(){
            view.$input.val(val);
            view.getParentView().changeDayTimeInModel(val, name);
            clearTimeout(view.eventTimeout);
        }, 1000);
    }
});
export default TimeCounterView
