import JourneyReportSelectionMenuVehicleListScrollView from './JourneyReportSelectionMenuVehicleListScroll'
import JourneyReportSelectionMenuVehicleList from '../../../collection/report/journeyReport/JourneyReportSelectionMenuVehicleList'
import SelectionMenuVehicleListView from '../../SelectionMenuVehicleList'

const JourneyReportSelectionMenuVehicleListView = SelectionMenuVehicleListView.extend({
  collection: JourneyReportSelectionMenuVehicleList,
  templateUrl: 'template/report/journeyReport/journeyReportSelectionMenuVehicleList.html',
  elSelector: '[data-vehicle-list=journey]',
  initialize: function () {
    var view = this

    view.addSubView('scroll', JourneyReportSelectionMenuVehicleListScrollView)
    view.render()
  }
})

export default JourneyReportSelectionMenuVehicleListView
