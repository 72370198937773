import $ from 'jquery'
import CounterView from '../../../view/Counter'
import IdlingReportFormModel from '../../../model/report/idling/IdlingReportForm'
import ReportFormView from '../ReportForm'

const IdlingReportFormView = ReportFormView.extend({
  model: IdlingReportFormModel,
  initialize: function (option) {
    var view = this

    view.addSubView('idlingCounter', CounterView, { el: view.$('[data-counter=idling]') })
  },
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'Idling Report',
      'report-type': 'Idling',
      'report-format': model.get('repFormat'),
      alternate: false,
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      idling: model.get('idling') * 60,
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/')
    }
    return $.param(data)
  },
  destroy: function () {
    var view = this

    view.findSubView('idlingCounter').destroy()
    ReportFormView.prototype.destroy.apply(this, arguments)
  }
})

export default IdlingReportFormView
