
import Tabset from '../../../model/Tabset'
import TabsetView from '../../Tabset'
import View from '../../View'
import $ from 'jquery'
import AvailableSelectedTableGridView from '../AvailableSelectedTableGrid'
import TowAwayAlertSelectRecipientView from './TowAwayAlertSelectRecipient'

const tabView = {
  'view/alert/AvailableSelectedTableGrid': AvailableSelectedTableGridView,
  'view/alert/towAway/TowAwayAlertSelectRecipient': TowAwayAlertSelectRecipientView
}

const TowAwayTabsetView = TabsetView.extend({
  model: Tabset,
  mergeEvents: true,
  events: {
    'click [data-tabset-control]:first [data-target]': 'switchTabHandler'
  },
  constructor: function (option) {
    var view = this
    view.$el = $(option.el)
    view.model = new view.model()

    option.beforeInitTabCallback && option.beforeInitTabCallback(view, view.model)

    view
      .listenTo(view.model, 'change:activeTab', function (tabsetModel, activeTab) {
        view.switchTab(tabsetModel, activeTab)
        view.initTab('selectVehicle')
        view.initTab('selectRecipient')
      })
      .model
      .set('activeTab', view.$el.find('[data-target]:first').attr('data-target'))

    option.afterInitTabCallback && option.afterInitTabCallback(view, view.model)

    View.apply(this, arguments)
  },
  initTab: function (activeTab) {
    var view = this
    var $activeTab = view.$el.find('[data-tab=' + activeTab + ']')

    if (!(activeTab in view)) {
      const InnerView = tabView[$activeTab.data('view')]

      console.log($activeTab.data('view'))

      InnerView.prototype.parentView = view
      view[activeTab] = {
        innerView: new InnerView({
          el: $activeTab
        })
      }
      view.trigger('initTab', view[activeTab], activeTab)
      // require([$activeTab.data('view')], function (innerView) {
      //   innerView.prototype.parentView = view
      //   view[activeTab] = {
      //     innerView: new innerView({
      //       el: $activeTab
      //     })
      //   }
      //   view.trigger('initTab', view[activeTab], activeTab)
      // })
    }
  }
  // getActiveTabView: function(){
  //     return this[this.model.get("activeTab")];
  // },
  // get$ActiveTab: function(){
  //     return this.$el.find("[data-tab=" + this.model.get("activeTab") + "]");
  // },
  // switchTab: function(tabsetModel,activeTab){
  //     this
  //         .switchClassActive(this.$el,"[data-tab=" + activeTab + "]")
  //         .switchClassActive(this.$el,"[data-target=" + activeTab + "]");
  //     return this;
  // },
  // switchClassActive: function($el,selector){
  //     $el
  //         .find(selector)
  //         .addClass("active")
  //         .siblings()
  //         .removeClass("active");
  //     return this;
  // },
  // switchTabHandler: function(e){
  //     this.model.set("activeTab", e.currentTarget.getAttribute("data-target"))
  // },
  // setTabView: function(tabName){
  //     this[tabName];
  // }
})

export default TowAwayTabsetView
