
import AlertTableGrid from "../AlertTableGrid";
import UnpluggedDeviceAlertList from "../../../collection/alert/unpluggedDevice/UnpluggedDeviceAlertList";

const UnpluggedDeviceAlertTableGrid = AlertTableGrid.extend({
    defaults: {
        tableRow: UnpluggedDeviceAlertList
    }
});

export default UnpluggedDeviceAlertTableGrid;
