import JqueryUiWidgetModel from './JqueryUiWidget'

const SelectModel = JqueryUiWidgetModel.extend({
  defaults: {
    widgetName: 'selectmenu',
    appendTo: null,
    disabled: false,
    icons: { button: 'ui-icon-triangle-1-s' },
    position: { my: 'left top', at: 'left bottom', collision: 'none' },
    width: null
  }
})

export default SelectModel
