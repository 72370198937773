
import FormView from "../../Form";
import UnpluggedDeviceAlertModel from "../../../model/alert/unpluggedDevice/UnpluggedDeviceAlert";

const UnpluggedDeviceAlertViewFormView = FormView.extend({
    model: UnpluggedDeviceAlertModel,
    mergeEvents: true,
    events: {
        "change input[name=sendToSelf]": "sendToSelfChange"
    },
    constructor: function() {
        const UnpluggedDeviceAlert = this.parentView;

        this.el = UnpluggedDeviceAlert.$el.find("form");
        FormView.apply(this, arguments);
    },
    initialize: function() {
        const view = this,
            notificationPopupView = view.findSubView("notificationPopup"),
            UnpluggedDeviceAlertView = this.parentView;
            let UnpluggedDeviceAlertTabsetView;
            let UnpluggedDeviceAlertListPopup
            let UnpluggedDeviceAlertTableGridView;

        view.listenTo(view.model, "change:sendToSelf", function(model, sendToSelf) {
            view.renderSendToSelf(sendToSelf);
        });
        UnpluggedDeviceAlertView.on("ready", function() {
            UnpluggedDeviceAlertTabsetView = UnpluggedDeviceAlertView.tabsetView;
            UnpluggedDeviceAlertListPopup = UnpluggedDeviceAlertView.findSubView("alertListPopup");
            UnpluggedDeviceAlertTableGridView = UnpluggedDeviceAlertListPopup.findSubView("alertTableGrid");

            view.on("cancel", function() {
                const selectTrackingDevice = "selectTrackingDevice" in UnpluggedDeviceAlertTabsetView ?
                    UnpluggedDeviceAlertTabsetView.selectTrackingDevice.innerView : null;
                const selectRecipientTableGridView = "selectRecipient" in UnpluggedDeviceAlertTabsetView ?
                    UnpluggedDeviceAlertTabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid") : null;

                view.getParentView().switchTitle("create");
                UnpluggedDeviceAlertTableGridView.resetSelectedItemAndRow();
                if (selectTrackingDevice) {
                    selectTrackingDevice.reloadContent();
                }
                if (selectRecipientTableGridView) {
                    selectRecipientTableGridView.reset();
                }
            });
            view.on("save", function() {
                UnpluggedDeviceAlertTableGridView.model.get('tableRow').reset();
                if (UnpluggedDeviceAlertListPopup.isVisible()) {
                    UnpluggedDeviceAlertTableGridView.fetchAlertlist();
                }
                view.cancel();
                notificationPopupView.render({
                    msgId: "successfully_saved",
                    title: "",
                    message: "Successfully saved.",
                    button: ["OK"]
                });
            });
            view.model.on("invalid", function(model, error) {
                notificationPopupView.render(error);
            });
        });
    },
    sendToSelfChange: function(e) {
        const $checkbox = $(e.currentTarget);
        this.model.set("sendToSelf", $checkbox.prop("checked"), { silent: true });
    },
    renderSendToSelf: function() {
        this.$el.find("input[name=sendToSelf]").prop("checked", this.model.get("sendToSelf"));
    },
    cancel: function(){
        this.getParentView().model.set({dataMode: "create"});
        // this.getParentView().tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.id = 0;
        this.reset();
        this.trigger("cancel");
    },
});

export default UnpluggedDeviceAlertViewFormView;
