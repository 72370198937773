import View from '../../View'
import DetailedReportModel from '../../../model/report/detailed/DetailedReport'
import ReportView from '../Report'
import JourneyReportScrollView from '../journeyReport/JourneyReportScroll'

const DetailedReportView = ReportView.extend({
  model: DetailedReportModel,
  mergeEvents: true,
  initialize: function () {
    var view = this

    view.addSubView('scroll', JourneyReportScrollView)
  },
  destroy: function () {
    var view = this
    console.log('destroy()')
    view.removeSubView('scroll')
  }
})

export default DetailedReportView
