import Model from '../../Model'

const DrivingTimeModel = Model.extend({
  defaults: {
    vehReg: '',
    driverName: '',
    drivingTime: null,
    stoppedTime: null,
    idlingTime: null,
    totalTime: null
  }
})

export default DrivingTimeModel
