/**
 * Created by George on 10.04.2017.
 */
import ProfileBuilderTabsetView from '../profileManager/ProfileBuilderTabset';

const DriverBehaviorProfileBuilderTabset = ProfileBuilderTabsetView.extend({
    templateUrl: "template/alert/driverBehavior/driverBehaviorProfileBuilderTabset.html"
});

export default DriverBehaviorProfileBuilderTabset;

