import SelectedTableGridModel from '../../model/alert/SelectedTableGrid'
import TableGridView from '../TableGrid'
import $ from 'jquery'

const SelectedTableGridView = TableGridView.extend({
  model: SelectedTableGridModel,
  mergeEvents: true,
  events: {
    'change input:checkbox': 'setEntryExit'
  },
  initialize: function () {
    var view = this

    $(window).resize(function () {
      view.scroll.render()
    })
  },
  renderGrid: function () {
    this.render()
    this.scroll.render()

    return this
  },
  getModelById: function ($tr) {
    var id = $tr.attr('data-cid') || $tr.attr('data-id')
    var model = this.collection.get(id)

    return model
  },
  setEntryExit: function (e) {
    var checkbox = e.currentTarget
    var checkboxName = checkbox.name
    var checked = $(checkbox).prop('checked')
    var $tr = $(checkbox).closest('tr')

    this.getModelById($tr).set(checkboxName, checked)
  }
})

export default SelectedTableGridView
