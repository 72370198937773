import $ from 'jquery'
import _ from 'underscore'
import View from '../View'
import SelectedVehicleView from '../../view/report/SelectedVehicle'
import sort from '../../util/sort'
import ReportTableDropView from './ReportTableDrop'

const ReportView = View.extend({
  events: {
    'click [data-button=newReport]': 'newReport',
    'click [data-button=slide]': 'slide',
    'mousedown th': 'showDrop',
    //            'click .table-drop li': 'sort',
    'change [data-checkbox=selectAll]': 'selectAllCheckboxHandler',
    'click tbody tr[data-journey-id]': 'selectJourney'
  },
  constructor: function () {
    var view = this
    var args = arguments
    view.model = new view.model()

    window.wls.resetState()
    $('.left-panel').fadeOut(400)
    if (!view.subView) {
      view.setSubView({
        tableDrop: ReportTableDropView
      })
    } else if (!view.hasSubView('tableDrop')) {
      view.addSubView('tableDrop', ReportTableDropView)
    }
    view.loadDotTemplate(function () {
      view.compileTemplate()
      view.templateOptions = window.settings.data
      View.apply(view, args)
    })
    setTimeout(function () {
      console.log('Report: constructor')
      window.mapCanvas.cleanUp()
    }, 1000)
  },
  render: function () {
    var view = this
    var json = view.model.toJSON()
    var reportFormView = view.getParentView().findSubView('reportPopup').findSubView('reportForm')
    var tableDropView = view.findSubView('tableDrop')
    var sortData = tableDropView.getSortData()
    var filterData = tableDropView.getFilterData()
    var sortDataSize = _.size(sortData)
    var filterDataSize = _.size(filterData)
    var rowIndex
    var daySectionIndex
    var activeThIndex = view.activeTh && view.activeTh.index()
    var journeySectionPropertyName = _.keys(view.model.attributes)[0]
    var $report
    var thIndex
    var oldJourneyList
    json.startDate = reportFormView.model.get('from')
    json.endDate = reportFormView.model.get('to')
    json.vehiclesSize = reportFormView.findSubView('selectedVehicle').collection.length

    if (filterDataSize || sortDataSize) {
      daySectionIndex = view.activeTh.closest('[data-section]').index() - 1
      rowIndex = view.activeTh.closest('.row').index()
      thIndex = view.activeTh.index()
      oldJourneyList = json[journeySectionPropertyName][rowIndex]['daySubSections'][daySectionIndex]['journeyList']

      if (sortData[rowIndex] && sortData[rowIndex][daySectionIndex]) {
        json[journeySectionPropertyName][rowIndex]['daySubSections'][daySectionIndex]['journeyList'] = view[sortData[rowIndex][daySectionIndex]['sortOrder']](sortData[rowIndex][daySectionIndex]['field'], json[journeySectionPropertyName][rowIndex]['daySubSections'][daySectionIndex]['journeyList'])
      }
      if (filterData[rowIndex] && _.size(filterData[rowIndex][daySectionIndex])) {
        json[journeySectionPropertyName][rowIndex]['daySubSections'][daySectionIndex]['journeyList'] = view.filter(filterData[rowIndex][daySectionIndex], json[journeySectionPropertyName][rowIndex]['daySubSections'][daySectionIndex]['journeyList'])
      }
      view.$el.find('.row:eq(' + rowIndex + ') [data-section]:eq(' + daySectionIndex + ') tbody').html($(view.dotTemplateToHtml(json)).find('.row:eq(' + rowIndex + ') [data-section]:eq(' + daySectionIndex + ') tbody').html())
      view.$('[data-section=day]').find('th.active').removeClass('active')
      json[journeySectionPropertyName][rowIndex]['daySubSections'][daySectionIndex]['journeyList'] = oldJourneyList
      view.activeTh = null
      return
    }

    $report = $(view.dotTemplateToHtml(json))

    if (view.$el.attr('data-report')) {
      view.$el.html($report.html())
    } else {
      view.setElement($report)
      $('#content').append(view.$el)
      $(document).on('click.tableDrop', function (e) {
        var currEl = $(e.target)

        if (!currEl.closest('th').size() && !currEl.closest('[data-drop]').size()) {
          view.findSubView('tableDrop').hide()
          view.activeTh = null
          view.$('[data-section=day]').find('th.active').removeClass('active')
        }
      })
    }
    view.$el.fadeIn(300, function () {
      if (!view.hasSubView('selectedVehicle')) {
        view.addSubView('selectedVehicle', SelectedVehicleView, { el: view.$('[data-list=selectedVehicle]') })
        view.findSubView('selectedVehicle').once('ready', function () {
          this.on('imageLoad', () => {
            view.findSubView('scroll').render()
          })
          this.syncSelectedVehicleList(view.getParentView().getReportFormSelectedVehicleView().collection.toJSON())
          this.render()
        })
        return
      } else {
        view.findSubView('selectedVehicle').syncSelectedVehicleList(view.getParentView().getReportFormSelectedVehicleView().collection.toJSON())
        view.findSubView('selectedVehicle').setElement(view.$('[data-list=selectedVehicle]')).render()
      }
    })
    return view
  },
  showSortIcon: function ($th, sortOrder) {
    var iconClass = sortOrder === 'sortAsc' ? 'asc' : 'desc'

    $th.find('[data-icon=sort]').removeClass('asc').removeClass('desc').addClass(iconClass)
    this.hideSortIcon($th.siblings())
  },
  hideSortIcon: function ($th) {
    $th.find('[data-icon=sort]').removeClass('asc').removeClass('desc')
  },
  hideFilterIcon: function ($th) {
    $th.find('[data-icon=filter]').removeClass('filter')
  },
  showFilterIcon: function ($th) {
    $th.find('[data-icon=filter]').addClass('filter')
  },
  newReport: function () {
    var snailTrailPopup = this.findSubView('snailTrailPopup')
    var reportTableDropView = this.findSubView('tableDrop')

    reportTableDropView.clearAllFilterData()
    reportTableDropView.clearAllSortData()
    this.$el.fadeOut(300)
    this.getParentView().findSubView('reportPopup').show()
    this.getParentView().findSubView('selectionMenu').show()
    snailTrailPopup.hide()
  },
  slide: function (e) {
    var slideBtn = $(e.currentTarget)
    var leftPosition = 0

    if (slideBtn.hasClass('active')) {
      leftPosition = 0
    } else {
      leftPosition = -this.$el.width()
    }

    slideBtn.toggleClass('active')
    this.$el.stop().animate({ left: leftPosition }, 600)
  },
  findActiveTh: function () {
    return this.activeTh
  },
  showDrop: function (e) {
    var view = this
    var $th = $(e.currentTarget)
    var reportTableDropView = view.findSubView('tableDrop')
    var offset = $th.offset()
    var $daySection = $th.closest('[data-section=day]')

    if ($(e.target).hasClass('ui-draggable')) return

    if (!$th.hasClass('active')) {
      reportTableDropView.render(view.model.get('tableHead')[$th.index()])
      reportTableDropView.show(e.pageX + 1, e.pageY + 1)
      view.activeTh = $th
      //                view.$('.table-drop').show().css({'top' : offset.top + $th.outerHeight(),'left' : e.pageX})
    } else {
      view.activeTh = null
      reportTableDropView.hide()
    }
    $th.toggleClass('active').siblings().removeClass('active')
    view.$('[data-section=day]').not($daySection).find('th.active').removeClass('active')
  },
  showDropOld: function (e) {
    var view = this
    var $th = $(e.currentTarget)
    var offset = $th.offset()
    var $daySection = $th.closest('[data-section=day]')

    if ($(e.target).hasClass('ui-draggable')) return

    if (!$th.hasClass('active')) {
      view.$('.table-drop').show().css({ top: offset.top + $th.outerHeight(), left: e.pageX })
    } else {
      view.$('.table-drop').hide()
    }
    $th.toggleClass('active').siblings().removeClass('active')
    view.$('[data-section=day]').not($daySection).find('th.active').removeClass('active')
  },
  goToPOI: function (el) {
    el.preventDefault()

    var $el = $('.report #addPoiMenu')
    var _$el = $(el.currentTarget)
    this.$curr = _$el.attr('data-logId')
    var pageX = _$el.position().left + _$el.width() - $el.width() + 10
    var pageY = _$el.position().top + (_$el.outerHeight() / 2) - ($el.height() / 2) - 1

    $el.css({ left: pageX, top: pageY })
    $el.delay(100).show()
  },
  outFromPOI: function (e) {
    var $el = $('.report #addPoiMenu')

    $el.hide()
  },
  addNewPoi: function () {
    var $report = $('.report')

    this.outFromPOI()
    $report.hide()
    this.getPOIcoordinates(this.$curr)
  },

  sort: function (e) {
    var view = this
    var $li = $(e.currentTarget)
    var $th = view.$('th.active')
    var thIndex = $th.index()
    var $table = view.$('[data-table-grid=journey]:has(th.active)')
    var $tr = $table.find('tbody tr')
    var order = $li.attr('data-sort-order')

    $table.append(sort[order]($tr, thIndex))
    view.$('[data-drop=table]').hide()
    $th.siblings().find('.table-icon').removeClass('asc').removeClass('desc')
    $th.removeClass('active').find('.table-icon').removeClass('asc').removeClass('desc').addClass(order)
  },
  sortAsc: function (field, collection) {
    return _.sortByNat(collection, field)
  },
  sortDesc: function (field, collection) {
    return _.sortByNat(collection, field).reverse()
  },
  filter: function (filterData, collection) {
    var view = this
    var filterDataLen = _.size(filterData)

    function iteratee (element) {
      collection = _.filter(collection, function (model) {
        return view[element.filterFnName](model[element.field], element.value)
      })
    }
    if (filterDataLen >= 1) {
      _.each(filterData, function (value) {
        _.each(value, iteratee)
      })
    } else {
      throw new Error('filterData is empty')
    }
    return collection
  },
  greaterThan: function (fieldValue, inputValue) {
    return fieldValue > inputValue
  },
  lessThan: function (fieldValue, inputValue) {
    return fieldValue < inputValue
  },
  startsWith: function (fieldValue, inputValue) {
    var regExp = new RegExp('^' + inputValue, 'gi')

    return (regExp).test(fieldValue)
  },
  selectAllCheckboxHandler: function (e) {
    var view = this
    var $checkbox = $(e.currentTarget)
    var $daySection = $checkbox.closest('[data-section=day]')
    var $tr = $daySection.find('[data-table-grid=journey] tr')

    if ($checkbox.prop('checked')) {
      view.selectAll($tr.filter(':not(.active)'))
    } else {
      view.selectAll($tr.filter('.active'))
    }
  },
  selectAll: function ($tr) {
    var view = this

    if ($tr.size() === 1) {
      $tr.addClass('active').siblings().removeClass('active')
      view.$('input:checkbox:checked').prop('checked', false)
    } else {
      $tr.toggleClass('active')
    }
    view.resetSelectedJourneyAndCheckbox($tr)
    if (view.$('tr.active').size()) {
      $('.report .slide-button').click()
      view.findSubView('snailTrailPopup').showSnailTrail()
    } else {
      view.findSubView('snailTrailPopup').hide()
    }
  },
  getSelectedJourneyList: function () {
    var view = this
    var journeyIdList = []
    var $tr = view.$('tr.active')

    return $tr.map(function () {
      return this.getAttribute('data-journey-id')
    }).get()
  },
  getActiveRows: function () {
    var view = this
    return view.$('tr.active')
  },
  selectJourney: function (e) {
    var $tr = $(e.currentTarget)

    this.selectAll($tr)
  },
  resetSelected: function () {
    this.$('tr.active').removeClass('active')
    this.$('input:checkbox:checked').prop('checked', false)
  },
  resetSelectedJourneyAndCheckbox: function ($tr) {
    var view = this
    var $daySection = $tr.closest('[data-section=day]')

    view.$('[data-section=day]').not($daySection).find('tr.active').removeClass('active').end().find('[data-checkbox]:checked').prop('checked', false)
  },
  hideSnailTrailPopup: function () {
    var snailTrailPopup = this.findSubView('snailTrailPopup')
    if (snailTrailPopup.isVisible()) {
      snailTrailPopup.hide()
      snailTrailPopup.findSubView('snailTrailTableGrid').removeSnailTrailFromMap()
    }
  },
  showLock: function (lockedPropertyName) {
    var vehicles = window.wls.getVehicles()
    var length = vehicles.length
    var currentVehicle
    var hasLock = false

    for (var i = 0; i < length; i++) {
      currentVehicle = vehicles[i]
      if (currentVehicle.data.lockedProperties[lockedPropertyName]) {
        hasLock = true
        currentVehicle.HTML.find('.lock').addClass('active')
      }
    }
    if (hasLock) {
      window.panel.expandAll.apply($('.left-panel .expand'))
    }
  },
  hideLock: function () {
    $('.left-panel .lock').removeClass('active')
  },
  isVisible: function () {
    return this.findSubView('snailTrailPopup').isVisible() || this.findSubView('reportPopup').isVisible() || this.$el.is(':visible')
  },
  fetchReportModel: function () {
    var reportView = this
    var reportPopupView = reportView.getParentView().findSubView('reportPopup')
    var reportFormView = reportPopupView.findSubView('reportForm')
    var model = reportFormView.model

    var dateParams = model.get('from').split(/[\.]/)
    var yyyy = dateParams[2]
    var mm = dateParams[1]
    var dd = dateParams[0]
    var fromDateStr = mm + '/' + dd + '/' + yyyy

    dateParams = model.get('to').split(/[\.]/)
    yyyy = dateParams[2]
    mm = dateParams[1]
    dd = dateParams[0]
    var toDateStr = mm + '/' + dd + '/' + yyyy
    var from = Date.parse(fromDateStr + ' ' + model.get('fromTime'))
    var to = Date.parse(toDateStr + ' ' + model.get('toTime'))

    model.set('fromDate', from)
    model.set('toDate', to)
    window.modelForSnailTrailSpeedingReport = model

    reportView.model.fetch({
      data: $.param(model.toJSON()),
      success: function (model) {
        if (reportView.isModelEmpty(model)) {
          alert('No data against this Unit for range selected')
          return
        }
        reportView.getParentView().findSubView('selectionMenu').hide()
        if (!$('.top-bar').is(':hidden')) {
          $('.top-bar').animate({
            'margin-left': 0
          }, 500, null)
        }
        reportPopupView.hide()
        reportView.render()
      }
    })
  },
  isModelEmpty: function (model) {
    if (model.get('journeySections') === undefined || model.get('journeySections') == null) {
      return true
    } else {
      return model.get('journeySections') && !model.get('journeySections').length
    }
  },
  findTableHeadOptions: function ($th) {
    return this.model.get('tableHead')[$th.index()]
  },
  destroy: function () {
    $(document).off('click.tableDrop')
    if (this.hasSubView('tableDrop')) {
      this.findSubView('tableDrop').destroy()
    }
    if (this.hasSubView('snailTrailPopup')) {
      this.findSubView('snailTrailPopup').destroy()
    }
    if (this.hasSubView('scroll')) {
      this.findSubView('scroll').destroy()
    }
    if (this.hasSubView('selectedVehicle')) {
      this.findSubView('selectedVehicle').destroy()
    }
    this.remove()
    if (window.mapCanvas.isStreetViewVisible()) {
      window.mapCanvas.toggleStreetView(false)
    }
  }
})

export default ReportView
