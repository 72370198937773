import $ from 'jquery'
import View from './View'

const DateTimePickerRangeView = View.extend({
  elSelector: '[data-date-time-picker-range]',
  constructor: function (option) {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find(view.elSelector)
    view.el = view.$el
    view.initFromDateTimePicker()
    view.initToDateTimePicker()
    view.insertDatepickerTrigger()
    view.today()
    View.apply(view, arguments)
  },
  insertDatepickerTrigger: function () {
    var view = this

    view.$('[data-input=from],[data-input=to]').each(function () {
      $(this).closest('.date').find('.calendar-btn').append($(this).next('button'))
    })
  },
  initFromDateTimePicker: function () {
    var view = this

    view.$('[data-input=from]').datetimepicker({
      showOn: 'button',
      buttonImage: '',
      selectOtherMonths: true,
      beforeShow: function (input, inst) {
        $(this).closest('.date').find('.calendar-btn').addClass('active')
      },
      onClose: function (dateText, inst) {
        view.insertDatepickerTrigger()
        $(this).closest('.date').find('.calendar-btn').removeClass('active')
      }
    })
  },
  initToDateTimePicker: function () {
    var view = this

    view.$('[data-input=to]').datetimepicker({
      showOn: 'button',
      buttonImage: '',
      selectOtherMonths: true,
      beforeShow: function (input, inst) {
        $(this).closest('.date').find('.calendar-btn').addClass('active')
      },
      onClose: function (selectedDate) {
        view.insertDatepickerTrigger()
        $(this).closest('.date').find('.calendar-btn').removeClass('active')
      }
    })
  },
  today: function () {
    var view = this
    var today = new Date()

    today.setHours(0, 0)
    view.$('[data-input=from]').datetimepicker('setDate', today)
    today.setHours(23, 59)
    view.$('[data-input=to]').datetimepicker('setDate', today)
    view.insertDatepickerTrigger()
  },
  yesterday: function () {
    var view = this
    var yesterday = new Date()

    yesterday.setDate(yesterday.getDate() - 1)
    yesterday.setHours(0, 0)
    view.$('[data-input=from]').datetimepicker('setDate', yesterday)
    yesterday.setHours(23, 59)
    view.$('[data-input=to]').datetimepicker('setDate', yesterday)
    view.insertDatepickerTrigger()
  },
  thisWeek: function () {
    var view = this
    var date = new Date()

    date.setDate(date.getDate() - (date.getDay() - 1))
    date.setHours(0, 0)
    view.$('[data-input=from]').datetimepicker('setDate', date)
    date.setDate(date.getDate() + 6)
    date.setHours(23, 59)
    view.$('[data-input=to]').datetimepicker('setDate', date)
    view.insertDatepickerTrigger()
  },
  lastWeek: function () {
    var view = this
    var date = new Date()

    date.setDate(date.getDate() - (date.getDay() + 6))
    date.setHours(0, 0)
    view.$('[data-input=from]').datetimepicker('setDate', date)
    date.setDate(date.getDate() + 6)
    date.setHours(23, 59)
    view.$('[data-input=to]').datetimepicker('setDate', date)
    view.insertDatepickerTrigger()
  },
  lastMonth: function () {
    var view = this
    var date = new Date()

    date.setDate(0)
    date.setHours(23, 59)
    view.$('[data-input=to]').datetimepicker('setDate', date)
    date.setDate(1)
    date.setHours(0, 0)
    view.$('[data-input=from]').datetimepicker('setDate', date)
    view.insertDatepickerTrigger()
  },
  destroy: function () {
    this.remove()
  }
})

export default DateTimePickerRangeView
