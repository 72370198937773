import Collection from '../../Collection'
import ReportDriverModel from '../../../model/report/journeyReport/ReportDriver'

const ReportDriverList = Collection.extend({
  model: ReportDriverModel,
  url: function () {
    return 'api/report/drivers'
  }
})

export default ReportDriverList
