import $ from 'jquery'
import SnailTrailPopupView from '../SnailTrailPopup'
import SnailTrailStepPlayView from '../SnailTrailStepPlay'
import JourneyReportSnailTrailTableGridView from './JourneyReportSnailTrailTableGrid'

const JourneyReportSnailTrailPopupView = SnailTrailPopupView.extend({
  url: '/getJourneyDetailsPopup.do',
  initialize: function () {
    this.addSubView('SnailTrailStepPlayer', SnailTrailStepPlayView, { el: this.$('[data-player=stepPlay]') })
    this.addSubView('snailTrailTableGrid', JourneyReportSnailTrailTableGridView)
  },
  show: function () { // override
    $('[data-popup="journeyDetails"]').addClass('journey-report')
    $('#content').prepend($('[data-popup="journeyDetails"]'))

    SnailTrailPopupView.prototype.show.apply(this, arguments)
  }
})

export default JourneyReportSnailTrailPopupView
