import Model from '../../Model'

const VehicleDefectModel = Model.extend({
  idAttribute: 'defectId',
  defaults: {
    defectId: 0,
    vehReg: '',
    fleetId: '',
    driverName: '',
    date: 0,
    location: '',
    postcode: '',
    poiAoi: '',
    odometer: '',
    notes: '',
    defect: '',
    readBy: '',
    status: '',
    vehicleDefectCheckCommentDtoList: []
  }
})

export default VehicleDefectModel
