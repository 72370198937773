
import Model from '../model/Model'

const Popup = Model.extend({
  defaults: {
    visible: false,
    lockAnimation: false,
    htmlReady: false,
    isDraggable: false
  }
})

export default Popup
