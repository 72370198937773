import FormView from '../Form'
import RecipientModel from '../../model/alert/Recipient'

const AddRecipientFormView = FormView.extend({
  model: RecipientModel,
  constructor: function () {
    this.$el = this.parentView.$el.find('form')
    this.el = this.$el[0]
    FormView.apply(this, arguments)
  },
  initialize: function () {
    var view = this
    var addRecipientPopupView = this.parentView
    var notificationPopupView = view.findSubView('notificationPopup')

    view.model.on('invalid', function (model, error) {
      notificationPopupView.render(error)
    })
    notificationPopupView.on('ok', function (message) {
      if (message.msgId === 'user_email_already_exists') {
        view.submit()
      }
    })
    view.on('save', function (recipientModel) {
      var selectRecipientView = addRecipientPopupView.parentView
      var selectRecipientTableGridView = selectRecipientView.findSubView('selectRecipientTableGrid')
      var dataMode = addRecipientPopupView.model.get('dataMode')
      var recipientModelClone = recipientModel.clone()

      if (dataMode === 'create') {
        selectRecipientTableGridView.createEntry(recipientModelClone)
      } else {
        selectRecipientTableGridView.editEntry(recipientModelClone)
      }
      addRecipientPopupView.model.set('visible', false)
      view.cancel()
    })
    view.on('cancel', function () {
      addRecipientPopupView.closeBtnHandler()
      addRecipientPopupView.getParentView().findSubView('selectRecipientTableGrid').resetSelectedItemAndRow()
    })
  }
})

export default AddRecipientFormView
