import ScrollView from '../../../Scroll'

const ChecklistModalTableGridScrollView = ScrollView.extend({
  calculateScrollContentSize: function () {
    if (!this.getParentView().$el.hasClass('info-holder')) {
      this.checkTableHeight()
      this.$frame.height(this.getParentView().$el.height() - 26)
    } else {
      this.$frame.height(this.getParentView().$el.height())
    }
  },
  checkContentSize: function () {
    var _modalLayer = this.getParentView().$el.parents('.modal-layer-content')

    if (!this.getParentView().$el.hasClass('info-holder')) {
      return this.$el.offset().top - _modalLayer.offset().top + this.$('.slidee').height() > _modalLayer.height() - 46
    } else {
      return this.$el.offset().top - _modalLayer.offset().top + this.$('.slidee').height() > _modalLayer.height()
    }
  },
  checkTableHeight: function () {
    if (this.$el.find('tbody tr').length < 9) {
      this.getParentView().$el.css('height', (this.$el.find('tbody tr').length * 29) + 27)
    } else {
      this.getParentView().$el.css('height', 288)
    }
  }
})

export default ChecklistModalTableGridScrollView
