import $ from 'jquery'
import FormView from '../Form'
import SendCommandModel from '../../model/editVehicle/SendCommandModel.js'
import DriverModel from "../../model/editVehicle/Driver.js";

const SendCommandFormView = FormView.extend({
    model: SendCommandModel,
    elSelector: '[data-form]',
    mergeEvents: true,

    events: {
        'click [data-button=cancel]': 'cancel',
        'click [data-button=send]': 'send'

    },
    constructor: function () {
        var view = this
        var parentView = view.getParentView()
        if (parentView) {
            view.$el = parentView.$(view.elSelector)
        } else {
            view.$el = $(view.elSelector)
        }
        view.el = view.$el[0]

        FormView.apply(this, arguments)
    },
    initialize: function () {
        var view = this
        view.model.on('invalid', this.error)
    },

    cancel: function () {
        this.getParentView().hide()
        this.$('.button.save').addClass('disabled')
        FormView.prototype.cancel.apply(this, arguments)
    },
    submitHandler: function (e) {
        if (this.$('.button.save').hasClass('disabled')) {
            return e && e.preventDefault()
        }
        FormView.prototype.submitHandler.apply(this, arguments)
    }
})

export default SendCommandFormView
