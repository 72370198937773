import $ from 'jquery'
import FormView from '../../Form'
import GeoFenceAlert from '../../../model/alert/geofence/GeoFenceAlert'

const GeoFenceAlertFormView = FormView.extend({
  model: GeoFenceAlert,
  mergeEvents: true,
  events: {
    'change input[name=sendToSelf]': 'sendToSelfChange'
  },
  constructor: function () {
    var geoFenceAlert = this.parentView
    this.el = geoFenceAlert.$el.find('form')
    FormView.apply(this, arguments)
  },
  initialize: function () {
    var view = this,
      notificationPopupView = view.findSubView('notificationPopup'),
      GeoFenceAlertView = this.parentView,
      GeoFenceAlertTabsetView,
      GeoFenceAlertListPopup,
      GeoFenceAlertTableGridView

    view.listenTo(view.model, 'change:sendToSelf', function (model, sendToSelf) {
      view.renderSendToSelf(sendToSelf)
    })
    GeoFenceAlertView.on('ready', function () {
      GeoFenceAlertTabsetView = GeoFenceAlertView.tabsetView
      GeoFenceAlertListPopup = GeoFenceAlertView.findSubView('alertListPopup')
      GeoFenceAlertTableGridView = GeoFenceAlertListPopup.findSubView('alertTableGrid')

      view.on('cancel', function () {
        // var selectProfile
        var selectRecipientTableGridView,
          selectPoiListTableGridView,
          selectAoiListTableGridView,
          selectVehicleListTableGridView

        if (view.getParentView().model.get('dataMode') !== 'edit') {
          if ('selectRecipient' in GeoFenceAlertTabsetView) {
            selectRecipientTableGridView = GeoFenceAlertTabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid')
            selectRecipientTableGridView.reset()
          }
          if ('SelectPoi' in GeoFenceAlertTabsetView) {
            selectPoiListTableGridView = GeoFenceAlertTabsetView.SelectPoi.innerView
            selectPoiListTableGridView.reloadContent()
          }
          if ('selectAoi' in GeoFenceAlertTabsetView) {
            selectAoiListTableGridView = GeoFenceAlertTabsetView.selectAoi.innerView
            selectAoiListTableGridView.reloadContent()
          }
          if ('selectVehicle' in GeoFenceAlertTabsetView) {
            selectVehicleListTableGridView = GeoFenceAlertTabsetView.selectVehicle.innerView
            selectVehicleListTableGridView.reloadContent()
          }
        }

        GeoFenceAlertTableGridView.resetSelectedItemAndRow()
        view.getParentView().switchTitle('create')

        if (GeoFenceAlertTabsetView.getActiveTabView().innerView.availableTableGridCollection) {
          GeoFenceAlertTabsetView.getActiveTabView().innerView.availableTableGridCollection.id = null
          GeoFenceAlertTabsetView.getActiveTabView().innerView.reloadContent()
        } else {
          GeoFenceAlertTabsetView.getActiveTabView().innerView.findSubView('selectRecipientTableGrid').renderSelectedList()
        }

        view.reset()
      })
      view.on('save', function () {
        GeoFenceAlertTableGridView.model.get('tableRow').reset()
        if (GeoFenceAlertListPopup.isVisible()) {
          GeoFenceAlertTableGridView.fetchAlertlist()
        }
        view.cancel()
        notificationPopupView.render({
          msgId: 'successfully_saved',
          title: '',
          message: 'Successfully saved.',
          button: ['Ok']
        })
      })
      view.model.on('invalid', function (model, error) {
        notificationPopupView.render(error)
      })
    })
  },
  sendToSelfChange: function (e) {
    var $checkbox = $(e.currentTarget)

    this.model.set('sendToSelf', $checkbox.prop('checked'), { silent: true })
  },
  renderSendToSelf: function () {
    this.$el.find('input[name=sendToSelf]').prop('checked', this.model.get('sendToSelf'))
  },
  cancel: function () {
    // console.log(this.getParentView().model.get('dataMode'))
    // if(this.getParentView().model.get('dataMode') === 'edit'){
    //     this.getParentView().model.set({dataMode: 'create'})
    // }
    // this.reset()
    this.trigger('cancel')
  },
  syncData: function () {
    var GeoFenceAlertView = this.getParentView()
    var GeoFenceAlertTabsetView = GeoFenceAlertView.tabsetView

    if ('selectRecipient' in GeoFenceAlertTabsetView) {
      this.model.set('selectedAlertRecipientList', GeoFenceAlertTabsetView.selectRecipient.innerView.findSubView('selectRecipientTableGrid').model.get('tableRow').toJSON())
    }
    if ('SelectPoi' in GeoFenceAlertTabsetView && GeoFenceAlertTabsetView.SelectPoi.innerView.selectedTableGridCollection.models.length > 0) {
      this.model.set('selectedPoiList', GeoFenceAlertTabsetView.SelectPoi.innerView.selectedTableGridCollection.models[0].collection.toJSON())
    }
    if ('selectAoi' in GeoFenceAlertTabsetView && GeoFenceAlertTabsetView.selectAoi.innerView.selectedTableGridCollection.models.length > 0) {
      this.model.set('selectedAoiList', GeoFenceAlertTabsetView.selectAoi.innerView.selectedTableGridCollection.models[0].collection.toJSON())
    }
    if ('selectVehicle' in GeoFenceAlertTabsetView && GeoFenceAlertTabsetView.selectVehicle.innerView.selectedTableGridCollection.models.length > 0) {
      this.model.set('selectedVehicleList', GeoFenceAlertTabsetView.selectVehicle.innerView.selectedTableGridCollection.models[0].collection.toJSON())
    }

    this.model.set(this.getFormData())

    if (GeoFenceAlertView.model.get('dataMode') === 'edit') {
      this.model.set('id', GeoFenceAlertView.model.get('alertId'))
      this.model.set('active', GeoFenceAlertView.alertListPopupView.findSubView('alertTableGrid').model.get('tableRow').get(this.model).get('active'))
    } else {
      GeoFenceAlertView.model.set('alertId', null)
      this.model.set('id', null)
    }
  }
})

export default GeoFenceAlertFormView
