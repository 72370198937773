/**
 ** Created by Paul Zelenov
 **/
import Collection from "../../Collection";
import UnpluggedDeviceAlertModel from "../../../model/alert/unpluggedDevice/UnpluggedDeviceAlert";

const UnpluggedDeviceAlert = Collection.extend({
    model: UnpluggedDeviceAlertModel,
    url: "/api/unplugged-device/alert"
});

export default UnpluggedDeviceAlert;
