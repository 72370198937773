import Validator from '../../../util/Validator'
import Model from '../../Model'

const TowAwayAlertModel = Model.extend({
  urlRoot: '/api/tow-away/alert',
  defaults: {
    id: null,
    name: '',
    selectedAlertVehicleList: [],
    selectedAlertRecipientList: [],
    sendToSelf: true
  },
  validate: function (attrs, options) {
    var message = {
      empty_alert_name: 'Please enter Alert name',
      wrong_alert_name: 'Please verify the Alert name format.',
      profile_not_selected: 'Please select profile.'
    }
    var json = {
      button: ['OK']
    }
    if (attrs.name.trim() === '') {
      json.msgId = 'empty_alert_name'
      json.title = 'Alert name verification'
      json.message = message[json.msgId]
      return json
    }
    if (!Validator.checkValidity('alertName', attrs.name)) {
      json.msgId = 'wrong_alert_name'
      json.title = 'Alert name verification'
      json.message = message[json.msgId]
      return json
    }
    // if(!attrs.selectedAlertVehicleList.length){
    //     json.msgId = "vehicle_not_selected";
    //     json.title = "Vehicle verification";
    //     json.message = message[json.msgId];
    //     return json;
    // }
  }
})

export default TowAwayAlertModel
