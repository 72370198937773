/**
 * Created by George on 16.07.2015.
 */
import AlertListPopupView from '../AlertListPopup';
import DriverBehaviorAlertTableGridView from './DriverBehaviorAlertTableGrid';
import DriverBehaviorAlertPopupModel from '../../../model/alert/driverBehavior/DriverBehaviorAlertPopup';

const DriverBehaviorAlertListPopupView = AlertListPopupView.extend({
    model: DriverBehaviorAlertPopupModel,
    AlertTableGridView: DriverBehaviorAlertTableGridView
});

export default DriverBehaviorAlertListPopupView;
