import $ from 'jquery'
import Model from '../Model'

const LoneWorkerActivityLogFormModel = Model.extend({
  defaults: {
    fromTime: null,
    toTime: null,
    date: null,
    userId: 0
  },
  url: 'api/lone-worker/activity-log',
  validate: function (attrs, options) {
    var messageAlertPopup = $('.message-alert-popup')

    if (window.systemAlert.isOpen()) {
      // _html.html()
      window.systemAlert.hideAlert()
    }
    if (!window.isValidDate(attrs.date)) {
      window.systemAlert.initError('Invalid date format.', function (html) {
        // _html = html
      })
    }
    if (!window.isValidTime(attrs.fromTime) || !window.isValidTime(attrs.toTime)) {
      window.systemAlert.initError('Invalid time format.', function (html) {
        // _html = html
      })
    }

    var message = {
      invalid_date_range: 'Please verify date range.',
      invalid_date_format: 'Please verify date format.'
    }
    var json = {
      button: ['OK']
    }
    if (!(/[0-9]{2}\.[0-9]{2}\.[0-9]{4}/).test(attrs.date)
      || !(/[0-9]{2}:[0-9]{2}/).test(attrs.fromTime)
      || !(/[0-9]{2}:[0-9]{2}/).test(attrs.toTime)) {
      json.msgId = 'invalid_date_format'
      json.title = 'Date format verification'
      json.message = message[json.msgId]
      return json
    }
  }
})

export default LoneWorkerActivityLogFormModel
