import $ from 'jquery'
import ReportFormView from '../ReportForm'
import ReportFormModel from '../../../model/report/ReportForm'
import SelectView from '../../Select'
import CounterView from '../../Counter'

const JourneyCostReportFormView = ReportFormView.extend({
  model: ReportFormModel,
  initialize: function (option) {
    var view = this

    // view.addSubView('select',SelectView,{el: view.$('[data-select=operation]')})
    // view.addSubView('speedCounter',CounterView,{el: view.$('[data-counter=speed]')})
    // view.addSubView('overspeedPercantageCounter',CounterView,{el: view.$('[data-counter=overspeedPercantage]')})
  },
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'Journey Cost Report',
      'report-type': 'JourneyCost',
      'report-format': model.get('repFormat'),
      alternate: false,
      remZeroCostJourneys: model.get('removeZeroCostJourneys'),
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/')
    }
    return $.param(data)
  },
  destroy: function () {
    var view = this

    // view.findSubView('select').destroy()
    // view.findSubView('speedCounter').destroy()
    // view.findSubView('overspeedPercantageCounter').destroy()
    ReportFormView.prototype.destroy.apply(this, arguments)
  }
})

export default JourneyCostReportFormView
