import Backbone from 'backbone'
import FormView from '../Form'
import UnitIdReportFormModel from '../../model/report/UnitIdReportForm'
import NotificationPopupView from '../NotificationPopup'

const UnitIdReportFormView = FormView.extend({
  model: UnitIdReportFormModel,
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form')
    view.el = view.$el[0]

    FormView.apply(this, arguments)
  },
  initialize: function () {
    var view = this

    view.model.on('invalid', function (model, error) {
      NotificationPopupView.render(error)
    })
    view.model.set('unitIds', Object.keys(window.wls.getIdList()))
    view.syncData()
    //            view.assignReportDataId()
    view.submit()
  },
  submit: function () { // override
    var view = this
    var tableGrid = view.getParentView().findSubView('compactReportTableGrid')

    Backbone.sync('read', view.model, {
      data: view.model.toJSON(),
      success: function (collection) {
        tableGrid.model.set('tableRow', collection)
        tableGrid.render()
        if (view.getParentView().$el.css('visibility') === 'hidden') {
          view.getParentView().show() // show report
        }
      }
    })
  },
  //        assignReportDataId: function(){
  //            throw new Error('assignReportDataId is not implemented')
  //        },
  generatePdfXlsReportURL: function () {
    throw new Error('generatePdfXlsReportURL not implemented')
  },
  destroy: function () {
    this.model.off('invalid')
    this.remove()
  }
})

export default UnitIdReportFormView
