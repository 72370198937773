import ReportPopupView from '../ReportPopup'
import IdlingReportFormView from './IdlingReportForm'

const IdlingReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/Idling.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', IdlingReportFormView)
  }
})

export default IdlingReportPopupView
