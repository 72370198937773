import Backbone from 'backbone'
import extendProperty from '../util/extendProperty'

const Model = Backbone.Model.extend({ // override default Model to prevent incorrect data attachment
  constructor: function () {
    if (this.defaults) {
      extendProperty(this, "defaults")
    }
    Backbone.Model.apply(this, arguments)
  },
  isClientSide: function () {
    return !this.urlRoot && !this.url
  }
})

export default Model
