import View from '../../View'
import ReportDriverList from '../../../collection/report/journeyReport/ReportDriverList'
import $ from 'jquery'

const ReportDriverListView = View.extend({
  templateUrl: 'template/editVehicle/driverList.html',
  collection: ReportDriverList,
  constructor: function (option) {
    var view = this
    var args = arguments
    this.el = option.el
    this.$el = $(this.el)
    this.collection = new this.collection()

    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, args)
    })
  },
  initialize: function () {
    var view = this
  },
  fetchList: function (options) {
    this.collection.fetch(options)
  },
  fetchListSuccess: function (defaultOption) {
    this.render(defaultOption)
  },
  render: function (defaultOption) {
    var html = $(this.dotTemplateToHtml(this.collection.toJSON()))
    var $select = this.getParentView().$el
    var defaultOptionValue = null
    this.$el.html(html)

    defaultOptionValue = $select.selectmenu().find('option:contains(' + defaultOption + ')').attr('value')
    $select.selectmenu().val(defaultOptionValue)
    this.getParentView().reset()
  }
})

export default ReportDriverListView
