import './App.css'
import $ from 'jquery'
import 'jquery.maskedinput'
import initCookiePlugin from './common/cookie'
import initNaturalSort from './common/backbone.naturalsort'
import initWheelColorPickerPlugin from './common/jquery.wheelcolorpicker'
import initSlyPlugin from './common/sly'
import initTristatePlugin from './common/jquery.tristate'
import initFlexDataListPlugin from './common/jquery.flexdatalist'
import initPlaceholderPlugin from './common/placeholders.jquery'
import initCroppiePlugin from './common/croppie.min'
import initTimePickerPlugin from './common/jquery-ui-timepicker-addon'
import initJqueryBackboneApp from './common/jquery_test.main'
import initJqueryUiPlugins from './common/jquery-ui'

initJqueryUiPlugins()
initSlyPlugin()
initCookiePlugin()
initNaturalSort()
initWheelColorPickerPlugin()
initTristatePlugin()
initFlexDataListPlugin()
initTimePickerPlugin()
initPlaceholderPlugin()
initCroppiePlugin()

window.initMap = function () {
  $(document).ready(() => {
    initJqueryBackboneApp()
  })
}

function App () {
  return null
}

export default App
