import $ from 'jquery'
import ModalView from '../../../Modal'
import VehicleDefectCheckListsModalTableGridView from './CheckListsModalTableGrid'

const CheckListsModalView = ModalView.extend({
  url: 'js/html/app/src/template/report/vehicleDefectReport/checklist/vehicleDefectCheckListsModal.html',
  mergeEvents: true,
  events: {
    'click [data-button=edit]': 'editChecklist',
    'click [data-button=duplicate]': 'duplicateChecklist',
    'click [data-button=clearFilters]': 'clearFiltersButtonHandler',
  },
  initialize: function () {
    this.editebleChecklist = false
    this.duplChecklist = false
    this.addSubView('compactReportTableGrid', VehicleDefectCheckListsModalTableGridView, this.$('[data-table-grid=checklists]'))
  },
  getList: function () {
    var view = this
    var tableGridView = view.findSubView('compactReportTableGrid')
    var tableDropView = tableGridView.findSubView('tableDrop')

    tableGridView.model.set('tableRow', [])

    $.get('/api/report/vehicleDefectReport/checklist/?account_id=' + $.cookie('lastViewedAccount')).done(function (json) {
      var list = view.modifyLists(json)

      tableGridView.model.set('tableRow', list)
      tableDropView.clearAllFilterData()
      tableGridView.hideAllFilterIcons()
      tableGridView.render()
    })
  },
  modifyLists: function (list) {
    var data = list

    for (var i = 0; i < data.length; i++) {
      var currData = data[i]

      currData.creatorFullName = currData.creator.fullName
      currData.modifierFullName = currData.modifier.fullName
    }

    return data
  },
  editChecklist: function (e) {
    var view = this
    var _id = $(e.currentTarget).closest('tr').attr('data-id')
    var model = view.findSubView('compactReportTableGrid').model
    var item = model.get('tableRow').get(_id)
    var checklistModal = view.getParentView().findSubView('checklistModal')

    view.editebleChecklist = true
    view.hide()
    checklistModal.editChecks(item)
    checklistModal.findSubView('checklistForm').findSubView('compactReportTableGrid').findSubView('scroll').render()
  },
  duplicateChecklist: function (e) {
    var view = this
    var _id = $(e.currentTarget).closest('tr').attr('data-id')
    var model = view.findSubView('compactReportTableGrid').model
    var item = model.get('tableRow').get(_id)
    var checklistModal = view.getParentView().findSubView('checklistModal')

    view.duplChecklist = true
    view.hide()
    checklistModal.editChecks(item, true)
    checklistModal.findSubView('checklistForm').findSubView('compactReportTableGrid').findSubView('scroll').render()
  },
  clearFiltersButtonHandler: function () {
    var view = this
    var tableGridView = view.findSubView('compactReportTableGrid')
    var tableDropView = tableGridView.findSubView('tableDrop')

    tableDropView.clearAllFilterData()
    tableGridView.hideAllFilterIcons()
    tableGridView.render()
  },
  generatePdfXlsReportURL: function (reportFormat) {

    console.log('generated')

    var data = {
      'report-name': 'VehicleDefectChecklist',
      'report-type': 'VehicleDefectChecklist',
      'report-format': reportFormat,
      accountId: $.cookie('lastViewedAccount')
    }

    return $.param(data)
  },
  closeBtnHandler: function () {
    var view = this
    var vehicleListModal = view.getParentView().findSubView('vehicleListModal')

    view.hide()

    if (vehicleListModal.vehicleListActive) {
      vehicleListModal.getList()
      vehicleListModal.show()
    }
  },
  destroy: function () {
    var view = this

    view.remove()
  }
})

export default CheckListsModalView
