import $ from 'jquery'
import View from '../../View'
import GeoMonitorView from './GeoMonitor'

const GeoMonitorAlertView = View.extend({
  el: $('[data-tab=geoMonitor]'),
  initialize: function () {
    var view = this

    view.addSubView('geoMonitor', GeoMonitorView)
    view.render()
  },
  destroy: function () {
    var view = this

    view.findSubView('geoMonitor').destroy()
  }
})

export default GeoMonitorAlertView
