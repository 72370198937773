import Model from '../../Model'

const NotificationsFormModel = Model.extend({
  idAttribute: 'status',
  defaults: {
    status: null
  },
  urlRoot: 'api/report/notificationStatus'
})

export default NotificationsFormModel
