import TableGridView from '../TableGrid@'
import SelectRecipientTableGridModel from '../../model/alert/SelectRecipientTableGrid'
import FormView from '../Form'
import SelectRecipientTableDropView from './SelectRecipientTableDrop'
import ScrollView from '../Scroll'
import $ from 'jquery'

const SelectRecipientTableGridView = TableGridView.extend({
  model: SelectRecipientTableGridModel,
  elSelector: '[data-table-grid=selectRecipient]',
  templateUrl: 'template/alert/recipientTableGrid.html',
  mergeEvents: true,
  events: {
    'click [data-button=delete]': 'deleteRowHandler',
    'click [data-button=edit]': 'editRowHandler',
    'change input:checkbox': 'setSendByEmail'
  },
  constructor: function () {
    this.el = this.parentView.$el.find('.info-holder')

    this.setSubView({
      tableDrop: SelectRecipientTableDropView,
      scroll: ScrollView
    })

    TableGridView.apply(this, arguments)
  },
  initialize: function () {
    this.templateOptions.activeRowId = null
    this.model.set('tableRow', [])
    this.render()
  },
  deleteRowHandler: function (e) {
    var $tr = $(e.currentTarget).closest('tr')
    var recipientModel = this.getModelById($tr)

    this.deleteEntry(recipientModel)
  },
  editRowHandler: function (e) {
    var $tr = $(e.currentTarget).closest('tr')
    var recipientModel = this.getModelById($tr).toJSON()
    var addRecipientPopupView = this.parentView.findSubView('addRecipientPopup')
    var addRecipientFormView = addRecipientPopupView.findSubView('addRecipientForm')
    var recipientPopupState
    var id = null

    if ($tr.hasClass('active')) {
      recipientPopupState = {
        dataMode: 'create',
        visible: false
      }
      recipientModel = {}
    } else {
      id = $tr.attr('data-id') || $tr.attr('data-cid')
      recipientPopupState = {
        dataMode: 'edit',
        visible: true
      }
    }
    this.templateOptions.activeRowId = id
    addRecipientPopupView.model.set(recipientPopupState)
    addRecipientFormView.model.set(recipientModel)
    addRecipientFormView.render()
    addRecipientPopupView.alignBy($tr, 'topRight')
  },
  deleteEntry: function (recipientModel) {
    var view = this
    var notificationPopup = FormView.prototype.findSubView('notificationPopup')
    notificationPopup.render({
      title: 'Confirm Action',
      message: 'Are you sure you want to Delete this Recipient?',
      button: ['Cancel', 'Confirm']
    })
    notificationPopup.once('answer', function (actionName) {
      if (actionName === 'ok') {
        view.model.get('tableRow').remove(recipientModel)
        view.render()
      }
    })
  },
  editEntry: function (recipientModel) {
    var id = recipientModel.get('id') || recipientModel.get('cid')
    if (!this.entryExists(recipientModel.toJSON())) {
      this.model.get('tableRow').get(id).set(recipientModel.toJSON())
      this.render()
    }
  },
  createEntry: function (recipientModel) {
    var entry = {
      firstName: recipientModel.firstName,
      lastName: recipientModel.lastName,
      email: recipientModel.email
    }
    if (!this.entryExists(entry)) {
      recipientModel.set('cid', recipientModel.cid)
      this.model.get('tableRow').add(recipientModel)
      this.render()
    }
  },
  entryExists: function (entry) {
    this.model.get('tableRow').findWhere(entry)
  },
  setSendByEmail: function (e) {
    var $tr = $(e.currentTarget).closest('tr')
    var $checkbox = $tr.find('input:checkbox')

    this.getModelById($tr).set('sendByEmail', $checkbox.prop('checked'))
  },
  getModelById: function ($tr) {
    var id = $tr.attr('data-cid') || $tr.attr('data-id')
    var recipientModel = this.model.get('tableRow').get(id)

    return recipientModel
  },
  renderSelectedList: function (selectedList) {
    var collection = this.model.get('tableRow')

    collection.reset()
    collection.set(selectedList)
    this.render()
  },
  resetSelectedItemAndRow: function () {
    this.templateOptions.activeRowId = null
    TableGridView.prototype.resetSelectedItemAndRow.apply(this, arguments)
  },
  reset: function () {
    this.model.get('tableRow').reset()
    this.render()
  }
})

export default SelectRecipientTableGridView
