import Model from '../../Model'

const FuelUsageModel = Model.extend({
  defaults: {
    vehReg: '',
    group: '',
    subGroup: '',
    startOdometer: null,
    endOdometer: null,
    distance: null,
    litres: null,
    mpg: null
  }
})

export default FuelUsageModel
