import AlertPanelView from "../AlertPanel";
import TabsetView from "../../Tabset";
import UnpluggedDeviceAlertListPopupView from "./UnpluggedDeviceAlertListPopup";
import UnpluggedDeviceAlertFormView from "./UnpluggedDeviceAlertForm";

const UnpluggedDeviceAlert = AlertPanelView.extend({
    constructor: function(option) {

        this.setSubView({});
        this.AlertListPopupView = UnpluggedDeviceAlertListPopupView;
        this.tabsetName = "UnpluggedDeviceAlert";
        if (option.el) {
            this.$el = option.el;
            this.el = option.el[0];
        } else {
            this.$el = $("[data-tab=unpluggedDevice]");
            this.el = this.$el[0];
        }

        this.addSubView("alertForm", UnpluggedDeviceAlertFormView);

        AlertPanelView.apply(this, arguments);
    },


    initialize: function(){ // should remove this

        var view = this;

        view.alertListPopupView.on("ready",function(){
            this.alertListTableGridView.on("itemSelect",function($tr,id){
                var activeTabName = view.tabsetView.model.get("activeTab");

                if(view.model.get("dataMode") == "edit"){
                    if(activeTabName == "selectRecipient"){
                        if(view.tabsetView.selectTrackingDevice) {
                            view.tabsetView.selectTrackingDevice.innerView.availableTableGridCollection.set(this.selectedItem[id].get("selectedAlertTrackingDeviceList"));
                        }
                    }else{
                        if(view.tabsetView.selectRecipient){
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").model.get('tableRow').set(this.selectedItem[id].get("selectedAlertRecipientList"));
                        }
                    }
                }else{
                    if(activeTabName == "selectRecipient"){
                        if(view.tabsetView.selectTrackingDevice) {
                            view.tabsetView.selectTrackingDevice.innerView.availableTableGridCollection.set([]);
                        }
                    }else{
                        if(view.tabsetView.selectRecipient) {
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").model.get('tableRow').set([]);
                        }
                    }
                }
            });
        });
    },
    changeActiveTabHandler: function(activeTabView, activeTabName) {
        var view = this
        var alertListTableGridView = view.alertListPopupView.alertListTableGridView;
        var id = view.model.get("alertId");
        var isSelectRecipient = !activeTabView.innerView.availableTableGridView;
        var selectedList, recipientTableGridView;

        if (view.model.get("dataMode") === "edit") {
            id = alertListTableGridView.getActiveRowId();

            if (!isSelectRecipient) {
                selectedList = alertListTableGridView.model.get('tableRow').get(id).get(activeTabView.get$ActiveTab().data("selected-list"));
                activeTabView.innerView.availableTableGridCollection.id = id;
            } else {
                selectedList = alertListTableGridView.model.get('tableRow').get(id).get("selectedAlertRecipientList");
            }
        } else {
            if (!isSelectRecipient) {
                activeTabView.innerView.availableTableGridCollection.id = null;
            } else {
                selectedList = [];
            }
        }

        this.tabData[activeTabName] = {
            alertId: this.model.get("alertId"),
            dataMode: this.model.get("dataMode")
        };

        if (!isSelectRecipient) {
            activeTabView.innerView.reloadContent(selectedList, true);
        } else {
            recipientTableGridView = activeTabView.innerView.findSubView("selectRecipientTableGrid");
            if (!recipientTableGridView.isReady) {
                recipientTableGridView.on("ready", () => {
                    recipientTableGridView.renderSelectedList(selectedList);
                });
            } else {
                recipientTableGridView.renderSelectedList(selectedList);
            }
        }
    },
    bindAlertListTableGridItemSelect: function() {
        var view = this
        view.alertListPopupView.on("ready",function () {
            this.alertListTableGridView.on("itemSelect", function($tr, id) {
                const activeTabView = view.tabsetView.getActiveTabView();
                let selectedTrackingDeviceList, selectedRecipientList;

                view.model.set("alertId", id);

                selectedTrackingDeviceList = this.model.get('tableRow').get(id).get("selectedTrackingDeviceList");
                selectedRecipientList = this.model.get('tableRow').get(id).get("selectedAlertRecipientList");

                if ($tr.is(".active")) {
                    view.model.set("dataMode", "edit");
                    view.$el.find("input[name=name]").val(this.model.get('tableRow').get(id).get("name"));

                    if (activeTabView.innerView.availableTableGridCollection) {
                        activeTabView.innerView.availableTableGridCollection.id = this.model.get("alertId");
                    }

                    view.tabsetView.selectTrackingDevice.innerView.reloadContent(selectedTrackingDeviceList, true);

                    if (!view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").isReady) {
                        view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").on("ready", () => {
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").renderSelectedList(selectedRecipientList);
                        });
                    } else {
                        view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").renderSelectedList(selectedRecipientList);
                    }

                } else {
                    view.model.set("dataMode", "create");
                    view.$el.find("input[name=name]").val("");
                    if (activeTabView.innerView.availableTableGridCollection) {
                        activeTabView.innerView.availableTableGridCollection.id = null;
                        activeTabView.innerView.reloadContent();
                    } else {
                        activeTabView.innerView.findSubView("selectRecipientTableGrid").renderSelectedList();
                    }
                }
            });
        });
        return this;
    },
});

export default UnpluggedDeviceAlert;
