/**
 * Created by George on 26.03.2015.
 */
import Collection from '../../Collection';
import OverspeedAlert from '../../../model/alert/overspeed/OverspeedAlert';

const OverspeedAlertList = Collection.extend({
    model: OverspeedAlert,
    url: "/services/overspeed/alert"
});

export default OverspeedAlertList;
