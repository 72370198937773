import DetailedReportPopupView from './DetailedReportPopup'
import DetailedReportSelectionMenuView from './DetailedReportSelectionMenu'
import ReportPageView from '../ReportPage'
import DetailedReportView from './DetailedReport'

const DetailedReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="journey"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', DetailedReportPopupView)
    view.addSubView('selectionMenu', DetailedReportSelectionMenuView)
    view.addSubView('report', DetailedReportView)
    view.render()
  }
})

export default DetailedReportPageView
