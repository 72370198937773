import $ from 'jquery'
import Backbone from 'backbone'
import FormView from '../../Form'
import LocationReportFormModel from '../../../model/report/location/LocationReportForm'
import SafeInputView from '../../SafeInput'
import SelectView from '../../Select'
import CounterView from '../../Counter'
import DatepickerRangeView from '../../../view/DatepickerRange'
import PoiDropdownView from '../../PoiDropdown'
import LocationSearchView from '../../LocationSearch'
import DateIntervalDropdownView from './DateIntervalDropdown'
import DateRangeDropdownView from './DateRangeDropdown'

const LocationReportFormView = FormView.extend({
  // events: {
  //     'itemChanged .proximity [data-select-poi]' : 'onPoiChanged',
  //     'submit': 'submit'
  // },
  model: LocationReportFormModel,
  // pin: null,
  mergeEvents: true,
  constructor: function () {
    var view = this
    var parentView = view.getParentView()

    view.$el = parentView.$el.find('form')
    view.el = view.$el[0]
    view._html = null

    view.addSubView('aoiPoiDropDown', PoiDropdownView, { el: view.$('.select-holder.aoi-poi .select') })
    view.addSubView('locationSearch', LocationSearchView, { el: view.$('.search.location-search') })
    view.addSubView('dateInterval', DateIntervalDropdownView, { el: view.$('[data-select=dateInterval]') })
    view.addSubView('dateRange', DateRangeDropdownView, { el: view.$('[data-select=dateRange]') })
    view.addSubView('radiusCounter', CounterView, { el: view.$('[data-counter=radius]') })

    // view.addSubView('selectInterval',SelectView,{el: view.$('[data-select=interval]')})
    view.addSubView('datepickerRange', DatepickerRangeView, { el: view.$('[data-calendar=dateRange]') })
    view.addSubView('safeInputLat', SafeInputView, { el: view.$('[data-input=lat]') }).model.set('allowedChars', ['digits', 'colon', 'edit', 'navigation'])
    view.addSubView('safeInputLon', SafeInputView, { el: view.$('[data-input=lon]') }).model.set('allowedChars', ['digits', 'colon', 'edit', 'navigation'])

    FormView.apply(this, arguments)
  },
  initialize: function (option) {
    var view = this

    var mapCenter = window.mapCanvas.getCenter()
    if (!mapCenter.latitude) {
      view.findSubView('safeInputLat').$el.val(Math.round(mapCenter.lat() * 1000000) / 1000000)
      view.findSubView('safeInputLon').$el.val(Math.round(mapCenter.lng() * 1000000) / 1000000)
    } else {
      view.findSubView('safeInputLat').$el.val(Math.round(mapCenter.latitude * 1000000) / 1000000)
      view.findSubView('safeInputLon').$el.val(Math.round(mapCenter.longitude * 1000000) / 1000000)
    }

    view.findSubView('aoiPoiDropDown').on('select', function (e) {
      console.log('e: ' + e)
    })

    view.findSubView('datepickerRange').today()
    // view.getParentView().findSubView('locationReport').findSubView('compactReportTableGrid').hide()

    if (window.settings.data.mapSource && window.settings.data.mapSource.startsWith('MSVE')) {
      window.Microsoft.Maps.Events.addHandler(window.mapCanvas.map, 'click', function (e) {
        if (e.targetType === 'pushpin') {
          return
        }
        if (LocationReportFormView.prototype.pin) {
          view.resetPin()
        }
        var point = new window.Microsoft.Maps.Point(e.getX(), e.getY())
        var loc = window.mapCanvas.map.tryPixelToLocation(point)
        var location = new window.Microsoft.Maps.Location(loc.latitude, loc.longitude)
        var lat = Math.round(location.latitude * 1000000) / 1000000
        var lon = Math.round(location.longitude * 1000000) / 1000000

        view.findSubView('safeInputLat').$el.val(lat)
        view.findSubView('safeInputLon').$el.val(lon)
      })
    } else if (window.settings.data.mapSource && window.settings.data.mapSource === 'GOOGLEMAPS') {
      window.google.maps.event.addListener(window.mapCanvas.map, 'click', function (event) {
        console.log(event)

        const location = event.latLng

        view.findSubView('safeInputLat').$el.val(location.lat())
        view.findSubView('safeInputLon').$el.val(location.lng())
      })
    }

    // view.addSubView('select',SelectView,{el: view.$('[data-select=operation]')})
    // view.addSubView('speedCounter',CounterView,{el: view.$('[data-counter=speed]')})
    // view.addSubView('overspeedPercantageCounter',CounterView,{el: view.$('[data-counter=overspeedPercantage]')})
  },
  destroy: function () {
    var view = this

    // view.findSubView('select').destroy()
    // view.findSubView('speedCounter').destroy()
    // view.findSubView('overspeedPercantageCounter').destroy()
    FormView.prototype.destroy.apply(this, arguments)
  },
  submit: function () { // override
    var view = this

    var tableGrid = view.getParentView().findSubView('locationReport').findSubView('compactReportTableGrid')
    var locationReport = view.getParentView().findSubView('locationReport')
    locationReport.$el.show()
    var model = view.model
    view.getParentView().getParentView().$('.location-table-spinner').addClass('active')

    tableGrid.model.set('tableRow', [])
    tableGrid.initialize()

    var range = parseInt(model.get('dateRange'), 10)
    var interval = parseInt(model.get('dateInterval'), 10)

    var dateParams = model.get('from').split(/[\.]/)

    var userEnteredDateISO = dateParams[2] + '-' + dateParams[1] + '-' + dateParams[0]
    var dateFrom = new Date(userEnteredDateISO + 'T00:00:00Z')
    var dateTo = new Date(userEnteredDateISO + 'T23:59:59Z')
    var dd_from = dateFrom.getDate()
    var dd_to = dateTo.getDate()

    switch (interval) {
      case 1:
        dd_to += range
        dateTo.setDate(dd_to)
        break
      case 2:
        dd_from -= range
        dateFrom.setDate(dd_from)
        break
      case 3:
        dd_from -= range
        dd_to += range
        dateFrom.setDate(dd_from)
        dateTo.setDate(dd_to)
        break
      default:
        console.log('Choose Date Interval')
        return
    }

    var from = dateFrom.getTime()
    var to = dateTo.getTime()

    model.set('fromDate', from)
    model.set('toDate', to)
    model.set('unitViewId', $.cookie('lastViewedGroup'))

    Backbone.sync('read', view.model, {
      data: $.param(model.toJSON()),
      success: function (collection) {
        view.getParentView().getParentView().$('.location-table-spinner').removeClass('active')

        tableGrid.model.set('tableRow', collection.locationRecordList)
        locationReport.render(true)
        window.toPoiCollection = collection.locationRecordList
        tableGrid.render()
        locationReport.$el.css('height', 'auto')
        tableGrid.findSubView('scroll').render()

        if (!$('.top-bar').is(':hidden')) {
          $('.top-bar').animate({
            'margin-left': 0
          }, 500, null)
        }
      },
      error: function () {
        view.getParentView().getParentView().$('.location-table-spinner').removeClass('active')
        if (!window.isValidDate(dateParams[0], dateParams[1], dateParams[2])) {
          window.systemAlert.initError('Invalid date format.', function (html) {
            view._html = html
            setTimeout(function () {
              window.systemAlert.hideAlert()
            }, 3000)
          })
        }
      }
    })
  },

  isModelEmpty: function (model) {
    if (model.data === undefined || model.data == null) {
      return true
    } else {
      return model.data && !model.data.length
    }
  },
  handlePostcodeSearch: function (e) {
    var view = this

    // $('.left-panel.proximity').show()
    //            var selectionMenu = view.findSubView('selectionMenu')
    //             var leftPanel = $('.tabset-report-panel.proximity .left-panel.proximity')
    // var form = leftPanel.find('.proximity-search').closest('.search')
    var redMarker = new Image()
    redMarker.src = 'img/markers/marker_red.png'
    var markerData = e.data.markerData
    view.findSubView('safeInputLat').$el.val(markerData.lat)
    view.findSubView('safeInputLon').$el.val(markerData.lon)
    var pin = LocationReportFormView.prototype.pin
    if (pin) {
      view.resetPin()
    }
    window.mapCanvas.addTextMarkerSimple(redMarker, 'Chosen Location', markerData.lon, markerData.lat, true,
      function (result) {
        pin = LocationReportFormView.prototype.pin = result
        pin.remove = function () {
          window.mapCanvas.removeMarker(this)
        }
        pin.lon = markerData.lon
        pin.lat = markerData.lat
        setTimeout(window.rezoomMapFlx, 1500)
      })
  },
  resetPin: function () {
    //            var leftPanel = $('.tabset-report-panel.proximity .left-panel')
    var pin = LocationReportFormView.prototype.pin

    if (pin) {
      pin.remove()
      pin.ignore = false
      if ('HTML' in pin) {
        pin.HTML.show()
      }
      pin = null
      //              leftPanel.find('.proximity-search').val('')
    }
    if (window.wls.hasShapes()) {
      window.wls.removeCars()
      window.mapCanvas.cleanUp()
    }
  }
})

export default LocationReportFormView
