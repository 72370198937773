import CompactReportPageView from '../CompactReportPage'
import LeagueTableReportView from './LeagueTableReport'

const LeagueTableReportPageView = CompactReportPageView.extend({
  el: '<div class="report-page" data-report-page="leagueTable"></div>',
  initialize: function () {
    var view = this

    view.addSubView('compactReport', LeagueTableReportView)
    view.render()
  }
})

export default LeagueTableReportPageView
