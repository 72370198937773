import PopupView from '../Popup'
import RefreshSettingsView from './RefreshSettings'

const RefreshSettingsPopupView = PopupView.extend({
  url: 'js/html/app/src/template/modalPanel/refreshSettingsPopup.html',
  mergeEvents: true,
  hideActivePopup: function () {
    var view = this

    if (view.isVisible()) {
      view.hide()
    }
  },
  initialize: function () {
    var view = this
    view.addSubView('refreshSettings', RefreshSettingsView)
  },
  closeBtnHandler: function () { // override
    this.findSubView('refreshSettings').cancel()
  },

  destroy: function () {
    this.findSubView('refreshSettings').destroy()
    this.remove()
  }
  // submit: function() {
  //     this.findSubView('refreshSettings').submit()
  // }

})

export default RefreshSettingsPopupView
