
import FormView from "../../Form";
import UnpluggedDeviceAlertRecipient from "../../../model/alert/unpluggedDevice/UnpluggedDeviceAlertRecipient";

const UnpluggedDeviceAlertAddRecipientFormView = FormView.extend({
    model: UnpluggedDeviceAlertRecipient,
    constructor: function() {
        this.$el = this.parentView.$el.find("form");
        this.el = this.$el[0];
        FormView.apply(this, arguments);
    },
    initialize: function() {
        const view = this;
        const addRecipientPopupView = this.parentView;
        const notificationPopupView = view.findSubView("notificationPopup");

        view.model.on("invalid", function(model, error) {
            notificationPopupView.render(error);
        });

        notificationPopupView.on("ok", function(message) {
            if (message.msgId === "user_email_already_exists") {
                view.submit();
            }
        });

        view.on("save", function(recipientModel) {
            const selectRecipientView = addRecipientPopupView.parentView;
            const selectRecipientTableGridView = selectRecipientView.findSubView("selectRecipientTableGrid");
            const dataMode = addRecipientPopupView.model.get("dataMode");
            const recipientModelClone = recipientModel.clone();

            if (dataMode === "create") {
                selectRecipientTableGridView.createEntry(recipientModelClone);
            } else {
                selectRecipientTableGridView.editEntry(recipientModelClone);
            }

            addRecipientPopupView.model.set("visible", false);
            view.cancel();
        });

        view.on("cancel", function() {
            addRecipientPopupView.closeBtnHandler();
            addRecipientPopupView.getParentView().findSubView("selectRecipientTableGrid").resetSelectedItemAndRow();
        });
    }
});

export default UnpluggedDeviceAlertAddRecipientFormView;
