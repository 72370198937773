import $ from 'jquery'
import ScrollView from '../../view/Scroll'

const GroupTabsetScrollView = ScrollView.extend({
  elSelector: '[data-scroll=groupTabset]',
  initialize: function () {
    var view = this
    var timeout = null

    $(window).on('resize.groupTabsetScroll', function () {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(function () {
        view.render()
      }, 1)
    })
  },
  checkContentSize: function () {
    var groupTabsetView = this.getParentView()

    return groupTabsetView.$el.find('ul').height() > $(window).height()
  },
  calculateScrollContentSize: function () {
    var groupTabsetView = this.getParentView()

    this.$frame.height($(window).height() - this.$frame.offset().top)
  },
  destroy: function () {
    $(window).off('resize.groupTabsetScroll')
    ScrollView.prototype.destroy.apply(this, arguments)
  }
})

export default GroupTabsetScrollView
