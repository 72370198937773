import PopupView from '../Popup'
import VideoPlayerView from '../VideoPlayer'

const VideoPlayerPopupView = PopupView.extend({
  url: 'js/html/app/src/template/videoPlayerPopup.html',
  activityLogId: null,
  mergeEvents: true,
  events: {
    'click [data-button=getMore]': 'showGetMorePopup'
  },
  initialize: function (option) {
    var view = this
    view.addSubView('videoPlayer', VideoPlayerView, { el: this.$el.find('.vp-container')[0] })
  },
  closeBtnHandler: function () {
    var view = this

    view.hide()
  },
  getActivityLogTableGrid: function () {
    return this.parentView
  },
  getConfirmVideoRequestPopup: function () {
    return this.getActivityLogTableGrid().findSubView('confirmVideoRequestPopup')
  },
  setVideoProps: function (props) {
    var $title = this.$el.find('.header-panel .title')
    $title.find('span:first').text(props.vehicleId)
    $title.find('span:last').text(new Date(props.recDate).format('dd.MM.yy HH:mm:ss'))
    this.findSubView('videoPlayer').setProps(props)
  },
  setActivityLogId: function (activityLogId) {
    this.activityLogId = activityLogId
  },
  showGetMorePopup: function () {
    var activityTableGridView = this.getActivityLogTableGrid()
    var confirmVideoRequestPopupView = this.getConfirmVideoRequestPopup()
    var activityLog = activityTableGridView.getActivityLog(this.activityLogId)

    activityTableGridView.showPopup(confirmVideoRequestPopupView)
    confirmVideoRequestPopupView.renderGetMoreForm(activityLog)
  }
})

export default VideoPlayerPopupView
