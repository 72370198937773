import $ from 'jquery'
import _ from 'underscore'
import PopupView from '../Popup'
import DriverWorkingHoursCalendarView from './DriverWorkingHoursCalendar'
import DriverWorkingHoursCalendarPopupModel from '../../model/driverWorkingHours/DriverWorkingHoursCalendarPopup'
import DriverSelectView from './DriverSelect'
import DriverWorkingHoursTotalView from './DriverWorkingHoursTotal'

const DriverWorkingHoursCalendarPopupView = PopupView.extend({
  url: 'js/html/app/src/template/driverWorkingHours/driverWorkingHoursCalendarPopup.html',
  model: DriverWorkingHoursCalendarPopupModel,
  initialize: function () {
    var view = this

    view.addSubView('driverWorkingHoursTotal', DriverWorkingHoursTotalView)
    view.addSubView('driverWorkingHoursCalendar', DriverWorkingHoursCalendarView)
  },
  fetchCalendarPopupData: function () {
    var view = this
    var vehicleId = $('[data-button=tachograph].active').closest('.row').find('input:hidden').val()
    var vehicleData = window.wls.getIdList()[vehicleId].data
    var param = {
      driverId: vehicleData.currentDriverId,
      startDate: view.getLastWeekFirstDay(),
      endDate: view.getThisWeekLastDay()
    }

    return view.model.fetch({ data: $.param(param) })
  },

  render: function () { // override
    var view = this

    if (view.isElementInDom()) {
      view.renderDriverSelect()
      view.renderFortnightTotal()
      view.renderCalendar()
    } else {
      $('#content').append(this.el)
    }

    return this
  },
  renderCalendar: function () {
    var view = this
    var driverWorkingHoursCalendarView = view.findSubView('driverWorkingHoursCalendar')

    driverWorkingHoursCalendarView.model.set('currentDate', Date.now())
    driverWorkingHoursCalendarView.model.get('driverWorkingHoursList').reset(view.model.get('calendarData'))
    driverWorkingHoursCalendarView.render()
  },
  renderFortnightTotal: function () {
    var view = this
    var calendarData = view.model.get('calendarData')
    var json = {
      workingTime: 0,
      drivingTime: 0,
      restTime: 0,
      availabilityTime: 0
    }

    _.each(calendarData, function (item) {
      json.workingTime += item['workingTime']
      json.drivingTime += item['drivingTime']
      json.restTime += item['restTime']
      json.availabilityTime += item['availabilityTime']
    })

    view.findSubView('driverWorkingHoursTotal').render(json)
  },
  closeBtnHandler: function () { // override
    $('[data-button=tachograph]').removeClass('active')
    this.hide()
  },
  renderDriverSelect: function () {
    var view = this
    var vehicleId
    var vehicleData
    var currentDriverId
    var $driverSelect
    var json = {
      driverList: view.model.get('driverList')
    }
    var html = view.dotTemplateToHtml(json)

    view.$el.find('.select-holder').html($(html).find('.select-holder').html())
    $driverSelect = view.$('[data-select=driver]')
    vehicleId = $('[data-button=tachograph].active').closest('.row').find('input:hidden').val()
    vehicleData = window.wls.getIdList()[vehicleId].data
    currentDriverId = vehicleData.currentDriverId
    view.addSubView('select', DriverSelectView, { el: $driverSelect })
    if (currentDriverId && $driverSelect.has('option[value=' + currentDriverId + ']').size()) {
      $driverSelect.val(currentDriverId).selectmenu('refresh')
    }
  },
  getThisWeekLastDay: function () {
    var view = this
    var date = new Date()

    date.setDate(date.getDate() - ((date.getDay() || 7) - 1) + 6)

    return date.setHours(23, 59, 59, 999)
  },
  getLastWeekFirstDay: function () {
    var view = this
    var date = new Date()

    date.setDate(date.getDate() - 7)
    date.setDate(date.getDate() - ((date.getDay() || 7) - 1))

    return date.setHours(0, 0, 0, 0)
  },
  loadHTML: function (url, callback) { // override
    var view = this
    $.post(url, function (msg) {
      view.dotTemplate = msg
      view.compileTemplate()
      view.$el = $(msg)
      view.el = view.$el[0]
      view.render()
      view.model.set('htmlReady', true)
      if (callback) callback()
    })
    return this
  }
})

export default DriverWorkingHoursCalendarPopupView
