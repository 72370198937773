import Model from '../../Model'
import Validator from '../../../util/Validator'

const ProfileBuilderFormModel = Model.extend({
    defaults:{
        description: ""
    },
    checkProfileNameValidity: function (attrs, json, message) {
        if(!Validator.checkValidity('profileName',attrs.description)){
            json.msgId = "wrong_profile_name_format";
            json.title = "Profile name verification";
            json.message = message[json.msgId];
            return json;
        }
    },
    checkSelectVehiclesValidity: function (attrs, json, message) {
        if (attrs.vehicles.length === 0) {
            json.msgId = "wrong_selected_vehicle_count";
            json.title = "Select Vehicles verification";
            json.message = message[json.msgId];
            return json;
        }
    },
    validate: function (attrs, options, json, message) {
        return this.checkProfileNameValidity(attrs, json, message) || this.checkSelectVehiclesValidity(attrs, json, message);
    }
})

export default ProfileBuilderFormModel
