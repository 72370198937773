import $ from 'jquery'
import PopupView from '../Popup'
import ReportPopupModel from '../../model/report/ReportPopup'

const ReportPopupView = PopupView.extend({
  model: ReportPopupModel,
  closeBtnHandler: function () {
    this.hide()
    this.on('hideAnimationEnd', function () {
      this.off('hideAnimationEnd')
      window.wlsMapTools.loadState()
      var mappingItem = $('.user-menu .categories li.mapping')
      mappingItem.siblings().removeClass('active')
      mappingItem.addClass('active')
      var topBar = $('.top-bar')
      if (topBar.is(':visible')) {
        topBar.animate({
          'margin-left': 0
        }, 500, null)
      }

      if (window.view.getActiveState() !== 'dual') {
        window.wlsMapTools.showToolsFrame()
        window.wlsMapTools.show()
        window.wlsMapTools.toggleMapTools()
        window.wlsMapTools.activateDropdowns()
        window.view.switchStateTo('fleet', true)
      }

      window.wls.loadState()

      this.getParentView().destroy() // destroy report page
    })
  },
  render: function () {
    this.getParentView().$el.append(this.el) //append popup to report page
    return this
  },
  destroy: function () {
    this.findSubView('reportForm').destroy()
    this.remove()
  }
})

export default ReportPopupView
