import SelectItemDropdownView from '../SelectItemDropdown'
import SelectVehicleDropdownModel from '../../model/proximity/SelectVehicleDropdown'

const SelectVehicleDropdownView = SelectItemDropdownView.extend({
  model: SelectVehicleDropdownModel,
  elSelector: '.select-holder.vehicle',
  selectSelector: 'vehicle'
})

export default SelectVehicleDropdownView
