import Model from '../Model'
import DriverWorkingHoursList from '../../collection/driverWorkingHours/DriverWorkingHoursList'
import Collection from '../../collection/Collection'

const DriverWorkingHoursCalendarModel = Model.extend({
  constructor: function () {
    this.defaults.currentDate = Date.now()
    this.defaults.driverWorkingHoursList = new DriverWorkingHoursList()

    Model.apply(this, arguments)
  },
  defaults: {
    currentDate: null,
    driverWorkingHoursList: null
  },
  toJSON: function () { // override
    var json = Model.prototype.toJSON.apply(this, arguments)

    if (this.attributes.driverWorkingHoursList instanceof Collection) {
      json.driverWorkingHoursList = this.attributes.driverWorkingHoursList.toJSON()
    }
    return json
  }
})

export default DriverWorkingHoursCalendarModel
