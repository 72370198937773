import CommentPopupView from '../../CommentPopup'
import VehicleDefectCommentListView from './VehicleDefectReportCommentList'
import VehicleDefectReportCommentFormView from './VehicleDefectReportCommentForm'

const VehicleDefectReportCommentPopupView = CommentPopupView.extend({
  initialize: function () {
    var view = this

    view.addSubView('commentForm', VehicleDefectReportCommentFormView)
    view.addSubView('commentList', VehicleDefectCommentListView)
    view.findSubView('commentForm').on('save', function (responseModel) {
      var vehicleDefectCheckCommentDtoList = view.getParentView().model.get('tableRow').get(responseModel.get('defectId')).get('vehicleDefectCheckCommentDtoList')

      vehicleDefectCheckCommentDtoList.unshift(responseModel.toJSON())
      view.findSubView('commentList').render(vehicleDefectCheckCommentDtoList)
      this.resetTextArea()
    })
  },
  closeBtnHandler: function () {
    var view = this

    view.findSubView('commentForm').resetTextArea()
    view.getParentView().resetSelectedItemAndRow()
    view.hide()
  },
  destroy: function () {
    var view = this

    view.findSubView('commentForm').destroy()
    view.findSubView('commentList').destroy()
    view.remove()
  }
})

export default VehicleDefectReportCommentPopupView
