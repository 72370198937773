import Model from '../../model/Model'

const GroupTabsetGroupModel = Model.extend({
  defaults: {
    id: null,
    descr: '',
    colour: ''
  },
  url: 'api/group-tabset/group'
})

export default GroupTabsetGroupModel
