import Model from '../Model'

const PreferencesModel = Model.extend({
  defaults: {
    distanceMetric: 'Miles',
    replaceDriverNameWithRegNum: false,
    vehicleIconSize: '100'
  },
  'url': 'api/settings/preferences'
})

export default PreferencesModel
