import Model from '../Model'

const DriverWorkingHoursModel = Model.extend({
  defaults: {
    date: null,
    workingTime: null,
    drivingTime: null,
    restTime: null,
    availabilityTime: null
  },
  url: 'api/tachograph/driverWorkingHours'
})

export default DriverWorkingHoursModel
