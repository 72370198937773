import ProfileBuilderPopupView from "../profileManager/ProfileBuilderPopup";
import OutOfHoursProfileBuilderFormView from "./OutOfHoursProfileBuilderForm";
import OutOfHoursProfileBuilderPopupModel from "../../../model/alert/outOfHours/OutOfHoursProfileBuilderPopup";

const OutOfHoursProfileBuilderPopupView = ProfileBuilderPopupView.extend({
    model: OutOfHoursProfileBuilderPopupModel,
    ProfileBuilderFormView: OutOfHoursProfileBuilderFormView
});

export default OutOfHoursProfileBuilderPopupView;
