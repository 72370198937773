import PopupView from '../../Popup'
import $ from 'jquery'

const ProfileBuilderPopupView = PopupView.extend({
  ProfileBuilderFormView: null,
  url: 'template/alert/profileManager/profileBuilderPopup.html',
  mergeEvents: true,
  events: {
    'click [data-button=cancel]': 'cancel'
  },
  initialize: function () {
    var view = this
    this.addSubView('profileBuilderForm', this.ProfileBuilderFormView)
  },
  loadHTML: function (url, callback) { // override
    var view = this
    view.templateUrl = url

    this.loadDotTemplate(function () {
      view.compileTemplate()
      view.$el = $(view.dotTemplateToHtml({ title: view.title }))
      view.el = view.$el[0]
      view.render()
      view.model.set('htmlReady', true)
      if (callback) callback()
    })
    return this
  },
  closeBtnHandler: function () { // override
    this.findSubView('profileBuilderForm').cancel()
    this.resetSelectedRow()
    this.hide()
  },
  cancel: function () {
    this.resetSelectedRow()
    this.hide()
  },
  resetSelectedRow: function () {
    this.getParentView().findSubView('profileManagerTableGrid').resetSelectedItemAndRow()
  },
  save: function () {
    this.hide()
  },
  destroy: function () {
    this.findSubView('profileBuilderForm').destroy()
    this.remove()
  }
})

export default ProfileBuilderPopupView
