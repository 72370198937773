import SnailTrailPopupView from '../SnailTrailPopup'
import IdlingReportFuelUsageTableGridView from './IdlingReportFuelUsageTableGrid'
import SnailTrailStepPlayView from '../SnailTrailStepPlay'

const IdlingReportFuelUsageSnailTrailPopupView = SnailTrailPopupView.extend({
  url: '/getJourneyDetailsPopup.do',
  initialize: function () {
    this.addSubView('SnailTrailStepPlayer', SnailTrailStepPlayView, { el: this.$('[data-player=stepPlay]') })
    //            this.model.set('visible',true)
    this.addSubView('snailTrailTableGrid', IdlingReportFuelUsageTableGridView)
  }
})

export default IdlingReportFuelUsageSnailTrailPopupView
