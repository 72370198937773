/**
 ** Created by Paul Zelenov
 **/

import Collection from "../Collection";
import Imei from "../../model/Imei";

const AlertAvailableImeiList = Collection.extend({
    model: Imei,
    id: null,
    url: function() {
        return `services/unplugged-device/alert-available-imei/${this.id || ""}`;
    }
});

export default AlertAvailableImeiList;
