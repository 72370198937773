import ReportModel from '../Report'

const IdlingFuelUsageReportModel = ReportModel.extend({
  defaults: {
    tableHead: [
      {
        field: 'id'
      },
      {
        field: 'startStreetName'
      },
      {
        field: 'startPostcode'
      },
      {
        field: 'startAoiPoiDescr'
      },
      {
        field: 'endStreetName'
      },
      {
        field: 'endPostcode'
      },
      {
        field: 'endAoiPoiDescr'
      },
      {
        field: 'duration'
      },
      {
        field: 'factoredMaxSpeed',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Max Speed:',
                  filterByField: 'factoredMaxSpeed',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Greater Than',
                        value: 'greaterThan'
                      },
                      {
                        option: 'Less Than',
                        value: 'lessThan'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter Value',
                    name: 'value',
                    dataType: 'float'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        field: 'driverDescr'
      },
      {
        field: 'idlingFuelConsumption',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Litres:',
                  filterByField: 'idlingFuelConsumption',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Greater Than',
                        value: 'greaterThan'
                      },
                      {
                        option: 'Less Than',
                        value: 'lessThan'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter Value',
                    name: 'value',
                    dataType: 'float'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        field: 'factoredDistance',
        tableDropOption: [
          {
            optionName: 'Sort Ascending',
            iconClass: 'asc',
            dataButton: 'sortAsc'
          },
          {
            optionName: 'Sort Descending',
            iconClass: 'desc',
            dataButton: 'sortDesc'
          },
          {
            optionName: 'Filter',
            iconClass: 'filter',
            subDrop: {
              dataLeftButtonValue: 'filter',
              dataRightButtonValue: 'clearFilter',
              leftButtonText: 'Filter',
              rightButtonText: 'Clear',
              subDropSection: [
                {
                  title: 'Distance:',
                  filterByField: 'factoredDistance',
                  select: {
                    name: 'filter',
                    options: [
                      {
                        option: 'Greater Than',
                        value: 'greaterThan'
                      },
                      {
                        option: 'Less Than',
                        value: 'lessThan'
                      }
                    ]
                  },
                  input: {
                    placeholder: 'Enter Value',
                    name: 'value',
                    dataType: 'float'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        field: 'idlingStr'
      }
    ]
  },
  urlRoot: 'api/report/idlingFuelUsage'
})

export default IdlingFuelUsageReportModel
