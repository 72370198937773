import TableGridModel from '../../TableGrid@'
import TodaysJourneyList from '../../../collection/report/todaysJourney/TodaysJourneyList'

const TodaysJourneyTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: true,
    multiSelect: true,
    tableRow: TodaysJourneyList
  }
})

export default TodaysJourneyTableGridModel
