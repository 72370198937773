import $ from 'jquery'
import PopupView from '../Popup'
import DriverWorkingHoursDetailsTableGridView from './DriverWorkingHoursDetailsTableGrid'
import DriverWorkingHoursDetailsPopupModel from '../../model/driverWorkingHours/DriverWorkingHoursDetailsPopup'

const DriverWorkingHoursDetailsPopupView = PopupView.extend({
  model: DriverWorkingHoursDetailsPopupModel,
  url: 'js/html/app/src/template/driverWorkingHours/driverWorkingHoursDetailsPopup.html',
  initialize: function () {
    var view = this

    view.addSubView('driverWorkingHoursDetailsTableGrid', DriverWorkingHoursDetailsTableGridView)
  },
  updateTitle: function (milliseconds) {
    var view = this
    var $title = view.$('[data-title]')

    $title.text($.datepicker.formatDate('D dd.mm.yy', new Date(milliseconds)))
  },
  closeBtnHandler: function () { // override
    this.hide()
    this.getParentView().$('td.active').removeClass('active')
  }
})

export default DriverWorkingHoursDetailsPopupView
