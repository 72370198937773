import PopupModel from '../Popup'

const LoneWorkerActivityLogPopupModel = PopupModel.extend({
  defaults: {
    registrationNumber: '',
    isDraggable: true,
    position: {}
  }
})

export default LoneWorkerActivityLogPopupModel
