import $ from 'jquery'
import View from '../View'

const CompactReportPageView = View.extend({
  render: function () {
    window.reportPageIsActive = true
    $('#content').append(this.el)
    window.wls.resetState()
    $('.left-panel').fadeOut(400)
    return this
  },
  show: function () {
    this.$el.hide().css({ visibility: 'visible' }).fadeIn(400)
  },
  isVisible: function () {
    return this.$el.is(':visible')
  },
  destroy: function (dontShowLeftPanel) {
    var view = this

    view.findSubView('compactReport').destroy()
    window.reportHandler.removeReport(view)
    window.reportPageIsActive = false
    if (!dontShowLeftPanel) {
      $('.left-panel').fadeIn(400)
      window.wls.loadState()
    }
    view.remove()
  }
})

export default CompactReportPageView
