import View from '../view/View'
import Popup from '../model/Popup'
import DraggablePopupView from './DraggablePopup'
import _ from 'underscore'
import $ from 'jquery'

const PopupView = View.extend({
  model: Popup,
  url: '',
  events: {
    'click [data-button=close]': 'closeBtnHandler',
    'click .max-min_btn': 'maxMin',
    'click .button.click-report': 'exportToPdf',
    'click .drop-poi-btn': 'addNewPoi',
    'contextmenu tbody tr>td.poi-address': 'goToPOI',
    'click table': 'outFromPOI'
  },
  constructor: function (option) {
    var view = this
    var args = arguments

    view.model = new view.model()

    view
      .listenTo(view.model, 'change:visible', function (model, visible) {
        if (visible) {
          this.trigger('beforeShow')
          if (!this.model.get('lockAnimation')) {
            view.showAnimation()
          } else {
            view.hide({ silent: true })
          }
        } else {
          if (!this.model.get('lockAnimation')) {
            view.hideAnimation()
          } else {
            view.show({ silent: true })
          }
        }
      })
      .listenTo(view.model, 'change:isDraggable', function (model, isDraggable) {
        if (isDraggable) {
          view.initDraggable()
        } else {
          view.$el.draggable('destroy')
        }
      })
      .loadHTML(view.url || option.url, function () {
        if (view.model.get('isDraggable')) {
          view.initDraggable()
        }
        View.apply(view, args)
      })
  },
  render: function () {
    $('#content').append(this.el)
    return this
  },
  isOutsideBottomBorder: function () {
    var view = this

    return view.$el.offset().top + view.$el.height() > $(window).height()
  },
  moveTo: function (top, left) {
    var cssOptions = {}

    if (top) {
      cssOptions.top = top
    }
    if (left) {
      cssOptions.left = left
    }
    this.$el.css(cssOptions)
  },
  prepare: function (handler) {
    this.$el.css({ visibility: 'hidden', display: 'block' })
    if (handler) handler()
  },
  prepareDone: function () {
    this.$el.css({ visibility: 'visible', display: 'none' })
  },
  closeBtnHandler: function () {
    this.resetWindowSize()
    this.hide()
  },
  hideAnimation: function () {
    var view = this
    view.$el.fadeOut(300, function () {
      view.trigger('hideAnimationEnd')
    })
  },
  showAnimation: function () {
    var view = this
    view.$el.fadeIn(300, function () {
      view.trigger('showAnimationEnd')
    })
  },
  hide: function (option) {
    this.model.set('visible', false, option)
  },
  show: function (option) {
    this.model.set('visible', true, option)
  },
  loadHTML: function (url, callback) {
    var view = this
    $.post(url, function (msg) {
      view.$el = $(msg)
      view.el = view.$el[0]
      view.render()
      view.model.set('htmlReady', true)
      if (callback) callback()
    })
    return this
  },
  align: function (popup) {
    if (!popup) {
      popup = this.$el
    }

    popup.css('top', ($(window).height() / 2) - (popup.outerHeight() / 2))
  },
  alignBy: function ($alignBy, position, padding) {
    var offset = $alignBy.offset()
    var defaultPadding = {
      top: 0,
      left: 0
    }
    if (!padding) {
      padding = defaultPadding
    } else {
      padding = _.extend(defaultPadding, padding)
    }
    if (position === 'center') {
      this.$el.css({
        top: offset.top + $alignBy.outerHeight() + padding.top,
        left: offset.left - (this.$el.outerWidth() - $alignBy.outerWidth()) / 2
      })
    } else if (position === 'topRight') {
      this.$el.css({
        top: offset.top + padding.top,
        left: offset.left + $alignBy.outerWidth() + padding.left
      })
    } else if (position === 'bottomRight') {
      this.$el.css({
        top: offset.top + $alignBy.outerHeight() + padding.top,
        left: offset.left + $alignBy.outerWidth() + padding.left
      })
    } else if (position === 'commentsBox') {
      this.$el.css({
        top: offset.top + $alignBy.outerHeight() + padding.top,
        left: offset.left - this.$el.outerWidth() + $alignBy.outerWidth() / 2 + $alignBy.parents('td.with-ico').outerWidth() / 2
      })
    }
  },
  correctElPositionResize: function () {
    var view = this
    var $wind = $(window)
    var $el = view.$el
    var posVertical = null
    var posHorizontal = null

    if ($wind.height() < $el.offset().top) {
      posVertical = -(($el.offset().top - $wind.height()) + 20)
    }

    if ($wind.width() < $el.offset().left) {
      posHorizontal = -(($el.offset().left - $wind.width()) + 20)
    }

    if (posVertical) {
      $el.animate({ top: '+=' + (posVertical) }, 400)
    }

    if (posHorizontal) {
      $el.animate({ left: '+=' + (posHorizontal) }, 400)
    }
  },
  initDraggable: function () {
    var view = this
    var resizeable = null

    new DraggablePopupView({
      el: view.$el
    })

    $(window).resize(function () {
      var doc = $(document)

      if (window.view.getActiveState() !== 'dual') {
        view.$el.draggable('option', 'containment', [0, 0, doc.width() - 20, doc.height() - 10])
        clearTimeout(resizeable)
        resizeable = setTimeout(function () {
          view.correctElPositionResize()
        }, 200)
      }
    })
  },
  isVisible: function () {
    return this.model.get('visible')
  },
  maxMin: function () {
    var view = this

    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.$el.animate({ top: view.model.get('position').top, left: view.model.get('position').left }, 300)
      view.maximisePopup()
    } else {
      view.model.set('position', { top: view.$el[0].offsetTop, left: view.$el[0].offsetLeft })
      view.minimisePopup()
      view.$el.animate({ top: window.innerHeight * 0.96 + 'px', left: '260px' }, 300)
    }
  },
  maximisePopup: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.find('.info-holder').show()
    view.$el.find('.control-holder').show()
    view.$el.css('height', 'auto')
  },
  minimisePopup: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.find('.info-holder').hide()
    view.$el.find('.control-holder').hide()
    view.$el.css('height', 'auto')
  },
  resetWindowSize: function () {
    var view = this

    view.$el.find('.max-min_btn').toggleClass('active')
    view.$el.css('height', 'auto')
    view.$el.find('.info-holder').show()
    view.$el.find('.control-holder').show()
    if (view.model.get('position')) {
      view.$el.animate({ top: view.model.get('position').top, left: view.model.get('position').left }, 300)
    }
  }
})

export default PopupView
