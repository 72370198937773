import $ from 'jquery'
import View from '../../View'
import SpeedingReportModel from '../../../model/report/speedingReport/SpeedingReport'
import SpeedingReportSnailTrailPopupView from './SpeedingReportSnailTrailPopup'
import ReportView from '../Report'
import SpeedingReportScrollView from './SpeedingReportScroll'

const SpeedingReportView = ReportView.extend({
  model: SpeedingReportModel,
  templateUrl: 'template/report/speedingReport/speedingReport.html',
  mergeEvents: true,
  events: {
    'mousedown th': 'showDropOld',
    'click .table-drop li': 'sort',
    'click .drop-poi-btn': 'addNewPoi',
    'contextmenu tbody tr>td.poi-address': 'goToPOI',
    'click table': 'outFromPOI'
  },
  initialize: function (option) {
    var view = this

    view.addSubView('snailTrailPopup', SpeedingReportSnailTrailPopupView)
    view.addSubView('scroll', SpeedingReportScrollView)
  },
  isModelEmpty: function (model) { // override
    return !model.get('repJourneySections') || (model.get('repJourneySections') && !model.get('repJourneySections').length)
  },
  getPOIcoordinates: function (el) {
    var view = this

    $.get('/api/report/journey/snail-trail/' + el).done(function (res) {
      if (res.lat === 0 || res.lon === 0) {
        window.AddPOI.lat = 51.507351
        window.AddPOI.lon = -0.127758
      } else {
        window.AddPOI.lat = res.lat
        window.AddPOI.lon = res.lon
      }

      view.hideSnailTrailPopup()

      window.AddPOI.goToPOIAOI('fromJourneyReport')
    }).error(function (err) {
      console.log('Error is --- ', err)
    })
  }
})

export default SpeedingReportView
