
import AlertTableGridView from "../AlertTableGrid";
import UnpluggedDeviceAlertTableGridModel from "../../../model/alert/unpluggedDevice/UnpluggedDeviceAlertTableGrid";
import SelectView from "../../Select";

const UnpluggedDeviceAlertTableGridView = AlertTableGridView.extend({
    model: UnpluggedDeviceAlertTableGridModel,
    mergeEvents: true,
    events: {
        "click [data-button=status]": "showFilterDrop",
        "click [data-button=filter]": "handleFilter",
        "click [data-button=clear]": "clear",
        "mouseover [data-drop=table] li": "showSubDrop",
        "click tbody tr:not(.empty-row)": ""
    },
    initialize: function() {
        this.addSubView("select", SelectView, { el: this.$("select") });
        AlertTableGridView.prototype.initialize.apply(this, arguments);
    },
    render: function() {
        AlertTableGridView.prototype.render.apply(this, arguments);
        this.handleFilter();
    },
    showFilterDrop: function(e) {
        var $button = $(e.currentTarget);
        var $popup = this.getParentView().$el;
        var $drop = this.$el.find("[data-drop=table]");

        $drop.show().css({ top: e.pageY - $popup.offset().top, left: e.pageX - $popup.offset().left });
        $drop.find(".table-sub-drop").css("left", $drop.width());
    },
    showSubDrop: function(e) {
        var $filter = $(e.currentTarget);

        $filter.find(".table-sub-drop").show();
    },
    edit: function(e) {
        var $tr = $(e.currentTarget).closest('tr');
        var dataId = $(e.currentTarget).attr('data-id')
        e.currentTarget = $tr[0];

        if ($tr.hasClass('active')) {
            this.templateOptions['activeRowId'] = null;
        } else {
            this.templateOptions['activeRowId'] = dataId;
        }
        this.setActiveRow(e);
    },
    activateDeactivate: function(e) {
        AlertTableGridView.prototype.activateDeactivate.apply(this, arguments);
        this.handleFilter();
    },
    clear: function() {
        this.$el.find("select").val("none");
        this.findSubView("select").$el.selectmenu("refresh");
        this.handleFilter();
    },
    handleFilter: function() {
        var view = this;
        var selectValue = this.$el.find("select").val();

        switch (selectValue) {
            case "active":
                view.filterActive();
                break;
            case "inactive":
                view.filterInactive();
                break;
            case "none":
                view.$el.find("tbody tr").show();
                break;
            default:
                break;
        }
        view.$("[data-drop=table]").hide();
        this.findSubView("scroll").render();
    },
    filterActive: function() {
        var view = this;
        var $tr = view.$el.find("tbody tr");

        $tr.each(function() {
            if (this.getAttribute("data-id")) {
                if (view.collection.get(this.getAttribute("data-id")).get("active")) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            }
        });
    },
    filterInactive: function() {
        var view = this;
        var $tr = view.$el.find("tbody tr");

        $tr.each(function() {
            if (this.getAttribute("data-id")) {
                if (!view.collection.get(this.getAttribute("data-id")).get("active")) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            }
        });
    }
});

export default UnpluggedDeviceAlertTableGridView;
