import Model from '../../Model'

const FuelTankLevelVarianceModel = Model.extend({
  defaults: {
    account: '',
    address: null,
    fuelLevelEngineOffDate: null,
    fuelLevelEngineOffLitres: null,
    fuelLevelEngineOffTankPercent: null,
    fuelLevelEngineOnDate: null,
    fuelLevelEngineOnLitres: null,
    fuelLevelEngineOnTankPercent: null,
    fuelLevelVarianceLitres: null,
    fuelLevelVarianceTankPercent: null,
    group: '',
    postcode: null,
    subGroup: null,
    vehReg: ''
  }
})

export default FuelTankLevelVarianceModel
