/**
 * Created by George on 24.07.2015.
 */
import FormView from '../../Form';
import OverspeedAlert from '../../../model/alert/overspeed/OverspeedAlert';

const OverspeedAlertFormView = FormView.extend({
    model: OverspeedAlert,
    mergeEvents: true,
    events: {
        "change input[name=sendToSelf]": "sendToSelfChange"
    },
    constructor: function() {
        const parentOverspeedAlert = this.parentView;
        this.el = parentOverspeedAlert.$el.find("form");
        FormView.apply(this, arguments);
    },
    initialize: function() {
        const view = this;
        const notificationPopupView = view.findSubView("notificationPopup");
        const parentOverspeedAlertView = this.parentView;
        let OverspeedAlertTabsetView;
        let OverspeedAlertListPopup;
        let OverspeedAlertTableGridView;

        view.listenTo(view.model, "change:sendToSelf", function(model, sendToSelf) {
            view.renderSendToSelf(sendToSelf);
        });

        parentOverspeedAlertView.on("ready", function() {
            OverspeedAlertTabsetView = parentOverspeedAlertView.tabsetView;
            OverspeedAlertListPopup = parentOverspeedAlertView.findSubView("alertListPopup");
            OverspeedAlertTableGridView = OverspeedAlertListPopup.findSubView("alertTableGrid");

            view.on("cancel", function() {
                let selectProfile;
                let selectRecipientTableGridView;

                view.getParentView().switchTitle("create");
                OverspeedAlertTableGridView.resetSelectedItemAndRow();
                if ("selectProfile" in OverspeedAlertTabsetView) {
                    selectProfile = OverspeedAlertTabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid");
                    selectProfile.reloadContent();
                }
                if ("selectRecipient" in OverspeedAlertTabsetView) {
                    selectRecipientTableGridView = OverspeedAlertTabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid");
                    selectRecipientTableGridView.reset();
                }
            });

            view.on("save", function() {
                OverspeedAlertTableGridView.model.get('tableRow').reset();
                if (OverspeedAlertListPopup.isVisible()) {
                    OverspeedAlertTableGridView.fetchAlertlist();
                }
                view.cancel();
                notificationPopupView.render({
                    msgId: "successfully_saved",
                    title: "",
                    message: "Successfully saved.",
                    button: ["OK"]
                });
            });

            view.model.on("invalid", function(model, error) {
                notificationPopupView.render(error);
            });
        });
    },
    sendToSelfChange : function(e) {
        const $checkbox = $(e.currentTarget);

        this.model.set("sendToSelf", $checkbox.prop("checked"), { silent: true });
    },
    renderSendToSelf: function() {
        this.$el.find("input[name=sendToSelf]").prop("checked", this.model.get("sendToSelf"));
    },
    cancel: function() {
        const parentView = this.getParentView();
        parentView.model.set({ dataMode: "create" });
        parentView.tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.id = 0;
        this.reset();
        this.trigger("cancel");
    },
    syncData: function() {
        const OverspeedAlertView = this.getParentView();
        const OverspeedAlertTabsetView = OverspeedAlertView.tabsetView;

        if ("selectProfile" in OverspeedAlertTabsetView) {
            this.model.set("selectedAlertProfileList", OverspeedAlertTabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").selectedTableGridCollection.toJSON());
        }
        if ("selectRecipient" in OverspeedAlertTabsetView) {
            this.model.set("selectedAlertRecipientList", OverspeedAlertTabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").model.get('tableRow').toJSON());
        }
        this.model.set(this.getFormData());
        if (OverspeedAlertView.model.get("dataMode") === "edit") {
            this.model.set("id", OverspeedAlertView.model.get("alertId"));
            this.model.set("active", OverspeedAlertView.alertListPopupView.findSubView("alertTableGrid").model.get('tableRow').get(this.model).get("active"));
        } else {
            OverspeedAlertView.model.set("alertId", null);
            this.model.set("id", null);
        }
    }
});

export default OverspeedAlertFormView;
