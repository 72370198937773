import ReportModel from '../Report'

const DigitalInputReportModel = ReportModel.extend({
  urlRoot: 'api/report/digital-input',
  defaults: {
    digitalInputSections: []
  }
})

export default DigitalInputReportModel
