import ReportSelectionMenuView from '../../ReportSelectionMenu'
import SpeedingReportSelectionMenuModel from '../../../model/report/speedingReport/SpeedingReportSelectionMenu'
import SpeedingReportSelectionMenuVehicleListView from './SpeedingReportSelectionMenuVehicleList'

const SpeedingReportSelectionMenuView = ReportSelectionMenuView.extend({
  model: SpeedingReportSelectionMenuModel,
  templateUrl: 'template/report/speedingReport/speedingReportSelectionMenu.html',
  elSelector: '[data-selection-menu=speeding]',
  initialize: function () {
    var view = this

    view.addSubView('vehicleList', SpeedingReportSelectionMenuVehicleListView)
    view.render()
  }
})

export default SpeedingReportSelectionMenuView
