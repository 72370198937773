import AvailableTableGridModel from '../../model/alert/AvailableTableGrid'
import TableGridView from '../TableGrid'
import $ from 'jquery'
import _ from 'underscore'

const AvailableTableGridView = TableGridView.extend({
  model: AvailableTableGridModel,
  elementIndex: {},
  initialize: function () {
    var view = this

    $(window).resize(function () {
      view.scroll.render()
    })
  },
  sortByIndex: function () {
    var view = this
    var modelsSortedByIndex = _.sortBy(view.collection.models, function (model) {
      return view.getElementIndex(model)
    })
    view.collection.set(modelsSortedByIndex, { silent: true })
  },
  getElementIndex: function (model) {
    return this.elementIndex[model.get('id')]
  },
  setElementIndex: function () {
    var view = this
    this.$el.find('tbody tr').each(function (i) {
      view.elementIndex[$(this).attr('data-id')] = i
    })
  },
  renderGrid: function () {
    this.render()
    this.scroll.render()

    return this
  },
  enableCopy: function () {
    this.$el.find('[data-button=copy]').removeClass('disabled')
  },
  disableCopy: function () {
    this.$el.find('[data-button=copy]').addClass('disabled')
  }
})

export default AvailableTableGridView
