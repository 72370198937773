import View from './View'
import SafeInputModel from '../model/SafeInput'

const SafeInputView = View.extend({
  model: SafeInputModel,
  events: {
    keydown: 'render'
  },
  constructor: function (option) {
    var view = this
    var parentView = view.getParentView()

    view.model = new view.model()
    if (option && !option.el) {
      view.$el = parentView.$('input:text')
      view.el = view.$el[0]
    }

    View.apply(view, arguments)
  },
  render: function (e) {
    var model = this.model

    model.set('keyCode', e.keyCode)

    if (!model.isValid()) {
      e.preventDefault()
    }
    return this
  },
  destroy: function () {
    this.remove()
  }
})

export default SafeInputView
