import Model from '../Model'

const AlertPanelModel = Model.extend({
  defaults: {
    alertId: 0,
    dataMode: 'create'
  }
})

export default AlertPanelModel
