import $ from 'jquery'
import View from '../View'

const ModalReportPageView = View.extend({
  render: function () {
    window.reportPageIsActive = true
    $('.map-wrap').append(this.el)
    //            wls.resetState()
    //            $('.left-panel').fadeOut(400)
    return this
  },
  show: function () {
    this.$el.hide().css({ visibility: 'visible' }).fadeIn(400)
  },
  isVisible: function () {
    return this.$el.is(':visible')
  },
  destroy: function () {
    var view = this

    view.findSubView('reportPopup').destroy()
    window.reportHandler.removeReport(view)
    window.reportPageIsActive = false
    /*
    if(!dontShowLeftPanel){
        $('.left-panel').fadeIn(400)
        wls.loadState()
    }
    */
    view.remove()
  }
})

export default ModalReportPageView
