/**
 * Created by Yurii on 24.02.2021.
 */
import Popup from '../../Popup';

const OverspeedAlertPopupModel = Popup.extend({
    defaults: {
        isDraggable: true,
    }
});

export default OverspeedAlertPopupModel;
