import $ from 'jquery'
import SnailTrailPopupView from '../SnailTrailPopup'
import LoneWorkerJourneyReportSnailTrailTableGridView from './LWJourneyReportSnailTrailTableGrid'
import SnailTrailStepPlayView from '../SnailTrailStepPlay'

const LoneWorkerJourneyReportSnailTrailPopupView = SnailTrailPopupView.extend({
  url: '/getJourneyDetailsPopup.do',
  initialize: function () {
    this.addSubView('SnailTrailStepPlayer', SnailTrailStepPlayView, { el: this.$('[data-player=stepPlay]') })
    this.addSubView('snailTrailTableGrid', LoneWorkerJourneyReportSnailTrailTableGridView)
  },
  show: function () { // override
    $('[data-popup="journeyDetails"]').addClass('journey-report')
    $('#content').prepend($('[data-popup="journeyDetails"]'))

    SnailTrailPopupView.prototype.show.apply(this, arguments)
  },
  generatePdfXlsReportURL: function (repFormat) { // overriding implementation
    var view = this
    var parentView = view.getParentView()
    var tableGridView = view.findSubView('snailTrailTableGrid')
    var journeyIds = parentView.getSelectedJourneyList()
    var mapMetadata = window.mapCanvas.map.getCenter()

    var data = {
      'report-name': 'LoneWorkerJourneyImg',
      'report-type': 'LoneWorkerJourneyImg',
      'report-format': repFormat,
      alternate: false,
      journeyIdList: journeyIds.join('/'),
      centerLat: mapMetadata.latitude,
      centerLon: mapMetadata.longitude,
      zoomLevel: window.map.map.getZoom(),
      selectedIndex: tableGridView.selectedIndex
    }

    return $.param(data)
  }
})

export default LoneWorkerJourneyReportSnailTrailPopupView
