import TableDropView from '../TableDrop'

const ReportTableDropView = TableDropView.extend({
  saveSort: function (sortData, $activeTh) {
    var thIndex = $activeTh.index()
    var rowIndex = $activeTh.closest('.row').index()
    var daySectionIndex = $activeTh.closest('[data-section]').index() - 1

    if (!this.sortData[rowIndex]) {
      this.sortData[rowIndex] = {}
    }
    this.sortData[rowIndex][daySectionIndex] = sortData
  },
  clearSortData: function ($activeTh) {
    var rowIndex = $activeTh.closest('.row').index()
    var daySectionIndex = $activeTh.closest('[data-section]').index() - 1
    var thIndex = $activeTh.index()

    if (this.sortData[thIndex].hasOwnProperty(rowIndex) && this.sortData[rowIndex].hasOwnProperty(daySectionIndex)) {
      delete this.sortData[rowIndex][daySectionIndex]
    }
  },
  saveFilter: function (filterData, $activeTh) {
    var thIndex = $activeTh.index()
    var rowIndex = $activeTh.closest('.row').index()
    var daySectionIndex = $activeTh.closest('[data-section]').index() - 1

    if (!this.filterData[rowIndex]) {
      this.filterData[rowIndex] = {}
    }
    if (!this.filterData[rowIndex][daySectionIndex]) {
      this.filterData[rowIndex][daySectionIndex] = {}
    }

    this.filterData[rowIndex][daySectionIndex][thIndex] = filterData
  },
  clearFilterData: function ($activeTh) {
    var rowIndex = $activeTh.closest('.row').index()
    var daySectionIndex = $activeTh.closest('[data-section]').index() - 1
    var thIndex = $activeTh.index()

    if (this.filterData.hasOwnProperty(rowIndex) && this.filterData[rowIndex].hasOwnProperty(daySectionIndex) && this.filterData[rowIndex][daySectionIndex].hasOwnProperty(thIndex)) {
      delete this.filterData[rowIndex][daySectionIndex][thIndex]
    }
  }
})

export default ReportTableDropView
