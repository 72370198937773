import $ from 'jquery'
import _ from 'underscore'
import ReportSelectionMenuView from '../../view/ReportSelectionMenu'
import JourneyReportSelectionMenuModel from '../../model/report/journeyReport/JourneyReportSelectionMenu'
import ProximitySelectionMenuVehicleListView from './ProximitySelectionMenuVehicleList'

const ProximitySelectionMenuView = ReportSelectionMenuView.extend({
  model: JourneyReportSelectionMenuModel,
  templateUrl: 'template/proximity/proximitySelectionMenu.html',
  // $el: '[data-selection-menu=proximity]',
  elSelector: '[data-selection-menu=proximity]',
  initialize: function () {
    var view = this

    view.addSubView('vehicleList', ProximitySelectionMenuVehicleListView)
    view.render(true)
    view.hide()
  },
  hideVehicle: function (id) {
    var view = this
    view.findSubView('vehicleList').hideVehicle(id)
  },
  isVisible: function () {
    var view = this

    return $(view.el).is(':visible')
  },
  resetSelection: function () {
    var view = this
    view.findSubView('vehicleList').resetSelection()
  },
  select: function (e) {
    var view = this
    var $selectionOption = $(e.currentTarget)
    var selectionName = $selectionOption.attr('data-select')

    switch (selectionName) {
      case 'SM_SELECT_ALL':
        view.selectAll($selectionOption)
        break
      case 'SM_SELECT_NONE':
        view.selectNone($selectionOption)
        break
      case 'SM_INVERT_SELECTION':
        view.invertSelection($selectionOption)
        break
      default:
        break
    }
    view.hideDropdown($selectionOption.closest('[data-dropdown]'))
    // view.getParentView().findSubView('reportPopup').findSubView('reportForm').reportFormSyncSelectedVehicles()
  },
  selectAll: function ($selectionOption) {
    var view = this
    var i = 0
    var selectionMenuVehicleListView = view.findSubView('vehicleList')

    if (window.wls.hasShapes()) {
      window.mapCanvas.cleanUp()
    }

    selectionMenuVehicleListView.collection.each(function (vehicleModel) {
      i++
      selectionMenuVehicleListView.selectVehicle(vehicleModel.get('id'), true)
    })
  },
  selectNone: function () {
    var view = this
    var i = 0
    var selectionMenuVehicleListView = view.findSubView('vehicleList')

    selectionMenuVehicleListView.collection.each(function (vehicleModel) {
      i++
      selectionMenuVehicleListView.deselectVehicle(vehicleModel.get('id'), true)
    })
  },
  selectedVehicle: function (list) {
    var view = this
    var selectedVehicles = list
    var selectionMenuVehicleListView = view.findSubView('vehicleList')

    if (window.wls.hasShapes()) {
      window.mapCanvas.cleanUp()
    }

    for (var i = 0; i < selectedVehicles.length; i++) {
      if (selectedVehicles[i].selected) {
        selectionMenuVehicleListView.selectVehicle(selectedVehicles[i].id, true)
      }
    }
  },
  invertSelection: function () {
    var view = this
    var i = 0
    var selectionMenuVehicleListView = view.findSubView('vehicleList')

    selectionMenuVehicleListView.collection.each(function (vehicleModel) {
      i++
      if (vehicleModel.get('selected')) {
        selectionMenuVehicleListView.deselectVehicle(vehicleModel.get('id'), true)
      } else {
        selectionMenuVehicleListView.selectVehicle(vehicleModel.get('id'), true)
      }
    })
  },
  showDistances: function (pin) {
    var view = this
    var proximitySelectionMenu = view.findSubView('vehicleList')

    proximitySelectionMenu.showDistances(pin)
    proximitySelectionMenu.render(_.sortBy(proximitySelectionMenu.collection.toJSON(), 'dis'))
    proximitySelectionMenu.showDistances(pin)
    view.hideVehicle(window.cid)
  },
  sort: function (e) {
    var view = this
    var $sortOption = $(e.currentTarget)
    var selectionName = $sortOption.attr('data-sort')
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var sortedVehicles
    var selectedVehicles
    var sortOrder

    switch (selectionName) {
      case 'SM_REGISTRATION':
        sortedVehicles = view.sortRegistration($sortOption)
        break
      case 'SM_TIME':
        sortedVehicles = view.sortTime($sortOption)
        break
      default:
        break
    }
    view.hideDropdown($sortOption.closest('[data-dropdown]'))
    selectionMenuVehicleListView.render(sortedVehicles)
    selectionMenuVehicleListView.showDistances(window.pin)
    selectedVehicles = _.filter(sortedVehicles, 'selected')
    view.hideVehicle(window.cid)
    view.selectedVehicle(selectedVehicles)
  },
  filter: function (e) {
    var view = this
    var $selectionOption = $(e.currentTarget)
    var selectionName = $selectionOption.attr('data-filter')
    var selectionMenuVehicleListView = view.findSubView('vehicleList')
    var filtredVehicles
    var selectedVehicles
    var oldCollection

    if ($selectionOption.hasClass('active') || $selectionOption.parent().siblings().find('[data-filter]').hasClass('active')) {
      view.findSubView('vehicleList').collection.add(view.model.get('vehicles'), { silent: true })
    }
    oldCollection = view.findSubView('vehicleList').collection.toJSON()
    view.model.set('vehicles', oldCollection)
    $selectionOption.addClass('active').parent().siblings().removeClass('active')
    switch (selectionName) {
      case 'sm_no_filter':
        filtredVehicles = view.noFilter()
        $selectionOption.removeClass('active')
        break
      case 'sm_tractor':
        filtredVehicles = view.filterTractor()
        break
      case 'sm_plant':
        filtredVehicles = view.filterPlant()
        break
      case 'sm_trailer_device':
        filtredVehicles = view.filterTrailerDevice()
        break
      case 'sm_trailer_tag':
        filtredVehicles = view.filterTrailerTag()
        break
      case 'sm_ignition_on':
        filtredVehicles = view.filterIgnitionOn()
        break
      case 'sm_ignition_off':
        filtredVehicles = view.filterIgnitionOff()
        break
      case 'sm_idling':
        filtredVehicles = view.filterIdling()
        break
      default:
        break
    }
    view.$('[data-sort].asc,[data-sort].desc').removeClass('asc').removeClass('desc')
    view.hideDropdown($selectionOption.closest('[data-dropdown]'))
    view.findSubView('vehicleList').collection.set(filtredVehicles, { silent: true })
    selectedVehicles = _.filter(filtredVehicles, 'selected')
    selectionMenuVehicleListView.showDistances(window.pin)
    selectionMenuVehicleListView.render(_.sortBy(selectionMenuVehicleListView.collection.toJSON(), 'dis'))
    selectionMenuVehicleListView.showDistances(window.pin)
    view.hideVehicle(window.cid)
    view.selectedVehicle(selectedVehicles)
  }
})

export default ProximitySelectionMenuView
