import Model from './Model'

const PoiDropdownModel = Model.extend({
  urlRoot: 'api/geo-resource/poi-options/',
  defaults: {
    data: []
  }
})

export default PoiDropdownModel
