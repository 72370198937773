/**
 * Created by George on 20.07.2015.
 */
import AlertTableGridView from '../AlertTableGrid';
import OverspeedAlertTableGridModel from '../../../model/alert/overspeed/OverspeedAlertTableGrid';
import SelectView from '../../Select';

const OverspeedAlertTableGridView = AlertTableGridView.extend({
    model: OverspeedAlertTableGridModel,
    mergeEvents: true,
    events: {
        "click [data-button=status]": "showFilterDrop",
        "click [data-button=filter]": "handleFilter",
        "click [data-button=clear]": "clear",
        "mouseover [data-drop=table] li": "showSubDrop",
        "click tbody tr:not(.empty-row)": ""
    },
    initialize: function(){
        this.addSubView("select", SelectView, { el: this.$("select") });
        AlertTableGridView.prototype.initialize.apply(this, arguments);
    },
    render: function(){
        AlertTableGridView.prototype.render.apply(this, arguments);
        this.handleFilter();
    },
    showFilterDrop: function(e){
        const $button = $(e.currentTarget);
        const $popup = this.getParentView().$el;
        const $drop = this.$el.find("[data-drop=table]");

        $drop.show().css({ top: e.pageY - $popup.offset().top, left: e.pageX - $popup.offset().left });
        $drop.find(".table-sub-drop").css("left", $drop.width());
    },
    showSubDrop: function(e){
        const $filter = $(e.currentTarget);

        $filter.find(".table-sub-drop").show();
    },
    activateDeactivate: function(e){
        AlertTableGridView.prototype.initialize.apply(this, arguments);
        this.handleFilter();
    },
    clear: function(){
        this.$el.find("select").val("none");
        this.findSubView("select").$el.selectmenu("refresh");
        this.handleFilter();
    },
    handleFilter: function(){
        const view = this;
        const selectValue = this.$el.find("select").val();

        switch(selectValue ){
            case "active":
                view.filterActive();
                break;
            case "inactive":
                view.filterInactive();
                break;
            case "none":
                view.$el.find("tbody tr").show();
                break;
            default:
                break;
        }
        view.$("[data-drop=table]").hide();
        this.findSubView("scroll").render();
    },
    filterActive: function(){
        const view = this;
        const $tr = view.$el.find("tbody tr");

        $tr.each(function(){
            if(view.collection.get(this.getAttribute("data-id")).get("active")){
                $(this).show();
            }else{
                $(this).hide();
            }
        });
    },
    filterInactive: function(){
        const view = this;
        const $tr = view.$el.find("tbody tr");

        $tr.each(function(){
            if(!view.collection.get(this.getAttribute("data-id")).get("active")){
                $(this).show();
            }else{
                $(this).hide();
            }
        });
    }
});

export default OverspeedAlertTableGridView;
