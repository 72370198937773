import Model from '../Model'

const LoneWorkerActivityLogModel = Model.extend({
  defaults: {
    recDate: null,
    details: null,
    directionCode: '',
    id: null,
    ignitionStr: '',
    inStealthMode: false,
    input1Str: '',
    input2Str: '',
    input3Str: '',
    journeyEnd: false,
    journeyStart: false,
    lat: null,
    lon: null,
    streetName: '',
    postcode: '',
    poiDescr: '',
    factoredSpeed: null,
    applicationStatusUrl: '',
    movingStateUrl: '',
    directionIconUrl: '',
    vehicleIconUrl: ''
  }
})

export default LoneWorkerActivityLogModel
