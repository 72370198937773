import $ from 'jquery'
import _ from 'underscore'
import View from './View'
import SelectionMenuVehicleList from '../collection/SelectionMenuVehicleList'

const SelectionMenuVehicleListView = View.extend({
  events: {
    'click [data-opener=slide]': 'toggleSlide',
    'click [data-select=vehicle]': 'toggleActive'
  },
  collection: SelectionMenuVehicleList,
  constructor: function () {
    var view = this
    var args = arguments
    var selectionMenuView = view.getParentView()

    view.collection = new view.collection(selectionMenuView.model.get('vehicles'))

    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, args)
    })
  },
  render: function (_json) {
    var view = this
    var json
    var $vehicleList
    var selectionMenuView = view.getParentView()

    if (selectionMenuView.getParentView().el.dataset.reportPage !== 'loneWorkerJourney') {
      view.collection = view.isLocked(view.collection, 'Vehicle')
    } else {
      view.collection = view.isLocked(view.collection, 'LoneWorker')
    }

    if (!view.$el.attr('data-vehicle-list')) {
      view.resetElement()
      view.selectVehicleFromStorage()
    }

    json = _json ? _json : view.collection.toJSON()
    $vehicleList = $(view.dotTemplateToHtml(json))
    view.$el.find('.slidee').html($vehicleList)
    view.findSubView('scroll').render()

    return view
  },
  isLocked: function (collection, type) {
    var arr = collection

    for (var i = 0; i < arr.models.length; i++) {
      if (arr.models[i].attributes.typeDescr === type && arr.models[i].attributes.locked) {
        arr.models[i].attributes.locked = arr.models[i].attributes.locked = true
      } else if (arr.models[i].attributes.typeDescr !== type) {
        arr.models[i].attributes.locked = true
      } else {
        arr.models[i].attributes.locked = false
      }
    }

    return arr
  },
  selectVehicle: function (vehicleId, silent) {
    var view = this
    var vehicleModel = view.collection.get(vehicleId)

    if (vehicleModel.get('locked')) return
    // if (vehicleModel.get('inStealthMode')) return
    view.$('[data-item=vehicle][data-id=' + vehicleId + ']').addClass('active')
    vehicleModel.set('selected', true, { silent: silent ? true : false })
  },
  deselectVehicle: function (vehicleId, silent) {
    var view = this
    var vehicleModel = view.collection.get(vehicleId)

    view.$('[data-item=vehicle][data-id=' + vehicleId + ']').removeClass('active')
    vehicleModel.set('selected', false, { silent: silent ? true : false })
  },
  toggleActive: function (e) {
    var view = this
    var $vehicleHolder = $(e.currentTarget)
    var vehicleId = $vehicleHolder.closest('[data-item=vehicle]').attr('data-id')
    if ($vehicleHolder.closest('[data-item=vehicle]').hasClass('active')) {
      view.deselectVehicle(vehicleId)
    } else {
      view.selectVehicle(vehicleId)
    }
    view.getParentView().getParentView().findSubView('reportPopup').resetWindowSize()
  },
  toggleSlide: function (e) {
    var view = this
    var $vehicleHeader = $(e.currentTarget)
    var $slide = $vehicleHeader.next()

    if (view.$(':animated').size()) return
    if ($slide.is(':hidden')) {
      view.showSlide($slide)
    } else {
      view.hideSlide($slide)
    }
  },
  showSlide: function ($slide, callback) {
    var view = this
    var collection = view.collection

    $slide.slideDown(400, function () {
      collection.get($slide.closest('[data-item=vehicle]').attr('data-id')).set('expanded', true)
      view.findSubView('scroll').render()
      if (callback) callback()
    })
  },
  hideSlide: function ($slide, callback) {
    var view = this
    var collection = view.collection

    $slide.slideUp(400, function () {
      collection.get($slide.closest('[data-item=vehicle]').attr('data-id')).set('expanded', false)
      view.findSubView('scroll').render()
      if (callback) callback()
    })
  },
  selectVehicleFromStorage: function () {
    var view = this
    var selectedVehicles = JSON.parse(window.localStorage.getItem('selectedVehicles'))
    var selectedVehiclesLen = selectedVehicles.length
    for (var i = 0; i < selectedVehiclesLen; i++) {
      var selectedVehiclesId = selectedVehicles[i]
      var vehicleModel = view.collection.get(selectedVehiclesId)
      if (vehicleModel) {
        vehicleModel.set('selected', true)
      }
    }
  },
  saveSelectedVehicleToStorage: function () {
    var view = this
    var selectedVehicles = _.filter(view.collection.toJSON(), { selected: true })

    window.localStorage.setItem('selectedVehicles', JSON.stringify(_.pluck(selectedVehicles, 'id')))
  },
  destroy: function () {
    this.findSubView('scroll').destroy()
    this.remove()
  }
})

export default SelectionMenuVehicleListView
