import TableGridModel from '../../TableGrid@'
import GeoMonitorList from '../../../collection/alert/geoMonitor/GeoMonitorList'

const GeoMonitorTableGridModel = TableGridModel.extend({
  defaults: {
    selectAllowed: false,
    tableHead: [{
      tableHeadName: 'Vehicle Reg.',
      field: 'vehReg',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'recipient',
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'string'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Regular Driver',
      field: 'regularDriver'
    },
    {
      tableHeadName: 'Date',
      field: 'date',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        }
      ]
    },
    {
      tableHeadName: 'Time',
      field: 'time',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        }
      ]
    },
    {
      tableHeadName: 'POI/AOI Name',
      field: 'poiAoiName',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [{
              title: 'Show items with value that:',
              filterByField: 'poiAoiName',
              input: {
                placeholder: 'Enter Value',
                name: 'value',
                dataType: 'string'
              }
            }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Entry | Exit',
      field: 'entryExit',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'entryExit',
                select: {
                  name: 'filterAndValue',
                  filterFn: 'equal',
                  options: [
                    {
                      option: 'Entry',
                      value: 'Entry'
                    },
                    {
                      option: 'Exit',
                      value: 'Exit'
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Geo-fence Alert',
      field: 'geoFenceAlert',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        }
      ]
    },
    {
      tableHeadName: 'Alert Name',
      field: 'alertName',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'alertName',
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'string'
                }
              }
            ]
          }
        }
      ]
    },
    {
      tableHeadName: 'Recipient(s)',
      field: 'recipient',
      tableDropOption: [
        {
          optionName: 'Sort Ascending',
          iconClass: 'asc',
          dataButton: 'sortAsc'
        },
        {
          optionName: 'Sort Descending',
          iconClass: 'desc',
          dataButton: 'sortDesc'
        },
        {
          optionName: 'Filter',
          iconClass: 'filter',
          subDrop: {
            dataLeftButtonValue: 'filter',
            dataRightButtonValue: 'clearFilter',
            leftButtonText: 'Filter',
            rightButtonText: 'Clear',
            subDropSection: [
              {
                title: 'Show items with value that:',
                filterByField: 'recipient',
                input: {
                  placeholder: 'Enter Value',
                  name: 'value',
                  dataType: 'string'
                }
              }
            ]
          }
        }
      ]
    }],
    tableRow: GeoMonitorList
  }
})

export default GeoMonitorTableGridModel
