import $ from 'jquery'
import ReportFormView from '../ReportForm'
import ReportFormModel from '../../../model/report/ReportForm'

const MileageReportFormView = ReportFormView.extend({
  model: ReportFormModel,
  generatePdfXlsReportURL: function () { // implementation
    var model = this.model
    var data = {
      'report-name': 'Mileage Report',
      'report-type': 'Mileage',
      'report-format': model.get('repFormat'),
      alternate: false,
      startPeriod: model.get('from').replace(/\.+/g, '/') + ' ' + model.get('fromTime'),
      endPeriod: model.get('to').replace(/\.+/g, '/') + ' ' + model.get('toTime'),
      vehicleIds: model.get('unitIdList').replace(/,+/g, '/')
    }
    return $.param(data)
  }
})

export default MileageReportFormView
