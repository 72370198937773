import Model from '../../../model/Model'

const LocationRecordModel = Model.extend({
  defaults: {
    id: null,
    lat: null,
    lon: null,
    recDate: null,
    streetName: '',
    postcode: '',
    directionCode: '',
    poiDescr: '',
    ignitionStr: '',
    speed: null,
    distance: null,
    vehicleReg: ''
  }
})

export default LocationRecordModel
