/**
 * Created by George on 07.04.2015.
 */
/**
 * Created by George on 26.03.2015.
 */
import ProfileBuilderPopupView from '../profileManager/ProfileBuilderPopup';
import OverspeedProfileBuilderFormView from './OverspeedProfileBuilderForm';

const OverspeedProfileBuilderPopup = ProfileBuilderPopupView.extend({
    ProfileBuilderFormView: OverspeedProfileBuilderFormView
});

export default OverspeedProfileBuilderPopup;
