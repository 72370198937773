import $ from 'jquery'
import FormView from '../../Form'
import NotificationsFormModel from '../../../model/report/vehicleDefectReport/NotificationsForm'

const NotificationsFormView = FormView.extend({
  model: NotificationsFormModel,
  elSelector: '[data-form=notifications]',
  mergeEvents: true,
  events: {
    'mouseover a:first': 'showHint',
    'mouseout a:first': 'hideHint'
  },
  initialize: function () {
    var view = this

    view.resetElement()
    view.model.set('status', window.userPrefs.vehicleDefectNotificationStatus)
    view.on('save', function (model) {
      window.userPrefs.vehicleDefectNotificationStatus = model.get('status')
      $.cookie('timestamp', Date.now())
    })
  },
  showHint: function (e) {
    var view = this
    var $email = $(e.currentTarget)
    var $tooltip = $email.parent().find('[data-tooltip]')

    $tooltip.find('a').text($email.attr('href').replace('mailto:', ''))
    $tooltip.css({
      top: $email.offset().top - view.$el.offset().top + 5,
      left: $email.offset().left - view.$el.offset().left - (($tooltip.width() - $email.width()) / 2)
    }).show()
  },
  hideHint: function (e) {
    var $email = $(e.currentTarget)

    $email.parent().find('[data-tooltip]').hide()
  },
  submit: function () { // override
    var view = this

    view.getParentView().hide()

    FormView.prototype.submit.apply(this, arguments)
  },
  destroy: function () {
    var view = this

    view.off('save')
    view.remove()
  }
})

export default NotificationsFormView
