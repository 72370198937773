import $ from 'jquery'
import ScrollView from '../Scroll'

const CompactReportTableGridScrollView = ScrollView.extend({
  calculateScrollContentSize: function () {
    if (!this.getParentView().getParentView().$el.hasClass('ooi-list-popup')) {
      this.$frame.height($(window).height() - this.$frame.offset().top)
    } else {
      this.$frame.height(this.$el.height())
    }
  },
  checkContentSize: function () {
    if (!this.getParentView().getParentView().$el.hasClass('ooi-list-popup')) {
      return this.$el.offset().top + this.$('.slidee').height() > $(window).height()
    } else {
      return this.$el.find('.slidee').height() > this.$el.height()
    }
  }
})

export default CompactReportTableGridScrollView
