import Model from '../../Model'

const AoiListModel = Model.extend({
  defaults: {
    id: null,
    categoryDto: '',
    description: '',
    accountDto: '',
    status: '',
    statusAction: ''
  }
})

export default AoiListModel
