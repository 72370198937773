import Collection from '../Collection'
import Driver from '../../model/editVehicle/Driver'

const DriverList = Collection.extend({
  model: Driver,
  url: function () {
    return 'api/selection-menu/action/edit-vehicle/drivers'
  }
})

export default DriverList
