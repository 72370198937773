import $ from 'jquery'
import CompactReportFormView from '../CompactReportForm'
import LeagueTableReportFormModel from '../../../model/report/leagueTable/LeagueTableReportForm'

const LeagueTableReportFormView = CompactReportFormView.extend({
  model: LeagueTableReportFormModel,
  assignReportDataId: function () {
    var view = this
    var dateSelect = view.getParentView().findSubView('selectPeriod').findSubView('dateList')
    var dateParse = dateSelect.json[0].val.split(' ')

    view.model.set('unit_id', $.cookie('lastViewedGroup'))
    view.model.set('month', dateParse[1])
    view.model.set('year', dateParse[0])
  },
  generatePdfXlsReportURL: function (reportFormat) { // implementation
    var model = this.model
    var data = {
      'report-name': 'LeagueTable',
      'report-type': 'LeagueTable',
      'report-format': reportFormat,
      month: model.get('month'),
      year: model.get('year'),
      nodeId: model.get('unit_id'),
      nodeType: 'uv'
    }
    return $.param(data)
  },
  submit: function () { // override
    var view = this
    // var compactReportTableGridView = view.getParentView().findSubView('compactReportTableGrid')

    // compactReportTableGridView.hideActivePopup()

    CompactReportFormView.prototype.submit.apply(this, arguments)
  }
})

export default LeagueTableReportFormView
