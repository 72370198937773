import $ from 'jquery'
import View from './View'
import Modal from '../model/Modal'

const ModalView = View.extend({
  model: Modal,
  url: '',
  events: {
    'click [data-button=close]': 'closeBtnHandler',
    'click [data-button=xls]': 'exportToXls',
    'click [data-button=pdf]': 'exportToPdf'
  },
  constructor: function (option) {
    var view = this
    var args = arguments

    view.model = new view.model()

    view
      .listenTo(view.model, 'change:visible', function (model, visible) {
        if (visible) {
          this.trigger('beforeShow')
          if (!this.model.get('lockAnimation')) {
            view.showAnimation()
          } else {
            view.hide({ silent: true })
          }
        } else {
          if (!this.model.get('lockAnimation')) {
            view.hideAnimation()
          } else {
            view.show({ silent: true })
          }
        }
      })
      .loadHTML(view.url || option.url, function () {
        View.apply(view, args)
      })
  },
  render: function () {
    $('#content').append(this.el)

    return this
  },
  isOutsideBottomBorder: function () {
    var view = this

    return view.$el.offset().top + view.$el.height() > $(window).height()
  },
  moveTo: function (top, left) {
    var cssOptions = {}

    if (top) {
      cssOptions.top = top
    }
    if (left) {
      cssOptions.left = left
    }
    this.$el.css(cssOptions)
  },
  align: function (popup) {
    if (!popup) {
      popup = this.$el
    }

    popup.find('.modal-layer-content').css('top', ($(window).height() / 2) - (popup.find('.modal-layer-content').outerHeight() / 2))
  },
  prepare: function (handler) {
    this.$el.css({ visibility: 'hidden', display: 'block' })
    if (handler) handler()
  },
  prepareDone: function () {
    this.$el.css({ visibility: 'visible', display: 'none' })
  },
  closeBtnHandler: function () {
    this.hide()
  },
  hideAnimation: function () {
    var view = this
    view.$el.fadeOut(300, function () {
      view.trigger('hideAnimationEnd')
    })
  },
  showAnimation: function () {
    var view = this
    view.$el.fadeIn(300, function () {
      view.trigger('showAnimationEnd')
    })
  },
  hide: function (option) {
    this.model.set('visible', false, option)
  },
  show: function (option) {
    this.model.set('visible', true, option)
  },
  exportToPdf: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('PDF'))
  },
  exportToXls: function () {
    window.open('/load-report?' + this.generatePdfXlsReportURL('XLS'))
  },
  loadHTML: function (url, callback) {
    var view = this

    $.post(url, function (msg) {
      view.$el = $(msg)
      view.el = view.$el[0]
      view.render()
      view.model.set('htmlReady', true)
      if (callback) callback()
    })

    return this
  },
  isVisible: function () {
    return this.model.get('visible')
  }
})

export default ModalView
