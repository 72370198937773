import ScrollView from '../../Scroll'

const DetailedVehicleDefectReportScrollView = ScrollView.extend({
  calculateScrollContentSize: function () {
    this.$frame.height(87)
  },
  checkContentSize: function () {
    return this.$('.slidee').height() > 87
  }
})

export default DetailedVehicleDefectReportScrollView
