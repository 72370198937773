import Model from './Model'

const TableGrid = Model.extend({
  defaults: {
    selectAllowed: true,
    multiSelect: false,
    hasScroll: true,
    reloadScrollOnWindowResize: false
  }
})

export default TableGrid
