import ReportPopupView from '../ReportPopup'
import DrivingTimeReportView from './DrivingTimeReport'

const DrivingTimeReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/DrivingTime.do',
  initialize: function () {
    console.log('initialize()')
    var view = this
    view.show()
    view.addSubView('modalReport', DrivingTimeReportView)
  },

  destroy: function () {
    this.findSubView('modalReport').destroy()
    this.remove()
  }
})

export default DrivingTimeReportPopupView
