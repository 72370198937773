import $ from 'jquery'
import JqueryUiWidgetView from '../../../JqueryUiWidget'
import ChecklistSelectModel from '../../../../model/report/vehicleDefectReport/ChecklistSelect'
import SelectScrollView from '../../../SelectScroll'

const ChecklistSelectView = JqueryUiWidgetView.extend({
  model: ChecklistSelectModel,
  mergeEvents: true,
  events: {
    selectmenuopen: 'selectmenuopenHandler',
    imgselectmenuchange: 'selectChange'
  },
  selectmenuopenHandler: function () {
    if (!this.hasSubView('scroll')) {
      this.renderScroll()
    }
  },
  selectChange: function (e, ui) {
    var view = this
    var parentView = view.getParentView()
    var checksList = parentView.checkList
    var idx = $(e.currentTarget).closest('tr')[0].rowIndex - 1

    checksList[idx].value = ui.item.value
  },
  renderScroll: function () {
    var view = this
    var $selectMenuDrop = view.$el.imgselectmenu().imgselectmenu('widget').next()
    var $scroll = $('<div class="scroll-frame">' +
      '<div class="scrollbar vertical">' +
      '<div class="handle">' +
      '<div class="mousearea"></div>' +
      '<div class="bg"></div>' +
      '</div>' +
      '</div>' +
      '<div class="scroll-content">' +
      '<div class="slidee"></div>' +
      '</div>' +
      '</div>')

    $selectMenuDrop.append($scroll)
    $scroll.find('.slidee').append($selectMenuDrop.find('ul'))
    view.addSubView('scroll', SelectScrollView, { el: $scroll }).render()
  },
  destroy: function () {
    this.remove()
  }
})

export default ChecklistSelectView
