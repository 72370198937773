import ProfileBuilderFormView from '../profileManager/ProfileBuilderForm'
import OutOfHoursProfileBuilderFormModel from '../../../model/alert/outOfHours/OutOfHoursProfileBuilderForm'
import OutOfHoursProfileBuilderTabsetView from "./OutOfHoursProfileBuilderTabset";
import _ from 'underscore'

const OutOfHoursProfileBuilderFormView = ProfileBuilderFormView.extend({
    model: OutOfHoursProfileBuilderFormModel,
    ProfileBuilderTabsetView: OutOfHoursProfileBuilderTabsetView,
    render: function(){ // override
        var $el = this.$el;
        var view = this;
        var json = view.model.toJSON();
        var timeRangeWeekMap = null

        if(view.findSubView('profileBuilderTabset').hasOwnProperty('timeRange')){
            timeRangeWeekMap = view.findSubView('profileBuilderTabset').timeRange.innerView.findSubView('timeRangeWeekMap');
        }

        _.mapObject(json,function(val,key) {
            var $formElement = $el.find("[name=" + key + "]");
            if($formElement.is("input:checkbox")){
                $formElement.prop("checked",val);
            }else if($formElement.is("input:radio")){
                $formElement.filter("[value=" + val + "]").prop("checked",true);
            }else if(key == 'weekMap' && timeRangeWeekMap){
                timeRangeWeekMap.resetWeekModel();
                timeRangeWeekMap.setToModel();
            } else {
                $formElement.val(val);
            }
        });
    },
});

export default OutOfHoursProfileBuilderFormView
