import $ from 'jquery'
import CompactUnitViewReportFormModel from '../../model/report/CompactUnitViewReportForm'
import CompactReportFormView from './CompactReportForm'

const CompactUnitViewReportFormView = CompactReportFormView.extend({
  model: CompactUnitViewReportFormModel,
  assignReportDataId: function () { // override
    var view = this

    view.model.set('unit_view_id', $.cookie('lastViewedGroup'))
  }
})

export default CompactUnitViewReportFormView
