import $ from 'jquery'
import View from './View'
import videojs from 'video.js'
import Sortable from 'sortablejs'
import ScrollView from '../view/Scroll'
import _ from 'underscore'

const LiveVideoPlayerView = View.extend({
  events: {
    'click .vp-stop-btn': 'onPauseClick',
    'click .vp-play-btn': 'onPlayClick',
    'click .vp-fullscreen-btn': 'toggleFullScreen',
    'click .mini-video': 'onClickMiniVideo'
  },
  constructor: function (option) {
    this.el = option.el
    this.$el = option.$el
    this.isPausedBeforeSeeking = false

    View.apply(this, arguments)
  },
  initialize: function () {
    this.playerHash = {}
    const $video = this.$el.find('video')
    const VideosScrollView = ScrollView.extend({
      calculateScrollContentSize: function () { } // override
    })

    this.addSubView('scroll', VideosScrollView)

    $video.on('play', $.proxy(this.onPlay, this))
    $video.on('pause', $.proxy(this.onPause, this))
    $video.on('ended', $.proxy(this.onVideoEnded, this))

    this.fullscreenchangeListener = $.proxy(this.onEscPress, this)

    document.addEventListener('fullscreenchange', this.fullscreenchangeListener)
    document.addEventListener('webkitfullscreenchange', this.fullscreenchangeListener)
    document.addEventListener('mozfullscreenchange', this.fullscreenchangeListener)
    document.addEventListener('MSFullscreenChange', this.fullscreenchangeListener)
  },
  initPlayer: function (channelNumber) {
    this.playerHash[channelNumber] = {
      videojs: videojs(`video${channelNumber}`, {
        autoplay: true,
        muted: true,
        controls: false
      }),
      liveVideoUrl: null
    }
  },
  toggleFullScreen: function () {
    var HTML = this.el
    if (!document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) { // current working methods
      if (HTML.requestFullscreen) {
        HTML.requestFullscreen()
      } else if (HTML.msRequestFullscreen) {
        HTML.msRequestFullscreen()
      } else if (HTML.mozRequestFullScreen) {
        HTML.mozRequestFullScreen()
      } else if (HTML.webkitRequestFullscreen) {
        HTML.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
      this.$el.addClass('fullscreen-active')
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
      this.$el.removeClass('fullscreen-active')
    }
  },
  onEscPress: function () {
    if (!this.isFullscreen()) {
      this.$el.removeClass('fullscreen-active')
    }
  },
  isFullscreen: function () {
    return document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement
  },
  formatSecondsToMS: function (duration) {
    var seconds = duration % 60
    var minutes = duration / 60
    seconds = Math.floor(seconds)
    minutes = Math.floor(minutes)
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return minutes + ':' + seconds
  },
  loadedMetadata: function () {
    var view = this
    var $video = this.$el.find('video')
    var video = $video[0]
    var videoDuration = video.duration

    this.$el.find('.vp-time-tracker span:last').text(this.formatSecondsToMS(videoDuration))
  },
  play: function () {
    this.player && this.player.play()
  },
  pause: function () {
    this.player && this.player.pause()
  },
  onPlay: function () {
    var video = this.$el.find('video')[0]

    if (video.ended) {
      video.currentTime = 0
    }
    this.$el.find('.vp-play-btn').addClass('vp-stop-btn').removeClass('vp-play-btn')
    this.play()
  },
  onPause: function () {
    // if(this.isPausedBeforeSeeking === false) return
    this.$el.find('.vp-stop-btn').addClass('vp-play-btn').removeClass('vp-stop-btn')
  },
  onPlayClick: function () {
    this.play()
  },
  onPauseClick: function () {
    this.pause()
  },
  onVideoEnded: function () {
    // this.src(null)
    // this.$el.find('.vp-stop-btn').addClass('vp-play-btn').removeClass('vp-stop-btn')
  },
  startLiveVideo: function (channelNumber) {
    var unitId = this.props.unitId

    return $.get('/api/video/live-stream-start?channelNumber=' + channelNumber + '&unitId=' + unitId)
  },
  stopLiveVideo: function (channelNumber) {
    var unitId = this.props.unitId

    return $.get('/api/video/live-stream-stop?channelNumber=' + channelNumber + '&unitId=' + unitId)
  },
  setProp: function (key, value) {
    if (key === 'src') {
      this.player.src(value)
    } else {
      this.$el.find('video').prop(key, value)
    }
  },
  setProps: function (props) {
    var view = this
    this.props = props
    const channels = props.channels
    const firstChannel = channels[0]
    const channelNumber = firstChannel.channelNumber

    this.setSelectedChannelNumber(channelNumber)

    view.renderVideo(firstChannel.snapshotUrl)
    view.renderMessage()

    if (channels.length > 1) {
      this.renderMiniVideos()
    } else {
      this.hideSideVideos()
    }

    if (channels.length > 2) {
      this.renderScroll()
      new Sortable(view.$el.find('#sortableLive')[0], {
        animation: 150,
        onEnd: function () {
          view.updateSideVideosOrder()
        }
      })
    }

    this.setChannelName(firstChannel.name)
    this.$el.find('.video-player video').attr('id', `video${channelNumber}`)
    this.startLiveVideo(channelNumber).then(function (liveVideoUrl) {
      // liveVideoUrl = 'http://test.html5.e-v-t.co.uk/hls/test.m3u8'
      view.hideMessage()

      if (!liveVideoUrl) return

      // if (view.player) {
      //     view.player.remove()
      // }
      // view.initPlayer(liveVideoUrl)

      if (!view.player) {
        view.initPlayer(channelNumber)
      }
      view.src(liveVideoUrl, channelNumber)
    })
  },
  onClickMiniVideo (e) {
    const $miniVideo = $(e.target).closest('.mini-video')
    const channelNumber = $miniVideo.data('channelnumber')

    if ($miniVideo.hasClass('loading-stream')) {
      return
    }

    if ($miniVideo.hasClass('active')) {
      this.selectChannel(channelNumber, $miniVideo)
    } else {
      const $loader = $miniVideo.find('.video-player-message')

      $loader.show()
      $miniVideo.addClass('loading-stream')
      this.startMiniStream(channelNumber).then(() => {
        $loader.hide()
        $miniVideo.removeClass('loading-stream')
      })
      $miniVideo.addClass('active').find('.play-button-container').hide()
    }
  },
  startMiniStream (channelNumber) {
    if (!this.player) {
      this.initPlayer(channelNumber)
    }
    return this.startLiveVideo(channelNumber).then( liveVideoUrl => {
      this.src(liveVideoUrl, channelNumber)
    })
  },
  selectChannel (channelNumber, $miniPlayer) {
    const $mainPlayer = this.$el.find('.video-player')
    // const $mainVideo = $mainPlayer.find('video')
    // const $selectedVideo = $miniPlayer.find('video')
    const $mainPlayerChannelName = $mainPlayer.parent().find('.channel-name')
    const $miniPlayerChannelName = $miniPlayer.find('.channel-name')
    const mainPlayerChannelNameText = $mainPlayerChannelName.text()
    const miniPlayerChannelNameText = $miniPlayerChannelName.text()
    const mainVideoSrc = this.playerHash[this.getSelectedChannelNumber()].liveVideoUrl
    const selectedVideoSrc = this.playerHash[channelNumber].liveVideoUrl

    // $miniPlayer.data('channelnumber', $mainVideo.attr('id').replace('video', ''))
    // $mainPlayer.append($selectedVideo)
    // $miniPlayer.append($mainVideo)

    this.src(mainVideoSrc, channelNumber)
    this.src(selectedVideoSrc, this.getSelectedChannelNumber())

    $mainPlayerChannelName.text(miniPlayerChannelNameText)
    $miniPlayerChannelName.text(mainPlayerChannelNameText)
  },
  hideSideVideos: function () {
    this.$el.find('.side-video-section').hide()
  },
  showSideVideos: function () {
    if (this.props.channels.length > 1) {
      this.$el.find('.side-video-section').show()
    }
  },
  setSelectedChannelNumber: function (channelNumber) {
    this.selectedChannelNumber = channelNumber
  },
  setChannelName: function (name) {
    this.$el.find('.player-control .channel-name').text(name)
  },
  getSelectedChannelNumber: function () {
    return this.selectedChannelNumber
  },
  updateSideVideosOrder: function () {
    var channels = this.props.channels
    var $miniVideos = this.$el.find('.mini-video')
    _.forEach($miniVideos, function (sideVideo, index) {
      var channelNumber = parseInt($(sideVideo).attr('data-channelnumber'))
      var currentChannel = _.find(channels, function (channel) {
        return channelNumber === channel.channelNumber
      })

      currentChannel.order = index
    })
  },
  calcMiniVideosHeight: function () {
    var aspectRatioHeight = 1080 / 1920
    var outerWidth = window.outerWidth
    var popupWidth = outerWidth * 0.7
    var listWidth = popupWidth * 0.26
    var height = listWidth * aspectRatioHeight

    if (this.isFullscreen()) {
      height = outerWidth * 0.26 * aspectRatioHeight
    }

    this.$el.find('.mini-video').css({ height: height.toFixed(2) + 'px' })
  },
  renderScroll: function () {
    this.findSubView('scroll').render()
  },
  renderMiniVideos: function () {
    var view = this
    var channels = view.props.channels
    var $content = view.$el.find('.side-video-section .slidee #sortableLive')
    var videos = ''
    var hasOrder = _.some(channels, function (channel) {
      return channel.order
    })

    if (hasOrder) {
      channels = _.sortBy(channels, function (item) {
        return item.order
      })
    }

    _.forEach(channels, function (channel, index) {
      if (view.getSelectedChannelNumber() === channel.channelNumber) return

      videos += (`
        <div class="mini-video ui-state-default ui-sortable-handle" data-channelnumber="${channel.channelNumber}">
            <video id="video${channel.channelNumber}" poster="${channel.snapshotUrl}" style="width: 100%; height: 100%;" />
            <div class="video-player-message" style="display: none">
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <span>Stream is starting please wait</span>
            </div>
            <div class="play-button-container">
                <div class="play-icon" />
                <span>Press To Go Live</span>
            </div>
            <span class="channel-name">${channel.name}</span>
        </div>
      `)
    })
    $content.html(videos)
    this.$sideVideos = this.$el.find('.mini-video video')

    this.calcMiniVideosHeight()
  },
  src: function (liveVideoUrl, channelNumber) {
    if (this.playerHash && this.playerHash[channelNumber]) {
       this.playerHash[channelNumber].liveVideoUrl = liveVideoUrl
       this.playerHash[channelNumber].videojs.src(liveVideoUrl)
    }
  },
  renderVideo: function (posterUrl) {
    var videoEl = `<video id="live-video" poster="${posterUrl}" style="width: 100%; height: 100%"></video>`

    this.$el.find('.video-player').html(videoEl)
  },
  renderMessage: function () {
    var $messageEl = this.$el.find('.main-video-section .video-player-message')

    $messageEl.show()
  },
  hideMessage: function () {
    this.$el.find('.video-player-message').hide()
  },
  reset: function () {
    this.onPause()
  },
  dispose: function () {
    Object.keys(this.playerHash).forEach(channelNumber => {
      this.stopLiveVideo(channelNumber)
      this.playerHash[channelNumber].videojs.dispose()
    })
    this.playerHash = {}
  },
  destroy: function () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    document.removeEventListener('fullscreenchange', this.fullscreenchangeListener)
    document.removeEventListener('webkitfullscreenchange', this.fullscreenchangeListener)
    document.removeEventListener('mozfullscreenchange', this.fullscreenchangeListener)
    document.removeEventListener('MSFullscreenChange', this.fullscreenchangeListener)
    this.remove()
  }
})

export default LiveVideoPlayerView
