import $ from 'jquery'
import SnailTrailPopupView from '../SnailTrailPopup'
import TodaysJourneySnailTrailTableGridView from './TodaysJourneySnailTrailTableGrid'
import SnailTrailStepPlayView from '../SnailTrailStepPlay'

const TodaysJourneySnailTrailPopupView = SnailTrailPopupView.extend({
  url: '/getJourneyDetailsPopup.do',
  initialize: function () {
    this.addSubView('SnailTrailStepPlayer', SnailTrailStepPlayView, { el: this.$('[data-player=stepPlay]') })
    this.addSubView('snailTrailTableGrid', TodaysJourneySnailTrailTableGridView)
  },
  show: function () { // override
    $('[data-popup="journeyDetails"]').addClass('journey-report')
    $('#content').prepend($('[data-popup="journeyDetails"]'))

    SnailTrailPopupView.prototype.show.apply(this, arguments)
  },
  closeBtnHandler: function () { // override
    var view = this
    var tableGridView = view.findSubView('snailTrailTableGrid')

    view.hide()

    if (view.$el.find('.max-min_btn').hasClass('active')) {
      view.resetWindowSize()
    }

    view.getParentView().resetSelectedJourneyAndCheckbox()

    if ($('.report .slide-button').hasClass('active')) {
      $('.report .slide-button').click()
    }
    tableGridView.removeSnailTrailFromMap()
  },
  addNewPoi: function () {
    var $HTML = $('[data-popup="journeyDetails"]')
    var $report = $('[data-report="todays-journeys"]')

    $HTML.hide()
    $report.hide()

    this.outFromPOI()

    window.AddPOI.goToPOIAOI('fromJourney')
  }
})

export default TodaysJourneySnailTrailPopupView
