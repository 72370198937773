import ReportModel from '../Report'

const TimeOnSiteReportModel = ReportModel.extend({
  urlRoot: 'api/report/time-on-site',
  defaults: {
    vehicle: null,
    journeyCount: 0,
    recordList: null,
    day: null,
    averageTimeOnSite: '',
    averageTimeOnSiteInSec: 0,
    totalInstancesOfTimeOnSite: 0
  }
})

export default TimeOnSiteReportModel
