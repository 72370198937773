import Model from '../../model/Model'

const AlertPoiModel = Model.extend({
  defaults: {
    id: 0,
    description: '',
    entry: false,
    exit: false
  }
})

export default AlertPoiModel
