import $ from 'jquery'
import ModalView from '../../../Modal'
import VehicleDefectVehicleListModalTableGridView from './VehicleListTableGrid'
import VehicleAssignSelectView from './VehicleAssignSelect'

const VehicleListModalView = ModalView.extend({
  url: 'js/html/app/src/template/report/vehicleDefectReport/checklist/vehicleDefectVehicleListModal.html',
  mergeEvents: true,
  collection: null,
  idVehicleList: [],
  vehicleListActive: false,
  events: {
    'click [data-button=assignChecklist]': 'assignChecklist',
    'click [data-button=checkItem]': 'checkItem',
    'click [data-button=checkAll]': 'checkAll',
    'click [data-button=clearFilters]': 'clearFiltersButtonHandler',
    'click [data-button=checklists]': 'showCheckLists',
    'click [data-button=newChecklist]': 'showAddChecklist'
  },
  initialize: function () {
    var view = this

    view.idVehicleList = []

    view.addSubView('compactReportTableGrid', VehicleDefectVehicleListModalTableGridView, this.$('[data-table-grid=vehicle]'))
    view.addSubView('vehicleAssignChecklist', VehicleAssignSelectView, this.$('[data-assign=assignList]'))
  },
  getList: function () {
    var view = this
    var tableGrid = view.findSubView('compactReportTableGrid')

    tableGrid.model.set('tableRow', [])

    $.get('/api/report/vehicleDefectReport/vehicles').done(function (json) {
      view.collection = view.updateChecklistName(json)
      tableGrid.model.set('tableRow', view.collection)
      if (!view.vehicleListActive) {
        view.clearFiltersButtonHandler()
      }
    })
  },
  updateChecklistName: function (json) {
    for (var i = 0; i < json.length; i++) {
      if (json[i].checklistName == null) {
        json[i].checklistName = ''
      }
    }

    return json
  },
  setChecklistName: function (ids, name) {
    var view = this
    var collection = view.collection

    for (var i = 0; i < ids.length; i++) {
      for (var j = 0; j < collection.length; j++) {
        if (collection[j].id === ids[i]) {
          collection[j].checklistName = name
        }
      }
    }

    return collection
  },

  checkItem: function (e) {
    var _check = $(e.target)
    var _id = parseInt(_check.closest('tr').attr('data-id'))
    var isCheckedAll = _check.closest('.info-holder').find('thead .with-checkbox input')
    var checked = _check.siblings('input').prop('checked')

    if ($(isCheckedAll).prop('checked')) {
      $(isCheckedAll).prop('checked', !$(isCheckedAll).prop('checked'))
    }

    if (!checked) {
      this.addVehicle(_id)
    } else {
      this.removeVehicle(_id)
    }
  },
  addVehicle: function (id) {
    this.idVehicleList.push(id)
  },
  removeVehicle: function (id) {
    this.idVehicleList.splice(this.idVehicleList.indexOf(id), 1)
  },
  checkAll: function (e) {
    var view = this
    var _tr = view.$el.find('tbody tr')
    var checked = $(e.target).siblings('input').prop('checked')

    if (view.idVehicleList.length > 0) {
      view.idVehicleList = []
    }

    if (!checked) {
      view.addVehicleList(_tr, checked)
    } else {
      view.resetVehicleList(_tr, checked)
    }
  },
  addVehicleList: function (el, checked) {
    var list = this.idVehicleList

    for (var i = 0; i < el.length; i++) {
      if ($(el[i]).attr('data-id')) {
        $(el[i]).find('input').prop('checked', !checked)
        list.push(parseInt($(el[i]).attr('data-id')))
      }
    }
  },
  resetVehicleList: function (el, checked) {
    var view = this

    for (var i = 0; i < el.length; i++) {
      if ($(el[i]).attr('data-id')) {
        $(el[i]).find('input').prop('checked', !checked)
      }
    }

    view.idVehicleList = []
  },
  showAddChecklist: function (e) {
    var view = this
    var checklistModal = view.getParentView().findSubView('checklistModal')

    view.vehicleListActive = true
    view.hide()

    checklistModal.loadDefaultList()
    checklistModal.show()
  },
  showCheckLists: function () {
    var view = this
    var checklistsModal = view.getParentView().findSubView('checklistsModal')

    view.vehicleListActive = true
    view.hide()

    checklistsModal.getList()
    checklistsModal.show()
  },
  assignChecklist: function (e) {
    var view = this
    var assignSelectView = view.findSubView('vehicleAssignChecklist')

    if (view.idVehicleList.length > 0) {
      assignSelectView.getList()
    } else {
      assignSelectView.renderTemplate()
    }

    assignSelectView.show(e.currentTarget.offsetHeight, e.currentTarget.offsetLeft)
  },
  clearFiltersButtonHandler: function () {
    var view = this
    var tableGridView = view.findSubView('compactReportTableGrid')
    var tableDropView = tableGridView.findSubView('tableDrop')

    tableDropView.clearAllFilterData()
    tableGridView.hideAllFilterIcons()
    tableGridView.render()
  },
  generatePdfXlsReportURL: function (reportFormat) {
    var data = {
      'report-name': 'VehicleDefectVehicleList',
      'report-type': 'VehicleDefectVehicleList',
      'report-format': reportFormat,
      accountId: $.cookie('lastViewedAccount')
    }

    return $.param(data)
  },
  resetOptions: function () {
    var view = this

    view.idVehicleList = []
    view.vehicleListActive = false
  },
  closeBtnHandler: function () {
    var view = this

    view.resetOptions()
    view.hide()
  },
  destroy: function () {
    var view = this

    view.remove()
  }
})

export default VehicleListModalView
