import $ from 'jquery'
import TableGridView from '../TableGrid@'
import AlertTableGridModel from '../../model/alert/AlertTableGrid'
import NotificationPopupView from '../NotificationPopup'
import AlertTableGridScrollView from './AlertTableGridScroll'
import AlertTableDropView from './AlertTableDrop'

const AlertTableGridView = TableGridView.extend({
  templateUrl: 'template/alert/alertListTableGrid.html',
  model: AlertTableGridModel,
  events: {
    'click [data-button=activateDeactivate]': 'activateDeactivate',
    'click [data-button=delete]:not([data-show=false])': 'deleteAlert',
    'click [data-button=edit]': 'edit'
  },
  constructor: function (option) {
    var view = this

    view.setSubView({
      tableDrop: AlertTableDropView,
      scroll: AlertTableGridScrollView
    })

    TableGridView.apply(view, arguments)
  },
  getRelativeY: function ($th, pageY) { // override
    return pageY - this.$el.offset().top + $th.position().top
  },
  initialize: function (option) {
    var view = this
    var alertPanelView = view.getParentView().getParentView()

    view.model.set('tableRow', [])

    view
      .listenTo(view.model.get('tableRow'), 'change:active', function (alertModel) {
        alertModel.save(alertModel.toJSON(), {
          validate: false,
          success: function () {
            view.render()
          }
        })
      })
      .listenTo(view.model.get('tableRow'), 'destroy', function (alertModel, alertCollection, _option) {
        _option.xhr.done(function () {
          view.$el.find('[data-id=' + alertModel.get('id') + ']').remove()
        })
      })
    view.render()
  },
  deleteAlert: function (e) {
    var view = this
    var $tr = this.$(e.currentTarget).closest('tr')
    var alertModel = this.model.get('tableRow').get($tr.attr('data-id'))
    NotificationPopupView.render({
      title: 'Confirm Action',
      message: 'Are you sure you want to Delete this Alert?',
      button: ['Cancel', 'Confirm']
    })
    NotificationPopupView.once('answer', function (actionName) {
      if (actionName === 'ok') {
        if (view.hasSelectedItem()) {
          view.getParentView().getParentView().findSubView('alertForm').cancel()
        }
        alertModel.destroy()
        view.findSubView('scroll').render()
      }
    })
  },
  activateDeactivate: function (e) {
    var $tr = this.$(e.currentTarget).closest('tr')
    var alertModel = this.model.get('tableRow').get($tr.attr('data-id'))
    alertModel.set('active', !alertModel.get('active'))
  },
  edit: function (e) {
    var $tr = $(e.currentTarget).closest('tr')
    e.currentTarget = $tr[0]

    if ($tr.hasClass('active')) {
      this.templateOptions['activeRowId'] = null
    } else {
      this.templateOptions['activeRowId'] = $(e.currentTarget).attr('data-id')
    }
    this.setActiveRow(e)
  },
  fetchAlertlist: function (success, noRender) {
    var view = this
    var options = {
      success: success,
      requestType: 'fetch'
    }

    // if (noRender) {
    //   renderScroll = options.requestType = null
    // }

    this.model.get('tableRow').fetch(options).done(function () {
      view.render()
      view.findSubView('scroll').render()
    })
  },
  resetSelectedItemAndRow: function () {
    this.templateOptions['activeRowId'] = null
    TableGridView.prototype.resetSelectedItemAndRow.apply(this, arguments)
    this.getParentView().getParentView().model.set('alertId', null)
  },
  destroy: function () {
    this.findSubView('scroll').destroy()
    this.remove()
  }
})

export default AlertTableGridView
