import AlertPanelView from '../AlertPanel';
import DriverBehaviorAlertListPopupView from './DriverBehaviorAlertListPopup';
import DriverBehaviorAlertFormView from './DriverBehaviorAlertForm';
import DriverBehaviorProfileManagerPopupView from './DriverBehaviorProfileManagerPopup';

const DriverBehaviorAlert = AlertPanelView.extend({
    constructor: function (option) {
        this.setSubView({});
        this.AlertListPopupView = DriverBehaviorAlertListPopupView;
        this.ProfileManagerPopupView = DriverBehaviorProfileManagerPopupView;
        this.tabsetName = "driverBehaviorAlert";
        if (option.el) {
            this.$el = option.el;
            this.el = option.el[0];
        } else {
            this.$el = $("[data-tab=driverBehaviourAlert]");
            this.el = this.$el[0];
        }
        this.addSubView("alertForm", DriverBehaviorAlertFormView);
        AlertPanelView.apply(this, arguments);
    },
    initialize: function () {
        const view = this;

        view.alertListPopupView.on("ready", function () {
            this.alertListTableGridView.on("itemSelect", function ($tr, id) {
                const activeTabName = view.tabsetView.model.get("activeTab");
                if (view.model.get("dataMode") === "edit") {
                    if (activeTabName === "selectRecipient") {
                        if (view.tabsetView.selectProfile) {
                            view.tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.set(this.selectedItem[id].get("selectedAlertProfileList"));
                        }
                    } else {
                        if (view.tabsetView.selectRecipient) {
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").collection.set(this.selectedItem[id].get("selectedAlertRecipientList"));
                        }
                    }
                } else {
                    if (activeTabName === "selectRecipient") {
                        if (view.tabsetView.selectProfile) {
                            view.tabsetView.selectProfile.innerView.findSubView("availableSelectedTableGrid").availableTableGridCollection.set([]);
                        }
                    } else {
                        if (view.tabsetView.selectRecipient) {
                            view.tabsetView.selectRecipient.innerView.findSubView("selectRecipientTableGrid").collection.set([]);
                        }
                    }
                }
            });
        });
    }
});

export default DriverBehaviorAlert;
