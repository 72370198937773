import $ from 'jquery'
import View from './View'
import CommentList from '../collection/CommentList'

const CommentListView = View.extend({
  templateUrl: 'template/commentList.html',
  collection: CommentList,
  elSelector: '[data-list]',
  constructor: function () {
    var view = this
    var args = arguments

    view.collection = new view.collection()
    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, args)
    })
  },
  render: function () {
    var view = this

    if (!document.body.contains(view.el)) {
      view.getParentView().$el.append(view.dotTemplateToHtml(view.collection.toJSON()))
    } else {
      view.$el.html($(view.dotTemplateToHtml(view.collection.toJSON())).html())
    }
    view.findSubView('scroll').render()
  }
})

export default CommentListView
