/**
 * Created by George on 07.04.2015.
 */
/**
 * Created by George on 26.03.2015.
 */
import ProfileManagerPopupView from '../profileManager/ProfileManagerPopup';
import OverspeedProfileManagerTableGridView from './OverspeedProfileManagerTableGrid';
import OverspeedProfileBuilderPopupView from './OverspeedProfileBuilderPopup';

const OverspeedProfileManagerPopup = ProfileManagerPopupView.extend({
    title: 'Overspeed Parameters Profiles',
    ProfileManagerTableGrid: OverspeedProfileManagerTableGridView,
    ProfileManagerBuilderPopupView: OverspeedProfileBuilderPopupView
});

export default OverspeedProfileManagerPopup;
