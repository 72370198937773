import Model from '../../Model'

const PoiListModel = Model.extend({
  defaults: {
    id: null,
    categoryDto: '',
    description: '',
    lat: null,
    lon: null,
    radius: null,
    accountDto: '',
    status: '',
    statusAction: '',
  }
})

export default PoiListModel
