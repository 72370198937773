import Collection from '../Collection'
import SendCommandModel from "../../model/editVehicle/SendCommandModel";

const CommandList = Collection.extend({
    model: SendCommandModel,
    url: function () {
        return 'api/selection-menu/action/send-command/commands'
    }
})

export default CommandList
