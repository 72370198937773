import View from '../view/View'
import CheckboxScrollView from "./CheckboxScroll";
import $ from 'jquery'

const CheckboxListView = View.extend({
    events: {
        'click .arrow-ico': 'toggleList'
    },
    constructor: function () {
        var view = this;
        var args = arguments;
        view.model = new this.model();

        view.loadDotTemplate(function () {
            view.compileTemplate();
            view.fetchList({
                success: function () {
                    view.fetchListSuccess();
                    view.el = view.$el[0];
                    View.apply(view, args);
                }
            });
        });
    },
    fetchListSuccess: function () {
        this.$el = $(this.dotTemplateToHtml(this.model.toJSON()));
        this.getParentView().$el.find('.slidee').html(this.$el);
    },
    fetchList: function (options) {
        this.model.fetch(options)
    },
    initialize: function(){
        this.addSubView('scroll', CheckboxScrollView, {
            el: this.$('.scroll-frame')[0]
        });
        this.render();
    },
    render: function () {
        this.$('.checkbox-list:first').tristate();
    },
    toggleList: function (e) {
        var view = this;
        var $arrow = $(e.currentTarget);
        $arrow.parent().toggleClass('active');
        $arrow.siblings('ul').slideToggle(400, function () {
            view.findSubView('scroll').render();
        });
    },
    groupChecked: function ($checkboxlist) {
        var groupChecked = true;
        $checkboxlist.each(function (i,item) {
            if (!item.checked) {
                groupChecked = false;
                return false; //break loop
            }
        });
        return groupChecked
    }
});

export default CheckboxListView
