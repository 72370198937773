import $ from 'jquery'
import CompactReportView from '../../report/CompactReport'
import AoiListTableGridView from './AoiListTableGrid'

const AoiListView = CompactReportView.extend({
  templateUrl: 'template/alert/geoZones/aoiList.html',
  initialize: function () {
    var view = this

    view.addSubView('compactReportTableGrid', AoiListTableGridView, this.$('[data-table-grid=aoiList]'))
    view.loadList()
  },
  loadList: function () {
    var view = this
    var tableGrid = view.findSubView('compactReportTableGrid')

    $.post('/getAllAoisForCurrentUser.do', { active: true }).done(function (json) {
      var data = tableGrid.updatePoiList(json.data)

      tableGrid.model.set('tableRow', data)
      tableGrid.render()
      view.show()
    })
  },
  show: function () {
    this.$el.css({ visibility: 'visible', display: 'none', left: $('.tabset-report-panel').width() }).fadeIn(400, function () {
      $('.map-wrap').removeClass('active').css('margin-left', 0)
      $('.left-panel').removeClass('active')
    })
  },
  render: function () {
    var view = this

    view.getParentView().$el.append(view.$el)
    return view
  },
  destroy: function () {
    var view = this

    view.findSubView('compactReportTableGrid').destroy()
    view.remove()
  }
})

export default AoiListView
