import $ from 'jquery'
import View from '../../View'
import TimeOnSiteReportModel from '../../../model/report/timeOnSite/TimeOnSite'
import TimeOnSiteDetailsPopupView from './TimeOnSiteDetailsPopup'
import ReportView from '../Report'
import TimeOnSiteScrollView from './TimeOnSiteScroll'

const TimeOnSiteView = ReportView.extend({
  model: TimeOnSiteReportModel,
  templateUrl: 'template/report/timeOnSite/timeOnSiteReport.html',
  mergeEvents: true,
  events: {
    'mousedown th': 'showDropOld',
    'click .table-drop li': 'sort',
    'click .drop-poi-btn': 'addNewPoi',
    'contextmenu tbody tr>td.poi-address': 'goToPOI',
    'click table': 'outFromPOI'
  },
  initialize: function (option) {
    var view = this

    view.addSubView('snailTrailPopup', TimeOnSiteDetailsPopupView)
    view.addSubView('scroll', TimeOnSiteScrollView)
  },
  selectAll: function ($tr) {
    console.log('selectAll: overriden')
    var view = this

    if ($tr.size() === 1) {
      $tr.addClass('active').siblings().removeClass('active')
      view.$('input:checkbox:checked').prop('checked', false)
    } else {
      $tr.toggleClass('active')
    }
    view.resetSelectedJourneyAndCheckbox($tr)
    if (view.$('tr.active').size()) {
      $('.report .slide-button').click()
      view.findSubView('snailTrailPopup').showSnailTrail($tr)
    } else {
      view.findSubView('snailTrailPopup').hide()
    }
  },
  isModelEmpty: function (model) {
    return model.get('timeOnSiteSections') && !model.get('timeOnSiteSections').length
  },
  getPOIcoordinates: function (el) {
    var view = this
    var latLon = view.$('[data-journey-id=' + el + '] [data-latlon]').text()
    var lat = latLon.substr(5, latLon.lastIndexOf('lon:') - 7)
    var lon = latLon.substr(21, latLon.length)

    if (lat === 0 || lon === 0) {
      window.AddPOI.lat = 51.507351
      window.AddPOI.lon = -0.127758
    } else {
      window.AddPOI.lat = lat
      window.AddPOI.lon = lon
    }

    view.hideSnailTrailPopup()

    window.AddPOI.goToPOIAOI('fromTimeOnSiteReport')
  }
})

export default TimeOnSiteView
