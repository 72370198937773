/**
 ** Created by Paul Zelenov
 **/
import TableGrid from "../../TableGrid@";
import RecipientList from "../../../collection/alert/RecipientList";

const UnpluggedDeviceAlertSelectRecipientTableGrid = TableGrid.extend({
    defaults: {
        selectAllowed: false,
        tableHead: [
            {
                tableHeadName: "First Name",
                field: "firstName",
                tableDropOption: [
                    // ... (options for sorting and filtering)
                ]
            },
            {
                tableHeadName: "Last Name",
                field: "lastName",
                tableDropOption: [
                    // ... (options for sorting and filtering)
                ]
            },
            {
                tableHeadName: "Email Address",
                field: "email",
                tableDropOption: [
                    // ... (options for sorting and filtering)
                ]
            },
            {
                tableHeadName: "EMAIL",
                field: "sendByEmail",
                noDrop: true
            },
            {
                tableHeadName: "Delete",
                noDrop: true
            },
            {
                tableHeadName: "Edit",
                noDrop: true
            }
        ],
        tableRow: RecipientList
    }
});

export default UnpluggedDeviceAlertSelectRecipientTableGrid;
