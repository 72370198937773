/**
 * Created by George on 30.07.2017.
 */
import CheckboxListView from '../../CheckboxList';
import OverspeedSelectedVehiclesModel from '../../../model/alert/overspeed/OverspeedSelectedVehicles';

const OverspeedSelectedVehicles = CheckboxListView.extend({
    templateUrl: 'template/alert/overspeed/overspeedCheckboxList.html',
    model: OverspeedSelectedVehiclesModel
});

export default OverspeedSelectedVehicles;
