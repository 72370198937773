import Model from '../../Model'

const TodaysJourneyFormModel = Model.extend({
  defaults: {
    repFormat: 'HTML',
    from: null,
    fromTime: '00:00',
    to: null,
    toTime: '23:59'
  },
  url: 'getJourneyReport.do'
})

export default TodaysJourneyFormModel
