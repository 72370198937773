import View from '../../View'
import ReportPopupView from '../ReportPopup'
import BusinessPrivateReportFormView from './BusinessPrivateReportForm'

const BusinessPrivateReportPopupView = ReportPopupView.extend({
  url: '/getReportWindow/BusinessPrivate.do',
  initialize: function () {
    this.show()
    this.addSubView('reportForm', BusinessPrivateReportFormView)
  }
})

export default BusinessPrivateReportPopupView
