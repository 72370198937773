import $ from 'jquery'
import _ from 'underscore'
import View from '../../../View'
import VehicleAssignScrollView from './VehicleAssignScrollView'

const VehicleAssignSelectView = View.extend({
  templateUrl: 'template/report/vehicleDefectReport/checklist/vehicleAssignSelect.html',
  collection: null,
  elSelector: '[data-assign=assignList]',
  events: {
    'click li[data-id]': 'assignList'
  },
  constructor: function () {
    var view = this
    view.$el = view.getParentView().$el.find('.assign-checklist')
    view.el = view.$el

    view.setSubView({ scroll: VehicleAssignScrollView })

    view.loadDotTemplate(function () {
      view.compileTemplate()
      View.apply(view, arguments)
    })
    // View.apply(view,arguments)
  },
  initialize: function () {
    var view = this

    $(document).on('click.vehicleAssignChecklist', function (e) {
      if (!$(e.target).closest('.assign-btn').size() && !$(e.target).closest('[data-assign]').size()) {
        view.hide()
      }
    })
  },
  renderTemplate: function (json) {
    var view = this
    var html

    if (!json) {
      html = '<div class="warning-assign">Select Vehicle(s) to see Checklists</div>'
    } else {
      html = view.dotTemplateToHtml(json)
    }
    view.$el.html(html)
  },
  assignList: function (e) {
    var view = this
    var id = $(e.currentTarget).attr('data-id')
    var checklistName = (id !== 0 ? $(e.currentTarget).text() : '')
    var collection = view.getParentView().idVehicleList

    view.setChecklist(id, collection, checklistName)
  },
  setChecklist: function (id, collection, checklistName) {
    var view = this
    var vehicleListModal = view.getParentView()
    var tableGrid = vehicleListModal.findSubView('compactReportTableGrid')
    var data = collection
    var url = '/api/report/vehicleDefectReport/checklist/' + parseInt(id) + '/assign'

    view.hide()

    tableGrid.model.set('tableRow', [])

    $.ajax({
      type: 'POST',
      url: url,
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8'
    }).done(function (json) {
      vehicleListModal.collection = vehicleListModal.setChecklistName(collection, checklistName)
      if (id !== 0) {
        window.systemAlert.initMsg('Checklist was successfully assign')
      } else {
        window.systemAlert.initMsg('Checklist(s) was successfully unassigned')
      }

      tableGrid.model.set('tableRow', vehicleListModal.collection)
      tableGrid.render()

      vehicleListModal.idVehicleList = []
    }).error(function (err) {
      console.log(err)
    })
  },
  addUnassignItemList: function (list) {
    var item = { id: 0, descr: 'Unassign Checklist' }

    list.unshift(item)

    return list
  },
  getList: function () {
    var view = this
    var list = null

    $.get('/api/report/vehicleDefectReport/active-checklist/?account_id=' + $.cookie('lastViewedAccount')).done(function (json) {
      list = _.sortByNat(json, 'descr')
      view.collection = view.addUnassignItemList(list)

      view.renderTemplate(view.collection)
      view.findSubView('scroll').render()
    })
  },
  show: function (top, left) {
    this.$el.css({ display: 'block', top: top, left: left + 1 }).fadeIn(400)
  },
  hide: function () {
    this.$el.css('display', 'none').fadeOut(400)
  }
})

export default VehicleAssignSelectView
