import $ from 'jquery'
import View from '../View'
import VehicleIconModel from '../../model/editVehicle/VehicleIcon'

const VehicleIconView = View.extend({
  model: VehicleIconModel,
  constructor: function (option) {
    this.model = new this.model()
    this.el = option.el
    this.$el = $(this.el)

    View.apply(this, arguments)
  },
  initialize: function () {
    var view = this

    view.model.listenTo(view.model, 'change:imageFileName', function () {
      view.$el.attr('src', view.model.imgUrl())
    })
  },
  renderVehicleIcon: function () {
    this.$el.attr('src', this.get('imgUrl'))
  },
  save: function () {
    var vehId = this.getParentView().vehId
    var vehicle = window.wls.getVehicle(vehId)
    var $vehicleData = $('.row[data-id=' + vehId + ']')

    this.model.save()
    vehicle.data.imageFileName = this.model.get('imageFileName')
    $vehicleData.find('.data-left-col img:first').attr('src', vehicle.getImgURL())
    if ($vehicleData.hasClass('active')) {
      vehicle.cache()
      vehicle.remove()
      setTimeout(function () {
        vehicle.add()
      }, 200)
    }
  }
})

export default VehicleIconView
