import IdlingReportPopupView from './IdlingReportPopup'
import JourneyReportSelectionMenuView from '../journeyReport/JourneyReportSelectionMenu'
import ReportPageView from '../ReportPage'
import IdlingReportView from './IdlingReport'

const IdlingReportPageView = ReportPageView.extend({
  el: '<div class="report-page" data-report-page="journey"></div>',
  initialize: function () {
    var view = this

    view.addSubView('reportPopup', IdlingReportPopupView)
    view.addSubView('selectionMenu', JourneyReportSelectionMenuView)
    view.addSubView('report', IdlingReportView)
    view.render()
  }
})

export default IdlingReportPageView
