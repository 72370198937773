import View from '../../View'
import CounterView from '../../Counter'
import TimeRangeWeekMapFormView from './TimeRangeWeekMap'

const TimeRangeProfileBuilderFormView = View.extend({
    initialize: function () {
        this.addSubView('journeysMetres', CounterView, {
            el: this.$el.find("[data-counter=journeysMetres]")[0],
            step: 1,
            minVal: 0,
        });
        this.addSubView('journeysMinutes', CounterView, {
            el: this.$el.find("[data-counter=journeysMinutes]")[0],
            step: 1,
            minVal: 0,
        });
        this.addSubView('timeRangeWeekMap', TimeRangeWeekMapFormView);
    }
})

export default TimeRangeProfileBuilderFormView
