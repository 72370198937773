import _ from 'underscore'
import $ from 'jquery'
import TableGrid from '../model/TableGrid'
import View from './View'
import ScrollView from '../view/Scroll'
import AlertAvailablePoiList from '../collection/alert/AlertAvailablePoiList'
import AlertPoiList from '../collection/alert/AlertPoiList'
import AlertAvailableAoiList from '../collection/alert/AlertAvailableAoiList'
import AlertAoiList from '../collection/alert/AlertAoiList'
import AlertAvailableVehicleList from '../collection/alert/AlertAvailableVehicleList'
import OutOfHoursAlertAvailableProfileList from "../collection/alert/outOfHours/OutOfHoursAlertAvailableProfileList";
import ProfileList from '../collection/alert/outOfHours/ProfileList'
import DriveBehaviorAlertAvailableProfileList
  from "../collection/alert/driverBehavior/DriveBehaviorAlertAvailableProfileList"
import OverspeedAlertAvailableProfileList from "../collection/alert/overspeed/OverspeedAlertAvailableProfileList";
import AlertAvailableImeiList from "../collection/alert/AlertAvailableImeiList";

const collectionModule = {
  'collection/alert/AlertAvailablePoiList': AlertAvailablePoiList,
  'collection/alert/AlertPoiList': AlertPoiList,
  'collection/alert/AlertAvailableAoiList': AlertAvailableAoiList,
  'collection/alert/AlertAoiList': AlertAoiList,
  'collection/alert/AlertAvailableVehicleList': AlertAvailableVehicleList,
  'collection/alert/outOfHours/OutOfHoursAlertAvailableProfileList': OutOfHoursAlertAvailableProfileList,
  'collection/alert/outOfHours/ProfileList': ProfileList,
  'collection/alert/driverBehavior/DriveBehaviorAlertAvailableProfileList' : DriveBehaviorAlertAvailableProfileList,
  'collection/alert/overspeed/OverspeedAlertAvailableProfileList' : OverspeedAlertAvailableProfileList,
  'collection/alert/AlertAvailableImeiList' : AlertAvailableImeiList
}

const TableGridView = View.extend({
  selectedItem: null,
  scroll: ScrollView,
  model: TableGrid,
  templateUrl: null,
  collection: null,
  events: {
    'click tbody tr:not(.empty-row)': 'setActiveRow'
  },
  constructor: function (option) {
    var view = this
    var args = arguments
    var $el = $(this.el || option.el)
    var data = $el.data()

    view.templateUrl = view.templateUrl ? view.templateUrl : option.templateUrl
    view.model = new view.model()
    view.scroll = new view.scroll({
      el: $el.find('.scroll-frame')
    })
    if (view.collection) {
      view.collection = new view.collection()
    }
    if (_.size(data)) {
      if (data.option.template) {
        view.templateUrl = data.option.template
      }

      console.log(data.option.collection)
      view.collection = new collectionModule[data.option.collection]() // collection initialization
      view.bindListenersLoadAndCreateTemplate($el, view, args)
      // require([data.option.collection], function (Collection) {
      //   view.collection = new Collection()
      //   view.bindListenersLoadAndCreateTemplate($el, view, args)
      // })
    } else {
      view.bindListenersLoadAndCreateTemplate($el, view, args)
    }
  },
  bindListenersLoadAndCreateTemplate: function ($el, view, args) {
    if (!view.model.get('selectAllowed')) {
      $el.addClass('unselectable')
      view.disableEvent('click tr')
    }
    view
      .listenTo(view.collection, 'remove', function () {
        view.render()
      })
      .listenTo(view.model, 'change:selectAllowed', function (model, selectAllowed) {
        if (!selectAllowed) {
          $el.addClass('unselectable')
          view.disableEvent('click tr')
        } else {
          $el.removeClass('unselectable')
        }
      })
      .loadTemplate(function () {
        var $emptyRow

        view
          .createTemplate($el.find('tbody:last')[0])
          .listenTo(view.collection, 'request', function (_collection, xhr, _option) {
            if (_option && _option.requestType === 'fetch') {
              xhr.done(function () {
                view.render()
              })
            }
          })
        $emptyRow = $el.find('tbody:last tr').clone().children().html('').end()
        view.emptyRow = '<tr class="empty-row">' + ($emptyRow.html()) + '</tr>'
        view.selectedItem = {} // prevent from prototyping

        View.apply(view, args)
      })
  },
  render: function () {
    if (this.somaTemplate !== '') {
      this.somaTemplate.scope.json = this.collection.toJSON()
      this.somaTemplate.render()
      this.renderEmptyRows()
    }

    return this
  },
  renderRow: function (model) {
    var view = this
    var id = model.get('id')
    var node = this.somaTemplate.getNode(this.$el.find('tr[data-id=' + id + ']')[0])
    node.scope.item = model.toJSON()
    node.update()
    node.render()
    return this
  },
  renderEmptyRows: function () {
    var view = this
    var rowCount = 0
    var maxEmptyRows = 0
    var emptyRowArray = []

    view.$el.find('.empty-row').remove()
    rowCount = view.collection.size()
    if (rowCount < 10) {
      maxEmptyRows = 10 - rowCount
      for (var i = 0; i < maxEmptyRows; i++) {
        emptyRowArray.push(view.emptyRow)
      }
      view.$el.find('tbody:last').append(emptyRowArray.join(''))
    }
    return this
  },
  setActiveRow: function (e) {
    var $tr = $(e.currentTarget)
    var multiSelect = this.model.get('multiSelect')
    var activeId

    if (!multiSelect) {
      activeId = $tr.siblings().andSelf().filter('tr.active').attr('data-id')
    } else if ($tr.hasClass('active')) {
      activeId = $tr.attr('data-id')
    }
    if ($tr.hasClass('active')) {
      $tr.removeClass('active')
    } else {
      if (!multiSelect) {
        $tr.addClass('active').siblings().removeClass('active')
      } else {
        $tr.addClass('active')
      }
    }
    this.setSelectedItem($tr, activeId)
  },
  setSelectedItem: function ($tr, activeId) {
    var id = $tr.attr('data-id')

    if (activeId in this.selectedItem) {
      delete this.selectedItem[activeId]
    }
    if (activeId !== id) {
      this.selectedItem[id] = this.collection.get(id)
    }
    this.trigger('itemSelect', $tr, id)
  },
  getSelectedItem: function () {
    return this.selectedItem
  },
  fetchSelectedItemModel: function (callback) {
    this.collection.get(_.keys(this.selectedItem)[0]).fetch({ success: callback })
  },
  getActiveRowId: function () {
    return this.$el.find('tr[data-id=' + _.first(_.keys(this.selectedItem)) + ']').attr('data-id')
  },
  hasSelectedItem: function () {
    return !!_.size(this.selectedItem)
  },
  resetSelectedItemAndRow: function () {
    this.selectedItem = {}
    this.$el.find('tr.active').removeClass('active')
  }
})

export default TableGridView
