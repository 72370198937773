import SelectView from '../Select'
import DriverList from '../editVehicle/DriverList'

const DriverSelectView = SelectView.extend({
  events: {
    selectmenuchange: 'selectChange'
  },
  initialize: function () {
    this.addSubView('driverList', DriverList, { el: this.el })
    SelectView.prototype.initialize.apply(this, arguments)
  },
  selectChange: function (e, ui) {
    var view = this

    if (ui.item.value === 'none') {
      this.getParentView().disableSave()
      return
    }
    this.getParentView().enableSave()
  },
  reset: function () {
    var view = this

    view.el.value = 'none'
    view.$el.selectmenu('refresh')
  }
})

export default DriverSelectView
