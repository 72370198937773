import Model from '../Model'

const ConfirmVideoRequestModel = Model.extend({
  urlRoot: '/api/activity-log/request-video',
  defaults: {
    logId: 0,
    vehicleId: 0,
    channelList: '',
    periodAfter: '',
    periodBefore: '',
    time: 0
  },
  validate: function (attrs, options) {

  }
})

export default ConfirmVideoRequestModel
