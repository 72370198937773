import $ from 'jquery'
import CompactReportTableGridView from '../../CompactReportTableGrid'
import ChecklistTableGridModel from '../../../../model/report/vehicleDefectReport/ChecklistTableGrid'
import ChecklistModalTableGridScrollView from './ChecklistModalTableGridScroll'

const ChecklistTableGridView = CompactReportTableGridView.extend({
  model: ChecklistTableGridModel,
  templateUrl: 'template/report/vehicleDefectReport/checklist/checklistTableGrid.html',
  mergeEvents: true,
  events: {
    'click [data-button=new-check]': 'addNewCheck',
    'click [data-button=remove-check]': 'removeCheck',
    'change .sortable-table input': 'inputValue',
    'keyup .sortable-table input': 'inputValue'
  },
  constructor: function (option) {
    var view = this

    view.setSubView({
      scroll: ChecklistModalTableGridScrollView
    })

    CompactReportTableGridView.apply(view, arguments)
  },
  initialize: function () {
    var view = this

    view.trigger('ready')
  },
  addNewCheck: function () {
    var view = this
    var parentView = view.getParentView().getParentView()
    var list = parentView.checkList
    var largestId = 0

    if (parentView.checkList.length !== 0) {
      largestId = Math.max.apply(Math, list.map(function (o) { return o.id }))
    }

    parentView.removeSelectViews(parentView.checkList)
    parentView.checkList.unshift({ id: largestId + 1, checkName: 'New Item', opts: parentView.defaultOptions })

    view.model.set('tableRow', parentView.checkList)
    view.render()
    parentView.addSelectViews(parentView.checkList)
    parentView.bindEvents()
  },
  removeCheck: function (e) {
    var view = this
    var parentView = view.getParentView().getParentView()
    var idxOfElem = $(e.currentTarget).closest('tr')[0].rowIndex

    parentView.checkList.splice(idxOfElem - 1, 1)

    if (parentView.checkList.length !== 0) {
      view.model.set('tableRow', parentView.checkList)
      view.render()

      parentView.addSelectViews(parentView.checkList)
      parentView.bindEvents()
    } else {
      view.addNewCheck()
    }
  },
  inputValue: function (e) {
    $(e.currentTarget).attr('value', e.currentTarget.value)
  },
  setChecksItem: function (_id, val) {
    var view = this
    var parentView = view.getParentView().getParentView()

    for (var i = 0; i < parentView.checkList.length; i++) {
      if (parentView.checkList[i].id === _id) {
        parentView.checkList[i].checkName = val
      }
    }
  },
  destroy: function () {
    var view = this

    view.findSubView('detailedVehicleDefectReportPopup').destroy()
    view.findSubView('statusPopup').destroy()
    view.findSubView('vehicleDefectReportCommentPopup').destroy()

    CompactReportTableGridView.prototype.destroy.apply(view, arguments)
  }
})

export default ChecklistTableGridView
