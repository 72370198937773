import $ from 'jquery'
import _ from 'underscore'
import FormView from '../Form'
import ConfirmVideoRequestModel from '../../model/camera/ConfirmVideoRequestModel'
import SelectView from '../Select'

const ConfirmVideoRequestFormView = FormView.extend({
  model: ConfirmVideoRequestModel,
  activityLog: null,
  mergeEvents: true,
  events: {
    'change input:checkbox': 'onSelect'
  },
  constructor: function () {
    this.$el = this.parentView.$el.find('form')
    this.el = this.$el[0]
    FormView.apply(this, arguments)
  },
  initialize: function () {
    var view = this
    var confirmVideoRequestPopupView = this.parentView
    var notificationPopupView = view.findSubView('notificationPopup')

    confirmVideoRequestPopupView.prepare()
    view.addSubView('periodBeforeSelect', SelectView, { el: view.$('[data-select=periodBefore]') })
    view.addSubView('periodAfterSelect', SelectView, { el: view.$('[data-select=periodAfter]') })
    this.findSubView('periodBeforeSelect').$el.on('selectmenuselect', $.proxy(view.onSelect, view))
    this.findSubView('periodAfterSelect').$el.on('selectmenuselect', $.proxy(view.onSelect, view))

    confirmVideoRequestPopupView.prepareDone()
    view.on('save', $.proxy(view.onSave, view))
    view.on('cancel', function () {
      confirmVideoRequestPopupView.closeBtnHandler()
      view.resetSelect()
    })
  },
  onSave: function (confirmVideoRequestModel, response) {
    var view = this
    var confirmVideoRequestPopupView = view.parentView
    var notificationPopupView = view.findSubView('notificationPopup')
    var isGetMoreForm = view.$el.find('[data-value]:visible').size() > 0
    var channels = confirmVideoRequestModel.get('channelList').split(',')
    var updateVideoSelect = function (activityLog, response) {
      var videoPLayerView = view.getActivityLogTable().findSubView('videoPlayerPopup').findSubView('videoPlayer')

      videoPLayerView.setProps(activityLog, parseInt(_.last(channels)))
    }
    var submitDoneCallback = isGetMoreForm ? updateVideoSelect : null

    confirmVideoRequestPopupView.model.set('visible', false)
    $.get('/api/activity-log/' + confirmVideoRequestModel.get('logId')).then(function (activityLog) {
      view.updateActivityLogTableRow(activityLog)
      submitDoneCallback && submitDoneCallback(activityLog)
    })
    // view.parentView.parentView.parentView.findSubView('activityLogForm').submit(submitDoneCallback)
    view.cancel()
    view.$el.find('[data-button=save]').addClass('disabled')

    !isGetMoreForm && notificationPopupView.render({
      msgId: 'successfully_saved',
      title: 'Camera Status',
      message: response.message,
      button: ['Close']
    })
  },
  setProps: function (props) {
    this.activityLog = props
    this.$el.find('[data-time]').text(new Date(props.recDate).format('HH:mm:ss'))
    this.$el.find('[data-vehicle-id]').text(props.vehicleId)
    this.$el.find('[data-vehreg]').text(props.registrationNumber)
    this.renderChannels(props.channels)
  },
  renderChannels: function (channels) {
    var html = ''
    var chunks = _.chunk(channels, 2)

    _.each(chunks, function (channelsArr) {
      html += '<div class="cameras-row">'
      _.each(channelsArr, function (channel) {
        var icoClass = ''
        var disabled = 'disabled'
        var checked = 'checked'
        switch (channel.videoStatus) {
          case -1:
            icoClass = 'no-video'
            break
          case 0:
            icoClass = 'video-download'
            disabled = ''
            checked = ''
            break
          case 1:
            icoClass = 'video-uploading'
            break
          case 2:
            icoClass = 'video-uploaded'
            break
        }
        html += '<label class="' + disabled + '">'
        html += '<span class="camera-ico ' + icoClass + '"></span>'
        html += '<input type="checkbox" ' + disabled + ' ' + checked + ' name="channel" value="' + channel.channelNumber + '">'
        html += '<span class="checkbox"></span>'
        html += '<span>' + channel.name + '</span>'
        html += '</label>'
      })
      html += '</div>'
    })

    this.$el.find('.cameras-row-container').html(html)
  },
  submitHandler: function (e) {
    e && e.preventDefault()
    if (this.$el.find('[data-button=save]').hasClass('disabled')) {
      return
    }
    FormView.prototype.submitHandler.apply(this, arguments)
  },
  syncData: function () {
    var activityLog = this.parentView.findSubView('confirmVideoRequestForm').activityLog
    var formData = this.getFormData()
    var vehicleId = this.$el.find('[data-vehicle-id]').text()

    formData.logId = activityLog.id
    formData.time = activityLog.recDate
    formData.channelList = []
    formData.vehicleId = vehicleId
    _.each(this.$el.find('input:checkbox:checked[name=channel]:not([disabled])'), function (item) {
      formData.channelList.push($(item).val())
    })
    formData.channelList = formData.channelList.join(',')
    this.model.set(formData)
  },
  onSelect: function () {
    var $periodBeforeSelect = this.findSubView('periodBeforeSelect').$el
    var $periodAfterSelect = this.findSubView('periodAfterSelect').$el

    if (($periodAfterSelect.val() !== '0' || $periodBeforeSelect.val() !== '0') && this.$el.find('input:checkbox:checked[name=channel]:not([disabled])').size() !== 0) {
      this.$el.find('[data-button=save]').removeClass('disabled')
    } else {
      this.$el.find('[data-button=save]').addClass('disabled')
    }
  },
  resetSelect: function () {
    var $periodBeforeSelect = this.findSubView('periodBeforeSelect').$el
    var $periodAfterSelect = this.findSubView('periodAfterSelect').$el

    $periodBeforeSelect.val(5)
    $periodAfterSelect.val(5)
    $periodBeforeSelect.selectmenu('refresh')
    $periodAfterSelect.selectmenu('refresh')
  },
  updateActivityLogTableRow: function (activityLog) {
    var view = this
    var activityLogTable = view.getActivityLogTable()
    var $tableRow = activityLogTable.$el.find('tr[data-id=' + activityLog.id + ']')
    var $videStatus = $tableRow.find('[data-video-status]')

    $tableRow.find('[data-camera-event]').text(activityLog.cameraEvent)
    activityLogTable.model.get('tableRow').get(activityLog.id).set('channels', activityLog.channels)

    switch (activityLog.videoStatus) {
      case -1:
        $videStatus.html('<span className="camera-ico no-video"></span>')
        break
      case 0:
        $videStatus.html('<span class="camera-ico video-download"></span>')
        break
      case 1:
        $videStatus.html('<span class="camera-ico video-uploading"></span>')
        break
      case 2:
        $videStatus.html('<span class="camera-ico video-uploaded"></span>')
        break
    }
  },
  getActivityLogTable: function () {
    return this.parentView.parentView
  }
})

export default ConfirmVideoRequestFormView
