import View from '../../View'
import PoiListView from './PoiList'

const PoiListAlertView = View.extend({
  el: '#content',
  events: {
    'click .close-btn': 'closeBtnHandler'
  },
  initialize: function () {
    var view = this

    view.addSubView('poiList', PoiListView)
    view.render()
  },
  closeBtnHandler: function () {
    this.destroy()
  },
  destroy: function () {
    var view = this

    view.findSubView('poiList').destroy()
  }
})

export default PoiListAlertView
